import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import { useSearchParams } from "react-router-dom";
import { Post } from "@/utils/post";
import PostWidget from "@components/elements/PostWidget";
import Input from "@/components/elements/input/Input";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

const News = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchParams] = useSearchParams();

  const posts = useInfiniteQuery<Response>(
    ["posts", searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed?page=" +
          pageParam +
          "&q=" +
          searchQuery +
          "&featured_post_id=" +
          searchParams.get("featured") +
          "&user_id=" +
          "",
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const { user } = useAppSelector((state) => state.user);

  if (!posts.isSuccess) return null;
  if (!user) return null;

  return (
    <div className="flex flex-col gap-3">
      <Input
        placeholder="Feed durchsuchen"
        name="feed_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      {posts.data.pages.map(
        (page) =>
          page &&
          page.data.map((post: Post) => (
            <PostWidget
              postId={post.id}
              post={post}
              key={post.id}
              text={post.text}
              author={post.author}
              repost={post.reposted}
              created_at={post.created_at}
            />
          )),
      )}

      {(posts.hasNextPage || posts.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => posts.fetchNextPage()} />
      )}
    </div>
  );
};

export default News;
