import React from "react";
import BackButton from "@/components/nav/BackButton";

const ActGlobal = () => {
  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/global_header.png"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Global agieren
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              Über Ländergrenzen hinaus denken
            </div>
          </div>
        </section>

        <article className="grid grid-flow-row gap-3 text-sm">
          <h1 className="font-semibold text-base">
            Tradefoox.com, der weltweit größte C2C, B2C &
            B2B-E-Commerce-Marktplatz, hilft Dir, auch in Zeiten der Pandemie
            Millionen von Käufern zu erreichen.
          </h1>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Tradefoox.com hilft Dir, Dein Geschäft weltweit zu betreiben
            </h2>
            <div>
              Tradefoox.com hilft Verkäufern wie Dir, verschiedene Vorteile zu
              genießen und an Millionen von Käufern auf der ganzen Welt zu
              verkaufen.
            </div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Verbinde Dich mit Millionen von Käufern auf der ganzen Welt
            </h2>
            <ul className="list-inside list-disc">
              <li>
                Erhalte sofortigen Zugang zu 11 Millionen B2B-Käufern auf der
                ganzen Welt
              </li>
              <li>
                Unsere aktiven Käufer senden jeden Tag über 300.000 Anfragen
              </li>
              <li>
                Erschließe Dir den 27 Billionen Dollar schweren weltweiten
                B2B-E-Commerce-Markt
              </li>
              <li>
                Tradefoox.com ist wie eine globale Messe für Verkäufer, die rund
                um die Uhr geöffnet haben
              </li>
            </ul>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Steiger Deine Umsätze mit intelligenten Tools
            </h2>
            <ul className="list-inside list-disc">
              <li>
                Erstelle ein digitales Schaufenster für Deine Marke – keine
                Programmierkenntnisse erforderlich
              </li>
              <li>
                Präsentiere Bilder, Videos, Zertifizierungen und Deine
                wichtigsten Verkaufsargumente
              </li>
              <li>
                Stellen mithilfe der Massen-Upload-Funktion Deinen gesamten
                Katalog online
              </li>
              <li>
                Finden mithilfe intelligenter Marketing-Tools die richtigen
                Käufer für Deine Produkte
              </li>
            </ul>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Erhalte Anfragen und steigere Deinen Umsatz
            </h2>
            <div>
              Jeder Verkauf ist ein Gespräch, das mit einer Anfrage beginnt. Du
              kannst mit Deinen Kunden zu Deinen Bedingungen interagieren und
              die Beziehungen selbst gestalten. Mit Online-Messen und
              Live-Streaming-Veranstaltungen kannst Du Deine Produkte
              potenziellen Kunden präsentieren. Du kannst proaktiv Käufer
              finden, die eine Angebotsanfrage stellen und nach Produkten wie
              den Ihren suchen.
            </div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Stelle Deinen Erfolg mit professionellem Kundenservice sicher
            </h2>
            <div>
              Wir haben unsere neuen Erfolgsprogramme für Verkäufer entwickelt,
              um Dich auf den Erfolg vorzubereiten. Dazu gehören
              Schulungsseminare, der Tradefoox Seller Training E-Kurs und
              Verkäuferforen von Servicepartnern und dem offiziellen
              Tradefoox-Team.
            </div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">
              Schöpfe den Wettbewerbsvorteil im grenzüberschreitenden
              elektronischen Handel voll aus.
            </h2>
          </div>
          <h1 className="font-semibold text-base">
            Bist Du bereit, Dein Geschäft auszubauen?
          </h1>
        </article>
      </div>
    </>
  );
};

export default ActGlobal;
