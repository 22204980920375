import React, { useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StatusBar, Style } from "@capacitor/status-bar";
import tw, { TwStyle } from "twin.macro";

type Props = {
  onClose: () => void;
  imageUrl: string;
  imageCSS?: TwStyle;
  backgroundCSS?: TwStyle;
};

const FullScreenImage = (props: Props) => {
  useEffect(() => {
    StatusBar.setStyle({ style: Style.Dark });
    return () => {
      StatusBar.setStyle({ style: Style.Light });
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-full z-[500]"
      onClick={props.onClose}
    >
      <div
        css={[
          tw`absolute top-0 left-0 w-full h-full bg-black`,
          props.backgroundCSS,
        ]}
      ></div>
      <button
        onClick={props.onClose}
        className="absolute right-5 top-5 mt-[env(safe-area-inset-top)]"
      >
        <XMarkIcon className="h-8 w-8 text-white" />
      </button>
      <div className="absolute top-[calc(50%+env(safe-area-inset-top))] left-0 -translate-y-1/2 w-full">
        <div className="overflow-y-hidden overflow-x-auto mt-3 relative snap-x flex scrollbar-none scroll-smooth snap-mandatory overflow-scrolling[touch]">
          <div className="cursor-pointer relative">
            <div className="relative w-screen">
              <img
                height="100%"
                width="100%"
                css={[
                  tw`mx-auto max-h-[calc(calc(100dvh-120px)-env(safe-area-inset-top))] h-screen max-w-[90vw] object-contain`,
                  props.imageCSS,
                ]}
                src={props.imageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenImage;
