import React from "react";
import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { User } from "@/utils/user";
import Dropdown from "@/components/menu/Dropdown";
import { SocialIcon } from "react-social-icons";
import DropdownItem from "@/components/menu/DropdownItem";
import getName from "@/utils/getName";
import tw from "twin.macro";
import GoogleMapsWidget from "@/pages/profile/details/GoogleMapsWidget";
import EmployeeContainer from "@/pages/profile/details/EmployeeContainer";
import PrivateContainer from "@/pages/profile/details/PrivateContainer";
import ClubContainer from "@/pages/profile/details/ClubContainer";
import { TFunction } from "i18next";
import ProfileDetailsDescriptionContainer from "@pages/profile/details/ProfileDetailsDescriptionContainer";

const socialmediaTypes = [
  { name: "Twitter", value: "twitter", url: "https://twitter.com/" },
  { name: "Facebook", value: "facebook", url: "https://facebook.com/" },
  { name: "Instagram", value: "instagram", url: "https://instagram.com/" },
  { name: "Tiktok", value: "tiktok", url: "https://tiktok.com/" },
  { name: "Youtube", value: "youtube", url: "https://youtube.com/" },
  { name: "Linkedin", value: "linkedin", url: "https://linkedin.com/" },
  { name: "Xing", value: "xing", url: "https://xing.com/" },
];

type ProfileConfigType = {
  [key in "company" | "person" | "club"]: any;
};

const ProfileConfig = (t: TFunction): ProfileConfigType => ({
  person: {
    tabs: [
      { name: "Über mich", key: "about", url: "über_mich" },
      { name: "Feed", key: "news", url: "news" },
      { name: "Watch", key: "watch", url: "watch" },
      { name: "Jobs", key: "jobs", url: "jobs" },
      { name: "Shop", key: "ads", url: "shop" },
      { name: "Inserate", key: "assignments", url: "inserate" },
    ],
    mediatabs: [
      { name: "Watch", key: "watch" },
      { name: "Feed", key: "news" },
    ],
    userlabels: {
      items: [
        {
          label: "Freunde",
          type: "objectlabel",
          property: ["friends"],
          placeholder: "0",
        },
        {
          label: "Likes",
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: "Follower",
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
        {
          label: "Folgt",
          type: "objectlabel",
          property: ["following_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: (
            <ChatBubbleOvalLeftIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: (
            <HeartIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "like_count",
        },
        {
          type: "button",
          image: (
            <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "views",
        },
        {
          type: "dropdown",
          image: (
            <EllipsisHorizontalIcon className="w-5 h-5 text-darkblue heroicon-stroke-w-2.0" />
          ),
          menupoints: [
            {
              label: "Vernetzen",
              fn: () => {
                console.log("network");
              },
            },
            {
              label: "Kontaktieren",
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: "Bearbeiten",
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: "Teilen",
              fn: () => {
                console.log("share");
              },
            },
            {
              label: "Melden",
              fn: () => {
                console.log("report");
              },
            },
            {
              label: "Like",
              fn: () => {
                console.log("like");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.futherInfo"),
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
          authRequired: true,
          page: "/verwaltung/profile",
          state: { backlink: "/profile" },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.contacts",
          ),
        },
        {
          image: "/images/data.svg",
          tabname: "facts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.facts"),
        },
        {
          image: "/images/steckbrief.svg",
          tabname: "other_facts",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.characteristics",
          ),
        },
        {
          image: "/images/cv.svg",
          tabname: "cvs",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.career",
          ),
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.media"),
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.hashtags",
          ),
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.socialMedia",
          ),
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: t(
            "main.profileViewTabs.aboutMe.content.sidebarButtons.statistics",
          ),
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "facts",
        title: "Daten und Fakten",
        items: [
          { label: "Benutzername", type: "objectlabel", property: ["dummy"] },
          { label: "Geburtstag", type: "objectlabel", property: ["dummy"] },
          { label: "Website", type: "objectlabel", property: ["dummy"] },
          { label: "Arbeitsgeber", type: "objectlabel", property: ["dummy"] },
          { label: "Organisation", type: "objectlabel", property: ["dummy"] },
          { label: "Interessen", type: "objectlabel", property: ["dummy"] },
        ],
      },
      {
        name: "contacts",
        title: "Kontakte",
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
      {
        name: "other_facts",
        title: "Steckbrief",
        items: [
          { label: "Ausbildung", type: "objectlabel", property: ["dummy"] },
          { label: "Werdegang", type: "objectlabel", property: ["dummy"] },
          { label: "Qualifikation", type: "objectlabel", property: ["dummy"] },
          { label: "Sprachen", type: "objectlabel", property: ["dummy"] },
        ],
      },
    ],
  },
  club: {
    tabs: [
      { name: "Über uns", key: "about", url: "über_uns" },
      { name: "Feed", key: "news", url: "news" },
      { name: "Watch", key: "watch", url: "watch" },
      { name: "Jobs", key: "jobs", url: "jobs" },
      { name: "Shop", key: "ads", url: "shop" },
      { name: "Inserate", key: "assignments", url: "inserate" },
    ],
    mediatabs: [
      { name: "Watch", key: "watch" },
      { name: "Feed", key: "news" },
    ],
    userlabels: {
      items: [
        {
          label: "Likes",
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: "Follower",
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: (
            <ChatBubbleOvalLeftIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: (
            <HeartIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "like_count",
        },
        {
          type: "button",
          image: (
            <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "views",
        },
        {
          type: "dropdown",
          image: (
            <EllipsisHorizontalIcon className="w-5 h-5 text-darkblue heroicon-stroke-w-2.0" />
          ),
          menupoints: [
            {
              label: "Kontaktieren",
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: "Bearbeiten",
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: "Teilen",
              fn: () => {
                console.log("share");
              },
            },
            {
              label: "Like",
              fn: () => {
                console.log("like");
              },
            },
            {
              label: "Melden",
              fn: () => {
                console.log("report");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: "Weitere Infos",
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: "Bearbeiten",
          authRequired: true,
          page: "/verwaltung/profile",
          props: { state: { backlink: "/profile" } },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: "Kontakt",
        },
        {
          image: "/images/data.svg",
          tabname: "facts",
          label: "Fakten",
        },
        {
          image: "/images/worker.svg",
          tabname: "employee",
          label: "Mitarbeiter",
        },
        {
          image: "/images/members.svg",
          tabname: "members",
          label: "Mitglieder",
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: "Medien",
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: "Hashtags",
        },
        {
          image: "/images/jobsfile.svg",
          tabname: "jobs",
          label: "Jobs",
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: "Socialmedia",
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: "Statistik",
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "facts",
        title: "Daten und Fakten",
        items: [
          { label: "Vereinsname", type: "objectlabel", property: ["dummy"] },
          { label: "Rechsform", type: "objectlabel", property: ["dummy"] },
          {
            label: "Vereinsregisternummer",
            type: "objectlabel",
            property: ["dummy"],
          },
          {
            label: "Umsatzsteuer-Id",
            type: "objectlabel",
            property: ["dummy"],
          },
          { label: "Tätigkeitsfeld", type: "objectlabel", property: ["dummy"] },
          { label: "Keywords", type: "objectlabel", property: ["dummy"] },
          { label: "Impressum", type: "objectlabel", property: ["dummy"] },
          { label: "AGB", type: "objectlabel", property: ["dummy"] },
        ],
      },
      {
        name: "contacts",
        title: "Kontakte",
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
  company: {
    tabs: [
      { name: "Über uns", key: "about", url: "über_uns" },
      { name: "Feed", key: "news", url: "news" },
      { name: "Watch", key: "watch", url: "watch" },
      { name: "Jobs", key: "jobs", url: "jobs" },
      { name: "Shop", key: "ads", url: "shop" },
      { name: "Inserate", key: "assignments", url: "inserate" },
    ],
    mediatabs: [
      { name: "Watch", key: "watch" },
      { name: "Feed", key: "news" },
    ],
    userlabels: {
      items: [
        {
          label: "Likes",
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: "Follower",
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: (
            <ChatBubbleOvalLeftIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: (
            <HeartIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "like_count",
        },
        {
          type: "button",
          image: (
            <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
          ),
          property: "views",
        },
        {
          type: "dropdown",
          image: (
            <EllipsisHorizontalIcon className="w-5 h-5 text-darkblue heroicon-stroke-w-2.0" />
          ),
          menupoints: [
            {
              label: "Kontaktieren",
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: "Bearbeiten",
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: "Teilen",
              fn: () => {
                console.log("share");
              },
            },
            {
              label: "Like",
              fn: () => {
                console.log("like");
              },
            },
            {
              label: "Melden",
              fn: () => {
                console.log("report");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: "Weitere Infos",
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: "Bearbeiten",
          authRequired: true,
          page: "/verwaltung/profile",
          state: { backlink: "/profile" },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: "Kontakt",
        },
        {
          image: "/images/data.svg",
          tabname: "facts",
          label: "Fakten",
        },
        {
          image: "/images/worker.svg",
          tabname: "employee",
          label: "Mitarbeiter",
        },
        {
          image: "/images/jobsfile.svg",
          tabname: "jobs",
          label: "Jobs",
        },
        {
          image: "/images/cv.svg",
          tabname: "cvs",
          label: "Werdegänge",
          authRequired: true,
        },
        {
          image: "/images/pricetag.svg",
          tabname: "assignments",
          label: "Inserate",
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: "Medien",
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: "Hashtags",
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: "Socialmedia",
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: "Statistik",
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "facts",
        title: "Daten und Fakten",
        items: [
          {
            type: "userdescription",
          },
          {
            label: "Unternehmensname:",
            bold: true,
            small: true,
            type: "functionlabel",
            property: [""],
            fn: getName,
          },
          { label: "Rechsform", type: "objectlabel", property: ["dummy"] },
          {
            label: "Handelsregister-Nummer",
            type: "objectlabel",
            property: ["dummy"],
          },
          {
            label: "Umsatzsteuer-Id",
            type: "objectlabel",
            property: ["dummy"],
          },
          { label: "Jahresumsatz", type: "objectlabel", property: ["dummy"] },
          {
            label: "Zahl der Mitarbeiter",
            type: "objectlabel",
            property: ["dummy"],
          },
          { label: "Tätigkeitsfeld", type: "objectlabel", property: ["dummy"] },
          { label: "Keywords", type: "objectlabel", property: ["dummy"] },
          { label: "Impressum", type: "objectlabel", property: ["dummy"] },
          { label: "AGB", type: "objectlabel", property: ["dummy"] },
        ],
      },
      {
        name: "contacts",
        title: "Kontakte",
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
});

/**
 * Returns component according to type
 * @param {any} component
 * @param {User} user
 * @returns
 */
export function getComponent({
  component,
  user,
  t,
}: {
  component: any;
  user?: User;
  t: any;
}) {
  switch (component.type) {
    case "functionlabel":
      if (!component.fn) return;
      if (!user) return;
      if (!getProperty(user, component.property, component.placeholder)) return;

      return (
        <div
          css={[
            tw`flex flex-wrap gap-1`,
            component.bold && tw`font-semibold`,
            component.small && tw`text-xs`,
          ]}
        >
          {component.label && <div>{component.label}</div>}
          {component.fn(
            getProperty(user, component.property, component.placeholder),
          )}
        </div>
      );

    case "userbuttons":
      return (
        <div className="flex flex-wrap gap-2 text-xs">
          {component.buttons &&
            component.buttons.map((button: any, index: number) => {
              switch (button.type) {
                case "button":
                  return (
                    <button
                      key={index}
                      className={`flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] ${
                        button.color ? button.color : "bg-slate-200"
                      }`}
                      onClick={() => {
                        console.log(button.action);
                      }}
                    >
                      {button.image}
                      <div key={index} className="text-gray-500">
                        {button?.label} {button.property && "0"}
                      </div>
                    </button>
                  );

                case "dropdown":
                  return (
                    <div
                      className={`flex flex-row gap-1 justify-center rounded-lg w-[62px] h-[20px] ${
                        button.color ? button.color : "bg-slate-200"
                      }`}
                    >
                      <Dropdown icon={button.image} align="left">
                        {button.menupoints &&
                          button.menupoints.map((menupoint: any) => {
                            return (
                              <DropdownItem
                                key={menupoint.label}
                                onClick={menupoint.fn}
                                className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm"
                              >
                                {menupoint.label}
                              </DropdownItem>
                            );
                          })}
                      </Dropdown>
                    </div>
                  );

                default:
                  return <div></div>;
              }
            })}
        </div>
      );

    case "objectlabel":
      if (!user) return;
      if (!getProperty(user, component.property, component.placeholder)) return; // show placeholder if property is empty or undefined

      return (
        <div className="flex flex-wrap gap-1">
          {component.label && (
            <div className={`${component.bold && "font-semibold"}`}>
              {component.label}
            </div>
          )}
          {getProperty(user, component.property, component.placeholder)}
        </div>
      );

    case "socialmedia":
      if (!user) return;
      if (!getProperty(user, component.property)) return;

      return (
        <div className="flex flex-wrap gap-2 ">
          {getProperty(user, component.property, component.placeholder).map(
            (socialmedia: any, index: number) => {
              return (
                <div key={index}>
                  <SocialIcon
                    url={getSocialMedia(socialmedia)}
                    className="scale-75 -mx-2"
                  />
                </div>
              );
            },
          )}
        </div>
      );

    case "googlemaps":
      if (!user) return;
      return <GoogleMapsWidget />;

    case "contactbox":
      if (!user) return;
      return (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {getContactBox(user)}
        </div>
      );

    case "userdescription":
      if (!user) return;
      return (
        <>
          <div className="mb-2">
            <div className="font-semibold text-sm">
              {t("main.profileViewTabs.aboutMe.content.general")}
            </div>
          </div>
          <div className="mb-2">
            <ProfileDetailsDescriptionContainer
              text={user.description || user.legacy_description || ""}
              legacy={!!(!user.description && user.legacy_description)}
            />
          </div>
        </>
      );

    default:
      return <div>Unbekannte Komponente</div>;
  }
}

/**
 * Returns the value of the property of the JSON object
 * @param object JSON object
 * @param path properties
 * @param placeholder returns placeholder if value of property is undefined
 * @returns value of property. returns undefined if no value was found
 */
const getProperty = (
  object: any,
  path: Array<string>,
  placeholder?: string,
) => {
  let currentValue = object;
  for (const element of path) {
    if (!element) return object;
    if (!currentValue[element]) return placeholder;
    currentValue = JSON.parse(JSON.stringify(currentValue[element]));
  }
  return currentValue;
};

/**
 * Returns the url of the social media
 * @param linkData Linkdata of the social media page
 * @returns url of social media
 */
const getSocialMedia = (linkData: any) => {
  const index = socialmediaTypes.findIndex(
    (element) => element.value === linkData.type,
  );
  if (index < 0) return undefined;
  return socialmediaTypes[index].url + linkData.username;
};

const getContactBox = (user: User) => {
  switch (user.type) {
    case "person":
      return <PrivateContainer user={user} minimized={false} />;
    case "club":
      return <ClubContainer user={user} minimized={false} />;
    case "company":
      return <EmployeeContainer user={user} />;
    default:
      return <></>;
  }
};

export default ProfileConfig;
