import React from "react";
import { useState } from "react";
import Messenger from "@pages/messenger/Messenger";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatType } from "@/utils/types/ChatType";
import ChatContainer from "@pages/messenger/ChatContainer";
import tw from "twin.macro";
import TabFilter from "@components/menu/TabFilter";
import UserComboBox from "@components/elements/form/UserComboBox";
import { NavLink, useLocation } from "react-router-dom";
import ForwardMessagePopup from "@pages/messenger/chat/popup/ForwardMessagePopup";
import { Media } from "@/utils/types/Media";
import api from "@/api/api";
import BackButton from "@/components/nav/BackButton";
import useLoginModal from "@/state/modal/useLoginModal";
import MessengerGroups from "@/pages/messenger/MessengerGroups";
import { useAppSelector } from "@/state/hooks";
import { XMarkIcon } from "@heroicons/react/24/outline";
import isMobile from "@/utils/isMobile";
import { useTranslation } from "react-i18next";
import GroupInfo from "@/pages/messenger/group/GroupInfo";
import UserInfo from "@/pages/messenger/group/UserInfo";

const MessengerContainer = () => {
  const [selectedChats, setSelectedChats] = useState<string[]>([]);
  const [tab, setTab] = useState<
    "chats" | "archive" | "group" | "groupinfo" | "userinfo"
  >("chats");
  const queryClient = useQueryClient();
  const location = useLocation();
  const [selectedForm, setSelectedForm] = useState("chats");
  const [selectedChat, setSelectedChat] = useState<string | false>(
    location.state?.chatId || false,
  );
  const [returnChat, setReturnChat] = useState<string | false>(
    location.state?.chatId || false,
  );
  const [forwardMessage, setForwardMessage] = useState<
    | {
        text: string;
        link: string | undefined;
        media: Array<Media> | undefined;
      }
    | false
  >(false);
  const [searchClick, setSearchClick] = useState<(visible: boolean) => void>();

  const { user } = useAppSelector((state) => state);
  const { t } = useTranslation();

  const loginModal = useLoginModal();

  const chats = useQuery<ChatType[], Error>(["chats", tab], async () => {
    const { data } = await api.get(
      `/api/v1/chats${tab === "archive" ? "/archive" : ""}`,
    );
    // data[0] && setSelectedChat(data[0].id);
    return data;
  });

  const createChat = useMutation(["create-chat"], {
    mutationFn: async (userId: string) => {
      const res = await api.get(`/api/v1/chats/create/${userId}`);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] }).then(() => {
        setSelectedChat(data.data.id);
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) {
    loginModal.open();
    return <></>;
  }
  if (!chats.isSuccess) return <></>;

  return (
    <>
      <BackButton />
      <div className="lg:grid lg:grid-cols-2 w-full gap-2 relative">
        {forwardMessage && (
          <ForwardMessagePopup
            onClose={() => setForwardMessage(false)}
            message={forwardMessage}
          />
        )}
        <div css={[selectedChat && tw`hidden lg:block`]}>
          <>
            {(tab === "chats" || tab === "archive") && (
              <div className="lg:border lg:border-gray-300 lg:rounded-2xl lg:relative lg:h-fit">
                {isMobile && (
                  <NavLink
                    to="/news"
                    className="w-5 h-5 absolute right-2 top-2"
                  >
                    <XMarkIcon />
                  </NavLink>
                )}

                <div className="p-2 max-md:pr-10">
                  <TabFilter
                    options={[
                      {
                        name: t("main.header.links.messenger.tabs.chats"),
                        key: "chats",
                      },
                      {
                        name: t("main.header.links.messenger.tabs.archived"),
                        key: "archive",
                      },
                      {
                        name: t("main.header.links.messenger.tabs.groups"),
                        key: "group",
                      },
                    ]}
                    value={tab}
                    onChange={(s) => setTab(s as "chats" | "archive" | "group")}
                  />
                </div>
                <div className="px-2">
                  <UserComboBox
                    onSelect={(id) => createChat.mutate(id)}
                    label="Kontakt suchen"
                  />
                </div>
                <div className="flex flex-col gap-2 mt-4 no-scrollbar overflow-y-auto h-[calc(100dvh-190px)]">
                  {chats.data.map((chat) => (
                    <Messenger
                      key={chat.id}
                      chat={chat}
                      showCheckbox={selectedChats.length > 0}
                      onExit={() => setSelectedChat(false)}
                      isSelected={chat.id === selectedChat}
                      onChange={(checked) => {
                        if (checked) {
                          setSelectedChats([...selectedChats, chat.id]);
                        } else {
                          setSelectedChats(
                            selectedChats.filter((id) => id !== chat.id),
                          );
                        }
                      }}
                      onClick={() => setSelectedChat(chat.id)}
                    />
                  ))}
                </div>
              </div>
            )}
            {tab === "group" && (
              <MessengerGroups
                chats={chats.data}
                selectedChats={selectedChats}
                setSelectedChats={setSelectedChats}
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
                onClose={() => setTab("chats")}
                setSelectedChat={setSelectedChat}
              />
            )}
            {tab === "groupinfo" && (
              <GroupInfo
                groupId={returnChat.toString()}
                onClose={() => {
                  setSelectedChat(returnChat);
                  setReturnChat(false);
                  setTab("chats");
                  searchClick && searchClick(true);
                }}
              />
            )}
            {tab === "userinfo" && (
              <UserInfo
                userId={returnChat.toString()}
                onClose={() => {
                  setSelectedChat(returnChat);
                  setReturnChat(false);
                  setTab("chats");
                  searchClick && searchClick(true);
                }}
              />
            )}
          </>
        </div>

        {selectedChat && (
          <ChatContainer
            markedChats={selectedChats}
            chatId={selectedChat}
            switchTab={(
              newTab: "chats" | "archive" | "group" | "groupinfo" | "userinfo",
            ) => {
              setTab(newTab);
              setReturnChat(selectedChat);
              if (window.innerWidth < 768) {
                setSelectedChat(false);
              }
            }}
            exitChat={() => setSelectedChat(false)}
            forwardMessage={(text, link, media) => {
              setForwardMessage({ text, link, media });
            }}
            searchClick={{
              setFunction: setSearchClick,
              getFunction: searchClick,
            }}
          />
        )}
      </div>
    </>
  );
};

export default MessengerContainer;
