import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";

type Props = { user: User; minimized?: boolean };
const ClubContainer = ({ user, minimized = true }: Props) => {
  return (
    <Panel>
      <div className="text-sm flex flex-col gap-2">
        <UserWidget
          user={user}
          showName={true}
          follow={false}
          verified={true}
        />

        {!minimized && (
          <div className="flex flex-col gap-1">
            <div className="flex flex-col w-full justify-between text-xs">
              <div className="flex flex-row gap-x-1">
                <div className="font-semibold shrink-0">PLZ, Ort:</div>
                <div className="truncate"></div>
              </div>
              <div className="flex flex-row gap-x-1">
                <div className="font-semibold">Straße:</div>
                <div className="truncate"></div>
              </div>
              <div className="flex flex-row gap-x-1">
                <div className="font-semibold">Telefon:</div>
                <div></div>
              </div>
              <div className="flex flex-row gap-x-1">
                <div className="font-semibold">Website:</div>
                <div></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default ClubContainer;
