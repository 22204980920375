import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MagnifyingGlassIcon,
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
  PencilSquareIcon,
  TrashIcon,
  UserIcon,
  UserGroupIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import api from "@/api/api";
import ContextMenu from "@components/elements/shared/ContextMenu";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showReportModal } from "@/state/reportModal/actions";
import { useDispatch } from "react-redux";

type Props = {
  archived?: boolean;
  isAdmin?: boolean;
  chatId: string;
  userId: string;
  exitChat: () => void;
  toggleSearch?: () => void;
  openEditModal?: () => void;
  isGroup?: boolean;
};
const ChatDropdown = ({
  archived,
  chatId,
  exitChat,
  toggleSearch,
  userId,
  openEditModal,
  isGroup,
  isAdmin,
}: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const archiveChat = useMutation(["chat-archive"], {
    mutationFn: async (chatId: string) => {
      const res = await api.get(`/api/v1/chats/${chatId}/archive`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["chat-" + chatId]);
      queryClient.invalidateQueries(["chats"]);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unArchiveChat = useMutation(["chat-un-archive"], {
    mutationFn: async (chatId: string) => {
      const res = await api.delete(`/api/v1/chats/${chatId}/archive`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["chat-" + chatId]);
      queryClient.invalidateQueries(["chats"]);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const deleteChat = useMutation(["chat-delete"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/chats/${chatId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["chat-" + chatId]);
      queryClient.invalidateQueries(["chats"]);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const block = useMutation([`block-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["chat-" + chatId]);
      queryClient.invalidateQueries(["chats"]);
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      <ContextMenu
        items={[
          toggleSearch && {
            label: "Durchsuchen",
            onClick: () => {
              toggleSearch();
            },
            icon: MagnifyingGlassIcon,
          },
          openEditModal &&
            isGroup &&
            isAdmin && {
              label: "Bearbeiten",
              onClick: () => {
                openEditModal();
              },
              icon: PencilSquareIcon,
            },
          !archived
            ? {
                label: "Archivieren",
                onClick: () => {
                  archiveChat.mutate(chatId);
                },
                icon: ArchiveBoxArrowDownIcon,
              }
            : {
                label: "Entarchivieren",
                onClick: () => {
                  unArchiveChat.mutate(chatId);
                },
                icon: ArchiveBoxIcon,
              },
          {
            label: "Melden",
            onClick: () => dispatch(showReportModal(chatId, "chat")),
            icon: FlagIcon,
          },
          {
            label: isGroup ? "Gruppe blockieren" : "Nutzer blockieren",
            onClick: () => {
              exitChat();
              block.mutate();
            },
            icon: MinusCircleIcon,
          },
          {
            label: isGroup ? "Zum Gruppenprofil" : "Zum Nutzerprofil",
            onClick: () => {
              navigate("/profile/" + userId);
            },
            icon: isGroup ? UserGroupIcon : UserIcon,
          },
          {
            label: "Chat löschen",
            onClick: () => {
              exitChat();
              deleteChat.mutate();
            },
            icon: TrashIcon,
          },
        ]}
      />
    </>
  );
};

export default ChatDropdown;
