import React, { useState } from "react";
import { User } from "@/utils/user";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import { useInfiniteQuery } from "@tanstack/react-query";
import api from "@/api/api";
import ContactForYouItem from "@pages/contacts/for_you/ContactForYouItem";
import Input from "@components/elements/input/Input";
import useDebounce from "@/utils/useDebounce";
import ButtonPanel from "@components/box/ButtonPanel";
import { useTranslation } from "react-i18next";

type Props = {
  type: string;
  userId: string;
  onClose: () => void;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};
const UserRelationList = (props: Props) => {
  const { userId } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { t } = useTranslation();
  const list = useInfiniteQuery<Response>(
    [props.type, userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/users/${userId}/${props.type}?page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const headlines: { [index: string]: string } = {
    following: "Andere denen der Nutzer folgt",
    follows: "Andere die dem Nutzer folgen",
  };

  return (
    <ButtonPanel onClose={props.onClose} title={headlines[props.type]}>
      <Input
        name="search-contacts"
        placeholder={t("main.feedTabs.placeholder")}
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <div className="max-h-[80vh] overflow-y-auto mt-2">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
          {list.data &&
            list.data.pages.map((page) =>
              page.data.map((item: User) => (
                <ContactForYouItem key={item.id} user={item} />
              )),
            )}
        </div>
        {list.hasNextPage && !list.isFetchingNextPage && (
          <ShowMoreButton onClick={() => list.fetchNextPage()} />
        )}
      </div>
    </ButtonPanel>
  );
};

export default UserRelationList;
