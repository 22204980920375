import React, { ReactNode } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useLocation } from "react-router-dom";

type notificationType = {
  count: number;
  minimum: number;
  link: string;
  type: string;
};

type listType = {
  href: string;
  label: string;
  icon: ReactNode;
  solidIcon: ReactNode;
  style?: string;
  notification?: { count: number; minimum: number; link: string; type: string };
  reverse?: boolean;
  children?: Array<listType>;
  extern?: boolean;
};

type Props = {
  link: listType;
  depth?: number;
  isMobile?: boolean;
  onClose?: () => void;
  linkIndex: string;
  setLinkIndex: (index: string) => void;
  key?: string;
};

const NavSideLink = ({
  link,
  depth = 0,
  isMobile = false,
  onClose,
  linkIndex = "",
  setLinkIndex,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Get component according to type and count
   * @param type number or dot
   * @param count number of notifications
   * @returns component
   */
  const renderNotification = (type: string, count: number) => {
    switch (type) {
      case "number":
        return (
          <div className="bg-tf-red-600 flex items-center justify-center text-mini font-semibold text-white w-4 h-4 rounded-full">
            {count > 9 ? "+9" : count}
          </div>
        );

      case "dot":
        return (
          <div className="border-[6px] border-tf-red-600 bg-white p-0.5 rounded-full" />
        );

      default:
        return undefined;
    }
  };

  const checkPath = (url: string, node: string) => {
    const nodeStack = node.split("/").splice(1);
    const urlStack = url.split("/").splice(1);
    const result =
      nodeStack.findIndex((element, index) => {
        return element.replace("/", "") === urlStack[index];
      }) >= 0;
    return result;
  };

  return (
    <div
      className={`flex flex-col gap-2 ${link.style} ${
        link.reverse && "flex-col-reverse"
      }`}
      style={{ margin: "0px 0px 0px " + 20 * depth + "px" }}
    >
      <div className="flex flex-row justify-between items-center text-base">
        {link.extern ? (
          <a href={link.href} className="flex flex-row gap-2 items-center">
            {link.icon} {link.label}
          </a>
        ) : (
          <div className="flex flex-row gap-2 items-center">
            <NavLink
              to={link.href}
              onClick={(e) => {
                e.preventDefault();
                if (isMobile && onClose && link.href.length > 0) onClose();
                if (link.href.length > 0) navigate(link.href);
                if (link.children)
                  link.label === linkIndex
                    ? setLinkIndex("")
                    : setLinkIndex(link.label);
              }}
            >
              {({ isActive }) => (
                <div css={[tw`flex flex-row gap-2 items-center`]}>
                  <div
                    css={[
                      isActive && checkPath(location.pathname, link.href)
                        ? tw`scale-[120%]`
                        : tw``,
                    ]}
                  >
                    {isActive ? link.solidIcon : link.icon}
                  </div>
                  <div
                    css={[
                      isActive && checkPath(location.pathname, link.href)
                        ? tw`font-semibold`
                        : tw``,
                    ]}
                  >
                    {link.label}
                  </div>
                  {link.notification &&
                    link.notification?.count > link.notification?.minimum && (
                      <>
                        {renderNotification(
                          link.notification.type,
                          link.notification.count,
                        )}
                      </>
                    )}
                </div>
              )}
            </NavLink>
          </div>
        )}
        {link.children && link.children.length > 0 && (
          <ChevronDownIcon
            className={`w-4 h-4 cursor-pointer ml-auto ${
              link.reverse && "rotate-180"
            }`}
            onClick={() => {
              link.label === linkIndex
                ? setLinkIndex("")
                : setLinkIndex(link.label);
            }}
          />
        )}
      </div>
      {link.label === linkIndex && (
        <div
          className={`flex flex-col gap-2 bg-white w-full border border-white border-l-gray-200 border-l-2 ${
            isMobile &&
            (link.reverse
              ? "absolute -translate-y-8"
              : "absolute translate-y-8")
          }`}
        >
          {link.children &&
            link.children.map((childLink) => (
              <NavSideLink
                link={childLink}
                depth={depth + 1}
                key={childLink.href}
                isMobile={isMobile}
                onClose={onClose}
                linkIndex=""
                setLinkIndex={() => undefined}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default NavSideLink;
