import React from "react";
import Input from "@components/elements/input/Input";
import Checkbox from "@components/elements/input/Checkbox";
import WorkTimeSelector from "@components/elements/jobs/form/shared/elements/WorkTimeSelector";
import FormInput from "@components/elements/form/FormInput";
import getName from "@/utils/getName";
import { useAppSelector } from "@/state/hooks";

type Props = {
  form: any;
  onChange: (name: string, value: unknown) => void;
};

const JobBasicsForm = ({ form, onChange }: Props) => {
  const { user } = useAppSelector((state) => state.user);

  if (!user) {
    return null;
  }

  return (
    <div className="grid grid-flow-row gap-2 text-sm">
      <Input
        disabled
        value={getName(user)}
        name="company"
        placeholder="Firma"
      />
      <FormInput
        value={form.title}
        name="title"
        placeholder="Titel des Stellenangebots"
        onChange={onChange}
      />
      <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
        <FormInput
          value={form.occupation}
          name="occupation"
          placeholder="Tätigkeit / Beruf"
          onChange={onChange}
        />
        <Checkbox
          name="social_security_required"
          onChange={(e) => onChange("social_security_required", e)}
          checked={form.social_security_required || false}
        >
          Sozialversicherungspflichtig
        </Checkbox>
      </div>
      <div className="grid grid-cols-[auto,1fr] lg:grid-cols-2 gap-2 items-center">
        <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1">
          <input
            type="radio"
            id="limited"
            name="limited"
            value="checked"
            className="focus:ring-transparent"
          />
          Befristet
          <input
            type="radio"
            id="limited"
            name="limited"
            value="2"
            className="focus:ring-transparent"
          />
          Unbefristet
        </div>
        <Input
          type="date"
          name="limitation_data"
          placeholder="Bis wann"
          label="Bis wann"
          value={form.limitation_data || ""}
          onChange={(e) => onChange("limitation_data", e)}
        />
      </div>
      <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-end">
        <WorkTimeSelector value={form.type} onChange={onChange} />
        <Input
          name="hours"
          placeholder="Wochenstunden"
          value={form.weekly_hours || ""}
          onChange={(e) => onChange("weekly_hours", e)}
        />
      </div>
      <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-end">
        <Input
          type="date"
          name="earlystart"
          placeholder="Frühster Beginn"
          label="Frühster Beginn"
          value={form.earlystart || ""}
          onChange={(e) => onChange("earlystart", e)}
        />
        <Input
          type="date"
          name="latestart"
          placeholder="Spätester Beginn"
          label="Spätester Beginn"
          value={form.latestart || ""}
          onChange={(e) => onChange("latestart", e)}
        />
      </div>
      <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
        <div />
        <Checkbox
          name="tariffbound"
          onChange={(e) => onChange("standard_wage", e)}
          checked={form.standard_wage || false}
        >
          Tarifgebunden
        </Checkbox>
      </div>
      <div className="grid grid-flow-row lg:grid-cols-2 gap-2">
        <Input
          name="compensation"
          placeholder="Vergütung"
          value={form.compensation || ""}
          onChange={(e) => onChange("compensation", e)}
        />
        <Input
          name="Tariffcontract"
          placeholder="Tarif"
          value={form.standard_wage_details || ""}
          onChange={(e) => onChange("standard_wage_details", e)}
        />
      </div>
      <div className="-mb-2">Stellenbeschreibung</div>
      <div className="grid grid-flow-row gap-1">
        <textarea
          name="description"
          className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
          value={form.description || ""}
          onChange={(e) => onChange("description", e.target.value)}
        />
      </div>
      <div className="grid grid-flow-row gap-1">
        <Input type="file" name="attachments" placeholder="Anlagen" />
      </div>
    </div>
  );
};

export default JobBasicsForm;
