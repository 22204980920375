import React, { useState, useContext } from "react";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import ChangePasswordContainer from "@/pages/auth/ChangePasswordContainer";
import AccountDeactivationPage from "@pages/account/AccountDeactivationPage";
import Button from "@/components/elements/input/Button";
import Alert from "@/components/elements/Alert";
import { useNavigate } from "react-router-dom";
import { AppContext } from "@/App";
import isMobile from "@/utils/isMobile";
import BackButton from "@/components/nav/BackButton";

type Props = {
  tab?: string;
};

const AccountEditContainer = ({ tab = "passwort" }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(true);

  const tabs = [
    { key: "passwort", name: t("main.panel.profileWidgets.changePassword") },
    { key: "logout", name: t("main.panel.profileWidgets.logout") },
    {
      key: "löschen",
      name: t("main.panel.profileWidgets.deleteAccount"),
    },
  ];

  if (!user) return <span>Loading...</span>;

  return (
    <>
      <BackButton stepback />
      <div className="max-md:p-3">
        <TabMenu>
          {tabs.map((tab: { key: string; name: string }) => {
            return (
              <>
                <TabLink subTab to={"/verwaltung/account/" + tab.key}>
                  {tab.name}
                </TabLink>
              </>
            );
          })}
        </TabMenu>

        {tab === "passwort" && <ChangePasswordContainer />}
        {tab === "logout" && (
          <div className="mx-auto">
            <Button onClick={() => setShowAlert(true)}>Ausloggen</Button>
          </div>
        )}
        {tab === "löschen" && <AccountDeactivationPage />}

        {showAlert && (
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            buttons={Alert.ButtonVariants.YesNo}
            variant={Alert.MessageVariants.Info}
            timeout={0}
            message="Jetzt ausloggen?"
            title="Logout"
            yesClick={() => {
              navigate("/logout");
            }}
          />
        )}
      </div>
    </>
  );
};

export default AccountEditContainer;
