import React from "react";
import Panel from "@components/box/Panel";
import SuggestionsItem from "@components/elements/sidebar/suggestions/SuggestionsItem";
import SuggestionsHeader from "@components/elements/sidebar/suggestions/SuggestionsHeader";
import { useTranslation } from "react-i18next";

const items = [
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse1",
    picture: "/placeholder.png",
    link: "",
  },
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse2",
    picture: "/placeholder.png",
    link: "",
  },
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse3",
    picture: "/placeholder.png",
    link: "",
  },
];
const Suggestions = () => {
  const { t } = useTranslation();
  return (
    <Panel title={t("main.recommendations.title")}>
      <SuggestionsHeader
        text="Marketplace"
        action={t("main.shoppingVenture.sell")}
        link="/marketplace/create"
      />
      {items.map((item) => (
        <SuggestionsItem
          key={item.name}
          name={item.name}
          link={item.link}
          picture={item.picture}
        />
      ))}
      <SuggestionsHeader
        text={t("main.recommendations.advertisements")}
        action={t("buttons.publish")}
        link="/inserate/create"
      />
      {items.map((item) => (
        <SuggestionsItem
          key={item.name}
          name={item.name}
          link={item.link}
          picture={item.picture}
        />
      ))}
      <SuggestionsHeader
        text={t("main.recommendations.jobs")}
        action={t("buttons.publish")}
        link="/jobs/create"
      />
      {items.map((item) => (
        <SuggestionsItem
          key={item.name}
          name={item.name}
          link={item.link}
          picture={item.picture}
        />
      ))}
    </Panel>
  );
};
export default Suggestions;
