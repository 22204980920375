import React from "react";
import {
  ArrowUpOnSquareIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  HeartIcon,
  PencilIcon,
  ArrowUturnRightIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import Dropdown from "@components/menu/Dropdown";
import DropdownItem from "@components/menu/DropdownItem";
import LikeButton from "@/components/elements/form/buttons/LikeButton";
import ChatButton from "@/components/elements/form/buttons/ChatButton";
import ShareButton from "@/components/elements/form/buttons/ShareButton";
import ViewButton from "@/components/elements/form/buttons/ViewButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "@/api/api";
import { useDispatch } from "react-redux";
import { showReportModal } from "@/state/reportModal/actions";
import BlockUserDropdownButton from "@components/elements/shared/buttons/BlockUserDropdownButton";

type likeButtonType = {
  likeUrl: string;
  unlikeUrl: string;
  liked: boolean;
  like_count: number;
};

type chatButtonType = {
  chatUrl: string;
  backlink?: string;
};

type viewButtonType = {
  view_count: number;
};

type connectButtonType = {
  isVisible: boolean;
};

type shareButtonType = {
  title?: string;
  body?: string;
  media?: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
};

type Props = {
  likeButton: likeButtonType;
  viewButton: viewButtonType;
  chatButton: chatButtonType;
  connectButton?: connectButtonType;
  shareButton?: shareButtonType;
  blocked?: boolean;
  itemId: string;
  itemType: string;
};

const ButtonsBar = ({
  likeButton,
  viewButton,
  chatButton,
  connectButton,
  shareButton,
  itemId,
  itemType,
  blocked,
}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const unlike = useMutation(["unlike"], {
    mutationFn: async () => {
      const res = await api.get(likeButton.unlikeUrl);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const like = useMutation(["like"], {
    mutationFn: async () => {
      const res = await api.get(likeButton.likeUrl);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(chatButton.chatUrl);
      return res.data;
    },
    onSuccess: (data) => {
      navigate("/messenger", {
        state: { chatId: data.data.id, backlink: chatButton.backlink },
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connect = useMutation([`contacts-request-${itemId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${itemId}/connect`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + itemId],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex flex-wrap gap-2 items-end text-xs max-lg:justify-between">
      <ChatButton createChat={createChat} />
      <ShareButton contentType="" content={shareButton} />
      <LikeButton
        liked={likeButton.liked}
        likeCount={likeButton.like_count}
        unlike={unlike}
        like={like}
      />
      <ViewButton viewCount={viewButton.view_count} />

      <div className="h-5 md:h-6">
        <Dropdown
          icon={
            <div className="text-darkblue heroicon-stroke-w-2.0 rounded-full relative h-5 md:h-6">
              <EllipsisHorizontalIcon className="peer cursor-pointer w-4 h-5" />
            </div>
          }
          align="left"
        >
          {connectButton && connectButton.isVisible && (
            <DropdownItem
              onClick={() => connect.mutate()}
              className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
            >
              Vernetzen
              <ArrowUturnRightIcon className="w-4 h-4" />
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              createChat.mutate();
            }}
            className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
          >
            Kontaktieren
            <ChatBubbleOvalLeftIcon className="w-4 h-4" />
          </DropdownItem>
          <DropdownItem
            onClick={() => console.log("edit")}
            className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
          >
            Bearbeiten
            <PencilIcon className="w-4 h-4" />
          </DropdownItem>
          <DropdownItem
            onClick={() => console.log("share")}
            className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
          >
            Teilen
            <ArrowUpOnSquareIcon className="w-4 h-4" />
          </DropdownItem>
          <DropdownItem
            onClick={() => (likeButton.liked ? unlike.mutate() : like.mutate())}
            className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
          >
            Like
            <HeartIcon className="w-4 h-4" />
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(showReportModal(itemId, itemType))}
            className="px-4 py-2 text-left w-56 text-sm mb-1 group-first:mt-1 inline-flex items-center justify-between text-gray-700 active:bg-gray-100 active:text-gray-900"
          >
            Melden
            <FlagIcon className="w-4 h-4" />
          </DropdownItem>
          <BlockUserDropdownButton
            userId={itemId}
            blocked={blocked}
            keys={["user"]}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default ButtonsBar;
