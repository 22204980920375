import {
  HIDE_REPORT_MODAL,
  ReportModalActions,
  SHOW_REPORT_MODAL,
} from "@/state/reportModal/types";

export type ReportModalState = {
  itemId: string | null;
  title?: string | null;
  type: string | null;
  isVisible: boolean;
};

const initialState: ReportModalState = {
  itemId: null,
  title: null,
  type: null,
  isVisible: false,
};

const reportModalReducer = (
  state = initialState,
  action: ReportModalActions
): ReportModalState => {
  switch (action.type) {
    case SHOW_REPORT_MODAL:
      return {
        ...state,
        itemId: action.payload.itemId,
        title: action.payload.title,
        type: action.payload.type,
        isVisible: true,
      };
    case HIDE_REPORT_MODAL:
      return {
        ...state,
        itemId: null,
        title: null,
        type: null,
        isVisible: false,
      };
    default:
      return state;
  }
};

export default reportModalReducer;
