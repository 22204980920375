import React from "react";
import Panel from "@components/box/Panel";
import { NavLink, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const LegalNoticeContainer = () => {
  const navigate = useNavigate();

  return (
    <Panel mobileBorder={false}>
      <button onClick={() => navigate(-1)} className="lg:hidden">
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <div className="flex flex-col gap-6 text-gray-700">
        <div className="text-xl">Impressum</div>
        <div className="text-xs font-bold">Verantwortlich für „TRADEFOOX”</div>
        <div className="text-xs">tradefoox.com wird betreut von:</div>
        <div className="flex flex-col text-xs">
          <div className="font-bold">day one mediagroup GmbH & Co. KG</div>
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Deutschland</div>
          <div>Telefon: +49 (0) 371 / 52 480 900</div>
          <div>E-Mail: info@tradefoox.com</div>
          <div className="inline-flex gap-1">
            <div className="font-bold">USt-IdNr.:</div>
            DE208140174
          </div>
        </div>
        <div className="flex flex-col text-xs">
          <div className="inline-flex gap-1">
            <div className="font-bold">Geschäftsführende Gesellschafter:</div>
            Ronny Streit
          </div>
          <div className="inline-flex gap-1">
            <div className="font-bold">Registergericht:</div>
            Amtsgericht Chemnitz, HRA 8905
          </div>
        </div>
        <div className="text-xs">
          Die Gesellschaft ist eine Kommanditgesellschaft mit Sitz in Chemnitz,
          Registergericht Chemnitz HRA 8905. Persönlich haftende
          Gesellschafterin ist die SCC | HOLDING GmbH mit Sitz in Chemnitz,
          Registergericht Chemnitz HRB 31911. Die Europäische Kommission stellt
          eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter
          folgendem Link finden: http://ec.europa.eu/odr
        </div>
        <div>
          <div className="text-xs font-bold">
            Hinweis gemäß §36 Verbraucherstreitbeilegungsgesetz (VBSG)
          </div>
          <div className="text-xs">
            Der Verkäufer/Auftragnehmer wird nicht an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im
            Sinne des VSBG teilnehmen und ist hierzu auch nicht verpflichtet.
          </div>
        </div>
        <div className="text-xs font-bold">Haftungsausschluss (Disclaimer)</div>
        <div>
          <div className="text-xs font-bold">Haftung für Inhalte</div>
          <div className="text-xs">
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Haftung für Links</div>
          <div className="text-xs">
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Urheberrecht</div>
          <div className="text-xs">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Weitere Infos</div>
          <div className="text-xs flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              Datenschutz
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              Urheberrecht
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              Cookie Richtlinie
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              Community Richtlinie
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              Nutzungsbedingungen
            </NavLink>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default LegalNoticeContainer;
