import React, { ReactNode, useState } from "react";
import Panel from "@/components/box/Panel";
import Button from "@components/elements/input/Button";
import Tags from "@/components/elements/input/Tags";
import LegalInput from "@/components/elements/input/LegalInput";
import Select from "@/components/elements/input/Select";
import TextareaAutosize from "react-textarea-autosize";
import Input from "@/components/elements/input/Input";
import AssignmentCreateConfig from "@/pages/assignments/create/AssignmentCreateConfig";
import tw from "twin.macro";
import CheckboxGroup from "@/components/elements/input/CheckboxGroup";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

function AssignmentsCreateContainer() {
  const { user } = useAppSelector((state) => state.user);
  const [data, setData] = useState<any>({});
  const [category, setCategory] = useState("immobilie");
  const { t } = useTranslation();

  if (!user) return <span>Loading...</span>;

  const renderType = (item: any): ReactNode => {
    switch (item.type) {
      case "input":
        return (
          <Input
            name={item.name}
            placeholder={item.placeholder}
            label={item.label}
            onChange={(e) =>
              setData((value: any) => {
                return { ...value, [item.name]: e };
              })
            }
          />
        );

      case "date":
        return (
          <Input
            name={item.name}
            type="date"
            placeholder={item.label}
            label={item.label}
            onChange={(e) =>
              setData((value: any) => {
                return { ...value, [item.name]: e };
              })
            }
          />
        );

      case "link":
        return (
          <a
            className="text-sm hover:underline"
            rel="noopener noreferrer"
            target="_blank"
            href={item.link}
          >
            {item.label}
          </a>
        );

      case "textarea":
        return (
          <>
            <label className="block text-sm font-medium text-gray-700">
              {item.label}
            </label>
            <TextareaAutosize
              minRows={3}
              onChange={(e) =>
                setData((value: any) => {
                  return { ...value, [item.name]: e };
                })
              }
              value={data[item.name]}
              css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
            />
          </>
        );

      case "select":
        return (
          <Select
            name={item.name}
            items={item.items}
            label={item.label}
            onChange={(e) =>
              setData((value: any) => {
                return { ...value, [item.name]: e };
              })
            }
          />
        );

      case "tags":
        return (
          <Tags label={item.label} name={item.name} placeholder={item.label} />
        );

      case "legal":
        return (
          <LegalInput
            name={item.name}
            label={item.label}
            value={data[item.name]}
            onChange={(value) =>
              setData((oldData: any) => {
                return { ...oldData, [item.name]: value };
              })
            }
          />
        );

      case "radio":
        return (
          <div className="flex flex-col gap-1">
            <div className="text-gray-700">{item.label}</div>
            <div className="flex flex-col lg:flex-row gap-2">
              {item.items.map((subItem: any) => {
                return (
                  <div key={subItem.name} className="flex gap-2 items-center">
                    <input
                      name={item.name}
                      id={item.name}
                      type="radio"
                      value={subItem.value}
                      className="focus:ring-transparent"
                    />
                    {subItem.name}
                  </div>
                );
              })}
            </div>
          </div>
        );

      case "label":
        return <div className="font-semibold">{item.label}</div>;

      case "checkboxgroup":
        return (
          <div className="flex flex-col gap-x-6 gap-y-2 text-black">
            <CheckboxGroup
              itemStyle={
                item.itemStyle
                  ? item.itemStyle
                  : "flex flex-col lg:flex-row gap-x-6 gap-y-0"
              }
              name={item.name}
              label={item.label}
              items={item.items}
              onChange={() => {
                return null;
              }}
            />
          </div>
        );

      case undefined:
        return (
          <>
            {Array.isArray(item) &&
              item.map((subItem: any) => {
                return <>{renderType(subItem)}</>;
              })}
          </>
        );

      default:
        return <div>Unbekanntes Eingabefeld</div>;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Panel>
        <div className="grid grid-flow-row gap-2 text-sm">
          {AssignmentCreateConfig(t)[category].map((item: any) => {
            return (
              <div
                key={item.name}
                className="grid grid-flow-row lg:grid-flow-col items-end gap-2"
              >
                {renderType(item)}
              </div>
            );
          })}
          <div className="grid grid-flow-row lg:grid-flow-col gap-2">
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.back")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              Duplizieren
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.preview")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.save")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("buttons.publish")}
            </Button>
          </div>
        </div>
      </Panel>
    </form>
  );
}

export default AssignmentsCreateContainer;
