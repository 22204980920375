import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const Partner = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form className="grid grid-flow-row gap-2 text-sm">
      <BackButton />
      <img
        src="/images/info/banner-partnerprogramm.jpg"
        className="h-[200px] w-full object-cover"
      />
      <h1 className="font-semibold text-base">Werde Partner von TRADEFOOX!</h1>
      <div>
        Du bist im Online-Business tätig und hast ein starkes Vertriebsteam? Du
        willst Dein internationales Produktportfolio erweitern? Dann werde
        Vertriebspartner von TRADEFOOX.
      </div>
      <div>
        Präsentiere Dich mit tradefoox.com als attraktiven Partner, um Vorteile
        gegenüber Wettbewerbern zu schaffen. Neben einer nachhaltigen
        Kooperationsentwicklung kannst Du dich durch die Nutzung wechselseitiger
        Stärken auf Dein eigentliches Kerngeschäft konzentrieren.
      </div>
      <h2 className="font-semibold">Deine Vorteile als Partner:</h2>
      <ul className="list-disc list-inside">
        <li>
          Professionelle Zusammenarbeit mit unserem einzigartigen Netzwerk in
          Deutschland, Österreich und der Schweiz.
        </li>
        <li>Erschließung neuer Umsatzpotenziale</li>
        <li>
          Risikofreier Marktzugang nach Deutschland, Österreich der Schweiz und
          Europa für Deine Kunden
        </li>
        <li>Umfangreiche Unterstützung für Dein Team</li>
        <li>Maximiere die Sichtbarkeit für Deine Kunden</li>
      </ul>
      <h2 className="font-semibold">
        Gemeinsam mit unseren Unternehmenspartnern konnten wir schon viel
        erreichen.
      </h2>
      <div>
        Lass Dich von den erfolgreichen Kooperationen inspirieren und
        kontaktiere uns noch heute - wir freuen uns!
      </div>

      <Input
        label="Firma"
        type="text"
        name="company"
        value={company}
        onChange={(e) => {
          setCompany(e);
        }}
        required
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Land"
          type="text"
          name="country"
          placeholder="Deutschland"
          value={surname}
          onChange={(e) => {
            setSurname(e);
          }}
          required
        />
        <Input
          label="Website"
          type="website"
          name="name"
          placeholder="example.com"
          value={lastname}
          onChange={(e) => {
            setLastname(e);
          }}
          required
        />
        <div className="col-span-2">
          <Input
            label="E-Mail"
            type="text"
            name="email"
            placeholder="E-Mail"
            value={email}
            onChange={(e) => {
              setEmail(e);
            }}
            required
          />
        </div>
      </div>
      <div>
        Deine Nachricht
        <TextareaAutosize
          minRows={3}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
        />
      </div>

      <Checkbox
        name="agb"
        required
        checked={privacyCheck}
        onChange={(e) => setPrivacyCheck(e)}
      >
        Ich habe die{" "}
        <NavLink to="/privacy" target="_blank">
          Datenschutzerklärung
        </NavLink>{" "}
        gelesen und akzeptiert
      </Checkbox>
      <Button submit>Senden</Button>
    </form>
  );
};

export default Partner;
