import React, { useState } from "react";
import getName from "@/utils/getName";
import UserWidget from "@components/elements/UserWidget";
import {
  PencilIcon,
  Cog6ToothIcon,
  XMarkIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  PencilIcon as PencilIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  UserIcon as UserIconSolid,
} from "@heroicons/react/24/solid";
import NavSideUser from "@components/nav/NavSideUser";
import NavSideLink from "@components/nav/NavSideLink";
import Footer from "@/components/elements/sidebar/widgets/Footer";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  isMobile?: boolean;
  onClose?: () => void;
};

export const MyProfileWidget = ({ isMobile = false, onClose }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [linkIndex, setLinkIndex] = useState("");
  const { t } = useTranslation();
  const bottomlinks = [
    {
      href: "",
      label: t("main.panel.settings"),
      icon: <Cog6ToothIcon className="w-4 h-4" />,
      solidIcon: <Cog6ToothIconSolid className="w-4 h-4" />,
      style: "xl:hidden w-full bg-white",
      reverse: true,
      children: [
        {
          href: "/verwaltung/profile",
          label: t("main.panel.profileWidgets.editProfile"),
          icon: <PencilIcon className="w-4 h-4" />,
          solidIcon: <PencilIconSolid className="w-4 h-4" />,
        },
        {
          href: "/verwaltung/account",
          label: t("main.panel.profileWidgets.account"),
          icon: <UserIcon className="w-4 h-4" />,
          solidIcon: <UserIconSolid className="w-4 h-4" />,
        },
      ],
    },
  ];

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 h-full">
      {isMobile && (
        <button
          onClick={() => {
            setLinkIndex("");
            onClose && onClose();
          }}
          className="absolute right-2"
        >
          <XMarkIcon className="h-[25px]" />
        </button>
      )}
      <div className="grid grid-flow-row gap-2 text-center">
        <div className="line-clamp-2 flex-wrap">Hallo, {getName(user)}</div>
        <button
          onClick={() => {
            setLinkIndex("");
            onClose && onClose();
          }}
        >
          <UserWidget user={user} follow={false} online={true} />
        </button>
        <div className="flex flex-row gap-4 text-xs mt-2 justify-center">
          <div>{t("main.panel.likes", { likes: user.like_count })}</div>
          <button
            onClick={() => {
              navigate("/kontakte/follower");
              onClose && onClose();
            }}
          >
            {t("main.panel.followers", { followers: user.follower_count })}
          </button>
          <button
            onClick={() => {
              navigate("/kontakte/following");
              onClose && onClose();
            }}
          >
            {t("main.panel.following", { following: user.following_count })}
          </button>
        </div>
        <NavSideUser
          isMobile={isMobile}
          onClose={onClose}
          linkIndex={linkIndex}
          setLinkIndex={(newIndex) => {
            setLinkIndex(newIndex);
          }}
        />
      </div>
      {isMobile && linkIndex === "" && (
        <>
          <div className="flex flex-col mx-auto items-center">
            <QRCodeSVG
              width={65}
              height={65}
              value={"http://tradefoox.com/profile/" + user.id}
            />
          </div>
          <div className="xl:hidden">
            <Footer fontStyle={tw`font-thin`} />
          </div>
        </>
      )}
      <div className="flex flex-col gap-2 mt-auto">
        {bottomlinks.map((link) => {
          return (
            <NavSideLink
              link={link}
              key={link.href}
              isMobile={isMobile}
              onClose={onClose}
              linkIndex={linkIndex}
              setLinkIndex={(newIndex) => {
                setLinkIndex(newIndex);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default MyProfileWidget;
