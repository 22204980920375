import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";
import login from "@/api/auth/login";
import PopupWrapper from "@components/elements/PopupWrapper";
import useLoginModal from "@/state/modal/useLoginModal";
import { trackFormSubmission } from "@/utils/hubspot";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import isApp from "@/utils/isApp";
import AppLink from "@components/elements/shared/AppLink";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setToken, setUser } from "@/state/user/userSlice";

const LoginPopup = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginModal = useLoginModal();
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const loginMutation = useMutation(["login"], {
    mutationFn: () => {
      trackFormSubmission("loginForm");
      return login({
        email,
        password,
      });
    },
    onSuccess: (loginData) => {
      dispatch(setUser(loginData.user));
      dispatch(setToken(loginData.token));
      setTimeout(
        () =>
          navigate(
            loginData.user.start_page
              ? "/" + loginData.user.start_page
              : "/news",
          ),
        200,
      );
      queryClient.invalidateQueries({ queryKey: ["me"] });
      loginModal.close();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="E-Mail oder Passwort falsch"
          title="Login"
        />,
      );
    },
  });

  return (
    <PopupWrapper onClose={() => loginModal.close()}>
      <div className="w-full md:max-w-[310px]">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!loginMutation.isLoading) loginMutation.mutate();
          }}
          id="loginForm"
          className="grid grid-flow-row gap-1 text-center justify-center text-black"
        >
          <div className="text-darkblue font-medium text-xl">
            {t("main.signIn.title1")}
            <br /> {t("main.signIn.title2")}
          </div>
          <Input
            name="email"
            label={t("main.signIn.email")}
            value={email}
            placeholder={t("main.signIn.placeholderEmail")}
            type="email"
            onChange={(e) => setEmail(e)}
          />
          <Input
            name="password"
            label={t("main.signIn.password")}
            value={password}
            placeholder={t("main.signIn.password")}
            type="password"
            onChange={(e) => setPassword(e)}
          />
          <Button
            submit
            onClick={() => {
              if (!loginMutation.isLoading) loginMutation.mutate();
            }}
          >
            {t("main.signIn.login")}
          </Button>
          <div className="flex flex-row items-center justify-center gap-1 text-darkblue">
            <div className="text-3xl">!</div>
            <div className="whitespace-nowrap text-mini">
              {t("main.signIn.forgotPassword")} <br />
              {t("main.signIn.resetPassword")}{" "}
              <NavLink to="/verwaltung/passwort" className="text-darkcyan">
                {t("main.signIn.here")}
              </NavLink>{" "}
              {t("main.signIn.newPassword")}
            </div>
          </div>
          <div className="text-sm text-gray-700">
            {t("main.signIn.description")}
          </div>
          <Button
            onClick={() => {
              loginModal.close();
              navigate("/register");
            }}
            variant={Button.Variants.Cyan}
          >
            {t("main.signIn.createAccount")}
          </Button>
          <AppLink />
          <div className="flex gap-2 justify-center">
            <NavLink to="/impressum" className="text-sm">
              {t("main.signIn.imprint")}
            </NavLink>
            <span className="text-sm">|</span>
            <NavLink to="/datenschutz" className="text-sm">
              {t("main.signIn.dataProtection")}
            </NavLink>
          </div>
        </form>
      </div>
    </PopupWrapper>
  );
};

export default LoginPopup;
