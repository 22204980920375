import BackButton from "@/components/nav/BackButton";
import React from "react";
import { NavLink } from "react-router-dom";

type NewspaperType = {
  title: string;
  link: string;
  thumbnail: string;
};

const Newspapers = () => {
  const localNewspapers: Array<NewspaperType> = [
    {
      title: "Amtsblatt Landkreis Bautzen",
      link: "https://www.landkreis-bautzen.de/von-zeit-zu-zeit-aus-der-arbeit-der-landkreisverwaltung.php",
      thumbnail: "/images/info/amtsblatt_landkreis_bautzen.jpg",
    },
    {
      title: "Amtsblatt Erzgebirkskreis",
      link: "https://www.erzgebirgskreis.de/de/aktuelles/landkreiskurier/",
      thumbnail: "/images/info/amtsblatt_erzgebirgskreis.jpg",
    },
    {
      title: "Amtsblatt Landkreis Görlitz",
      link: "https://www.kreis-goerlitz.de/city_info/webaccessibility/index.cfm?item_id=852940&waid=392",
      thumbnail: "/images/info/amtsblatt_landkreis_goerlitz.jpg",
    },
    {
      title: "Amtsblatt Landkreis Leipzig",
      link: "https://www.landkreisleipzig.de/amtsblatt.html",
      thumbnail: "/images/info/amtsblatt_landkreis_leipzig.jpg",
    },
    {
      title: "Amtsblatt Landkreis Meißen",
      link: "http://www.kreis-meissen.org/60.html",
      thumbnail: "/images/info/amtsblatt_landkreis_meissen.jpg",
    },
    {
      title: "Amtsblatt Landkreis Mittelsachsen",
      link: "https://www.landkreis-mittelsachsen.de/das-amt/neuigkeiten/mittelsachsenkurier.html",
      thumbnail: "/images/info/amtsblatt_mittelsachsen.jpg",
    },
    {
      title: "Amtsblatt Landkreis Sächsische Schweiz",
      link: "https://www.landratsamt-pirna.de/amtsblatt.html",
      thumbnail: "/images/info/amtsblatt_landkreis_saechsische_schweiz_1.jpg",
    },
    {
      title: "Amtsblatt Landkreis Nordsachsen",
      link: "https://www.landkreis-nordsachsen.de/amtsblaetter.html",
      thumbnail: "/images/info/amtsblatt_landkreis_nordsachsen.jpg",
    },
    {
      title: "Amtsblatt Vogtlandkreis",
      link: "https://www.vogtlandkreis.de/B%C3%BCrgerservice-und-Verwaltung/Infos-und-Services/Kreis-Journal-Vogtland/",
      thumbnail: "/images/info/amtsblatt_landkreis_vogtland.jpg",
    },
    {
      title: "Amtsblatt Landkreis Zwickau",
      link: "https://www.landkreis-zwickau.de/aktuelles-amtsblatt",
      thumbnail: "/images/info/amtsblatt_landkreis_zwickau.jpg",
    },
  ];

  const communityNewspapers: Array<NewspaperType> = [
    {
      title: "Amtsblatt Kreisfreie Stadt Chemnitz",
      link: "https://www.chemnitz.de/chemnitz/de/aktuell/publikationen/amtsblatt/2021/28692.html",
      thumbnail: "/images/info/amtsblatt_chemnitz.jpg",
    },
    {
      title: "Amtsblatt Kreisfreie Stadt Dresden",
      link: "https://www.dresden.de/de/rathaus/aktuelles/amtsblatt.php",
      thumbnail: "/images/info/amtsblatt_dresden.jpg",
    },
    {
      title: "Amtsblatt Kreisfreie Stadt Leipzig",
      link: "https://www.leipzig.de/buergerservice-und-verwaltung/stadtverwaltung/amtsblatt/#c226910",
      thumbnail: "/images/info/amtsblatt_leipzig.jpg",
    },
    {
      title: "Altenberger Bote",
      link: "https://www.rathaus-altenberg.de/altenberger-bote/",
      thumbnail: "/images/info/altenberger_bote.jpg",
    },
    {
      title: "Amtsberger Anzeiger",
      link: "https://amtsberg.eu/buergerinfo/?content=108&pkat=14",
      thumbnail: "/images/info/amtsberger_anzeiger.jpg",
    },
    {
      title: "Stadtkurier Zschopau",
      link: "https://www.zschopau.de/buergerservice/stadtkurier",
      thumbnail: "/images/info/amtsblatt_zschopau.jpg",
    },
  ];

  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/startup_unternehmen_tipps_und_hilfe_tradefoox.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Gemeinde- und Amtsblätter
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              Regionale & Überregionale Information
            </div>
          </div>
        </section>

        <article className="text-sm">
          <div className="font-semibold">
            TRADEFOOX - Der erste Schritt zum Erfolg!
          </div>
          <div className="grid grid-flow-row gap-1">
            Auf unserer Plattform findest Du die Kontakte, die Du brauchst. Gehe
            in Austausch mit anderen Start-ups und Innovatoren, finde Business
            Angels und Partner für Dein Unternehmen! Kommuniziere in unserem
            User Network deine Neuigkeiten und zeig Deine Innovation. Bilde
            Deine Start-up Community und vernetze Dich mit neuen B2B Kontakten.
            Ein Start-up zu haben bedeutet - Du bist bereits eine extra Meile
            gegangen und dafür danken wir Dir!
            <div />
            Das Team von tradefoox.com unterstützt Dich und bietet Dir unsere
            Onlinedienste komplett kostenlos an! Nimm Kontakt zu uns auf und
            erfahre wie wir Dich gezielt platzieren!
          </div>
        </article>
        <h3 className="font-semibold text-center">
          Amtsblätter der Landkreise
        </h3>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          {localNewspapers.map((newspaper: NewspaperType, index) => {
            return (
              <NavLink to={newspaper.link} key={index} target="_blank">
                <div className="relative group h-[225px] shadow-lg overflow-hidden">
                  <img
                    src={newspaper.thumbnail}
                    className="w-full h-[225px] object-cover group-hover:brightness-50 group-hover:scale-150"
                  />
                  <h2 className="text-xs font-semibold absolute top-2 text-center w-full break-words group-hover:text-gray-100 group-hover:font-semibold group-hover:text-base px-2">
                    {newspaper.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
        <h3 className="font-semibold text-center">Amts- und Gemeindeblätter</h3>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          {communityNewspapers.map((newspaper: NewspaperType, index) => {
            return (
              <NavLink to={newspaper.link} key={index} target="_blank">
                <div className="relative group h-[225px] shadow-lg overflow-hidden">
                  <img
                    src={newspaper.thumbnail}
                    className="w-full h-[225px] object-cover group-hover:brightness-50 group-hover:scale-150"
                  />
                  <h2 className="text-xs font-semibold absolute top-2 text-center w-full break-words group-hover:text-gray-100 group-hover:font-semibold group-hover:text-base px-2">
                    {newspaper.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Newspapers;
