import { EyeIcon } from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  name: string;
  link: string;
  picture: string;
  key?: string;
  imageStyle?: TwStyle;
  nameStyle?: TwStyle;
  views?: {
    icon?: ReactNode;
    count?: number;
    color?: TwStyle;
  };
};

const SuggestionsCenterItem = (props: Props) => (
  <div className="flex gap-4 w-fit">
    <NavLink to={props.link} className="relative">
      <div className="cursor-pointer relative grid grid-flow-row">
        <div
          css={[
            tw`w-[50px] h-[50px] rounded-full overflow-hidden rounded-full border border-gray-300 relative mx-auto`,
            props.imageStyle,
          ]}
        >
          <img
            src={props.picture}
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        <div
          css={[
            tw`text-xs text-center whitespace-nowrap text-gray-600 text-sm truncate leading-[50px]`,
            props.nameStyle,
          ]}
        >
          {props.name}
        </div>

        {props.views && (
          <div
            css={[
              tw`absolute bottom-1 right-1 flex items-center gap-1`,
              props.views.color,
            ]}
          >
            {props.views.count}
            {props.views.icon ?? <EyeIcon className="w-4 h-4" />}
          </div>
        )}
      </div>
    </NavLink>
  </div>
);

export default SuggestionsCenterItem;
