import React from "react";
import Panel from "@components/box/Panel";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const InfoContainer = () => {
  const navigate = useNavigate();

  return (
    <Panel mobileBorder={false}>
      <button onClick={() => navigate(-1)} className="lg:hidden">
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <div className="grid grid-flow-row text-xs gap-4">
        <div className="font-bold text-sm">Die Philosophie von TRADEFOOX</div>
        <p>
          Wir verstehen B2B nicht einfach nur als Begriff, sondern als Beziehung
          zwischen Menschen.
        </p>
        <p>
          Als Unternehmen, Freelancer, Auftraggeber oder Verein bekommst Du
          Deinen eigenen Marktplatz hier auf
          <b className="font-semibold">
            {" "}
            TRADEFOOX - und das völlig kostenfrei.{" "}
          </b>
          Wir erleichtern damit Deine tägliche Arbeit.
        </p>
        <p>
          Bei uns kannst Du als Firma, Verein oder Privatperson bequem Deine
          neusten Infos als Feed oder Kurzvideo posten, Aufträge vergeben oder
          finden, Jobangebote und Inserate schalten und vieles mehr. Aufwendiges
          recherchieren nach passenden Jobs, Projekten und geeigneten
          Dienstleistern auf endlos vielen Seiten ist damit Vergangenheit.
        </p>
        <p>
          Ein tolles Feature unserer Seite: Die Veröffentlichung Deines
          Werdegangs ermöglicht Dir, blitzschnell durch Recruiter und Headhunter
          gefunden zu werden.
        </p>
        <p>
          Ein weiterer Vorteil: Du kannst unseren unkomplizierten Marktplatz und
          das aktuelle Marktgeschehen mit geringem Zeitaufwand jeder Zeit im
          Blick behalten und somit schneller und effizienter neue
          Geschäftsbeziehungen knüpfen.
        </p>
        <p className="font-bold">
          Marktplatz + Jobbörse + Inserate + Newsfeed und Watch + Messenger =
          zahlreiche Möglichkeiten sich unter 5 Minuten schnell und effizient zu
          verknüpfen!
        </p>
        <p>
          Das Internet verbindet die gesamte Welt. Aus dieser einfachen
          Erkenntnis heraus leitet sich unsere Vision ab: Wir wollen der
          Anbieter mit der smartesten Plattform weltweit sein und alle Personen
          und Dienstleister vom Altenpfleger bis hin zum Zweiradmechatroniker
          vernetzen. Wir bringen für Dich qualifizierte Auftraggeber und
          Auftragnehmer zusammen, indem wir Dir eine Plattform bieten, die
          Personen, Aufträge und Firmen optimal auffindbar und sichtbar macht.
          Wir bieten Dir eine einfache Lösung, alles an einem Ort abzuwickeln.
          Hier kannst Du Dich für die Zukunft vernetzen.
        </p>
        <p>
          Wir arbeiten kontinuierlich daran, unsere Plattform für Dich so
          zuverlässig, aktuell und produktiv wie möglich zu gestalten. Daneben
          legen wir sehr großen Wert auf Sicherheit, einfache Bedienbarkeit,
          Transparenz und Qualität unseres Online-Marktplatzes. Diese Punkte
          haben bei TRADEFOOX besonderen Vorrang. Zudem bieten wir Dir
          erstklassigen Service mit einer persönlichen Beratung und Betreuung.
          Ihr könnt Euch rund um die Uhr in nur wenigen Klicks bei uns
          registrieren.
        </p>
        <p>
          Verpass also nicht die Chance, ein Teil unserer B2B-Netzwerk-Plattform
          der Zukunft zu werden.
        </p>
        <p className="grid grid-cols-3 gap-1">
          <img
            src="/images/info/tf-cover-1.jpg"
            className="w-full object-contain"
          />
          <img
            src="/images/info/tf-cover-2.jpg"
            className="w-full object-contain"
          />
          <img
            src="/images/info/tf-cover-3.jpg"
            className="w-full object-contain"
          />
        </p>
        <p className="font-bold">
          Entdecken, shoppen und verkaufen bei Tradefoox.com
        </p>
        <p>
          Unser Online Market ist ein Vertriebskanal, von dem Du stark
          profitieren kannst. Dein Erfolg als Online-Verkäufer ist uns sehr
          wichtig.
        </p>
        <p>
          Deshalb unterstützen wir Dich mit einer serviceorientierten
          Vertriebsplattform, persönlicher Beratung und professionellen
          E-Commerce-Partnern. Kaufen und verkaufen von Technik,
          Haushaltsgeräte, Kleidung, Autos, Werkezeuge, Reisen, Sportartikel,
          Software, Bücher, Lebensmittel, Gutscheine und vieles mehr. Eine große
          Auswahl an Produkten von verifizierten Verkäufern. Millionen von
          Produkten zu besten Preisen. Direkt von globalen Verkäufern kaufen.
        </p>
        <p>
          Vertraue einem starken Partner. Wir unterstützen Dich als Distributor
          auf dem Markt und versorgen Dich mit der Kraft unseres Netzwerks. Von
          Anfang an kannst Du Deinen persönlichen E-Commerce-Berater als
          Ansprechpartner verwenden.
        </p>
        <p>
          Du kannst neue, gebrauchte und generalüberholte Artikel auf unserem
          kostenlosen Online Market bewerben - von Kleidung über Elektronik bis
          hin zu Urlaubsreisen. Du kannst auch den ganzen Tag über völlig
          kostenlos an anderen Orten auf uns zurückgreifen, einfach inserieren
          und suchen!
        </p>
        <p>TRADEFOOX bietet eine Vielzahl an Tools und Möglichkeiten.</p>
        <p className="font-bold">
          Im folgendem findest du Tipps über unsere angebotenen
          Dienstleistungen. Ziel ist es, Dir stets alles aus einer Hand zu
          liefern – schnell und einfach!
        </p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-create-account.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>KONTOERSTELLUNG UND EINRICHTUNG</p>
        <p>
          Du verkaufst bisher noch nicht auf Tradefoox? Wir kümmern uns um die
          vollständige Einrichtung Deines neuen Firmen-Accounts.
        </p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-charts.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>LAGERBESTANDSMANAGEMENT</p>
        <p>Lieferfähigkeit ist für das A und O.</p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-action.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>KEYWORDOPTIMIERUNG</p>
        <p>
          Relevante Keywords sind von enormer Bedeutung für die Performance
          Deiner Produkte.
        </p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-mail.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>SEO</p>
        <p>
          Wer nicht gefunden wird, der kann auch nicht verkaufen. Wir erhöhen
          die Sichtbarkeit Deiner Produkte und verhelfen dir so zu mehr Umsatz.
        </p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-action.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>BEWERTUNGSMANAGEMENT</p>
        <p>
          Positive Produktrezensionen sind ein wichtiger Entscheidungsfaktor für
          die Kunden. Wir behalten die Rezensionen Deiner Produkte stets im
          Blick, um schnell auf eventuelle negative Kundenstimmen reagieren zu
          können.
        </p>
        <p className="flex justify-center">
          <img
            src="/images/info/tf-products.jpg"
            className="h-[200px] object-contain"
          />
        </p>
        <p>PRODUKTFOTOGRAFIE</p>
        <p>
          Gute, aussagekräftige Produktfotos sind extrem wichtig für den
          erfolgreichen Verkauf Deiner Produkte auf Tradefoox!
        </p>
        <p>Kostenlos anmelden, um verifizierte Lieferanten zu kontaktieren.</p>
        <p className="font-bold">
          Deutschlands größter Online-Marktplatz! Sicher und bequem online
          einkaufen und verkaufen.
        </p>
      </div>
    </Panel>
  );
};

export default InfoContainer;
