import React, { useState, useRef } from "react";
import LegacyLinkPreview from "@components/elements/LegacyLinkPreview";
import Schedule from "@components/elements/shared/Schedule";
import Checkbox from "@components/elements/input/Checkbox";
import Button from "@components/elements/input/Button";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import EmojiButton from "@components/elements/shared/buttons/EmojiButton";
import EmojiPicker from "emoji-picker-react";
import ScheduleButton from "@components/elements/shared/buttons/ScheduleButton";
import PostTextEdit from "@components/elements/posts/form/shared/PostTextEdit";
import regex from "@/utils/regex";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { formatDate } from "@/utils/formatDate";
import PostUserSelect from "@components/elements/posts/form/shared/PostUserSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PostMedia from "@components/elements/posts/form/shared/PostMedia";
import tw from "twin.macro";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import api from "@/api/api";

type Props = {
  onSuccess: () => void;
  messageText?: string;
  mediaList?: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
};

const SharePost = ({ onSuccess, messageText = "", mediaList = [] }: Props) => {
  const [link, setLink] = useState<string | undefined>();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [adult, setAdult] = useState(false);
  const [text, setText] = useState(messageText);
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >(mediaList);
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const links = regex.url.exec(text);
  if (links && links[0] && !link) {
    setLink(links[0]);
    setText((value) => value.replace(links[0], ""));
  }

  const send = useMutation(["post"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/posts", {
        text,
        adult,
        media: media.map((m) => m.id),
        link,
        set_active_at: scheduleDate,
        link_preview_id: linkPreviewId,
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Dein Beitrag konnte nicht erstellt werden."
          title="Feed"
        />
      );
    },
  });

  return (
    <div>
      <div css={[tw`grid grid-flow-row gap-2`, openSchedule && tw`hidden`]}>
        <div className="font-semibold">Im Newsfeed teilen</div>
        {scheduleDate && (
          <button
            className="flex flex-row gap-2 items-center text-xs hover:underline"
            onClick={() => {
              setOpenSchedule(true);
            }}
          >
            <CalendarDaysIcon className="w-4 h-4" />
            Wird veröffentlicht am{" "}
            {formatDate(new Date(scheduleDate).toISOString())}.
          </button>
        )}
        <PostUserSelect />
        <PostMedia
          onChange={(data) => setMedia((value) => [...value, data])}
          media={media}
          onDelete={(id) =>
            setMedia((data) => data.filter((item) => item.id !== id))
          }
        />
        {link && (
          <LegacyLinkPreview
            link={link}
            onChange={setLinkPreviewId}
            onDelete={() => {
              setLink(undefined);
              setLinkPreviewId(undefined);
            }}
          />
        )}
        <PostTextEdit value={text} onChange={setText} textRef={textareaRef} />
        <div className="flex flex-row gap-2">
          <Button
            variant={Button.Variants.Transparent}
            onClick={() => {
              if (textareaRef.current) {
                const oldSelectionStart = textareaRef.current.selectionStart;
                const newText =
                  textareaRef.current.value.slice(
                    0,
                    textareaRef.current.selectionStart
                  ) +
                  "@" +
                  textareaRef.current.value.slice(
                    textareaRef.current.selectionStart
                  );
                textareaRef.current.value = newText;
                setText(newText);
                textareaRef.current.focus();
                textareaRef.current.setSelectionRange(
                  oldSelectionStart + 1,
                  oldSelectionStart + 1
                );
              }
            }}
          >
            <div className="text-xs">@ Erwähnungen</div>
          </Button>
          <Button
            variant={Button.Variants.Transparent}
            onClick={() => {
              if (textareaRef.current) {
                const oldSelectionStart = textareaRef.current.selectionStart;
                const newText =
                  textareaRef.current.value.slice(
                    0,
                    textareaRef.current.selectionStart
                  ) +
                  "#" +
                  textareaRef.current.value.slice(
                    textareaRef.current.selectionStart
                  );
                textareaRef.current.value = newText;
                setText(newText);
                textareaRef.current.focus();
                textareaRef.current.setSelectionRange(
                  oldSelectionStart + 1,
                  oldSelectionStart + 1
                );
              }
            }}
          >
            <div className="text-xs"># Hashtags</div>
          </Button>
        </div>
        <div className="flex flex-row items-baseline justify-between">
          <Checkbox
            name="18+"
            checked={adult}
            onChange={(checked) => setAdult(checked)}
          >
            Ab 18
          </Checkbox>
          <div className="max-sm:hidden">
            <EmojiButton onClick={() => setEmojiOpen((value) => !value)} />
          </div>
          <ScheduleButton onClick={() => setOpenSchedule((value) => !value)} />

          <div className="w-fit">
            <Button onClick={send.mutate}>
              <PaperAirplaneIcon className="h-[20px]" />
            </Button>
          </div>
        </div>
        {emojiOpen && (
          <EmojiPicker
            onEmojiClick={(emoji) =>
              setText(
                (data) =>
                  data + String.fromCodePoint(parseInt(emoji.unified, 16))
              )
            }
          />
        )}
      </div>
      {openSchedule && (
        <Schedule
          onClose={() => setOpenSchedule(false)}
          onChange={(date) => setScheduleDate(date)}
          selectedDate={scheduleDate}
        />
      )}
    </div>
  );
};

export default SharePost;
