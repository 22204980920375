import React, { useState } from "react";
import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import PostsActionBarItem from "@/pages/news/posts/PostsActionBarItem";
import tw from "twin.macro";
import { UseMutationResult } from "@tanstack/react-query";

type Props = {
  liked: boolean;
  likeCount: number;
  like: UseMutationResult<any, unknown, void, unknown>;
  unlike: UseMutationResult<any, unknown, void, unknown>;
  label?: string;
};

const LikeButton = ({ liked, likeCount, label = "", like, unlike }: Props) => {
  const [likeEffect, setLikeEffect] = useState(false);

  return (
    <PostsActionBarItem
      onClick={() => (liked ? unlike.mutate() : like.mutate())}
      label={label}
      textColor={
        liked
          ? tw`text-pink-700`
          : tw`text-gray-600 transition-all ease-out duration-200 group-hover:text-pink-700`
      }
      color={liked ? tw`bg-pink-100` : tw`group-hover:bg-pink-100`}
      count={likeCount}
    >
      {liked ? (
        <HeartIconSolid className={`${likeEffect && "animate-ping-once"}`} />
      ) : (
        <HeartIcon
          onClick={() => {
            setLikeEffect(true);
          }}
          onAnimationEnd={() => {
            setLikeEffect(false);
          }}
        />
      )}
    </PostsActionBarItem>
  );
};

export default LikeButton;
