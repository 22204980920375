/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import LegacyLinkPreview from "@components/elements/LegacyLinkPreview";
import Checkbox from "@components/elements/input/Checkbox";
import Button from "@components/elements/input/Button";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import EmojiButton from "@components/elements/shared/buttons/EmojiButton";
import EmojiPicker from "emoji-picker-react";
import regex from "@/utils/regex";
import PostUserSelect from "@components/elements/posts/form/shared/PostUserSelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Post } from "@/utils/post";
import PopupWrapper from "@components/elements/PopupWrapper";
import WatchTextEdit from "@/pages/watch/WatchTextEdit";
import PostMediaWatch from "@/pages/watch/PostMediaWatch";
import api from "@/api/api";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  postId: string;
};
type PropsInner = {
  onSuccess: () => void;
  onClose: () => void;
  post: Post;
};

const EditWatchPostInner = ({ onSuccess, onClose, post }: PropsInner) => {
  const [link, setLink] = useState<string | undefined>(post.link);
  const [adult, setAdult] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [text, setText] = useState(post.text);
  const [emojiOpen, setEmojiOpen] = useState(false);

  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >(
    post.media &&
      post.media.map((m) => ({
        id: m.id,
        key: m.key,
        file_name: m.file_name,
        type: m.type,
        thumbnail: m.thumbnail,
      }))
  );
  const queryClient = useQueryClient();

  const links = regex.url.exec(text);
  if (links && links[0] && !link) {
    setLink(links[0]);
    setText((value) => value.replace(links[0], ""));
  }

  const send = useMutation(["post"], {
    mutationFn: async () => {
      const res = await api.patch("/api/v1/posts/" + post.id, {
        text,
        adult,
        media: media.map((m) => m.id),
        link_preview_id: linkPreviewId,
        link,
        watch: true,
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PopupWrapper onClose={onClose}>
      <div className="grid grid-flow-row gap-2">
        <div className="font-semibold text-base">Video bearbeiten</div>
        <PostUserSelect />
        <PostMediaWatch
          onChange={(data) => setMedia([data])}
          media={media ?? []}
          onDelete={(id) =>
            setMedia((data) => data.filter((item) => item.id !== id))
          }
        />
        {link && (
          <LegacyLinkPreview
            link={link}
            onChange={setLinkPreviewId}
            onDelete={() => {
              setLink(undefined);
              setLinkPreviewId(undefined);
            }}
          />
        )}

        <div className="flex flex-col gap-1 h-[300px]">
          <WatchTextEdit
            value={text}
            onChange={setText}
            videoUrl={
              media && media.length > 0
                ? "https://cdn.tradefoox.iaccam.com/" +
                  media[0].key +
                  "/" +
                  media[0].file_name
                : ""
            }
          />
        </div>

        <div className="flex flex-row items-baseline justify-between">
          <Checkbox
            name="18+"
            checked={adult}
            onChange={(checked) => setAdult(checked)}
          >
            Ab 18
          </Checkbox>
          <div className="max-sm:hidden">
            <EmojiButton onClick={() => setEmojiOpen((value) => !value)} />
          </div>

          <div className="w-fit">
            <Button onClick={send.mutate}>
              <PaperAirplaneIcon className="h-[20px]" />
            </Button>
          </div>
        </div>
        {emojiOpen && (
          <EmojiPicker
            onEmojiClick={(emoji) =>
              setText(
                (data) =>
                  data + String.fromCodePoint(parseInt(emoji.unified, 16))
              )
            }
          />
        )}
      </div>
    </PopupWrapper>
  );
};

const EditWatchPost = ({ onSuccess, postId, onClose }: Props) => {
  const {
    data: post,
    isLoading,
    error,
  } = useQuery<Post, Error>(["post", postId], async () => {
    const { data } = await api.get(`/api/v1/posts/${postId}`);
    return data;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !post) {
    return <div>Error fetching post</div>;
  }

  return (
    <EditWatchPostInner onSuccess={onSuccess} onClose={onClose} post={post} />
  );
};

export default EditWatchPost;
