import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Button from "@/components/elements/input/Button";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { JobType } from "@/utils/types/JobType";
import LegacyPost from "@pages/news/posts/LegacyPost";
import getName from "@/utils/getName";
import useUser from "@/api/user/useUser";
import ButtonsBar from "@/components/elements/form/ButtonsBar";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

const JobsDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  if (id === undefined) return <div>Job not found</div>;

  const job = useQuery<JobType, Error>([`job-${id}`], async () => {
    const res = await api.get(`/api/v1/jobs/${id}`);
    return res.data.data;
  });

  const user = useUser(job.data?.user_id ?? "");

  const iconbuttons = [
    {
      image: "/images/assignmentsfile.svg",
      tabname: "edit",
      label: "Bearbeiten",
    },
    {
      image: "/images/hashtag.svg",
      tabname: "hashtags",
      label: "Hashtags",
    },
    {
      image: "/images/rating.svg",
      tabname: "rating",
      label: "Bewertung",
    },
    {
      image: "/images/events.svg",
      tabname: "event",
      label: "Events",
    },
    {
      image: "/images/statistics.svg",
      tabname: "statistics",
      label: "Statistik",
    },
  ];
  const [textMinimized, setTextMinimized] = useState(true);
  const buttonsForm = (style: string) => {
    return (
      <div className={`${style}`}>
        <div className="lg:w-fit">
          <Button
            onClick={() => {
              console.log("Bewertungen");
            }}
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
          >
            Bewertungen
          </Button>
        </div>
        <div className="lg:w-fit">
          <Button
            onClick={() => {
              console.log("Veröffentlichen");
            }}
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
          >
            {t("buttons.publish")}
          </Button>
        </div>
        <div className="lg:w-fit">
          <Button
            onClick={() => {
              console.log("Bewerbung");
            }}
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
          >
            Jetzt Bewerben
          </Button>
        </div>
      </div>
    );
  };

  if (!job.isSuccess || !user.isSuccess) return <div>Loading...</div>;

  return (
    <div className="grid grid-flow-row gap-2">
      <div>
        <h1 className="text-lg font-semibold">{job.data.title}</h1>
        <div className="flex flex-row gap-1 items-center text-xs">
          <div>
            Stellenanzeige vom:{" "}
            {new Date(job.data.created_at).toLocaleDateString("de-DE")}
          </div>
          <div className="text-mini">(0 Sterne)</div>
        </div>
      </div>

      <div className="grid grid-flow-row lg:grid-cols-[1fr,250px] gap-4">
        {/* Main description */}
        <div className="flex flex-col gap-4">
          <div className="aspect-square flex justify-center items-center">
            <img src={job.data.user.avatar} />
          </div>
          <ButtonsBar
            likeButton={{
              likeUrl: "/api/v1/jobs/" + id + "/like",
              unlikeUrl: "/api/v1/jobs/" + id + "/unlike",
              liked: false,
              like_count: 0,
            }}
            viewButton={{ view_count: 0 }}
            chatButton={{
              chatUrl: "/api/v1/chats/create/" + user.data.id,
            }}
            shareButton={{
              title: "Profil",
              body: "https://www.tradefoox.com/jobs/" + id,
            }}
            itemId={id}
            itemType="job"
          />
        </div>

        {/* Sidebar */}
        <div className="flex flex-col gap-2">
          {/* Job info  */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col h-fit text-sm text-gray-800">
            <div className="font-semibold border-b pb-1.5 mb-1.5 border-gray-300 whitespace-nowrap">
              Allgemeines
            </div>
            <div className="flex flex-wrap gap-1">
              <div>Tätigkeit / Beruf:</div>
              {job.data.occupation}
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Frühster Beginn:</div>
              {new Date(job.data.start_date).toLocaleDateString("de-DE")}
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Sozialversicherungspflichtig:</div>
              {job.data.social_security_required ? "ja" : "nein"}
            </div>

            <div>
              <div>Angaben zur Bewerbung:</div>
              <div className="inline-flex gap-2">
                Art der Bewerbung:
                <div>{job.data.application_type}</div>
              </div>
            </div>
          </div>

          {/* Conditions */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col h-fit text-sm text-gray-800">
            <div className="font-semibold border-b pb-1.5 mb-1.5 border-gray-300 whitespace-nowrap">
              Konditionen des Stellenangebotes
            </div>
            <div className="flex flex-wrap gap-1">
              <div>Arbeitszeit:</div>
              {job.data.weekly_hours} h / Woche
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Entlohnung:</div>
              {job.data.compensation}
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Tarifvertrag:</div>
              {job.data.standard_wage ? "ja" : "nein"}
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Befristung</div>
              {job.data.limitation ? "ja" : "nein"}
            </div>

            <div className="flex flex-wrap gap-1">
              <div>Einsatzort</div>
              {job.data.working_place}
            </div>
          </div>

          <Button
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
          >
            <NavLink to={`/profile/${job.data.user_id}`} target="_blank">
              Zum Arbeitgeber
            </NavLink>
          </Button>
        </div>
      </div>
      <div>
        <div className="font-semibold text-sm">Beschreibung:</div>
        <div css={[tw`text-sm`, textMinimized && tw`line-clamp-5`]}>
          {job.data.old_id ? (
            <LegacyPost content={job.data.description} />
          ) : (
            job.data.description
          )}
        </div>
        <button
          className="flex flex-row justify-center items-center gap-2 text-sm border border-gray-500 rounded-full w-full mt-1"
          onClick={() => setTextMinimized(!textMinimized)}
        >
          {textMinimized ? "weiterlesen" : "weniger"}
          {textMinimized ? (
            <ChevronDownIcon className="h-4 w-4" />
          ) : (
            <ChevronUpIcon className="h-4 w-4" />
          )}
        </button>
      </div>

      {/* Contacts */}
      <div className="rounded-2xl border-gray-200 border px-2 py-1 flex flex-col text-sm h-fit">
        <div className="font-semibold border-b pb-1.5 mb-1.5 border-gray-300 whitespace-nowrap">
          Weitere Infos
        </div>
        <div className="flex flex-wrap gap-2 text-xs">
          {iconbuttons.map((button: any, index: number) => {
            return (
              <div key={index}>
                <img
                  src={button.image}
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] w-[63px] mx-auto"
                />
                <div className="text-center">{button?.label}</div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Contacts */}
      <div className="rounded-2xl border-gray-200 border px-2 py-1 flex flex-col text-sm h-fit">
        <div className="font-semibold border-b pb-1.5 mb-1.5 border-gray-300 whitespace-nowrap">
          Kontaktinformationen
        </div>
        <div className="flex flex-wrap gap-1">
          <div>{getName(user.data) || job.data.company}</div>
        </div>

        <div className="flex flex-wrap gap-1">{user.data?.email}</div>
      </div>

      {buttonsForm("flex flex-wrap gap-4 font-normal justify-end")}
    </div>
  );
};

export default JobsDetails;
