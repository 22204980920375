import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { FlagIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CommentType } from "@/utils/types/CommentType";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";

type Props = {
  comment: CommentType;
};

const CommentsDropdown = ({ comment }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();

  const deleteComment = useMutation(
    [`delete-${comment.id}-${user ? user.id : ""}`],
    {
      mutationFn: async () => {
        const res = await api.delete(`/api/v1/comments/${comment.id}`);
        return res.data;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["comments"] });
      },
      onError: () => {
        alert("Failed");
      },
    },
  );

  return (
    <>
      {/* {openEdit && (
        <EditComment
          onSuccess={() => setOpenEdit(false)}
          post={comment}
          onClose={() => setOpenEdit(false)}
        />
      )} */}
      <Menu
        as="div"
        className="lg:hidden group-hover/comment:inline-block text-left absolute top-3 right-3"
      >
        <div>
          <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {user && user.id === comment.author.id ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active
                        ? tw`bg-gray-100 text-gray-900`
                        : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => setOpenEdit(true)}
                  >
                    Bearbeiten
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
              </Menu.Item>
            ) : null}
            {user && user.id === comment.author.id ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active
                        ? tw`bg-gray-100 text-gray-900`
                        : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => deleteComment.mutate()}
                  >
                    Löschen
                    <TrashIcon className="w-4 h-4" />
                  </button>
                )}
              </Menu.Item>
            ) : null}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  Melden
                  <FlagIcon className="w-4 h-4" />
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default CommentsDropdown;
