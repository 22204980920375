import BackButton from "@/components/nav/BackButton";
import React, { ReactNode, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

type ArticleType = {
  title?: string;
  subTitle?: string;
  text: ReactNode;
  id: string;
  youtubeLinks?: Array<string>;
  headerImage: string;
  source?: string;
  imgSource?: string;
};

const IndustrialHistoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const articles: Array<ArticleType> = [
    {
      title: "Crimmitschau",
      subTitle: "Tuchfabriken und Spinnereien",
      text: (
        <>
          <p>
            Die Tuchfabrik Gebr. Pfau, ein außerordentliche Denkmal mit original
            erhaltenem Maschinenbestand aus über 100 Jahren Textilgeschichte,
            ermöglicht eine Zeitreise in den harten Arbeitsalltag in der
            Textilproduktion. Von der Wollflocke über das Spinnen, Weben und
            Walken bis zur Appretur sind alle Schritte der Tuchfabrikation
            nachvollziehbar und werden von fachkundigem Personal vorgeführt.
          </p>
          <p>
            1885 entstanden die ersten Fabrikgebäude der Tuchfabrik Gebr. Pfau.
            Bis 1972 blieb sie in Familienbesitz und gehörte dann zum VEB
            Volltuchwerke Crimmitschau. 1990 wurde die ehemalige Tuchfabrik
            Gebr. Pfau als Gesamtheit unter Denkmalschutz gestellt. In ihrer
            Größe und in ihrer Vollständigkeit von historischem Gebäude- und
            Maschinenbestand ist die Pfausche Fabrik in Mitteleuropa
            einzigartig.
          </p>
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/2ZY6VuxQPkM?controls=0",
        "https://www.youtube-nocookie.com/embed/6BfMfJ226dM?controls=0",
      ],
      id: "tuchfabrik_gebr_pfau_in_crimmitschau",
      headerImage: "/images/info/crimmitschau_tuchfabrik.jpg",
      source: "https://www.boom-sachsen.de/schauplaetze/textilboom/",
      imgSource: "www.crimmitschau.de",
    },
    {
      id: "veb_pentacon_dresden",
      title: "VEB Pentacon",
      subTitle: "Dresden",
      text: (
        <>
          Praktica - Da staunte die ganze Welt!
          <div />
          Praktica ist ursprünglich der Markenname für eine Baureihe von
          Spiegelreflexkameras des Herstellers Kamera-Werke
          Dresden-Niedersedlitz („KW“), der von Pentacon, Dresden-Striesen,
          übernommen wurde. Die in der DDR gefertigten Fotoapparate wurden auch
          ins Ausland exportiert und beispielsweise in Westdeutschland vom
          Versandhaus Quelle unter dem Markennamen RevueFlex verkauft.
          <div />
          Insgesamt wurden von 1948 bis 2001 etwa 9 Millionen Prakticas
          produziert.
          <div />
          Der Markenname Praktica war von der Treuhandanstalt 1991 nicht dem
          Erben des früheren Besitzers der Kamera-Werke Niedersedlitz, John H.
          Noble, zurückgegeben worden. Stattdessen wurde er dem
          Pentacon-Betriebsteil zugeschlagen, der heute zur Dresdner PENTACON
          GmbH Foto- und Feinwerktechnik gehört (ein Unternehmen der Jos.
          Schneider-Gruppe, Bad Kreuznach).
          <div />
          Über die Markenrechte war es 1994 zu einer gerichtlichen
          Auseinandersetzung zwischen Noble und der Schneider-Gruppe gekommen.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/6iCdWG5v47I?controls=0",
        "https://www.youtube-nocookie.com/embed/fLjU3LyeqAc?controls=0",
        "https://www.youtube-nocookie.com/embed/AnshosR0Nc0?controls=0",
      ],
      headerImage: "/images/info/pentagon-dresden.jpg",
      source: "Wikipedia",
      imgSource: "sachsen-fernsehen.de",
    },
    {
      id: "uranbergbau_im_erzgebirge",
      title: "Uranbergbau",
      subTitle: "im Erzgebirge",
      text: (
        <>
          Es war eines der größten Geheimnisse der DDR - der Uranabbau im
          Erzgebirge. Hier wurde das Material für die Atombomben der Sowjetunion
          gewonnen. Die Folgen waren zerstörte Landschaften und kranke Menschen.
          Bad Schlema etwa galt als Tal des Todes . 25 Jahre nach der Wende ist
          die kleine Gemeinde ein Kurbad.
          <div />
          Vor dem Hintergrund der amerikanischen Atombombenabwürfe beginnt ein
          fieberhaftes Ringen um Uran für eine Atombombe der Sowjetunion. Unter
          denkbar schlechten Bedingungen arbeiten Zehntausende in den ersten
          Nachkriegsjahren im sog. Objekt 1 (Johanngeorgenstädter Bergrevier).{" "}
          <div />
          Um 1950 werden ca. 80000 Mitarbeiter gezählt. Die Einwohnerzahl der
          Stadt steigt von 6500 im Jahre 1945 auf 42000 nach 1950 an. Der Frisch
          Glück Kunst- und Treibeschacht wird zum Schacht 1 des Unternehmens. Im
          Jahre 1949 besitzt die UdSSR eine eigene Atombombe.
          <div />
          Nach 1955 geht der Bergbau stark zurück und wird 1958 eingestellt. Bis
          dahin lieferte der Fastenberger Bergbau einige 10000 Tonnen Uranerze
          an die Sowjetunion mit einem Uraninhalt von ca. 3500 Tonnen.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/_aouDJLe_v0?controls=0",
        "https://www.youtube-nocookie.com/embed/ix0EgIuJT_4?controls=0",
      ],
      headerImage: "/images/info/wismut-schacht.webp",
    },
    {
      id: "veb_flugzeugwerke-dresden",
      title: "VEB Flugzeugwerke",
      subTitle: "Dresden",
      text: (
        <>
          1955 war das Startjahr für den Flugzeugbau in Dresden: Die
          Flugzeugwerke Dresden wurden gegründet. Brunolf Baade führte
          international anerkannte Experten aus Unternehmen der ehemaligen
          Reichsluftfahrtindustrie wie Junkers, Heinkel, Siebel oder Arado in
          Dresden zusammen.
          <div />
          Innerhalb von drei Jahren entstand bis 1958 die Vereinigung
          Volkseigener Betriebe (VVB) Flugzeugbau mit einer
          Gesamtbelegschaftsstärke von 25.000 Mitarbeitern, darunter allein
          8.000 im Kernbetrieb der VVB, dem Flugzeugwerk Dresden. Die wichtigste
          Voraussetzung für diesen raschen Aufbau war das Potential an Fach- und
          Führungskräften aus dem ehemaligen Kriegsflugzeugbau.
          <div />
          So wurden Ende 1955 ca. 5.000 Beschäftigte als „Kader“ (Leiter,
          Ingenieure, Meister und Angestellte) eingestuft, von denen 24 % als
          „Flugzeugbauspezialisten“ ehemaligen Rüstungsbetrieben angehört
          hatten.
          <div />
          Der Einstieg in den Flugzeugbau erfolgte durch die Serienfertigung des
          sowjetischen Verkehrsflugzeugs Iljuschin Il-14P. Die zweimotorige
          Maschine bot 26 Passagieren und vier Besatzungsmitgliedern Platz. Bis
          Ende 1958 wurden 80 Il-14P gebaut.
          <div />
          Am 30. April 1958 rollte die 152 V1, die für eine Reichweite von 2000
          Kilometern, eine Geschwindigkeit von rund 800 km/h und 40 bis 60
          Fluggäste ausgelegt war, noch ohne Triebwerke aus der Montagehalle in
          Dresden. Dieses unter der Leitung von Baade entwickelte erste deutsche
          strahlgetriebene Verkehrsflugzeug entsprach bedingt durch die
          mindestens zweijährigen Verzögerungen im Gesamtprogramm schon nicht
          mehr den modernsten Anforderungen der damaligen Zeit.
          <div />
          Mit der Produktion der in Pirna aus Junkers-Vorläufermodellen
          entwickelten Pirna-014-Triebwerke waren die Industriewerke
          Ludwigsfelde 1957 beauftragt worden. Dort entstanden bis 1961
          insgesamt 51 Triebwerke. Bei dem zweiten Testflug des ersten Prototyps
          (152/I V1) am 4. März 1959 stürzte die Maschine in der Nähe von
          Ottendorf-Okrilla infolge Strömungsabrisses ab, wobei alle vier
          Insassen ums Leben kamen. Die Männer wurden in einer
          Gemeinschaftsgrabanlage auf dem Neuen Friedhof Klotzsche beigesetzt.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/_cURB_AEoR4?controls=0",
        "https://www.youtube-nocookie.com/embed/pw8TONtt4r4?controls=0",
        "https://www.youtube-nocookie.com/embed/7Kwj9hExvoA?controls=0",
      ],
      headerImage: "/images/info/flugzeugbau-dresden.jpg",
      source: "https://de.wikipedia.org/wiki/Elbe_Flugzeugwerke",
    },
    {
      id: "braunkohleabbau_leipzig_espenhain",
      title: "Industriegeschichte Braunkohleabbau",
      subTitle: "Leipzig und Espenhain",
      text: (
        <>
          Der Braunkohle-Abbau im Leipziger Umland zu DDR-Zeiten zwang viele
          Menschen zur Umsiedlung, und griff massiv in die damalige Landschaft -
          mit Auswirkungen bis heute - ein. Viele kleine Dörfer verschwanden
          damals, bzw. wurden einfach weggebaggert, darunter auch der kleine Ort
          Eythra. Alles, was der Braunkohle im Weg stand, wurde systematisch dem
          Erdboden gleich gemacht. Wie weit die Pläne der DDR damals wirklich
          gingen, und wie es in der Gegend heute aussieht, zeigen diese Videos.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/nnGCZOKGWIk?controls=0",
        "https://www.youtube-nocookie.com/embed/QnSuWZapkHI?controls=0",
        "https://www.youtube-nocookie.com/embed/pho2kYKP0FA?controls=0",
        "https://www.youtube-nocookie.com/embed/sZFV9DH8jdw?controls=0",
      ],
      headerImage: "/images/info/braunkohleabbau-leipzig.jpg",
    },
    {
      id: "der_trabant",
      title: "Heimat in Zwickau",
      subTitle: "Antrieb aus Chemnitz",
      text: (
        <>
          Trabant heißt die ab 1958 in der DDR im VEB Automobilwerk Zwickau,
          später VEB Sachsenring Automobilwerke Zwickau, in Serie gebaute
          Pkw-Baureihe.
          <div />
          Zur Zeit seiner Einführung galt er mit Frontantrieb und quer
          eingebautem Motor als moderner Kleinwagen und ermöglichte neben dem
          Wartburg die Massenmotorisierung in der DDR. <div />
          <div />
          Während seiner langen Bauzeit wurde er nur im Detail weiterentwickelt
          und blieb in seiner Klasse alternativlos, sodass er in späteren Jahren
          letztlich die Erstarrung der DDR-Wirtschaft widerspiegelte. Kein PKW
          wurde länger als der Trabant mit Zweitaktmotor gebaut (bis 1990).
          <div />
          Relativ große Stückzahlen erreichte von 1964 bis 1991 insbesondere der
          Trabant P 601, der 1989/1990 zu einem Symbol der Wiedervereinigung
          Deutschlands wurde. Ähnlich wie der VW Käfer entwickelte sich der oft
          liebevoll „Trabi“, „Rennpappe“ oder „Trabbi“ genannte Wagen zu einem
          Kultfahrzeug mit umfangreichem Freundeskreis.
          <div />
          Bis in die frühen 1980er-Jahre war der Trabant auch in internationalen
          Rallye-Wettkämpfen erfolgreich. Viele Fahrzeuge wurden in die ČSSR,
          nach Polen und Ungarn exportiert.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/emoF0EFxjjA?controls=0",
      ],
      headerImage: "/images/info/trabant.jpg",
    },
    {
      id: "auto_union_ag_chemnitz",
      title: "Auto Union AG",
      subTitle: "Chemnitz",
      text: (
        <>
          Vier verschlungende Ringe
          <div />
          Die Auto Union AG, Chemnitz war der erste deutsche staatliche
          Automobilkonzern.
          <div />
          Er entstand aus der Fusion des Kleinwagen- und Motorradproduzenten
          Zschopauer Motorenwerke J. S. Rasmussen (DKW) mit seiner
          Tochtergesellschaft Audiwerke AG Zwickau, der Horchwerke AG (ebenfalls
          Zwickau) und dem Automobilwerk Siegmar der Wanderer-Werke in Schönau
          bei Chemnitz.
          <div />
          Die Gründung erfolgte im Juni 1932 mit dem Eintrag in das
          Handelsregister Chemnitz. Die Hauptverwaltung war anfangs im
          DKW-Stammwerk Zschopau und nicht am Firmensitz in Chemnitz. Sie wurde
          erst 1936 in die umgebauten und erweiterten Gebäude der ehemaligen
          Chemnitzer Presto-Werke verlegt.
          <div />
          Das Firmenzeichen mit den verschlungenen Ringen symbolisierte den
          Zusammenschluss der vier Marken Audi, DKW, Horch und Wanderer, die
          jedoch eigenständig blieben. Allein die zwischen 1934 und 1939 bei
          Horch in Zwickau entwickelten Grand-Prix-Rennwagen trugen den Namen
          „Auto Union“; ein Pkw-Modell dieser Marke gab es bis 1958 (Auto Union
          1000) nicht.
          <div />
          Vor dem Zweiten Weltkrieg war der Auto-Union-Konzern zweitgrößter
          deutscher Automobilproduzent nach der Adam Opel AG.
          <div />
          Die Zschopauer Motorenwerke J. S. Rasmussen AG war 1928 mit 65.000
          produzierten Motorrädern größter Motorradhersteller der Welt. Im
          selben Jahr übernahm deren Inhaber Jørgen Skafte Rasmussen mit
          Krediten der Sächsischen Staatsbank die Aktienmehrheit der Audiwerke
          AG in Zwickau.
          <div />
          Im Zuge der Weltwirtschaftskrise geriet Rasmussens Konzern Anfang der
          1930er Jahre in eine angespannte Finanzlage. Die Sächsische
          Staatsbank, die ihrerseits seit 1929 mit 25 % an der Zschopauer
          Motorenwerke AG beteiligt war, sperrte sich weiteren Krediten.
          Rasmussen und Richard Bruhn, Vertrauensmann der Staatsbank,
          entwickelten daraufhin den Plan, die Zschopauer Motorenwerke mit ihrer
          Tochter Audi und der ebenfalls in Schieflage geratenen Horchwerke AG
          zu vereinigen.
          <div />
          Mit Gründung der Auto Union war als Unternehmenssitz Chemnitz
          festgelegt worden. Nur unter dieser Bedingung hatte sich die Stadt
          Chemnitz mit einem Aktienkapital von 750.000 Reichsmark an der
          Gesellschaft beteiligt.
          <div />
          Trotz Mahnungen war die Konzernverwaltung die ersten Jahre im
          DKW-Stammwerk in Zschopau untergebracht und wurde erst 1936 nach
          Chemnitz in das ehemalige Verwaltungsgebäude der Presto-Werke verlegt.
          Nach der Sächsischen Staatsbank (und damit dem sächsischen Staat) war
          die Stadt Chemnitz zweitgrößter Aktionär. Bei der Staatsbank befanden
          sich zunächst 75 % und bald 90 % des Aktienkapitals des neuen
          Konzerns. Vorstandsvorsitzender wurde Richard Bruhn von der
          Sächsischen Staatsbank.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/bv-VgzqDa4E?controls=0",
        "https://www.youtube-nocookie.com/embed/d5rHftpgWMk?controls=0",
        "https://www.youtube-nocookie.com/embed/YQonZODpzT8?controls=0",
        "https://www.youtube-nocookie.com/embed/1D0gZjEvZcU?controls=0",
      ],
      headerImage: "/images/info/auto_union.jpg",
      source: "https://de.wikipedia.org/wiki/Auto_Union",
    },
    {
      id: "eisenbahnmuseum_chemnitz",
      title: "Eisenbahnmuseum",
      subTitle: "Chemnitz",
      text: (
        <>
          Größtes noch funktionstüchtiges Dampflok-Bahnbetriebswerk Europas
          <div />
          Die drittegrößte Stadt Sachsens nach Dresden und Leipzig ist Chemnitz.
          <div />
          Kaum jemanden ist bekannt, dass in Chemnitz – auch als “Sächsisches
          Manchester” bezeichnet – die Wiege des Sächsischen Maschinenbaus
          stand. Richard Hartmann, dessen Geburtstag sich 2009 zum 200. Mal
          jährte, gründete in Chemnitz seine Maschinenbauanstalt, die sich zur
          größten sächsischen Lokomotivfabrik entwickelte.
          <div />
          Bereits 1848 wurde die “Glückauf” als erste in Chemnitz produzierte
          Dampflok getauft, der noch zahlreiche Lokomotiven folgen sollten, die
          nicht nur in Sachsen, sondern in vielen Teilen der Welt fuhren. Ebenso
          wenig bekannt ist, dass heute das größte voll funktionstüchtige
          Bahnbetriebswerk Europas in Chemnitz-Hilbersdorf zu finden ist.
          Bereits 1901 wurde das heutige Museum als Heizhaus der K.S.Sts.E.B.
          eröffnet, im wesentlich dem heute bestehenden Museum bereits sehr
          ähnlich.
          <div />
          Als Heizhaus wurden in Sachsen die Bahnbetriebswerke mit all ihren
          Einrichtungen zur Wartung von Lokomotiven bezeichnet. In den beiden
          großen Ringlokschuppen mit ihren 22 Ständen stehen derzeit 19
          Dampfloks und 10 Dieselloks verschiedenster Baureihen. Daneben sind
          auch alle für die Restaurierung notwendigen Einrichtung wie
          Kohlehochbunker oder Besandungsanlagen voll funktionsfähig.
          <div />
          Besonders lohnt sich der Besuch jeweils im August zum “Heizhausfest”,
          zu dem immer wieder tausende Eisenbahnfreunde das Museum besuchen.
          Darüber hinaus bietet der Verein das ganze Jahr über zahlreiche
          Sonderfahrten mit seiner Dampflok und dem Museumszug an. Unter den
          Sonderfahrten sind nicht nur die Lichtelfahrten durch das
          weihnachtliche Erzgebirge, sondern auch die Tagestouren zu anderen
          attraktiven Zielen in Sachsen und darüber hinaus zu empfehlen. Schauen
          Sie sich doch einmal im Museum um oder fahren sie mit dem Dampfzug.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/icp1QHXWv9o?controls=0",
        "https://www.youtube-nocookie.com/embed/9PnzVmZftiw?controls=0",
      ],
      headerImage: "/images/info/chemnitzer_eisenbahn_museum.jpg",
      source:
        "https://www.bahnnostalgie-deutschland.de/normalspur_eisenbahnmuseen/21/Schsisches_EisenbahnMuseum__ChemnitzHilbersdorf.html",
    },
    {
      id: "strassenbahnmuseum_chemnitz",
      title: "Industriegeschichte",
      subTitle: "Straßenbahnmuseum Chemnitz",
      text: (
        <>
          Folgt man der Industrieroute entlang der Zwickauer Straße, so findet
          sich im Grundstück mit der Nummer 164 eine alte Gleisanlage, die an
          die Epoche der Chemnitzer Schmalspurstraßenbahn erinnert. In einer
          Wagenhalle aus dem Jahr 1908 beherbergt das Straßenbahnmuseum Chemnitz
          Relikte aus einer längst vergangenen Zeit. Betreut wird das Museum
          ehrenamtlich vom Verein „RennpappeStraßenbahnfreunde Chemnitz e.V.“
          <div />
          In der Ausstellung erfahren Sie warum die Chemnitzer
          Schmalspurstraßenbahnen auf dem ungewöhnlichen Spurmaß von 925 mm
          fuhren, weshalb ein Engländer die erste Pferdebahnlinie der Stadt
          erbaute, wie eine Straßenbahn „funktioniert“ und noch vieles mehr
          <div />
          Die einmalige Spurweite von 915 mm erwies sich als unpraktisch und
          sollte auf die Regelspur von 1435 mm erweitert werden. Dies lehnte die
          Straßenbahngesellschaft aber ab und stimmte nur der sukzessiven
          Spurerweiterung auf 925 mm zu. Erst 1958 begann dann doch das
          Umspurungsprogramm der Straßenbahn.
          <div />
          So war Chemnitz die einzige Stadt die über eine lange Zeit von 30
          Jahre hinweg im städtische Nahverkehr mit zwei Spurweiten fuhr, denn
          erst 1988 wurde mit der Umstellung der letzten
          Schmalspurstraßenbahnlinie auf Omnibusbetrieb die Schmalspur
          Straßenbahn eingestellt.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/rhEqhA3Xm2A?controls=0",
      ],
      headerImage: "/images/info/strassenbahnmuseum_chemnitz.jpg",
      source: "http://www.strassenbahn-chemnitz.de/museum.html",
      imgSource: "https://www.sachsens-museen-entdecken.de/",
    },
    {
      id: "museum_für_sächsische_fahrzeuge_chemnitz",
      title: "Museum für sächsische",
      subTitle: "Fahrzeuge Chemnitz",
      text: (
        <>
          In den “Stern-Garagen”, einer der ältesten erhaltenen deutschen
          Hochgaragen, bietet sich der passende historische Rahmen für rund 200
          Exponate von mehr als 70 Herstellern. Auf 1.000 Quadratmetern
          präsentieren sich dem Besucher über 150 Automobile, Motorräder und
          Fahrräder. In chronologischen Themenboxen finden Sie viele einmalige
          Zeugnisse der sächsischen Fahrzeugbaugeschichte zwischen dem späten
          19. Jahrhundert und heute. Renn- und Geländesportfahrzeuge sind ebenso
          zu erleben, wie eine zeitgenössische Werkstattszene und wechselnde
          Sonderausstellungen.
          <div />
          Nach dem Zweiten Weltkrieg behielt nur noch eine Etage ihre
          ursprüngliche Bestimmung. Dort waren bis Ende der 1980er Jahre die
          Autos der Fahrbereitschaft des Rates des Bezirks Karl-Marx-Stadt
          geparkt. Das übrige Haus wurde als Lagerfläche genutzt. Bis 1990
          lagerte und kommissionierte die Handelsgesellschaft GHG hier
          überwiegend Plasterzeugnisse für den gesamten Bezirk Karl-Marx-Stadt.
          Seit Ende 2008 bildet das Erdgeschoß eine passende Unterkunft und
          Kulisse für die Fahrzeuge des Museums.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/TzxR80gcPqE?controls=0",
      ],
      headerImage: "/images/info/fahrzeugmuseum_chemnitz.jpg",
      source: "https://de.wikipedia.org/wiki/Stern-Garagen",
    },
    {
      id: "industriemuseum_chemnitz",
      title: "Industriemuseum Chemnitz...",
      subTitle: "...eine Zeitreise",
      text: (
        <>
          Der Maschinenbau boomt: Fabriken für Maschinen und Maschinenbau
          entstehen, in denen Textil-, Dampf-, Werkzeug- und andere Maschinen
          „Made in Sachsen“ gefertigt werden. Für die Gäste wird erlebbar, wie
          die Menschen im Laufe der Zeit Maschinen perfektionierten und wie der
          Maschinenboom ihren Alltag revolutionierte. Dabei wird auch der
          zunehmende Einfluss der Maschinen auf das menschliche Leben
          thematisiert.
          <div />
          Die Ausstellung nimmt die Besucherinnen und Besucher mit auf eine
          Reise zu den Stationen der sächsischen Maschinenbaukunst, von den
          ersten Maschinen aus der Pionierzeit bis zu zeitgenössischen Utopien
          „menschenleerer Werkhallen“ im Zeitalter der Industrie 4.0. Bereits im
          frühen 20. Jahrhundert präsentiert sich Sachsen mit ersten selbst
          entwickelten Maschinen auf Weltausstellungen und wird zum gefragten
          Exporteur.
          <div />
          Das Industriemuseum Chemnitz lädt seine Besucherinnen und Besucher ein
          zu einem Streifzug durch 220 Jahre sächsische Industriegeschichte. Wie
          sehr sich Sachsen von den Anfängen der Industrialisierung bis heute
          verändert hat, wird auch am hiesigen, Anfang des 20. Jahrhunderts
          erbauten, Gebäudekomplex deutlich.
          <div />
          Dieser diente bis 1982 als Gießerei- und Maschinenhalle der
          Werkzeugmaschinenfabrik Hermann und Alfred Escher AG und wurde von
          1999 bis 2002 aufwendig saniert und punktuell ergänzt; ein besonders
          geeigneter Ort, um Industriegeschichte zu erleben.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/9vD4Hv4FiAI?controls=0",
        "https://www.youtube-nocookie.com/embed/ZAcldqP7A-M?controls=0",
      ],
      headerImage: "/images/info/industriemuseum-chemnitz.jpg",
    },
    {
      id: "fritz_heckert_chemnitz",
      title: "Fritz Heckert Gebiet",
      subTitle: "Chemnitz",
      text: (
        <>
          Der Name „Fritz Heckert“ steht auch heute noch für ein gigantisches
          Neubauprojekt in der ehemaligen DDR. Am 5. Oktober 1974 wurde der
          Grundstein für das zeitweise zweitgrößte Baugebiet der DDR gelegt, in
          dem noch 1990 rund 90.000 Chemnitzer wohnten (von 1953 bis 1990
          Karl-Marx-Stadt).
          <div />
          Das riesige Baugebiet umfasste acht „Sektoren“ mit 37.000 Wohnungen.
          Diese Wohnungen waren bei der Bevölkerung sehr begehrt, da sie als
          modern und kostengünstig galten, außerdem verfügte jede Wohnung über
          eine Fernheizung und einem WC – was damals schon als „Luxus“ galt.
          <div />
          Am 5. Mai 1978 besuchte Erich Honecker das Großprojekt und lobte das
          Musterbeispiel des sozialistischen Wohnungsbaus.Nach der Wende zogen
          viele Familien aus der „Platte“ sodass bis 2009 fast ein Drittel
          abgerissen wurde.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/YzkLcfKb5Uk?controls=0",
      ],
      headerImage: "/images/info/fritz_heckert_chemnitz.jpg",
    },
    {
      id: "bmw",
      title: "Die Entstehungsgeschichte",
      subTitle: "von BMW Chemnitz und Berlin",
      text: (
        <>
          Sicherlich sah Karl Rapp zur Gründung seiner Motorenwerke GmbH im Jahr
          1913 nicht, welchen Stein er damit ins Rollen bringen würde. Die Rapp
          Motorenwerke GmbH, welche von Philipp Dörhöfer (Berlin) und Schneeweis
          (Chemnitz) sind, waren die Vorgänger der heutigen BMW Fahrzeuge.
          <div />
          Schneeweis konnte jahrelange Erfahrung im Bau für Flugmotoren für den
          Luftschiffbau Veeh GmbH vorweisen, was sich Dörhöfer zu Nutze machte
          und die Firma Schneeweis übernahm. 1917 änderten die Rapp Motorenwerke
          ihren Namen in BMW GmbH und ein Jahr später in BMW AG.
          <div />
          Der damals erste Geschäftsführer der Firma war Franz Josef Popp, der
          dem Unternehmen bis 1942 die Treue hielt. In dem aufstrebenden
          Unternehmen erreichte der Ingenieur Max Fritz mit seinen innovativen
          Ideen schnell großes Ansehen.
          <div />
          Sein 1917 entwickelter Flugmotor BMW IIIa mit Überdichtung,
          verringerte den Leistungsverlust in der Höhe, sodass diese sich für
          das Jagdflugzeug Fokker D.VII bewährte. So sehr, dass BMW über 2.000
          Aufträge für den Flugmotor umsetzen konnte.
          <div />
          Nur wenige Jahre später, am 17. Juni 1919, erzielte die
          Weiterentwicklung des Flugmotors - BMW IV - einen inoffiziellen
          Höhenweltrekord von 9.760m. Doch die Freude währte nicht lange, denn
          mit Ende des ersten Weltkrieges und dem Versailler Vertrag schien das
          Aus für BWM nah zu sein.
          <div />
          Der Friedensvertrag verbot das Herstellen von Flugmotoren in
          Deutschland für 5 Jahre. Da Flugmotoren allerdings das damals einzige
          Produkt waren, folgte BMW dem Verbot nicht.
          <div />
          1922 kehrte der Hauptaktionär Camillo Castiglioni dem Unternehmen den
          Rücken, um zu den Bayrischen Flugzeugwerken (BFW) zu wechseln. Mit ihm
          gingen auch die Namensrechte von BMW.
          <div />
          Die Bayrischen Flugzeugwerke bildeten sich aus dem
          Gustav-Otto-Flugzeugwerk. Die Fusion von BFW und BMW am 7.März 1916
          gilt in der offiziellen Unternehmensgeschichtsschreibung als
          Gründungsdatum von BMW.
          <div />
          1923 entwickelten Max Fritz und Martin Stolle das erste BMW-Motorrad -
          die R³², welche den Grundstein für eine neue Produktionslinie setzte.
          Der Ingenieur Max Fritz brauchte lediglich 5 Wochen für den Entwurf,
          dessen Grundprinzip noch bis heute das Gleich ist: Boxermotor und
          Kardantrieb im Doppelrohrrahmen.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/tybxjI0Vx_Q?controls=0",
      ],
      headerImage: "/images/info/bwm_header.jpg",
    },
    {
      id: "chemnitz_nach_dem_zweiten_weltkrieg",
      title: "Chemnitz nach dem zweiten Weltkrieg",
      text: (
        <>
          Bis zum zweiten Weltkrieg galt Chemnitz als der bekannteste Standort
          des deutschen Werkzeugmaschinenbaus. Die Verbindung von Maschinenbau,
          Produktion von Konsumgütern und spezialisierten Zulieferbetrieben
          wurde bereits damals schon erkannt und ist auch noch bis heute für die
          komplette Region prägend.
          <div />
          Durch den zweiten Weltkrieg erfolgte in fast allen Industriebetrieben
          die Umstellung auf die Rüstungsproduktion. Die folgende Demontage und
          Enteignungen nach dem Krieg rissen tiefe Wunden in die Chemnitzer
          Wirtschaftskraft.
          <div />
          Die Folge war, dass viele regionale Unternehmen in ihrer Innovation
          und Wettbewerbsfähigkeit enorm beeinträchtigt waren. Trotz alledem
          entwickelte sich die Region zu einem der führenden Industriezentren in
          der damaligen DDR.
          <div />
          In den Bereichen Werkzeug-, Fahrzeug- und Maschinenbau sowie in der
          Textilindustrie haben sich viele Unternehmen der drittgrößten
          sächsischen Stadt umstrukturiert, sodass sich Chemnitz zu den
          führenden Technologie- und Industriestandorten in Deutschland zählen
          darf.
          <div />
          Diverse kleine und mittelständische Unternehmen investieren in die
          Forschung, arbeiten an neuen Innovationen und besitzen eigene Patente.
          Durch die technische Universität sowie das Fraunhofer Institut werden
          optimale Rahmenbedingungen für Unternehmen geschaffen.
        </>
      ),
      headerImage: "/images/info/tu_chemnitz.jpg",
    },
    {
      id: "lutherkirche_chemnitz",
      title: "Lutherkirche",
      subTitle: "in Chemnitz",
      text: (
        <>
          Die Geschichte der Lutherkirche beginnt früher als das Bauwerk selbst
          - nämlich in der Johannisparochie. Durch die industrielle Entwicklung
          der damaligen Zeit wuchs die Stadt Chemnitz rasant und dehnte sich
          nach Süden aus. Um alle Gläubigen versorgen zu können, wurde das
          Gebiet der Johannisgemeinde bald zu klein. Durch eine
          Gemeindeneugründung entstand am 01.Januar 1900 die Luthergemeinde.
          <div />
          Zunächst musste man sich mit einem bescheidenen Gemeindehaus an der
          Senefelder Straße unmittelbar am Kirchbauplatz begnügen. Dass
          Pfarrhaus hatte einen integrierten Betsaal, platze jedoch fast aus
          allen Nähten. Die Planung eines Neubaus ab der Zschopauer Straße wurde
          immer dringlicher.
          <div />
          Die ersten Schritte zum Bau der Kirche wurden 1902 unternommen. Die
          Maßgabe war, dass die neue Lutherkirche hervorragend ins Stadtbild
          passen sollte. Luthers markanter und kraftvoller Charakter sollte 32 m
          über dem Marktplatz zum Ausdruck kommen. Kurzerhand wurde ein
          Architektenwettbewerb veranlasst, bei dem 130 Entwürfe eingingen.
          <div />
          Unter den eingegangenen Entwürfen gewann der der Entwurf von Prof.
          Otto Kuhlmann aus Charlottenburg. Der erste Spatenstich war am 10.
          Oktober 1905. Am 06. Mai 1906 war Grundsteinlegung und schon am 1.
          April 1908 war der Kirchweihtag. Bis heute steht die Lutherkirche
          mächtig und dominant auf der Zschopauer Straße.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/8YSrob9Nq7k?controls=0",
      ],
      headerImage: "/images/info/lutherkirche_chemnitz.jpg",
    },
    {
      id: "flughafen_chemnitz",
      title: "Flughafenbetrieb",
      subTitle: "in Chemnitz",
      text: (
        <>
          Chemnitz hatte vor vielen Jahren einen Flughafen mit regem
          Verkehrsbetrieb. Lasst uns einen Blick in die Vergangenheit werfen: Im
          Jahr 1896 wurde der „Chemnitzer Verein für Luftfahrt“ gegründet –
          übrigens der erste seiner Art. Nur wenige Jahre später – im Jahr 1911
          – war der erste Flugbetrieb auf dem alten Flughafen an der Zschopauer
          Straße zu verzeichnen.
          <div />
          In den Jahren 1925/26 wurde ein neuer Flughafen für Inlandsflüge an
          der Stollberger Straße erbaut. Zur feierlichen Einweihung im Mai 1926
          taufte der Chemnitzer Verein für Luftfahrt ein eigenes Flugzeug auf
          den Namen „Chemnitz“.
          <div />
          Nach feierlichen Reden des damaligen Bürgermeisters Walter Arlart
          zeigten Piloten mit diversen Showeinlagen ihr Können. Der absolute
          Höhepunkt war dabei ein Flugzeugrennen, welches der Pilot von
          Manteuffel für sich entschied.
          <div />
          Landung der Junkers G 38
          <div />
          Im Oktober 1932 sorgte die Landung des damals weltweit größten
          Landflugzeug, die viermotorige Junkers G 38 für großes Aufsehen. Die
          Maschine führte diverse Werbeflüge im damaligen deutschen Reich durch
          – auch in der Stadt Chemnitz. Nach einem 4-tägigem Aufenthalt startete
          die G 38 weiter nach Berlin.
          <div />
          Der Höhepunkt
          <div />
          Durch die relativ schlechte Beschaffenheit der Start- und Landebahn
          war der Flugverkehr vorerst nur in den Frühlings- und Sommermonaten
          möglich. Das tat dem Flugverkehr jedoch keinen Abbruch. Seinen
          Höhepunkt fand der Flughafen im Jahr 1930 mit 4.806 Starts und
          Landungen und einer Beförderung von 9.783 Fluggästen.
          <div />
          Am 16.November 1930 landete das erste und zugleich letzte Mal ein
          Großluftschiff auf dem Chemnitzer Flughafen. Der legendäre Graf
          Zeppelin, der unter anderem durch die Arktisfahrt und der Weltfahrt
          berühmt wurde.
          <div />
          Die Schließung
          <div />
          Nach den 1930er Jahren wurden die Flugzeuge schneller und größer.
          Kurzstrecken zwischen Zwickau und Chemnitz wurden nicht mehr beflogen.
          <div />
          Der Flughafen in der Stollberger Straße wurde für den regulären
          Flugverkehr zu klein, sodass nur noch Maschinen für Rundflüge sowie
          Landwirtschafts- und Segelflugzeuge starteten.
          <div />
          In der DDR diente der Flugplatz nur noch der Gesellschaft für Sport
          und Technik. 1970 wurde der Flughafen ins 10 km weit entferne
          Jahnsdorf verlagert, wo er heute noch als kleiner Verkehrslandeplatz
          in Betrieb ist.
          <div />
          Das Empfangsgebäude in der Stollberger Straße wurde vom Abriss
          verschont und steht nun mitten im Gelände Plattenbausiedlung
          „Fritz-Heckert“.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/5CYiTMAwiBY?controls=0",
        "https://www.youtube-nocookie.com/embed/ss1CZjPTYPg?controls=0",
        "https://www.youtube-nocookie.com/embed/QZgvBPtVLSE?controls=0",
        "https://www.youtube-nocookie.com/embed/Y8oFDFSi0F8?controls=0",
      ],
      headerImage: "/images/info/flughafen_chemnitz.jpg",
    },
    {
      id: "roter_turm_chemnitz",
      title: "Roter Turm",
      subTitle: "Chemnitz",
      text: (
        <>
          Den Roten Turm kann man als DAS Wahrzeichen von Chemnitz betrachten,
          denn es ist das älteste erhaltene Bauwerk und einer der wenigen
          Zeitzeugen der Stadt. Genau genommen ist das Gebäude älter als die
          Stadt selbst. Als in den Jahren nach 1100 die Bauern zur
          Landbesiedlung Richtung Osten zogen, entstanden in um und Chemnitz
          viele Siedlungen, welche überwacht, kontrolliert und geschützt werden
          mussten. Ein Bergfried musste her – die Geburtsstunde des heutige
          Roten Turms.
          <div />
          Porphyrtuff aus dem Zeisigwald
          <div />
          Als Baumaterial wurde das Chemnitzer Porphyrtuff verwendet. Das
          Gestein führt zur dominanten roten Farbe und war über Jahrhunderte das
          Hauptbaumaterial in der Stadt und deren Region. Aus diesem Grund
          zeigen sich die ältesten Gebäuden von Chemnitz in der roten Farbe.
          <div />
          Ende des 15. Jahrhunderts erhielt der rote Turm ein
          Backstein-Geschoss, welches mit roten Dachziegeln eingedeckt war.
          Heute sieht es so aus, als sei das Geschoss aufgesetzt.
          <div />
          Geschichtsträchtige Sehenswürdigkeit
          <div />
          Bis etwa 1900 wurde das Objekt als Gefängnis genutzt – Insassen waren
          unter anderem der Politiker August Bebel und der Lebenskünstler Karl
          Stülpner. Als die Chemnitzer Stadtbefestigungsanlagen abgetragen
          wurden, sollte auch der Rote Turm abgerissen werden. Die Funktion als
          Gefängnis rettete das Bauwerk allerdings vor dem Abriss.
          <div />
          Durch die Neubebauung der Theaterstraße am Ende des 19. Jahrhunderts
          verschwand das Gebäude hinter einer Häuserzeile. Vom bis dahin
          freistehenden Turm war somit nur noch die Turmhaube zu sehen.
          <div />
          Bei einem der Luftangriffe auf Chemnitz am Ende des Zweiten Weltkriegs
          brannte der Turm vollständig aus. Danach wurde er zwischen 1957 und
          1959 wiederaufgebaut und gilt seither als einer der
          geschichtsträchtige Sehenswürdigkeiten der Stadt.
          <div />
          Übrigens: Der Rote Turm ist ein heimlicher Star. Mitte der 60er Jahre
          war das Gebäude die Vorlage der klassischen 500-ml-Flasche des
          ostdeutschen Geschirrspülmittels fit. Aber nicht nur die VEB
          Fettchemie Karl-Marx-Stadt nahm sich das Gebäude zum Vorbild, denn
          seit September 2007 ist der Turm auf dem Spielbrett der
          Deutschlandausgabe von Monopoly zu sehen.
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/Sg0vGpgDAkY?controls=0",
      ],
      headerImage: "/images/info/roter_turm_chemnitz.jpg",
    },
    {
      id: "von_der_industriestadt_zur_stadt_der_moderne",
      title: "Chemnitz - von der Industriestadt",
      subTitle: "zur Stadt der Moderne",
      text: (
        <>
          Warum wir uns in Chemnitz niedergelassen haben? Ganz einfach: Die
          Stadt Chemnitz ist eine kreisfreie Stadt im Südwesten des Freistaates
          Sachsen und nach Leipzig und Dresden die drittgrößte Stadt im
          Bundesland. Der Namensgeber ist der gleichnamige Fluss Chemnitz (aus
          dem sorbischen: Kamenica = Steinbach).
          <div />
          Chemnitz greift auf mehr als 200 Jahre alte Industriegeschichte
          zurück. Bereits seit dem 16. Jahrhundert wird die Stadt mit dem
          Bergbau und der Textilherstellung verbunden. Seit dem 19. Jahrhundert
          zählt die Stadt zum Zentrum des deutschen Maschinenbaus.
          <div />
          Seine Entwicklung zur heutigen „Stadt der Moderne“ hat die Stadt
          Chemnitz der globalisierten Dynamik zu verdanken. Boomländer wie
          China, Indien und Russland produzieren mit Industrieanlagen, die aus
          den westlichen Ländern kommen - vorzugsweise von renommierten
          Anlagenbauern des Qualitätsstandorts Deutschland.
          <div />
          Viele Chemnitzer Unternehmen profitieren von der Mischung aus
          Modernität und Geschichte. Durch die praxisorientierte Arbeit mit
          Industrie, der TU Chemnitz und dem Fraunhofer-Institut entsteht ein
          Globalisierungsgebot mit regionaler Verwurzelung, welche hierzulande
          einzigartig ist. Zwar beliefern Chemnitzer Unternehmen die ganze Welt
          mit Anlagen, Autoteilen und Mikrosystemtechnik, aber die
          Globalisierung könnte nicht so gut gemeistert werden, wenn die
          unmittelbare Nachbarschaft nicht so gut miteinander vernetzt wäre.
          <div />
          Man kennt sich: Kurze Wege und bewährte Partnerschaften. Ein weiterer
          Pluspunkt wäre es, wenn die Chemnitz schiffbar wäre. Allerdings ist
          der Fluss, ein Nebenfluss der Zwickauer Mulde, nur ein Rinnsal, über
          das man an Sommertagen trockenen Fußes laufen kann.
          <div />
          Zurück zur Geschichte: Bereits im Jahr 1835 wurden Dampfmaschinen
          hergestellt. Wenige Jahre später (1844) dann Dampflokomotiven. Durch
          die rasante Entwicklung im Bereich des Fahrzeugbaus befanden sich
          bereits 1850 mehr als 60 Prozent aller sächsischen Maschinenfabriken
          in der sächsischen Stadt. Der Einsatz von Dampfkraft forderte eine
          dramatische Veränderung des positiven Ansehens der Stadt.
          <div />
          Aufgrund der hohen Anzahl von Industrieschornsteinen und der damit
          einhergehenden Schmutz- und Rauchentwicklung vergab man den weniger
          attraktiven Beinamen „Sächsisches-Manchester“. Trotz alledem erhielt
          die Stadt 1852 einen Eisenbahnanschluss. Durch die Bahnstrecke
          Chemnitz-Riesa galt Chemnitz als eine Industriestadt mit Weltrang.
          <div />
          Was der niedersächsische Industrielle und Erfinder Werner von Siemens
          mit der sächsischen Metropole gemein hat, erfahren Sie [hier]
        </>
      ),
      youtubeLinks: [
        "https://www.youtube-nocookie.com/embed/kFIp_78OqU4?controls=0",
        "https://www.youtube-nocookie.com/embed/4I0UGtnJnGo?controls=0",
        "https://www.youtube-nocookie.com/embed/qAM8O6xAjyU?controls=0",
        "https://www.youtube-nocookie.com/embed/Y-Jdsgxq-9A?controls=0",
      ],
      headerImage: "/images/info/chemnitz_stadt_der_moderne.jpg",
      imgSource: "TU-Chemnitz",
    },
  ];

  const article = articles.find((item) => item.id === id);

  useEffect(() => {
    if (!article) navigate("/news");
  }, [article, navigate]);

  if (!article) return <></>;

  return (
    <div className="grid grid-flow-row gap-6 text-gray-700">
      <BackButton />
      <section className="relative">
        <img
          src={article.headerImage}
          className="h-[300px] w-full object-cover"
        />

        <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
          {article.title && (
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl whitespace-nowrap">
              {article.title}
            </div>
          )}
          {article.subTitle && (
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              {article.subTitle}
            </div>
          )}
        </div>
      </section>

      <article className="flex flex-col gap-4 text-sm">
        <div className="flex flex-col gap-1.5">
          {article.text}

          <div className="text-xs">
            {article.source && (
              <div className="flex">Quelle: {article.source}</div>
            )}
            {article.imgSource && (
              <div className="flex">Bildquelle: {article.imgSource}</div>
            )}
          </div>
        </div>
        {article.youtubeLinks && (
          <div className="flex flex-wrap gap-2">
            {article.youtubeLinks.map((src: string, index) => {
              return (
                <iframe
                  key={index}
                  src={src}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  width="100%"
                  height="100px"
                  className="w-[calc(33%-4px)]"
                />
              );
            })}
          </div>
        )}
      </article>
    </div>
  );
};

export default IndustrialHistoryDetails;
