import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import api from "@/api/api";

export const useUser = (id: string) => {
  return useQuery<User, Error>(
    ["user", `user-${id}`],
    async () => {
      const res = await api.get(`/api/v1/users/${id}`);
      return res.data.data;
    },
    {
      retry: false,
    },
  );
};

export default useUser;
