import React from "react";
import getName from "@/utils/getName";
import UserWidget from "@components/elements/UserWidget";
import { ChatType } from "@/utils/types/ChatType";
import { User } from "@/utils/user";
import tw from "twin.macro";
import ChatDropdown from "@pages/messenger/ChatDropdown";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";
import EditModal from "@pages/messenger/EditGroupModal";

type Props = {
  chat: ChatType;
  onClick: () => void;
  onExit?: () => void;
  onChange: (checked: boolean) => void;
  showCheckbox?: boolean;
  key?: string;
  checked?: boolean;
  isSelected?: boolean;
};

export const getChatPartner = (members: Array<{ user: User }>, self: User) => {
  for (const member of members) {
    if (self.id !== member.user.id) {
      return member.user;
    }
  }
  return members[0].user;
};
const Messenger = ({
  chat,
  onClick,
  onChange,
  showCheckbox,
  onExit,
  checked,
  isSelected,
}: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const loginModal = useLoginModal();
  if (!user) {
    loginModal.open();
    return <></>;
  }

  // const name = getName(me.data);

  const getChatMeMember = (
    members: Array<{ user: User; archived: boolean; admin: boolean }>,
    self: User,
  ) => {
    for (const member of members) {
      if (self.id === member.user.id) {
        return member;
      }
    }
    return members[0];
  };

  const meMember = getChatMeMember(chat.members, user);

  const partner = getChatPartner(chat.members, user);
  const placeholder = "/images/placeholder/club.png";

  if (!partner) return <></>;

  return (
    <button
      css={[
        isSelected && tw`bg-gray-200`,
        tw`relative flex flex-row p-2 gap-2`,
      ]}
      className="group"
      onClick={() => onClick()}
    >
      <div
        css={[
          tw`items-center justify-center`,
          showCheckbox ? tw`flex` : tw`flex`,
          tw`absolute bottom-0 right-0 mr-3 mb-3`,
        ]}
      >
        <input
          id="chat-checkbox"
          name="chat-checkbox"
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
        />
      </div>
      <div className="my-auto">
        {chat.group ? (
          <div
            css={[
              tw`rounded-full border border-gray-300 relative bg-white shrink-0`,
            ]}
            style={{
              width: "45px",
              height: "45px",
            }}
          >
            <div
              css={[
                tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`,
              ]}
            >
              {chat.image ? (
                <img className="w-full" src={`${chat.image.data_url}/avatar`} />
              ) : (
                <img className="w-full" src={placeholder} />
              )}
            </div>
          </div>
        ) : (
          <UserWidget user={partner} follow={false} online={false} size={45} />
        )}
      </div>
      <div className="flex flex-col truncate mr-6">
        <div className="text-left truncate text-sm">
          {chat.group ? chat.name : getName(partner)}
        </div>
        <div className="text-xs line-clamp-1 text-left">
          {chat.last_message_text.startsWith("chat/media/")
            ? "Media"
            : chat.last_message_text}
        </div>
        <div className="text-xs text-left">{chat.created_at}</div>
      </div>
      <EditModal
        chatId={chat.id}
        name={chat.name}
        avatar={chat.image}
        members={chat.members.map((item) => item.user)}
        visible={showEditModal}
        closeModal={() => setShowEditModal(false)}
      />
    </button>
  );
};

export default Messenger;
