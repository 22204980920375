import React, { useState } from "react";
import { Icon } from "@/utils/types";
import { XMarkIcon } from "@heroicons/react/24/outline";

export type Valid = {
  valid: boolean;
  text?: string;
  icon?: Icon;
};

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  onChange?: (newValue: string) => void;
  type?: React.HTMLInputTypeAttribute;
  value?: string | number;
  disabled?: boolean;
  tooltip?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  title?: string;
  defaultValue?: string | number;
  clearable?: boolean;
  autoComplete?: string;
};

const Input = ({
  label,
  onChange,
  name,
  placeholder,
  type,
  value,
  disabled = false,
  tooltip,
  pattern,
  minLength,
  maxLength,
  required,
  title,
  defaultValue,
  clearable,
  autoComplete,
}: Props) => {
  const [internalState, setInternalState] = useState(value ?? "");

  return (
    <div className="relative mt-3">
      {label && (
        <label
          htmlFor={name}
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      {maxLength && (
        <label
          htmlFor={name}
          className="absolute -top-2 right-3 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {maxLength - (value?.toString()?.length ?? 0)}
        </label>
      )}
      <input
        disabled={disabled}
        name={name}
        id={name}
        type={type}
        autoComplete={autoComplete}
        onChange={(e) => {
          setInternalState(e.target.value);
          onChange && onChange(e.target.value);
        }}
        value={internalState}
        className="block w-full pr-8 rounded-2xl border border-gray-300 shadow-sm focus:border-darkblue focus:ring-darkblue invalid:focus:ring-tf-red-600 sm:text-sm py-2 px-3 outline-darkblue peer placeholder-gray-400"
        placeholder={placeholder}
        pattern={pattern}
        minLength={minLength}
        maxLength={maxLength}
        required={required}
        title={title}
        defaultValue={defaultValue}
      />

      {!!((value || internalState) && clearable) && (
        <button
          onClick={() => {
            setInternalState("");
            onChange && onChange("");
          }}
          className="w-5 h-5 absolute right-0 -translate-x-1/2 top-0 translate-y-1/2"
        >
          <XMarkIcon />
        </button>
      )}
      {tooltip && (
        <div className="absolute border border-gray-500 rounded-md z-10 px-2 py-1 leading-3 -mt-1 ml-2 bg-gray-50 text-left text-xs hidden peer-focus:block ">
          {tooltip}
        </div>
      )}
    </div>
  );
};

export default Input;
