import React from "react";
import AvatarEditor from "react-avatar-editor";

type Props = {
  label?: string;
  value: string;
  name?: string;
  onChange?: (e: File) => void;
  formats?: string;
  multiple?: boolean;
  required?: boolean;
  borderRadius?: number;
};

const ImageUpload = ({
  label,
  onChange,
  name,
  value,
  multiple = false,
  required = false,
  formats = "image/*",
  borderRadius = 0,
}: Props) => {
  return (
    <div>
      {label && <div className="text-gray-600 text-sm">{label}</div>}
      <div className="grid grid-flow-row overflow-hidden border border-gray-600">
        <input
          type="file"
          name={name}
          accept={formats}
          onChange={(e) => {
            if (!e.target.files) return;
            if (onChange) onChange(e.target.files[0]);
          }}
          className="truncate text-left px-1 py-0.5 text-xs mb-0.5 border-b border-gray-400"
          multiple={multiple}
          required={required}
        />
        <div className="flex justify-center bg-black">
          <div>
            <AvatarEditor
              image={value}
              border={0}
              scale={1}
              borderRadius={borderRadius}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
