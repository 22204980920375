import React from "react";
import { Media } from "@/utils/types/Media";

type Props = {
  image?: Media;
  loading: boolean;
  onClick: () => void;
};

const AvatarEditElement = ({ image, loading, onClick }: Props) => (
  <div
    className="relative rounded-full bg-gray-100 h-32 w-32 mx-auto cursor-pointer border overflow-hidden group"
    onClick={onClick}
  >
    {!loading ? (
      image ? (
        <img src={`${image.data_url}/avatar`} />
      ) : (
        <img src="/images/placeholder/club.png" />
      )
    ) : (
      <div className="absolute top-0 left-0 w-full h-full bg-gray-500 animate-pulse"></div>
    )}
    <div className="bg-gray-500 absolute bottom-0 left-0 w-full h-12 opacity-0 group-hover:opacity-70 transition-opacity text-center leading-7">
      <span>Bearbeiten</span>
    </div>
  </div>
);

export default AvatarEditElement;
