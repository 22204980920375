import React from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { User } from "@/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import api from "@/api/api";

type Props = {
  user: User;
  me: User;
  size?: number;
};

const UserWidget = ({ user, me, ...props }: Props) => {
  const size = (props.size || 50) * 0.7 * 0.7;
  const queryClient = useQueryClient();

  const follow = useMutation([`follow-${me.id}-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/follow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PlusCircleIcon
      className="absolute mx-auto translate-y-1 left-0 right-0 text-red-600 bg-white font-bold rounded-full shadow-xl cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        follow.mutate();
      }}
      style={{
        width: `${size.toString()}px`,
        height: `${size.toString()}px`,
        bottom: `-${size * 0.35}px`,
      }}
    />
  );
};

export default UserWidget;
