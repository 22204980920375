import React, { ReactNode } from "react";
import Panel from "@components/box/Panel";

interface Props {
  children: ReactNode;
}

const AuthWrapper = ({ children }: Props) => (
  <Panel mobileBorder={false}>{children}</Panel>
);

export default AuthWrapper;
