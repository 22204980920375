import React from "react";
import BackButton from "@/components/nav/BackButton";

const MemberFAQ = () => {
  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Mitglieder - So geht&apos;s
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              TRADEFOOX
            </div>
          </div>
        </section>

        <article className="text-sm">
          <h1 className="font-semibold text-base mb-4">
            Info&apos;s für Mitglieder
          </h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-3">
              <div>
                <h2 className="font-semibold">
                  Wie erstelle ich ein Nutzer- oder Firmenprofil?
                </h2>
                Wie erstelle ich ein Nutzer- oder Firmenprofil? Einfach den
                Registrierungsprozess mit Pflichtangaben ausfüllen und im
                Anschluss die Registrierung mittels Doppel-Opt-in aus der E-Mail
                heraus bestätigen.
              </div>
              <div>
                <h2 className="font-semibold">Wie erstelle ich ein Beitrag?</h2>
                Wie erstelle ich ein Beitrag? Über das eigene Profil selbst via
                Beitragsfenster oder oberhalb des Newsfeeds findet sich die
                Gelegenheit Bilder zu teilen und einen Status zu
                veröffentlichen.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie Folge, Like und merke ich mir Beiträge und Anzeigen die
                  mir gefallen?
                </h2>
                Wie Folge, Like und merke ich mir Beiträge und Anzeigen die mir
                gefallen? Das funktioniert mittels unseres Abo-Buttons, einem
                animierten Herz-Icon und das merken über Lesezeichen Symbol.
                Diese Symbole findest du in vielen Bereichen unserer Plattform.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie kontaktiere ich Firmen und andere Nutzer ?
                </h2>
                Wie kontaktiere ich Firmen und andere Nutzer ? Über den
                Kontaktbereich und dem darin befindlichen Reiter der
                Kontaktsuche, das globale Menü mit Filter oder das
                Firmenverzeichnis besteht die Möglichkeit neue Kontakte zu
                finden. Im jeweiligen Profil ist ein Button um Nachrichten zu
                versenden.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie folge ich einem Nutzer- oder Firmenprofil?
                </h2>
                Wie folge ich einem Nutzer- oder Firmenprofil? Im aufgerufenem
                Profil und unseren Übersichtsseiten befindet sich der Button zum
                Folgen. Nach dem Abonnieren erhältst du zukünftig
                Benachrichtigungen über Neuigkeiten von diesem Profil!
              </div>
              <div>
                <h2 className="font-semibold">Wie erstelle ich Bewertungen?</h2>
                Wie erstelle ich Bewertungen? Es gibt die Möglichkeit ein
                Firmenprofil als Arbeitgeber zu bewerten oder eine
                Produktspezifische Bewertung eines Artikels im OnlineMarket zu
                verfassen. Produkte können im OnlineMarket bewertet werden, und
                als Arbeitgeber über das Firmenprofil. Es kann nur einmal in der
                jeweiligen Kategorie bewertet werden. Eine Bewertung beinhaltet
                ein Freitextfeld und eine kategorische Auswahl in 5 Sternen.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie funktioniert der Messenger?
                </h2>
                Wie funktioniert der Messenger? Aufrufbar über Top-Menü oder das
                linke Seitenmenü, danach folgt eine Übersicht der Kontakte und
                eingegangenen Nachrichten, Absender bzw. Empfänger anwählen,
                Chatbox rechts mit Nachrichtenfeld und der Möglichkeit Emoticons
                zu verwenden und Anhänge zu versenden.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie erstelle ich einen Werdegang?
                </h2>
                Wie erstelle ich einen Werdegang? Ein vollständig ausgefülltes
                Nutzerprofil erspart die halbe Arbeit. Beim aufrufen des
                JobMarkets über das Top-Menü oder linke Seiten-Menü -Job Market
                –erscheint eine Maske mit den Auswahloptionen für Arbeitnehmer-
                oder für Arbeitgeber. Nach der Auswahl für Arbeitnehmer kann die
                Option Werdegang veröffentlichen links im Menü angewählt werden.
                Die erscheinende Übersicht kannst du nun überprüfen,
                vervollständigen und veröffentlichen.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie erstellen private Nutzer Auftragsangebote/Gesuche?
                </h2>
                Wie erstellen private Nutzer Auftragsangebote/Gesuche? Über das
                Top-Menü oder linke Seitenmenü den Bereich Auftragsplattform
                aufrufen, anschließend links im Menü, unter dem Suchbereich
                finden sich die Optionen Aufträge erstellen und Aufträge
                verwalten, nach dem erstellen und hinzufügen der Auftragsdetails
                den Status auf öffentlich setzen und speichern. Der Auftrag oder
                das Gesuch ist nun veröffentlicht.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie empfehle ich etwas - Jobangebote, Firmenprofile,
                  Nutzerprofile, Artikel, Aufträge?
                </h2>
                Wie empfehle ich etwas - Jobangebote, Firmenprofile,
                Nutzerprofile, Artikel, Aufträge? In der Detailansicht eines
                Jobs, Beitrags oder Artikels gibt es die Möglichkeit des
                internen teilen auf TRADEFOOX und das weiterempfehlen auf andere
                Plattformen zum Beispiel Facebook und Whatsapp. Das interne
                Teilen auf der Plattform bietet die Möglichkeiten im Newsfeed,
                in einer Nachricht und in der Chronik eines Kontaktes. Hierfür
                wählst du den Teil-Button und dein Ziel an.
              </div>
              <div>
                <h2 className="font-semibold">
                  Wie Teile ich von Tradefoox auf andere Soziale Netzwerke?
                </h2>
                Wie Teile ich von Tradefoox auf andere Soziale Netzwerke? In den
                Details eines Inserates oder Beitrages befindet sich die Teilen
                Rubrik, der gewünschte Empfänger z.B. Facebook wird ausgewählt.
                Via Popup Fenster wird der Vorgang des Teilen-Vorgangs
                abgeschlossen.
              </div>
              <div>
                <h2 className="font-semibold">Wie Spende ich?</h2>
                Wie Spende ich? Auf unserer Seite Spendenaktion finden sich
                viele regionale Projekte und Organisationen, die du unterstützen
                kannst. Es befinden sich weiterführende Links zu den eigenen
                Webseiten der Organisationen unter dem Text. Dort sind die
                Spendenvorgänge genau erklärt.
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default MemberFAQ;
