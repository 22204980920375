import React, { Fragment, useState } from "react";
import Assignment from "@components/elements/Assignment";
import TabFilter from "@components/menu/TabFilter";
import PopularWidget from "@components/elements/PopularWidget";
import Input from "@/components/elements/input/Input";
import Tab from "@/components/menu/Tab";
import AssignmentsCreateContainer from "@/pages/assignments/AssignmentsCreateContainer";
import { useTranslation } from "react-i18next";

type Props = {
  filterDefault?: string;
};

const AssignmentsContainer = ({ filterDefault = "assignments" }: Props) => {
  const [filter, setFilter] = useState<string>(filterDefault);
  const [subFilter, setSubFilter] = useState<string>("all");
  const { t } = useTranslation();

  const tabs = [
    { name: t("main.navLinks.createAd.tabs.toYou.title"), key: "assignments" },
    {
      name: t("main.navLinks.createAd.tabs.myAdvertisements"),
      key: "my_assignments",
    },
    {
      name: t("main.navLinks.createAd.tabs.create.title"),
      key: "create",
      mobileHidden: true,
    },
    { name: t("main.navLinks.createAd.tabs.followers"), key: "follower" },
    { name: t("main.navLinks.createAd.tabs.trending"), key: "trending" },
  ];
  const subTabs = [
    { name: t("main.navLinks.createAd.tabs.toYou.tabs.overview"), key: "all" },
    {
      name: t("main.navLinks.createAd.tabs.toYou.tabs.requested"),
      key: "requested",
    },
    {
      name: t("main.navLinks.createAd.tabs.toYou.tabs.administer"),
      key: "manage",
    },
    { name: t("main.navLinks.createAd.tabs.toYou.tabs.bills"), key: "bills" },
    {
      name: t("main.navLinks.createAd.tabs.toYou.tabs.statistics"),
      key: "stats",
    },
  ];

  const dataSource = {
    assignments: [
      {
        id: "0",
        creator: {
          id: "0",
          username: "Ada",
          person: { firstname: "Ada", lastname: "Lovelace" },
          follower_count: 0,
          type: "person",
        },
        title: "Kfz-Mechaniker 1111",
        city: "Chemnitz",
        price: Math.floor(Math.random() * 10000),
        created_at: new Date(Date.now()).toDateString(),
        start_at: new Date(
          Math.random() * 123456789000 + Date.now(),
        ).toString(),
        showButtons: false,
      },
      {
        id: "1",
        creator: {
          id: "0",
          username: "Ada",
          person: { firstname: "Ada", lastname: "Lovelace" },
          follower_count: 0,
          type: "person",
        },
        title: "Kfz-Mechaniker 2222",
        city: "Chemnitz",
        price: Math.floor(Math.random() * 10000),
        created_at: new Date(Date.now()).toDateString(),
        start_at: new Date(
          Math.random() * 123456789000 + Date.now(),
        ).toString(),
        showButtons: false,
      },
      {
        id: "2",
        creator: {
          id: "0",
          username: "Ada",
          person: { firstname: "Ada", lastname: "Lovelace" },
          follower_count: 0,
          type: "person",
        },
        title: "Kfz-Mechaniker 3333",
        city: "Chemnitz",
        price: Math.floor(Math.random() * 10000),
        created_at: new Date(Date.now()).toDateString(),
        start_at: new Date(
          Math.random() * 123456789000 + Date.now(),
        ).toString(),
        showButtons: false,
      },
      {
        id: "3",
        creator: {
          id: "0",
          username: "Ada",
          person: { firstname: "Ada", lastname: "Lovelace" },
          follower_count: 0,
          type: "person",
        },
        title: "Kfz-Mechaniker 4444",
        city: "Chemnitz",
        price: Math.floor(Math.random() * 10000),
        created_at: new Date(Date.now()).toDateString(),
        start_at: new Date(
          Math.random() * 123456789000 + Date.now(),
        ).toString(),
        showButtons: false,
      },
    ],
  };

  return (
    <div className="flex flex-col gap-2">
      <PopularWidget>
        {dataSource[filter as keyof typeof dataSource] &&
          dataSource[filter as keyof typeof dataSource].map((assignment) => (
            <Assignment
              id={assignment.id}
              key={assignment.id}
              title={assignment.title}
              city={assignment.city}
              created_at={assignment.created_at}
              start_at={assignment.start_at}
              minimized={true}
            />
          ))}
        {dataSource[filter as keyof typeof dataSource] &&
          dataSource[filter as keyof typeof dataSource].map((assignment) => (
            <Assignment
              id={assignment.id}
              key={assignment.id}
              title={assignment.title}
              city={assignment.city}
              created_at={assignment.created_at}
              start_at={assignment.start_at}
              minimized={true}
            />
          ))}
      </PopularWidget>

      <Fragment>
        <Tab stayVisible={true}>
          <TabFilter
            value={filter}
            options={tabs}
            onChange={(value) => setFilter(value)}
          />
        </Tab>

        <Tab stayVisible={true}>
          <Input
            name="inserate-keywords"
            placeholder="Inserate durchsuchen"
            clearable
          />
        </Tab>

        <Tab isActive={filter === "assignments"}>
          <TabFilter
            value={subFilter}
            options={subTabs}
            onChange={(value) => setSubFilter(value)}
            subTab={true}
          />
        </Tab>

        <Tab isActive={filter === "create"}>
          <AssignmentsCreateContainer />
        </Tab>

        <Tab stayVisible={true}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {dataSource[filter as keyof typeof dataSource] &&
              dataSource[filter as keyof typeof dataSource].map(
                (assignment) => (
                  <Assignment
                    id={assignment.id}
                    key={assignment.id}
                    title={assignment.title}
                    city={assignment.city}
                    created_at={assignment.created_at}
                    start_at={assignment.start_at}
                  />
                ),
              )}
          </div>
        </Tab>
      </Fragment>
    </div>
  );
};

export default AssignmentsContainer;
