import React, { useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CommentType } from "@/utils/types/CommentType";
import UserWidget from "@components/elements/UserWidget";
import api from "@/api/api";
import CommentTextEdit from "@components/elements/posts/form/shared/CommentTextEdit";
import getName from "@/utils/getName";
import { useAppSelector } from "@/state/hooks";

type Props = {
  postId: string;
  answer?: CommentType;
  onSuccess?: () => void;
  parentId?: string;
};

const CreatePostComment = ({ postId, answer, onSuccess, parentId }: Props) => {
  const [text, setText] = useState(
    answer
      ? JSON.stringify({
          root: {
            children: [
              {
                children: [
                  {
                    id: answer.author.id,
                    name: getName(answer.author),
                    type: "mention",
                    version: 1,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "paragraph",
                version: 1,
              },
            ],
            direction: null,
            format: "",
            indent: 0,
            type: "root",
            version: 1,
          },
        })
      : "",
  );
  const [clearTextField, setClearTextField] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAppSelector((state) => state.user);

  const createComment = useMutation(["create-post-comment"], {
    mutationFn: async () => {
      const res = await api.post(`/api/v1/posts/${postId}/comment`, {
        text,
        parent_id: parentId,
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setClearTextField(true);
      setTimeout(() => {
        setClearTextField(false);
      }, 100);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      queryClient.invalidateQueries({ queryKey: ["comments"] });
      onSuccess && onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) {
    return null;
  }

  return (
    <div className="flex gap-3">
      {<UserWidget user={user} size={30} />}
      <div className="grow overflow-hidden">
        <div className="rounded-2xl bg-gray-100 flex flex-row justify-evenly gap-3">
          {!clearTextField && (
            <CommentTextEdit value={text} onChange={setText} />
          )}
          <button onClick={() => createComment.mutate()}>
            <PaperAirplaneIcon className="h-[20px] text-darkblue pr-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePostComment;
