import React from "react";
import { User } from "@/utils/user";
import Input from "@components/elements/input/Input";
import getName from "@/utils/getName";
import { TrashIcon } from "@heroicons/react/24/outline";
import UserWidget from "@components/elements/UserWidget";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

type Props = {
  contacts?: Array<User>;
  name?: string;
  onChange: (array: Array<User>) => void;
};

const ContactsEditor = ({ contacts = [], name = "", onChange }: Props) => {
  /**
   * Removes user from list
   * @param index
   */
  const removeUser = (index: number) => {
    const newContacts: Array<User> = JSON.parse(JSON.stringify(contacts));
    newContacts.splice(index, 1);
    onChange(newContacts);
  };

  /**
   * Adds user to list
   * @param user
   */
  const addUser = (user: User) => {
    const newContacts: Array<User> = JSON.parse(JSON.stringify(contacts));
    newContacts.push(user);
    onChange(newContacts);
  };

  return (
    <div>
      <div className="relative mb-2">
        <input
          className="block w-full rounded-2xl border border-gray-300 shadow-sm focus:border-darkblue focus:ring-darkblue sm:text-sm py-2 px-3 outline-darkblue"
          placeholder="Nutzer suchen / hinzufügen"
        />
        <button
          className="absolute right-0 top-1/2 -translate-y-1/2"
          onClick={() => {
            addUser({
              id: "a59bfd74-e1f3-4736-bdb9-6aca955ff202",
              username: "new user",
              admin: false,
              type: "company",
              company: { name: "name name" },
              follower_count: 555,
              following_count: 555,
              like_count: 0,
              post_count: 0,
              job_count: 0,
              watch_count: 0,
              liked: false,
            });
          }}
        >
          <PlusCircleIcon className="w-8 h-8 text-green-600" />
        </button>
      </div>
      <div className="grid grid-flow-row gap-y-2 divide-y divide-gray-400 text-sm">
        <div className="grid grid-cols-[1fr,1fr,25px] gap-x-4 items-center justify-between">
          <div className="bg-darkblue text-white rounded-full text-center">
            Name
          </div>
          <div className="bg-darkblue text-white rounded-full text-center">
            Abteilung
          </div>
          <div></div>
        </div>
        {contacts.map((user, index) => {
          return (
            <div
              className="grid grid-cols-[1fr,1fr,25px] divide-black gap-x-4 gap-y-2 items-center pt-1 relative"
              key={user.id}
            >
              <div className="flex flex-row gap-2 items-center px-2">
                <UserWidget
                  user={user}
                  online={true}
                  showName={false}
                  follow={false}
                />
                <div>{getName(user)}</div>
              </div>
              <div className="flex flex-row gap-2 items-center px-2">
                department
              </div>
              <button
                className="absolute right-0 top-1/2 -translate-y-1/2"
                onClick={() => {
                  removeUser(index);
                }}
              >
                <XCircleIcon className="w-8 h-8 text-white text-red-600" />
              </button>
            </div>
          );
        })}
      </div>

      <Input
        name={name}
        type="hidden"
        value={JSON.stringify(contacts)}
        placeholder=""
      />
    </div>
  );
};

export default ContactsEditor;
