import React from "react";
import TabFilter from "@components/menu/TabFilter";
import { useState } from "react";
import PopularWidget from "@/components/elements/PopularWidget";
import Input from "@/components/elements/input/Input";
import GroupsCreateContainer from "@/pages/groups/GroupsCreateContainer";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import tw from "twin.macro";
import useDebounce from "@/utils/useDebounce";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const subTabs = (t: TFunction) => [
  { name: t("main.contacts.tabs.myContacts.tabs.overview"), key: "all" },
  { name: "Angefragt", key: "requested" },
  { name: "Austehend", key: "pending" },
  { name: "Statistik", key: "stats" },
];

type Props = {
  tab?: string;
};

type Response = {
  // data: GroupType[];
  current_page: number;
  total_pages: number;
};

const GroupsContainer = ({ tab = "for_you" }: Props) => {
  const [subFilter, setSubFilter] = useState<string>("all");
  const [mode, setMode] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const groups = useInfiniteQuery<Response>(
  //   ["groups", searchQuery, filter],
  //   async ({ pageParam = 0 }) => {
  //     const { data } = await api.get(
  //        `/api/v1/groups?page=${pageParam}&q=${searchQuery}&filter=${filter}`
  //     );
  //     return data;
  //   },
  //   {
  //     getNextPageParam: (lastPage) => {
  //       const nextPage = lastPage.current_page + 1;
  //       return nextPage <= lastPage.total_pages ? nextPage : false;
  //     },
  //   }
  // );

  return (
    <div className="flex flex-col gap-2">
      <div css={[tab === "create" && tw`max-md:hidden`]}>
        <TabMenu>
          <TabLink to="/gruppen/meine_gruppen">
            {t("main.header.links.groups.tabs.myGroups")}
          </TabLink>
          <TabLink to="/gruppen/für_dich">
            {t("main.header.links.groups.tabs.toYou.title")}
          </TabLink>
          <div className="max-lg:hidden">
            <TabLink to="/gruppen/erstellen">
              {t("main.header.links.groups.tabs.create.title")}
            </TabLink>
          </div>
          <TabLink to="/gruppen/following">
            {t("main.header.links.groups.tabs.following")}
          </TabLink>
          <TabLink to="/gruppen/trending">
            {t("main.header.links.groups.tabs.trending")}
          </TabLink>
        </TabMenu>
      </div>

      {tab != "create" && (
        <Input
          name="groups-keywords"
          placeholder="Gruppen durchsuchen"
          onChange={(e) => setSearchQuery(e)}
          clearable
        />
      )}

      {tab === "for_you" && (
        <TabFilter
          value={subFilter}
          options={subTabs(t)}
          onChange={(value) => {
            setSubFilter(value);
            setMode(value);
          }}
          subTab={true}
        />
      )}

      {tab === "create" && (
        <GroupsCreateContainer
          onSuccess={() => navigate("/gruppen/für_dich")}
          onClose={() => navigate("/gruppen/für_dich")}
        />
      )}

      {/* {tab !== "create" && (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        </div>
      )} */}
    </div>
  );
};

export default GroupsContainer;
