import React, {
  ComponentProps,
  createContext,
  useEffect,
  useState,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import getNotificationToken from "@/api/auth/notification_token";
import { store } from "@/state/store";
import toast, { Toast } from "react-hot-toast";
import BasicTextNotification from "@components/notification/BasicTextNotification";
import { useTranslation, UseTranslationResponse } from "react-i18next";

export const NotificationContextProvider = (props: ComponentProps<any>) => {
  const [reconnecting, setReconnecting] = useState(false);

  const notificationToken = localStorage.getItem("notification_token");

  const { t } = useTranslation();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    "wss://notifications.tradefoox.com/connect",
    {
      onError: (e) => console.log("error", e),
      shouldReconnect: () => true,
      reconnectAttempts: 20,
      reconnectInterval: 5000,

      onOpen: () => {
        console.log("[Notifications] Connection opened");
        if (notificationToken != null) {
          writeMessage({
            token: notificationToken,
          });
        }
      },
      onClose: () => console.log("[Notifications] Connection Closed"),
    },
  );

  const writeMessage = (message: object) => {
    const encoded = JSON.stringify(message);

    sendMessage(encoded);
  };

  const requestNotificationToken = () => {
    getNotificationToken().then((data) => {
      localStorage.setItem("notification_token", data.token);
      writeMessage({ token: data.token });
    });
  };

  if (notificationToken == null) {
    requestNotificationToken();
  }

  useEffect(() => {
    if (readyState == ReadyState.CLOSED) {
      console.log("Initiating reconnect.");
    }
    if (readyState == ReadyState.OPEN) {
      console.log("Initiating open");
    }
  }, [readyState]);

  if (reconnecting) {
    // todo show reconnection loader?
  }

  useEffect(() => {
    if (!lastMessage) return;
    const decoded = JSON.parse(lastMessage?.data);

    if (decoded.status === "ok") {
      console.log("[Notification] Authenticated successfully");
    } else if (decoded.status == "expired") {
      requestNotificationToken();
      // request new notification token
    } else if (NotificationConfigurationMappings[decoded.title]) {
      toast.custom(
        (toastRef: Toast) =>
          NotificationConfigurationMappings[decoded.title](
            decoded,
            toastRef,
            t,
          ),
        {
          duration: 1000 * 10,
          position: "top-right",
        },
      );
    }
  }, [lastMessage]);

  if (store.getState().user == null) return props.children;

  return props.children;
};

export const NotificationContext = createContext<NotificationState>({
  addHandler: () => null,
});

declare type Message = {
  body: any;
};

export const NotificationConfigurationMappings: {
  [key: string]: (data: Message, toast: Toast, t: any) => JSX.Element;
} = {
  new_follow: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.followed_by.name}
      content={<>{t("main.notifications.new_follow.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  post_liked: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.liked_by.name}
      content={<>{t("main.notifications.post_liked.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  post_reposted: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.liked_by.name}
      content={<>{t("main.notifications.post_reposted.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  new_connect: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.connected_by.name}
      content={<>{t("main.notifications.new_connect.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  new_post_comment: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.commented_by.name}
      content={<>{t("main.notifications.new_post_comment.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
};

export type NotificationState = {
  addHandler: (payload: object, handler: (payload: object) => void) => void;
};
