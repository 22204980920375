import React, { useEffect, useRef, useState } from "react";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import tw from "twin.macro";
import LegacyPost from "@pages/news/posts/LegacyPost";

type Props = {
  text: string;
  legacy: boolean;
};

const ProfileDetailsDescriptionContainer = ({ legacy, text }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const body = useRef<any>(null);

  useEffect(() => {
    body.current && body.current.clientHeight > 120 && setLargeText(true);
  }, [body]);

  return (
    <>
      <div
        css={[
          tw`overflow-hidden text-sm leading-6 break-words`,
          !showMore && largeText && tw`line-clamp-5`,
        ]}
        ref={body}
      >
        {legacy ? <LegacyPost content={text} /> : text}
      </div>
      {largeText && (
        <ShowMoreButton
          onClick={() => setShowMore((value) => !value)}
          active={showMore}
        />
      )}
    </>
  );
};

export default ProfileDetailsDescriptionContainer;
