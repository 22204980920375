import React, { useEffect, useRef, useState } from "react";
import {
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  PlayIcon,
  PauseIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import {
  PlayIcon as PlayIconFull,
  ChatBubbleOvalLeftIcon,
  PlusIcon,
  HeartIcon,
  MusicalNoteIcon,
} from "@heroicons/react/24/solid";
import { NavLink, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import WatchBookmarkButton from "@/pages/watch/actions/WatchBookmarkButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import SharePopup from "@/components/popup/SharePopup";
import WatchType from "@/utils/types/watch/WatchType";
import ProfileAvatar from "@components/elements/shared/ProfileAvatar";
import WatchDropdown from "@pages/watch/WatchDropdown";
import api from "@/api/api";
import { useMe } from "@/api/auth/me";
import useLoginModal from "@/state/modal/useLoginModal";
import CommentsPopup from "@/components/popup/CommentsPopup";
import Hls from "hls.js";
import { useAppSelector } from "@/state/hooks";
import TextView from "@components/elements/textedit/TextView";

type Props = {
  data: WatchType;
  active: boolean;
  preload: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
};

const WatchMobileItem = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  queryKey = ["watch", "posts"],
}: Props) => {
  const queryClient = useQueryClient();
  const [viewed, setViewed] = useState(false);

  const navigate = useNavigate();
  const [onCanPlay, setOnCanPlay] = useState(false);
  const [preloadPersist, setPreloadPersist] = useState(preload);
  useEffect(() => {
    if (!preloadPersist && preload) {
      console.log("Preload persist");
      setPreloadPersist(preload);
    }
  }, [preload]);
  const [playing, setPlaying] = useState(autoplay);
  const [preventPause, setPreventPause] = useState(false);
  const [timeRatio, setTimeRatio] = useState(0);
  const { user: meUser } = useAppSelector((state) => state.user);
  const loginModal = useLoginModal();
  const like = useMutation([`like-${data.post_id}`], {
    mutationFn: async () => {
      if (!meUser) {
        loginModal.open();
        return;
      }
      const liked = data.liked;

      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: WatchType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.post_id !== data.post_id) continue;

            if (data.liked) {
              post.like_count -= 1;
            } else {
              post.like_count += 1;
            }
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }
      const res = await api.get(
        `/api/v1/posts/${data.post_id}/${liked ? "unlike" : "like"}`,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watch", "posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (active && !viewed) {
      setViewed(true);
      axios.get(`/api/v1/posts/${data.post_id}/view`);
    }
  }, [active, data.post_id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const thumbnailRef = useRef<HTMLImageElement>(null);
  const { user } = useAppSelector((state) => state.user);
  const [likeEffect, setLikeEffect] = useState(false);

  useEffect(() => {
    if (videoRef.current === null) return;
    const video = videoRef.current;
    const src =
      data.media_thumbnail.replace("/thumbnails/thumbnail.jpg", "") +
      "/manifest/video.m3u8";

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (active) {
          video.play().catch((e) => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = src;
      video.addEventListener("loadedmetadata", function () {
        if (active) {
          video.play().catch((e) => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    }
  }, [active, data.media_key, videoRef]);

  return (
    <div
      css={[
        tw`bg-neutral-900 h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] w-screen relative snap-center`,
      ]}
    >
      <div className="max-h-full max-w-full cursor-pointer overflow-hidden">
        <video
          poster={data.media_thumbnail}
          ref={videoRef}
          className="h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] mx-auto"
          css={[
            videoRef.current &&
              videoRef.current?.videoWidth / videoRef.current?.videoHeight <
                0.57 &&
              tw`object-cover`,
          ]}
          loop={true}
          muted={muted}
          playsInline
          preload={"auto"}
          onCanPlayThrough={() => setOnCanPlay(true)}
          onPlaying={() => setPlaying(true)}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onTimeUpdate={() => {
            if (!videoRef.current) return;
            setTimeRatio(
              (videoRef.current?.currentTime / videoRef.current?.duration) *
                100,
            );
          }}
        />
        {!onCanPlay && (
          <div className="absolute top-0 left-0 w-full h-full">
            <img
              src={data.media_thumbnail}
              className="h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] object-cover mx-auto"
            />
          </div>
        )}
        <div
          className="absolute top-0 left-0 w-full h-full"
          onClick={(e) => {
            if (!videoRef.current || preventPause) return;
            videoRef.current.paused
              ? videoRef.current.play()
              : videoRef.current.pause();
            e.stopPropagation();
          }}
        ></div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.post_id}
          userId={data.author_id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: "https://www.tradefoox.com/watch/" + data.post_id,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.post_id}
        />
      )}
      <div className="absolute z-10 px-6 pb-4 bottom-[20px] w-full flex flex-row justify-between rounded-xl text-gray-200">
        <button
          className="pointer-events-auto hidden duration-200"
          onClick={() => {
            if (!videoRef.current) return;
            videoRef.current.paused
              ? videoRef.current.play()
              : videoRef.current.pause();
          }}
        >
          {playing ? (
            <PauseIcon className="w-6 h-6 " />
          ) : (
            <PlayIcon className="w-6 h-6 " />
          )}
        </button>
        <button
          className="pointer-events-auto duration-200 absolute right-6 bottom-0"
          onClick={(e) => {
            setMuted && setMuted(!muted);
            e.stopPropagation();
          }}
        >
          {muted ? (
            <SpeakerXMarkIcon className="w-6 h-6 " />
          ) : (
            <SpeakerWaveIcon className="w-6 h-6 " />
          )}
        </button>
      </div>
      {/* Sidebar */}
      <div className="absolute z-10 right-3 bottom-20 w-[50px] rounded-xl text-xs text-gray-200">
        <div className="flex flex-col items-center gap-3">
          <button
            className="flex flex-col items-center pointer-events-auto"
            onClick={() => like.mutate()}
          >
            {!data.liked && (
              <HeartIcon
                className="w-8 h-8"
                onClick={() => {
                  setLikeEffect(true);
                }}
                onAnimationEnd={() => {
                  setLikeEffect(false);
                }}
              />
            )}
            {data.liked && (
              <HeartIcon
                className={`w-8 h-8 text-red-600 ${
                  likeEffect && "animate-ping-once"
                }`}
              />
            )}
            <div>{data.like_count}</div>
          </button>
          <div className="flex flex-col items-center pointer-events-auto">
            <button
              className="flex flex-col items-center gap-0"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ChatBubbleOvalLeftIcon
                className="w-8 h-8"
                onClick={() => {
                  setCommentsPopupOpen(!commentsPopupOpen);
                }}
              />
            </button>
            <div>{data.comment_count}</div>
          </div>
          <div className="flex flex-col items-center pointer-events-auto">
            <WatchBookmarkButton
              postId={data.post_id}
              bookmarked={data.bookmarked}
            />
            <div>{data.bookmark_count}</div>
          </div>
          <div
            className="flex flex-col items-center pointer-events-auto"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PlayIconFull className="w-8 h-8" />
            <div>{data.view_count}</div>
          </div>
          <button
            className="relative flex flex-col items-center cursor-pointer pointer-events-auto"
            onClick={(e) => {
              if (user) {
                setSharePopupOpen(true);
              } else {
                loginModal.open();
              }
              e.stopPropagation();
            }}
          >
            <ArrowPathRoundedSquareIcon className="w-8 h-8" />
            <div>0</div>
            <button
              className="lg:hidden absolute top-0 left-0 w-full h-full"
              onClick={() => {
                setSharePopupMobile(true);
              }}
            />
          </button>

          <div className="pointer-events-auto text-black">
            {
              <WatchDropdown
                isRepost={data.is_reposted}
                postId={data.post_id}
                authorId={data.author_id}
                bookmarked={data.bookmarked}
              />
            }
          </div>
          <button
            className="flex gap-2.5 items-center border rounded-xl px-2.5 mr-12 py-1 bg-gray-200 text-black pointer-events-auto text-sm mt-4"
            onClick={() => {
              if (!user) {
                loginModal.open();
                return;
              }
              navigate("/watch/erstellen");
            }}
          >
            <PlusIcon className="w-4 h-4 text-black" /> Create
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full block">
        <div className="flex flex-row gap-4 w-[calc(100%-70px)] text-white text-sm rounded-xl py-1 px-2">
          <div className="pointer-events-auto">
            <ProfileAvatar
              user={{
                id: data.author_id,
                name: data.author_name,
                type: data.author_type,
                avatar: data.author_avatar,
              }}
              nameStyle={tw`hidden`}
            />
          </div>
          <div className="flex flex-col w-full">
            <NavLink
              to={`/watch/c/${data.author_id}`}
              className="hover:font-semibold w-fit"
            >
              @{data.author_name}
            </NavLink>
            <div className="overflow-clip overflow-ellipsis leading-4">
              <TextView value={data.post_text} />
            </div>
            <div className="flex flex-row">
              <MusicalNoteIcon className="flex-shrink-0 w-5 h-5" />
              <div className="w-full overflow-hidden">
                <div className="w-full animate-marquee"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-10 bottom-0 w-screen left-0">
        <input
          type="range"
          min={0}
          max={100}
          value={timeRatio}
          onChange={(e) => {
            setPreventPause(true);
            setTimeout(() => {
              setPreventPause(false);
            }, 400);
            if (!videoRef.current) return;
            videoRef.current.currentTime =
              (videoRef.current?.duration * parseFloat(e.currentTarget.value)) /
              100;
            setTimeRatio(parseInt(e.currentTarget.value));
          }}
          className="accent-darkblue h-0.5 w-full cursor-pointer"
        />
      </div>
    </div>
  );
};

export default WatchMobileItem;
