import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  stack: Array<ReactNode>;
}

const initialState: ModalState = {
  stack: [],
};

export const popupSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<ReactNode>) => {
      state.stack.push(action.payload);
    },
    closePopup: (state, action: PayloadAction<Array<ReactNode>>) => {
      state.stack = action.payload;
    },
  },
});

export const { openPopup, closePopup } = popupSlice.actions;

export default popupSlice.reducer;
