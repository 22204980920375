import React, { useState } from "react";
import Button from "@components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: (media: {
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }) => void;
  onDelete: (id: string) => void;
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
};

const PostMediaWatch = ({ onChange, media, onDelete }: Props) => {
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadstatus, setUploadstatus] = useState<any>({});
  const errorTranslation = {
    copyright: {
      title: "Urheberrechtsproblem erkannt",
      explanation:
        "Hinweis: Ergebnis von Urheberrechtsprüfungen sind nicht endgültig. Zukünftige Änderungen der Autorisierung des Urheberrechtsinhabers des können beispielsweise Auswirkungen auf dein Video haben.",
    },
    interrupted: {
      title: "Upload unterbrochen",
      explanation: "Der Upload wurde unerwarted unterbrochen.",
    },
    generic: {
      title: "Fehler beim Upload",
      explanation: "",
    },
    novideo: {
      title: "Kein Video ausgewählt",
      explanation: "Bitte wähle zuerst ein Video aus.",
    },
  };

  const upload = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      let url = "/api/v1/video/upload";
      let key = "";
      let id = "";
      let thumbnail = "";
      if (file.type.startsWith("video")) {
        const directUploadResponse = await api.get(
          "/api/v1/video/upload/direct",
        );
        url = directUploadResponse.data.data.url;
        key = directUploadResponse.data.data.key;
        id = directUploadResponse.data.data.id;
        thumbnail = directUploadResponse.data.data.thumbnail;
      }
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let res;
      if (file.type.startsWith("video")) {
        res = await axios.post(url, formData, config);
      } else {
        res = await api.post(url, formData, config);
      }
      if (!file.type.startsWith("video")) {
        key = res.data.data.key;
        id = res.data.data.id;
      }
      setUploadingCount((count) => count - 1);
      return {
        key,
        id,
        file_name: file.name,
        type: file.type.startsWith("video") ? "video" : "image",
        thumbnail,
      };
    },
    onSuccess: (data) => {
      setUploadstatus({ errors: [] });
      onChange(data);
    },
    onError: () => {
      setUploadstatus({
        errors: [
          {
            debug: "",
            type: "generic",
          },
        ],
      });
      setUploadingCount((count) => count - 1);
    },
  });

  const { t } = useTranslation();
  const fileInputRef = React.createRef<HTMLInputElement>();
  return (
    <>
      <div className="font-semibold text-sm">Video</div>
      <div className="lg:border lg:px-3 lg:py-3 grid grid-cols-4 gap-3 rounded-xl">
        {media.map((data) => {
          return (
            <div key={data.id} className="relative min-h-[100px]">
              <button
                onClick={() => {
                  onDelete(data.id);
                }}
                className="absolute right-3 top-3"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
              <img className="bg-gray-200 rounded-xl" src={data.thumbnail} />
            </div>
          );
        })}
        {[...Array(uploadingCount)].map((_, index) => (
          <div key={index} className="bg-gray-200 min-h-[100px] rounded-xl">
            <svg
              className="animate-spin h-5 w-5 mr-3"
              viewBox="0 0 24 24"
            ></svg>
          </div>
        ))}
      </div>
      <div className="w-fit ml-auto">
        <Button
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
          onClick={() => {
            fileInputRef.current && fileInputRef.current.click();
          }}
        >
          {t("main.watchTabs.create.buttons.addVideo")}
        </Button>
      </div>
      <input
        type="file"
        accept="video/*"
        onChange={(e) => {
          if (
            e.target.files &&
            e.target.files.length + uploadingCount + media.length > 10
          ) {
            alert("Max 10 Videos");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount((count) => count + 1);
            upload.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
      {uploadstatus.errors && uploadstatus.errors.length === 0 && (
        <div className="border px-3 py-1 grid grid-cols-[25px,auto] items-top align-top text-sm">
          {/* <CheckIcon className="w-5 h-5 text-green-600" />
          <div className="flex flex-wrap">
            <div>Der Upload war erfolgreich und fehlerfrei.</div>
          </div> */}
          <CheckIcon className="w-5 h-5 text-green-600" />
          <div className="flex flex-wrap">
            <div>Keine Urheberrechtsprobleme festgestellt.</div>
          </div>
        </div>
      )}
      {uploadstatus.errors && uploadstatus.errors.length != 0 && (
        <div className="border px-3 py-1 grid grid-cols-[25px,auto] items-top align-top text-sm">
          {uploadstatus.errors.map(
            (error: {
              type: "copyright" | "interrupted" | "novideo" | "generic";
            }) => {
              return (
                <>
                  <XMarkIcon className="w-5 h-5 text-red-600" />
                  {(error.type === "copyright" ||
                    error.type === "interrupted" ||
                    error.type === "generic" ||
                    error.type === "novideo") && (
                    <div className="flex flex-col">
                      {errorTranslation[error.type].title}
                    </div>
                  )}
                </>
              );
            },
          )}
        </div>
      )}
    </>
  );
};

export default PostMediaWatch;
