import { User } from "@/utils/user";

export type loginRequest = {
  email: string;
  password: string;
};

export type Response = {
  user: User;
  token: string;
};

const API_BASE_URL = import.meta.env.VITE_API_URL || "";

async function login(login: loginRequest): Promise<Response> {
  const res = await fetch(`${API_BASE_URL}/api/v1/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(login),
  });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  const data = await res.json();
  const loginData = {
    user: data.data,
    token: data.token,
  };
  return loginData;
}

export default login;
