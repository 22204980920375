import BackButton from "@/components/nav/BackButton";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

type ArticleType = {
  title: string;
  text: ReactNode;
  youtubeLink: string;
};

const StartupAdvice = () => {
  const articles: Array<ArticleType> = [
    {
      title: "Wie finde ich einen Namen für meine Firma?",
      text: "...Es mag simpel klingen, aber die Realität sieht ein bisschen anders aus:  je einfacher und schöner der eigene Firmenname sein soll, umso mehr Arbeit muss man dafür investieren...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/OASCoUPeliM?controls=0",
    },
    {
      title:
        "Unternehmensformen erklärt: GmbH, AG, UG, GbR, Inc., Ltd uvm. einfach erklärt!",
      text: "....Welche Unternehmensform sollte man wählen?...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Xqer4ADWUd8?controls=0",
    },
    {
      title: "Mut zum Machen – Mit Startup-Spirit zum Erfolg // Felix Plötz",
      text: "...Wie baut man ein Startup Unternehmen ohne Kündigung des Jobs im Büro auf? Wie sieht die Haltung des Start-up Spirits aus? Einfach mal machen! Anfangen und innovativ sein. Zweifel aus dem Weg räumen...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/fYWlqM7p2EU?controls=0",
    },
    {
      title: "Crowdfunding einfach erklärt",
      text: "...Den Studienabschluss in der Tasche und eine super Idee für eine Geschäftsgründung. So geht es einigen frisch gebackenen Absolventen. Doch was fehlt ist das Startkapital. Die Finanzierungsalternative zu den altbekannten Bankkrediten lautet Crowdfunding...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/mbVCLmxa2Z8?controls=0",
    },
    {
      title:
        "Crowdfunding – der schnelle Kredit - Ratgeber Internet - Kickstarter, Startnext & Co. - ARD",
      text: "...Die zentrale Frage für junge Internet-Unternehmer ist, an Startkapital heran zu kommen. Der Weg zum Bankenkredit ist steinig und teuer. Doch es gibt noch andere Ideen fürs Startkapital. In seinem „Deeplink“ stellt ARD-Ratgeber-Experte Jörg Schieb das Crowdfunding vor...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/-RwlH15TFUE?controls=0",
    },
    {
      title: "Die goldene Regel der Selbstständigkeit // Dieter Lange",
      text: "...Be first, better, different or faster...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/wDc2Oufns3s?controls=0",
    },
    {
      title: "Die 5 größten Start-up Fehler | felixthoennessen.de",
      text: "...Startups und Gründer machen oft eine Menge Fehler. Warum nicht aus diesen Fehlern lernen und selber vermeiden?...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/33pYHF3tXEU?controls=0",
    },
    {
      title:
        "Wie man erfolgreich digitales Marketing betreibt // Alexander Müller",
      text: "...Alexander Müller berät, begleitet und investiert in junge Start-Ups und deren Gründer, u.a. als einer von weltweit 25 offiziellen Google Product Strategy Experts...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/F3vPtNiY-zs?controls=0",
    },
    {
      title:
        "Online Marketing: Nie wieder unnötiges Geld für Werbung ausgeben // Marcel Knopf",
      text: "...Marketing findet heutzutage primär an einem Ort statt: Online. Dort Aufmerksamkeit zu gewinnen, zu verkaufen, sprich Geld verdienen und schließlich Erfolg zu haben, ist die Königsdisziplin...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/icMuo7bQWNM?controls=0",
    },
    {
      title: "VERTRIEBSKAMPAGNE - Was ist eigentlich...?",
      text: "...Was macht eine erfolgreiche Vertriebskampagne wirklich aus? Und welche Rolle spielt die Marketingabteilung dabei. In diesem Video gibt es wertvolle Tipps für Deine nächste Vertriebskampagne. Weitere Informationen gibt es aber auch hier: http://b2b-marketing.tips/ Viel Spaß!...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/O112j7brarM?controls=0",
    },
    {
      title: "Facebook Werbeanzeige erstellen - Tutorial [Traffic]!",
      text: "...In wenigen Schritten erfolgreich Werbung bei Facebook schalten...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/ADd7e96N8KM?controls=0",
    },
    {
      title: "Perfekte Google Ads Kampagne erstellen in 2019",
      text: "...Schritt für Schritt, wie zur eigenen Kampagnen im neuen Google Ads Design erstellen. Viele Schaltflächen sind woanders und Funktionen wurden teilweise umbenannt. Deswegen ein neues aktualisiertes Video...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/rZpszUgtUhQ?controls=0",
    },
    {
      title: "Wie Sie XING optimal nutzen? // Joachim Rumohr",
      text: "...Joachim Rumohr ist gebürtiger Hamburger, hat über 20 Jahre Vertriebserfahrung und ist XING-Mitglied der ersten Stunde. Seit 2006 gibt er sein Wissen über die Business Plattform weiter und besitzt seit 2008 eine Masterlizenz zur Durchführung von offiziellen XING-Seminaren...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/FTSO5y72wqw?controls=0",
    },
    {
      title: "7 Tipps für erfolgreiches LinkedIn-Marketing",
      text: "...LinkedIn hat viel Marketing-Potential, wird aber immer noch von Vielen unterschätzt...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/T5u8vm7YYk0?controls=0",
    },
  ];

  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/startup_unternehmen_tipps_und_hilfe_tradefoox.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl whitespace-nowrap">
              Industriegeschichte Sachsen
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              Tradition verbindet Innovation
            </div>
          </div>
        </section>

        <article className="text-sm">
          <div className="font-semibold">
            TRADEFOOX - Der erste Schritt zum Erfolg!
          </div>
          <div className="grid grid-flow-row gap-1">
            Auf unserer Plattform findest Du die Kontakte, die Du brauchst. Gehe
            in Austausch mit anderen Start-ups und Innovatoren, finde Business
            Angels und Partner für Dein Unternehmen! Kommuniziere in unserem
            User Network deine Neuigkeiten und zeig Deine Innovation. Bilde
            Deine Start-up Community und vernetze Dich mit neuen B2B Kontakten.
            Ein Start-up zu haben bedeutet - Du bist bereits eine extra Meile
            gegangen und dafür danken wir Dir!
            <div />
            Das Team von tradefoox.com unterstützt Dich und bietet Dir unsere
            Onlinedienste komplett kostenlos an! Nimm Kontakt zu uns auf und
            erfahre wie wir Dich gezielt platzieren!
          </div>
        </article>
        {articles.map((article: ArticleType, index) => {
          return (
            <article className="flex flex-col gap-1" key={index}>
              <h2 className="text-base font-semibold">{article.title}</h2>
              <iframe
                src={article.youtubeLink}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                width="100%"
                height="300px"
              />
              <p className="text-sm">{article.text} </p>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default StartupAdvice;
