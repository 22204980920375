import React from "react";
import Panel from "@components/box/Panel";
import BackButton from "@/components/nav/BackButton";
import { NavLink } from "react-router-dom";

const ConditionsContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-6 text-gray-700">
        <div className="text-xl">Allgemeine Geschäftsbedingungen (AGB)</div>
        <div className="text-xs font-bold">§ 1 Geltung</div>
        <div className="text-xs">
          Diese Allgemeinen Geschäftsbedingungen sind Bestandteil aller zwischen
          Ihnen und der „TRADEFOOX“ (im Folgenden: „wir“, „uns“ oder
          „TRADEFOOX“) geschlossenen Verträge über Produkte und Dienstleistungen
          von „TRADEFOOX“ (im Folgenden: „TRADEFOOX“ -Services) und deren
          alleinige Grundlage; entgegenstehende Geschäftsbedingungen gelten auch
          dann nicht, wenn wir nicht ausdrücklich widersprochen haben und Ihren
          Auftrag vorbehaltlos ausführen. Mit Auftragserteilung versichern Sie
          zugleich, dass Sie nicht Verbraucher, sondern Unternehmer und Kaufmann
          im Sinne des HGB sind.
        </div>
        <div className="text-xs font-bold">
          § 2 Definitionen und Auftragsinhalte
        </div>
        <div className="text-xs">
          Der Inhalt Ihres Auftrages zur Aufnahme Ihres Unternehmens unter
          seiner tatsächlichen Firma als Firmenproﬁl in unsere Lieferantensuche
          ergibt sich aus den von Ihnen in Ihrem Kundenbereich auf unserer
          Webseite gemachten Angaben und gewählten Leistungen unter
          Zugrundelegung unserer jeweils gültigen Prospekte und bildet ihren
          Hauptauftrag. Gestaltung und Sortierung Ihres Firmenproﬁls richten
          sich vorbehaltlich abweichender Einzelvereinbarungen nach den jeweils
          geltenden Vorgaben und Voreinstellungen in Ihrem Kundenbereich.
          Änderungen dieser Vorgaben und Einstellungen auch während eines
          laufenden Auftrags bleiben vorbehalten und berechtigen nicht zu
          Minderung, Rücktritt oder Kündigung. Im Rahmen des beauftragten
          Leistungsumfangs können Sie jederzeit inhaltliche Änderungen Ihres
          Firmenproﬁls vornehmen.
        </div>
        <div className="text-xs font-bold">
          § 3 Auftragserteilung und Laufzeiten
        </div>
        <div className="text-xs">
          Sie können uns schriftlich, elektronisch oder telefonisch beauftragen.
          Die Auftragsausführung startet zum von Ihnen gewählten Datum,
          unabhängig davon, ob die allein von Ihnen zu stellenden oder
          einzupﬂegenden Inhalte vollständig oder richtig vorliegen. Alle
          Aufträge sind vorbehaltlich einer abweichenden individualvertraglichen
          Abrede Daueraufträge, die sich als solche stets um jeweils 12 Monate
          verlängern, wenn sie nicht mit einer Frist von drei Monaten (hier
          eingehend) zum Ablauf der Mindestvertragslaufzeit oder eines je
          12-monatigen Verlängerungszeitraums gekündigt worden sind. Die
          Beendigung des Hauptauftrages (Leistungspaket) beendet auch bestehende
          Zusatzaufträge „Top Ranking“. Alle Kündigungen bedürfen der
          Papierform. Beauftragen Sie während der Vertragslaufzeit zusätzliche
          Leistungen als Dauerauftrag, so laufen diese Zusatzaufträge zunächst
          bis zum Beginn des nächsten Verlängerungszeitraums des Hauptauftrages;
          danach gelten die für den Hauptauftrag geltenden
          Verlängerungszeiträume und Kündigungsfristen.
        </div>
        <div className="text-xs font-bold">§ 4 Qualitätssicherung</div>
        <div className="text-xs">
          Wir sind berechtigt, im Rahmen der Qualitätssicherung etwaig entdeckte
          Fehler in Ihrem Firmenproﬁl zu korrigieren und dieses sinnvoll
          anzureichern, ohne dass wir hierzu verpﬂichtet wären. Diese Erlaubnis
          können Sie jederzeit in Textform widerrufen sowie Korrekturen und
          Ergänzungen rückgängig machen oder löschen.
        </div>
        <div className="text-xs font-bold">
          § 5 Unsere vertraglichen Pflichten
        </div>
        <div className="text-xs">
          Unsere Leistungspﬂicht besteht darin, Ihnen während der
          Vertragslaufzeit für die Darstellung Ihres Firmenproﬁls Speicherplatz
          zur Verfügung zu stellen und die Auﬃndbarkeit Ihres Firmenproﬁls in
          unserer Lieferantensuchmaschine sicherzustellen. Ferner werden wir uns
          bemühen, ohne hierzu verpﬂichtet zu sein, Ihr Firmenproﬁl unter
          Heranziehung von auf Ihrer Website verfügbaren Informationen und
          Abbildungen zu ergänzen und im Rahmen des technisch Angezeigten und
          Zumutbaren ganz oder auszugsweise auch auf Webseiten Dritter, in
          sozialen Netzwerken und in weiteren Nutzungsformen und Medien zu
          platzieren und zu bewerben. Eine bestimmte inhaltliche oder graﬁsche
          Beschaﬀenheit Ihres Firmenproﬁls ist nicht Vertragsbestandteil. Der
          Nachweis der Veröﬀentlichung kann durch aktuelle Bildschirmausdrucke
          oder durch die Reproduktion datierter und mit Uhrzeit versehener
          Dateien aus unserem internen Backup-System geführt werden.
        </div>
        <div className="text-xs font-bold">
          § 6 Ihre vertraglichen Pflichten
        </div>
        <div className="text-xs">
          Zur Nutzung Ihres Kundenbereichs stehen Ihnen Zugangsdaten zur
          Verfügung, bestehend aus Benutzername und Passwort. Halten Sie Ihr
          Passwort geheim und teilen Sie es nicht unbefugten Dritten mit. Wenn
          Sie den Eindruck haben, dass eine unbefugte Person Ihr Passwort
          herausgefunden hat, sind Sie verpflichtet, Ihr Passwort sofort zu
          ändern. Für die Inhalte und Daten Ihres Firmenprofils haften Sie nach
          den allgemeinen Gesetzen, ebenso wie für die Virenfreiheit aller von
          Ihnen bereitgestellten Dateien. Sie dürfen keine rechts- oder
          sittenwidrigen, beleidigenden, bedrohlichen, Gewalt verherrlichenden,
          rassistischen, sexuell anstößigen Inhalte hinterlegen oder auf solche
          verlinken, welche religiöse Gefühle verletzen oder politisch
          Andersdenkende verunglimpfen oder die geeignet sind, Kinder oder
          Jugendliche sittlich zu gefährden oder in ihrem Wohl zu
          beeinträchtigen. Werden wir wegen eines Verstoßes gegen eine dieser
          Pflichten in Anspruch genommen, so stellen Sie uns auf erstes
          Anfordern von allen Ansprüchen Dritter frei. Bei Verstoß gegen eine
          dieser Pflichten sind wir gleichfalls berechtigt, Ihr Firmenprofil zu
          sperren und/oder den Vertrag fristlos zu kündigen. Wir behalten uns
          zudem vor, die Veröffentlichung abzulehnen, wenn deren Inhalt
          möglicherweise einen Verstoß gegen geltende Gesetze, die öffentliche
          Ordnung, die guten Sitten, anerkannte Grundsätze der Moral sowie das
          Wettbewerbs-, Marken- oder Werberecht darstellt. Eine Rückerstattung
          entrichteter Entgelte erfolgt in solchen Fällen nicht. Sie sind
          weiterhin verpflichtet, auf Ihren verlinkten Webangeboten auf deren
          werblichen Inhalt hinzuweisen, wenn redaktionelle und werbliche
          Beiträge vermischt werden, sowie klarzustellen, dass Sie selbst
          inhaltsverantwortlich für diese Webangebote sind.
        </div>
        <div className="text-xs font-bold">§ 7 Zahlungen und Fälligkeit</div>
        <div className="text-xs">
          Aktuell ist die Plattform TRADEFFOX für alle Mitglieder kostenlos.
          Sollten künftig Kosten erhoben werden, werden Mitglieder im Voraus
          über die E-Mail-Adresse, welche bei der Registrierung angegeben wurde,
          benachrichtigt.
        </div>
        <div className="text-xs font-bold">
          § 8 Leistungsstörungen, Abnahme und Haftung
        </div>
        <div className="text-xs">
          Eine Haftung für kurzzeitige, unerhebliche oder von uns nicht zu
          beeinflussende Störungen der Abrufbarkeit Ihres Firmenprofils oder für
          etwaige Nachteile aus der durch Sie zu vertretenden unbefugten
          Verwendung Ihrer Zugangsdaten ist ausgeschlossen. Dies gilt auch für
          etwaige Leistungsstörungen während Wartungsarbeiten; diese berechtigen
          nicht zur Minderung, Kündigung oder Geltendmachung von
          Ersatzansprüchen. Voraussetzung für die Behebung von Störungen und
          Mängeln ist deren rechtzeitige Anzeige. Ansprüche wegen Mängeln, die
          uns nicht innerhalb von zwei Wochen nach erstmaliger Möglichkeit der
          Kenntnisnahme durch Sie schriftlich angezeigt werden, sind
          ausgeschlossen. Werkvertragliche Leistungen gelten mit Ingebrauchnahme
          als abgenommen, spätestens jedoch 10 Tage nach Zugänglichmachung.
          Gewährleistung erfolgt vorrangig durch Nachbesserung. Schlägt diese
          nach zwei erfolglosen Versuchen endgültig fehl, so sind Sie zur
          Herabsetzung der Vergütung oder zur außerordentlichen Kündigung
          berechtigt. Wir haften nur für Schäden, die auf einer grob
          fahrlässigen oder vorsätzlichen Pflichtverletzung oder einer
          fahrlässigen Verletzung von Hauptleistungspflichten durch uns oder
          unsere Gehilfen beruhen. In jedem Fall ist die Haftung der Höhe nach
          auf bei Vertragsschluss vorhersehbare Schäden begrenzt und umfasst
          nicht Fälle höherer Gewalt. Die Verjährungsfrist für Leistungsmängel
          von „day one mediagroup“ wird, sofern diese nicht auf Vorsatz beruhen,
          auf 12 Monate verkürzt.
        </div>
        <div className="text-xs font-bold">
          § 9 Datenverarbeitung und Rechtseinräumung
        </div>
        <div className="text-xs">
          Mit Auftragserteilung stimmen Sie der Verarbeitung, Speicherung und
          Nutzung der zur Auftragsdurchführung, Steigerung der Werbewirkung
          Ihres Firmenprofils, technischen Support sowie zur Performance-Analyse
          benötigten Firmenprofildaten und Nutzungsprofile und der damit
          verbundenen personenbezogenen Daten zu. Ferner räumen Sie „day one
          mediagroup“ im zur Vertragsdurchführung erforderlichen Umfang das
          Recht zur Speicherung, Be- und Verarbeitung sowie öffentlichen
          Zugänglichmachung Ihrer Logos und unternehmens- oder produktbezogenen
          Bilder, Bewegtbilder und Texte auf beliebigen Webseiten und
          vergleichbaren, auch mobilen, Medien und Diensten ein. Eine Weitergabe
          an mit dem Vertragszweck nicht befasste Dritte erfolgt nicht.
        </div>
        <div className="text-xs font-bold">§ 10 Sonstiges</div>
        <div className="text-xs">
          Es gilt das Recht der Bundesrepublik Deutschland. Erfüllungs- und
          Gerichtsort ist der Sitz von „day one mediagroup“. Wir sind
          berechtigt, österreichische Kunden auch am für Wien, Innere Stadt
          zuständigen Gericht zu verklagen, schweizerische Kunden am Gerichtsort
          Baar, alle alternativ auch an dem Ort, an dem sich ihr Vermögen
          befindet. Sämtliche Änderungen dieser AGB einschließlich der
          Textformklausel bedürfen der Textform. Die Unwirksamkeit einzelner
          Bestimmungen berührt nicht die übrige Wirksamkeit der anderen
          Bestimmungen dieser AGB.
        </div>
        <div className="text-xs">Kontakt in Deutschland</div>
        <div className="text-xs font-bold">
          day one mediagroup GmbH & Co. KG
        </div>
        <div className="flex flex-col text-xs">
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Deutschland</div>
        </div>
        <div className="flex flex-col text-xs">
          <div>Telefon: +49 (0) 371 52 49 99 19 00</div>
          <div>Telefax: +49 (0) 371 91 89 56 11</div>
          <div>E-Mail: info@tradefoox.com</div>
        </div>
        <div className="inline-flex gap-1 text-xs">
          <div className="font-bold">USt-IdNr.:</div>
          DE 304342652
        </div>
        <div className="flex flex-col text-xs">
          <div className="inline-flex gap-1">
            <div className="font-bold">Geschäftsführer:</div>
            Ronny Streit, Nico Seifert
          </div>
        </div>
        <div className="text-xs">Allgemeine Geschäftsbedingungen (AGB)</div>
        <div className="text-xs">Stand: 21.September 2021</div>
        <div>
          <div className="text-xs font-bold">Weitere Infos</div>
          <div className="text-xs flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              Datenschutz
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              Urheberrecht
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              Cookie Richtlinie
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              Community Richtlinie
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              Nutzungsbedingungen
            </NavLink>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default ConditionsContainer;
