import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  text: string;
  action: string;
  link?: string;
};
const SuggestionsHeader = ({ text, action, link = "" }: Props) => {
  return (
    <div className="grid grid-cols-2 mb-4 mt-2">
      <div>
        <div className="border-b text-sm font-semibold w-min">{text}</div>
      </div>
      <div className="grid justify-items-end">
        <NavLink
          to={link}
          className="rounded-full bg-gray-300 hover:bg-gray-400 h-full px-2 text-sm"
        >
          {action}
        </NavLink>
      </div>
    </div>
  );
};

export default SuggestionsHeader;
