import React, { PropsWithChildren } from "react";
import tw from "twin.macro";
import { NavLink } from "react-router-dom";

type Props = {
  subTab?: boolean;
  to: string;
  counter?: number;
};
const TabLink = ({
  subTab,
  to,
  children,
  counter,
}: PropsWithChildren<Props>) => (
  <NavLink to={to}>
    {({ isActive }) => (
      <span
        css={[
          tw`relative whitespace-nowrap`,
          isActive ? tw`border-b-2 text-gray-900` : tw`text-gray-500`,
          subTab ? tw`border-darkblue` : tw`border-darkblue`,
        ]}
      >
        {children}
        {counter !== undefined ? (
          <span className="inline-flex items-center rounded-full bg-lightgray px-2 mx-1 text-xs font-light">
            {counter}
          </span>
        ) : null}
      </span>
    )}
  </NavLink>
);

export default TabLink;
