import BackButton from "@/components/nav/BackButton";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

type ArticleType = {
  title: string;
  text: ReactNode;
  youtubeLink: string;
};

const Copyright = () => {
  const articles: Array<ArticleType> = [
    {
      title:
        "Schritt 1 : Was Gründer über Marken wissen sollten - Marken Basiswissen",
      text: "In diesem Video geht es darum, was Gründer über Marken wissen sollten. Wie finde ich heraus, ob es schon ähnliche ältere Marken gibt, mit denen ich kollidieren könnte? Warum ist eine Wortmarke in der Regel besser als eine Wort-/Bildmarke? Wie gehe ich mit Nachahmern um? Warum sollte ich mir einen möglichst fantasievollen Namen ausdenken? All diese Fragen beantworten wir in diesem Video.",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/kXthSf2d5lw?controls=0",
    },
    {
      title:
        "Schritt 2 : Markenrecherche - kostenfreie Recherche nach ähnlichen älteren Marken",
      text: "Die 45 Klassen: http://tmclass.tmdn.org/ec2/ Link zur Recherche: https://www.tmdn.org/tmview/#/tmview",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/AVjU2J0yBgE?controls=0",
    },
    {
      title:
        "Schritt 3 : Wortmarke oder Wortbildmarke? Was melde ich am besten an?",
      text: "",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Kn-alT2ifrI?controls=0",
    },
    {
      title:
        "Schritt 4 : Markenanmeldung - Anmeldung einer Marke - Markenschutz Tutorial & HowTo",
      text: "",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/BklLZ9J-cRo?controls=0",
    },
    {
      title: "Schritt 5 : Deutsche Marke oder EU-weite Unionsmarke?",
      text: "...Meldet man nur eine deutsche Marke oder eine EU-weite Unionsmarke an? Das ist zum einen eine Frage der Kriegskasse. Die Unionsmarke (ab 850 EUR) ist von den minimal anfallenden Amtsgebühren her etwa dreimal so teuer wie die deutsche Marke (ab 290 EUR). Wenn man z.B. einen Patentanwalt hinzuzieht wird die Anmeldung je nach Beratungsumfang, Markenart, Kanzlei und Kompetenz etwa 400 bis 1200 EUR Honorar kosten...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Wpvo4ATs5M8?controls=0",
    },
    {
      title: "Tipp: Verwechslungsgefahr bei Marken",
      text: (
        <>
          Wie stellt ein Gericht oder Amt fest, ob Marken zu ähnlich sind? Es
          gibt da eigentlich immer dieselben Kriterien, die durchgeprüft werden:
          <ul className="list-disc list-inside">
            <li>Sind die Zeichen sehr ähnlich oder identisch?</li>
            <li>
              Sind die gegenüberstehenden Waren oder Dienstleistungen sehr
              ähnlich oder identisch?
            </li>
            <li>
              Ist die ältere Marke besonders bekannt oder hat sie eine sog.
              erhöhte Unterscheidungskraft?
            </li>
            <li>Kennzeichnungskraft der älteren Marke</li>
            <li>Verkehrskreise</li>
          </ul>
        </>
      ),
      youtubeLink: "https://www.youtube-nocookie.com/embed/XXXXXX?controls=0",
    },
    {
      title:
        "Schritt 1 : Patent...Ein Erfinder hat eine neue Idee - Was tut er?",
      text: "...In diesem Video geht es um Erfinder und ihre Ideen. Nehmen wir an, ein Erfinder hat eine neue technische Idee, wie man leichtverderbliche Flüssigkeiten wie Milch leichter abfüllen kann. Was muss er beachten? Wann kann er mit Investoren sprechen? Was muss in einer Geheimhaltungsvereinbarung stehen..?",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Tg13mwAJiK0?controls=0",
    },
    {
      title:
        "Schritt 2 : Patentrecherche - wie recherchiere ich nach Patenten?",
      text: "...Eine Recherche nach Stand der Technik kann erfahrungsgemäß 500 bis 5000 EUR kosten, je nachdem, wie sorgfältig man sucht und welche Datenbanken man einsetzen möchte...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/qdOtmuzvxm8?controls=0",
    },
    {
      title: "Schritt 3 : Patentanmeldung - Patent anmelden - Patentschutz",
      text: "...Mit einer Patentanmeldung beantragt man Schutz für eine neue Technologie. Die Patentanmeldung wird dann beim Patentamt geprüft. Das Prüfungsverfahren dauert dann in der Regel 2 bis 4 Jahre und das Patent wird erteilt oder die Patentanmeldung zurückgewiesen...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/CtJn9g-b4_U?controls=0",
    },
    {
      title:
        "Tipp: Patentansprüche formulieren - 5 Grundprinzipien - 12 häufige Fehler",
      text: "",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Mf82_qC6OHw?controls=0",
    },
    {
      title: "Tipp: Umgehung von Patenten - Wie umgeht man ein Patent?",
      text: "...In diesem Video geht Herr Dr. Rolf Claessen darauf ein, wie der Schutzbereich eines Patentanspruchs grundsätzlich bestimmt wird, was äquivalente Patentverletzung ist und welche Merkmale man in der Umgehungslösung anders gestalten muss, um aus dem Schutzbereich des Patents herauszukommen. Zum Schluss geht er auf einige Entscheidungen ein, die die Beurteilung der Umgehung besonders einfach machen und gibt ein konkretes Beispiel an...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Udwj1MKj6sY?controls=0",
    },
    {
      title: "Tipp: Gebrauchsmuster - was man darüber wissen sollte!",
      text: "...In diesem Video geht es um die wichtigsten Dinge, die man über das Gebrauchsmuster wissen muss. Von der historische Rolle des Gebrauchsmusters als „kleines Patent“ hat sich das Gebrauchsmuster zu einem interessant Schutzrecht entwickelt...",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/Z10l3_hPFuI?controls=0",
    },
    {
      title:
        "Tipp: Designschutz mit einer Designanmeldung/Geschmacksmuster - Kosten",
      text: "...Vor der Anmeldung eines Designs sollte man wissen, dass ein Design zum Anmeldetag neu sein muss, damit es später erfolgreich durchgesetzt werden kann. Die allermeisten Ämter prüfen nämlich nicht, ob das eingereichte Design tatsächlich neu ist. Allerdings wird das bei der Durchsetzung des Designs in der Regel überprüft, so dass man darauf achten sollte....",
      youtubeLink:
        "https://www.youtube-nocookie.com/embed/AN9ZsdkZnO0?controls=0",
    },
  ];

  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/marken_patent_rechte_tradefoox_2.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl whitespace-nowrap">
              Der lange Weg zur Marke ...
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              ... der lange Weg zum Patent!
            </div>
          </div>
        </section>

        <article className="text-sm">
          <div className="font-semibold">Rechtlicher Hinweis:</div>
          <div className="grid grid-flow-row gap-1">
            Soweit diese Website Informationen zu rechtlichen Fragen auf dem
            Gebiet des nationalen und internationalen gewerblichen
            Rechtsschutzes enthält, erheben wir keinen Anspruch auf
            Vollständigkeit dieser Angaben und können für die Richtigkeit nicht
            garantieren. Sie sind daher auch nicht als professionelle
            Rechtsberatung zu verstehen; eine Haftung wird insoweit
            ausgeschlossen. Bevor Du aufgrund einer Information auf dieser
            Website Maßnahmen ergreifst, empfehlen wir in jedem Fall eine
            eingehende rechtliche Beratung durch einen Patent- oder
            Rechtsanwalt.
          </div>
        </article>
        {articles.map((article: ArticleType, index) => {
          return (
            <article className="flex flex-col gap-1" key={index}>
              <h2 className="text-base font-semibold">{article.title}</h2>
              <iframe
                src={article.youtubeLink}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                width="100%"
                height="300px"
              />
              <p className="text-sm">{article.text} </p>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default Copyright;
