import React, { ReactNode } from "react";
import {
  PencilIcon,
  BriefcaseIcon,
  BookmarkIcon,
  EnvelopeIcon,
  ClipboardDocumentListIcon,
  ArrowUpOnSquareIcon,
  BuildingStorefrontIcon,
  FolderIcon,
  Cog6ToothIcon,
  HeartIcon,
  ShoppingCartIcon,
  GiftIcon,
  TagIcon,
  UsersIcon,
  NewspaperIcon,
  VideoCameraIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import {
  PencilIcon as PencilIconSolid,
  BriefcaseIcon as BriefcaseIconSolid,
  BookmarkIcon as BookmarkIconSolid,
  EnvelopeIcon as EnvelopeIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  ArrowUpOnSquareIcon as ArrowUpOnSquareIconSolid,
  BuildingStorefrontIcon as BuildingStorefrontIconSolid,
  FolderIcon as FolderIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  HeartIcon as HeartIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  GiftIcon as GiftIconSolid,
  TagIcon as TagIconSolid,
  UsersIcon as UsersIconSolid,
  UserIcon as UserIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  VideoCameraIcon as VideoCameraIconSolid,
} from "@heroicons/react/24/solid";

export type ListType = {
  href: string;
  label: string;
  icon: ReactNode;
  solidIcon: ReactNode;
  style?: string;
  notification?: { count: number; minimum: number; link: string; type: string };
  reverse?: boolean;
  children?: Array<ListType>;
  extern?: boolean;
};

const NavSideLinkList = (t: TFunction): Array<ListType> => [
  {
    href: "",
    label: t("main.panel.settings"),
    icon: <Cog6ToothIcon className="w-4 h-4" />,
    solidIcon: <Cog6ToothIconSolid className="w-4 h-4" />,
    style: "hidden xl:flex",
    children: [
      {
        href: "/verwaltung/profile",
        label: t("main.panel.profileWidgets.editProfile"),
        icon: <PencilIcon className="w-4 h-4" />,
        solidIcon: <PencilIconSolid className="w-4 h-4" />,
      },
      {
        href: "/verwaltung/account",
        label: t("main.panel.profileWidgets.account"),
        icon: <UserIcon className="w-4 h-4" />,
        solidIcon: <UserIconSolid className="w-4 h-4" />,
      },
    ],
  },
  {
    href: "/news",
    label: t("main.navLinks.news"),
    icon: <NewspaperIcon className="w-4 h-4" />,
    solidIcon: <NewspaperIconSolid className="w-4 h-4" />,
  },
  {
    href: "/watch",
    label: t("main.navLinks.watch"),
    icon: <VideoCameraIcon className="w-4 h-4" />,
    solidIcon: <VideoCameraIconSolid className="w-4 h-4" />,
  },
  {
    href: "/messenger",
    label: t("main.navLinks.messenger"),
    icon: <EnvelopeIcon className="w-4 h-4" />,
    solidIcon: <EnvelopeIconSolid className="w-4 h-4" />,
    notification: { count: 0, minimum: 1, link: "", type: "number" },
  },
  {
    href: "/kontakte",
    label: t("main.header.links.contacts"),
    icon: <UsersIcon className="w-4 h-4" />,
    solidIcon: <UsersIconSolid className="w-4 h-4" />,
    notification: {
      count: 0,
      minimum: 1,
      link: "/kontakte",
      type: "number",
    },
  },
  {
    href: "/verwaltung/jobs/jobs_erstellen",
    label: t("main.navLinks.jobSpublished.title"),
    icon: <BriefcaseIcon className="w-4 h-4" />,
    solidIcon: <BriefcaseIconSolid className="w-4 h-4" />,
    notification: { count: 0, minimum: 1, link: "", type: "dot" },
  },
  {
    href: "/verwaltung/inserate/inserate_erstellen",
    label: t("main.navLinks.createAd.title"),
    icon: <TagIcon className="w-4 h-4 rotate-180 -scale-y-100" />,
    solidIcon: <TagIconSolid className="w-4 h-4 rotate-180 -scale-y-100" />,
  },
  {
    href: "/marketplace",
    label: t("main.navLinks.marketplace.title"),
    icon: <BuildingStorefrontIcon className="w-4 h-4" />,
    solidIcon: <BuildingStorefrontIconSolid className="w-4 h-4" />,
    children: [
      {
        href: "/verwaltung/marketplace/warenkorb",
        label: t("main.navLinks.marketplace.card"),
        icon: <ShoppingCartIcon className="w-4 h-4" />,
        solidIcon: <ShoppingCartIconSolid className="w-4 h-4" />,
      },
      {
        href: "/verwaltung/marketplace/wunschliste",
        label: t("main.navLinks.marketplace.wishList"),
        icon: <HeartIcon className="w-4 h-4" />,
        solidIcon: <HeartIconSolid className="w-4 h-4" />,
      },
      {
        href: "/verwaltung/marketplace/kaufübersicht",
        label: t("main.navLinks.marketplace.viewCard"),
        icon: <GiftIcon className="w-4 h-4" />,
        solidIcon: <GiftIconSolid className="w-4 h-4" />,
      },
    ],
  },
  {
    href: "/verwaltung/mein_shop",
    label: t("main.navLinks.myShop.title"),
    icon: <FolderIcon className="w-4 h-4" />,
    solidIcon: <FolderIconSolid className="w-4 h-4" />,
    children: [
      {
        href: "/verwaltung/mein_shop/verkaufen",
        label: t("main.navLinks.myShop.sell"),
        icon: <PencilIcon className="w-4 h-4" />,
        solidIcon: <PencilIconSolid className="w-4 h-4" />,
      },
      {
        href: "/verwaltung/mein_shop/vorlagen",
        label: t("main.navLinks.myShop.templates"),
        icon: <ClipboardDocumentListIcon className="w-4 h-4" />,
        solidIcon: <ClipboardDocumentListIconSolid className="w-4 h-4" />,
      },
      {
        href: "/verwaltung/mein_shop/importieren",
        label: t("main.navLinks.myShop.import"),
        icon: <ArrowUpOnSquareIcon className="w-4 h-4" />,
        solidIcon: <ArrowUpOnSquareIconSolid className="w-4 h-4" />,
      },
    ],
  },
  {
    href: "/bookmarks",
    label: t("main.navLinks.watchLists"),
    icon: <BookmarkIcon className="w-4 h-4" />,
    solidIcon: <BookmarkIconSolid className="w-4 h-4" />,
  },
  {
    href: "/entdecken",
    label: t("main.navLinks.discover"),
    icon: <img src="/images/compass.svg" className="w-4 h-4" />,
    solidIcon: <img src="/images/compass_solid.svg" className="w-4 h-4" />,
  },
];

export default NavSideLinkList;
