import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import UserWidgetFollowButton from "@components/elements/UserWidgetFollowButton";
import tw, { TwStyle } from "twin.macro";
import VerifyBadge from "@components/elements/VerifyBadge";
import { useAppSelector } from "@/state/hooks";
import FullScreenImage from "@components/elements/shared/FullScreenImage";

type Props = {
  user: User;
  follow?: boolean;
  showName?: boolean;
  sideName?: boolean;
  online?: boolean;
  size?: number;
  verified?: boolean;
  key?: string;
  nameStyle?: TwStyle;
  avatarFullscreen?: boolean;
};

const UserWidget = ({
  user,
  showName = false,
  follow = true,
  online = true,
  verified = false,
  nameStyle,
  ...props
}: Props) => {
  const { user: userData } = useAppSelector((state) => state.user);
  const self = userData && userData.id === user.id;
  const followed = self || !follow || user.followed || false;
  online = online && true;
  const size = props.size || 50;
  const placeholder = `/images/placeholder/${user.type}.png`;
  const [openAvatar, setOpenAvatar] = useState(false);

  return (
    <NavLink
      to={`/profile/${user.id}`}
      onClick={(e) => {
        if (props.avatarFullscreen) {
          setOpenAvatar(!openAvatar);
          e.preventDefault();
        }
      }}
    >
      <div
        css={[
          tw`cursor-pointer relative w-full`,
          props.sideName && tw`flex`,
          props.avatarFullscreen && tw`pointer-events-auto`,
        ]}
      >
        <div
          css={[
            tw`rounded-full border border-gray-300 relative bg-white shrink-0`,
            !props.sideName && tw`mx-auto`,
          ]}
          style={{
            width: `${size.toString()}px`,
            height: `${size.toString()}px`,
          }}
        >
          <div
            css={[
              tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`,
            ]}
          >
            <img
              className="w-full"
              src={user.avatar || placeholder}
              alt={getName(user)}
            />
            {!!openAvatar && (
              <FullScreenImage
                onClose={() => setOpenAvatar(false)}
                imageUrl={user.avatar || placeholder}
              />
            )}
          </div>
          {online && (
            <div
              css={[
                tw`absolute rounded-full border-white bg-green-500 w-[25%] h-[25%] right-[2%] bottom-[2%]`,
              ]}
            />
          )}
          {!followed && userData && (
            <UserWidgetFollowButton user={user} me={userData} size={size} />
          )}
        </div>
        {showName && (
          <div
            css={[
              tw`text-xs text-left whitespace-nowrap text-gray-600 flex justify-center items-center gap-1 w-full`,
              !props.sideName && tw`mt-2`,
              props.sideName &&
                tw`text-sm font-bold h-[50px] leading-[50px] text-center pl-3 justify-start`,
              tw`truncate`,
            ]}
          >
            <div
              css={[
                tw`truncate`,
                verified ? tw`max-w-[calc(100%-16px)]` : tw`w-full`,
                !props.sideName ? tw`text-center` : tw`text-left`,
                nameStyle,
              ]}
            >
              {getName(user)}
            </div>
            {verified && <VerifyBadge type={user.type} />}
          </div>
        )}
      </div>
    </NavLink>
  );
};

export default UserWidget;
