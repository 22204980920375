import React, { useRef } from "react";
import Panel from "@components/box/Panel";
import SuggestionsCenterItem from "@components/elements/sidebar/suggestions/SuggestionsCenterItem";
import axios from "axios";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { Post } from "@/utils/post";
import api from "@/api/api";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import ScrollIcons from "@components/elements/shared/ScrollIcons";
import { useTranslation } from "react-i18next";

type Props = { key?: string };

type Response = {
  data: Array<Post>;
};

const NewsfeedSuggestions = ({ key }: Props) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const posts = useQuery<Response, Error>(["posts"], async () => {
    const { data } = await api.get("/api/v1/feed");
    return data;
  });

  if (!posts.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title="Beliebte News auf Tradefoox" mobileBorder={false} key={key}>
        <div className="relative">
          <div
            className="flex gap-4 overflow-x-auto no-scrollbar"
            ref={galleryRef}
          >
            {posts.data.data
              .filter((item) => item.media.length > 0)
              .map((item) => {
                return (
                  <SuggestionsCenterItem
                    key={item.id}
                    name={""}
                    link={"/news/für_dich?featured=" + item.id}
                    picture={
                      item.media[0].type === "image"
                        ? `${item.media[0].data_url}/post`
                        : `${item.media[0].data_url}/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop`
                    }
                    imageStyle={tw`rounded-xl w-[150px] h-[150px]`}
                    nameStyle={tw`w-[150px]`}
                  />
                );
              })}
            <NavLink to="/news/">
              <div className="w-[100px] h-[150px] flex flex-col justify-center text-center">
                {t("buttons.showMore")}
              </div>
            </NavLink>
            <ScrollIcons galleryRef={galleryRef} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
export default NewsfeedSuggestions;
