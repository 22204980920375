import React from "react";
import Panel from "@components/box/Panel";
import BackButton from "@/components/nav/BackButton";

const TermsOfUseContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-3 text-gray-700 text-xs">
        <h1 className="text-sm font-bold">Nutzungsbedingungen</h1>
        <div>Gültig ab 1. Februar 2022</div>
        <div>
          Unsere Mission ist es, Fachleute, private User sowie Vereine auf der
          ganzen Welt zu vernetzen, damit diese produktiver und erfolgreicher
          arbeiten können. Unsere Dienstleistungen zielen darauf ab, die
          wirtschaftlichen Chancen unserer Mitglieder zu fördern, indem wir Dir
          und Millionen anderer Fachleute die Möglichkeit geben, sich in einem
          Netzwerk vertrauenswürdiger Beziehungen zu treffen, miteinander
          auszutauschen und zu vernetzen, Ideen auszutauschen, zu lernen und
          Möglichkeiten oder Mitarbeiter zu finden, zu arbeiten und
          Entscheidungen zu treffen.
        </div>
        <h2 className="font-semibold">Inhaltsverzeichnis:</h2>
        <ul className="list-inside list-decimal">
          <li>Einführung</li>
          <li>Verpflichtungen</li>
          <li>Rechte und Grenzen</li>
          <li>Haftungsausschluss und Haftungsbeschränkung</li>
          <li>Beendigung</li>
          <li>Geltendes Recht und Streitbeilegung</li>
          <li>Allgemeine Geschäftsbedingungen</li>
          <li>TRADEFOOX „Dos and Don&apos;ts“</li>
          <li>Beschwerden bezüglich des Inhalts</li>
          <li>Wie Sie uns erreichen</li>
        </ul>

        <h2 className="font-semibold">1. Einführung</h2>
        <h2 className="font-semibold">1.1 Vertrag</h2>
        <div>
          Wenn Du unsere Dienste nutzt, stimmst Du allen diesen Bedingungen zu.
          Die Nutzung unserer Dienste unterliegt auch unserer Cookie-Richtlinie
          und unserer Datenschutzrichtlinie, die regeln, wie wir Deine
          persönlichen Daten erfassen, verwenden, weitergeben und speichern.
        </div>
        <div>
          Du erklärst sich damit einverstanden, dass Du durch Klicken auf „Jetzt
          beitreten“, „TRADEFOOX beitreten“, „Anmelden“ oder ähnliches, durch
          die Registrierung, den Zugriff auf oder die Nutzung unserer Dienste
          (unten beschrieben) einen rechtsverbindlichen Vertrag mit TRADEFOOX
          abschließen (auch wenn Du unsere Dienste im Namen eines Unternehmens
          nutzt). Wenn Du diesem Vertrag („Vertrag“ oder „Benutzervereinbarung“)
          nicht zustimmst, klicke nicht auf „Jetzt beitreten“, „TRADEFOOX
          beitreten“, „Anmelden“ oder ähnliches und greife nicht auf unsere
          Dienste zu oder nutzen diese nicht anderweitig. Wenn Du diesen Vertrag
          kündigen möchtest, kannst Du dies jederzeit tun, indem Du Dein Konto
          schließt und nicht mehr auf unsere Dienste zugreifst oder diese nutzt.
        </div>
        <h2 className="font-semibold">Dienstleistungen</h2>
        <div>
          Dieser Vertrag gilt für TRADEFOOX.com, Apps der Marke TRADEFOOX und
          andere TRADEFOOX-bezogene Websites, Apps, Kommunikations- und andere
          Dienste, die angeben, dass sie im Rahmen dieses Vertrags angeboten
          werden („Dienste“), einschließlich der externen Datenerfassung für
          diese Dienste, wie unsere Anzeigen und die Plugins „Mit TRADEFOOX
          bewerben“ und „Mit TRADEFOOX teilen“. Registrierte Benutzer unserer
          Dienste sind „Mitglieder“ und nicht registrierte Benutzer sind
          „Besucher“.
        </div>
        <h2 className="font-semibold">TRADEFOOX</h2>
        <div>
          Du schließt diesen Vertrag mit TRADEFOOX (auch als „wir“ und „uns“
          bezeichnet).
        </div>
        <div>
          Wir verwenden den Begriff „designierte Länder“ für Länder in der
          Europäischen Union (EU), im Europäischen Wirtschaftsraum (EWR) und in
          der Schweiz.
        </div>
        <div>
          Wenn Du in den „bestimmten Ländern“ ansässig bist, schließt Du diesen
          Vertrag mit TRADEFOOX Germany ab und TRADEFOOX Germany ist der
          Verantwortliche für Deine personenbezogenen Daten, die Dir zur
          Verfügung gestellt, von Dir oder für Dich erfasst oder verarbeitet
          werden in Verbindung mit unseren Diensten.
        </div>
        <div>
          Wenn Du außerhalb der „bezeichneten Länder“ ansässig bist, schließt
          Sie diesen Vertrag mit TRADEFOOX Corporation („TRADEFOOX Corp.“) ab
          und TRADEFOOX Corp. ist der Verantwortliche für Deine
          personenbezogenen Daten, die Dir zur Verfügung gestellt, von Dir oder
          für Dich erfasst oder verarbeitet werden in Verbindung mit unseren
          Dienstleistungen.
        </div>
        <div>Dieser Vertrag gilt für Mitglieder und Besucher.</div>
        <div>
          Als Besucher oder Mitglied unserer Dienste unterliegt die Erhebung,
          Nutzung und Weitergabe Deiner personenbezogenen Daten dieser
          Datenschutzrichtlinie (einschließlich unserer Cookie-Richtlinie und
          anderen Dokumenten, auf die in dieser Datenschutzrichtlinie verwiesen
          wird) und Aktualisierungen.
        </div>
        <h2 className="font-semibold">1.2 Mitglieder und Besucher</h2>
        <div>
          Wenn Du Dich registrierst und den TRADEFOOX-Diensten beitrittst, wirst
          Du Mitglied. Wenn Du Dich entschieden hast, Dich nicht für unsere
          Dienste zu registrieren, kannst Du als „Besucher“ auf bestimmte
          Funktionen zugreifen.
        </div>
        <h2 className="font-semibold">1.3 Änderung</h2>
        <div>Wir können Änderungen am Vertrag vornehmen.</div>
        <div>
          Wir können diesen Vertrag, unsere Datenschutzrichtlinie und unsere
          Cookie-Richtlinie von Zeit zu Zeit ändern. Wenn wir wesentliche
          Änderungen daran vornehmen, werden wir Dich über unsere Dienste oder
          auf andere Weise darüber informieren, um Dir die Möglichkeit zu geben,
          die Änderungen zu überprüfen, bevor sie in Kraft treten. Wir stimmen
          zu, dass Änderungen nicht rückwirkend sein können. Wenn Du den
          Änderungen widersprichst, kannst Du Dein Konto schließen . Deine
          fortgesetzte Nutzung unserer Dienste, nachdem wir unsere Änderungen an
          diesen Bedingungen veröffentlicht oder eine Mitteilung darüber
          gesendet haben, bedeutet, dass Du den aktualisierten Bedingungen ab
          dem Datum ihres Inkrafttretens zustimmst.
        </div>
        <h2 className="font-semibold">2. Pflichten</h2>
        <h2 className="font-semibold">2.1 Serviceberechtigung</h2>
        <div>
          Du bist berechtigt, diesen Vertrag abzuschließen, und Du hast
          mindestens unser „Mindestalter“ erreicht.
        </div>
        <div>
          Die Dienste dürfen nicht von Personen unter 16 Jahren genutzt werden.
        </div>
        <div>
          Um die Dienste nutzen zu können, erklärst Du Dich damit einverstanden,
          dass: (1) Du das „Mindestalter“ besitzt (unten beschrieben) oder älter
          bist ; (2) Du verfügst nur über ein TRADEFOOX-Konto, das auf Deinen
          echten Namen lauten muss; und (3) Du wirst von TRADEFOOX nicht bereits
          an der Nutzung der Dienste gehindert. Das Erstellen eines Kontos mit
          falschen Informationen stellt einen Verstoß gegen unsere Bedingungen
          dar, einschließlich Konten, die im Namen anderer oder Personen unter
          16 Jahren registriert wurden.
        </div>
        <div>
          „Mindestalter“ bedeutet 16 Jahre. Wenn das Gesetz jedoch vorschreibt,
          dass Du älter sein musst, damit TRADEFOOX Dir die Dienste ohne
          Zustimmung der Eltern rechtmäßig bereitstellen kann (einschließlich
          der Verwendung Deiner personenbezogenen Daten), dann ist das
          Mindestalter das höhere Alter.
        </div>
        <h2 className="font-semibold">2.2 Dein Konto</h2>
        <div>Du wirst Dein Passwort geheim halten</div>
        <div>
          Du wirst Dein Konto nicht mit anderen teilen und unsere Regeln und das
          Gesetz befolgen.
        </div>
        <div>
          Mitglieder sind Kontoinhaber. Du stimmst zu: (1) ein sicheres Passwort
          zu verwenden und es vertraulich zu behandeln; (2) keinen Teil Deines
          Kontos (z. B. Verbindungen)zu teilen und (3) die Gesetze und unsere
          Liste mit Verhaltensregeln und professionellen Community-Richtlinien
          zu befolgen. Du bist für alles verantwortlich, was über Dein Konto
          geschieht, es sei denn, es wurde bereits von Dir geschlossen oder es
          wurde bereits unter der Rubrik „Missbrauch melden“ bei uns
          registriert.
        </div>
        <div>
          Im Verhältnis zwischen Dir und anderen (einschließlich Deinem
          Arbeitgeber) gehört Dein Konto Dir. Wenn die Dienste jedoch von einer
          anderen Partei für Deine Nutzung erworben wurden (z. B. ein von Deinem
          Arbeitgeber gekaufter Recruiter-Platz), hat die Partei, die diesen
          Dienst bezahlt, das Recht, den Zugang zu diesem kostenpflichtigen
          Dienst zu kontrollieren und Berichte über Deine Nutzung dieses
          kostenpflichtigen Dienstes zu erhalten; damit entstehen aber keine
          Rechte an Deinem persönlichen Konto.
        </div>
        <h2 className="font-semibold">2.3 Zahlung</h2>
        <div>
          Du kommst deinen Zahlungsverpflichtungen nach und bist damit
          einverstanden, dass wir deine Zahlungsinformationen speichern. Du
          verstehst, dass zu unseren Preisen möglicherweise Gebühren und Steuern
          hinzukommen.
        </div>
        <div>Rückerstattungen unterliegen unseren Richtlinien.</div>
        <div>
          Wenn Du einen unserer kostenpflichtigen Dienste („Premiumdienste“)
          kaufst, erklärst Du Dich damit einverstanden, uns die anfallenden
          Gebühren und Steuern sowie zusätzliche Bedingungen zu zahlen, die für
          die kostenpflichtigen Dienste spezifisch sind. Die Nichtzahlung dieser
          Gebühren führt zur Kündigung Deiner kostenpflichtigen Dienste.
          Außerdem stimmst Du Folgendem zu:
        </div>
        <ul className="list-disc list-inside">
          <li>
            Für Deinen Kauf/Verkauf können Wechselkursgebühren oder
            Preisunterschiede je nach Standort (z. B. Wechselkurse) anfallen.
          </li>
          <li>
            Wir können Deine Zahlungsmethode (z. B. Kreditkarte) speichern und
            weiterhin abrechnen, auch wenn diese abgelaufen ist, um
            Unterbrechungen bei Deinen Diensten zu vermeiden und um sie für die
            Bezahlung anderer von Dir gekaufter Dienste zu nutzen.
          </li>
          <li>
            Wenn Du ein Abonnement erwirbst, wird Deine Zahlungsmethode
            automatisch zu Beginn jedes Abonnementzeitraums mit den für diesen
            Zeitraum geltenden Gebühren und Steuern belastet. Um zukünftige
            Gebühren zu vermeiden, kündige vor dem Verlängerungsdatum. Erfahre
            wie Du Deine Premium-Dienste kündigen oder aussetzen kannst.
          </li>
          <li>
            Alle Deine Käufe/Verkäufe von Diensten unterliegen der
            Rückerstattungsrichtlinie von TRADEFOOX.
          </li>
          <li>
            Wir können die von Dir zu zahlenden Steuern auf der Grundlage der
            Rechnungsinformationen berechnen, die Du uns zum Zeitpunkt des
            Kaufs/Verkaufs zur Verfügung stellst.
          </li>
        </ul>
        <div>
          Du kannst eine Kopie Deiner Rechnung über die Einstellungen Deines
          TRADEFOOX-Kontos unter „Kaufübersicht“ erhalten.
        </div>
        <h2 className="font-semibold">2.4 Hinweise und Nachrichten</h2>
        <div>
          Du bist damit einverstanden, dass wir Dir über unsere Websites, Apps
          und Kontaktinformationen Mitteilungen und Nachrichten zukommen lassen.
          Wenn Deine Kontaktinformationen veraltet sind, verpasst du
          möglicherweise wichtige Mitteilungen.
        </div>
        <div>
          Du erklärst sich damit einverstanden, dass wir Dir Mitteilungen und
          Nachrichten auf folgende Weise zukommen lassen: (1) innerhalb des
          Dienstes oder (2) an die von Dir angegebenen Kontaktinformationen (z.
          B. E-Mail, Mobiltelefonnummer, physische Adresse). Du erklärst sich
          damit einverstanden, Deinee Kontaktinformationen auf dem neuesten
          Stand zu halten.
        </div>
        <div>
          Bitte überprüfe Deine Einstellungen, um die Nachrichten, die Du von
          uns erhältst zu kontrollieren und einzuschränken.
        </div>
        <h2 className="font-semibold">2.5 Teilen</h2>
        <div>
          Wenn Du Informationen über unsere Dienste weitergibst, können andere
          diese Informationen sehen, kopieren und verwenden.
        </div>
        <div>
          Unsere Dienste ermöglichen das Versenden von Nachrichten und das
          Teilen von Informationen auf vielfältige Weise, beispielsweise über
          Dein Profil, Artikel, Gruppenbeiträge, Links zu Nachrichtenartikeln,
          Stellenausschreibungen, Nachrichten und InMails. Informationen und
          Inhalte, die Du teilst oder veröffentlichst, können von anderen
          Mitgliedern, Besuchern oder anderen Personen gesehen werden (auch
          außerhalb der Dienste). Wenn wir Einstellungen zur Verfügung gestellt
          haben, berücksichtigen wir die von Dir getroffenen Entscheidungen
          darüber, wer Inhalte oder Informationen sehen kann (z. B.
          Nachrichteninhalte an Deine Adressaten senden, Inhalte nur für
          TRADEFOOX-Verbindungen freigeben, die Sichtbarkeit Deines Profils in
          Suchmaschinen einschränken oder sich dafür entscheiden, keine
          Benachrichtigungen zu tätigen). Bei Aktivitäten zur Jobsuche
          benachrichtigen wir standardmäßig weder Dein Verbindungsnetzwerk noch
          die Öffentlichkeit. Wenn Du Dich also über unseren Service auf eine
          Stelle bewirbst oder Dein Interesse an einer Stelle signalisieren
          möchtest,
        </div>
        <div>
          Wir sind nicht verpflichtet, Informationen oder Inhalte auf unserem
          Dienst zu veröffentlichen und können diese mit oder ohne
          Vorankündigung entfernen.
        </div>
        <h2 className="font-semibold">3.3. Rechte und Grenzen</h2>
        <div>
          Du bist Eigentümer aller Inhalte, Rückmeldungen und persönlichen
          Informationen, die Du uns zur Verfügung stellst, gewährst uns aber
          auch eine nicht ausschließliche Lizenz dafür.
        </div>
        <div>
          Wir respektieren die Entscheidungen, die Du darüber triffst, wer Deine
          Informationen und Inhalte sehen darf, einschließlich der Art und
          Weise, wie diese für Anzeigen verwendet werden können.
        </div>
        <div>
          Im Verhältnis zwischen Dir und TRADEFOOX bist Du Eigentümer der
          Inhalte und Informationen, die Du an die Dienste übermittelst oder
          veröffentlichst, und Du gewährst TRADEFOOX und unseren verbundenen
          Unternehmen lediglich die folgende nicht exklusive Lizenz:
        </div>
        <div>
          Ein weltweites, übertragbares und unterlizenzierbares Recht,
          Informationen und Inhalte, die Du über unsere Dienste und die Dienste
          anderer bereitstellst, ohne weitere Zustimmung, Mitteilung und/oder
          Vergütung an Dich oder andere zu nutzen, zu kopieren, zu ändern, zu
          verteilen, zu veröffentlichen und zu verarbeiten. Diese Rechte werden
          wie folgt eingeschränkt:
        </div>
        <ul className="list-disc list-inside">
          <li>
            Du kannst diese Lizenz für bestimmte Inhalte beenden, indem Du diese
            Inhalte aus den Diensten löschst oder ganz allgemein Dein Konto
            schließt, außer (a) in dem Umfang, in dem Du sie im Rahmen des
            Dienstes mit anderen geteilt hast und diese sie kopiert, erneut
            geteilt oder gespeichert haben und (b) für die angemessene Zeit, die
            zum Entfernen aus Backup- und anderen Systemen erforderlich ist.
          </li>
          <li>
            Wir werden Deine Inhalte ohne Deine gesonderte Zustimmung nicht in
            Werbung für Produkte und Dienstleistungen Dritter einbeziehen
            (einschließlich gesponserter Inhalte). Wir haben jedoch das Recht,
            ohne Bezahlung an Dich oder andere Anzeigen in der Nähe Deiner
            Inhalte und Informationen zu schalten, und Deine sozialen Aktionen
            können sichtbar und in Anzeigen enthalten sein, wie in der
            Datenschutzrichtlinie angegeben. Wenn Du eine Dienstfunktion nutzt,
            können wir dies mit Deinem Namen oder Foto erwähnen, um diese
            Funktion innerhalb unserer Dienste zu bewerben, abhängig von Deinen
            Einstellungen.
          </li>
          <li>
            Wir holen Deine Zustimmung ein, wenn wir anderen das Recht geben
            möchten, Deine Inhalte über die Dienste hinaus zu veröffentlichen.
            Wenn Du Deinen Beitrag jedoch als „Öffentlich, für alle oder
            ähnlich“ teilst, aktivieren wir eine Funktion, die es anderen
            Mitgliedern ermöglicht, diesen öffentlichen Beitrag in Dienste
            Dritter einzubetten, und wir ermöglichen Suchmaschinen, diesen
            öffentlichen Inhalt auffindbar zu machen. Erfahren Sie mehr
          </li>
          <li>
            Während wir Deinen Inhalt möglicherweise bearbeiten und
            Formatänderungen daran vornehmen (z. B. ihn übersetzen oder
            transkribieren, die Größe, das Layout oder den Dateityp ändern oder
            Metadaten entfernen), werden wir die Bedeutung des Ausdrucks nicht
            ändern.
          </li>
          <li>
            Da Deine Inhalte und Informationen Dir gehören und wir nur über
            nicht-exklusive Rechte daran verfügen, kannst Du Dich sich dafür
            entscheiden, sie anderen zur Verfügung zu stellen, auch im Rahmen
            einer Creative-Commons-Lizenz.
          </li>
        </ul>
        <div>
          Du und TRADEFOOX stimmen zu, dass Inhalte, die personenbezogene Daten
          enthalten, unserer Datenschutzrichtlinie unterliegen.
        </div>
        <div>
          Du und TRADEFOOX stimmen zu, dass wir auf alle von Dir
          bereitgestellten Informationen und personenbezogenen Daten gemäß den
          Bestimmungen der Datenschutzrichtlinie und deinen Wahlmöglichkeiten
          (einschließlich Einstellungen) zugreifen, diese speichern, verarbeiten
          und nutzen dürfen.
        </div>
        <div>
          Durch die Übermittlung von Vorschlägen oder anderem Feedback zu
          unseren Diensten an TRADEFOOX erklärst Du Dich damit einverstanden,
          dass TRADEFOOX dieses Feedback für beliebige Zwecke nutzen und
          weitergeben kann (aber nicht muss), ohne dass Dir dafür eine Vergütung
          entsteht.
        </div>
        <div>
          Du erklärst Dich damit einverstanden, nur Inhalte oder Informationen
          bereitzustellen, die weder gegen das Gesetz noch gegen die Rechte
          anderer (einschließlich geistiger Eigentumsrechte) verstoßen. Du
          stimmst außerdem zu, dass Ihre Profilinformationen wahrheitsgemäß
          sind. TRADEFOOX kann in bestimmten Ländern gesetzlich dazu
          verpflichtet sein, bestimmte Informationen oder Inhalte zu entfernen.
        </div>
        <h2 className="font-semibold">3.2 Serviceverfügbarkeit</h2>
        <div>
          Wir können jeden Dienst ändern oder beenden oder unsere Preise in
          Zukunft ändern.
        </div>
        <div>
          Wir können unsere Dienste ändern, aussetzen oder einstellen. Wir
          können unsere Preise auch mit Wirkung für die Zukunft im gesetzlich
          zulässigen Umfang mit angemessener Vorankündigung ändern.
        </div>
        <div>
          Wir gewährleisten nicht, die von Dir geposteten Informationen und
          Inhalte dauerhaft zu speichern oder weiterhin anzuzeigen. TRADEFOOX
          ist kein Speicherdienst. Du erklärst Dich damit einverstanden, dass
          wir nicht verpflichtet sind, Inhalte oder Informationen, die Du oder
          andere bereitstellen, zu speichern, zu pflegen oder Dir eine Kopie
          davon zur Verfügung zu stellen, außer in dem gesetzlich
          vorgeschriebenen Umfang und wie in unserer Datenschutzrichtlinie
          angegeben.
        </div>
        <h2 className="font-semibold">3.3 Andere Inhalte, Websites und Apps</h2>
        <div>
          Die Nutzung der auf unseren Diensten veröffentlichten Inhalte und
          Informationen anderer erfolgt auf eigenes Risiko.
        </div>
        <div>
          Andere bieten möglicherweise ihre eigenen Produkte und
          Dienstleistungen über unsere Dienste an und wir sind nicht für diese
          Aktivitäten Dritter verantwortlich.
        </div>
        <div>
          Durch die Nutzung der Dienste kannst Du auf Inhalte oder Informationen
          stoßen, die möglicherweise ungenau, unvollständig, verzögert,
          irreführend, illegal, anstößig oder anderweitig schädlich sind.
          TRADEFOOX überprüft im Allgemeinen keine Inhalte, die von unseren
          Mitgliedern oder anderen bereitgestellt werden. Du erklärst Dich damit
          einverstanden, dass wir nicht für die Inhalte oder Informationen
          anderer (einschließlich anderer Mitglieder) verantwortlich sind. Wir
          können diesen Missbrauch unserer Dienste nicht immer verhindern und Du
          stimmst zu, dass wir für einen solchen Missbrauch nicht verantwortlich
          sind. Du erkennst auch das Risiko an, dass Du oder Deine Organisation
          fälschlicherweise mit Inhalten über andere in Verbindung gebracht
          werden, wenn wir Kontakte und Follower darüber informieren, dass Du
          oder Deine Organisation in den Nachrichten erwähnt wurden. Mitglieder
          haben bei dieser Funktion die Wahl.
        </div>
        <div>
          TRADEFOOX kann dabei helfen, Mitglieder, die ihre Dienstleistungen
          anbieten (Karrierecoaching, Buchhaltung usw.), mit Mitgliedern zu
          verbinden, die Dienstleistungen suchen. TRADEFOOX erbringt diese
          Dienste nicht und beschäftigt auch keine Personen damit. Du musst
          mindestens 18 Jahre alt sein, um diese Dienstleistungen anbieten,
          erbringen oder beschaffen zu können. Du erkennst an, dass TRADEFOOX
          die Mitglieder bei der Erbringung dieser Dienste nicht beaufsichtigt,
          anweist, kontrolliert oder überwacht, und stimmst zu, dass (1)
          TRADEFOOX nicht für das Angebot, die Leistung oder die Beschaffung
          dieser Dienste verantwortlich ist und (2) TRADEFOOX keine besonderen
          Empfehlungen abgibt Die angebotenen Dienstleistungen des Mitglieds und
          (3) nichts begründen ein Beschäftigungs-, Agentur- oder
          Joint-Venture-Verhältnis zwischen TRADEFOOX und einem Mitglied, das
          Dienstleistungen anbietet. Wenn Du Mitglied bist und Dienstleistungen
          anbietest, Professionelle Community-Richtlinien.
        </div>
        <div>
          Ebenso kann TRADEFOOX Dir dabei helfen, sich für von Mitgliedern
          organisierte Veranstaltungen zu registrieren und/oder daran
          teilzunehmen und mit anderen Mitgliedern in Kontakt zu treten, die an
          solchen Veranstaltungen teilnehmen. Du erklärst Dich damit
          einverstanden, dass (1) TRADEFOOX nicht für das Verhalten von
          Mitgliedern oder anderen Teilnehmern bei solchen Veranstaltungen
          verantwortlich ist, (2) TRADEFOOX keine bestimmte in unseren Diensten
          aufgeführte Veranstaltung unterstützt, (3) TRADEFOOX keine Bewertungen
          durchführt und/oder keine dieser Veranstaltungen überprüft und (4)
          dass Du Dich an die für diese Veranstaltungen geltenden
          Geschäftsbedingungen hältst.
        </div>
        <h2 className="font-semibold">3.4 Grenzen</h2>
        <div>
          Wir haben das Recht, die Art und Weise, wie Du Dich mit unseren
          Diensten verbindest und/oder interagierst, einzuschränken.
        </div>
        <div>
          TRADEFOOX behält sich das Recht vor, Deine Nutzung der Dienste
          einzuschränken, einschließlich der Anzahl Deiner Verbindungen und
          Deiner Möglichkeit, andere Mitglieder zu kontaktieren. TRADEFOOX
          behält sich das Recht vor, Dein Konto einzuschränken, zu sperren oder
          zu kündigen, wenn Du gegen diesen Vertrag oder das Gesetz verstößt
          oder die Dienste missbrauchst (z. B. durch Verstoß gegen die „Dos and
          Don&apos;ts“ oder professionelle Community-Richtlinien).
        </div>
        <h2 className="font-semibold">3.5 Geistige Eigentumsrechte</h2>
        <div>Wir informieren Dich über unsere geistigen Eigentumsrechte.</div>
        <div>
          TRADEFOOX behält sich alle seine geistigen Eigentumsrechte an den
          Diensten vor. Marken und Logos, die im Zusammenhang mit den Diensten
          verwendet werden, sind Marken ihrer jeweiligen Eigentümer. TRADEFOOX
          und „In“-Logos sowie andere TRADEFOOX-Marken, Dienstleistungsmarken,
          Grafiken und Logos, die für unsere Dienste verwendet werden, sind
          Marken oder eingetragene Marken von TRADEFOOX.
        </div>
        <h2 className="font-semibold">3.6 Automatisierte Verarbeitung</h2>
        <div>
          Wir verwenden Daten und Informationen über Dich, um Dir und anderen
          relevante Vorschläge zu unterbreiten.
        </div>
        <div>
          Wir verwenden die von Dir bereitgestellten und uns vorliegenden
          Informationen und Daten über Mitglieder, um Empfehlungen für
          Verbindungen, Inhalte und Funktionen abzugeben, die für Dich nützlich
          sein könnten. Beispielsweise nutzen wir Daten und Informationen über
          Dich, um Dir Stellen zu empfehlen. Wenn Du Dein Profil korrekt und
          aktuell hältst, können wir diese Empfehlungen genauer und relevanter
          gestalten. Erfahren Sie mehr
        </div>
        <h2 className="font-semibold">
          4. Haftungsausschluss und Haftungsbeschränkung
        </h2>
        <h2 className="font-semibold">4.1 Keine Gewährleistung</h2>
        <div>
          Dies ist unser Haftungsausschluss für die Qualität, Sicherheit oder
          Zuverlässigkeit unserer Dienste.
        </div>
        <div>
          TRADEFOOX UND SEINE VERBUNDENEN UNTERNEHMEN GEBEN KEINE ZUSICHERUNGEN
          ODER GARANTIEN BEZÜGLICH DER DIENSTE, EINSCHLIESSLICH EINER
          ZUSICHERUNG, DASS DIE DIENSTE UNUNTERBROCHEN ODER FEHLERFREI SIND, UND
          BIETEN DIE DIENSTLEISTUNGEN (EINSCHLIESSLICH INHALTE UND
          INFORMATIONEN) „WIE BESEHEN“ UND „WIE VERFÜGBAR“ BEREIT. SOWEIT NACH
          GELTENDEM RECHT ZULÄSSIG, SCHLIESSEN TRADEFOOX und SEINE VERBUNDENEN
          UNTERNEHMEN JEGLICHE STILLSCHWEIGENDE ODER GESETZLICHE GEWÄHRLEISTUNG
          AUS, EINSCHLIESSLICH STILLSCHWEIGENDER GEWÄHRLEISTUNG IN BEZUG AUF
          TITEL, RICHTIGKEIT DER DATEN, NICHTVERLETZUNG, MARKTGÄNGIGKEIT ODER
          EIGNUNG FÜR EINEN BESTIMMTEN ZWECK.
        </div>
        <h2 className="font-semibold">4.2 Haftungsausschluss</h2>
        <div>
          Dies sind die Grenzen der gesetzlichen Haftung, die wir Dir gegenüber
          haben können.
        </div>
        <div>
          SOWEIT GESETZLICH ZULÄSSIG (UND ES SEI DENN, TRADEFOOX HAT EINE
          GESONDERTE SCHRIFTLICHE VEREINBARUNG GESCHLOSSEN, DIE DIESEN VERTRAG
          AUFHEBT), ÜBERNIMMT TRADEFOOX, EINSCHLIESSLICH SEINER VERBUNDENEN
          UNTERNEHMEN, KEINE HAFTUNG IM ZUSAMMENHANG MIT DIESEM VERTRAG FÜR
          ENTGANGENE GEWINNE ODER VERSCHIEDENE GESCHÄFTSMÖGLICHKEITEN,
          REPUTATION (Z. B , beleidigende oder verleumderische Äußerungen),
          Datenverlust (z. B. Ausfallzeit oder Verlust, Nutzung oder Änderung
          Ihrer Informationen oder Inhalte) oder jeglicher indirekter,
          zufälliger, Folge-, besonderer oder Strafschadenersatz.
        </div>
        <div>
          TRADEFOOX UND SEINE VERBUNDENEN UNTERNEHMEN HAFTEN IM ZUSAMMENHANG MIT
          DIESEM VERTRAG NICHT FÜR BETRÄGE, DIE VON IHNEN AN TRADEFOOX FÜR DIE
          DIENSTLEISTUNGEN WÄHREND DER LAUFZEIT DIESES VERTRAGES GEZAHLTEN ODER
          ZU ZAHLENDEN GEBÜHREN.
        </div>
        <h2 className="font-semibold">
          4.3 Grundlage des Geschäfts; Ausschlüsse
        </h2>
        <div>
          Die Haftungsbeschränkungen in diesem Abschnitt 4 sind Bestandteil der
          Vereinbarung zwischen Dir und TRADEFOOX und gelten für alle
          Haftungsansprüche (z. B. Gewährleistung, unerlaubte Handlung,
          Fahrlässigkeit, Vertrag und Gesetz), auch wenn TRADEFOOX oder seine
          verbundenen Unternehmen davon in Kenntnis gesetzt wurden der
          Möglichkeit eines solchen Schadens, selbst wenn diese Abhilfemaßnahmen
          ihren wesentlichen Zweck verfehlen.
        </div>
        <div>
          Diese Haftungsbeschränkungen gelten nicht für die Haftung bei Tod oder
          Körperverletzung oder bei Betrug, grober Fahrlässigkeit oder
          vorsätzlichem Fehlverhalten sowie in Fällen der Fahrlässigkeit, bei
          der eine wesentliche Pflicht verletzt wurde, wobei es sich um eine
          wesentliche Pflicht handelt, die eine Voraussetzung für unsere
          Lieferung darstellt Leistungen und auf die Sie sich vernünftigerweise
          verlassen können, jedoch nur in dem Umfang, in dem die Schäden
          unmittelbar durch die Vertragsverletzung verursacht wurden und bei
          Abschluss dieses Vertrages vorhersehbar waren und soweit sie im Rahmen
          dieses Vertrages typisch sind.
        </div>
        <h2 className="font-semibold">5. Kündigung</h2>
        <div>
          Jeder von uns kann diesen Vertrag kündigen, einige Rechte und
          Pflichten bleiben jedoch bestehen.
        </div>
        <div>
          Sowohl Du als auch TRADEFOOX können diesen Vertrag jederzeit durch
          Mitteilung an die jeweils andere Seite kündigen. Mit der Kündigung
          verlierst Du das Recht, auf die Dienste zuzugreifen oder sie zu
          nutzen. Folgendes bleibt über die Beendigung hinaus bestehen:
        </div>
        <ul className="list-disc list-inside">
          <li>Unsere Rechte zur Nutzung und Offenlegung Deines Feedbacks;</li>
          <li>
            Rechte der Mitglieder und/oder Besucher zur weiteren Weitergabe von
            Inhalten und Informationen, die Du über die Dienste geteilt hast;
          </li>
          <li>Abschnitte 4, 6, 7 und 8.2 dieses Vertrags;</li>
          <li>
            Alle von einer der Parteien vor der Kündigung geschuldeten Beträge
            bleiben auch nach der Kündigung geschuldet.
          </li>
        </ul>
        <div>
          Du kannst unser Hilfecenter besuchen, um Dein Konto zu schließen.
        </div>
        <h2 className="font-semibold">
          6. Anwendbares Recht und Streitbeilegung
        </h2>
        <div>
          Für den unwahrscheinlichen Fall, dass wir in einen Rechtsstreit
          geraten, vereinbaren Du und TRADEFOOX, je nachdem, wo Du lebst, diesen
          vor Gerichten in Deutschland, nach deutschem Recht oder vor Deinen
          örtlichen Gerichten nach lokalem Recht zu lösen.
        </div>
        <div>
          Wenn Du in den angegebenen Ländern lebst, gelten die Gesetze für alle
          Ansprüche im Zusammenhang mit der Bereitstellung der Dienste durch
          TRADEFOOX. Dies bedeutet jedoch nicht, dass Dir der obligatorische
          Verbraucherschutz nach dem Recht des Landes entzogen wird, in das wir
          Deine Dienste verweisen und in dem Du gewöhnliche Dienste in Anspruch
          nimmst Hinsichtlich der Gerichtsbarkeit vereinbaren Du und TRADEFOOX,
          für alle Streitigkeiten, die sich aus oder im Zusammenhang mit dieser
          Nutzungsvereinbarung ergeben, die Gerichte des Landes zu wählen, an
          das wir Deine Dienste weiterleiten, in dem Du Deinen gewöhnlichen
          Aufenthalt hast, oder alternativ kannst Du den Verantwortlichen in
          Bezug auf ein deutsches Gericht wählen.
        </div>
        <div>
          Für andere außerhalb der angegebenen Länder, einschließlich
          derjenigen, die außerhalb der Vereinigten Staaten leben: Du und
          TRADEFOOX stimmen zu, dass alle Streitigkeiten im Zusammenhang mit
          diesem Vertrag und/oder ausschließlich den Gesetzen des
          US-Bundesstaates Kalifornien, mit Ausnahme seiner Kollisionsnormen,
          unterliegen oder die Dienste. Du und TRADEFOOX stimmen beide zu, dass
          alle Ansprüche und Streitigkeiten nur vor den Bundes- oder
          Landesgerichten im Santa Clara County, Kalifornien, USA, verhandelt
          werden können, und Du und TRADEFOOX stimmen jeweils der persönlichen
          Zuständigkeit dieser Gerichte zu.
        </div>
        <h2 className="font-semibold">7. Allgemeine Geschäftsbedingungen</h2>
        <div>Hier findest Du einige wichtige Details zum Vertrag.</div>
        <div>
          Sollte ein für diesen Vertrag zuständiges Gericht feststellen, dass
          ein Teil davon nicht durchsetzbar ist, vereinbaren Du und wir, dass
          das Gericht die Bedingungen ändern sollte, um diesen Teil durchsetzbar
          zu machen und dennoch seine Absicht zu erreichen. Wenn das Gericht
          dies nicht tun kann, vereinbaren Du und wir, das Gericht zu bitten,
          diesen nicht durchsetzbaren Teil zu entfernen und dennoch den Rest
          dieses Vertrags durchzusetzen.
        </div>
        <div>
          Dieser Vertrag (einschließlich zusätzlicher Bedingungen, die von uns
          bereitgestellt werden können, wenn Du eine Funktion der Dienste in
          Anspruch nimmst) ist die einzige Vereinbarung zwischen uns in Bezug
          auf die Dienste und ersetzt alle vorherigen Vereinbarungen für die
          Dienste.
        </div>
        <div>
          Wenn wir nicht handeln, um einen Verstoß gegen diesen Vertrag
          durchzusetzen, bedeutet das nicht, dass TRADEFOOX auf sein Recht
          verzichtet hat, diesen Vertrag durchzusetzen. Du darfst diesen Vertrag
          (oder Ihre Mitgliedschaft oder Nutzung der Dienste) ohne unsere
          Zustimmung nicht an Dritte abtreten oder übertragen. Du stimmen jedoch
          zu, dass TRADEFOOX diesen Vertrag ohne Deine Zustimmung an seine
          verbundenen Unternehmen oder eine Partei, die ihn kauft, abtreten
          kann. Es gibt keine Drittbegünstigten dieses Vertrags.
        </div>
        <div>
          Du erklärst Dich damit einverstanden, dass die einzige Möglichkeit,
          uns rechtliche Hinweise zukommen zu lassen, die in Abschnitt 10
          angegebene Adresse ist.
        </div>
        <h2 className="font-semibold">8. TRADEFOOX „Dos and Don&apos;ts“</h2>
        <div>
          TRADEFOOX ist eine Community von privaten Usern, Fachleuten
          Unternehmern und Vereinen. Diese Liste mit „Geboten und Verboten“
          sowie unsere professionellen Community-Richtlinien schränken ein, was
          Du auf unseren Diensten tun kannst und was nicht.
        </div>
        <h2 className="font-semibold">8.1. DOS</h2>
        <h2 className="font-semibold">Du stimmst zu, dass Du:</h2>
        <ul>
          <li>
            alle geltenden Gesetze beachtest, insbesondere Datenschutzgesetze,
            Gesetze zum Schutz geistigen Eigentums, Anti-Spam-Gesetze,
            Exportkontrollgesetze, Steuergesetze und behördliche Anforderungen.
          </li>
          <li>
            uns genaue Informationen zur Verfügung stellst und diese auf dem
            neuesten Stand hältst.
          </li>
          <li>in Deinem Profil deinen richtigen Namen verwendest, und</li>
          <li>
            die von uns bereitgestellten Dienste auf professionelle Weise nutzt.
          </li>
        </ul>
        <h2 className="font-semibold">8.2. Verbote</h2>
        <h2 className="font-semibold">
          Sie stimmen zu, dass Sie Folgendes nicht tun werden :
        </h2>
        <ul>
          <li>
            Erstelle auf TRADEFOOX keine falsche Identität, stellen Deine
            Identität nicht falsch dar, erstelle kein Mitgliedsprofil für jemand
            anderen als für Dich selbst (eine reale Person) oder verwende nicht
            das Konto einer anderen Person oder versuche, dies zu verwenden;
          </li>
          <li>
            Software, Geräte, Skripte, Roboter oder andere Mittel oder Prozesse
            (einschließlich Crawler, Browser-Plugins und Add-ons oder andere
            Technologien) entwickeln, unterstützen oder nutzen, um die Dienste
            zu extrahieren oder auf andere Weise Profile und andere Daten aus
            den Diensten zu kopieren;
          </li>
          <li>
            Sicherheitsfunktionen außer Kraft setzen oder Zugriffskontrollen
            oder Nutzungsbeschränkungen des Dienstes umgehen oder umgehen (z. B.
            Obergrenzen für Stichwortsuchen oder Profilansichten);
          </li>
          <li>
            Kopieren, Verwenden, Offenlegen oder Verbreiten von Informationen,
            die Du über die Dienste erhalten hast, sei es direkt oder über
            Dritte (z. B. Suchmaschinen), ohne die Zustimmung von TRADEFOOX;
          </li>
          <li>
            Informationen offenlegen, zu deren Offenlegung Du nicht die
            Einwilligung hast (z. B. vertrauliche Informationen anderer
            (einschließlich Deines Arbeitgebers));
          </li>
          <li>
            Die geistigen Eigentumsrechte anderer verletzen, einschließlich
            Urheberrechte, Patente, Marken, Geschäftsgeheimnisse oder andere
            Eigentumsrechte. Kopieren oder verbreite beispielsweise nicht die
            Beiträge oder anderen Inhalte anderer (außer über die verfügbare
            Freigabefunktion) ohne deren Erlaubnis, die sie durch das Posten
            unter einer Creative-Commons-Lizenz erteilen können;
          </li>
          <li>
            Das geistige Eigentum oder andere Rechte von TRADEFOOX verletzen,
            insbesondere das Kopieren oder Verbreiten fremder Materialien oder
            das Kopieren oder Verbreiten unserer Technologie, sofern diese nicht
            unter Open-Source-Lizenzen veröffentlicht wird; die Verwendung des
            Wortes „TRADEFOOX“ oder unserer Logos in einem Firmennamen, einer
            E-Mail-Adresse oder einer URL, sofern nicht in den Markenrichtlinien
            vorgesehen;
          </li>
          <li>
            Posten nichts, was Softwareviren, Würmer oder anderen schädlichen
            Code enthält.
          </li>
          <li>
            Den Quellcode für die Dienste oder eine damit verbundene
            Technologie, die nicht Open Source ist, zurückzuentwickeln, zu
            dekompilieren, zu disassemblieren, zu entschlüsseln oder auf andere
            Weise zu versuchen, ihn abzuleiten;
          </li>
          <li>
            Ohne unsere ausdrückliche Zustimmung andeuten oder angeben, dass Du
            mit TRADEFOOX verbunden bist oder von TRADEFOOX unterstützt wirst
            (z. B. indem Du Dich als akkreditierter TRADEFOOX-Mitarbeiter
            ausgibst);
          </li>
          <li>
            Ohne die Zustimmung von TRADEFOOX die Dienste oder zugehörige Daten
            zu vermieten, zu leasen, zu verleihen, zu handeln, zu
            verkaufen/weiterzuverkaufen oder anderweitig zu monetarisieren oder
            darauf zuzugreifen;
          </li>
          <li>
            Einen Deep-Link zu unseren Diensten zu einem anderen Zweck als der
            Werbung für Dein Profil oder eine Gruppe auf unseren Diensten ohne
            die Zustimmung von TRADEFOOX erstellen;
          </li>
          <li>
            Verwende keine Bots oder andere automatisierte Methoden, um auf die
            Dienste zuzugreifen, Kontakte hinzuzufügen oder herunterzuladen,
            Nachrichten zu senden oder umzuleiten;
          </li>
          <li>
            Überwache nicht die Verfügbarkeit, Leistung oder Funktionalität der
            Dienste für Wettbewerbszwecke;
          </li>
          <li>
            Sich am „Framing“, „Spiegeln“ oder anderweitigen Simulieren des
            Erscheinungsbilds oder der Funktion der Dienste beteiligen;
          </li>
          <li>
            Die Dienste oder ihr Erscheinungsbild überlagern oder anderweitig
            verändern (z. B. durch Einfügen von Elementen in die Dienste oder
            Entfernen, Abdecken oder Unkenntlichmachen einer in den Diensten
            enthaltenen Werbung);
          </li>
          <li>
            Den Betrieb der Dienste stören oder diese unangemessen belasten (z.
            B. Spam, Denial-of-Service-Angriff, Viren, Spielalgorithmen);
            und/oder
          </li>
          <li>
            Verstoße nicht gegen die Professional Community-Richtlinien oder
            zusätzliche Bedingungen für einen bestimmten Dienst, die
            bereitgestellt werden, wenn Du Dich für diesen Dienst anmeldest oder
            mit der Nutzung beginnst, sowie gegebenenfalls gegen die Bing
            Maps-Bedingungen.
          </li>
        </ul>
        <h2 className="font-semibold">9. Beschwerden bezüglich des Inhalts</h2>
        <div>
          Kontaktinformationen für Beschwerden über von unseren Mitgliedern
          bereitgestellte Inhalte.
        </div>
        <div>
          Wir respektieren die geistigen Eigentumsrechte anderer. Wir verlangen,
          dass die von Mitgliedern veröffentlichten Informationen korrekt sind
          und nicht die geistigen Eigentumsrechte oder andere Rechte Dritter
          verletzen. Wir stellen eine Richtlinie und ein Verfahren für
          Beschwerden bezüglich der von unseren Mitgliedern geposteten Inhalte
          bereit.
        </div>
        <h2 className="font-semibold">10. So erreichst Du uns</h2>
        <div>
          Unsere Kontaktinformationen. Unser Help Center bietet auch
          Informationen zu unseren Dienstleistungen.
        </div>
        <div>
          Für allgemeine Anfragen kannst Du uns online kontaktieren . Für
          rechtliche Hinweise oder Prozesszustellung nutze Bitte die Daten aus
          unserem Impressum.
        </div>
      </div>
    </Panel>
  );
};

export default TermsOfUseContainer;
