import TabFilter from "@components/menu/TabFilter";
import React, { Fragment, useState } from "react";
import PopularWidget from "@/components/elements/PopularWidget";
import Input from "@/components/elements/input/Input";
import Tab from "@/components/menu/Tab";
import ContactForYouContainer from "@pages/contacts/for_you/ContactForYouContainer";
import ContactIFollowContainer from "@pages/contacts/i_follow/ContactIFollowContainer";
import ContactMyFollowersContainer from "@pages/contacts/my_followers/ContactMyFollowersContainer";
import ContactMyContactsContainer from "@pages/contacts/contacts/ContactMyContactsContainer";
import ContactRequestsContainer from "@pages/contacts/contacts/requests/ContactRequestsContainer";
import useDebounce from "@/utils/useDebounce";
import ContactTrendingForYouContainer from "@/pages/contacts/for_you/ContactTrendingForYouContainer";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import { Outlet } from "react-router-dom";
import BlockedList from "@pages/contacts/contacts/BlockedList";
import { useTranslation } from "react-i18next";

type Props = {
  tab?: string;
  subTab?: string;
};

const AssignmentsContainer = ({ tab, subTab }: Props) => {
  const { t } = useTranslation();
  const mycontactsTabs = [
    {
      label: t("main.contacts.tabs.myContacts.tabs.overview"),
      key: "all",
      url: "übersicht",
    },
    {
      label: t("main.contacts.tabs.myContacts.tabs.requested"),
      key: "sent",
      url: "angefragt",
    },
    {
      label: t("main.contacts.tabs.myContacts.tabs.pending"),
      key: "received",
      url: "ausstehend",
    },
    {
      label: t("main.contacts.tabs.myContacts.tabs.blocked"),
      key: "blocked",
      url: "blocked",
    },
    {
      label: t("main.contacts.tabs.myContacts.tabs.birthdays"),
      key: "birthdays",
      url: "geburtstage",
    },
    {
      label: t("main.contacts.tabs.myContacts.tabs.statistics"),
      key: "stats",
      url: "statistik",
    },
  ];

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  return (
    <div className="flex flex-col gap-2">
      <Fragment>
        <TabMenu>
          <TabLink subTab to="/kontakte/meine_kontakte/übersicht">
            {t("main.contacts.tabs.myContacts.title")}
          </TabLink>
          <TabLink subTab to="/kontakte/für_dich">
            {t("main.contacts.tabs.toYou.title")}
          </TabLink>
          <TabLink subTab to="/kontakte/follower">
            {t("main.contacts.tabs.followers.title")}
          </TabLink>
          <TabLink subTab to="/kontakte/following">
            {t("main.contacts.tabs.following.title")}
          </TabLink>
          <TabLink subTab to="/kontakte/trending">
            {t("main.contacts.tabs.trending.title")}
          </TabLink>
        </TabMenu>
        {tab && (
          <Input
            name="search-contacts"
            placeholder={t("main.browsePlaceholder")}
            onChange={(e) => setSearchQuery(e)}
            clearable
          />
        )}
        {tab === "my_contacts" && (
          <TabMenu subTab>
            {mycontactsTabs.map((tab: any) => {
              return (
                <>
                  <TabLink subTab to={"/kontakte/meine_kontakte/" + tab.url}>
                    {tab.label}
                  </TabLink>
                </>
              );
            })}
          </TabMenu>
        )}
        {tab === "for_you" && (
          <ContactForYouContainer
            tab={subTab}
            searchQuery={debouncedSearchQuery}
          />
        )}
        {tab === "trending" && (
          <ContactTrendingForYouContainer searchQuery={debouncedSearchQuery} />
        )}
        {tab === "following" && (
          <ContactIFollowContainer searchQuery={debouncedSearchQuery} />
        )}
        {tab === "follower" && (
          <ContactMyFollowersContainer searchQuery={debouncedSearchQuery} />
        )}
        {tab === "my_contacts" && (
          <>
            {subTab === "all" && (
              <ContactMyContactsContainer searchQuery={debouncedSearchQuery} />
            )}
            {subTab === "sent" && (
              <ContactRequestsContainer
                type="sent"
                searchQuery={debouncedSearchQuery}
              />
            )}
            {subTab === "received" && (
              <ContactRequestsContainer
                type="received"
                searchQuery={debouncedSearchQuery}
              />
            )}
            {subTab === "blocked" && (
              <BlockedList searchQuery={debouncedSearchQuery} />
            )}
          </>
        )}
        <Outlet />
      </Fragment>
    </div>
  );
};

export default AssignmentsContainer;
