import React, { useEffect } from "react";
import PostWidget from "@components/elements/PostWidget";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Post } from "@/utils/post";
import { useSearchParams } from "react-router-dom";
import SuggestionsContainer from "@/components/elements/sidebar/suggestions/SuggestionsContainer";
import api from "@/api/api";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
  userId?: string;
};

const PostsContainer = ({ searchQuery, userId = "" }: Props) => {
  const [searchParams] = useSearchParams();
  const posts = useInfiniteQuery<Response>(
    ["posts", searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed?page=" +
          pageParam +
          "&q=" +
          searchQuery +
          "&featured_post_id=" +
          searchParams.get("featured") +
          "&user_id=" +
          userId,
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(
        scrollElement.scrollHeight -
          scrollElement.scrollTop -
          scrollElement.clientHeight,
      ) < 1000
    ) {
      posts.fetchNextPage();
    }
  };

  useEffect(() => {
    document
      .getElementsByTagName("main")[0]
      .addEventListener("scroll", handleScroll);
    return () =>
      document
        .getElementsByTagName("main")[0]
        .removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (!posts.isSuccess) return <></>;

  return (
    <>
      {posts.data.pages.map(
        (page) =>
          page &&
          page.data.map((post: Post, index: number) => {
            return (
              <>
                {[6, 13, 20, 27].includes(index) && <SuggestionsContainer />}
                <div
                  className="max-lg:odd:py-2 max-lg:odd:border-y max-lg:odd:border-y-gray-300"
                  key={post.id}
                >
                  <PostWidget
                    postId={post.id}
                    post={post}
                    text={post.text}
                    author={post.author}
                    repost={post.reposted}
                    created_at={post.created_at}
                  />
                </div>
              </>
            );
          }),
      )}
    </>
  );
};

export default PostsContainer;
