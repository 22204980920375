import React, { useState } from "react";
import Input from "@/components/elements/input/Input";
import WatchThumbnailList from "@components/elements/posts/watch/WatchThumbnailList";
import { useTranslation } from "react-i18next";

const Watch = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3">
      <Input
        placeholder={t("main.watchTabs.placeholder")}
        name="watch_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <WatchThumbnailList search={searchQuery} />
    </div>
  );
};

export default Watch;
