import React, { useState } from "react";
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  FilmIcon,
  ArrowDownOnSquareIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import {
  PhoneIcon,
  StarIcon,
  VideoCameraIcon,
  PhotoIcon,
  SpeakerXMarkIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import InfoButton from "@/pages/messenger/group/InfoButton";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";
import { useQuery } from "@tanstack/react-query";
import { ChatType } from "@/utils/types/ChatType";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";
import isMobile from "@/utils/isMobile";
import tw from "twin.macro";
import FormTextarea from "@/components/elements/form/FormTextarea";
import getName from "@/utils/getName";

type Props = {
  userId: string;
  onClose?: () => void;
};

const UserInfo = ({ userId, onClose }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const [description, setDescription] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const chat = useQuery<ChatType, Error>(["chat-" + userId], async () => {
    const { data } = await api.get("/api/v1/chats/" + userId);
    return data;
  });

  const getChatPartner = (
    members: Array<{ user: User; archived: boolean }>,
    self: User,
  ) => {
    for (const member of members) {
      if (self.id !== member.user.id) {
        return member;
      }
    }
    return members[0];
  };

  if (!chat.isSuccess) return <></>;
  if (!user) return <></>;
  const partner = getChatPartner(chat.data.members, user);

  return (
    <div
      css={[
        tw`flex flex-col overflow-auto h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] lg:h-[calc(100dvh-82px)] relative`,
        isMobile
          ? tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`
          : tw`border border-gray-300 rounded-2xl relative px-3`,
      ]}
      className="no-scrollbar"
    >
      <div className="pt-3 px-0.5">
        <div className="grid grid-flow-row gap-3 text-sm">
          <button
            className="absolute left-0 flex justify-center items-center px-2"
            onClick={onClose}
          >
            <ArrowLeftIcon className="w-8 h-8 hover:bg-gray-300 rounded-full" />
          </button>
          <div className="flex flex-row gap-4 items-center text-center text-xs">
            <div className="flex flex-col justify-center items-center w-full gap-2 cursor-pointer">
              <UserWidget user={partner.user} follow={false} online={true} />
              <div className="my-auto text-gray-600 text-sm font-bold line-clamp-1 break-all">
                {getName(partner.user)}
              </div>
            </div>
          </div>
          <div className="grid grid-flow-col gap-2 text-xs">
            <button className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1">
              <PhoneIcon className="w-4 h-4" />
              Audio
            </button>
            <button className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1">
              <VideoCameraIcon className="w-4 h-4" />
              Video
            </button>
            <button
              className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1"
              onClick={() => {
                onClose && onClose();
              }}
            >
              <MagnifyingGlassIcon className="w-4 h-4 stroke-[3px]" />
              Suchen
            </button>
          </div>

          {!showDescription && (
            <button
              className="border border-gray-300 rounded-xl"
              onClick={() => {
                setShowDescription(true);
              }}
            >
              Beschreibung hinzufügen
            </button>
          )}
          {showDescription && (
            <FormTextarea
              onChange={(e) => setDescription(e)}
              value={description}
              name="description"
            />
          )}
          <div className="grid grid-flow-row">
            <InfoButton
              label="Medien, Links und Doks"
              icon={
                <PhotoIcon className="w-6 h-6 bg-white text-blue-700 rounded-md p-[1px]" />
              }
            />
            <InfoButton
              label="Mit Stern markiert"
              icon={
                <StarIcon className="w-6 h-6 bg-yellow-500 text-white rounded-md" />
              }
            />
          </div>
          <div className="grid grid-flow-row">
            <InfoButton
              label="Archivierte Chats sind stummgeschaltet"
              icon={
                <SpeakerXMarkIcon className="w-6 h-6 bg-gray-600 text-white rounded-md p-[1px]" />
              }
            />
            <InfoButton
              label="Hintergründe und Töne"
              showArrow
              icon={
                <FilmIcon className="w-6 h-6 bg-red-500 text-white rounded-md p-[1px]" />
              }
            />
            <InfoButton
              label="Sichern in Aufnahmen"
              value="Standard"
              icon={
                <ArrowDownOnSquareIcon className="w-6 h-6 bg-yellow-500 text-white rounded-md p-[1px]" />
              }
              showArrow={false}
            />
          </div>
          <div className="grid grid-flow-row">
            <InfoButton
              label="Verschlüsselung"
              subLabel="Nachrichten und Anrufe sind Ende-zu-Ende-verschlüsselt. Tipp, um mehr zu erfahren"
              showArrow
              icon={
                <LockClosedIcon className="w-6 h-6 bg-blue-700 text-white rounded-md" />
              }
            />
            <InfoButton
              label="Selbstlöschende Nachrichten"
              showArrow
              value="Aus"
              icon={
                <FilmIcon className="w-6 h-6 bg-blue-700 text-white rounded-md p-[1px]" />
              }
            />
            <InfoButton
              label="Chatsperre"
              showArrow
              icon={
                <NoSymbolIcon className="w-6 h-6 bg-gray-600 text-white rounded-md p-[1px]" />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
