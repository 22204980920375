import React, { useEffect, useState } from "react";
import Panel from "@/components/box/Panel";
import Checkbox from "@/components/elements/input/Checkbox";
import Input from "@/components/elements/input/Input";
import Select from "@/components/elements/input/Select";
import Tags from "@/components/elements/input/Tags";
import Button from "@/components/elements/input/Button";
import tw from "twin.macro";
import TabFilter from "@/components/menu/TabFilter";
import AssignmentsCreateContainer from "@/pages/assignments/AssignmentsCreateContainer";
import { v4 as uuid } from "uuid";
import AdsMedia from "@/pages/marketplace/form/AdsMedia";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAppSelector } from "@/state/hooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function MarketplaceCreateContainer() {
  const testTabs = [
    { name: "Allgemeines Inserat", key: "standard" },
    { name: "Autos", key: "autos" },
    { name: "Immobilie", key: "immobilie" },
    { name: "Marketplace", key: "marketplace" },
  ];
  const [testTab, setTestTab] = useState<string>(testTabs[0].key);

  const tabs = [
    { name: "Produktdaten", key: "product" },
    { name: "Wichtiges", key: "important" },
    { name: "Angebot", key: "offer" },
    { name: "Details", key: "details" },
    { name: "Variationen", key: "variants" },
    { name: "Regeln", key: "rules" },
    { name: "Bilder", key: "media" },
  ];
  const [tab, setTab] = useState<string>(tabs[0].key);

  type ListItem = {
    label: string;
    id: string;
  };

  type CombinationItemType = {
    value: string;
    id?: string;
    changes?: string;
    key?: string;
  };

  type CombinationType = {
    list: Array<CombinationItemType>;
    checked?: boolean;
    id?: string;
  };

  type AttributeType = {
    checked?: boolean;
    key: string;
    name: string;
    type?: string;
    values?: Array<CombinationItemType>;
  };

  const categories: Array<ListItem> = [
    { label: "Antiquitäten, Kunst", id: "1" },
    { label: "Audio, Foto", id: "1" },
    { label: "Baby, Kleinkind", id: "1" },
    { label: "Bücher", id: "1" },
    { label: "Büro-, Schulbedarf", id: "1" },
    { label: "Computer", id: "1" },
    { label: "Fahrräder", id: "1" },
    { label: "Gewerbe, Industrie", id: "1" },
    { label: "Handy, Telefon, Fax", id: "1" },
    { label: "Haushalt", id: "1" },
    { label: "Heimwerker, Garten", id: "1" },
    { label: "Kleidung, Schmuck", id: "1" },
    { label: "Konsolen, Games", id: "1" },
    { label: "Möbel, Wohnen", id: "1" },
    { label: "Musikinstrumente", id: "1" },
    { label: "Musik, Film", id: "1" },
    { label: "Sammeln", id: "1" },
    { label: "Spielzeug, Modellbau", id: "1" },
    { label: "Sport, Camping", id: "1" },
    { label: "TV, Empfangstechnik", id: "1" },
    { label: "Sonstiges", id: "1" },
  ];

  const idTypeOptions = [
    { name: "ISBN", value: "isbn" },
    { name: "UPC", value: "upc" },
    { name: "GTIN", value: "gtin" },
    { name: "EAN", value: "ean" },
  ];

  const conditionOptions = [
    { name: "Neu", value: "new" },
    { name: "Gebraucht", value: "used" },
    { name: "Aufgebessert", value: "refurbished" },
  ];

  const currencyOptions = [
    { name: "€ EUR", value: "new" },
    { name: "$ USD", value: "dollar" },
    { name: "£ GBP", value: "pound" },
    { name: "¥ JPY", value: "yen" },
  ];

  const shippingServiceOptions = [
    { name: "Keine Angabe", value: "undefined" },
    { name: "DHL", value: "dhl" },
    { name: "DHL Express", value: "dhl express" },
    { name: "DPD", value: "dpd" },
    { name: "UPS", value: "ups" },
    { name: "GLS", value: "gls" },
    { name: "FedEx", value: "fedex" },
    { name: "Deutsche Post", value: "deutsche post" },
  ];
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);

  if (!user) return <span>Loading...</span>;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Panel>
        <div className="grid grid-flow-row gap-2 text-sm">
          <div className="font-semibold">
            {t("main.sell.created.productData")}
          </div>
          <Input
            name="barcode"
            placeholder={t("main.sell.created.barcode")}
            label={t("main.sell.created.barcode")}
          />
          <div>
            {t("main.sell.created.productTitle")}
            <textarea
              name="title"
              className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
              placeholder={t("main.sell.created.productTitle")}
            />
          </div>
          <div>
            {t("main.sell.created.productDescription")}
            <textarea
              name="description"
              className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
              placeholder={t("main.sell.created.productDescription")}
            />
          </div>
          <Tags name="keywords" label={t("main.sell.created.tagsKeywords")} />
          <Select
            items={[]}
            name="categories"
            label={t("main.sell.created.categories")}
          />
          <Input
            name="quantity"
            placeholder={t("main.sell.created.quantityAvailable")}
            label={t("main.sell.created.quantityAvailable")}
          />
          <div className="grid grid-flow-row lg:grid-cols-3 gap-2 items-center">
            <Input
              name="netvalue"
              placeholder={t("main.sell.created.netPrice")}
              label={t("main.sell.created.netPrice")}
            />
            <Input
              name="grossvalue"
              placeholder={t("main.sell.created.grossPrice")}
              label={t("main.sell.created.grossPrice")}
            />
            <Input
              name="quotedvalue"
              placeholder={t("main.sell.created.priceOffer")}
              label={t("main.sell.created.priceOffer")}
            />
          </div>

          <div>{t("main.sell.created.vat")}</div>
          <div className="flex flex-col lg:flex-row gap-x-8 gap-y-1">
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="limited"
                name="limited"
                value="0"
                defaultChecked
                className="focus:ring-transparent"
              />
              19%
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="limited"
                name="limited"
                value="1"
                className="focus:ring-transparent"
              />
              7%
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="limited"
                name="limited"
                value="2"
                className="focus:ring-transparent"
              />
              nicht ausweisbar
            </div>
          </div>

          <Input
            name="hscode"
            placeholder={t("main.sell.created.hsCode")}
            label={t("main.sell.created.hsCode")}
          />
          <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-end">
            <Select
              items={shippingServiceOptions}
              name="shippingservice"
              label={t("main.sell.created.shippingServiceProvider")}
            />
            <Input
              name="reference"
              placeholder={t("main.sell.created.referenceNumber")}
              label={t("main.sell.created.referenceNumber")}
            />
          </div>
          <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
            <Input
              name="trackingid"
              placeholder={t("main.sell.created.trackingNumber")}
              label={t("main.sell.created.trackingNumber")}
            />
            <Input
              name="trackingurl"
              placeholder={t("main.sell.created.trackingUrl")}
              label={t("main.sell.created.trackingUrl")}
            />
          </div>
          <Input
            name="deliverydate"
            placeholder={t("main.sell.created.expectedDelivery")}
            label={t("main.sell.created.expectedDelivery")}
          />
          <div className="grid grid-flow-row gap-1">
            <Input type="file" name="attachments" placeholder="" />
          </div>
          <Checkbox
            name="agb"
            onChange={() => {
              return null;
            }}
            checked={false}
          >
            {t("main.sell.created.termsCondition")}
          </Checkbox>
          <div className="grid grid-flow-row lg:grid-flow-col gap-2">
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.back")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              Duplizieren
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.preview")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.save")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("buttons.publish")}
            </Button>
          </div>
        </div>
      </Panel>
    </form>
  );
}

export default MarketplaceCreateContainer;
