import React from "react";
import {
  BriefcaseIcon,
  EnvelopeIcon,
  BuildingStorefrontIcon,
  NewspaperIcon,
  VideoCameraIcon,
  UserGroupIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import HeaderButton from "@components/nav/HeaderButton";
import tw, { TwStyle } from "twin.macro";
import NavUserElement from "@components/nav/NavUserElement";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  type tabsType = {
    title: string;
    href: string;
    style?: TwStyle;
    icon: JSX.Element;
    disabled?: boolean;
  };
  StatusBar.setStyle({ style: Style.Light });
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);

  const tabsMobile: Array<tabsType> = [
    {
      title: t("main.header.links.messenger.title"),
      href: "/messenger",
      icon: <EnvelopeIcon className="heroicon-stroke-w-1.2" />,
      style: tw`hidden md:block`,
    },
    {
      title: t("main.header.links.contacts"),
      href: "/kontakte",
      icon: <UsersIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.marketplace"),
      href: "/marketplace",
      icon: <BuildingStorefrontIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.advertisements"),
      href: "/inserate",
      icon: <TagIcon className="w-7 h-7 rotate-180 -scale-y-100" />,
    },
    {
      title: t("main.header.links.jobs"),
      href: "/jobs",
      icon: <BriefcaseIcon className="heroicon-stroke-w-1.2" />,
    },
  ];

  const tabs: Array<tabsType> = [
    {
      title: t("main.header.links.messenger.title"),
      href: "/messenger",
      icon: <EnvelopeIcon className="heroicon-stroke-w-1.2" />,
      style: tw`hidden md:block`,
    },
    {
      title: t("main.header.links.contacts"),
      href: "/kontakte",
      icon: <UsersIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.groups.title"),
      href: "/gruppen",
      icon: <UserGroupIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.watch"),
      href: "/watch",
      icon: <VideoCameraIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.feed"),
      href: "/news",
      icon: <NewspaperIcon className="heroicon-stroke-w-1.2" />,
    },
    {
      title: t("main.header.links.jobs"),
      href: "/jobs",
      icon: <BriefcaseIcon className="heroicon-stroke-w-1.2" />,
    },
  ];

  return (
    <div className="z-30 top-0 border-b border-gray-300 bg-white w-full">
      <div className="flex flex-row items-center gap-2 justify-between align-middle px-5 lg:pt-0.5 -mb-0.5">
        <NavLink
          to="/news"
          className="hidden lg:block flex-shrink-0 relative w-56 h-12 cursor-pointer"
        >
          <img src="/logo.gif" alt="TRADEFOOX" />
        </NavLink>

        {!user && (
          <NavLink
            className="bg-darkblue px-3 py-1 rounded-xl text-white max-md:hidden text-sm"
            to="/register"
          >
            {t("main.header.links.register")}
          </NavLink>
        )}
        <div className="block sm:hidden relative h-12"></div>

        <div className="w-full justify-center hidden md:flex">
          <div className="flex items-center justify-between gap-2 lg:gap-4 xl:gap-8 mr-0 2xl:mr-[250px] w-full md:w-fit">
            {tabs
              .filter((item) => !item?.disabled)
              .map((tabData) => {
                return (
                  <HeaderButton
                    key={tabData.href}
                    title={tabData.title}
                    icon={tabData.icon}
                    href={tabData.href}
                    style={tabData.style}
                  />
                );
              })}
            <div className="hidden md:block">
              <NavUserElement />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center md:hidden">
          <div className="flex items-center justify-between gap-2 lg:gap-4 xl:gap-8 mr-0 2xl:mr-[250px] w-full md:w-fit">
            {tabsMobile
              .filter((item) => !item?.disabled)
              .map((tabData) => {
                return (
                  <HeaderButton
                    key={tabData.href}
                    title={tabData.title}
                    icon={tabData.icon}
                    href={tabData.href}
                    style={tabData.style}
                  />
                );
              })}
            <div className="hidden md:block">
              <NavUserElement />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
