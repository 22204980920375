import React, { useState, useContext, useRef } from "react";
import Panel from "@components/box/Panel";
import tw from "twin.macro";
import Checkbox from "@components/elements/input/Checkbox";
import Button from "@components/elements/input/Button";
import EmojiPicker from "emoji-picker-react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Tab from "@/components/menu/Tab";
import { formatDate } from "@/utils/formatDate";
import { AppContext } from "@/App";
import PostMediaWatch from "@/pages/watch/PostMediaWatch";
import Schedule from "@/components/elements/shared/Schedule";
import PostUserSelect from "@/components/elements/posts/form/shared/PostUserSelect";
import EmojiButton from "@/components/elements/shared/buttons/EmojiButton";
import ScheduleButton from "@/components/elements/shared/buttons/ScheduleButton";
import api from "@/api/api";
import { useNavigate } from "react-router-dom";
import PostTextEdit from "@components/elements/posts/form/shared/PostTextEdit";
import { useTranslation } from "react-i18next";
import isMobile from "@/utils/isMobile";

const CreateWatchContainer = () => {
  const appContext = useContext(AppContext);
  if (window.innerWidth < 768) {
    if (appContext?.setFullscreen) appContext.setFullscreen(true);
  }

  const navigate = useNavigate();

  const onSuccess = () => navigate("/watch/für_dich");
  const onClose = () => navigate("/watch/für_dich");

  const [text, setText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [adult, setAdult] = useState(false);
  const [tab, setTab] = useState("basics");
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const queryClient = useQueryClient();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);
  const { t } = useTranslation();

  const send = useMutation(["post"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/posts", {
        text,
        adult,
        watch: true,
        media: media.map((m) => m.id),
        set_active_at: scheduleDate,
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert("Dein Beitrag konnte nicht erstellt werden.");
    },
  });

  return (
    <div
      css={[
        tw`fixed lg:relative top-0 left-0 w-full h-full z-50 overflow-y-auto pt-[env(safe-area-inset-top)]`,
        isMobile && tw`bg-black text-white`,
      ]}
    >
      <Tab isActive={tab === "basics"}>
        <Panel mobileBorder={false} dark={isMobile}>
          <div className="grid grid-flow-row gap-2">
            <div className="flex gap-5 items-center font-semibold">
              <button onClick={onClose}>
                <XMarkIcon className="w-8 h-8 hover:bg-gray-200 rounded-full p-1.5" />
              </button>
              <PostUserSelect dark={isMobile} />
            </div>

            {scheduleDate && (
              <button
                className="flex flex-row gap-2 items-center text-xs hover:underline"
                onClick={() => {
                  setTab("schedule");
                }}
              >
                <CalendarDaysIcon className="w-4 h-4" />
                Wird veröffentlicht am{" "}
                {formatDate(new Date(scheduleDate).toISOString())}.
              </button>
            )}
            <PostMediaWatch
              onChange={(data) => setMedia((value) => [...value, data])}
              media={media}
              onDelete={(id) =>
                setMedia((data) => data.filter((item) => item.id !== id))
              }
            />

            <div className="grid grid-cols-[170px,1fr] h-[300px] gap-4">
              <div className="relative w-[170px]">
                <img
                  src="/smartphone-frame-simple.png"
                  className="absolute w-full pointer-events-none z-20 h-[300px]"
                />
                <div className="w-full h-full rounded-lg relative">
                  {media[0]?.thumbnail && (
                    <img
                      alt="Thumbnail"
                      src={media[0]?.thumbnail}
                      className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 z-10 max-w-[170px] max-h-[300px]"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-1 h-[300px]">
                <PostTextEdit
                  value={text}
                  onChange={setText}
                  small
                  dark={isMobile}
                />
              </div>
            </div>

            <div className="flex flex-row items-baseline justify-between">
              <Checkbox
                name="18+"
                checked={adult}
                onChange={(checked) => setAdult(checked)}
              >
                Ab 18
              </Checkbox>
              <div className="max-sm:hidden">
                <EmojiButton onClick={() => setEmojiOpen((value) => !value)} />
              </div>
              <ScheduleButton
                onClick={() =>
                  setTab(tab === "schedule" ? "basics" : "schedule")
                }
              />

              <div className="w-fit">
                <Button onClick={send.mutate}>
                  <PaperAirplaneIcon className="h-[20px]" />
                </Button>
              </div>
            </div>
            {emojiOpen && (
              <EmojiPicker
                onEmojiClick={(emoji) =>
                  setText(
                    (data) =>
                      data + String.fromCodePoint(parseInt(emoji.unified, 16)),
                  )
                }
              />
            )}
          </div>
        </Panel>
      </Tab>

      <Tab isActive={tab === "schedule"}>
        <Schedule
          dark={isMobile}
          onClose={() => setTab("basics")}
          onChange={(date) => setScheduleDate(date)}
          selectedDate={scheduleDate}
        />
      </Tab>
    </div>
  );
};

export default CreateWatchContainer;
