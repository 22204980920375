import toast, {Toast} from "react-hot-toast";
import React from "react";

declare type BasicTextNotification = {
    toastRef: Toast;
    title: JSX.Element;
    content: JSX.Element;
    imageUrl: string;
}

export default function BasicTextNotification({toastRef, title, content, imageUrl}: BasicTextNotification) {
    return (
        <div
            className={(toastRef.visible ? "animate-enter" : "animate-leave") + " max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
            }
        >
            <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                        <img
                            className="h-10 w-10 rounded-full"
                            src={imageUrl}
                            alt=""
                        />
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                            {title}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                            {content}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex border-l border-gray-200">
                <button
                    onClick={() => toast.dismiss(toastRef.id)}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darkblue hover:text-darkblue focus:outline-none focus:ring-2 focus:ring-darkblue"
                >
                    Close
                </button>
            </div>
        </div>
    )
}