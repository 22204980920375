import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  EllipsisHorizontalIcon,
  HeartIcon,
  ChatBubbleOvalLeftIcon,
  EyeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  EnvelopeIcon,
  PhoneIcon,
  BookmarkIcon,
  PencilIcon,
  PrinterIcon,
  TrashIcon,
  FlagIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import tw from "twin.macro";
import Dropdown from "@/components/menu/Dropdown";
import DropdownItem from "@/components/menu/DropdownItem";

const AssignmentsDetails = () => {
  const { assignmentsId } = useParams();
  const [textMinimized, setTextMinimized] = useState(true);
  const sampleText =
    "WCPN (104.9 FM) is a non-commercial educational radio station licensed to Lorain, Ohio, featuring a public radio format as a repeater of Kent–licensed WKSU. Owned by Ideastream Public Media, the station serves the western portion of Greater Cleveland and parts of surrounding Northeast Ohio. By virtue of WKSU, studios are located at Playhouse Square in Downtown Cleveland,[2] while WCPN's transmitter resides in the Cleveland suburb of Avon. In addition to a standard analog transmission, WCPN broadcasts over four HD Radio channels and streams online. Signing on in 1975 under the WZLE call sign, the station originally focused on Lorain County with a full-service format of local news and easy listening music dubbed  mellow gold . A 1983 sale of WZLE to a local non-profit Christian group saw the station switch to Christian radio programming, eventually focusing on contemporary Christian music by the early 1990s. Jacor's purchase of WZLE in 1998 resulted in a format change to contemporary hit radio as the first home of WAKS the following year. A complex seven-station asset swap involving Jacor's successor company Clear Channel, Salem Communications and WCLV (95.5 FM) owner Radio Seaway on July 3, 2001, resulted in 104.9 FM becoming the second home of WCLV and its classical music format. Converted to non-commercial status and donated to Ideastream in 2013, WCLV's programming and call letters moved to 90.3 FM on March 28, 2022; in turn, this station became a full-power satellite of co-managed WKSU, bearing the WCPN calls. ";

  return (
    <div className="grid grid-flow-row gap-2">
      {/* Title */}
      <div>
        <div className="text-lg font-semibold">TITLE TITLE TITLE</div>
        <div className="flex flex-row gap-1 items-center text-xs">
          <div>Gesuch / Inserat mm.dd.yyyy</div>
          <div className="text-mini">(0 Sterne)</div>
        </div>
      </div>

      <div className="grid grid-flow-row lg:grid-cols-[1fr,250px] gap-4">
        {/* Left side */}
        <div className="flex flex-col gap-2">
          {/* Image */}
          <img
            className="w-full h-[250px] object-cover"
            src="/placeholder.png"
          />
          <div className="flex flex-row gap-2 text-xs font-light">
            <button className="flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] bg-slate-200">
              <ChatBubbleOvalLeftIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
            </button>
            <button className="flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] bg-slate-200">
              <ArrowPathRoundedSquareIcon className="w-3 h-3" />0
            </button>
            <button className="flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] bg-slate-200">
              <HeartIcon className="w-3 h-3" />0
            </button>
            <button className="flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] bg-slate-200">
              <EyeIcon className="w-3 h-3" />0
            </button>
            <button className="flex flex-row gap-1 justify-center items-center rounded-lg w-[62px] h-[20px] bg-slate-200">
              <Dropdown
                icon={
                  <EllipsisHorizontalIcon className="w-5 h-5 text-darkblue heroicon-stroke-w-2.0" />
                }
                align="left"
              >
                <DropdownItem
                  key={"test"}
                  onClick={() => {
                    console.log("Bearbeiten");
                  }}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm inline-flex items-center justify-between"
                >
                  Bearbeiten
                  <PencilIcon className="w-4 h-4" />
                </DropdownItem>
                <DropdownItem
                  key={"test"}
                  onClick={() => {
                    console.log("drucken");
                  }}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm inline-flex items-center justify-between"
                >
                  Drucken
                  <PrinterIcon className="w-4 h-4" />
                </DropdownItem>
                <DropdownItem
                  key={"test"}
                  onClick={() => {
                    console.log("merken");
                  }}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm inline-flex items-center justify-between"
                >
                  Merken
                  <BookmarkIcon className="w-4 h-4" />
                </DropdownItem>
                <DropdownItem
                  key={"test"}
                  onClick={() => {
                    console.log("melden");
                  }}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm inline-flex items-center justify-between"
                >
                  Melden
                  <FlagIcon className="w-4 h-4" />
                </DropdownItem>
                <DropdownItem
                  key={"test"}
                  onClick={() => {
                    console.log("Löschen");
                  }}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-900 text-left w-56 text-sm inline-flex items-center justify-between"
                >
                  Löschen
                  <TrashIcon className="w-4 h-4" />
                </DropdownItem>
              </Dropdown>
            </button>
          </div>

          {/* Order description */}
          <div className="font-semibold text-sm">Beschreibung:</div>
          <div css={[tw`text-sm`, textMinimized && tw`line-clamp-5`]}>
            {sampleText}
          </div>
          <button
            className="flex flex-row justify-center items-center gap-2 text-sm border border-gray-500 rounded-full w-full mt-1"
            onClick={() => setTextMinimized(!textMinimized)}
          >
            {textMinimized ? "weiterlesen" : "weniger"}
            {textMinimized ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronUpIcon className="h-4 w-4" />
            )}
          </button>
        </div>

        {/* Right side */}
        <div className="flex flex-col gap-8 text-gray-800">
          {/* Info */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap">
              Allgemeines
            </div>
            <div className="text-sm border-b">Gesuch</div>
            <div className="inline-flex gap-1.5">
              <div className="whitespace-nowrap">Inserat-Nr.:</div>
              3234623233
              <div>/</div>
              <div className="whitespace-nowrap">Privat</div>
            </div>

            <div className="text-gray-800 gap-1">
              <div>Zeitraum:</div>
              <div>03.04.2005 bis 05.06.2007</div>
            </div>

            <div className="text-gray-800 inline-flex gap-1">
              <div className="font-semibold">Ausführungsort:</div>
              02433 Meissen, Sachsen
            </div>
          </div>

          {/* Conditions */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap">
              Konditionen
            </div>
            <div className="text-gray-800 inline-flex gap-1">
              <div>Vergütung:</div>
              12,34 € pro Stunde
            </div>

            <div className="text-gray-800 inline-flex gap-1">
              <div>Vergütung in €:</div>
              90000
            </div>

            <div className="text-gray-800 inline-flex gap-1">
              <div className="text-darkred">Ab 18 freigegeben</div>
            </div>

            <div className="flex flex-col leading-5 text-sm">
              <div className="text-gray-800 inline-flex gap-1">
                <div>Mitarbeiter:</div> 4004 Personen
              </div>
            </div>
          </div>

          {/* Info */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap">
              Anbieterinformationen
            </div>

            {/* Contact partner description*/}
            <div className="text-gray-800 inline-flex gap-1">
              <div>John Titor</div>
            </div>
            <div className="text-gray-800 inline-flex gap-1">
              Leipziger Straße 12
            </div>
            <div>03429 Dresden</div>
            <div className="text-sm text-gray-800 break-normal whitespace-normal inline-flex items-center gap-1">
              <EnvelopeIcon className="w-4 h-4" />
              qwertz@qwertzmail.com
            </div>
            <div className="text-sm text-gray-800 break-all whitespace-normal inline-flex items-center gap-1">
              <PhoneIcon className="w-4 h-4" />
              +01 858 2030405060708090
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsDetails;
