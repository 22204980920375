import React from "react";
import {
  DocumentTextIcon,
  EyeIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

type Props = {
  title?: string;
  image?: string;
  type?: string;
  tag?: string;
  viewCount?: number;
  likeCount?: number;
  id: string;
  key?: string;
};

const CompactPost = ({
  type,
  image,
  tag,
  viewCount,
  likeCount,
  id,
  title,
}: Props) => {
  return (
    <NavLink
      to={"/news/für_dich?featured=" + id}
      className="h-[200px] relative group"
    >
      <img
        src={image}
        className="object-cover object-center w-full h-full mx-auto"
      />
      {type === "video" ? (
        <PlayIcon className="w-5 h-5 absolute top-1 right-1" />
      ) : (
        <DocumentTextIcon className="w-5 h-5 absolute top-1 right-1" />
      )}
      <div className="absolute top-0 invisible group-hover:visible w-full h-full bg-black/30">
        <div className="absolute top-1/2 -translate-y-1/2 w-full px-2">
          {likeCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 text-white font-semibold">
              <HeartIcon className="w-5 h-5" />
              <div>{likeCount}</div>
            </div>
          )}
          {viewCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <EyeIcon className="w-5 h-5 text-white flip -scale-x-100" />
              <div>{viewCount}</div>
            </div>
          )}
          {title && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div className="line-clamp-1 text-center">{title}</div>
            </div>
          )}
          {tag && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div>#{tag}</div>
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};

export default CompactPost;
