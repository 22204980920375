import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const CreateAccount = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/jobangebote_job_angebote_2_tradefoox.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              Tausche Ideen mit Menschen aus, die Deine Meinung teilen.
            </div>
          </div>
        </section>

        <article className="grid grid-flow-row gap-3 text-sm">
          <h1 className="font-semibold text-base">
            Sieh, was Deine Businesskontakte gerade machen. Wir alle brauchen
            Unterstützung in unserer Karriere. Sichtbarkeit und Vielfalt treffen
            hier aufeinander.
          </h1>
          <div>
            Unsere Mitglieder nutzen tradefoox.com auf lange Zeit, um ihre
            Karriere zu finden und weiterzuentwickeln, präsent zu sein und
            Neuigkeiten aus ihrer Branche zu teilen. Ein professionelles Profil
            aufzubauen ist keine Raketenwissenschaft. Du solltest einige Dinge
            beachten, z.B. aussagekräftige Titel, professionelle Profilbilder
            hinzufügen und Deinen aktuellen Job beschreiben. Es ist wichtig,
            dass Du Deine Ziele berücksichtigst und Deine persönlichen Keywörter
            intelligent in das Profil aufnimmst. Es kann helfen, von der
            richtigen Person gefunden zu werden.
          </div>
          <div>
            Wir bieten eine Vielzahl an Möglichkeiten sich richtig zu
            platzieren. Über den Messenger lassen sich Nachrichten einfach
            verwalten, so behältst Du immer den Überblick. In Deiner
            Kontaktübersicht fehlt ein wichtiger Nutzer? Suche ihn und sende
            eine Kontaktanfrage oder Abonniere um nichts mehr zu verpassen. Die
            Geburtstagsübersicht hilft Dir, Deine Aufmerksamkeit zu zeigen.
          </div>
          <div>
            Wer hat Dein Profil besucht? Vermutlich ein interessanter Kontakt
            aus Deiner Branche oder ein Arbeitskollege.
          </div>
          <div>
            Dein Werdegang ist die Möglichkeit in der Karriere-Leiter die
            nächste Stufe zu erreichen. Je detaillierter, umso besser können
            sich potentielle Arbeitgeber ein Bild von Dir als Person machen.
          </div>
          <div>
            Wenn Du Dir gerade einen gemütlichen Platz gesucht hast, dann
            stöbere doch einfach durch unseren Online Market.
          </div>
          <div>
            Hier findest Du Angebote der kleinen Läden von Nebenan und der
            bekannten Marken.
          </div>
          <h1 className="font-semibold text-base">
            Die beste Website für Dein Unternehmen
          </h1>
          <div>
            Kontaktiere Deine Kontakte vor Ort oder im ganzen Land, um Dein
            Netzwerk auf dem neuesten Stand zu halten um Informationen und
            Referenzen bereitzustellen, oder stelle Dein Angebot anhand von
            Produktbildern und detaillierten Unternehmensinformationen vor. Du
            hast die Wahl!
          </div>
          <div>Wir bieten alles aus einer Hand.</div>
          <div>
            Daher musst Du Online-Marketing-Methoden nicht an verschiedene
            Anbieter verteilen. TRADEFOOX kann dabei helfen.
          </div>
          <div>
            Du kannst Dich von Anfang an auf unsere Unterstützung verlassen. Zum
            Beispiel beim Erstellen von Firmenprofilen.
          </div>
          <div>
            TRADEFOOX resultiert aus der Idee, den Nachunternehmen und
            Freelancern sowie den Auftraggebern einen B2B-Marktplatz zu bieten.
            Dieser soll allen Marktbeteiligten die Arbeit erleichtern, indem sie
            bei uns bequem Aufträge finden oder Aufträge vergeben können.
            Aufwendiges Recherchieren nach passenden Projekten und geeigneten
            Dienstleistern ist damit Vergangenheit.
          </div>
          <div>
            Nutze also die Chance, durch unseren unkomplizierten B2B-Marktplatz
            das Marktgeschehen ohne großen Zeitaufwand stets im Blick zu
            behalten und schneller und effizienter neue Geschäftsbeziehungen zu
            knüpfen.
          </div>
        </article>
        <Input
          label="Firma"
          type="text"
          name="company"
          placeholder="Bitte trage Deinen vollständigen Firmennamen ein."
          value={company}
          onChange={(e) => {
            setCompany(e);
          }}
          required
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <Input
            label="Vorname"
            type="text"
            name="surname"
            placeholder="Max"
            value={surname}
            onChange={(e) => {
              setSurname(e);
            }}
            required
          />
          <Input
            label="Nachname*"
            type="text"
            name="name"
            placeholder="Mustermann"
            value={lastname}
            onChange={(e) => {
              setLastname(e);
            }}
            required
          />
          <Input
            label="E-Mail"
            type="text"
            name="email"
            placeholder="E-Mail"
            value={email}
            onChange={(e) => {
              setEmail(e);
            }}
            required
          />
          <Input
            label="Telefon"
            type="text"
            name="phone"
            placeholder="+49 1234 123456"
            value={phone}
            onChange={(e) => {
              setPhone(e);
            }}
            required
          />
        </div>
        <div>
          Deine Nachricht
          <TextareaAutosize
            minRows={3}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
            required
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <Checkbox
            name="agb"
            required
            checked={privacyCheck}
            onChange={(e) => setPrivacyCheck(e)}
          >
            Ich habe die{" "}
            <NavLink to="/privacy" target="_blank">
              Datenschutzerklärung
            </NavLink>{" "}
            gelesen und akzeptiert
          </Checkbox>
          <Checkbox
            name="newsletter"
            checked={newsletterCheck}
            onChange={(e) => setNewsletterCheck(e)}
          >
            Ich möchte den TRADEFOOX-Newsletter kostenlos erhalten.
          </Checkbox>
          <Button submit>Senden</Button>
        </div>
      </div>
    </form>
  );
};

export default CreateAccount;
