import {
  HIDE_BLOCK_USER_MODAL,
  HideBlockUserModalAction,
  SHOW_BLOCK_USER_MODAL,
  ShowBlockUserModalAction,
} from "@/state/blockUserModal/types";

export const showBlockUserModal = (
  userId: string
): ShowBlockUserModalAction => ({
  type: SHOW_BLOCK_USER_MODAL,
  payload: { userId },
});

export const hideBlockUserModal = (): HideBlockUserModalAction => ({
  type: HIDE_BLOCK_USER_MODAL,
});
