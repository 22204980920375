import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import BlockUserDropdownButton from "@components/elements/shared/buttons/BlockUserDropdownButton";
import ContextMenu from "@components/elements/shared/ContextMenu";
import { useAppSelector } from "@/state/hooks";
import {
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";

type Props = { userId: string };
const ContactContextMenu = ({ userId }: Props) => {
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const { user } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/chats/create/${userId}`);
      return res.data;
    },
    onSuccess: (data) => {
      navigate("/messenger", { state: { chatId: data.data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const block = useMutation([`block-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <ContextMenu
      items={[
        {
          label: "Nachricht senden",
          onClick: () => (user ? createChat.mutate() : loginModal.open()),
          icon: ChatBubbleLeftEllipsisIcon,
        },
        {
          label: "Blockieren",
          onClick: () => block.mutate(),
          icon: MinusCircleIcon,
        },
      ]}
    />
  );
};

export default ContactContextMenu;
