import React, { useState } from "react";
import { User } from "@/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import ContactRequestsItem from "@pages/contacts/contacts/requests/ContactRequestsItem";
import api from "@/api/api";

type Response = {
  data: Array<{
    recipient: User;
    requester: User;
    accepted: boolean;
  }>;
  current_page: number;
  total_pages: number;
};

type Props = {
  type: "sent" | "received";
  searchQuery: string;
};

const ContactRequestsContainer = ({ type, searchQuery }: Props) => {
  const contacts = useInfiniteQuery<Response>(
    ["contacts-requests-" + type, searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/requests/${type}?page=${pageParam}&q=${searchQuery}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    }
  );

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {contacts.data &&
          contacts.data.pages.map((page) =>
            page.data.map((item) => (
              <ContactRequestsItem
                key={item.requester.id + "/" + item.recipient.id}
                user={type === "sent" ? item.recipient : item.requester}
                type={type}
              />
            ))
          )}
      </div>
      {contacts.hasNextPage && !contacts.isFetchingNextPage && (
        <ShowMoreButton onClick={() => contacts.fetchNextPage()} />
      )}
    </>
  );
};

export default ContactRequestsContainer;
