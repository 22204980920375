import { TFunction } from "i18next";

type ProfileEditConfigType = {
  [key in "company" | "person" | "club"]: any;
};

const ProfileEditConfig = (t: TFunction): ProfileEditConfigType => ({
  person: {
    common: [
      {
        name: "firstname",
        label: t("main.profileViewTabs.editProfile.form.firstName"),
        type: "input",
      },
      {
        name: "birthday",
        label: t("main.profileViewTabs.editProfile.form.birthday"),
        type: "date",
      },
      {
        name: "lastname",
        label: t("main.profileViewTabs.editProfile.form.lastName"),
        type: "input",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "username",
        label: t("main.profileViewTabs.editProfile.form.userName"),
        type: "input",
        disabled: true,
      },
      {
        name: "gender",
        label: t("main.profileViewTabs.editProfile.form.gender"),
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.genders.male"),
            value: "male",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.genders.female"),
            value: "female",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.genders.diverse"),
            value: "diverse",
          },
        ],
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      /*{
        name: "public_phone",
        label: "Telefonnummer öffentlich anzeigen",
        type: "checkbox",
      },*/
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
        disabled: true,
      },
      /*      {
              name: "public_email",
              label: "E-Mail öffentlich anzeigen",
              type: "checkbox",
            },*/
      {
        name: "current_employer",
        label: t("main.profileViewTabs.editProfile.form.currentEmployer"),
        type: "input",
      },
      {
        name: "job_position",
        label: t("main.profileViewTabs.editProfile.form.position"),
        type: "input",
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "select",
        disabled: true,
        items: [
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.company",
            ),
            value: "company",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.person",
            ),
            value: "person",
          },
        ],
      },
      {
        name: "start_page",
        label: "Startsite",
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.messenger",
            ),
            value: "messenger",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.marketplace",
            ),
            value: "marketplace",
          },
        ],
      },
      {
        name: "startsite",
        label: t("main.profileViewTabs.editProfile.form.homeSite"),
        type: "select",
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    more_information: [
      {
        name: "description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.description",
          { maximum: "1000" },
        ),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "short_description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.shortDescription",
          { maximum: "150" },
        ),
        maxLength: 150,
        type: "input",
        cols: 2,
      },
      {
        name: "offer",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.iOffer",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "clubs",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.organizationsAssociations",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "looking_for",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.search",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "interests",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.interests",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "languages",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.languages",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "personal",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.personal",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "qualifications",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.qualifications",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "awards",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.awards",
        ),
        type: "input",
        cols: 2,
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.contact.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.contact.training"),
        type: "cv",
      },
    ],
  },
  club: {
    common: [
      {
        name: "name",
        label: t("main.profileViewTabs.editProfile.form.clubname"),
        type: "input",
      },
      {
        name: "username",
        label: t("main.profileViewTabs.editProfile.form.username"),
        type: "input",
      },
      {
        name: "legal_type",
        label: t("main.profileViewTabs.editProfile.form.legalForm"),
        type: "input",
      },
      {
        name: "employees",
        label: t("main.profileViewTabs.editProfile.form.employees"),
        type: "number",
      },
      {
        name: "vat_id",
        label: t("main.profileViewTabs.editProfile.form.vatID"),
        type: "input",
      },
      {
        name: "club_id",
        label: t("main.profileViewTabs.editProfile.form.clubID"),
        type: "input",
      },
      {
        name: "country",
        label: t("main.profileViewTabs.editProfile.form.country"),
        type: "input",
      },
      {
        name: "zip_code",
        label: t("main.profileViewTabs.editProfile.form.zipCode"),
        type: "input",
      },
      {
        name: "street",
        label: t("main.profileViewTabs.editProfile.form.street"),
        type: "input",
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      {
        name: "annualsales",
        label: t("main.profileViewTabs.editProfile.form.annualsales"),
        type: "input",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "select",
        disabled: true,
        items: [
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.company",
            ),
            value: "company",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.person",
            ),
            value: "person",
          },
        ],
      },
      {
        name: "start_page",
        label: "Startsite",
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.messenger",
            ),
            value: "messenger",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.marketplace",
            ),
            value: "marketplace",
          },
        ],
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    description: [
      {
        name: "description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.description",
          { maximum: "1000" },
        ),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "specialization",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.specialty",
          { maximum: "200" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "short_description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.shortDescription",
          { maximum: "150" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "industries",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.industry",
          { maximum: "150" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "keywords",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.industry",
          { maximum: "150" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "legal_notice",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.imprint",
        ),
        type: "legal",
        cols: 2,
        maxLength: 1000,
      },
      {
        name: "terms",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.termsCondition",
        ),
        type: "legal",
        cols: 2,
        maxLength: 2000,
      },
    ],
    contact_person: [
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.person"),
        type: "contacts",
      },
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.admin"),
        type: "contacts",
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.contact.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.contact.training"),
        type: "cv",
      },
    ],
  },
  company: {
    common: [
      {
        name: "name",
        label: t("main.profileViewTabs.editProfile.form.companyName"),
        type: "input",
      },
      {
        name: "username",
        label: t("main.profileViewTabs.editProfile.form.username"),
        type: "input",
        disabled: true,
      },
      {
        name: "legal_type",
        label: t("main.profileViewTabs.editProfile.form.legalForm"),
        type: "input",
      },
      {
        name: "employees",
        label: t("main.profileViewTabs.editProfile.form.employees"),
        type: "number",
      },
      {
        name: "company_registration_number",
        label: t("main.profileViewTabs.editProfile.form.companyRegistration"),
        type: "input",
      },
      {
        name: "tax_id",
        label: t("main.profileViewTabs.editProfile.form.vatID"),
        type: "input",
      },
      {
        name: "country",
        label: t("main.profileViewTabs.editProfile.form.country"),
        type: "input",
      },
      {
        name: "zip_code",
        label: t("main.profileViewTabs.editProfile.form.zipCode"),
        type: "input",
      },
      {
        name: "street",
        label: t("main.profileViewTabs.editProfile.form.street"),
        type: "input",
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      {
        name: "annual_sales",
        label: t("main.profileViewTabs.editProfile.form.annualsales"),
        type: "input",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
        disabled: true,
      },
      {
        name: "duns_number",
        label: t("main.profileViewTabs.editProfile.form.dunsNumber"),
        type: "input",
      },
      {
        name: "duns_text",
        label: t("main.profileViewTabs.editProfile.form.dunsHelp"),
        link: "http://www.dnb.com/de-de/produkte-services/dun-bradstreet/dnb-duns-nummer/",
        type: "link",
        cols: 2,
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "select",
        disabled: true,
        items: [
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.company",
            ),
            value: "company",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.accountTypes.person",
            ),
            value: "person",
          },
        ],
      },
      {
        name: "start_page",
        label: "Startsite",
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.messenger",
            ),
            value: "messenger",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
          },
          {
            name: t(
              "main.profileViewTabs.editProfile.form.startPage.marketplace",
            ),
            value: "marketplace",
          },
        ],
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    description: [
      {
        name: "description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.description",
          { maximum: "1000" },
        ),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "topic",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.specialty",
          { maximum: "200" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "short_description",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.shortDescription",
          { maximum: "150" },
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "industry",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.industry",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "keywords",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.keywords",
        ),
        type: "input",
        cols: 2,
      },
      {
        name: "imprint",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.imprint",
        ),
        type: "legal",
        cols: 2,
        maxLength: 1000,
      },
      {
        name: "terms",
        label: t(
          "main.profileViewTabs.editProfile.form.moreInformation.termsCondition",
        ),
        type: "legal",
        cols: 2,
        maxLength: 2000,
      },
    ],
    contact_person: [
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.person"),
        type: "contacts",
      },
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.admin"),
        type: "contacts",
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.contact.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.contact.training"),
        type: "cv",
      },
    ],
  },
});

export default ProfileEditConfig;
