import React from "react";
import {
  DocumentTextIcon,
  EyeIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

type Props = {
  image?: string;
  type?: string;
  tag: string;
  viewCount: number;
  likeCount: number;
  key?: string;
  tab?: string;
};

const HashtagItem = ({
  image = "/images/default_header_profile.png",
  ...props
}: Props) => (
  <NavLink
    to={"/tags/" + props.tag + "/" + props.tab}
    className="h-[200px] relative group"
  >
    <img src={image} className="object-cover h-full w-full mx-auto" />
    {props.type === "video" ? (
      <PlayIcon className="w-5 h-5 absolute top-1 right-1" />
    ) : (
      <DocumentTextIcon className="w-5 h-5 absolute top-1 right-1" />
    )}
    <div className="absolute top-0 lg:invisible group-hover:visible w-full h-full group-hover:bg-black/30 max-lg:bg-black/30">
      <div className="absolute top-1/2 -translate-y-1/2 w-full px-4">
        <div className="flex flex-row items-center justify-center gap-1 text-white font-semibold">
          <HeartIcon className="w-5 h-5" />
          <div>{props.likeCount}</div>
        </div>
        <div className="flex flex-row items-center justify-center gap-1 text-white">
          <EyeIcon className="w-5 h-5 text-white flip -scale-x-100" />
          <div>{props.viewCount}</div>
        </div>
        <div className="flex flex-row items-center justify-center gap-1 text-white">
          <div className="truncate text-center">#{props.tag}</div>
        </div>
      </div>
    </div>
  </NavLink>
);

export default HashtagItem;
