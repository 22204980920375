import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Panel from "@/components/box/Panel";
import UserWidget from "@/components/elements/UserWidget";
import getName from "@/utils/getName";
import PF, { getComponent } from "@pages/profile/ProfileConfig";
import VerifyBadge from "@components/elements/VerifyBadge";
import Tab from "@/components/menu/Tab";
import TabFilter from "@/components/menu/TabFilter";
import Button from "@/components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ButtonPanel from "@/components/box/ButtonPanel";
import { NavLink } from "react-router-dom";
import EmployeeContainer from "@/pages/profile/details/EmployeeContainer";
import tw from "twin.macro";
import PostsContainer from "@pages/news/posts/PostsContainer";
import ProfileDetailsWatch from "@pages/profile/details/ProfileDetailsWatch";
import useUser from "@/api/user/useUser";
import Input from "@/components/elements/input/Input";
import ButtonsBar from "@/components/elements/form/ButtonsBar";
import ProfileDetailsJobs from "@pages/profile/details/ProfileDetailsJobs";
import ProfileDetailsUser from "@pages/profile/details/ProfileDetailsUser";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import CVList from "@pages/profile/details/cvs/CVList";
import api from "@/api/api";
import ProfileDetailsMedia from "@pages/profile/details/ProfileDetailsMedia";
import BackButton from "@/components/nav/BackButton";
import UserRelationList from "@pages/profile/UserRelationList";
import UserLikeList from "@pages/profile/UserLikeList";
import { useTranslation } from "react-i18next";
import FullScreenImage from "@components/elements/shared/FullScreenImage";
import { AppContext } from "@/App";
import isMobile from "@/utils/isMobile";

type Props = {
  filter?: string;
};

const ProfileContainer = ({ filter = "about" }: Props) => {
  const { userId } = useParams();

  const { t } = useTranslation();

  const hashtagTabs = [
    { name: "Watch", key: "watch" },
    { name: "Feed", key: "news" },
  ];

  const [openHeaderImage, setOpenHeaderImage] = useState(false);
  const [hashtagFilter, setHashtagFilter] = useState<string>("watch");
  const [sidebarFilter, setSidebarFilter] = useState<string>("");
  const [mediaFilter, setMediaFilter] = useState<string>("watch");
  const [showInteractionsList, setShowInteractionsList] = useState<
    | "likes"
    | "feed"
    | "watch"
    | "following"
    | "follows"
    | "feed_likes"
    | "watch_likes"
    | undefined
  >();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const user = useUser(userId ?? "");
  const userData = user.data;
  const appContext = useContext(AppContext);
  if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(true);

  const unfollow = useMutation([`unfollow-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/unfollow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const follow = useMutation(
    [`follow-${userData ? userData.id : ""}-${userId}`],
    {
      mutationFn: async () => {
        const res = await api.get(`/api/v1/users/${userId}/follow`);
        return res.data;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: () => {
        alert("Failed");
      },
    },
  );

  if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(true);

  if (!user.isSuccess) return <span>Loading...</span>;

  if (!userData) {
    return;
  }

  if (userData.blocked)
    return (
      <Panel title="Dieser Nutzer ist blockiert">
        Dieses Profil kann nicht angezeigt werden, da du dieses Profil blockiert
        hast
      </Panel>
    );

  return (
    <>
      <div className="relative">
        <img
          src={
            userData.header_image && userData.header_image !== ""
              ? userData.header_image
              : "/images/default_header_profile.png"
          }
          className="w-full max-md:h-[150px] h-[270px] object-cover cursor-pointer"
          alt={getName(userData)}
          onClick={() => {
            setOpenHeaderImage(true);
          }}
        />
        {!!(openHeaderImage && userData.header_image) && (
          <FullScreenImage
            onClose={() => setOpenHeaderImage(false)}
            imageUrl={userData.header_image}
          />
        )}
        <BackButton
          stepback={true}
          className={tw`absolute top-1 left-1 bg-white rounded-full p-0.5`}
        />
      </div>
      <Tab stayVisible={true}>
        <TabMenu>
          <div className="flex justify-between w-full gap-2 overflow-x-auto scrollbar-none max-md:mx-3">
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink subTab to={"/profile/" + userId + "/über_mich"}>
                {userData.type === "person"
                  ? t("main.profileViewTabs.aboutMe.title")
                  : "Über uns"}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                subTab
                to={"/profile/" + userId + "/news"}
                counter={userData.post_count}
              >
                {t("main.profileViewTabs.feed.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                subTab
                to={"/profile/" + userId + "/watch"}
                counter={userData.watch_count}
              >
                {t("main.profileViewTabs.watch.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                subTab
                to={"/profile/" + userId + "/jobs"}
                counter={userData.job_count}
              >
                {t("main.profileViewTabs.jobs.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink subTab to={"/profile/" + userId + "/shop"} counter={0}>
                {t("main.profileViewTabs.shop.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                subTab
                to={"/profile/" + userId + "/inserate"}
                counter={0}
              >
                {t("main.profileViewTabs.advertisements.title")}
              </TabLink>
            </div>
          </div>
        </TabMenu>
      </Tab>

      {/* Main description */}
      <Tab stayVisible={true}>
        <div className="grid grid-flow-row gap-3 max-md:px-3">
          <div className="flex flex-row gap-4 items-start w-full">
            <UserWidget
              avatarFullscreen
              user={userData}
              follow={false}
              online={true}
            />
            <div className="flex flex-col items-start">
              <div className="grid grid-flow-col gap-1 items-center">
                <div className="text-lg font-semibold truncate">
                  {getName(userData)} {userData.blocked && "- Blockiert"}
                </div>
                <VerifyBadge type={userData.type} />
              </div>
              <div className="text-xs">@{getName(userData)}</div>
            </div>
            {userData &&
              userData.id.localeCompare(userId ? userId : "") != 0 && (
                <div className="w-[100px] shrink-0 ml-auto">
                  {userData.followed ? (
                    <Button
                      onClick={() => unfollow.mutate()}
                      size={Button.Sizes.Small}
                      variant={Button.Variants.Transparent}
                    >
                      <img src="/follow.svg" className="w-4 h-5 mx-auto" />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => follow.mutate()}
                      size={Button.Sizes.Small}
                      variant={Button.Variants.Red}
                    >
                      Folgen
                    </Button>
                  )}
                </div>
              )}
          </div>
          <div className="flex flex-row gap-4 text-xs">
            <div
              onClick={() => setShowInteractionsList("likes")}
              className="cursor-pointer"
            >
              Likes {userData.like_count}
            </div>
            <div
              onClick={() => setShowInteractionsList("follows")}
              className="cursor-pointer"
            >
              Follower {userData.follower_count}
            </div>
            <div
              onClick={() => setShowInteractionsList("following")}
              className="cursor-pointer"
            >
              Following {userData.following_count}
            </div>
          </div>

          <ButtonsBar
            likeButton={{
              likeUrl: "/api/v1/users/" + userData.id + "/like",
              unlikeUrl: "/api/v1/users/" + userData.id + "/unlike",
              liked: userData.liked,
              like_count: userData.like_count,
            }}
            viewButton={{ view_count: 0 }}
            chatButton={{
              chatUrl: "/api/v1/chats/create/" + userData.id,
              backlink: location.pathname,
            }}
            connectButton={{ isVisible: userData.type === "person" }}
            shareButton={{
              title: "Profil",
              body: "https://www.tradefoox.com/profile/" + userData.id,
            }}
            itemId={userData.id}
            itemType={"user"}
            blocked={userData.blocked}
          />
        </div>
      </Tab>

      <div className="max-md:px-1">
        <Tab isActive={filter === "about"}>
          {/* Über uns Tab */}
          <div className="grid grid-flow-row gap-4">
            <div className="relative flex flex-col gap-2 text-sm">
              {/* Tab control in sidebar */}
              {PF(t)[userData.type].sidebarbuttons &&
                sidebarFilter === "" &&
                !showInteractionsList && (
                  <>
                    <div className="border-b pb-1 mb-2 text-base font-semibold">
                      {PF(t)[userData.type].sidebarbuttons.title}
                    </div>
                    <div className="flex flex-wrap gap-2 text-xs">
                      {PF(t)[userData.type].sidebarbuttons.buttons &&
                        PF(t)
                          [userData.type].sidebarbuttons.buttons.filter(
                            (item: any) =>
                              item.authRequired && userData
                                ? userId === userData.id
                                : true,
                          )
                          .map((button: any, index: number) => {
                            return (
                              <div key={index}>
                                {button.page && button.page ? (
                                  <NavLink
                                    to={button.page}
                                    state={{ backlink: location.pathname }}
                                  >
                                    <img
                                      src={button.image}
                                      css={[
                                        tw`border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] w-[63px] mx-auto`,
                                        button.tabname.localeCompare(
                                          sidebarFilter,
                                        ) === 0 && tw`bg-gray-200`,
                                      ]}
                                    />
                                    <div className="text-center text-mini">
                                      {button?.label}
                                    </div>
                                  </NavLink>
                                ) : (
                                  <button
                                    onClick={() => {
                                      if (button.page) navigate(button.page);
                                      setSidebarFilter(button.tabname);
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={button.image}
                                      css={[
                                        tw`border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] w-[63px] mx-auto`,
                                        button.tabname.localeCompare(
                                          sidebarFilter,
                                        ) === 0 && tw`bg-gray-200`,
                                      ]}
                                    />
                                    <div className="text-center text-mini">
                                      {button?.label}
                                    </div>
                                  </button>
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </>
                )}

              {PF(t)[userData.type].sidebars &&
                PF(t)[userData.type].sidebars.map((sidebar: any) => {
                  return (
                    <>
                      <Tab isActive={sidebarFilter === sidebar.name}>
                        <ButtonPanel
                          onClose={() => setSidebarFilter("")}
                          title={sidebar.title}
                        >
                          {sidebar.items &&
                            sidebar.items.map((item: any) => {
                              return (
                                <>
                                  {getComponent({
                                    component: item,
                                    user: userData,
                                    t: t,
                                  })}
                                </>
                              );
                            })}
                        </ButtonPanel>
                      </Tab>
                    </>
                  );
                })}

              <Tab isActive={sidebarFilter === "media"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Medien"
                >
                  <TabFilter
                    value={mediaFilter}
                    options={PF(t)[userData.type].mediatabs}
                    onChange={(value) => setMediaFilter(value)}
                    subTab={true}
                  />
                  {userId && (
                    <ProfileDetailsMedia
                      userId={userId}
                      watch={mediaFilter === "watch"}
                    />
                  )}
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "hashtags"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Hashtags"
                >
                  <Tab stayVisible={true}>
                    <TabFilter
                      value={hashtagFilter}
                      options={hashtagTabs}
                      onChange={(value) => setHashtagFilter(value)}
                      subTab={true}
                    />
                  </Tab>

                  <Tab isActive={hashtagFilter === "watch"}>
                    <div className="flex flex-wrap gap-1"></div>
                  </Tab>
                  <Tab isActive={hashtagFilter === "news"}>
                    <div className="flex flex-wrap gap-1"></div>
                  </Tab>
                </ButtonPanel>
              </Tab>

              {["likes", "feed_likes", "watch_likes"].includes(
                showInteractionsList ?? "",
              ) &&
                userId && (
                  <UserLikeList
                    type={showInteractionsList ?? ""}
                    userId={userId}
                    onClose={() => setShowInteractionsList(undefined)}
                    setTab={(
                      e:
                        | "likes"
                        | "feed"
                        | "watch"
                        | "following"
                        | "follows"
                        | "feed_likes"
                        | "watch_likes"
                        | undefined,
                    ) => setShowInteractionsList(e)}
                  />
                )}

              {["following", "follows"].includes(showInteractionsList ?? "") &&
                userId && (
                  <UserRelationList
                    type={showInteractionsList ?? ""}
                    userId={userId}
                    onClose={() => setShowInteractionsList(undefined)}
                  />
                )}

              <Tab isActive={sidebarFilter === "employee"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Mitarbeiter"
                >
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    <EmployeeContainer user={userData} />
                  </div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "cvs"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Werdegänge"
                >
                  <CVList />
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "assignments"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Inserate"
                >
                  <div className="grid grid-cols-3 gap-2"></div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "social media"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Social Media"
                >
                  <div className="grid grid-cols-1 gap-4"></div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "members"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Mitglieder"
                >
                  <div className="grid grid-cols-1 gap-4"></div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "stats"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Statistik"
                >
                  <div className="grid grid-cols-1 gap-4"></div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "jobs"}>
                <ButtonPanel onClose={() => setSidebarFilter("")} title="Jobs">
                  <div className="grid grid-cols-1 gap-4"></div>
                </ButtonPanel>
              </Tab>

              <Tab isActive={sidebarFilter === "social_media"}>
                <ButtonPanel
                  onClose={() => setSidebarFilter("")}
                  title="Social Media"
                >
                  <div className="grid grid-cols-1 gap-4"></div>
                </ButtonPanel>
              </Tab>
            </div>
          </div>
        </Tab>

        {filter === "news" && (
          <div className="grid grid-cols-1 gap-4">
            <Input
              placeholder="Feed durchsuchen"
              name="feed_keywords"
              clearable
            />
            <PostsContainer searchQuery="" userId={userId} />
          </div>
        )}

        <Tab isActive={sidebarFilter === "personal"}>
          <ButtonPanel
            onClose={() => {
              setSidebarFilter("");
            }}
            title="Persönliches"
          >
            <div className="grid grid-cols-1 gap-4">
              <ProfileDetailsUser userId={userId ?? ""} />
            </div>
          </ButtonPanel>
        </Tab>

        <Tab isActive={filter === "watch"}>
          <div className="grid grid-cols-1 gap-4">
            <ProfileDetailsWatch userId={userId} showInput={true} />
          </div>
        </Tab>

        <Tab isActive={filter === "ads"}>
          <div className="grid grid-cols-1 gap-4">
            <Input
              placeholder="Shop durchsuchen"
              name="shop_keywords"
              clearable
            />
          </div>
        </Tab>

        <Tab isActive={filter === "jobs"}>
          <div className="grid grid-cols-1 gap-4">
            <ProfileDetailsJobs userId={userId} />
          </div>
        </Tab>

        <Tab isActive={filter === "assignments"}>
          <div className="grid grid-cols-1 gap-4">
            <Input
              placeholder="Inserate durchsuchen"
              name="assignments_keywords"
              clearable
            />
          </div>
        </Tab>
      </div>
    </>
  );
};

export default ProfileContainer;
