import React from "react";
import MessengerBubble from "@pages/messenger/MessengerBubble";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { User } from "@/utils/user";
import moment from "moment";
import { LinkPreviewType } from "@/utils/types/LinkPreviewType";
import { Media } from "@/utils/types/Media";
import api from "@/api/api";

type Props = {
  chatId: string;
  me: User;
  query: string;
  onForward: (data: {
    text: string;
    link: string | undefined;
    media: Array<Media>;
  }) => void;
};
type Message = {
  id: string;
  text: string;
  author: User;
  created_at: string;
  read: boolean;
  link_preview?: LinkPreviewType;
  media?: Array<Media>;
};
const MessagesContainer = ({ chatId, me, query, onForward }: Props) => {
  const messages = useQuery<Message[], Error>(
    ["messages", "messages-" + chatId, query],
    async () => {
      const { data } = await api.get(
        `/api/v1/chats/${chatId}/messages?q=${query}`,
      );
      return data;
    },
  );

  return (
    <div className="flex flex-col-reverse gap-3 px-4 py-4 h-full mb-[92px] overflow-auto no-scrollbar">
      {messages.isSuccess &&
        messages.data.map((message) => (
          <MessengerBubble
            isSender={message.author.id === me.id}
            key={message.id}
            author={message.author}
            onForward={() =>
              onForward({
                text: message.text,
                link: message.link_preview?.url,
                media: message.media || [],
              })
            }
            message={{
              id: message.id,
              text: message.text,
              link_preview: message.link_preview,
              read: message.read,
              created_at: moment(message.created_at).format(
                "DD.MM.YYYY, HH:mm",
              ),
              media: message.media,
            }}
          />
        ))}
    </div>
  );
};

export default MessagesContainer;
