import React, { useContext } from "react";
import NavBar from "@components/nav/NavBar";
import { Outlet, useLocation } from "react-router-dom";
import NavBarBottom from "@components/nav/NavBarBottom";
import MyProfileWidget from "@components/elements/MyProfileWidget";
import Suggestions from "@components/elements/sidebar/suggestions/Suggestions";
import MyCart from "@/components/elements/sidebar/widgets/MyCart";
import Footer from "@components/elements/sidebar/widgets/Footer";
import SideBarUserElement from "@components/elements/sidebar/SideBarUserElement";
import { useQuery } from "@tanstack/react-query";
import Panel from "@/components/box/Panel";
import usePopupModal from "@/state/modal/usePopupModal";
import { AppContext } from "@/App";
import tw from "twin.macro";
import api from "@/api/api";
import AppLink from "@/components/elements/shared/AppLink";
import CreateFormButton from "@/components/nav/CreateFormButton";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import AppQRCode from "@/components/elements/shared/QRCode";

const sidebarConfig = (t: TFunction) => ({
  left: [
    {
      key: "person_suggestions",
      title: t("main.userSuggestions.title"),
      link: "/kontakte/für_dich/personen",
    },
    {
      key: "company_suggestions",
      title: t("main.companySuggestions.title"),
      link: "/kontakte/für_dich/firmen",
    },
    {
      key: "group_suggestions",
      title: t("main.groupSuggestions.title"),
      link: "/gruppen/für_dich",
    },
    {
      key: "birthdays",
      title: t("main.birthdays.title"),
      link: "/kontakte/meine_kontakte/geburtstage",
      login: true,
    },
    { key: "managed_pages", title: "Verwaltete Seiten", link: "", login: true },
  ],
  right: [
    {
      key: "new_users",
      title: t("main.new.title"),
      link: "/kontakte/für_dich/alle",
    },
    {
      key: "visitors",
      title: t("main.visitors.title"),
      link: "/kontakte/meine_kontakte/statistik/besucher",
      login: true,
    },
    {
      key: "my_followers",
      title: t("main.myFollowers.title"),
      link: "/kontakte/follower",
      login: true,
    },
    {
      key: "my_friends",
      title: t("main.myFriends.title"),
      link: "/kontakte/meine_kontakte/übersicht",
      login: true,
    },
    { key: "last_visited", title: "Zuletzt angesehen", link: "", login: true },
    {
      key: "remember",
      title: t("main.bookmarks.title"),
      link: "/bookmarks",
      login: true,
    },
  ],
});

const MainWrapper = () => {
  const appContext = useContext(AppContext);

  const sidebar = useQuery<any>(["sidebar"], async () => {
    const res = await api.get("/api/v1/sidebar");
    return res.data.data;
  });
  const { user } = useAppSelector((state) => state.user);
  const popupModal = usePopupModal();
  const path = useLocation().pathname.split("/")[1];
  const fullpath = useLocation().pathname;
  const { t } = useTranslation();

  return (
    <div
      css={[
        tw`grid grid-rows-[50px,calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom))),40px] xl:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen || appContext?.topNavHidden) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <div
        css={[
          (appContext?.fullscreen || appContext?.topNavHidden) &&
            tw`max-md:hidden`,
        ]}
      >
        <NavBar />
      </div>
      <div className="grid grid-cols-12 grid-cols-[0px,0px,auto,0px,0px] lg:grid-cols-[0px,0px,auto,300px,0px] xl:grid-cols-[0px,300px,auto,300px,0px] 2xl:grid-cols-[250px,300px,auto,300px,250px]">
        <div></div>
        <aside className="scrollbar-none overflow-y-scroll">
          <div className="w-[300px] mx-auto gap-4 p-4 hidden grid-flow-row auto-rows-max lg:grid">
            {user && (
              <Panel title={t("main.panel.profile")}>
                <MyProfileWidget />
              </Panel>
            )}
            {user && <CreateFormButton />}
            <Suggestions />
            {sidebarConfig(t).left.map((item) => (
              <>
                {(user || !item.login) && (
                  <SideBarUserElement
                    title={item.title}
                    key={item.key}
                    users={sidebar.data && sidebar.data[item.key]}
                    link={item.link}
                  />
                )}
              </>
            ))}
          </div>
        </aside>

        <main className="scrollbar-thin overflow-y-scroll">
          <div
            css={[
              tw`w-full md:max-w-[600px] mx-auto p-4 lg:px-0 gap-3 grid grid-flow-row auto-rows-max grid-cols-1`,
              (appContext?.borderless || appContext?.fullscreen) &&
                tw`p-0 pt-4`,
            ]}
          >
            <Outlet />
          </div>
        </main>
        <aside className="overflow-y-scroll scrollbar-none">
          <div className="w-[300px] mx-auto gap-4 p-4 grid-flow-row auto-rows-max hidden xl:grid">
            <MyCart />
            {sidebarConfig(t).right.map((item) => (
              <>
                {(user || !item.login) && (
                  <SideBarUserElement
                    title={item.title}
                    key={item.key}
                    users={sidebar.data && sidebar.data[item.key]}
                    link={item.link}
                  />
                )}
              </>
            ))}
            <AppQRCode />
            <AppLink />
            <Footer />
          </div>
        </aside>
        <div></div>
      </div>
      <div
        css={[
          (appContext?.fullscreen || appContext?.bottomNavHidden) &&
            tw`max-md:hidden`,
        ]}
      >
        <NavBarBottom />
      </div>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
    </div>
  );
};

export default MainWrapper;
