import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";
import { NavLink } from "react-router-dom";
import Button from "@components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ContactContextMenu from "@pages/contacts/context/ContactContextMenu";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = { user: User; mode?: string; key?: string };

const ContactForYouItem = ({ user }: Props) => {
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const { t } = useTranslation();
  const { user: userData } = useAppSelector((state) => state.user);

  const follow = useMutation([`follow-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/follow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-for_you"] });
      queryClient.invalidateQueries({ queryKey: ["follows"] });
      queryClient.invalidateQueries({ queryKey: ["following"] });
      queryClient.invalidateQueries({ queryKey: ["likes"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unfollow = useMutation([`unfollow-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/unfollow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-for_you"] });
      queryClient.invalidateQueries({ queryKey: ["follows"] });
      queryClient.invalidateQueries({ queryKey: ["following"] });
      queryClient.invalidateQueries({ queryKey: ["likes"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel>
      <ContactContextMenu userId={user.id} />
      <div>
        <div className="text-sm flex flex-col w-full gap-2">
          <NavLink
            to={"/profile/" + user.id}
            className="flex flex-col gap-1 w-full"
          >
            <UserWidget
              user={user}
              showName={true}
              follow={false}
              verified={true}
            />
          </NavLink>
          <div className="flex flex-row justify-between text-gray-600 text-xs">
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.contacts.tabs.toYou.followers")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.contacts.tabs.toYou.following")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
            <div className="flex flex-col items-center gap-x-1">
              <div>{t("main.contacts.tabs.toYou.likes")}</div>
              <div>{user.like_count}</div>
            </div>
          </div>
          {user.followed ? (
            <Button
              onClick={() => (userData ? unfollow.mutate() : loginModal.open())}
              size={Button.Sizes.Small}
              variant={Button.Variants.Secondary}
            >
              <img src="/follow.svg" className="w-4 h-5 mx-auto" />
            </Button>
          ) : (
            <Button
              onClick={() => (userData ? follow.mutate() : loginModal.open())}
              size={Button.Sizes.Small}
              variant={Button.Variants.Red}
            >
              {t("main.contacts.tabs.toYou.buttonsConsequences")}
            </Button>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default ContactForYouItem;
