import React, { useState } from "react";
import Input from "@/components/elements/input/Input";
import { useAppSelector } from "@/state/hooks";

const Assignments = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { user } = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <div className="flex flex-col gap-3">
      <Input
        placeholder="Inserate durchsuchen"
        name="assignments_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
    </div>
  );
};

export default Assignments;
