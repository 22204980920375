import React, { useState } from "react";
import AuthWrapper from "@pages/auth/AuthWrapper";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";

const ChangePasswordContainer = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  return (
    <AuthWrapper>
      <div className="text-darkblue font-medium text-xl mb-4">
        Passwort ändern.
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          name="current password"
          label="Aktuelles Passwort"
          value={currentPassword}
          placeholder="**************"
          type="password"
          onChange={(e) => setCurrentPassword(e)}
        />
        <Input
          name="new password"
          label="Neues Passwort"
          value={newPassword}
          placeholder="**************"
          type="password"
          onChange={(e) => setNewPassword(e)}
        />
        <div className="gap-x-4 mt-2 flex ml-auto mr-auto">
          <Button onClick={() => console.log("password changed")}>
            Reset Password
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ChangePasswordContainer;
