import React, { useState } from "react";
import PostsContainer from "@pages/news/posts/PostsContainer";
import MyFeedContainer from "@pages/news/posts/MyFeedContainer";
import FollowingFeedContainer from "@pages/news/posts/FollowingFeedContainer";
import Input from "@components/elements/input/Input";
import useDebounce from "@/utils/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import PopularWidget from "@components/elements/PopularWidget";
import UserWidget from "@components/elements/UserWidget";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import api from "@/api/api";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  filter?: string;
};

const NewsContainer = ({ filter = "for_you" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const users = useQuery<User[], Error>(["users-suggestions"], async () => {
    const { data } = await api.get("/api/v1/users/suggestions");
    return data;
  });

  return (
    <>
      {filter != "create" && (
        <PopularWidget>
          {users.isSuccess &&
            users.data.map((user) => (
              <div className="w-[70px] shrink-0" key={user.id}>
                <UserWidget key={user.id} user={user} showName={true} />
              </div>
            ))}
        </PopularWidget>
      )}
      <div css={[filter === "create" && tw`max-md:hidden`]}>
        <TabMenu>
          <TabLink to="/news/mein_feed">{t("main.feedTabs.myFeed")}</TabLink>
          <TabLink to="/news/für_dich">{t("main.feedTabs.toYou")}</TabLink>
          {user && (
            <div className="max-lg:hidden">
              <TabLink to="/news/erstellen">
                {t("main.feedTabs.create.title")}
              </TabLink>
            </div>
          )}
          <TabLink to="/news/following">{t("main.feedTabs.following")}</TabLink>
          <TabLink to="/news/trending">{t("main.feedTabs.trending")}</TabLink>
        </TabMenu>
      </div>
      {filter !== "create" && (
        <Input
          name="search-contacts"
          placeholder={t("main.feedTabs.placeholder")}
          onChange={(e) => setSearchQuery(e)}
          clearable
        />
      )}
      {filter === "for_you" && (
        <PostsContainer searchQuery={debouncedSearchQuery} />
      )}
      {filter === "trending" && (
        <PostsContainer searchQuery={debouncedSearchQuery} />
      )}
      {filter === "my_feed" && (
        <MyFeedContainer searchQuery={debouncedSearchQuery} />
      )}
      {filter === "following" && (
        <FollowingFeedContainer searchQuery={debouncedSearchQuery} />
      )}

      {filter !== "create" && (
        <button
          className="absolute bottom-20 right-5 flex justify-center items-center w-[50px] h-[50px] rounded-full bg-blue-200 lg:hidden z-20"
          onClick={() => navigate("/news/erstellen")}
        >
          <PencilIcon className="text-gray-600 w-6 h-6" />
        </button>
      )}
    </>
  );
};

export default NewsContainer;
