import React, { TouchEvent, useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Stream } from "@cloudflare/stream-react";
import tw from "twin.macro";
import ProgressiveImage from "@components/elements/shared/ProgressiveImage";
import _ from "lodash";
import { StatusBar, Style } from "@capacitor/status-bar";

type Props = {
  onClose: () => void;
  media: Array<{
    type: string;
    url: string;
    key: string;
  }>;
};

const Lightbox = (props: Props) => {
  const { media } = props;
  const [initialTouchPosition, setInitialTouchPosition] = useState<
    number | null
  >(null);
  const [selectedMedia, setSelectedMedia] = useState(0);
  const galleryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    StatusBar.setStyle({ style: Style.Dark });
    return () => {
      StatusBar.setStyle({ style: Style.Light });
    };
  }, []);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setInitialTouchPosition(e.touches[0].clientX);
  };
  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!initialTouchPosition) return;

    const touchPosition = e.touches[0].clientX;
    const difference = initialTouchPosition - touchPosition;

    if (difference > 50 && selectedMedia < props.media.length - 1) {
      // Swipe left
      setSelectedMedia(selectedMedia + 1);
      setInitialTouchPosition(null);
    } else if (difference < -50 && selectedMedia !== 0) {
      // Swipe right
      setSelectedMedia(selectedMedia - 1);
      setInitialTouchPosition(null);
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full z-[500]"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-black"
        onClick={props.onClose}
      ></div>
      <button
        onClick={props.onClose}
        className="absolute right-5 top-5 mt-[env(safe-area-inset-top)]"
      >
        <XMarkIcon className="h-8 w-8 text-white" />
      </button>
      <div className="absolute top-[calc(50%+env(safe-area-inset-top))] left-0 -translate-y-1/2 w-full">
        <div
          ref={galleryRef}
          className="overflow-y-hidden overflow-x-auto mt-3 relative snap-x flex scrollbar-none scroll-smooth snap-mandatory overflow-scrolling[touch]"
          onScroll={(e) => {
            const index = Math.round(
              e.currentTarget.scrollLeft / e.currentTarget.clientWidth,
            );
            setSelectedMedia(index);
          }}
        >
          {media.map((item, index) => (
            <div className="cursor-pointer relative snap-start" key={item.key}>
              <div className="relative w-screen">
                {item.type === "image" && (
                  <ProgressiveImage
                    height="100%"
                    width="100%"
                    className="mx-auto max-h-[calc(calc(100dvh-120px)-env(safe-area-inset-top))] h-screen max-w-[90vw] object-contain"
                    highResSrc={`${item.url}/lightbox`}
                    lowResSrc={`${item.url}/lightboxlow`}
                  />
                )}
                {item.type === "video" && (
                  <Stream
                    src={item.url}
                    muted={selectedMedia !== index}
                    controls={true}
                    autoplay={true}
                    responsive={false}
                    className="mx-auto max-h-[calc(calc(100dvh-120px)-env(safe-area-inset-top))] h-screen max-w-[90vw] w-screen"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {selectedMedia !== 0 && (
          <div
            className="absolute left-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
            onClick={() => {
              galleryRef.current &&
                galleryRef.current.scrollTo(
                  (selectedMedia - 1) * galleryRef.current.clientWidth,
                  0,
                );
            }}
          >
            <div className="group absolute opacity-0 hover:opacity-100 left-0 top-0 h-full w-full z-0 flex items-center justify-center">
              <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
                <ChevronLeftIcon className="h-[25px] w-[25px] mx-auto z-50" />
              </div>
            </div>
          </div>
        )}
        {selectedMedia < media.length - 1 && (
          <div
            className="absolute right-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
            onClick={() => {
              galleryRef.current &&
                galleryRef.current.scrollTo(
                  (selectedMedia + 1) * galleryRef.current.clientWidth,
                  0,
                );
            }}
          >
            <div className="group absolute opacity-0 hover:opacity-100 right-0 top-0 h-full w-full z-0 flex items-center justify-center">
              <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
                <ChevronRightIcon className="h-[25px] w-[25px] mx-auto z-50" />
              </div>
            </div>
          </div>
        )}
        {media.length > 1 && (
          <div className="absolute bottom-0 w-full mx-auto flex justify-center gap-2 my-2">
            {media.map((media, index) => (
              <div
                css={[
                  tw`rounded-full w-[7px] h-[7px] bg-black cursor-pointer border border-white`,
                  index === selectedMedia && tw`border-black bg-white`,
                ]}
                onClick={() => {
                  galleryRef.current &&
                    galleryRef.current.scrollTo(
                      index * galleryRef.current.clientWidth,
                      0,
                    );
                }}
                key={index}
              ></div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
