import React from "react";
import CVItem from "@pages/profile/details/cvs/CVItem";

const CVList = () => {
  const cvs: Array<string> = [];

  return (
    <div className="grid grid-cols-3 gap-2">
      {cvs.map((cv: string) => (
        <CVItem key={cv} />
      ))}
    </div>
  );
};

export default CVList;
