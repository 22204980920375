import React from "react";
import Panel from "@components/box/Panel";
import BackButton from "@/components/nav/BackButton";

const CookiesContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-3 text-gray-700 text-xs">
        <h1 className="text-sm font-bold">Cookie-Richtlinie</h1>
        <div>Mit Wirkung vom 3. Juni 2022</div>
        <div>
          TRADEFOOX ist der Überzeugung, dass die Erfassung und Verwendung
          Deiner Daten für Dich klar und durchschaubar sein soll. Diese
          Cookie-Richtlinie gilt für alle Produkte oder Dienstleistungen von
          TRADEFOOX, die auf diese Richtlinie verweisen oder diese per Verweis
          einbeziehen. Wir nutzen Cookies und ähnliche Technologien wie Pixel,
          lokalen Speicher und mobile Werbe-IDs (in dieser Richtlinie
          zusammenfassend als „Cookies“ bezeichnet) zum Erfassen und Verwenden
          von Daten im Rahmen unserer Dienstleistungen, wie in unserer
          Datenschutzrichtlinie („Dienste“) definiert und die unsere Websites,
          Kommunikation, Apps für Mobilgeräte und Offsite-Dienstleistungen
          umfasst, zum Beispiel unsere Anzeigendienste sowie die Plug-ins „Mit
          TRADEFOOX bewerben“ und „Auf TRADEFOOX teilen“ oder Tags. Aus Gründen
          der Transparenz gibt Dir diese Richtlinie detaillierte Auskunft
          darüber, wie und wann wir diese Technologien verwenden.
        </div>
        <div>
          Durch die weitere Nutzung unserer Dienste erklärst Du Dich
          einverstanden, dass TRADEFOOX Cookies und ähnliche Technologien zu den
          in dieser Richtlinie dargelegten Zwecken verwendet.
        </div>
        <h2 className="font-semibold">
          Welche Technologien werden eingesetzt?
        </h2>
        <div>ZUSAMMENFASSUNG EINGEBEN</div>
        <table className="text-left w-full">
          <tr>
            <th className="w-[100px] lg:w-[150px] pr-[30px]">
              Art der Technologie
            </th>
            <th>Beschreibung</th>
          </tr>
          <tr>
            <td></td>
            <td className="pb-4">
              Ein Cookie ist eine kleine Datei, die auf Ihr Gerät geladen wird
              und die es ermöglicht, TRADEFOOX Funktionen zu aktivieren. Alle
              Browser, die unsere Websites laden, erhalten Cookies von uns oder
              Cookies von Dritten wie unseren Kunden, Partnern oder
              Dienstanbietern. Wir oder Dritte platzieren möglicherweise Cookies
              in Deinem Browser, wenn Du Websites besuchst, die nicht von
              TRADEFOOX stammen, die unsere Anzeigen präsentieren oder unsere
              Plug-ins oder Tags bereitstellen.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Cookies
            </td>
            <td className="pb-4">
              Wir verwenden zwei Arten von Cookies: dauerhafte Cookies und
              Sitzungs-Cookies. Ein dauerhaftes Cookie bleibt über die aktuelle
              Sitzung hinaus erhalten und wird für zahlreiche Zwecke eingesetzt,
              etwa um Dich als vorhandenen Nutzer zu erkennen, damit Du
              einfacher zu TRADEFOOX zurückkehren und mit unseren Diensten
              interagieren kannst, ohne sich erneut einloggen zu müssen. Da ein
              dauerhaftes Cookie in Deinem Browser verbleibt, wird es von
              TRADEFOOX gelesen, wenn Du zu einer unserer Webseiten zurückkehrst
              oder eine Partnerseite besuchst, die unsere Dienste verwendet.
              Sitzungs-Cookies sind nur für die jeweilige Sitzung gültig (in der
              Regel der aktuelle Besuch einer Website oder eine
              Browser-Sitzung).
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Pixel
            </td>
            <td className="pb-4">
              Ein Pixel ist ein winziges Bild, das auf Websites und in E-Mails
              eingebettet ist und einen Aufruf (der Geräte- und
              Besuchsinformationen bereitstellt) an unsere Server erfordert,
              damit das Pixel auf diesen Websites und in E-Mails bereitgestellt
              werden kann. Wir verwenden Pixel, um mehr über Deine Interaktionen
              mit E-Mail-Inhalten oder Webinhalten zu erfahren, zum Beispiel, ob
              Du auf Werbeanzeigen oder Beiträge reagiert hast. Pixel
              ermöglichen es uns und Dritten, Cookies in Ihrem Browser zu
              platzieren.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Lokaler Speicher
            </td>
            <td className="pb-4">
              Der lokale Speicher ermöglicht es einer Website oder einer
              Anwendung, Informationen lokal auf Deinem/Deinen Gerät(en) zu
              speichern. Der lokale Speicher kann verwendet werden, um die User
              Experience auf TRADEFOOX zu verbessern, beispielsweise indem
              Funktionen aktiviert, Deine Einstellungen gespeichert und die
              Funktionalität der Website beschleunigt werden.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Weitere ähnliche Technologien
            </td>
            <td className="pb-4">
              Wir nutzen auch andere Tracking-Technologien, beispielsweise IDs
              und Tags für mobile Werbung zu ähnlichen Zwecken wie den in dieser
              Cookie-Richtlinie beschriebenen. Verweise auf ähnliche
              Technologien in dieser Richtlinie umfassen Pixel, den lokalen
              Speicher und weitere Tracking-Technologien.
            </td>
          </tr>
        </table>
        <div>
          In unseren Cookie-Tabellen werden Cookies und ähnliche Technologien
          aufgelistet, die im Rahmen unserer Dienste zum Einsatz kommen.
          Beachten, dass sich die Bezeichnungen von Cookies und ähnlichen
          Technologien im Laufe der Zeit ändern können.
        </div>
        <h2 className="font-semibold">
          Wofür werden diese Technologien verwendet?
        </h2>
        <div>
          Im Folgenden werden die Zwecke beschrieben, zu denen wir diese
          Technologien nutzen.
        </div>
        <div>ZUSAMMENFASSUNG EINGEBEN</div>
        <table className="text-left w-full table-auto">
          <tr>
            <th className="w-[100px] lg:w-[150px] pr-[30px]">Zweck</th>
            <th>Beschreibung</th>
          </tr>
          <tr>
            <td></td>
            <td className="pb-4">
              Anhand von Cookies können wir beispielsweise vorherige Suchen
              festhalten, sodass wir Dir bei einem erneuten Zugriff auf unsere
              Dienste zusätzliche Informationen anbieten können, die sich auf
              Deine vorherigen Suchen beziehen.
              <br />
              Wir verwenden Cookies und ähnliche Technologien, um TRADEFOOX
              Plug-ins auf und außerhalb von TRADEFOOX Websites zu aktivieren.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Plug-ins auf und außerhalb von TRADEFOOX
            </td>
            <td className="pb-4">
              Beispielsweise sind unsere Plug-ins, einschließlich der Buttons
              „Mit TRADEFOOX bewerben“ und „Teilen“, auf Websites von TRADEFOOX
              und Dritten (z. B. auf den Websites unserer Kunden und Partner) zu
              finden. Unsere Plug-ins nutzen Cookies und andere Technologien, um
              Analysen bereitzustellen und Dich auf Websites von TRADEFOOX und
              Dritten zu erkennen. Wenn Du mit einem Plug-in interagierst (indem
              Du z. B. auf „Bewerben“ klicken), verwendet das Plug-in Cookies,
              um Dich zu identifizieren und Deine Bewerbungsanfrage in die Wege
              zu leiten.
              <br />
              Mehr über unsere Plug-ins erfahren Sie in unserer
              Datenschutzrichtlinie.
              <br />
              Anhand von Cookies und ähnlichen Technologien können wir Dir
              effektiver relevante Werbung anzeigen, sowohl innerhalb als auch
              außerhalb unserer Dienste, und die Leistung solcher Werbung
              messen. Wir nutzen diese Technologien, um herauszufinden, ob Dir
              Inhalte gezeigt wurden oder ob jemand, ddem eine Werbeanzeige
              eingeblendet wurde, später zurückgekehrt ist und auf einer anderen
              Website eine Aktion ausgeführt (z. B. ein Whitepaper
              heruntergeladen oder einen Kauf getätigt) hat. In ähnlicher Weise
              verwenden unsere Partner oder Dienstanbieter unter Umständen diese
              Technologien, um festzustellen, ob wir eine Werbeanzeige oder
              einen Beitrag angezeigt haben und wie erfolgreich diese(r) war,
              oder um uns Informationen darüber zu geben, wie Sie auf
              Werbeanzeigen reagieren.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Werbung
            </td>
            <td className="pb-4">
              Wir arbeiten möglicherweise auch mit unseren Kunden und Partnern
              zusammen, um Dir innerhalb oder außerhalb von TRADEFOOX
              Werbeanzeigen zu präsentieren, beispielsweise nachdem Sie die
              Website oder Anwendung eines Kunden oder Partners besucht haben.
              Diese Technologien helfen uns dabei, unseren Kunden und Partnern
              aggregierte Information bereitzustellen.
              <br />
              Weitere Informationen zur Nutzung von Cookies für Werbezwecke
              finden Sie in den Absätzen 1.4 und 2.4 der Datenschutzrichtlinie.
              <br />
              Wie in Absatz 1.4 unserer Datenschutzrichtlinie vermerkt, erfassen
              wir außerhalb designierter Länder auch Informationen zu Deinem
              Gerät (oder verlassen uns auf andere, die diese erfassen), wenn Du
              unsere Dienste nicht genutzt hast (z. B. Anzeigen-ID, IP-Adresse,
              Betriebssystem und Browserdaten), damit wir unseren Mitgliedern
              relevante Anzeigen zur Verfügung stellen und deren Wirksamkeit
              besser verstehen können.
            </td>
          </tr>
          <tr>
            <td className="w-[100px] lg:w-[150px] pr-[30px] align-top">
              Analysen und Forschung
            </td>
            <td className="pb-4">
              Weitere Informationen finden Sie in Absatz 1.4 der
              Datenschutzrichtlinie.
              <br />
              Anhand von Cookies und ähnlichen Technologien können wir
              herausfinden, wie gut unsere Dienste und Plug-ins an verschiedenen
              Orten funktionieren.
              <br />
              Wir oder unsere Dienstanbieter nutzen diese Technologien, um
              Produkte, Funktionen und Dienstleistungen zu verstehen, zu
              verbessern und zu untersuchen, unter anderem auch, wenn Du durch
              unsere Websites navigierst oder wenn Du von anderen Websites,
              Anwendungen oder Geräten aus auf TRADEFOOX zugreifst. Wir oder
              unsere Dienstanbieter nutzen diese Technologien, um die
              Performance von Werbeanzeigen und Beiträgen auf und außerhalb von
              TRADEFOOX zu messen und um herauszufinden, ob Du mit unseren
              Websites, Inhalten oder E-Mails interagiert hast; basierend auf
              diesen Interaktionen stellen wir Dir dann Analysedaten bereit.
              <br />
              Wir nutzen diese Technologien auch, um im Rahmen unserer Dienste
              aggregierte Informationen für unsere Kunden und Partner
              bereitzustellen.
              <br />
              Wenn Du Mitglied bei TRADEFOOX bist, Dich aber in einem Browser
              von Deinem Konto abgemeldet hast, kann TRADEFOOX Deine Interaktion
              mit unseren Diensten in diesem Browser bis zum Ablauf des Cookies
              weiter protokollieren, um Nutzungsanalysen für unsere Dienste zu
              erstellen. Diese Analysedaten werden möglicherweise in
              aggregierter Form an unsere Kunden weitergegeben.
            </td>
          </tr>
        </table>
        <h2 className="font-semibold">
          Welche Dritten nutzen diese Technologien in Verbindung mit unseren
          Diensten?
        </h2>
        <div>
          Dritte wie unsere Kunden, Partner und Dienstanbieter nutzen
          möglicherweise Cookies in Verbindung mit unseren Diensten.
        </div>
        <div>
          Beispielsweise können Dritte Cookies auf Deinen TRADEFOOX
          Unternehmensseiten, in Deinen Stellenanzeigen und in Deiner Werbung
          auf und außerhalb von TRADEFOOX für ihre eigenen Marketingzwecke
          einsetzen. Weitere Informationen dazu findest Du im TRADEFOOX
          Hilfebereich.
        </div>
        <div>
          Dritte können zudem Cookies in Verbindung mit unseren Offsite-Diensten
          nutzen, z. B. mit den TRADEFOOX Anzeigendiensten. Dritte können
          Cookies verwenden, um uns bei der Bereitstellung unserer Dienste zu
          unterstützen. Wir arbeiten möglicherweise auch mit Dritten zusammen,
          um unsere eigenen Marketingzwecke zu verfolgen und um es uns zu
          ermöglichen, unsere Dienste zu analysieren und zu untersuchen.
        </div>
        <h2 className="font-semibold">Deine Optionen</h2>
        <div>
          Du hast verschiedene Optionen, wie TRADEFOOX Cookies und ähnliche
          Technologien nutzen darf. Bitte beachten: Wenn Du die Fähigkeit von
          TRADEFOOX, Cookies und ähnliche Dienste einzusetzen, einschränkst,
          kann sich Dein Nutzererlebnis insgesamt verschlechtern, da es nun
          nicht mehr auf Dich persönlich zugeschnitten ist. Außerdem kannst Du
          möglicherweise keine nutzerspezifischen Einstellungen wie
          Anmeldeinformationen mehr speichern.
        </div>
        <h2 className="font-semibold">Gezielte Werbung ablehnen</h2>
        <div>
          Wie in Absatz 2.4 der Datenschutzrichtlinie beschrieben, stehen Dir
          bezüglich der personalisierten Werbeanzeigen, die Dir eingeblendet
          werden, verschiedene Optionen zur Verfügung.
        </div>
        <div>TRADEFOOX Mitglieder können ihre Einstellungen hier anpassen.</div>
        <div>Steuerelemente für Besucher befinden sich hier.</div>
        <div>
          Einige Betriebssysteme für Mobilgeräte wie Android bieten die
          Möglichkeit, die Nutzung mobiler Werbe-IDs zur Personalisierung von
          Werbeanzeigen zu steuern. Wenn Du mehr über diese Steuerelemente
          erfahren möchten, besuche die Hersteller-Websites für Android und iOS.
          Wir verwenden keine mobilen iOS-Werbe-IDs für gezielte Werbung.
        </div>
        <h2 className="font-semibold">Browser-Steuerungen</h2>
        <div>
          Die meisten Browser erlauben es Dir, Cookies anhand ihrer
          Einstellungen zu steuern, die so eingestellt werden können, dass sie
          Deine Einwilligung in die Nutzung von Cookies widerspiegeln. Die
          meisten Browser bieten Dir die Möglichkeit, Cookies zu überprüfen und
          zu löschen, einschließlich Cookies von TRADEFOOX. Wenn Du mehr über
          die Browser-Steuerungen erfahren möchten, ziehen bitte die vom
          Browser-Hersteller bereitgestellte Dokumentation zurate.
        </div>
        <h2 className="font-semibold">Was ist DNT (Do Not Track)?</h2>
        <div>
          DNT ist eine von Aufsichtsbehörden, insbesondere der U.S. Federal
          Trade Commission (FTC) geförderte Idee. Danach wurde die
          Internetbranche aufgefordert, einen Mechanismus zu entwickeln und zu
          implementieren, der es Internetnutzern ermöglicht, die Verfolgung
          ihrer Online-Aktivitäten auf Webseiten mithilfe ihrer
          Browsereinstellungen zu kontrollieren. Generell reagiert TRADEFOOX
          nicht auf „Do Not Track“-Signale.
        </div>
        <h2 className="font-semibold">Weitere nützliche Ressourcen</h2>
        <div>
          Um mehr über die Nutzung von Cookies durch Werbetreibende zu erfahren,
          besuche bitte die folgenden Links:
        </div>
        <ul className="list-inside list-disc">
          <li>Internet Advertising Bureau (US)</li>
          <li>European Interactive Digital Advertising Alliance (EU)</li>
          <li>Internet Advertising Bureau (EU)</li>
        </ul>
      </div>
    </Panel>
  );
};

export default CookiesContainer;
