import React, { useState, useContext } from "react";
import Watch from "@pages/watch/Watch";
import CreateWatchContainer from "@pages/watch/CreateWatchContainer";
import { useInfiniteQuery } from "@tanstack/react-query";
import Input from "@/components/elements/input/Input";
import Tab from "@/components/menu/Tab";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import tw from "twin.macro";
import WatchType from "@/utils/types/watch/WatchType";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import api from "@/api/api";
import { AppContext } from "@/App";
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  tab?: string;
};

type Response = {
  data: Array<WatchType>;
  current_page: number;
  total_pages: number;
};

const WatchContainer = ({ tab = "for_you" }: Props) => {
  const appContext = useContext(AppContext);
  if (appContext?.setTopNavHidden) appContext.setTopNavHidden(true);
  if (appContext?.setDarkTheme) appContext.setDarkTheme(true);

  const [searchParams] = useSearchParams();
  const [muted, setMuted] = useState(true);
  const [activePost, setActivePost] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const posts = useInfiniteQuery<Response>(
    ["watch", "posts", tab, searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      let queryString = "";
      if (searchQuery) queryString = `&q=${searchQuery}`;
      if (user && tab === "my_watch") queryString = `&user_id=${user.id}`;
      if (tab === "following") queryString = "&following=true";
      const { data } = await api.get(
        `/api/v1/watch?page=${pageParam}&${queryString}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const count = (posts.isSuccess && posts.data.pages.length * 30) || 0;

  if (activePost > count - 5 && posts.hasNextPage) posts.fetchNextPage();

  return (
    <div>
      <div
        css={[
          tw`absolute lg:relative left-0 z-[10] lg:z-0 lg:block bg-opacity-40 invert lg:invert-0 grayscale lg:grayscale-0 brightness-125 lg:brightness-100 w-full px-4 lg:px-0`,
          tab === "create" && tw`max-md:hidden`,
        ]}
      >
        <div className="grid grid-cols-[auto,1fr,auto] gap-4 overflow-x-scroll scrollbar-none">
          <div className="lg:hidden">
            <NavLink to="/news/">
              <ChevronLeftIcon className="text-gray-600 w-5 h-5" />
            </NavLink>
          </div>
          <TabMenu>
            <TabLink to="/watch/mein_watch">
              {t("main.watchTabs.myWatch")}
            </TabLink>
            <TabLink to="/watch/für_dich">{t("main.watchTabs.toYou")}</TabLink>
            {user && (
              <div className="max-lg:hidden">
                <TabLink to="/watch/video_erstellen">
                  {t("main.watchTabs.create.title")}
                </TabLink>
              </div>
            )}
            <TabLink to="/watch/following">
              {t("main.watchTabs.following")}
            </TabLink>
            <TabLink to="/watch/trending">
              {t("main.watchTabs.trending")}
            </TabLink>
          </TabMenu>
          <NavLink to="/watch/search" className="sm:hidden">
            <MagnifyingGlassIcon className="flex items-center w-5 h-5 text-gray-600" />
          </NavLink>
        </div>
      </div>
      <Tab isActive={tab != "create"}>
        <div className="gap-3 grid grid-flow-row">
          <div className="hidden lg:block">
            <Input
              name="search-contacts"
              placeholder={t("main.watchTabs.placeholder")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e)}
              clearable
            />
          </div>

          <div
            className="grid grid-cols-1 mx-auto snap-y overflow-y-scroll snap-mandatory scrollbar-none w-[calc((100dvw-32px))] lg:w-[calc((100dvh-300px)/16*9)] h-[calc((100dvw-32px)/9*16)] lg:h-[calc(100vh-180px)]"
            onScroll={(e) => {
              if (e.currentTarget.scrollTop === 0 && activePost !== 0)
                return setActivePost(0);
              if (e.currentTarget.scrollTop % e.currentTarget.clientHeight)
                return;
              setActivePost(
                e.currentTarget.scrollTop / e.currentTarget.clientHeight,
              );
            }}
          >
            {posts.isSuccess &&
              posts.data.pages.map(
                (page) =>
                  page &&
                  page.data.map((watch, i) => {
                    return (
                      <Watch
                        queryKey={[
                          "watch",
                          "posts",
                          tab,
                          searchQuery,
                          searchParams.get("featured"),
                        ]}
                        key={`${watch.post_id}-${watch.media_id}`}
                        data={watch}
                        active={i === activePost}
                        preload={activePost + 3 > i && activePost - 3 < i}
                        autoplay={true}
                        muted={muted}
                        setMuted={(state: boolean) => setMuted(state)}
                      />
                    );
                  }),
              )}
            {(posts.hasNextPage || posts.isFetchingNextPage) && (
              <ShowMoreButton onClick={() => posts.fetchNextPage()} />
            )}
          </div>
        </div>
      </Tab>
    </div>
  );
};

export default WatchContainer;
