import React, { ReactNode } from "react";
import tw, { styled } from "twin.macro";

enum Variants {
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Warning = "warning",
  Red = "red",
  Cyan = "cyan",
  Transparent = "transparent",
}

enum Sizes {
  Default = "default",
  Small = "small",
  Large = "large",
}

type Props = {
  size?: Sizes;
  variant?: Variants;
  onClick?: () => void;
  submit?: boolean;
  children: ReactNode;
  disabled?: boolean;
};

interface StyledButtonProps {
  variant?: Variants;
  size?: Sizes;
}

const variantStyles: Record<Variants, any> = {
  primary: tw`text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-indigo-500`,
  secondary: tw`text-darkblue bg-zinc-100 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-50 border border-darkblue`,
  success: tw`text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500`,
  warning: tw`text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500`,
  red: tw`text-white bg-tf-red-600 hover:bg-tf-red-700 focus:outline-none focus:ring-2 focus:ring-tf-red-500`,
  cyan: tw`text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500`,
  transparent: tw`text-darkblue bg-zinc-100 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-50 border border-darkblue`,
};

const sizeStyles: Record<Sizes, any> = {
  small: tw`px-2 py-0.5`,
  default: tw`px-2 py-0.5`,
  large: tw`px-2 py-0.5`,
};

const StyledButton = styled.button<StyledButtonProps>`
  ${({ variant = Variants.Primary, size }) => [
    tw`relative inline-flex items-center justify-center gap-1 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-2xl block`,
    variantStyles[variant],
    size && sizeStyles[size],
  ]}
`;

const _Button = ({
  size = Sizes.Default,
  variant = Variants.Primary,
  onClick,
  submit,
  children,
  disabled = false,
}: Props) => {
  return (
    <StyledButton
      css={[tw`w-full text-center mt-2`]}
      type={submit ? "submit" : "button"}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

const Button = Object.assign(_Button, {
  Variants,
  Sizes,
});

export default Button;
