import React, { useEffect } from "react";
import { User } from "@/utils/user";
import ContactForYouItem from "@pages/contacts/for_you/ContactForYouItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import api from "@/api/api";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
};

const ContactTrendingForYouContainer = ({ searchQuery }: Props) => {
  const contacts = useInfiniteQuery<Response>(
    ["contacts-for_you", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=${pageParam}&q=${searchQuery}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    }
  );

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {contacts.data &&
          contacts.data.pages.map((page) =>
            page.data.map((item: User) => (
              <ContactForYouItem key={item.id} user={item} />
            ))
          )}
      </div>
      {contacts.hasNextPage && !contacts.isFetchingNextPage && (
        <ShowMoreButton onClick={() => contacts.fetchNextPage()} />
      )}
    </>
  );
};

export default ContactTrendingForYouContainer;
