import React, { useState } from "react";
import Input from "@components/elements/input/Input";

type Props = {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number;
};
const LegalInput = (props: Props) => {
  const [mode, setMode] = useState(0);

  return (
    <div className="text-sm">
      <div className="mb-1">{props.label}</div>
      <div className="flex flex-row items-center gap-2">
        <input
          type="radio"
          name={`${props.name}-external`}
          id={`${props.name}-external`}
          value="external"
          onChange={() => setMode(0)}
          checked={!mode}
        />
        <label htmlFor={`${props.name}-external`}>Externe Seite</label>

        <input
          type="radio"
          name={`${props.name}-internal`}
          id={`${props.name}-internal`}
          value="internal"
          onChange={() => setMode(1)}
          checked={!!mode}
        />
        <label htmlFor={`${props.name}-internal`}>Direkte Texteingabe</label>
      </div>
      {mode ? (
        <textarea
          name={props.name}
          className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0 my-1 text-sm"
          placeholder={`${props.label} Text`}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          maxLength={props.maxLength}
        />
      ) : (
        <Input
          name={props.name}
          placeholder={`Link zur ${props.label}`}
          value={props.value}
          onChange={(e) => props.onChange(e)}
          maxLength={props.maxLength}
        />
      )}
    </div>
  );
};

export default LegalInput;
