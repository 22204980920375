import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlayIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { Media } from "@/utils/types/Media";
import tw from "twin.macro";

const Video = ({ media }: { media: Media }) => (
  <>
    <img
      src={`${media.data_url}/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop`}
      className="w-full"
      width="100%"
      height="100%"
    />
    <PlayIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 w-16 text-white" />
  </>
);
const Image = ({ media }: { media: Media }) => (
  <img
    src={`${media.data_url}/post`}
    className="w-full cursor-pointer"
    width="100%"
    height="100%"
  />
);

type Props = {
  onClick: () => void;
  media: Media[];
};

const PostGallery = ({ onClick, media }: Props) => {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const galleryRef = useRef<HTMLDivElement>(null); // To hold the reference to the scrollable div

  return (
    <div className="relative">
      <div
        ref={galleryRef}
        className="rounded-2xl overflow-y-hidden overflow-x-auto border border-gray-400 mt-3 relative max-h-[700px] snap-x flex aspect-square scrollbar-none scroll-smooth snap-mandatory overflow-scrolling[touch]"
        onScroll={(e) => {
          const index = Math.round(
            e.currentTarget.scrollLeft / e.currentTarget.clientWidth,
          );
          setSelectedMedia(index);
        }}
      >
        {media.map((item) => (
          <div
            onClick={onClick}
            className="cursor-pointer h-full aspect-square relative snap-start"
            key={item.key}
          >
            {item.type === "image" && <Image media={item} />}
            {item.type === "video" && <Video media={item} />}
          </div>
        ))}
      </div>

      {selectedMedia !== 0 && (
        <div
          className="absolute left-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
          onClick={() => {
            galleryRef.current &&
              galleryRef.current.scrollTo(
                (selectedMedia - 1) * galleryRef.current.clientWidth,
                0,
              );
          }}
        >
          <div className="group absolute opacity-0 hover:opacity-100 left-0 top-0 h-full w-full z-0 flex items-center justify-center">
            <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
              <ChevronLeftIcon className="h-[25px] w-[25px] mx-auto z-50" />
            </div>
          </div>
        </div>
      )}
      {selectedMedia < media.length - 1 && (
        <div
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
          onClick={() => {
            galleryRef.current &&
              galleryRef.current.scrollTo(
                (selectedMedia + 1) * galleryRef.current.clientWidth,
                0,
              );
          }}
        >
          <div className="group absolute opacity-0 hover:opacity-100 right-0 top-0 h-full w-full z-0 flex items-center justify-center">
            <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
              <ChevronRightIcon className="h-[25px] w-[25px] mx-auto z-50" />
            </div>
          </div>
        </div>
      )}
      {media.length > 1 && (
        <div className="absolute bottom-0 w-full mx-auto flex justify-center gap-2 my-2">
          {media.map((media, index) => (
            <div
              css={[
                tw`rounded-full w-[7px] h-[7px] bg-black cursor-pointer`,
                index === selectedMedia && tw`border border-black bg-white`,
              ]}
              onClick={() => {
                galleryRef.current &&
                  galleryRef.current.scrollTo(
                    index * galleryRef.current.clientWidth,
                    0,
                  );
              }}
              key={index}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostGallery;
