import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "@/state/store";
import { openModal, closeModal } from "@/state/modal/modalSlice";

const useLoginModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useSelector((state: RootState) => state.login.isOpen);

  const open = () => {
    dispatch(openModal());
  };

  const close = () => {
    dispatch(closeModal());
  };

  return { isOpen, open, close };
};

export default useLoginModal;
