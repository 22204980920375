import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  title?: string;
  children: ReactNode;
  padding?: boolean;
  key?: string;
  mobileBorder?: boolean;
  dark?: boolean;
};

const Panel: React.FC<Props> = ({
  title,
  children,
  padding = true,
  mobileBorder = true,
  dark = false,
}: Props): JSX.Element => (
  <div
    css={[
      tw`lg:border lg:border-gray-300 bg-white rounded-2xl relative`,
      padding && tw`p-3`,
      mobileBorder && tw`border border-gray-300`,
      dark && tw`bg-black border border-black`,
    ]}
  >
    {title && (
      <div className="flex flex-row items-center gap-2 border-b pb-1 mb-4 w-full text-base font-semibold">
        {title}
      </div>
    )}
    {children}
  </div>
);

export default Panel;
