import React, { useState } from "react";
import { getChatPartner } from "@pages/messenger/Messenger";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatType } from "@/utils/types/ChatType";
import axios from "axios";
import PopupWrapper from "@components/elements/PopupWrapper";
import tw from "twin.macro";
import UserWidget from "@components/elements/UserWidget";
import getName from "@/utils/getName";
import TabFilter from "@components/menu/TabFilter";
import Button from "@components/elements/input/Button";
import { Media } from "@/utils/types/Media";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";

type Props = {
  onClose: () => void;
  message: {
    text: string;
    link: string | undefined;
    media?: Media[];
  };
};

const ForwardMessagePopup = ({ onClose, message }: Props) => {
  const [chatFilter, setChatFilter] = useState("");
  const [selectedChats, setSelectedChats] = useState<string[]>([]);
  const { user } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();

  const chats = useQuery<ChatType[], Error>(["chats", chatFilter], async () => {
    const { data } = await api.get("/api/v1/chats/" + chatFilter);
    // data[0] && setSelectedChat(data[0].id);
    return data;
  });

  const sendMessage = useMutation(["send-message"], {
    mutationFn: async () => {
      for (const selectedChat of selectedChats) {
        const mediaIds = message.media?.map((m) => m.id);
        await api.post(`/api/v1/chats/${selectedChat}/messages`, {
          text: message.text,
          link: message.link,
          media: mediaIds,
        });
        queryClient.invalidateQueries({
          queryKey: ["messages-" + selectedChat],
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      queryClient.invalidateQueries({
        queryKey: ["messages"],
      });
      onClose();
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) return <></>;
  if (!chats.isSuccess) return <></>;
  return (
    <PopupWrapper onClose={onClose}>
      <div className="flex flex-col gap-2 no-scrollbar">
        <TabFilter
          options={[
            { name: "Chats", key: "" },
            { name: "Archiviert", key: "archive" },
            { name: "Gruppen", key: "groups" },
          ]}
          value={chatFilter}
          onChange={setChatFilter}
          subTab={true}
        />
        {chats.data.map((chat) => (
          <button
            key={chat.id}
            className="relative rounded-2xl flex flex-row p-2 gap-2 bg-gray-200 group"
            onClick={() =>
              setSelectedChats((data) => {
                if (data.includes(chat.id)) {
                  return data.filter((id) => id !== chat.id);
                } else {
                  return [...data, chat.id];
                }
              })
            }
          >
            <div css={[tw`items-center justify-center h-[45px] flex`]}>
              <input
                id="chat-checkbox"
                name="chat-checkbox"
                type="checkbox"
                checked={selectedChats.includes(chat.id)}
                className="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
              />
            </div>
            <div className="my-auto">
              <UserWidget
                user={getChatPartner(chat.members, user)}
                follow={false}
                online={false}
                size={45}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-semibold text-left">
                {getName(getChatPartner(chat.members, user))}
              </div>
              <div className="text-xs line-clamp-1 text-left">
                {chat.last_message_text.startsWith("chat/media/")
                  ? "Media"
                  : chat.last_message_text}
              </div>
              <div className="text-xs text-left">{chat.created_at}</div>
            </div>
          </button>
        ))}
        <Button onClick={() => sendMessage.mutate()}>Senden</Button>
      </div>
    </PopupWrapper>
  );
};

export default ForwardMessagePopup;
