import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import AuthWrapper from "@pages/auth/AuthWrapper";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";
import Checkbox from "@components/elements/input/Checkbox";
import register from "@/api/auth/register";
import { trackFormSubmission } from "@/utils/hubspot";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import CustomSelect from "@components/elements/input/CustomSelect";
import VerifyBadge from "@components/elements/VerifyBadge";
import tw from "twin.macro";
import AppLink from "@components/elements/shared/AppLink";

const types = [
  {
    name: "Persönliches Profil",
    value: "person",
    icon: (
      <VerifyBadge
        customStyle={[tw`my-0.5`]}
        h={tw`h-4`}
        w={tw`w-4`}
        type="person"
      />
    ),
  },
  {
    name: "Unternehmen",
    value: "company",
    icon: (
      <VerifyBadge
        customStyle={[tw`my-0.5`]}
        h={tw`h-4`}
        w={tw`w-4`}
        type="company"
      />
    ),
  },
  {
    name: "Verein",
    value: "club",
    icon: (
      <VerifyBadge
        customStyle={[tw`my-0.5`]}
        h={tw`h-4`}
        w={tw`w-4`}
        type="club"
      />
    ),
  },
];

const RegisterContainer = () => {
  const [email, setEmail] = useState<string>("");
  const [type, setType] = useState<string>(types[0].value);
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [agb, setAgb] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const popupModal = usePopupModal();
  const navigate = useNavigate();

  const registerMutation = useMutation(["register"], {
    mutationFn: () => {
      trackFormSubmission("registerForm");
      return register({
        type,
        email,
        password,
        firstname,
        lastname,
        name,
      });
    },
    onSuccess: async () => {
      navigate("/news");
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          variant={Alert.MessageVariants.OK}
          onClose={popupModal.close}
          message="Die E-Mail für die Aktivierung wird in wenigen Minuten ankommen."
          title="Registrierung"
        />
      );
    },
    onError: async () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          variant={Alert.MessageVariants.Error}
          onClose={popupModal.close}
          message="Die Registrierung konnte nicht abegschlossen werden."
          title="Registrierung"
        />
      );
    },
  });

  return (
    <AuthWrapper>
      <h1>TRADEFOOX Registrierung</h1>
      <form
        id="registerForm"
        onSubmit={(e) => {
          if (!registerMutation.isLoading) registerMutation.mutate();
          e.preventDefault();
        }}
      >
        <CustomSelect
          options={types}
          label="Registrieren als"
          onChange={(value) => setType(value)}
        />
        {type === "person" ? (
          <div className="grid grid-cols-2 gap-2">
            <Input
              name="firstname"
              label="Vorname"
              value={firstname}
              placeholder="Max"
              type="text"
              minLength={3}
              maxLength={32}
              required={true}
              onChange={(e) => setFirstname(e)}
              title="Der Vorname muss mindestens 3 Zeichen lang sein"
            />
            <Input
              name="lastname"
              label="Nachname"
              value={lastname}
              placeholder="Mustermann"
              type="text"
              minLength={3}
              maxLength={32}
              required={true}
              onChange={(e) => setLastname(e)}
              title="Der Nachname muss mindestens 3 Zeichen lang sein"
            />
          </div>
        ) : (
          <Input
            name="name"
            label={`Name des ${type === "club" ? "Vereins" : "Unternehmens"}`}
            value={name}
            placeholder={
              type === "club" ? "Beispiel e.V." : "Beispiel GmbH & Co. KG"
            }
            type="text"
            minLength={3}
            maxLength={32}
            required={true}
            onChange={(e) => setName(e)}
            title="Der Name muss mindestens 3 Zeichen lang sein"
          />
        )}
        <Input
          name="email"
          label="E-Mail"
          value={email}
          placeholder="max.mustermann@beispiel.de"
          type="email"
          onChange={(e) => setEmail(e)}
          required={true}
          title="Bitte eine gültige Email Adresse angeben"
        />
        <Input
          name="password"
          label="Passwort"
          value={password}
          placeholder="Passwort"
          type="password"
          minLength={8}
          maxLength={32}
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
          onChange={(e) => setPassword(e)}
          required={true}
          title="Das Passwort muss Groß- und Kleinbuchstaben sowie Ziffern enthalten und insgesamt mindestens 8 Zeichen lang sein."
        />
        <div className="my-3">
          <Checkbox
            name="privacy"
            checked={agb}
            onChange={(checked) => setAgb(checked)}
            required={true}
          >
            Ich akzeptiere die{" "}
            <NavLink to="/datenschutzrichtlinie" className="text-darkcyan">
              Datenschutzrichtlinien
            </NavLink>{" "}
            und{" "}
            <NavLink to="/agb" className="text-darkcyan">
              Nutzungsbedingungen
            </NavLink>
            .
          </Checkbox>
          <Checkbox
            name="newsletter"
            checked={newsletter}
            onChange={(checked) => setNewsletter(checked)}
          >
            Ich möchte den TRADEFOOX-Newsletter kostenlos erhalten.
          </Checkbox>
        </div>
        <Button submit={true}>Konto erstellen</Button>
      </form>
      <div className="grid grid-cols-1 text-center mt-2">
        <NavLink to="/login">Du hast schon ein Account? Hier anmelden</NavLink>
        <NavLink to="/verwaltung/passwort">Password vergessen?</NavLink>
      </div>
      <AppLink />
      <div className="text-center">
        <NavLink to="/impressum" className="text-sm">
          Impressum
        </NavLink>
      </div>
    </AuthWrapper>
  );
};

export default RegisterContainer;
