import React, { ReactNode, useRef } from "react";
import Panel from "@components/box/Panel";
import ScrollIcons from "@components/elements/shared/ScrollIcons";
import { useTranslation } from "react-i18next";

type Props = { children?: ReactNode };

const PopularWidget = ({ children }: Props) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  return (
    <Panel title={t("main.contacts.popular")} mobileBorder={false}>
      <div
        className="flex gap-2 overflow-x-auto no-scrollbar max-h-[75px]"
        ref={galleryRef}
      >
        {children}

        <ScrollIcons galleryRef={galleryRef} />
      </div>
    </Panel>
  );
};

export default PopularWidget;
