import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  link?: string;
  value?: string;
  label?: string;
  onClick?: () => void;
  subLabel?: string;
  icon?: React.ReactNode;
  showArrow?: boolean;
};

const InfoButton = ({
  label,
  subLabel,
  value,
  link,
  onClick,
  icon,
  showArrow = true,
}: Props) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        onClick && onClick();
        if (link) navigate(link);
      }}
      className="border border-transparent border-x-gray-300 first:border-gray-300 last:border-gray-300 first:rounded-t-xl first:border-b-transparent last:rounded-b-xl last:border-t-transparent only:border-gray-300 py-1 px-2 leading-4"
    >
      <div className="grid grid-cols-[auto,1fr,auto] gap-3">
        {icon}
        <div className="text-left">
          {label}
          <div className="text-gray-500 text-mini leading-3">{subLabel}</div>
        </div>
        <div className="flex flex-row items-center gap-1 text-gray-500 my-auto">
          {value}
          {showArrow && <ChevronRightIcon className="w-4 h-4" />}
        </div>
      </div>
    </button>
  );
};

export default InfoButton;
