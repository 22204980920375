import React from "react";
import tw, { TwStyle } from "twin.macro";
import { useLocation, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  className?: TwStyle;
  title?: string;
  stepback?: boolean;
};

function BackButton({ className, title, stepback = false }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state?.backlink || stepback)
    return (
      <div css={[tw`flex gap-4 items-center lg:hidden`, className]}>
        <button
          onClick={() =>
            stepback ? navigate(-1) : navigate(location.state.backlink)
          }
        >
          <XMarkIcon className="text-gray-700 w-5 h-5" />
        </button>
        {title}
      </div>
    );

  return <></>;
}

export default BackButton;
