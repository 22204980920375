import React from "react";
import Checkbox from "@components/elements/input/Checkbox";
import Input from "@components/elements/input/Input";

type Props = {
  items: Array<{
    checked?: boolean;
    label?: string;
  }>;
  label?: string;
  name: string;
  placeholder?: string;
  itemStyle?: string;
  onChange?: (
    e: Array<{
      checked?: boolean;
      label?: string;
    }>
  ) => void;
};

const CheckboxGroup = ({
  items = [],
  label,
  name,
  onChange,
  itemStyle = "flex flex-col lg:flex-row gap-x-8 gap-y-0",
}: Props) => {
  const changeBox = () => {
    if (onChange) {
      onChange(items);
    }
  };

  return (
    <div className="grid grid-flow-row">
      <div className="text-sm">{label}</div>
      <div className={itemStyle}>
        {items.map((box, index) => {
          return (
            <Checkbox
              key={name + "-" + index}
              checked={box.checked ? box.checked : false}
              name={name + "-" + index}
              onChange={changeBox}
            >
              {box.label}
            </Checkbox>
          );
        })}
        <Input
          name={name}
          type="hidden"
          value={JSON.stringify(items)}
          placeholder=""
        />
      </div>
    </div>
  );
};

export default CheckboxGroup;
