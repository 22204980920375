import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { LinkPreviewType } from "@/utils/types/LinkPreviewType";
import LinkPreview from "@components/elements/LinkPreview";
import api from "@/api/api";

type Props = {
  link: string;
  onChange: (linkPreviewId?: string) => void;
  onDelete?: () => void;
  small?: boolean;
};

const LegacyLinkPreview = ({ link, small, onChange, onDelete }: Props) => {
  const linkPreview = useQuery<LinkPreviewType, Error>(
    [`link-preview-${link}`],
    async () => {
      const { data } = await api.get(`/api/v1/link-preview?url=${link}`);
      return data.data;
    },
    {
      onSuccess: (data) => onChange(data.id),
    }
  );

  if (!linkPreview.isSuccess)
    return (
      <div className="w-full border border-gray-400 rounded-2xl animate-pulse bg-gray-300 h-[374px]"></div>
    );

  return (
    <LinkPreview link={linkPreview.data} small={small} onDelete={onDelete} />
  );
};

export default LegacyLinkPreview;
