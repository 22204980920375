import React, { useState } from "react";
import Panel from "@components/box/Panel";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type LinkType = {
  href: string;
  label: string;
  children?: Array<LinkType>;
};

const LegalNoticeContainer = () => {
  const [selection, setSelection] = useState<string>();
  const links: Array<LinkType> = [
    {
      label: "Über Tradefoox",
      href: "",
      children: [
        { label: "Über uns", href: "/info" },
        // { label: "TRADEFOOX Blog", href: "" },
        { label: "Industriegeschichte", href: "/industriegeschichte" },
        { label: "Amts- und Gemeindeblätter", href: "/amts_gemeindeblätter" },
        { label: "Kultur und Kunst in Chemnitz", href: "/kunst_kultur" },
        { label: "Für Start-Ups", href: "/startup_tipps" },
        {
          label: "Patent- und Markenrecht",
          href: "/marken_patentrecht",
        },
        { label: "Nachfolger gesucht", href: "/nachfolger" },
      ],
    },
    {
      label: "Geld verdienen",
      href: "",
      children: [
        { label: "Marketplace", href: "/marketplace" },
        { label: "Partnerprogramm", href: "/partner" },
        { label: "Regeln für Verkäufer", href: "/agb" },
        // { label: "Händler Verifizierung", href: "" },
        // { label: "Warum Verkäufer auf TRADEFOOX?", href: "" },
        { label: "Global agieren mit Tradefoox.com", href: "/global_agieren" },
      ],
    },
    {
      label: "Wir helfen Dir",
      href: "",
      children: [
        { label: "TRADEFOOX - So geht's", href: "/info" },
        { label: "Watch - So geht's", href: "/watch_faq" },
        { label: "FAQ für Firmen", href: "/firmen_faq" },
        { label: "FAQ für Mitglieder", href: "/mitglieder_faq" },
        { label: "Spendenaktionen", href: "/spendenaktionen" },
        { label: "Newsletter abonnieren", href: "/newsletter" },
        { label: "Messekalender", href: "/messe_kalender" },
        // { label: "Karriere", href: "" },
        { label: "Warum einen TRADEFOOX Account?", href: "/account_erstellen" },
      ],
    },
    {
      label: "Support",
      href: "",
      children: [
        { label: "AGB", href: "/conditions" },
        { label: "Kontakt", href: "/support" },
        { label: "Donate", href: "/" },
        { label: "Investors", href: "/" },
        { label: "Impressum", href: "/impressum" },
        { label: "Urheberrecht", href: "/urheberrecht" },
        { label: "Cookie Richtlinie", href: "/cookies" },
        { label: "Community Richtlinie", href: "/community" },
        { label: "Datenschutzrichtlinien", href: "/privacy" },
        { label: "Nutzungsbedingungen", href: "/nutzungsbedingungen" },
        { label: "Newsletter abmelden", href: "/newsletter_abmelden" },
        // { label: "Privatsphäre-Einstellungen", href: "" },
      ],
    },
  ];
  return (
    <Panel mobileBorder={false}>
      <div className="grid grid-flow-row lg:grid-cols-4 gap-6 lg:gap-2">
        {links.map((column: LinkType) => {
          return (
            <div key={column.href} className="flex flex-col gap-1 text-xs">
              <NavLink
                to={column.href}
                className="font-semibold lg:min-h-[32px]"
                onClick={() => {
                  setSelection(column.label);
                }}
              >
                {column.label}
              </NavLink>
              <div
                css={[
                  tw`flex flex-col gap-1 text-xs`,
                  selection != column.label && tw`max-lg:hidden`,
                ]}
              >
                {column.children &&
                  column.children.map((link: LinkType) => {
                    return (
                      <div
                        key={link.href}
                        className="flex flex-col gap-2 font-normal break-words hover:underline"
                      >
                        <NavLink to={link.href}>{link.label}</NavLink>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </Panel>
  );
};

export default LegalNoticeContainer;
