import isApp from "@/utils/isApp";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon, UsersIcon } from "@heroicons/react/20/solid";
import React, { useRef, useState } from "react";
import ModalWrapper from "@components/elements/shared/ModalWrapper";
import tw from "twin.macro";

type Props = {
  items: Array<
    | {
        label: string;
        icon: React.FC<Parameters<typeof UsersIcon>[0]>;
        onClick: () => void;
      }
    | null
    | false
    | undefined
  >;
};

const ContextMenu = (props: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  if (isApp || window.innerWidth < 768) {
    return (
      <>
        <button
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600 absolute top-3 right-3 z-20"
          onClick={(e) => {
            setOpen(true);
            e.stopPropagation();
          }}
        >
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <ModalWrapper
          open={open}
          onCancel={() => setOpen(false)}
          hideActions={true}
        >
          {props.items.map(
            (item, index) =>
              !!item && (
                <button
                  key={index}
                  onClick={(e) => {
                    item.onClick();
                    setOpen(false);
                    e.stopPropagation();
                  }}
                  type="submit"
                  css={[
                    tw`text-gray-700`,
                    tw`block w-full py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  <span>{item.label}</span>
                  <item.icon className="w-4 h-4" />
                </button>
              ),
          )}
        </ModalWrapper>
      </>
    );
  } else {
    return (
      <>
        <Menu
          as="div"
          className="inline-block text-left absolute w-full top-0 left-0"
          ref={ref}
        >
          <Menu.Button
            className="flex items-center rounded-full text-gray-400 hover:text-gray-600 z-10 absolute top-3 right-3"
            onClick={(e) => e.stopPropagation()}
          >
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Menu.Items className="absolute right-0 top-8 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {props.items.map(
                (item, index) =>
                  !!item && (
                    <div
                      className="text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-md mx-1"
                      key={index}
                    >
                      <button
                        key={index}
                        onClick={(e) => {
                          item.onClick();
                          e.stopPropagation();
                        }}
                        type="submit"
                        css={[
                          tw`block w-full px-2 py-2 text-left text-sm inline-flex items-center justify-between`,
                        ]}
                      >
                        <span>{item.label}</span>
                        <item.icon className="w-4 h-4" />
                      </button>
                    </div>
                  ),
              )}
            </div>
          </Menu.Items>
        </Menu>
      </>
    );
  }
};

export default ContextMenu;
