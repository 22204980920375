import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import Input from "@/components/elements/input/Input";
import axios from "axios";
import { User } from "@/utils/user";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import ContactForYouItem from "@pages/contacts/for_you/ContactForYouItem";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";

const tabs = [
  { label: "Alle", key: "", url: "" },
  { label: "Personen", key: "person", url: "personen" },
  { label: "Firmen", key: "company", url: "firmen" },
  { label: "Vereine", key: "club", url: "vereine" },
  { label: "Gruppe", key: "group", url: "gruppen" },
];

type Props = {
  tab?: string;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

const Profiles = ({ tab = "" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const contacts = useInfiniteQuery<Response>(
    ["contacts-for_you", searchQuery, tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=${pageParam}&q=${searchQuery}&type=${tab}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const { user } = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <div className="grid grid-flow-row gap-2">
      <Input
        placeholder="Profile durchsuchen"
        name="profiles_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <TabMenu subTab>
        {tabs.map((tab: any) => {
          return (
            <>
              <TabLink subTab to={"/entdecken/profile/" + tab.url}>
                {tab.label}
              </TabLink>
            </>
          );
        })}
      </TabMenu>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {contacts.data &&
          contacts.data.pages.map((page) =>
            page.data.map((item: User) => (
              <ContactForYouItem key={item.id} user={item} />
            )),
          )}
      </div>
      {contacts.hasNextPage && !contacts.isFetchingNextPage && (
        <ShowMoreButton onClick={() => contacts.fetchNextPage()} />
      )}
    </div>
  );
};

export default Profiles;
