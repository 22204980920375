import React, { useState } from "react";
import Input from "@/components/elements/input/Input";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

const Ads = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <div className="flex flex-col gap-3">
      <Input
        placeholder={t("main.navLinks.createAd.tabs.toYou.searchMarketplace")}
        name="ads_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
    </div>
  );
};

export default Ads;
