import React from "react";
import { LinkPreviewType } from "@/utils/types/LinkPreviewType";
import tw from "twin.macro";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ExternalLink from "@components/elements/shared/ExternalLink";

type Props = {
  link: LinkPreviewType;
  small?: boolean;
  onDelete?: () => void;
};

const LinkPreview = ({ link, small, onDelete }: Props) => {
  const domainFromUrl = (url: string) => {
    let match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im,
    );
    let result;
    if (match) {
      result = match[1];
      match = result.match(/^[^\.]+\.(.+\..+)$/);
      if (match) {
        result = match[1];
      }
    }
    return result;
  };

  return (
    <div className="w-full relative border border-gray-400 rounded-2xl overflow-hidden">
      {onDelete && (
        <button onClick={onDelete} className="absolute right-3 top-3 z-50">
          <XMarkIcon className="w-6 h-6" />
        </button>
      )}
      <ExternalLink to={link.url}>
        {link.image && (
          <div css={[tw`relative aspect-video`]}>
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={link.image + "/linkpreview"}
              alt={link.title}
            />
          </div>
        )}
        <div css={[tw`p-3 text-black`, small && tw`text-xssm leading-3`]}>
          {<div className="text-sm uppercase">{domainFromUrl(link.url)}</div>}
          {<div className="font-bold line-clamp-1">{link.title}</div>}
          {link.description && (
            <span css={[small && tw`line-clamp-1`]}>{link.description}</span>
          )}
        </div>
      </ExternalLink>
    </div>
  );
};

export default LinkPreview;
