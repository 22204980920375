import React from "react";
import BackButton from "@/components/nav/BackButton";

const CompanyFAQ = () => {
  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Firmen - So geht&apos;s
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              TRADEFOOX
            </div>
          </div>
        </section>

        <article className="text-sm">
          <h1 className="font-semibold text-base mb-4">Funktionen von Watch</h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-3">
              <h2 className="font-semibold">
                Wie können Arbeitgeber auf Bewertungen Antworten?
              </h2>
              Mit eingeloggtem Firmenprofil kann in der eigenen Übersicht der
              Bewertungen der Antworten-Button ausgewählt werden, es erscheint
              ein Kommentarfeld.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Wie erstelle ich ein Jobangebot?
              </h2>
              In der Auswahlmaske den Bereich für Arbeitgeber anwählen (Du musst
              mit einem Firmenprofil eingeloggt sein). Im linken Menü den Punkt
              Job veröffentlichen anwählen, die Maske mit den Reitern nach
              ausfüllen und Job speichern und veröffentlichen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Wie erstelle ich Vorlagen für Jobangebote oder Artikel?
              </h2>
              Nach dem Ausfüllen der Details in der Option Job veröffentlichen
              gibt es die Möglichkeit dieses Angebot auch als Vorlage zu
              speichern, eine Vorschau anzusehen und den Job direkt zu
              veröffentlichen.
              <br />
              Bei der Artikelerstellung im Online Market gibt es zu der Option
              veröffentlichen zusätzlich die Möglichkeit speichern. Unter meine
              Artikel Vorlagen im linken Menü sind alle gespeicherten Vorlagen
              zur Verwaltung.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Wie werde ich Verkäufer auf Tradefoox?
              </h2>
              Um Verkäufer auf Tradefoox zu werden, bedarf es zuerst ein
              Firmenprofil. Der OnlineMarket mit Produktübersichten ist über das
              Top-Menü oder linke Seiten-Menü aufzurufen. Es erscheint ein
              Button „Jetzt als Händler verifizieren“. Zur Freischaltung müssen
              nun Angaben über die Firma und die Gewerbeanmeldung als
              PDF-Dokument beigefügt werden. Nach Abschluss der Einreichung wird
              das Profil von Tradefoox geprüft und freigeschalten. Nach der
              Freischaltung unserer Mitarbeiter kann es auch direkt los gehen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Wie erstelle ich Artikel, mit und ohne csv-Datei?
              </h2>
              Nach aufrufen des OnlineMarkets im eingeloggten und
              freigeschalteten Zustands erscheint im linken Menü die
              Möglichkeit, Artikel hochzuladen. Nach ausfüllen aller Angaben
              kann ein Artikel veröffentlicht und als Vorlage gespeichert
              werden.
              <br />
              Für Händler mit vielen Artikeln bietet sich im linken Menü auch
              die Möglichkeit des gesammelten Uploads. Hierfür muss die Beispiel
              CSV-Datei heruntergeladen werden. Anhand dieser kann man sich nach
              den Inhalten orientieren. Nach dem Tabellenupload gibt es noch den
              Überprüfungsmodus mit der Möglichkeit Spalten zuzuweisen, wenn
              diese mal nicht an der richtigen Position erscheinen sollten.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Wie kann ich mein Tradefoox Firmenprofil auf meiner eigenen
                Webseite einbinden?
              </h2>
              Kopiere die URL Deines Profils auf tradefoox.com und nutze die
              Einbettungsmöglichkeiten im Editor deiner Webseite. Du hast
              zusätzlich die Möglichkeit unser Logo mit einzufügen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Erfolgreich verkaufen auf Tradefoox
              </h2>
              So ein guter Artikel, aber der Verkauf war nicht erfolgreich? Oft
              liegt es an Kleinigkeiten, die relativ einfach verbessert werden
              können. Bei richtiger Darstellung können sich die meisten Produkte
              auch gut verkaufen. Hier finden sich ein paar hilfreiche
              Informationen, wie der Absatz gesteigert wird!
              <br />
              Gebe Dein Bestes, um Artikel zu beschreiben!
              <br />
              Potenzielle Käufer treffen Kaufentscheidungen hauptsächlich anhand
              des Textes - die Entscheidung beginnt mit dem Titel.
              <br />
              Gib dem Angebot einen eindeutigen Titel, der bereits alle
              relevanten Informationen zu Deinem Produkt enthält. Es reicht
              nicht aus, im Titel zu erwähnen, dass Du z.B. ein iPhone
              verkaufst. Stelle sicher, dass Du der Zeile weitere Informationen
              hinzufügst: Modellnummer, verfügbarer Speicherplatz, Gerätestatus
              und Hersteller sind alles wertvolle Informationen für Deine
              Interessenten. Sei im Text so genau wie möglich und beschreibe die
              von Dir angebotenen Produkte im Detail.
              <br />
              Du solltest alle Mängel oder Macken beheben. Nur wer ehrlich ist
              und Schwächen nennt, wird ernst und glaubwürdig erscheinen.
              Vertrauen aufbauen. Käufer schlagen tendenziell eher zu, wenn sie
              ein genaues Bild vom angebotenen Artikel haben.
              <br />
              Weitere Tipps zur Artikelbeschreibung: Absätze schaffen eine
              Struktur, Rechtschreib- oder Grammatikfehler wirken unseriös. Für
              Privatverkäufer ist es wichtig, die Gewährleistung ausdrücklich
              auszuschließen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">Beziehungen aufbauen</h2>
              Kunden kommen zu TRADEFOOX , weil sie hier eine vertrauenswürdige
              Einkaufsumgebung vorfinden, in der sie Produkte entdecken,
              vergleichen und kaufen können. Durch aussagefähige Beschreibungen
              werden Deine Produkte und Botschaften für diese engagierte
              Zielgruppe sichtbarer. Sie erhöhen das Markenbewusstsein und
              können einen größeren Kreis an Stammkunden bringen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">Relevante Kunden erreichen</h2>
              75 % der TRADEFOOX -Kunden nutzen das TRADEFOOX -Suchfeld für die
              Suche nach Produkten. Durch Keyword- und Produkt-Targeting kannst
              Du Käufer zur richtigen Zeit und am richtigen Ort erreichen und
              mit ihnen interagieren.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Die richtigen Einblicke erhalten
              </h2>
              Dank leicht verständlicher Berichte kannst Du nachvollziehen, wie
              die Käufer mit Deiner Marke interagiert haben. Darüber hinaus
              helfen Dir eine Reihe von Funktionen Deine Aktivitäten zu
              überwachen und neue Verkaufsstrategien in die Tat umzusetzen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">Einfache, flexible Lösungen</h2>
              Wenn Du auf TRADEFOOX verkaufst, profitierst Du von Lösungen, mit
              denen Du Käufer an jedem Touchpoint erreichen kannst. Alle
              Darstellungsmöglichkeiten gemeinsam zu nutzen kann Dir helfen die
              Bekanntheit Deines Unternehmens zu erhöhen und Deine Ziele
              effizienter zu erreichen.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">Wie Spende ich?</h2>
              Auf unserer Seite Spendenaktion finden sich viele regionale
              Projekte und Organisationen, die Du unterstützen kannst. Es
              befinden sich weiterführende Links zu den eigenen Webseiten der
              Organisationen unter dem Text. Dort sind die Spendenvorgänge genau
              erklärt.
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default CompanyFAQ;
