import React from "react";
import { Post } from "@/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import api from "@/api/api";
import useDebounce from "@/utils/useDebounce";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import CompactPost from "@pages/news/posts/CompactPost";
import WatchType from "@/utils/types/watch/WatchType";

type Props = {
  userId: string;
  searchQuery: string;
};

type Response = {
  data: WatchType[];
  current_page: number;
  total_pages: number;
};
const PostLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);

  const list = useInfiniteQuery<Response>(
    [userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/watch?liked_by=${userId}&page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
        {list.data &&
          list.data.pages.map((page) =>
            page.data.map((item: WatchType) => (
              <CompactPost
                key={item.media_key}
                id={item.media_key}
                viewCount={item.view_count}
                likeCount={item.like_count}
                image={item.media_thumbnail}
              />
            )),
          )}
      </div>
      {list.hasNextPage && !list.isFetchingNextPage && (
        <ShowMoreButton onClick={() => list.fetchNextPage()} />
      )}
    </>
  );
};

export default PostLikes;
