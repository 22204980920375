import React, { PropsWithChildren } from "react";
import isApp from "@/utils/isApp";
import { Browser } from "@capacitor/browser";

type Props = {
  to: string;
};
const ExternalLink = (props: PropsWithChildren<Props>) => {
  if (isApp) {
    return (
      <a
        className="text-lightblue cursor-pointer"
        onClick={() => {
          Browser.open({ url: props.to });
        }}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <a
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
        className="text-lightblue"
      >
        {props.children}
      </a>
    );
  }
};

export default ExternalLink;
