import React from "react";
import Panel from "@components/box/Panel";
import BackButton from "@/components/nav/BackButton";

const CommunityRulesContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-3 text-gray-700 text-xs">
        <h1 className="font-bold text-sm">Community Richtlinien</h1>
        <h2 className="font-bold">
          Wir möchten, dass TRADEFOOX die beste Version Deiner Person
          widerspiegelt
        </h2>
        <div>
          Dies ist eine Gemeinschaft, in der wir einander mit Respekt behandeln
          und uns gegenseitig zum Erfolg verhelfen.
        </div>
        <h2 className="font-bold">Sicher sein</h2>
        <div>Bringe nur sichere Gespräche auf TRADEFOOX.</div>
        <div>Erfahren Sie mehr</div>
        <h2 className="font-bold">Sei vertrauenswürdig</h2>
        <div>
          Nutze Deine wahre Identität und teile Informationen, die real und
          authentisch sind.
        </div>
        <div>Erfahren Sie mehr</div>
        <h2 className="font-bold">Sei professionell</h2>
        <div>
          Wir erlauben umfassende Gespräche über die Arbeits- und Privatwelt,
          bleiben aber einfach professionell.
        </div>
        <div>Erfahren Sie mehr</div>
        <h2 className="font-bold">
          TRADEFOOX Professional Community-Richtlinien
        </h2>
        <div>
          Vielen Dank, dass Du TRADEFOOX nutzt. Wir freuen uns, dass Du Dich für
          TRADEFOOX entschieden hast. Unsere Plattform bringt Fach- und
          Führungskräfte ebenso wie Privatpersonen und Vereine aus aller Welt
          zusammen, die sich austauschen, Videos teilen, Jobs und Aufträge
          finden, Produkte und Ideen vermarkten, auf dem Laufenden bleiben, neue
          Kompetenzen entwickeln und starke Beziehungen aufbauen möchten.
        </div>
        <div>
          Die von Dir beigesteuerten Inhalte sollten einen Mehrwert für die
          TRADEFOOX-Community darstellen. Weitere Informationen dazu, was das
          bedeutet, findest Du unten. Lies auch unsere Richtlinien zur
          Verwendung der Publishing-Plattform.
        </div>
        <div>
          Gemeinsam können wir TRADEFOOX zu einem Ort machen, an dem alle
          lernen, wachsen und sich austauschen können, und so berufliche und
          wirtschaftliche Chancen für alle ermöglichen.
        </div>
        <div>
          Wir aktualisieren unsere professionellen Community-Richtlinien
          regelmäßig, um sicherzustellen, dass die Millionen von Gesprächen, die
          jeden Tag über unsere Dienste stattfinden, unseren Mitgliedern helfen,
          produktiver und erfolgreicher zu sein und frei von unangemessenen und
          unerwünschten Inhalten oder Verhaltensweisen zu sein.
        </div>
        <h2 className="font-bold">
          Teile uns mit, wenn Du missbräuchliche Inhalte siehst
        </h2>
        <div>
          Wenn Du etwas siehst, von dem Du glaubst, dass es gegen unsere
          Richtlinien verstößt, sei es in Profilen, Beiträgen, Nachrichten,
          Kommentaren oder anderswo, melde es uns bitte. In Kombination mit
          unseren automatisierten Abwehrmaßnahmen helfen uns diese Berichte,
          Missbrauch zu erkennen und zu verhindern. Bitte nutze die
          Reporting-Tools verantwortungsvoll und nur für den vorgesehenen Zweck.
          Um mehr darüber zu erfahren, wie Du unangemessene Inhalte,
          Verhaltensweisen oder Mitglieder melden kannst, besuche unser
          Sicherheitscenter.
        </div>
        <h2 className="font-bold">
          Ein Verstoß gegen unsere Community-Richtlinien kann zu Maßnahmen gegen
          Dein Konto oder Deine Inhalte führen
        </h2>
        <div>
          Diese Richtlinien gelten für alle Mitglieder. Abhängig von der Schwere
          des Verstoßes schränken wir möglicherweise die Sichtbarkeit bestimmter
          Inhalte ein, kennzeichnen sie oder entfernen sie vollständig.
          Wiederholte oder schwerwiegende Verstöße führen zu einer
          Kontosperrung. Wenn Du der Meinung bist, dass die in Bezug auf Deine
          Inhalte oder Dein Konto vorgenommenen Maßnahmen fehlerhaft waren,
          kannst Du Einspruch einlegen.
        </div>
        <div>
          Erfahre mehr darüber, wie wir Verstöße gegen unsere professionellen
          Community-Richtlinien durchsetzen .
        </div>
        <h2 className="font-bold">Sicher sein</h2>
        <div>Bringe nur sichere Gespräche und Inhalte auf TRADEFOOX.</div>
        <div>
          <h2 className="font-bold">
            Veröffentliche keine belästigenden Inhalte:
          </h2>
          <div>
            Wir dulden weder Mobbing noch Belästigung. Dazu gehören gezielte
            persönliche Angriffe, Einschüchterung, Beschämung, Verunglimpfung
            und beleidigende Sprache gegenüber anderen Mitgliedern. Gebe keine
            persönlichen oder sensiblen Daten anderer preis (auch bekannt als
            „Doxing“) und rege andere nicht dazu an, dasselbe zu tun. Du darfst
            Dich nicht an Trolling oder anderen sich wiederholenden negativen
            Inhalten beteiligen, die andere Mitglieder oder Gespräche auf der
            Plattform stören.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien zu belästigenden und
          missbräuchlichen Inhalten.
        </div>
        <div>
          <h2 className="font-bold">
            Drohe nicht mit Gewalt, rufen nicht dazu auf oder fördere sie nicht:
          </h2>
          <div>
            Wir erlauben keine Androhung oder Anstiftung zu Gewalt jeglicher
            Art. Wir erlauben keine Einzelpersonen oder Gruppen, die sich an
            Gewalt, Sachbeschädigung oder organisierter Kriminalität beteiligen
            oder diese fördern. Du darfst TRADEFOOX nicht dazu nutzen,
            Unterstützung für solche Personen oder Gruppen auszudrücken oder auf
            andere Weise Gewalt zu verherrlichen.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien zu gewalttätigen und drastischen
          Inhalten.
        </div>
        <div>
          <h2 className="font-bold">
            Teile kein Material, das die Ausbeutung von Kindern darstellt:
          </h2>
          <div>
            Wir tolerieren keinerlei Inhalte, die die sexuelle Ausbeutung von
            Kindern darstellen. Teilen, posten, übermitteln oder erbitte kein
            Material über die Ausbeutung von Kindern über unsere Plattform.
            Nutze TRADEFOOX in keiner Weise, um den Missbrauch oder die
            Ausbeutung von Kindern zu erleichtern, zu fördern oder sich daran zu
            beteiligen. Wenn uns offensichtliche Ausbeutung von Kindern bekannt
            wird, melden wir dies dem National Center for Missing and Exploited
            Children (NCMEC). Darüber hinaus sind wir uns darüber im Klaren,
            dass es Fälle geben kann, in denen Menschen Bilder von nicht
            sexualisierter Nacktheit von Kindern mit guten Absichten teilen, wir
            entfernen diese Bilder jedoch im Allgemeinen aufgrund der
            Möglichkeit einer Veruntreuung und eines Missbrauchs durch andere.
          </div>
        </div>
        <div>
          <h2 className="font-bold">
            Bewirb, verkaufe oder versuche nicht, illegale oder gefährliche
            Waren oder Dienstleistungen zu erwerben.
          </h2>
          <div>
            Wir gestatten keine Inhalte, die den Kauf illegaler oder
            gefährlicher Waren und/oder Dienstleistungen, Prostitution und
            Begleitdienste erleichtern. Wir gestatten keine Inhalte, die
            gefälschte Bildungs- und/oder Berufszeugnisse, den Verkauf
            gestohlener Daten, die Teilnahme an Proxy-Tests oder Anweisungen zur
            Erstellung gefälschter offizieller Dokumente fördern oder
            verbreiten. Du darfst TRADEFOOX nicht für die Durchführung von
            Lotterien, Wettbewerben, Gewinnspielen oder Werbegeschenken nutzen.
            Nutze TRADEFOOX nicht, um tragische Ereignisse zu kommerziellen
            Zwecken zu sensationalisieren oder daraus Kapital zu schlagen.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinie zu illegalen und unangemessenen
          kommerziellen Aktivitäten.
        </div>
        <div>
          <h2 className="font-bold">
            Teile keine Inhalte, die gefährliche Organisationen oder
            Einzelpersonen fördern.
          </h2>
          <div>
            Wir erlauben keine terroristischen Organisationen oder gewalttätigen
            extremistischen Gruppen auf unserer Plattform. Und wir gestatten
            Personen, die solchen Organisationen oder Gruppen angehören, nicht,
            ein TRADEFOOX-Profil zu haben. Inhalte, die terroristische
            Aktivitäten darstellen, die dazu dienen, für terroristische
            Organisationen zu rekrutieren, oder die den Terrorismus in
            irgendeiner Weise bedrohen, fördern oder unterstützen, werden nicht
            toleriert.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien für gefährliche Organisationen
          und Einzelpersonen.
        </div>
        <h2 className="font-bold">Sei vertrauenswürdig</h2>
        <div>
          Wir verlangen von Dir, dass Du auf TRADEFOOX Deine wahre Identität
          angibst, genaue Informationen über Dich selbst oder Deine Organisation
          angibst und nur echte und authentische Informationen weitergibst.
        </div>
        <div>
          <h2 className="font-bold">
            Teile keine falschen oder irreführenden Inhalte:
          </h2>
          <div>
            Teile Inhalte nicht auf eine Art und Weise, von der Du weißt oder
            glaubst, dass sie irreführend oder ungenau ist, einschließlich
            Fehlinformationen oder Desinformationen. Teile keine Inhalte, um
            eine Wahl oder einen anderen bürgerschaftlichen Prozess zu stören
            oder unzulässig zu beeinflussen. Wir können Dich daran hindern,
            Inhalte von Websites zu veröffentlichen, die bekanntermaßen
            Fehlinformationen produzieren oder enthalten. Teile keine Inhalte,
            die den Leitlinien führender globaler Gesundheitsorganisationen und
            Gesundheitsbehörden direkt widersprechen; einschließlich falscher
            Informationen über die Sicherheit oder Wirksamkeit von Impfstoffen,
            Medikamenten und ähnlichem. Teile keine falschen Inhalte oder
            Informationen, einschließlich Nachrichtenmeldungen, als ob diese
            wahr oder wahrscheinlich wahr wären. Veröffentliche keine
            „Deepfake“-Bilder oder -Videos anderer und poste keine anderweitig
            manipulierten Inhalte, um zu täuschen. Werberichtlinien.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinie zu falschen oder irreführenden
          Inhalten.
        </div>
        <div>
          <h2 className="font-bold">
            Erstelle kein Fake-Profil und verfälsche keine Informationen über
            Dich selbst:
          </h2>
          <div>
            Wir erlauben keine Fake-Profile oder -Entitäten. Veröffentliche
            keine irreführenden oder täuschenden Informationen über Dich selbst,
            Dein Unternehmen, deine Qualifikationen, Berufserfahrung,
            Zugehörigkeiten oder Erfolge. Verwende für Dein Profilfoto kein Bild
            einer anderen Person oder ein anderes Bild, das Dir nicht ähnelt .
            Verknüpfe Dich nicht auf TRADEFOOX mit einem Unternehmen oder einer
            Organisation, mit der Du eigentlich nicht beruflich verbunden bist.
            Nutze nicht das TRADEFOOX-Konto einer anderen Person und versuchen
            auch nicht, ein Mitgliedsprofil für jemand anderen als Dich selbst
            zu erstellen. Und teilen Dein TRADEFOOX-Konto nicht mit anderen.
          </div>
        </div>
        <div>
          <h2 className="font-bold">Betrüge oder täusche andere nicht.</h2>
          <div>
            Nutze TRADEFOOX nicht, um Liebesbetrug zu ermöglichen,
            Pyramidensysteme zu fördern oder Mitglieder auf andere Weise zu
            betrügen. Gebe keine bösartige Software weiter, die unsere
            Mitglieder, unsere Plattform oder unsere Dienste gefährdet.
            Phishing-Versuche werden nicht toleriert.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien zu Betrug und betrügerischen
          Inhalten.
        </div>
        <h2 className="font-bold">Sei professionell</h2>
        <div>
          Um eine professionelle Plattform aufrechtzuerhalten, sollten die
          Mitglieder einander mit Respekt und Höflichkeit behandeln. Interagiere
          nicht mit anderen und teile keine Inhalte auf eine Art und Weise, die
          unhöflich, unangemessen oder respektlos ist.
        </div>
        <div>
          <h2 className="font-bold">Sei nicht hasserfüllt.</h2>
          <div>
            Wir erlauben keine Inhalte, die Einzelpersonen oder Gruppen aufgrund
            ihrer tatsächlichen oder vermeintlichen Rasse, ethnischen
            Zugehörigkeit, nationalen Herkunft, Kaste, ihres Geschlechts oder
            ihrer Geschlechtsidentität angreifen, verunglimpfen, einschüchtern,
            entmenschlichen, zu Hass, Gewalt, nachteiligen oder
            diskriminierenden Handlungen gegen Einzelpersonen oder Gruppen
            aufstacheln oder diese androhen. sexuelle Orientierung,
            Religionszugehörigkeit, Alter oder Behinderungsstatus. Hassgruppen
            sind auf TRADEFOOX nicht erlaubt. Verwende keine rassistischen,
            religiösen oder anderen Beleidigungen, die Hass schüren oder
            fördern, oder andere Inhalte, die Spaltung hervorrufen sollen.
            Veröffentliche oder teile keine Inhalte, die ein gut dokumentiertes
            historisches Ereignis wie den Holocaust oder die Sklaverei in den
            Vereinigten Staaten leugnen.
          </div>
        </div>
        <div>
          Erfahren Sie mehr über unsere Richtlinien zu hasserfüllten und
          herabwürdigenden Inhalten.
        </div>
        <div>
          <h2 className="font-bold">
            Lass Dich nicht auf sexuelle Anspielungen oder unerwünschte
            Annäherungsversuche ein.
          </h2>
          <div>
            Wir erlauben keine unerwünschten Äußerungen von Anziehung, Begierde,
            Bitten um eine romantische Beziehung, Heiratsanträge, sexuelle
            Annäherungsversuche oder Anspielungen oder anstößige Bemerkungen.
            TRADEFOOX ist eine professionelle Networking-Plattform, keine
            Dating-Site. Nutze TRADEFOOX nicht, um romantische Kontakte zu
            knüpfen, nach romantischen Verabredungen zu fragen oder sexuelle
            Kommentare zum Aussehen oder der wahrgenommenen Attraktivität einer
            Person abzugeben. Sende keine unerwünschten Annäherungsversuche in
            Nachrichten, Beiträgen oder Kommentaren und sende keine sexuell
            eindeutigen Bilder an irgendjemanden auf der Plattform.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien zu sexueller Belästigung und
          unerwünschten Annäherungsversuchen.
        </div>
        <div>
          <h2 className="font-bold">
            Teile kein schädliches oder schockierendes Material.
          </h2>
          <div>
            Wir erlauben keine Inhalte, die übermäßig grausam oder schockierend
            sind. Dazu gehören Inhalte, die sadistisch oder grundlos anschaulich
            sind, etwa die Darstellung von Körperverletzungen, schwerer
            körperlicher oder sexueller Gewalt. Wir erlauben keine Inhalte,
            Aktivitäten oder Veranstaltungen, die kriminelle Aktivitäten
            fördern, organisieren, darstellen oder erleichtern. Wir gestatten
            außerdem keine Inhalte, die den Waffenbau, Drogenmissbrauch und
            Diebstahlsdrohungen darstellen oder fördern. Beteilige Dich nicht an
            Begleitdiensten, Prostitution, Ausbeutung von Kindern oder
            Menschenhandel und fördere diese nicht. Teile keine Inhalte oder
            Aktivitäten, die Selbstmord oder jede Art von Selbstverletzung,
            einschließlich Selbstverstümmelung und Essstörungen, fördern oder
            fordern. Wenn Du Anzeichen dafür siest, dass jemand über
            Selbstverletzung nachdenkt, Bitte melde es mithilfe unserer
            Meldetools und ziehe in Betracht, es auch deinen örtlichen
            Strafverfolgungsbehörden zu melden. Teile kein Material, das
            Nacktheit oder sexuelle Aktivitäten zeigt.
          </div>
        </div>
        <div>
          Erfahre mehr über unsere Richtlinien zu gewalttätigen und drastischen
          Inhalten sowie zu unseren Richtlinien zu Nacktheit und Inhalten für
          Erwachsene.
        </div>
        <div>
          <h2 className="font-bold">
            Spamme weder Mitglieder noch die Plattform.
          </h2>
          <div>
            Wir erlauben keine ungezielten, irrelevanten, offensichtlich
            unerwünschten, nicht autorisierten, angemessen kommerziellen oder
            verkaufsfördernden oder sich grundlos wiederholenden Nachrichten
            oder ähnlichen Inhalte. Verwende unsere Einladungsfunktion nicht, um
            Werbenachrichten an Personen zu senden, die Sie nicht kennen, oder
            um Personen auf andere Weise zu spammen. Bitte bemühen Sie sich,
            originelle, professionelle, relevante und interessante Inhalte zu
            erstellen, um Engagement zu gewinnen. Tun Sie nichts, um die
            Interaktion mit Ihren Inhalten künstlich zu steigern. Reagieren Sie
            authentisch auf die Inhalte anderer und einigen Sie sich nicht im
            Voraus darauf, die Inhalte des anderen zu liken oder erneut zu
            teilen.
          </div>
        </div>
        <div>Erfahre mehr über unsere Richtlinien zu Spam-Inhalten.</div>
      </div>
    </Panel>
  );
};

export default CommunityRulesContainer;
