import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import tw from "twin.macro";
import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import api from "@/api/api";
import EditWatchPost from "@components/elements/posts/form/EditWatchPost";
import useLoginModal from "@/state/modal/useLoginModal";
import { showReportModal } from "@/state/reportModal/actions";
import { useDispatch } from "react-redux";
import BlockUserDropdownButton from "@components/elements/shared/buttons/BlockUserDropdownButton";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  postId: string;
  authorId: string;
  isRepost?: boolean;
  bookmarked: boolean;
};
const WatchDropdown = ({ postId, authorId, bookmarked, isRepost }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deletePost = useMutation(["delete-post"], {
    mutationFn: async (postId: string) => {
      const res = await api.delete(`/api/v1/posts/${postId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) return null;

  return (
    <>
      {openEdit && (
        <EditWatchPost
          onSuccess={() => setOpenEdit(false)}
          postId={postId}
          onClose={() => setOpenEdit(false)}
        />
      )}
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full text-white">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-8 w-8" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute -translate-y-[calc(100%+40px)] right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {user?.id === authorId && !isRepost && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active
                        ? tw`bg-gray-100 text-gray-900`
                        : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => setOpenEdit(true)}
                  >
                    Bearbeiten
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <Link
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                  to="https://cdn.tradefoox.iaccam.com/"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </Link>
              )}
            </Menu.Item>
            {user.id === authorId && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active
                        ? tw`bg-gray-100 text-gray-900`
                        : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => deletePost.mutate(postId)}
                  >
                    Löschen
                    <TrashIcon className="w-4 h-4" />
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                  onClick={() => dispatch(showReportModal(postId, "post"))}
                >
                  Melden
                  <FlagIcon className="w-4 h-4" />
                </button>
              )}
            </Menu.Item>
            <BlockUserDropdownButton
              userId={authorId}
              blocked={false}
              keys={["posts"]}
            />
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    if (user) {
                      bookmarked ? remove.mutate() : add.mutate();
                    } else {
                      loginModal.open();
                    }
                  }}
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  {t("main.groupsInfoForm.buttons.save")}
                  {bookmarked ? (
                    <BookmarkIconSolid className="w-4 h-4" />
                  ) : (
                    <BookmarkIcon className="w-4 h-4" />
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default WatchDropdown;
