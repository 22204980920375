import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/state/store";
import { v4 as uuid } from "uuid";
import React from "react";

export enum NotificationStyle {
  Announcement,
  Header,
  Toasts,
}

export enum NotificationTypes {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success",
}

export interface NotificationsState {
  value: Record<NotificationStyle, Record<string, Notification>>;
}

export interface Notification {
  uuid: string;
  title?: string;
  text: string;
  type: NotificationTypes;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  priority: number;
  duration?: number;
}

export interface NotificationPayload {
  title?: string;
  text: string;
  type: NotificationTypes;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  style: NotificationStyle;
  priority: number;
  duration?: number;
}

const initialState: NotificationsState = {
  value: {
    [NotificationStyle.Announcement]: {},
    [NotificationStyle.Header]: {},
    [NotificationStyle.Toasts]: {},
  },
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<NotificationPayload>) => {
      const localUuid = uuid();
      state.value[action.payload.style][localUuid] = {
        ...action.payload,
        uuid: localUuid,
      };
    },
    remove: (
      state,
      action: PayloadAction<{ uuid: string; style: NotificationStyle }>,
    ) => {
      delete state.value[action.payload.style][action.payload.uuid];
    },
  },
});

export const selectNotifications = (state: RootState) =>
  state.notifications.value;

export const { add, remove } = notificationsSlice.actions;

export default notificationsSlice.reducer;
