import React from "react";
import Panel from "@components/box/Panel";
import BackButton from "@/components/nav/BackButton";

const CopyRightContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-3 text-gray-700 text-xs">
        <h1 className="text-sm font-bold">Urheberrechtsrichtlinie</h1>
        <div>Gültig ab 26. März 2014</div>
        <h2 className="font-semibold">
          Beschwerden über Inhalte, die auf der TRADEFOOX-Website veröffentlicht
          werden
        </h2>
        <div>
          TRADEFOOX respektiert die geistigen Eigentumsrechte anderer und möchte
          eine Plattform anbieten, die keine Inhalte enthält, die diese Rechte
          verletzen. Unsere Nutzungsvereinbarung verlangt, dass die von
          Mitgliedern geposteten Informationen korrekt und rechtmäßig sind und
          nicht die Rechte Dritter verletzen. Um diese Ziele zu fördern, bietet
          TRADEFOOX ein Verfahren zur Einreichung von Beschwerden über von
          unseren Mitgliedern gepostete Inhalte. Unsere Richtlinien und
          Verfahren werden in den folgenden Abschnitten beschrieben und/oder
          referenziert.
        </div>
        <div>
          Bitte beachte, dass TRADEFOOX unabhängig davon, ob wir den Zugriff auf
          Inhalte sperren oder nicht, nach Treu und Glauben versuchen kann, die
          schriftliche Benachrichtigung, einschließlich der Kontaktinformationen
          des Beschwerdeführers, an das Mitglied weiterzuleiten, das den Inhalt
          gepostet hat, und/oder andere angemessene Schritte unternehmen kann,
          um das Mitglied zu benachrichtigen Mitglied, dass TRADEFOOX eine
          Mitteilung über eine mutmaßliche Verletzung geistiger Eigentumsrechte
          oder eine andere Inhaltsverletzung erhalten hat. Außerdem ist es
          unsere Politik, unter geeigneten Umständen und nach unserem Ermessen
          die Konten von Mitgliedern bzw. Gruppen zu sperren und/oder zu
          kündigen, die die Rechte anderer verletzen oder wiederholt verletzen
          oder auf andere Weise rechtswidrige Inhalte veröffentlichen.
        </div>
        <div>
          Bitte beachte, dass jede Mitteilung oder Gegendarstellung, die Du
          einreichst, wahrheitsgemäß sein muss und unter Strafe des Meineids
          eingereicht werden darf. Eine falsche Mitteilung oder Gegendarstellung
          kann zu einer persönlichen Haftung führen. Du solltest daher
          möglicherweise den Rat eines Rechtsberaters einholen, bevor Du eine
          Mitteilung oder Gegendarstellung einreichst.
        </div>
        <h2 className="font-semibold">
          Ansprüche wegen Urheberrechtsverletzung
        </h2>
        <h2 className="font-semibold">Hinweis auf Urheberrechtsverletzung:</h2>
        <div>
          Gemäß dem Digital Millennium Copyright Act (17 USC § 512) hat
          TRADEFOOX Verfahren für den Empfang schriftlicher Benachrichtigungen
          über behauptete Verstöße implementiert. TRADEFOOX hat außerdem einen
          Agenten benannt, der Benachrichtigungen über behauptete
          Urheberrechtsverletzungen entgegennimmt. Wenn Du in gutem Glauben
          davon ausgehst, dass Dein Urheberrecht verletzt wurde, kannst Du ein
          Formular zur Meldung einer Urheberrechtsverletzung ausfüllen und
          einreichen oder auf andere Weise eine schriftliche Mitteilung
          übermitteln, die Folgendes enthält:
        </div>
        <ul className="list-decimal list-inside">
          <li>
            Eine elektronische oder physische Unterschrift der Person, die
            befugt ist, im Namen des Inhabers des Urheberrechtsinteresses zu
            handeln;
          </li>
          <li>
            Eine Beschreibung des urheberrechtlich geschützten Werks, das Deiner
            Meinung nach verletzt wurde;
          </li>
          <li>
            Eine Beschreibung mit Angabe der Position auf unserer Website des
            Materials, von dem Du behauptest, dass es einen Verstoß darstellt;
          </li>
          <li>
            Deine E-Mail-Adresse und Deine Postanschrift und/oder Telefonnummer;
          </li>
          <li>
            Eine Erklärung von Dir, dass Du in gutem Glauben davon ausgehst,
            dass die umstrittene Nutzung nicht vom Urheberrechtsinhaber, seinem
            Vertreter oder dem Gesetz genehmigt wurde; Und
          </li>
          <li>
            6. Eine eidesstattliche Erklärung von Dir, dass die Informationen in
            Deiner Mitteilung korrekt sind und dass Du der Urheberrechtsinhaber
            bist oder befugt bist, im Namen des Urheberrechtsinhabers zu
            handeln.
          </li>
        </ul>
        <div>
          Bitte reiche Deine Mitteilung wie folgt an den
          Urheberrechtsbeauftragten der TRADEFOOX Germany ein:
        </div>
        <div>
          Fülle unser Online-Einreichungsformular aus, um den TRADEFOOX zu
          kontaktieren
        </div>
        <div>Oder kontaktieren Sie uns per Mail unter:</div>
        <div className="flex flex-col gap-1">
          <div>André Fischer</div>
          <div>SCC Group</div>
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Telefon: +49 (0) 371 / 432 32 05 82</div>
          <div>E-Mail: datenschutz@tradefoox.com</div>
          <div>Wir bitten, von telefonischen Anfragen abzusehen.</div>
        </div>
        <h2 className="font-semibold">Gegendarstellung:</h2>
        <div>
          Wenn Du der Meinung bist, dass gegen Dich unrechtmäßig eine Mitteilung
          über eine Urheberrechtsverletzung eingereicht wurde, kannst Du gemäß
          Abschnitt 512(g)(2) und (3) des Digital Millennium Copyright Act eine
          Gegendarstellung einreichen. Du kannst das Formular „Gegendarstellung
          bezüglich Anspruch wegen Urheberrechtsverletzung“ ausfüllen oder auf
          andere Weise eine schriftliche Mitteilung einreichen, die Folgendes
          enthält:
        </div>
        <ul className="list-decimal list-inside">
          <li>Deine physische oder elektronische Signatur;</li>
          <li>
            Identifizierung des Materials, das entfernt wurde oder zu dem der
            Zugriff gesperrt wurde;
          </li>
          <li>
            Eine eidesstattliche Erklärung, dass Du in gutem Glauben davon
            ausgehst, dass die Entfernung oder Deaktivierung des Materials ein
            Fehler war oder dass das Material falsch identifiziert wurde;
          </li>
          <li>
            Dein vollständiger Name, Deine E-Mail-Adresse, Deine Postanschrift
            und eine Erklärung, dass Du der Zuständigkeit des
            Bundesbezirksgerichts in dem Gerichtsbezirk zustimmst, in dem sich
            Deine Adresse befindet,
          </li>
          <li>
            Bitte reichen Deine Gegendarstellung über unser
            Online-Einreichungsformular oder per Post an die oben angegebene
            Adresse an den Urheberrechtsbeauftragten von TRADEFOOX ein .
          </li>
        </ul>
        <h2 className="font-semibold">
          Ansprüche bezüglich anderer Inhalte als Urheberrechtsverletzungen
        </h2>
        <div>
          Bei anderen Problemen als Urheberrechtsverletzungen besuche bitte
          unser Hilfecenter. Dort findest Du Informationen dazu, wie Du andere
          Arten von Inhaltsverstößen kennzeichnen und melden kannst. Erfahre
          mehr:
        </div>
        <ul className="list-decimal list-inside">
          <li>
            Unangemessene Inhalte, Nachrichten oder Sicherheitsbedenken melden
          </li>
          <li>Markenrichtlinie von TRADEFOOX</li>
          <li>TRADEFOOXs Richtlinie zu falschen Profilen</li>
        </ul>
        <div>
          Hinweis zu den Inhalten von Associated Press auf TRADEFOOX: Text-,
          Foto-, Grafik-, Audio- und/oder Videomaterial von Associated Press
          darf nicht veröffentlicht, ausgestrahlt, zur Ausstrahlung oder
          Veröffentlichung umgeschrieben oder direkt oder indirekt in
          irgendeinem Medium weitergegeben werden. Weder diese AP-Materialien
          noch Teile davon dürfen auf einem Computer gespeichert werden, außer
          für den persönlichen und nicht kommerziellen Gebrauch. Benutzer dürfen
          keinen wesentlichen Teil des auf dieser Website gefundenen
          AP-Materials herunterladen oder reproduzieren. AP übernimmt keine
          Haftung für etwaige Verzögerungen, Ungenauigkeiten, Fehler oder
          Auslassungen bei der Übermittlung oder Zustellung ganz oder teilweise
          davon oder für Schäden, die sich aus dem Vorstehenden ergeben.
        </div>
      </div>
    </Panel>
  );
};

export default CopyRightContainer;
