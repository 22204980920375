import React, { useState } from "react";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@components/elements/input/Button";
import { formatDate } from "@/utils/formatDate";
import moment from "moment";
import "moment/locale/de";
import Panel from "@/components/box/Panel";
import tw from "twin.macro";

type Props = {
  onClose: () => void;
  onChange: (date: number | undefined) => void;
  selectedDate?: number;
  dark?: boolean;
};
const Schedule = ({ onClose, onChange, selectedDate, dark }: Props) => {
  const [date, setDate] = useState(selectedDate || Date.now() + 900000);
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  const maxDays = new Date(year, month, 0).getDate();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <Panel mobileBorder={false} dark={dark}>
      <div
        css={[
          tw`grid grid-flow-row gap-2 text-sm`,
          dark && tw`bg-black text-white`,
        ]}
      >
        <div className="flex flex-row gap-8 items-center">
          <button onClick={onClose}>
            <XMarkIcon className="w-8 h-8 hover:bg-gray-200 rounded-full p-1.5" />
          </button>
          <div className="font-semibold text-base">Planen</div>
          <div className="flex flex-row gap-4 items-center ml-auto">
            {selectedDate && (
              <button
                className="border-b-gray-800 border-b-2 text-sm"
                onClick={() => {
                  onChange(undefined);
                  onClose();
                }}
              >
                löschen
              </button>
            )}
            <div className="w-fit">
              <Button
                variant={Button.Variants.Primary}
                size={Button.Sizes.Small}
                onClick={() => {
                  onChange(date);
                  onClose();
                }}
              >
                {selectedDate ? "Aktualisieren" : "Bestätigen"}
              </Button>
            </div>
          </div>
        </div>
        {selectedDate && (
          <div className="flex flex-row gap-2 items-center text-xs">
            <CalendarDaysIcon className="w-4 h-4" />
            Wird veröffentlicht am{" "}
            {formatDate(new Date(selectedDate).toISOString())}.
          </div>
        )}
        <div>Datum</div>
        <div className="flex flex-row gap-3">
          <select
            name="day"
            onChange={(e) => {
              setDate(
                new Date(
                  year,
                  month,
                  Number(e.target.value),
                  hours,
                  minutes,
                ).getTime(),
              );
            }}
            value={day}
            css={[dark && tw`text-black`]}
          >
            {Array(maxDays)
              .fill(0)
              .map((e, i) => {
                return <option key={i + 1}>{i + 1}</option>;
              })}
          </select>
          <select
            name="month"
            onChange={(e) => {
              setDate(
                new Date(
                  year,
                  Number(e.target.value),
                  day,
                  hours,
                  minutes,
                ).getTime(),
              );
            }}
            value={month}
            css={[dark && tw`text-black`]}
          >
            {moment.months().map((e, i) => {
              return (
                <option key={i} value={i}>
                  {e}
                </option>
              );
            })}
          </select>
          <select
            name="year"
            onChange={(e) => {
              setDate(
                new Date(
                  Number(e.target.value),
                  month,
                  day,
                  hours,
                  minutes,
                ).getTime(),
              );
            }}
            value={year}
            css={[dark && tw`text-black`]}
          >
            {Array(3)
              .fill(0)
              .map((e, i) => {
                return (
                  <option key={i} value={Number(new Date().getFullYear()) + i}>
                    {Number(new Date().getFullYear()) + i}
                  </option>
                );
              })}
          </select>
        </div>
        <div>Uhrzeit</div>
        <div className="flex flex-row gap-3">
          <input
            type="time"
            name="releasetime"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newDate = new Date(date);
              const [hours, minutes] = e.target.value.split(":");
              newDate.setHours(parseInt(hours));
              newDate.setMinutes(parseInt(minutes));
              setDate(newDate.getTime());
            }}
            css={[tw`pl-2 pr-2 py-2`, dark && tw`text-black`]}
            value={new Date(date).toLocaleTimeString("de-DE")}
          />
        </div>
        <div>
          <div>Zeitzone</div>
          <div className="font-semibold">{timeZone}</div>
        </div>
      </div>
    </Panel>
  );
};

export default Schedule;
