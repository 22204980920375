import React from "react";
import Panel from "@components/box/Panel";
import { NavLink } from "react-router-dom";
import BackButton from "@/components/nav/BackButton";

const PrivacyContainer = () => {
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback />
      <div className="flex flex-col gap-3 text-gray-700 text-xs">
        <h1 className="text-sm font-semibold">Datenschutzrichtlinie</h1>
        <div>
          Unsere Datenschutzrichtlinie wurde aktualisiert am 11.August 2022.
        </div>
        <h2 className="font-semibold">
          Der Schutz Deiner Daten ist uns wichtig.
        </h2>
        <div>
          TRADEFOOX hat es sich zur Aufgabe gemacht, Firmen, Vereine und private
          User sowie Fach- und Führungskräfte weltweit miteinander zu vernetzen,
          damit sie produktiver und erfolgreicher arbeiten können und sich zu
          verschiedensten Themen aus Kultur, Sport Politik etc. auszutauschen.
          Im Mittelpunkt dieser Mission steht unsere Verpflichtung, transparent
          darüber zu sein, welche Daten wir über Dich sammeln, wie sie verwendet
          werden und mit wem sie geteilt werden
        </div>
        <div>
          Wenn Du unsere Dienste nutzt, erfolgt dies auf Grundlage unserer
          Datenschutzrichtlinie (siehe nachfolgende Beschreibung). Wir bieten
          unseren Benutzern Wahlmöglichkeiten (hier Link) hinsichtlich der von
          uns erfassten, verwendeten und weitergegebenen Daten, wie in dieser
          Datenschutzrichtlinie, der{" "}
          <NavLink to="/cookies" className="text-darkcyan">
            Cookie-Richtlinie
          </NavLink>
          , den Einstellungen und unserem{" "}
          <NavLink to="/help" className="text-darkcyan">
            Hilfe-Center
          </NavLink>{" "}
          beschrieben.
        </div>
        <h2 className="font-semibold">Inhaltsverzeichnis</h2>
        <ul className="list-decimal list-inside">
          <li>Von uns erfasste Daten</li>
          <li>Wie wir Deine Daten verwenden</li>
          <li>Wie wir Informationen weitergeben</li>
          <li>Deine Entscheidungen und Pflichten</li>
          <li>Weitere wichtige Informationen</li>
        </ul>
        <h2 className="font-semibold">1. Einleitung</h2>
        <div>
          Wir sind ein soziales Netzwerk und eine Online-Plattform für private
          User, Unternehmen, Firmen und Vereine. Menschen nutzen unsere Dienste,
          um Geschäftsmöglichkeiten zu finden und gefunden zu werden, um mit
          anderen in Kontakt zu treten und Informationen zu finden. Unsere
          Datenschutzrichtlinie gilt für jedes Mitglied. Bei uns registrierte
          Nutzer („Mitglieder“) teilen ihre berufliche Identität, interagieren
          mit ihrem Netzwerk, tauschen Wissen und berufliche Einblicke aus,
          veröffentlichen und lesen relevante Inhalte, erlernen und erweitern
          Kompetenzen und finden Geschäfts- und Karrierechancen. Die Inhalte und
          Daten einiger unserer Dienste sind für Nichtmitglieder („Besucher“)
          einsehbar.
        </div>
        <div>
          Mit dem Begriff „designierte Länder“ beziehen wir uns auf die Länder
          in der Europäischen Union (EU) und im Europäischen Wirtschaftsraum
          (EWR) sowie auf die Schweiz.
        </div>

        <h2 className="font-semibold">Serviceleistungen</h2>
        <div>
          Diese Datenschutzrichtlinie, einschließlich unserer Cookie-Richtlinie,
          ist auf unsere Dienste anwendbar.
        </div>
        <div>
          Diese Datenschutzrichtlinie gilt für TRADEFOOX.com, Apps mit dem
          Markenzeichen von TRADEFOOX, sowie andere mit TRADEFOOX verbundene
          Websites, Apps, Informationen und Dienste („Dienste“), einschließlich
          Offsite-Dienste wie unsere Anzeigendienste sowie die Plug-ins „Mit
          TRADEFOOX bewerben“ und „Auf TRADEFOOX teilen“, aber unter Ausschluss
          von Diensten, bei denen angegeben wird, dass für sie eine andere
          Datenschutzrichtlinie gilt. Personen mit Wohnsitz in Kalifornien
          finden zusätzliche Offenlegungen, die nach kalifornischem Recht
          erforderlich sind, in unserem Dokument mit Informationen zum
          Datenschutz in Kalifornien (California Privacy Disclosure).
        </div>
        <h2 className="font-semibold">
          Datenverantwortliche und Vertragspartner
        </h2>
        <div>
          Wenn Du Dich in den „designierten Ländern“ befindest, wird TRADEFOOX
          day one mediagroup GmbH & Co. KG zum Verantwortlichen für Deine
          personenbezogenen Daten, die unsere Dienste bereitstellt, für oder
          durch diese erfasst oder im Zusammenhang mit ihnen verarbeitet werden.
        </div>
        <div>
          Wenn Du Dich außerhalb der „designierten Länder“ befinden, ist
          TRADEFOOX Corporation der Verantwortliche für Deine personenbezogenen
          Daten, die unseren Diensten bereitgestellt, für oder durch diese
          erfasst oder im Zusammenhang mit ihnen verarbeitet werden.
        </div>
        <div>
          Als Besucher oder Mitglied unserer Dienste unterliegt die Erfassung,
          Nutzung und Weitergabe Deiner personenbezogenen Daten dieser
          Datenschutzrichtlinie sowie anderen in dieser Datenschutzrichtlinie
          genannten Dokumenten und deren Aktualisierungen.
        </div>
        <h2 className="font-semibold">Ändern</h2>
        <div>
          Änderungen an dieser Datenschutzrichtlinie gelten für Deine Nutzung
          unserer Dienste ab dem „Datum des Inkrafttretens“.
        </div>
        <div>
          TRADEFOOX („wir“ oder „uns“) steht es frei, an dieser
          Datenschutzrichtlinie Änderungen vorzunehmen. Sollten wir wesentliche
          Änderungen vornehmen, benachrichtigen wir Dich über unsere Dienste
          oder auf andere Art und Weise, damit Du Gelegenheit hast, die
          Änderungen zu überprüfen, bevor sie in Kraft treten. Wenn Du mit
          irgendwelchen Änderungen nicht einverstanden bist, kannst Du Dein
          Konto schließen.
        </div>
        <div>
          Du erkennst an, dass durch Deine weitere Nutzung unserer Dienste nach
          Veröffentlichung der Änderungen an unserer Datenschutzrichtlinie oder
          der Benachrichtigung über diese Änderungen die Erfassung, Nutzung und
          Weitergabe Deiner personenbezogenen Daten der aktualisierten
          Datenschutzrichtlinie ab dem Datum des Inkrafttretens unterliegt.
        </div>
        <h2 className="font-semibold">2. 1. Von uns erfasste Daten</h2>
        <h2 className="font-semibold">1.1 Daten, die Sie uns bereitstellen</h2>
        <div>Du stellst Daten bereit, um ein Konto bei uns zu erstellen.</div>
        <h2 className="font-semibold">Registrierung</h2>
        <div>
          Zur Erstellung eines Kontos musst Du Daten bereitstellen,
          einschließlich Deines Namens, Deiner E-Mail-Adresse und/oder Deiner
          Handynummer und eines Passworts. Wenn Du Dich für einen Premium-Dienst
          registrierst, musst Du Zahlungs- und Rechnungsinformationen (z. B.
          Kreditkartendaten) angeben.
        </div>
        <div>
          Du erstellst Dein Profil auf TRADEFOOX (mit einem vollständigen Profil
          nutzt Du unsere Dienste optimal).
        </div>
        <h2 className="font-semibold">Profil</h2>
        <div>
          Welche Informationen Du in Deinem Profil veröffentlichst,
          beispielsweise Ausbildung, Berufserfahrung, Kenntnisse, Foto, Standort
          oder Region und Kenntnisbestätigungen, bleibt Dir überlassen. Du bist
          nicht verpflichtet, auf Deinem Profil zusätzliche Informationen
          anzugeben. Solche Profilinformationen tragen jedoch dazu bei, dass Du
          unsere Dienste besser nutzen kannst, z. B. weil Du dadurch für
          Recruiter und Geschäftschancen besser auffindbar bist. Es bleibt Dir
          überlassen, ob Du vertrauliche Informationen auf Deinem Profil
          veröffentlichen oder nicht und ob Du diese Informationen öffentlich
          zugänglich machst. Bitte füge keine personenbezogenen Daten zu Deinem
          Profil hinzu, von denen Du nicht möchtest, dass sie öffentlich
          verfügbar sind, und poste solche Daten nicht.
        </div>
        <div>
          Indem Du beispielsweise Dein Adressbuch oder Deinen Kalender
          synchronisierst, stellst Du weitere Daten zur Verfügung.
        </div>
        <h2 className="font-semibold">Veröffentlichen und Hochladen</h2>
        <div>
          Wir erfassen Deine personenbezogene Daten, wenn Du diese auf unseren
          Diensten bereitstellen, postest oder hochlädst, z. B. wenn Du auf
          unseren Diensten ein Formular ausfüllst (beispielsweise unter Angabe
          demografischer Daten oder eines Gehalts), eine Umfrage ausfüllst,
          einen Lebenslauf einreichst oder eine Bewerbung ausfüllst. Wenn Du
          Dein Adressbuch importierst, erhalten wir Deine Kontakte
          (einschließlich von Kontaktinformationen, die Deine Dienstleister oder
          Deine App automatisch zu Deinem Adressbuch hinzugefügt haben, als Sie
          Adressen oder Nummern kontaktierten, die nicht bereits auf Deiner
          Liste waren).
        </div>
        <div>
          Wenn Du Deine Kontakte oder Kalender mit unseren Diensten
          synchronisierst, erfassen wir die Daten Deines Adressbuchs und
          Terminkalenders, damit wir Dir und anderen zur Erweiterung Deines
          Netzwerks Kontakte vorschlagen und Informationen über Ereignisse
          bereitstellen können, z. B. Zeiten, Orte, Teilnehmer und Kontakte.
        </div>
        <div>
          Du bist nicht verpflichtet, personenbezogene Daten zu posten oder
          hochzuladen, jedoch ist Deine Fähigkeit, Dein Netzwerk zu erweitern
          und mit Deinem Netzwerk über unsere Dienste in Kontakt zu treten, in
          diesem Fall beschränkt.
        </div>
        <h2 className="font-semibold">1.2 Daten von Dritten</h2>
        <div>
          Andere Mitglieder können Dich in Beiträgen erwähnen oder über Dich
          schreiben.
        </div>
        <h2 className="font-semibold">Inhalte und News</h2>
        <div>
          Du und andere können Inhalte mit Informationen über Dich (im Rahmen
          von Artikeln, Beiträgen, Kommentaren, Videos) auf unseren Diensten
          veröffentlichen. Wir erfassen möglicherweise auch öffentlich
          verfügbare Informationen zu Deiner Person, zum Beispiel berufsbezogene
          News und Erfolge, und stellen diese im Rahmen unserer Dienste bereit,
          einschließlich in an andere Personen gesendete Mitteilungen über
          Erwähnungen in den News, in Übereinstimmung mit Deinen Einstellungen.
        </div>
        <div>
          Andere Mitglieder synchronisieren möglicherweise ihre Kontakte oder
          Kalender mit unseren Diensten.
        </div>
        <h2 className="font-semibold">Kontakt- und Kalenderdaten</h2>
        <div>
          Wir erhalten personenbezogene Daten (einschließlich Kontaktdaten) von
          Dir, wenn andere ihre Kontakt- oder Kalenderdaten mit unseren Diensten
          synchronisieren, ihre Kontakte Mitgliederprofilen zuordnen,
          Visitenkarten scannen und hochladen oder über unsere Dienste
          Nachrichten verschicken (einschließlich Einladungen oder
          Kontaktanfragen). Wenn Du oder andere der Synchronisation von
          E-Mail-Konten mit unseren Diensten zustimmen, erfassen wir außerdem
          Informationen aus den „Kopfzeilen“ der E-Mails, die wir mit
          Mitgliederprofilen assoziieren können.
        </div>
        <div>
          Kunden und Partnerunternehmen stellen uns möglicherweise Daten bereit.
        </div>
        <h2 className="font-semibold">Partner</h2>
        <div>
          Wir erhalten Deine personenbezogenen Daten (z. B. Deine Jobbezeichnung
          und Deine berufliche E-Mail-Adresse), wenn Du die Dienste unserer
          Kunden und Partner in Anspruch nimmst, wie Arbeitgeber, potenzielle
          Arbeitgeber und Bewerbermanagementsysteme, die uns Bewerbungsdaten
          bereitstellen.
        </div>
        <h2 className="font-semibold">
          Verbundene Unternehmen und andere Dienste
        </h2>
        <div>
          Wir erhalten Daten zu Deiner Person, wenn Du andere Dienste in
          Anspruch nimmst, die wir oder mit uns verbundene Unternehmen,
          einschließlich Microsoft, bereitstellen. Du kannst Dich beispielsweise
          dafür entscheiden, Informationen über Deine Kontakte in Microsoft Apps
          und Diensten wie Outlook an uns zu senden, um die professionellen
          Netzwerkaktivitäten auf unseren Diensten zu verbessern.
        </div>
        <h2 className="font-semibold">1.3 Nutzung der Dienste</h2>
        <div>
          Wir protokollieren Deine Besuche und Nutzung unserer Dienste,
          einschließlich mobiler Apps.
        </div>
        <div>
          Wir protokollieren Nutzungsdaten, wenn Du unsere Dienste
          einschließlich unserer Websites, App- und Plattformtechnologie
          besuchen oder anderweitig nutzen, wenn Du beispielsweise Inhalte (wie
          Lernvideos) oder Anzeigen betrachtest oder anklickst (innerhalb oder
          außerhalb unserer Websites und Apps), eine Suche ausführst, eine
          unserer Apps für Mobilgeräte installierst oder aktualisierst, Artikel
          teilst oder Dich um eine Stelle bewerbst. Wir verwenden Anmeldedaten,
          Cookies, Geräteinformationen und IP-Adressen, um Dich zu
          identifizieren und Deine Nutzung zu protokollieren.
        </div>
        <h2 className="font-semibold">1.4 Cookies und ähnliche Technologien</h2>
        <div>Wir erfassen Daten über Cookies und ähnliche Technologien.</div>
        <div>
          Wie in unserer Cookie-Richtlinie näher dargelegt, nutzen wir Cookies
          und ähnliche Technologien (z. B. Pixel und Anzeigen-Tags), um Daten zu
          sammeln (z. B. Geräte-IDs), um Dich und Dein Gerät oder Deine Geräte
          innerhalb und außerhalb von und übergreifend über verschiedene(n)
          Dienste(n) und Geräte(n) zu erkennen. Wir erlauben es auch bestimmten
          Dritten, in Übereinstimmung mit unserer Cookie-Richtlinie Cookies zu
          verwenden. Befindest Du Dich außerhalb der designierten Länder,
          erfassen wir auch Informationen zu Deinem Gerät (oder verlassen uns
          auf andere, die diese erfassen), wenn Du unsere Dienste nicht genutzt
          hast (z. B. Anzeigen-ID, IP-Adresse, Betriebssystem und Browserdaten),
          damit wir unseren Mitgliedern relevante Anzeigen zur Verfügung stellen
          und deren Wirksamkeit besser verstehen können. Mehr erfahren. Du
          kannst unsere Nutzung von Cookies und ähnlichen Technologien, die Dein
          Verhalten auf anderen Websites für Zielgruppenwerbung und andere
          Werbezwecke verfolgen, ablehnen. Besucher finden diese Steuerelemente
          hier.
        </div>
        <h2 className="font-semibold">1.5 Ihr Gerät und Standort</h2>
        <div>Wir erhalten Daten über Cookies und ähnliche Technologien.</div>
        <div>
          Wenn Du unsere Dienste (einschließlich einiger Plug-ins und unserer
          Cookies oder ähnlicher Technologien auf den Websites Dritter) besuchst
          oder verlässt, erhalten wir sowohl die URL der Website, von der Du
          kommst, als auch der Website, zu der Du als Nächstes navigierst, sowie
          die Uhrzeit Deines Besuchs. Wir erhalten auch Informationen über Dein
          Netzwerk und Dein Gerät (z. B. Deine IP-Adresse, Deinen Proxy-Server,
          Dein Betriebssystem, Deinen Webbrowser und Deine Add-ons, Deine
          Gerätekennung und -funktionen, Deine Cookie-IDs und/oder Deinen
          Internetanbieter bzw. Deinen Mobilfunkbetreiber). Greifst Du auf
          unsere Dienste von einem Mobilgerät zu, sendet uns dieses Gerät auf
          Grundlage Deiner Telefoneinstellungen Daten über Deinen Standort. Wir
          fragen Dich nach Deiner Zustimmung, bevor wir GPS oder andere Tools
          verwenden, um Deinen genauen Standort zu ermitteln.
        </div>
        <h2 className="font-semibold">1.6 Nachrichten</h2>
        <div>
          Wir werden informiert, wenn Du über unsere Dienste kommunizierst.
        </div>
        <div>
          Wir erfassen Informationen über Dich, wenn Du in Verbindung mit
          unseren Diensten Nachrichten sendest, erhältst oder mit Nachrichten
          interagierst. Erhältst Du beispielsweise eine Kontaktanfrage auf
          TRADEFOOX, verfolgen wir, ob Du darauf reagiert hast und senden Dir
          Erinnerungen. Mithilfe automatischer Scan-Technologie für Nachrichten
          unterstützen und schützen wir unsere Website. Beispielsweise setzen
          wir diese Technologie ein, um Dir mögliche Antworten auf Nachrichten
          vorzuschlagen und um Inhalte zu verwalten oder Inhalte auf unseren
          Diensten zu blockieren, die gegen unsere{" "}
          <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
            Nutzervereinbarung
          </NavLink>{" "}
          oder die{" "}
          <NavLink to="/community" className="text-darkcyan">
            Community-Richtlinien
          </NavLink>{" "}
          verstoßen.
        </div>
        <h2 className="font-semibold">
          1.7 Vom Arbeitsplatz oder von der Ausbildungsstätte bereitgestellte
          Informationen
        </h2>
        <div>
          Wenn Deine Organisation (z. B. Dein Arbeitgeber oder Deine Hoch- oder
          Berufsschule) für Dich einen Premium-Dienst kauft, übermittelt sie uns
          Daten zu Deiner Person.
        </div>
        <div>
          Wenn andere, wie Dein Arbeitgeber oder Deine Ausbildungsstätte, unsere
          Dienste für Deine Verwendung kaufen, übermitteln sie uns
          personenbezogene Daten über Dich und die Berechtigung zur Nutzung der
          Dienste durch ihre Angestellten, Studierenden oder Absolventen. Wir
          erhalten beispielsweise Kontaktdaten für die Admins von
          „Unternehmensseiten“ und wenn Personen die Berechtigung erhalten,
          unsere Premium-Dienste zu nutzen, wie z. B. unsere Recruiting-,
          Vertriebs- oder Lernprodukte.
        </div>
        <h2 className="font-semibold">1.8 Websites und Dienste Dritter</h2>
        <div>
          Wir erhalten Daten, wenn Du Websites besuchst, die unsere Anzeigen,
          Cookies oder einige unserer Plug-ins enthalten oder wenn Du sich mit
          Deinem TRADEFOOX Konto bei den Diensten Dritter einloggst.
        </div>
        <div>
          Wir erhalten Informationen über Deine Besuche bei und Interaktionen
          mit von Dritten bereitgestellten Diensten, wenn Du sich über TRADEFOOX
          einloggst oder die Dienste Dritter besuchst, die einige unserer
          Plug-ins (wie beispielsweise „Mit TRADEFOOX bewerben“) oder unsere
          Anzeigen, Cookies oder ähnliche Technologien enthalten.
        </div>
        <h2 className="font-semibold">1.Sonstiges</h2>
        <div>
          Wir optimieren unsere Dienste, d. h. wir erhalten neue Daten und
          schaffen neue Wege zur Nutzung von Daten.
        </div>
        <div>
          Unsere Dienste sind dynamisch und wir führen oft neue Funktionen ein.
          Daher kann es vorkommen, dass neue Informationen erfasst werden
          müssen. Wenn wir andere wesentliche personenbezogene Daten erfassen
          oder wesentliche Änderungen daran vornehmen, wie wir Deine Daten
          erfassen, verwenden oder teilen, benachrichtigen wir Dich und ändern
          unter Umständen auch diese Datenschutzrichtlinie.
        </div>
        <div>Zentrale Begriffe</div>
        <h2 className="font-semibold">3. 2. Wie wir Deine Daten verwenden</h2>
        <div>
          Wir verwenden Deine Daten zur Bereitstellung, Unterstützung,
          Personalisierung und Entwicklung unserer Dienste.
        </div>
        <div>
          Wie wir Deine personenbezogenen Daten verwenden, hängt davon ab,
          welche Dienste Du nutzt, wie Du diese Dienste nutzt und welche
          Einstellungen Du gewählt hast. Wir verwenden Deine personenbezogenen
          Daten, um Dir, u. a. mithilfe automatisierter Systeme und
          Schlussfolgerungen unsererseits, unsere Dienste (einschließlich
          Werbeanzeigen) zur Verfügung zu stellen und diese so anzupassen, dass
          sie für Dich und andere relevanter und nützlicher sind.
        </div>
        <h2 className="font-semibold">2.1 Serviceleistungen</h2>
        <div>
          Mit unseren Diensten kannst Du Dich mit anderen Personen vernetzen, um
          Karriere- und Geschäftschancen zu finden bzw. für berufliche
          Möglichkeiten gefunden zu werden. Unsere Dienste unterstützen Dich
          dabei, stets informiert zu sein, sich weiterzubilden und produktiver
          zu werden.
        </div>
        <div>
          Wir verwenden Deine Daten, um Dich zum Zugriff auf unsere Dienste zu
          berechtigen, und respektieren Deine Einstellungen.
        </div>
        <h2 className="font-semibold">In Kontakt bleiben</h2>
        <div>
          Unsere Dienste ermöglichen Dir, mit Kolleg:innen, Partnern, Kunden und
          anderen beruflichen Kontakten in Verbindung zu bleiben. Dazu kannst Du
          Dich u. a. mit Fach- und Führungskräften Deiner Wahl „vernetzen“, die
          sich auch mit Dir „vernetzen“ möchten. Wenn Du Dich mit anderen
          Mitgliedern vernetzt, kannst Du je nach Deinen und deren Einstellungen
          die Kontakte der anderen Mitglieder durchsuchen, um sich mit ihnen in
          Bezug auf berufliche Möglichkeiten auszutauschen.
        </div>
        <div>
          Wir verwenden Daten zu Deiner Person (wie Dein Profil, Profile, die Du
          Dir angesehen hast, oder Daten, die über Adressbuch-Uploads oder
          Partnerintegrierung bereitgestellt wurden), um es anderen zu
          ermöglichen, Dein Profil zu finden, Dir und anderen Mitgliedern
          (beispielsweise Mitgliedern, mit denen Du Kontakte oder Joberfahrungen
          gemein haben), Kontakte vorzuschlagen und es Dir zu ermöglichen,
          andere Personen einzuladen, Mitglied zu werden und sich mit Dir zu
          vernetzen. Du kannst außerdem zustimmen, dass wir Deinen genauen
          Standort oder die Tatsache, dass Du Dich in der Nähe zu anderen
          Personen befindest, für bestimmte Funktionen verwenden dürfen (z. B.
          um Dir andere Mitglieder in Deiner Nähe vorzuschlagen, mit denen Du
          Dich vernetzen kannst, oder um den Anfahrtsweg zu einem neuen Job zu
          berechnen oder Deinen Kontakten mitzuteilen, dass Du Dich auf einer
          beruflichen Veranstaltung befindest).
        </div>
        <div>
          Es bleibt Dir überlassen, ob Du eine andere Person zur Mitgliedschaft
          bei unseren Diensten einlädst, eine Kontaktanfrage sendest oder es
          einem anderen Mitglied gestatten möchtest, sich mit Dir zu vernetzen.
          Wenn Du eine Person einlädst, sich mit Dir zu vernetzen, enthält Deine
          Einladung Deine Netzwerkdaten und grundlegende Profildaten (z. B.
          Deinen Namen, Dein Profilfoto, Deine Jobbezeichnung, Deine Region).
          Wir senden Einladungserinnerungen an die von Dir eingeladene Person.
          Du kannst entscheiden, ob Du Deine eigene Kontaktliste mit Deinen
          Kontakten teilen möchten oder nicht.
        </div>
        <div>Besucher können wählen, wie wir Daten verwenden.</div>
        <h2 className="font-semibold">Informiert bleiben</h2>
        <div>
          Unsere Dienste ermöglichen es Dir, über alle Neuigkeiten, Ereignisse
          und Ideen hinsichtlich beruflicher Themenbereiche, die Dir wichtig
          sind und die von Fachleuten stammen, die Du respektierst, auf dem
          Laufenden zu bleiben. Unsere Dienste ermöglichen es auch, Dein
          berufliches Wissen zu erweitern und neue Kenntnisse zu erwerben. Wir
          verwenden die Daten, die uns über Dich vorliegen (z. B. von Dir
          bereitgestellte Daten, Daten, die wir durch Deine Interaktion mit
          unseren Diensten erfassen, sowie Schlussfolgerungen, die wir aus den
          Daten über Dich ziehen), um unsere Dienste für Dich zu
          personalisieren, indem wir beispielsweise geeignete Inhalte und
          Diskussionen auf unseren Diensten empfehlen oder nach Relevanz
          sortieren. Darüber hinaus verwenden wir Deine personenbezogenen Daten
          zum Empfehlen von Kenntnissen, die Du Deinem Profil hinzufügen kannst
          oder unter Umständen für Deine nächsten beruflichen Möglichkeiten
          ausbauen solltest. Wenn Du uns daher mitteilst, dass Du Dich für eine
          neue Kompetenz interessierst (indem Du Dir beispielsweise ein
          Lernvideo ansiehst), verwenden wir diese Informationen, um Inhalte in
          Deinem Feed zu personalisieren, vorzuschlagen, dass Du bestimmten
          Mitgliedern auf unserer Website folgst oder Dir Inhalte ansehen, die
          Dir helfen, diese neue Kompetenz zu erwerben. Wir verwenden Deine
          Inhalte, Aktivitäten und andere Daten, einschließlich Deines Namens
          und Deines Fotos, um Mitteilungen an Dein Netzwerk und andere Personen
          zu senden. Je nach Deinen Einstellungen informieren wir möglicherweise
          andere Personen darüber, dass Du Dein Profil aktualisiert, Inhalte
          veröffentlicht, eine soziale Aktion ausgeführt, eine Funktion
          verwendet oder einen neuen Kontakt gewonnen hast bzw. dass Du in den
          News erwähnt wurden.
        </div>
        <h2 className="font-semibold">Karriereseite</h2>
        <div>
          Unsere Dienste ermöglichen es, Berufswege und Ausbildungsmöglichkeiten
          zu erforschen, für Karrierechancen gefunden zu werden und
          Karrierechancen zu entdecken. Personen, die nach neuen
          Mitarbeiterinnen und Mitarbeitern (für einen Job oder eine spezielle
          Aufgabe) suchen und auch Personen, die Du einstellst, können Dein
          Profil finden. Wir verwenden Deine Daten, um Dir Jobs oder Mentees zu
          empfehlen und um Dir und anderen relevante berufliche Kontakte zu
          zeigen (z. B. wer bei einem Unternehmen, in einer Branche, in einem
          Tätigkeitsbereich oder an einem Standort arbeitet oder über bestimmte
          Kenntnisse oder Kontakte verfügt). Sie können anzeigen, dass Du daran
          interessiert bist, Deinen Job zu wechseln und mit Recruitern
          Informationen auszutauschen. Wir verwenden Deine Daten, um Dir Jobs zu
          empfehlen und Dir Recruitern vorzustellen. Wir verwenden
          möglicherweise automatisierte Systeme, um Inhalte bereitzustellen und
          Empfehlungen zu geben, damit unsere Dienste für unsere Mitglieder,
          Besucher und Kunden noch relevanter werden. Wenn Du Dein Profil auf
          dem aktuellen Stand hältst, kannst Du Dich besser mit anderen
          vernetzen und über unsere Dienste Jobangebote finden.
        </div>
        <h2 className="font-semibold">Produktivität</h2>
        <div>
          Unsere Dienste ermöglichen es Dir, mit Kolleg:innen
          zusammenzuarbeiten, nach potenziellen Klienten, Kunden, Partnern und
          anderen Personen zu suchen und mit ihnen privat und/oder geschäftlich
          in Verbindung zu treten. Unsere Dienste ermöglichen es Dir, mit
          anderen Mitgliedern zu kommunizieren und Meetings mit ihnen zu planen
          und vorzubereiten. Je nach Deinen Einstellungen können wir Nachrichten
          scannen, um „Bots“ oder ähnliche Tools bereitzustellen, die Aufgaben
          erleichtern, wie etwa die Planung von Meetings, den Entwurf von
          Antworten, die Zusammenfassung von Nachrichten oder die Empfehlung
          nächster Schritte. Mehr erfahren.
        </div>
        <h2 className="font-semibold">2.2 Premium-Dienste</h2>
        <div>
          Mit unseren Premium-Diensten können zahlende Nutzer nach Mitgliedern
          suchen und mit ihnen Kontakt aufnehmen. Beispiel: Suche nach und
          Kontaktaufnahme mit Kandidaten für einen Job, Suche nach Neukunden und
          Mitarbeitern, Talent-Management und Sponsern von Inhalten über soziale
          Netzwerke.
        </div>
        <div>
          Wir bieten Premium-Dienste an, die unseren Kunden und Mitgliedern
          persönlich angepasste Suchfunktionen und Tools (einschließlich
          Nachrichten- und Aktivitätsmitteilungen) bereitstellen, die Teil
          unserer Talent Solutions, Marketing Solutions und Sales Solutions
          sind. Kunden können in begrenztem Umfang Informationen aus Deinem
          Profil exportieren, wie den Namen, den Profil-Slogan, das aktuelle
          Unternehmen, die aktuelle Jobbezeichnung und den allgemeinen Standort
          (z. B. Dublin), um beispielsweise Vertriebskontakte oder Kandidaten zu
          verwalten, es sei denn, Du lehnst dies ab. Im Rahmen dieser
          Premium-Dienste geben wir keine Kontaktdaten ohne Deine Einwilligung
          aus. Ein Premium-Mitglied kann Informationen speichern, die ihm in
          unseren Premium-Diensten zu Deiner Person zur Verfügung stehen, wie
          Lebenslauf, Kontaktdaten oder Verkaufshistorie. Die Daten, die diese
          Kunden zu Deiner Person gespeichert haben, unterliegen den Richtlinien
          der jeweiligen Kunden.
        </div>
        <h2 className="font-semibold">2.3 Kommunikationen</h2>
        <div>
          Wir kontaktieren Dich und ermöglichen Kommunikation unter Mitgliedern.
          Wir bieten Einstellungen an, mit denen Du steuern kannst, welche
          Nachrichten und wie oft Du bestimmte Nachrichtenarten erhalten
          möchten.
        </div>
        <div>
          Wir kontaktieren Dich per E-Mail, Telefon, durch auf den TRADEFOOX
          Websites oder Apps veröffentlichte Mitteilungen, durch Mitteilungen an
          Dein TRADEFOOX Postfach oder durch andere, im Rahmen der Dienste
          verfügbare Mittel, einschließlich Textnachrichten (SMS) und
          Push-Benachrichtigungen. Wir senden Dir Nachrichten über die
          Verfügbarkeit und Sicherheit unserer Dienste sowie über andere
          dienstbezogene Themen. Wir senden auch Nachrichten in Bezug auf die
          Verwendung unserer Dienste, Netzwerk-Updates, Erinnerungen,
          Job-Vorschläge und Werbenachrichten von uns und unseren
          Partnerunternehmen. Du kannst Deine Kommunikationseinstellungen
          jederzeit ändern. Bitte beachte, dass Du den Empfang von Nachrichten
          zu unseren Diensten nicht ablehnen kannst. Dazu gehören Sicherheits-
          und Rechtshinweise.
        </div>
        <div>
          Wir ermöglichen auch die Kommunikation zwischen Dir und anderen
          Personen durch unsere Dienste, darunter Einladungen, InMail, Gruppen
          und Nachrichten unter Kontakten.
        </div>
        <h2 className="font-semibold">2.4 Werbung</h2>
        <div>
          Wir zeigen Dir für Dich angepasste Anzeigen sowohl innerhalb als auch
          außerhalb unserer Dienste. Du kannst zukünftig wählen, dass Dir
          personalisierte Anzeigen gezeigt werden, aber andere Anzeigen nicht
          ablehnen.
        </div>
        <div>
          Wir richten Anzeigen (und messen deren Leistung) sowohl innerhalb als
          auch außerhalb unserer Dienste gezielt an Mitglieder, Besucher und
          andere Personen, direkt oder über verschiedene Partner. Dazu nutzen
          wir folgende Daten, entweder kombiniert oder separat:
        </div>
        <ul className="list-inside list-disc">
          <li>
            Daten von Werbetechnologien sowohl innerhalb als auch außerhalb
            unserer Dienste, Pixel, Tags, Cookies und Gerätekennungen;
          </li>
          <li>
            Von Mitgliedern bereitgestellte Informationen (beispielsweise
            Kontaktdaten, Jobbezeichnung und Branche);
          </li>
          <li>
            Daten aus Deiner Nutzung unserer Dienste (beispielsweise
            Suchverlauf, Feed, Inhalte, die Du liest, wem Du folgst oder wer Dir
            folgt, Kontakte, Teilnahme an Gruppen, Seitenbesuche, Videos, die Du
            Dir ansiehst, Klicks auf eine Anzeige usw.), einschließlich wie in
            Abschnitt 1.3 beschrieben;
          </li>
          <li>
            Informationen von Werbepartnern, Anbietern und Herausgebern ; und
          </li>
          <li>
            Informationen, die aus den oben aufgeführten Daten abgeleitet werden
            können (z. B. Nutzung der Jobbezeichnungen eines Profils zur
            Schlussfolgerung auf eine Branche, Karrierestufe und
            Vergütungsklasse; Nutzung von Abschlussdaten zur Schlussfolgerung
            auf das Alter oder Nutzung der Vornamen oder Pronomennutzung zur
            Schlussfolgerung auf das Geschlecht; Nutzung Deiner Feed-Aktivitäten
            zur Schlussfolgerung auf Deine Interessen oder Nutzung von
            Gerätedaten, um Dich als Mitglied zu erkennen).
          </li>
        </ul>
        <div>
          Wir zeigen Dir Anzeigen mit der Bezeichnung Sponsored Content, die wie
          ähnliche, nicht gesponserte Inhalte aussehen, mit der Ausnahme, dass
          sie als Werbung gekennzeichnet sind (z. B. als „Anzeige“ oder
          „gesponsert“). Wenn Du bei diesen Anzeigen eine soziale Aktion
          ausführst (wie „Gefällt mir“-Markierungen, Kommentare oder Teilen),
          wird Deine Aktion mit Deinem Namen verbunden und kann von Dritten
          gesehen werden. Dazu gehört auch der Anbieter der Werbung. Wenn Du auf
          TRADEFOOX Diensten eine soziale Aktion ausführst (je nach Deinen
          Einstellungen), kann diese Aktion im Rahmen verbundener Werbeanzeigen
          genannt werden. Wenn Du z. B. ein Unternehmen mit „Gefällt mir“
          markierst, werden Dein Name und Foto u. U. angegeben, wenn dessen
          gesponserte Inhalte angezeigt werden.
        </div>
        <h2 className="font-semibold">Anzeigenauswahl</h2>
        <div>
          Wir halten uns an die Selbstregulierungsprinzipien für
          interessenbezogene Werbung und machen von der Option für die Branche
          Gebrauch, diese Art von Anzeigen abzulehnen. Damit lehnst Du nicht den
          Erhalt von Werbung ab. Du erhältst weiterhin andere Anzeigen von
          Werbetreibenden, die nicht an diese selbstregulierenden Tools gebunden
          sind. Du kannst auch spezifisch die Verwendung bestimmter Kategorien
          von Daten durch uns ablehnen, durch die wir Ihnen relevantere Werbung
          zeigen können. Die Einstellung zum Ablehnen für Besucher findest Du
          hier.
        </div>
        <h2 className="font-semibold">Informationen an Anzeigenanbieter</h2>
        <div>
          Wir übermitteln Deine personenbezogenen Daten nicht an dritte
          Werbetreibende oder Anzeigennetzwerke, mit folgenden Ausnahmen: (i)
          gehashte IDs oder Gerätekennungen (soweit diese in einigen Ländern als
          personenbezogene Daten betrachtet werden); (ii) mit Deiner separaten
          Genehmigung (z. B. über ein Formular zur Lead-Generierung) oder (iii)
          Daten, die bereits jedem Nutzer oder jeder Nutzerin der Dienste
          zugänglich sind (z. B. Profil). Wenn Du jedoch eine Anzeige innerhalb
          oder außerhalb unserer Dienste ansehen oder diese anklicken, wird der
          Anzeigenbetreiber benachrichtigt, dass jemand die Seite besucht hat,
          auf der die Anzeige präsentiert wurde, und er kann über Mechanismen
          wie z. B. Cookies auf Deine Person schließen. Anzeigenpartner können
          personenbezogene Daten, die von dem Werbetreibenden direkt von Ihnen
          erfasst wurden, mit von uns erhaltenen gehashten IDs oder
          Gerätekennungen in Verbindung bringen. In solchen Fällen versuchen
          wir, diese Werbepartner vertraglich dazu zu verpflichten, zuvor Deine
          ausdrückliche Zustimmung einzuholen.
        </div>
        <h2 className="font-semibold">2.5 Marketing</h2>
        <div>Wir empfehlen Dir und anderen Personen unsere Dienste.</div>
        <div>
          Wir nutzen Mitgliederdaten und -inhalte nicht nur zur Werbung für
          unsere Dienste, sondern auch für Einladungen und Mitteilungen, um den
          Ausbau unserer Mitgliederzahlen und unseres Netzwerks, die Interaktion
          damit und unsere Dienste zu fördern, beispielsweise indem wir Deinen
          Kontakten zeigen, dass Sie eine Funktion unserer Dienste verwendet
          haben.
        </div>
        <h2 className="font-semibold">
          2.6 Entwicklung unserer Dienste und Forschung
        </h2>
        <div>Wir entwickeln unsere Dienste und betreiben Forschung.</div>
        <h2 className="font-semibold">Entwicklung unserer Dienste</h2>
        <div>
          Wir verwenden Daten und Feedback unserer Mitglieder, um Forschung und
          Entwicklung für unsere Dienste zu betreiben und um Dir und anderen
          Mitgliedern ein besseres, intuitiveres und personalisierteres Erlebnis
          zu bieten, den Ausbau unserer Mitgliederzahlen und die Interaktion mit
          unseren Diensten zu fördern, Fach- und Führungskräfte miteinander zu
          vernetzen und ihnen den Weg zu beruflichen und wirtschaftlichen
          Chancen zu öffnen.
        </div>
        <h2 className="font-semibold">Sonstige Forschung</h2>
        <div>
          Wir streben danach, Mitgliedern des globalen Arbeitsmarkts berufliche
          und wirtschaftliche Chancen zu eröffnen und ihnen zu gesteigerter
          Produktivität und zu größerem Erfolg zu verhelfen. Wir verwenden die
          uns zur Verfügung stehenden personenbezogenen Daten, um soziale und
          wirtschaftliche Trends sowie Entwicklungen auf dem Arbeitsmarkt zu
          erforschen, wie beispielsweise die Verfügbarkeit von Jobs und
          Kenntnissen, die für diese Positionen erforderlich sind, sowie
          Strategien, die dabei helfen, Lücken in verschiedenen Branchen und
          geografischen Regionen zu schließen. Gelegentlich arbeiten wir im
          Rahmen dieser Forschung mit vertrauenswürdigen Dritten zusammen und
          haben Kontrollen implementiert, die zum Schutz Deiner Daten vorgesehen
          sind. Wir veröffentlichen wirtschaftliche Einblicke oder erlauben
          Dritten die Veröffentlichung solcher Informationen. Diese werden nicht
          als persönliche, sondern als zusammengefasste Daten präsentiert.
        </div>
        <h2 className="font-semibold">Umfragen</h2>
        <div>
          Meinungsumfragen werden über unsere Dienste von uns sowie Dritten
          durchgeführt. Du bist nicht verpflichtet, auf Umfragen zu antworten
          und kannst wählen, welche Informationen Du mitteilen möchtest. Du
          kannst es ablehnen, Einladungen zu Umfragen zu erhalten.
        </div>
        <h2 className="font-semibold">2.7 Kundensupport</h2>
        <div>
          Wir verwenden Daten, um Dich zu unterstützen und Probleme zu beheben.
        </div>
        <div>
          Wir verwenden Daten (möglicherweise auch Nachrichten von Dir), um
          Beschwerden und Problemen mit unseren Diensten nachzugehen, sie
          anzusprechen und zu beheben (Bugs usw.).
        </div>
        <h2 className="font-semibold">
          2.8 Einblicke, die keine Rückschlüsse auf Deine Person zulassen
        </h2>
        <div>
          Wir verwenden Daten, um Einblicke zu generieren, die keine
          Rückschlüsse auf Deine Person zulassen.
        </div>
        <div>
          Wir verwenden Deine Daten, um Einblicke zu gewinnen und zu teilen.
          Diese Informationen lassen jedoch keine Rückschlüsse auf Deine Person
          zu. Wir verwenden Deine Daten möglicherweise, um Statistiken über
          unsere Mitglieder, deren Beruf oder Branche, die Anzahl von
          bereitgestellten oder angeklickten Ad Impressions oder die
          demografische Verteilung der Besucher eines Dienstes bzw.
          demografische Belegschaftseinblicke zu erstellen.
        </div>
        <h2 className="font-semibold">2.9 Sicherheit und Untersuchungen</h2>
        <div>
          Wir verwenden Daten zur Sicherheit, Betrugsprävention und
          -untersuchungen.
        </div>
        <div>
          Wir nutzen Deine Daten (einschließlich Deiner Kommunikation) zu
          Sicherheitszwecken oder zur Untersuchung eines möglichen Betrugs oder
          anderer Verstöße gegen unsere Nutzervereinbarung und/oder zur
          Untersuchung eines Versuchs, unseren Mitgliedern, Besucher:innen oder
          weiteren Personen Schaden zuzufügen.
        </div>
        <div>Zentrale Begriffe</div>
        <h2 className="font-semibold">4. 3. Teilen von Informationen</h2>
        <h2 className="font-semibold">3.1 Unsere Serviceleistungen</h2>
        <div>
          Alle in Deinem Profil enthaltenen Informationen und sämtliche Inhalte,
          die Du veröffentlichst, oder soziale Aktionen (wie „Gefällt mir“,
          Kommentare und Teilen), die Du auf unseren Diensten ausführst, werden
          in Übereinstimmung mit Deinen Einstellungen von Dritten gesehen.
        </div>
        <h2 className="font-semibold">Profil</h2>
        <div>
          Dein Profil ist für alle Mitglieder und Kunden unserer Dienste
          vollständig sichtbar. Abhängig von Deinen Einstellungen kann es auch
          für Dritte innerhalb oder außerhalb unserer Dienste sichtbar sein (z.
          B. für Besucher unserer Dienste oder Nutzer von
          Drittanbietersuchmaschinen). Wie in unserem Hilfebereich dargelegt,
          wirken sich Deine Einstellungen, der Kontaktgrad zu dem Mitglied, das
          sich Dein Profil ansieht, dessen Mitgliedschaft und Nutzung unserer
          Dienste, der Zugangsweg und die Arten der Suche (beispielsweise nach
          Namen oder Stichwort) auf die Verfügbarkeit Deines Profils und gewisse
          Bereiche in Deinem aus.
        </div>
        <div>
          Beiträge, „Gefällt mir“-Markierungen, Folgen, Kommentare, Nachrichten
        </div>
        <div>
          Unsere Dienste ermöglichen es, Informationen zu lesen und zu teilen.
          Dazu gehören Beiträge, „Gefällt mir“-Markierungen und Kommentare und
          die Funktion „Folgen“.
        </div>
        <ul className="list-inside list-disc">
          <li>
            Wenn Du einen Artikel oder einen Beitrag öffentlich teilst (z. B.
            ein Update, Bild, Video oder einen Artikel), kann dies von jedem
            eingesehen und auf jeder Plattform geteilt werden (abhängig von
            Deinen Einstellungen). Mitglieder, Besucher und Dritte werden Deine
            öffentlich geteilten Inhalte einschließlich Deines Namens (und IDein
            Foto, sofern Du eins bereitgestellt hast) finden und einsehen
            können.
          </li>
          <li>
            In einer Gruppe sind Beiträge für andere Mitglieder der Gruppe
            sichtbar. Deine Mitgliedschaft in Gruppen ist öffentlich und Teil
            Deines Profils, jedoch kannst Du die Sichtbarkeit in Deinen
            Einstellungen ändern.
          </li>
          <li>
            Alle Informationen, die Du über die Seiten von Unternehmen oder
            anderen Organisationen auf unseren Diensten teilst, können von
            diesen und anderen Personen gesehen werden, die diese Seiten
            besuchen.
          </li>
          <li>
            Wenn Du einer Person oder Organisation folgst, bist Du für andere
            Personen und den „Eigentümer“ der Seite als Follower sichtbar.
          </li>
          <li>
            Abhängig von Deinen Einstellungen benachrichtigen wir gegebenenfalls
            den Absender einer Nachricht, wenn Du auf seine Nachricht reagieren.
          </li>
          <li>
            Entsprechend Deiner Einstellungen benachrichtigen wir gegebenenfalls
            ein Mitglied, wenn Du Dir dessen Profil ansiehst.
          </li>
          <li>
            Wenn Du Inhalte von anderen (auch Anzeigen) mit „Gefällt mir“
            markierst, teilst oder kommentierst, können andere diese „sozialen
            Aktionen“ sehen und Dir (d. h. Deinem Namen, Deinem Profil und
            Deinem Foto, falls vorhanden) zuordnen.
          </li>
        </ul>
        <div>
          Dein Arbeitgeber kann sehen, wie Du Dienste und damit verbundene
          Informationen verwendest, die für Deine Tätigkeit (beispielsweise als
          Recruiter oder Vertriebsmitarbeiter) bereitgestellt wurden. Wir zeigen
          Deinem Arbeitgeber weder Deine Jobsuchen noch Deine persönlichen
          Nachrichten.
        </div>
        <h2 className="font-semibold">Unternehmenskonten</h2>
        <div>
          Dein Arbeitgeber bietet Dir möglicherweise Zugriff auf unsere
          Unternehmensdienste wie Bewerbung, Verkauf, TRADEFOOX Business oder
          unseren Kampagnen-Manager für Marketing. Er kann Deine Verwendung
          dieser Unternehmensdienste überprüfen und verwalten.
        </div>
        <div>
          Abhängig vom Unternehmensdienst fordern wir vor Deiner Nutzung des
          betreffenden Diensts die Genehmigung an, relevante Daten aus Deinem
          Profil oder Deiner Nutzung unserer Dienste für Privatmitglieder mit
          Deinem Arbeitgeber teilen zu dürfen. Ein Nutzer von Sales Navigator
          wird beispielsweise gebeten, seinen „Social Selling Index“
          mitzuteilen, eine Punktzahl, die zum Teil auf seinen Aktivitäten
          innerhalb seines persönlichen Kontos beruht. Wir verstehen, dass
          bestimmte Aktivitäten, wie die Jobsuche und persönliche Nachrichten,
          vertraulich sind. Daher teilen wir solche Aktivitäten nicht mit Deinem
          Arbeitgeber, es sei denn, Du hast Dich entschieden, sie über unsere
          Dienste mit Deinem Arbeitgeber zu teilen (indem Du Dich beispielsweise
          um eine neue Position bei demselben Unternehmen bewerbst oder indem Du
          in einer Nachricht an einen Kollegen über unsere Dienste erwähnst,
          dass Du auf Jobsuche bist).
        </div>
        <div>
          Wenn Du Arbeitsplatz-Tools und -dienste verwenden (z. B. interaktive
          Mitarbeiterverzeichnis-Tools), werden abhängig von Deinen
          Einstellungen bestimmte Deiner Daten möglicherweise auch Deinem
          Arbeitgeber zur Verfügung gestellt oder mit Informationen verknüpft,
          die wir von Deinem Arbeitgeber erhalten, um diese Tools und Dienste zu
          ermöglichen.
        </div>
        <h2 className="font-semibold">3.2 Kommunikationsarchivierung</h2>
        <div>
          Mitglieder, die in einem regulierten Umfeld tätig sind, müssen
          Mitteilungen möglicherweise außerhalb unserer Dienste speichern.
        </div>
        <div>
          Manche Mitglieder (oder deren Arbeitgeber) müssen ihre Mitteilungen
          und Aktivitäten auf sozialen Netzwerken aus Gründen der Einhaltung
          rechtlicher oder beruflicher Vorgaben möglicherweise archivieren und
          nutzen dafür die Dienste Dritter, die ihnen diese Archivierungsdienste
          bereitstellen. Wir ermöglichen die Archivierung von Mitteilungen durch
          und für solche Mitglieder außerhalb unserer Dienste. Ein Beispiel
          dafür wäre ein Finanzberater, der Kommunikationen mit seinem Klienten
          im Rahmen seiner Zulassung als Finanzberater über unsere Dienste
          archivieren muss.
        </div>
        <h2 className="font-semibold">3.3 Serviceleistungen von Dritten</h2>
        <div>
          Du kannst Dein Konto mit den Diensten Dritter verknüpfen, sodass diese
          die Profile Deiner Kontakte aufrufen, von Dir geteilte Beiträge auf
          ihren Plattformen posten oder es Dir ermöglichen können, mit Deinen
          Kontakten auf ihren Plattformen zu kommunizieren. Außerdem erscheinen
          Auszüge Deines Profils auf den Diensten Dritter.
        </div>
        <div>
          Abhängig von Einstellungen können andere Dienste das Profil aufrufen.
          Wenn Du Dich entscheidest, Dein Konto mit einem anderen Dienst zu
          verknüpfen, hat dieser Zugang zu Deinen persönlichen Daten. Wenn Du
          die Konten verknüpfst, findest Du eine Beschreibung, wie Deine
          personenbezogenen Daten geteilt und verwendet werden, auf einem
          Einwilligungsbildschirm oder über einen Link, wo Du Deine Einwilligung
          geben kannst. Dienste Dritter unterliegen ihren eigenen
          Datenschutzrichtlinien. Du kannst dem Betreiber die Nutzung Deiner
          Daten in einer Art und Weise einräumen, die von unseren
          Gepflogenheiten abweicht. Du kannst die Verknüpfung mit solchen Konten
          aufheben.
        </div>
        <div>
          Abhängig von den Einstellungen erscheinen Auszüge aus Deinem Profil
          auf den Diensten Dritter (beispielsweise Suchmaschinen-Ergebnisse,
          E-Mail- und Kalender-Apps, die eingeschränkte Profildaten der Person
          anzeigen, der eine Nachricht oder SMS gesendet wird, soziale
          Medien-Aggregatoren, Talent- und Lead-Manager). „Alte“
          Profilinformationen bleiben weiterhin auf diesen Diensten bestehen,
          bis diese ihren Daten-Cachespeicher mit den von Dir vorgenommenen
          Profiländerungen aktualisieren.
        </div>
        <h2 className="font-semibold">3.4 Ähnliche Dienste</h2>
        <div>
          Wir teilen Ihre Daten über unsere verschiedenen Dienste und mit
          TRADEFOOX verbundenen Unternehmen.
        </div>
        <div>
          Wir teilen Deine personenbezogenen Daten mit den mit uns verbundenen
          Unternehmen, um unsere Dienste bereitzustellen und weiterzuentwickeln.
          Wir führen unter Umständen Daten intern über verschiedene in dieser
          Datenschutzrichtlinie erfasste Dienste zusammen, um unsere Dienste für
          Dich und andere relevanter und nützlicher zu gestalten. Wir können
          beispielsweise Deinen Feed oder Deine Jobempfehlungen basierend auf
          Deinen Lernverlauf personalisieren.
        </div>
        <h2 className="font-semibold">3.5 Serviceanbieter</h2>
        <div>
          Möglicherweise nutzen wir die Dienste Dritter zur Unterstützung
          unserer Dienste.
        </div>
        <div>
          Wir nutzen die Dienste Dritter, um uns bei der Bereitstellung unserer
          Dienste zu unterstützen (beispielsweise Wartung, Analyse, Prüfung,
          Zahlung, Betrugserkennung, Marketing und Entwicklung). Diese Dritten
          haben in dem Ausmaß Zugang zu Deinen Informationen wie angemessen
          erforderlich, um die betreffenden Aufgaben für uns zu erledigen, und
          sind verpflichtet, Deine Informationen nicht offenzulegen oder für
          andere Zwecke zu nutzen.
        </div>
        <h2 className="font-semibold">3.6 Gesetzliche Offenlegung</h2>
        <div>
          Möglicherweise ist es erforderlich, Daten zu teilen, wenn dies unserer
          Ansicht nach gesetzlich nötig ist, um Deine oder unsere Rechte und
          Sicherheit oder die Dritter zu schützen.
        </div>
        <div>
          Unter Umständen müssen wir Deine Informationen offenlegen, wenn dies
          gesetzlich, aufgrund einer Vorladung oder aus anderen rechtlichen
          Gründen erforderlich ist, oder wenn wir in gutem Glauben davon
          ausgehen können, dass die Offenlegung angemessenerweise erforderlich
          ist, um (1) mutmaßliche oder tatsächliche illegale Vorgänge zu
          untersuchen, zu verhindern oder entsprechende Maßnahmen zu ergreifen
          oder um staatliche Vollzugsbehörden zu unterstützen, um (2) unsere
          Vereinbarungen mit Dir durchzusetzen, (3) den Forderungen und
          Beschuldigungen Dritter nachzugehen und uns dagegen zu verteidigen,
          (4) die Sicherheit und Integrität unserer Dienste zu schützen (indem
          wir Informationen beispielsweise mit Unternehmen teilen, die auf
          ähnliche Weise bedroht sind), oder um (5) Rechte geltend zu machen und
          die Rechte und Sicherheit von TRADEFOOX, unseren Mitgliedern,
          Mitarbeiterinnen und Mitarbeitern und Dritten zu schützen. Wir
          versuchen, Mitglieder über rechtliche Anforderungen ihrer
          personenbezogenen Daten zu informieren, sofern dies unseres Erachtens
          angemessen ist, es sei denn, dies ist gesetzlich oder aufgrund einer
          gerichtlichen Anordnung verboten, oder es handelt sich bei der
          Anforderung um einen Notfall. Wir können derartige Anforderungen
          anfechten, wenn wir nach unserem Ermessen der Ansicht sind, dass die
          Anforderungen zu allgemein oder vage sind oder wenn es sich um
          mangelnde Befugnis handelt, verpflichten uns jedoch nicht, alle
          Anforderungen anzufechten. Weiteres erfährts Du in unseren Richtlinien
          für die Anforderung von Daten in unserem Transparenzbericht.
        </div>
        <h2 className="font-semibold">3.7 Kontrollwechsel oder Verkauf</h2>
        <div>
          Wir teilen Deine Daten möglicherweise, wenn unser Unternehmen an
          Dritte verkauft wird. Sie müssen jedoch weiterhin in Übereinstimmung
          mit dieser Datenschutzrichtlinie verwendet werden.
        </div>
        <div>
          Wir können Deine Daten auch im Rahmen eines Verkaufs, einer Fusion
          oder eines Kontrollwechsels oder in Vorbereitung auf eines dieser
          Ereignisse teilen. Ein anderes Unternehmen, das uns oder einen Teil
          unseres Unternehmens kauft, hat das Recht, Deine Daten zu verwenden,
          jedoch nur auf die in dieser Datenschutzrichtlinie dargelegte Art und
          Weise, es sei denn, Du vereinbarst etwas anderes.
        </div>
        <h2 className="font-semibold">
          5. 4. Ihre Optionen und Verpflichtungen
        </h2>
        <h2 className="font-semibold">4.1 Aufbewahrung von Daten</h2>
        <div>
          Wir behalten die meisten Deiner personenbezogenen Daten so lange, wie
          Du ein aktives Konto auf TRADEFOOX haben.
        </div>
        <div>
          Generell bewahren wir Deine personenbezogenen Daten so lange auf, wie
          Dein Konto aktiv ist oder wie es erforderlich ist, um Dir Dienste
          bereitzustellen. Darunter fallen Daten, die Du oder andere uns zur
          Verfügung stellen sowie Daten, die durch die Nutzung unserer Dienste
          generiert oder abgeleitet wurden. Auch wenn Du unsere Dienste nur
          nutzt, wenn Du alle paar Jahre einen neuen Job suchst, bewahren wir
          Deine Informationen auf und Dein Profil bleibt aktiv, es sei denn, Du
          schließt Dein Konto. Wir behalten uns vor, in einigen Fällen bestimmte
          Informationen (z. B. Einblicke über die Nutzung der Dienste) in
          entpersonalisierter oder aggregierter Form aufzubewahren.
        </div>
        <h2 className="font-semibold">
          4.2 Recht auf Zugriff auf und Kontrolle Deiner personenbezogenen Daten
        </h2>
        <div>
          Du kannst auf Deine personenbezogenen Daten zugreifen oder sie
          löschen. Du hast viele Optionen und kannst wählen, wie Deine Daten
          erfasst, verwendet und geteilt werden.
        </div>
        <div>
          Wir bieten Dir Optionen bezüglich des Erfassens, Verwendens und
          Teilens Deiner Daten, angefangen beim Löschen oder Korrigieren der
          Daten, die Du in Deinem Profil angibst, und der Kontrolle über die
          Sichtbarkeit Deiner Beiträge, bis hin zum Ablehnen von Werbung und
          Verwalten von Nachrichten. Du hast die Möglichkeit, Einstellungen
          bezüglich der Daten vorzunehmen, die uns zu Deiner Person vorliegen.
        </div>
        <div>
          In Bezug auf Daten, die uns zu Deiner Person vorliegen, kannst Du:
        </div>
        <ul className="list-inside list-disc">
          <li>
            Daten löschen: Du kannst von uns die Löschung der gesamten
            personenbezogenen Daten oder eines Teils davon verlangen (wenn
            beispielsweise die Erbringung von Dienstleistungen für Dich nicht
            mehr erforderlich ist).
          </li>
          <li>
            Daten ändern oder berichtigen: Du kannst einige Deiner
            personenbezogenen Daten über Dein Konto bearbeiten bzw. in
            bestimmten Fällen um die Änderung, Aktualisierung oder Korrektur der
            Daten bitten, insbesondere, wenn diese fehlerhaft sind.
          </li>
          <li>
            Der Nutzung von Daten widersprechen oder sie einschränken: Du kannst
            von uns verlangen, die Nutzung von personenbezogenen Daten ganz oder
            zum Teil einzustellen (wenn wir beispielsweise keine rechtliche
            Grundlage für ihre weitere Nutzung haben), oder die Nutzung
            einzuschränken (wenn beispielsweise Deine personenbezogenen Daten
            unzutreffend sind oder rechtswidrig von uns beibehalten werden).
          </li>
          <li>
            Recht auf Zugriff und/oder Übernahme Deiner Daten: Du kannst von uns
            eine Kopie Deiner personenbezogenen Daten verlangen, und Du kannst
            eine Kopie der von Dir überlassenen personenbezogenen Daten in
            maschinenlesbarer Form verlangen.
          </li>
        </ul>
        <div>
          Besucher erfahren hier mehr darüber, wie sie solche Anfragen stellen
          können. Du kannst Dich über die unten stehenden Kontaktinformationen
          an uns wenden. Wir werden Deine Anfrage den geltenden Gesetzen
          entsprechend prüfen.
        </div>
        <div>
          Personen mit Wohnsitz in einem der designierten Länder und anderen
          Regionen haben möglicherweise weitere gesetzlich vorgeschriebene
          Rechte.
        </div>
        <h2 className="font-semibold">4.3 Kontoschließung</h2>
        <div>
          Wir behalten einige Deiner Daten auch nachdem Du Dein Konto
          geschlossen hast.
        </div>
        <div>
          Wenn Du Dein Konto auf TRADEFOOX schließt, sind Deine
          personenbezogenen Daten im Allgemeinen innerhalb von 24 Stunden für
          andere Personen nicht mehr auf unseren Diensten sichtbar. Generell
          löschen wir die Informationen in einem geschlossenen Konto innerhalb
          von 30 Tagen nach der Kontoschließung, außer wie unten angegeben.
        </div>
        <div>
          Wir bewahren personenbezogenen Daten auch nach der Schließung des
          Kontos auf, wenn dies angemessen und erforderlich ist, um unseren
          rechtlichen Pflichten (einschließlich Anforderungen durch
          Strafverfolgungsbehörden) nachzukommen, regulatorische Vorgaben
          einzuhalten, Streitsachen zu schlichten, für Sicherheit zu sorgen,
          Betrug und Missbrauch vorzubeugen (beispielsweise, wenn wir das Konto
          wegen einer Verletzung unserer Community-Richtlinie eingeschränkt
          haben), unsere Nutzervereinbarung durchzusetzen oder Deine
          Abbestellung weiterer Mitteilungen von uns zu entsprechen. Nach der
          Schließung des Kontos bewahren wir entpersonalisierte Daten weiter
          auf.
        </div>
        <div>
          Informationen, die Du mit Dritten geteilt hast (beispielsweise per
          InMail, in Updates oder Beiträgen zu Gruppen), bleiben sichtbar,
          nachdem das Konto geschlossen oder die Informationen aus Deinem
          eigenen Profil oder deinem Postfach gelöscht haben. Des Weiteren
          kontrollieren wir keine Daten, die andere Mitglieder aus unseren
          Diensten kopiert haben. Bei Gruppeninhalten und Bewertungen oder
          Inhalten zur Prüfung von geschlossenen Konten wird als Quelle
          „unbekannter Nutzer“ angezeigt. Möglicherweise wird das Profil
          weiterhin auf den Diensten Dritter angezeigt (beispielsweise in
          Suchmaschinenergebnissen), bis diese ihren Cache-Speicher aktualisiert
          haben.
        </div>
        <h2 className="font-semibold">6. 5. Weitere wichtige Informationen</h2>
        <h2 className="font-semibold">5.1. Sicherheit</h2>
        <div>
          Wir prüfen, ob gegen unsere Sicherheit verstoßen wird und versuchen,
          derartige Verstöße zu verhindern. Bitte nutzen die in unseren Diensten
          verfügbaren Sicherheitsfunktionen.
        </div>
        <div>
          Wir implementieren Sicherheitsschutzmaßnahmen, wie HTTPS, die zum
          Schutz Deiner Daten vorgesehen sind. Wir prüfen unsere Systeme
          regelmäßig auf mögliche Schwachstellen und Angriffe. Wir können die
          Sicherheit der uns von Ihnen übermittelten Informationen jedoch nicht
          gewährleisten. Es gibt keine Garantie dafür, dass nicht auf Daten
          zugegriffen wird oder dass diese nicht offengelegt, geändert oder
          infolge der Verletzung unserer physischen, technischen und
          organisatorischen Sicherheitsvorkehrungen zerstört werden. Weitere
          Informationen über die sichere Nutzung unserer Dienste, einschließlich
          der zweistufigen Authentifizierung, findest Du in unserem
          Sicherheitsbereich.
        </div>
        <h2 className="font-semibold">
          5.2. Grenzüberschreitende Datenübertragungen
        </h2>
        <div>Wir speichern und nutzen Deine Daten außerhalb Deines Landes.</div>
        <div>
          Wir verarbeiten Daten sowohl innerhalb als auch außerhalb der USA und
          bedienen uns dabei gesetzlich vorgesehener Mechanismen für die
          rechtmäßige grenzübergreifende Übertragung von Daten. Mehr dazu.
          Möglicherweise gelten in den Ländern, in denen wir Daten verarbeiten,
          andere Gesetze, die eventuell nicht denselben Grad an Schutz bieten
          wie die Gesetze in Deinem Land.
        </div>
        <h2 className="font-semibold">
          5.3. Rechtsgrundlage für die Verarbeitung
        </h2>
        <div>
          Die Erfassung, Verwendung und Weitergabe der Daten, die uns zu Deiner
          Person vorliegen, erfolgt auf rechtlicher Grundlage. Bezüglich der
          Verwendung Deiner Daten hast Du Wahlmöglichkeiten. Über Deine
          Einstellungen kannst Du diese Einwilligung jederzeit widerrufen.
        </div>
        <div>
          Wir erfassen und verarbeiten personenbezogene Daten über Dich nur,
          wenn dafür eine entsprechende Rechtsgrundlage besteht. Als
          Rechtsgrundlage gelten unter anderem Einwilligung (wenn Du Deine
          Einwilligung gegeben hast), Vertrag (wenn die Verarbeitung für die
          Erfüllung eines Vertrags mit Dir (beispielsweise die Erbringung der
          von Dir verlangten TRADEFOOX Dienste) erforderlich ist) und „legitime
          Interessen“. Mehr erfahren
        </div>
        <div>
          Wenn wir uns bei der Verarbeitung personenbezogener Daten auf Deine
          Einwilligung berufen, bist Du berechtigt, Deine Einwilligung jederzeit
          zurückzuziehen oder zu verweigern. Wenn wir uns auf legitime
          Interessen berufen, bist Du berechtigt, Einspruch dagegen zu erheben.
          Mehr dazu. Wenn Du Fragen zur Rechtsgrundlage hast, auf der wir Deine
          personenbezogenen Daten erfassen und verwenden, wende Dich bitte hier
          an unseren Datenschutzbeauftragten.
        </div>
        <h2 className="font-semibold">
          5.4. Direktmarketing und „Do Not Track“-Signale
        </h2>
        <div>
          Unsere Aussagen zu Direktmarketing und „Do Not Track“-Signalen.
        </div>
        <div>
          Ohne Deine Erlaubnis geben wir keine personenbezogenen Daten an Dritte
          für deren Direkt-Marketing-Zwecke weiter. Erfahre mehr über dieses
          Thema und über unsere Haltung gegenüber „Do Not Track“-Signalen.
        </div>
        <h2 className="font-semibold">5.5. Kontaktinformationen</h2>
        <div>
          Du kannst Dich mit uns in Verbindung setzen oder andere Optionen
          nutzen, um Beschwerden beizulegen.
        </div>
        <div>
          Sollten Du Fragen oder Beschwerden hinsichtlich dieser Richtlinie
          haben, kontaktiere uns bitte zuerst online. Du kannst uns auch auf dem
          Postweg erreichen. Wenn Deine Beschwerde nicht durch eine
          Kontaktaufnahme mit uns beigelegt werden konnte, hast Du weitere
          Möglichkeiten. Wenn Du in den designierten Ländern und anderen
          Regionen ansässig bist, kannst Du Dich auch hier an unseren
          Datenschutzbeauftragten wenden. Wenn Deine Beschwerde auf diese Weise
          nicht gelöst werden kann, stehen Ansässigen in designierten Ländern
          und anderen Regionen ggf. weitere Optionen nach der je
        </div>
        <div>
          <div className="text-xs font-bold">Weitere Infos</div>
          <div className="text-xs flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              Datenschutz
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              Urheberrecht
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              Cookie Richtlinie
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              Community Richtlinie
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              Nutzungsbedingungen
            </NavLink>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default PrivacyContainer;
