import React from "react";
import BackButton from "@/components/nav/BackButton";
import { NavLink } from "react-router-dom";

type OrgType = {
  link: string;
  text: string;
  thumbnail: string;
};

const Donations = () => {
  const orgs: Array<OrgType> = [
    {
      link: "https://www.ekk-chemnitz.de/",
      text: "Jährlich erkranken in unserer Region bis zu 50 Kinder und Jugendliche an Leukämie oder bösartigen Tumoren. Die Diagnose einer solchen Erkrankung erschüttert die gesamte Familie grundlegend. Die Arbeit des Elternvereins krebskranker Kinder basiert auf einer engen Zusammenarbeit mit den Ärzten und dem medizinischen Personal der Kinderkrebsstation der Klinik für Kinder- und Jugendmedizin Chemnitz sowie anderen Kooperationspartnern, Psychologen, Therapeuten und Pädagogen...",
      thumbnail: "/images/info/orgs/ekk-chemnitz.png",
    },
    {
      link: "https://www.hospiz-chemnitz.de/spenden-finanzierung/spendenmanagement.php",
      text: "Sinn und Aufgabe eines Hospizdienstes ist es, sterbende Menschen und ihnen Nahestehende in dieser letzten Lebensphase zu begleiten, die Rechte und Bedürfnisse der Sterbenden, ihrer Angehörigen und Freunde zu achten und mit Aufmerksamkeit, Fürsorge und Wahrhaftigkeit dem Sterbenden und seinen Angehörigen beizustehen. „Sterben zu Hause“ ermöglichen ist das vorrangige Ziel des Hospiz- und Palliativdienstes Chemnitz e.V...",
      thumbnail: "/images/info/orgs/hospiz-chemnitz.png",
    },
    {
      link: "https://www.freundeskreis-kinderheim.de/",
      text: "Der Freundeskreis „Indira Gandhi“ e.V. ist ein anerkannter Träger der freien Jugendhilfe und Mitglied des Paritätischen Wohlfahrtsverbands (DPWV). Die haupt- und ehrenamtlichen Mitarbeiter tragen mit ihrem Engagement wesentlich dazu bei, dass die inhaltlichen Anforderungen sowie die materiellen Rahmenbedingungen für die breit gefächerten Hilfeformen im stationären und ambulanten Bereich auf hohem Niveau gehalten und ständig erweitert werden können...",
      thumbnail: "/images/info/orgs/kinderheim-chemnitz.png",
    },
    {
      link: "https://www.stadtmission-chemnitz.de/helfen-spenden",
      text: "Nicht allen geht es gut, nicht alle packen’s allein. Da gibt es Pflegebedürftige, Menschen in verschiedenen Konfliktsituationen, Jugendliche ohne Hoffnung auf Zukunft, Menschen in Einsamkeit, Menschen ohne Arbeit, behinderte, psychisch kranke oder wohnungslose Menschen. Seit mehr als 140 Jahren kümmern sich Mitarbeiter der Diakonie in Chemnitz um solche Menschen. Als Verein wurde die „Stadtmission Chemnitz e.V.“ am 05.01.1991 neu gegründet",
      thumbnail: "/images/info/orgs/stadtmission-chemnitz.jpg",
    },
    {
      link: "https://www.chemnitzer-tafel.de/",
      text: "Die Chemnitzer Tafel, sammelt mit ihren überwiegend ehrenamtlichen Mitarbeitern nach den Gesetzen der Marktlogik „überschüssige“ Lebensmittel und gibt diese kostenlos bzw. gegen einen symbolischen Kostenbeitrag an bedürftige Menschen und an soziale Einrichtungen weiter. Sie arbeiten dabei nach dem Grundsatz „Jeder gibt was er kann!“ - das heißt, die einen geben ihre Waren, andere ihr Geld und Ehrenamtliche ihre Zeit und ihre Erfahrungen...",
      thumbnail: "/images/info/orgs/logo-chemnitzer-tafel.png",
    },
    {
      link: "https://tierschutz-chemnitz.de/",
      text: "Das Tierheim Chemnitz kümmert sich seit 1995 um Tiere, die in Not geraten sind. Fundtiere finden Aufnahme, ebenso wie von den Behörden beschlagnahmte Tiere. Wenn Halter mit ihren Tieren überfordert sind, werden diese Tiere aufgenommen. Ein ganz wichtiger Teil der Arbeit ist die Tiervermittlung, denn Ziel ist es, für alle anvertrauten Geschöpfe wieder ein schönes Zuhause zu finden. Tierschutz ist ein unglaublich wichtiges Thema in unserer Gesellschaft und nicht nur Heimtiere finden bei im Tierheim Chemnitz eine liebevolle Aufnahme...",
      thumbnail: "/images/info/orgs/tierschutzverein-chemnitz-logo.jpg",
    },
  ];

  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/spendenaktion_2_tradefoox.jpg"
            className="w-full object-fill"
          />
        </section>

        <article className="text-sm">
          <h1 className="font-semibold text-base mb-4">
            TRADEFOOX Spendenaktion
          </h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-3">
              <div>
                <h2 className="font-semibold">Gemeinsam. Zusammen. Helfen.</h2>
                Geld ist nicht alles, aber es hilft beim Erreichen von Zielen.
                Genauso wie Sachspenden oder helfende Hände - wenn auch nur für
                ein paar Stunden. Wir möchten unsere Reichweite dafür nutzen,
                die hilfsbedürftigen Menschen in Chemnitz direkt zu fördern.
                Dafür brauchen wir auch Eure Unterstützung - egal ob als
                Privatperson oder Unternehmen. Jeder kann anderen helfen.
              </div>
            </div>
          </div>
        </article>
        {orgs.map((org: OrgType, index) => {
          return (
            <article key={index} className="flex flex-col gap-2">
              <img src={org.thumbnail} className="h-[100px] mx-auto" />
              <div>
                {org.text}{" "}
                <NavLink to={org.link} target="_blank">
                  <div className="text-darkcyan">Mehr erfahren &gt;&gt;</div>
                </NavLink>
              </div>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default Donations;
