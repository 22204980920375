import React from "react";
import PostWidget from "@components/elements/PostWidget";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Post } from "@/utils/post";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import api from "@/api/api";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
};

const MyFeedContainer = ({ searchQuery }: Props) => {
  const posts = useInfiniteQuery<Response>(
    ["posts", "following_feed", searchQuery],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed/following?page=" + pageParam + "&q=" + searchQuery,
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  if (!posts.isSuccess) return <></>;

  return (
    <>
      {posts.data.pages.map(
        (page) =>
          page &&
          page.data.map((post: Post) => (
            <div
              className="max-lg:odd:py-2 max-lg:odd:border-y max-lg:odd:border-y-gray-300"
              key={post.id}
            >
              <PostWidget
                postId={post.id}
                post={post}
                key={post.id}
                text={post.text}
                author={post.author}
                created_at={post.created_at}
              />
            </div>
          )),
      )}

      {(posts.hasNextPage || posts.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => posts.fetchNextPage()} />
      )}
    </>
  );
};

export default MyFeedContainer;
