import React, { useEffect, useRef, useState } from "react";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import tw from "twin.macro";
import {
  ArrowDownOnSquareIcon,
  ArrowDownTrayIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { LinkPreviewType } from "@/utils/types/LinkPreviewType";
import LinkPreview from "@components/elements/LinkPreview";
import ReactPlayer from "react-player";
import MessageDropdown from "@pages/messenger/chat/dropdown/MessageDropdown";
import { Media } from "@/utils/types/Media";

type Props = {
  author: User;
  message: {
    id: string;
    text: string;
    created_at: string;
    files?: Array<string>;
    read: boolean;
    link_preview?: LinkPreviewType;
    media?: Array<Media>;
  };
  isSender: boolean;
  onForward: () => void;
  key?: string;
};

const getFileName = (path: string) => {
  const split = path.split("/");
  return split[split.length - 1];
};

const getFileType = (filename: string) => {
  switch (filename.split(".")[1]) {
    case "png":
    case "jpg":
    case "jpeg":
      return "image";
    case "mp4":
    case "webm":
      return "video";
    default:
      return "file";
  }
};

function MessengerBubble({ author, message, isSender, onForward }: Props) {
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const body = useRef<any>(null);
  const filename = getFileName(message.text);
  const isMedia = message.text.startsWith("chat/media/");
  const [selectedMedia, setSelectedMedia] = useState(0);

  useEffect(() => {
    body.current && body.current.clientHeight > 120 && setLargeText(true);
  }, [body]);
  return (
    <div
      css={[tw`flex items-end`, isSender ? tw`justify-end` : tw`justify-start`]}
    >
      <div
        css={[
          tw`rounded-lg px-4 py-2 max-w-[90%] break-words relative`,
          isSender
            ? tw`bg-blue-600 text-white self-end`
            : tw`bg-gray-100 text-gray-700 self-start`,
          isMedia && tw`w-[90%]`,
        ]}
      >
        <MessageDropdown
          messageId={message.id}
          isSender={isSender}
          onForward={onForward}
        />
        <div className="font-semibold mr-3 truncate">{getName(author)}</div>
        {message.link_preview && (
          <LinkPreview link={message.link_preview} small={true} />
        )}
        {!isMedia && (
          <div
            css={[
              tw`overflow-hidden text-sm leading-4`,
              !showMore && largeText && tw`line-clamp-5`,
            ]}
            ref={body}
          >
            {message.text}
          </div>
        )}
        {message.media && message.media[0] && (
          <div className="rounded-2xl overflow-hidden border border-gray-400 mt-2 relative max-h-[700px] text-black">
            {message.media[selectedMedia].type === "other" && (
              <a
                href={
                  "https://cdn.tradefoox.iaccam.com/chat/" +
                  message.media[selectedMedia].key +
                  "/" +
                  message.media[selectedMedia].file_name
                }
                download={filename}
              >
                <div className="w-96 h-36 bg-gray-300 rounded-2xl">
                  <ArrowDownOnSquareIcon className="w-8 h-8 absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 text-gray-400" />
                  <span className="w-4/5 text-xs left-1/2 -translate-x-1/2 absolute text-center bottom-6 leading-1 truncate">
                    {message.media[selectedMedia].file_name}
                  </span>
                </div>
              </a>
            )}
            {message.media[selectedMedia].type === "image" && (
              <img
                src={
                  "https://cdn.tradefoox.iaccam.com/chat/" +
                  message.media[selectedMedia].key +
                  "/" +
                  message.media[selectedMedia].file_name
                }
                alt={message.media[selectedMedia].file_name}
                className="w-full h-full object-contain"
                width="100%"
                height="100%"
              />
            )}

            {message.media[selectedMedia].type === "video" && (
              <ReactPlayer
                width="100%"
                controls={true}
                height="100%"
                url={
                  "https://cdn.tradefoox.iaccam.com/chat/" +
                  message.media[selectedMedia].key +
                  "/" +
                  message.media[selectedMedia].file_name
                }
              />
            )}

            {selectedMedia !== 0 && (
              <div
                className="absolute left-0 top-0 h-full w-1/6 cursor-pointer"
                onClick={() => setSelectedMedia((count) => count - 1)}
              >
                <div className="hover:bg-white opacity-0 hover:opacity-50 absolute left-0 top-0 h-full w-full z-0">
                  <ChevronLeftIcon className="h-full w-[35px] mx-auto z-50" />
                </div>
              </div>
            )}
            {selectedMedia < message.media.length - 1 && (
              <div
                className="absolute right-0 top-0 h-full w-1/6 cursor-pointer"
                onClick={() => setSelectedMedia((count) => count + 1)}
              >
                <div className="hover:bg-white opacity-0 hover:opacity-50 absolute left-0 top-0 h-full w-full z-0">
                  <ChevronRightIcon className="h-full w-[35px] mx-auto z-50" />
                </div>
              </div>
            )}
            <div className="absolute bottom-0 w-full mx-auto flex justify-center gap-2 my-2">
              {message.media.map((media, index) => (
                <div
                  css={[
                    tw`rounded-full w-[10px] h-[10px] bg-black cursor-pointer`,
                    index === selectedMedia &&
                      tw`border-2 border-black bg-white`,
                  ]}
                  onClick={() => setSelectedMedia(index)}
                  key={index}
                ></div>
              ))}
            </div>
          </div>
        )}
        {largeText && (
          <ShowMoreButton
            onClick={() => setShowMore((value) => !value)}
            active={showMore}
            blue={isSender}
          />
        )}
        <div className="flex gap-1 mt-1 justify-end">
          <div css={[tw`text-xssm`]}>{message.created_at}</div>
          <CheckIcon className="h-3" />
          {message.read && <CheckIcon className="h-3 -ml-2.5" />}
        </div>
      </div>
    </div>
  );
}

export default MessengerBubble;
