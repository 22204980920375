import React, { ReactNode, useEffect, useRef, useState } from "react";
import LegacyPost from "@pages/news/posts/LegacyPost";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import api from "@/api/api";
import isApp from "@/utils/isApp";
import { Browser } from "@capacitor/browser";
import ExternalLink from "@components/elements/shared/ExternalLink";

type Props = {
  text: string;
  legacy?: boolean;
  disableTopMargin?: boolean;
};

const PostText = ({ text, legacy, disableTopMargin }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const urlRegex =
    /(https?:\/\/[^\s]+)|#([a-zA-ZäöüÄÖÜß0-9]+)|@([a-zA-Z0-9-]+)/g;
  const body = useRef<any>(null);

  let match;
  const links: any[] = [];
  while ((match = urlRegex.exec(text)) !== null) {
    links.push(match[0]);
  }

  const [users, setUsers] = useState<string[]>([]);

  const mentions = useQuery<User[] | null, Error>(
    ["mentions", "users", users],
    async () => {
      const res = await api.get(
        `/api/v1/users/mentions?ids=${users.join(",")}`,
      );
      return res.data.data;
    },
    {
      retry: false,
      enabled: users.length > 0,
    },
  );

  const generateTextWithLinks = () => {
    const content: Array<ReactNode> = [];
    let match;
    const matches = [];
    while ((match = urlRegex.exec(text)) !== null) {
      matches.push({ hashtag: match[0], index: match.index });
    }

    if (matches.length === 0) {
      content.push(text);
      return content;
    }

    let lastIndex = 0;
    for (const match of matches) {
      const prefix = text.slice(lastIndex, match.index);
      if (prefix.length > 0) {
        content.push(<span>{prefix}</span>);
      }
      if (match.hashtag.startsWith("http")) {
        content.push(
          <ExternalLink to={match.hashtag}>{match.hashtag}</ExternalLink>,
        );
      } else if (match.hashtag.startsWith("@")) {
        if (!users.includes(match.hashtag.replace("@", ""))) {
          setUsers((data) => [...data, match.hashtag.replace("@", "")]);
        }

        const mention =
          mentions.isSuccess &&
          mentions.data?.find(
            (user) => user.id === match.hashtag.replace("@", ""),
          );

        if (mention) {
          content.push(
            <NavLink to={`/profile/${mention.id}`} className="text-lightblue">
              @{getName(mention)}
            </NavLink>,
          );
        } else {
          content.push(<span>{match.hashtag}</span>);
        }
      } else {
        content.push(
          <NavLink
            to={`/tags/${match.hashtag.replace("#", "")}`}
            className="text-lightblue"
          >
            {match.hashtag}
          </NavLink>,
        );
      }
      lastIndex = match.index + match.hashtag.length;
    }

    const suffix = text.slice(lastIndex);
    if (suffix.length > 0) {
      content.push(<span>{suffix}</span>);
    }

    return content;
  };

  const content: Array<ReactNode> = generateTextWithLinks();

  useEffect(() => {
    body.current && body.current.clientHeight > 40 && setLargeText(true);
  }, [body]);

  return (
    <>
      <div css={[!disableTopMargin && tw`mt-3`]}>
        <div
          css={[
            tw`overflow-hidden leading-4`,
            !showMore && largeText && tw`line-clamp-2`,
          ]}
          ref={body}
        >
          {legacy ? <LegacyPost content={text} /> : content}
        </div>
      </div>
      {!showMore && largeText && (
        <button
          className="text-gray-500"
          onClick={() => setShowMore((value) => !value)}
        >
          mehr
        </button>
      )}
    </>
  );
};

export default PostText;
