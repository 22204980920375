import { User } from "@/utils/user";

const getName = (user: User) => {
  if (user.person) return `${user.person.firstname} ${user.person.lastname}`;
  if (user.company) return user.company.name;
  if (user.club) return user.club.name;
  return undefined;
};

export default getName;
