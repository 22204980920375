import React, { useState, Dispatch, SetStateAction, useRef } from "react";
import Button from "@components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Stream } from "@cloudflare/stream-react";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: (media: {
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }) => void;
  onDelete: (id: string) => void;
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
  addClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
};
const PostMedia = ({ onChange, media, onDelete, addClick }: Props) => {
  const [uploadingCount, setUploadingCount] = useState(0);
  const { t } = useTranslation();

  const upload = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      let url = "/api/v1/media/upload";
      let key = "";
      let id = "";
      let thumbnail = "";
      if (file.type.startsWith("video")) {
        const directUploadResponse = await api.get(
          "/api/v1/video/upload/direct",
        );
        url = directUploadResponse.data.data.url;
        key = directUploadResponse.data.data.key;
        id = directUploadResponse.data.data.id;
        thumbnail = directUploadResponse.data.data.thumbnail;
      }
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let res;
      if (file.type.startsWith("video")) {
        res = await axios.post(url, formData, config);
      } else {
        res = await api.post(url, formData, config);
      }
      if (!file.type.startsWith("video")) {
        key = res.data.data.key;
        id = res.data.data.id;
      }
      setUploadingCount((count) => count - 1);
      return {
        key,
        id,
        file_name: file.name,
        type: file.type.startsWith("video") ? "video" : "image",
        thumbnail,
      };
    },
    onSuccess: (data) => {
      onChange(data);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const addMedia = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  if (addClick?.setFunction && !addClick?.getFunction) {
    addClick.setFunction(() => {
      return addMedia;
    });
  }

  return (
    <>
      <div className="font-semibold text-sm">Medien</div>
      <div className="text-sm">
        Teile Fotos und Videos. Dein Feed-Beitrag kann bis zu 10 Dateien
        enthalten.
      </div>
      <div className="lg:border lg:px-3 lg:py-3 grid grid-cols-4 gap-3 rounded-xl">
        {media.map((data) => (
          <div key={data.id} className="relative">
            <button
              onClick={() => {
                onDelete(data.id);
              }}
              className="absolute right-3 top-3"
            >
              <XMarkIcon className="w-6 h-6 bg-white opacity-90 rounded-full" />
            </button>
            {data.type === "image" && (
              <img
                className="bg-gray-200 rounded-xl"
                src={
                  "https://cdn.tradefoox.iaccam.com/" +
                  data.key +
                  "/" +
                  data.file_name
                }
              />
            )}
            {data.type === "video" && (
              <img className="bg-gray-200 rounded-xl" src={data.thumbnail} />
            )}
          </div>
        ))}
        {[...Array(uploadingCount)].map((_, index) => (
          <div key={index} className="bg-gray-200 rounded-xl">
            <svg
              className="animate-spin h-5 w-5 mr-3"
              viewBox="0 0 24 24"
            ></svg>
          </div>
        ))}
      </div>
      {!addClick && (
        <div className="w-fit ml-auto">
          <Button
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
            onClick={addMedia}
          >
            {t("main.feedTabs.buttons.addMedia")}
          </Button>
        </div>
      )}
      <input
        type="file"
        className="ml-auto"
        accept="image/*,video/*"
        onChange={(e) => {
          if (
            e.target.files &&
            e.target.files.length + uploadingCount + media.length > 10
          ) {
            alert("Max 10 Bilder");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount((count) => count + 1);
            upload.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};

export default PostMedia;
