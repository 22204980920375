import React from "react";
import PostsActionBarItem from "@/pages/news/posts/PostsActionBarItem";
import usePopupModal from "@/state/modal/usePopupModal";
import SharePopup from "@/components/popup/SharePopup";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";

type ContentType = {
  title?: string;
  body?: string;
  media?: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
};

type Props = {
  contentType: string;
  label?: string;
  content?: ContentType;
};

const ShareButton = ({ contentType, label = "", content = {} }: Props) => {
  const popupModal = usePopupModal();

  return (
    <PostsActionBarItem
      onClick={() => {
        popupModal.open(
          <SharePopup onClose={popupModal.close} userId="" content={content} />,
        );
      }}
      label={label}
    >
      <ArrowPathRoundedSquareIcon />
    </PostsActionBarItem>
  );
};

export default ShareButton;
