import React, { useRef } from "react";
import Panel from "@components/box/Panel";
import SuggestionsCenterItem from "@components/elements/sidebar/suggestions/SuggestionsCenterItem";
import axios from "axios";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import { PlayIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { Post } from "@/utils/post";
import api from "@/api/api";
import WatchType from "@/utils/types/watch/WatchType";
import ScrollIcons from "@components/elements/shared/ScrollIcons";
import { useTranslation } from "react-i18next";

type Props = { key?: string };

type Response = {
  data: Array<WatchType>;
};

const WatchSuggestions = ({ key }: Props) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const videos = useQuery<Response, Error>(["posts", "watch"], async () => {
    const { data } = await api.get("/api/v1/watch");
    return data;
  });

  if (!videos.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel
        title="Beliebte Videos auf Tradefoox"
        mobileBorder={false}
        key={key}
      >
        <div className="relative">
          <div
            className="flex gap-4 overflow-x-auto no-scrollbar"
            ref={galleryRef}
          >
            {videos.isSuccess &&
              videos.data.data.map((item, i) => (
                <SuggestionsCenterItem
                  key={item.media_key}
                  name=""
                  link={"/watch/" + item.media_key}
                  picture={item.media_thumbnail}
                  imageStyle={tw`rounded-xl w-[150px] aspect-[9/16] h-full`}
                  nameStyle={tw`hidden`}
                  views={{
                    icon: <PlayIcon className="w-4 h-4" />,
                    count: item.view_count,
                    color: tw`text-black`,
                  }}
                />
              ))}
            <NavLink to="/watch/">
              <div className="w-[150px] aspect-[9/16] flex flex-col justify-center text-center">
                {t("buttons.showMore")}
              </div>
            </NavLink>
            <ScrollIcons galleryRef={galleryRef} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
export default WatchSuggestions;
