import React from "react";
import Panel from "@components/box/Panel";
import SuggestionsCenterItem from "@components/elements/sidebar/suggestions/SuggestionsCenterItem";
import tw from "twin.macro";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const items: Array<{ name: string; picture: string; link: string }> = [];

type Props = { key?: string };

const MarketplaceSuggestions = ({ key }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="px-3 lg:px-0">
      <Panel
        title="Beliebte Produkte auf Tradefoox"
        mobileBorder={false}
        key={key}
      >
        <div className="flex gap-4 overflow-x-auto no-scrollbar">
          {items.map((item) => (
            <SuggestionsCenterItem
              key={item.name}
              name={item.name}
              link={item.link}
              picture={item.picture}
              imageStyle={tw`rounded-xl overflow-visible w-[100px] h-[150px] object-cover`}
            />
          ))}
          <NavLink to="/marketplace/">
            <div className="w-[100px] h-[150px] flex flex-col justify-center text-center">
              {t("buttons.showMore")}
            </div>
          </NavLink>
        </div>
      </Panel>
    </div>
  );
};
export default MarketplaceSuggestions;
