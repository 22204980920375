import React from "react";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  name: string;
  link: string;
  picture: string;
  key?: string;
};
const SuggestionsItem = (props: Props) => (
  <div className="flex gap-4 overflow-x-auto no-scrollbar mt-2">
    <NavLink to={props.link} className="relative">
      <div className="cursor-pointer relative grid grid-cols-[65px,auto]">
        <div className="w-[50px] h-[50px] rounded-full border border-gray-300 relative border-pink-400">
          <img
            className="rounded-full overflow-hidden"
            src={props.picture}
            alt="Inserat"
          />
        </div>
        <div
          css={[
            tw`text-xs text-center whitespace-nowrap text-gray-600 text-sm truncate leading-[50px]`,
          ]}
        >
          {props.name}
        </div>
      </div>
    </NavLink>
  </div>
);

export default SuggestionsItem;
