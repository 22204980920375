import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const Successor = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [offerType, setOfferType] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [branches, setBranches] = useState("");
  const [employees, setEmployees] = useState("");
  const [lastAnnual, setLastAnnual] = useState("");
  const [firstOffer, setFirstOffer] = useState("");
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form className="grid grid-flow-row gap-2 text-sm">
      <BackButton />
      <img
        src="/images/info/firmen_unternehmen_nachfolger_gesucht_tradefoox.jpg"
        className="h-[250px] w-full object-cover"
      />
      <h1 className="font-semibold">
        Du suchst einen Betrieb, den Du übernehmen kannst, oder einen Nachfolger
        für Dein Unternehmen?
      </h1>
      <div>
        Unser Ziel der Unternehmensnachfolgeseite ist es, nachfolgeinteressierte
        Unternehmer und Existenzgründer zusammen zu bringen.
      </div>
      <div>
        Unternehmerinnen und Unternehmer sowie Existenzgründerinnen und -gründer
        können dazu Kontakt mit uns aufnehmen. Wir werden Dich gezielt bei
        Deiner Unternehmensdarstellung unterstützen.
      </div>
      <div>
        Auf dieser Seite hast Du die Möglichkeit Dich zu präsentieren und
        tradefoox.com wird Dich bei der Kontaktvermittlung zwischen den Nutzern
        begleiten. Nimm einfach Kontakt über dieses Formular zu uns auf!
      </div>
      <h2 className="font-semibold text-base">Kontaktiere uns</h2>
      <div>
        Was bedeutet anonym veröffentlichen? Es wird nur deine Beschreibung
        angezeigt. Die Kontaktaufnahme erfolgt ausschließlich über die Plattform
        TRADEFOOX.com
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Checkbox
          name="offerType"
          required
          checked={offerType}
          onChange={() => setOfferType(true)}
        >
          Ich biete
        </Checkbox>
        <Checkbox
          name="offerType"
          required
          checked={!offerType}
          onChange={() => setOfferType(false)}
        >
          Ich Suche
        </Checkbox>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Checkbox
          name="anonymous"
          required
          checked={anonymous}
          onChange={() => setAnonymous(true)}
        >
          Anonym veröffentlichen
        </Checkbox>
        <Checkbox
          name="anonymous"
          required
          checked={!anonymous}
          onChange={() => setAnonymous(false)}
        >
          Mit Firmenname veröffentlichen
        </Checkbox>
      </div>
      <Input
        label="E-Mail"
        type="text"
        name="email"
        value={email}
        onChange={(e) => {
          setEmail(e);
        }}
        required
      />
      <Input
        label="Telefon"
        type="text"
        name="phone"
        value={phone}
        onChange={(e) => {
          setPhone(e);
        }}
        required
      />
      <Input
        label="Firma"
        type="text"
        name="company"
        value={company}
        onChange={(e) => {
          setCompany(e);
        }}
        required
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Vorname"
          type="text"
          name="surname"
          placeholder="Max"
          value={surname}
          onChange={(e) => {
            setSurname(e);
          }}
          required
        />
        <Input
          label="Nachname*"
          type="text"
          name="name"
          placeholder="Mustermann"
          value={lastname}
          onChange={(e) => {
            setLastname(e);
          }}
          required
        />
      </div>
      <div>
        Deine Nachricht
        <TextareaAutosize
          minRows={3}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
          required
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Ort"
          type="text"
          name="city"
          value={city}
          onChange={(e) => {
            setCity(e);
          }}
        />
        <Input
          label="Bundesland"
          type="text"
          name="state"
          value={state}
          onChange={(e) => {
            setState(e);
          }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Branchen(n)"
          type="text"
          name="branches"
          value={branches}
          onChange={(e) => {
            setBranches(e);
          }}
        />
        <Input
          label="Anzahlder Mitarbeiter"
          type="text"
          name="employees"
          value={employees}
          onChange={(e) => {
            setEmployees(e);
          }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Letzter Jahresumsatz in €"
          type="text"
          name="lastAnnual"
          value={lastAnnual}
          onChange={(e) => {
            setLastAnnual(e);
          }}
        />
        <Input
          label="Preisvorstellung in €"
          type="text"
          name="firstOffer"
          value={firstOffer}
          onChange={(e) => {
            setFirstOffer(e);
          }}
        />
      </div>
      <Checkbox
        name="agb"
        required
        checked={privacyCheck}
        onChange={(e) => setPrivacyCheck(e)}
      >
        Ich habe die{" "}
        <NavLink to="/privacy" target="_blank">
          Datenschutzerklärung
        </NavLink>{" "}
        gelesen und akzeptiert
      </Checkbox>
      <Checkbox
        name="newsletter"
        checked={newsletterCheck}
        onChange={(e) => setNewsletterCheck(e)}
      >
        Ich möchte den TRADEFOOX-Newsletter kostenlos erhalten.
      </Checkbox>
      <Button submit>Senden</Button>
    </form>
  );
};

export default Successor;
