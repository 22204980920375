import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import notificationsReducer from "@/state/notifications/notificationsSlice";
import userReducer from "@/state/user/userSlice";
import { modalSlice } from "@/state/modal/modalSlice";
import { popupSlice } from "@/state/modal/popupSlice";
import reportModalReducer from "@/state/reportModal/reducer";
import blockUserModalReducer from "@/state/blockUserModal/reducer";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const userPersisted = persistReducer(
  {
    key: "user",
    version: 1,
    storage,
    whitelist: ["user", "token"],
  },
  userReducer,
);

const rootReducer = combineReducers({
  user: userPersisted,
  notifications: notificationsReducer,
  login: modalSlice.reducer,
  popups: popupSlice.reducer,
  reportModal: reportModalReducer,
  blockUserModal: blockUserModalReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 256 },
      serializableCheck: {
        warnAfter: 256,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
