import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";

const Newsletter = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <BackButton />
      <section className="relative">
        <img
          src="/images/info/newsletter_2_tradefoox.jpg"
          className="h-[300px] w-full object-cover"
        />
        <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
          <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
            Unser Newsletter
          </div>
          <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
            TRADEFOOX
          </div>
        </div>
      </section>

      <div className="grid grid-flow-row gap-6 text-gray-700">
        <article className="grid grid-flow-row gap-3 text-sm">
          <h1 className="text-base font-semibold">Bleibe auf dem Laufenden!</h1>
          Melde Dich kostenfrei für unseren Newsletter an und erhalte aktuelle
          Informationen und News aus der Wirtschaft und der Region. Das
          TRADEFOOX-Team versorgt Dich mit interessanten Content, beispielsweise
          Marketing-Solutions und Mustervorlagen.
        </article>
        <form className="text-sm">
          Trage hier Deine E-Mail Adresse ein und erhalte exklusive Tipps und
          Neuigkeiten von TRADEFOOX!
          <Input
            type="email"
            name="email"
            placeholder="E-Mail*"
            value={email}
            required
            onChange={(e) => {
              setEmail(e);
            }}
          />
          <Checkbox
            name="agb"
            required
            checked={privacyCheck}
            onChange={(e) => setPrivacyCheck(e)}
          >
            Ich habe die{" "}
            <NavLink to="/privacy" target="_blank">
              Datenschutzerklärung
            </NavLink>{" "}
            gelesen und akzeptiert
          </Checkbox>
          <Button submit>Anmelden</Button>
        </form>
      </div>
    </>
  );
};

export default Newsletter;
