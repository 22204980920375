import React from "react";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import tw from "twin.macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  ShareIcon,
  CheckIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import api from "@/api/api";
import { showReportModal } from "@/state/reportModal/actions";
import { useDispatch } from "react-redux";

type Props = {
  messageId: string;
  isSender: boolean;
  onForward: () => void;
};
const MessageDropdown = ({ messageId, isSender, onForward }: Props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const deleteMessage = useMutation(["delete-message"], {
    mutationFn: async () => {
      const res = await api.delete(
        `/api/v1/chats/undefined/messages/${messageId}`,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["messages"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Menu as="div" className="inline-block text-left absolute top-3 right-1">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                css={[
                  active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                  tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                ]}
              >
                Als ungelesen markieren
                <CheckIcon className="w-4 h-4" />
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                css={[
                  active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                  tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                ]}
              >
                Dateien runterladen
                <ArrowDownTrayIcon className="w-4 h-4" />
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onForward()}
                css={[
                  active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                  tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                ]}
              >
                Weiterleiten
                <ShareIcon className="w-4 h-4" />
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => dispatch(showReportModal(messageId, "message"))}
                css={[
                  active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                  tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                ]}
              >
                Melden
                <FlagIcon className="w-4 h-4" />
              </button>
            )}
          </Menu.Item>
          {isSender && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => deleteMessage.mutate()}
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  Löschen
                  <TrashIcon className="w-4 h-4" />
                </button>
              )}
            </Menu.Item>
          )}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default MessageDropdown;
