import React from "react";
import Panel from "@components/box/Panel";
import Button from "@components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/api/api";
import { useNavigate } from "react-router-dom";

const AccountDeactivationPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const action = useMutation(["deactivate"], {
    mutationFn: async () => {
      const res = await api.get("/api/v1/deactivate");
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      navigate("/logout");
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel title="Konto-Deaktivierung und -löschung" mobileBorder={false}>
      <div className="confirmation-container text-sm">
        <p className="mb-2">
          Sie sind dabei, Ihr Konto auf Tradefoox zu deaktivieren. Bitte lesen
          Sie die folgenden Informationen sorgfältig durch, bevor Sie
          fortfahren:
        </p>
        <ul className="mb-2 list-inside">
          <li className="mb-2">
            <strong>Deaktivierung:</strong> Ihr Konto wird vorübergehend
            stillgelegt, und Ihr Profil wird für andere Nutzer nicht mehr
            sichtbar sein.
          </li>
          <li className="mb-2">
            <strong>Automatische Löschung nach 30 Tagen:</strong> Wenn Sie sich
            innerhalb von 30 Tagen nach der Deaktivierung nicht wieder anmelden,
            wird Ihr Konto endgültig gelöscht. Alle Ihre Beiträge, Nachrichten
            und Informationen werden dann unwiderruflich entfernt.
          </li>
          <li className="mb-2">
            <strong>Wiederherstellung:</strong> Falls Sie sich innerhalb dieser
            30 Tage wieder einloggen, wird Ihr Konto inklusive aller zugehörigen
            Daten wiederhergestellt, und die Deaktivierung wird aufgehoben.
          </li>
        </ul>
        <p className="mb-2">
          Bitte bestätigen Sie, dass Sie die obigen Informationen verstanden
          haben und mit der Deaktivierung Ihres Kontos einverstanden sind.
        </p>
        <Button variant={Button.Variants.Red} onClick={() => action.mutate()}>
          Konto deaktivieren
        </Button>
      </div>
    </Panel>
  );
};

export default AccountDeactivationPage;
