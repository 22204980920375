import React from "react";
import { ChatBubbleOvalLeftIcon, HeartIcon } from "@heroicons/react/24/outline";
import PostsActionBarItem from "@/pages/news/posts/PostsActionBarItem";
import { UseMutationResult } from "@tanstack/react-query";

type Props = {
  label?: string;
  createChat: UseMutationResult<any, unknown, void, unknown>;
};

const ChatButton = ({ label = "", createChat }: Props) => {
  return (
    <PostsActionBarItem
      onClick={() => {
        createChat.mutate();
      }}
      label={label}
      hideCount={true}
    >
      <ChatBubbleOvalLeftIcon />
    </PostsActionBarItem>
  );
};

export default ChatButton;
