import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions = {
  allowedTags: ["b", "i", "em", "strong", "a", "p", "br", "ul", "ol", "li"],
  allowedAttributes: {
    "a": ["href", "target", "rel"]
  }
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(
    dirty,
    defaultOptions
  )
});

const SanitizeHTML = ({ html }: { html: string }) => (
  <div dangerouslySetInnerHTML={sanitize(html)} />
);

const LegacyPost = ({ content }: { content: string }) => {
  return <SanitizeHTML html={content} />;
};

export default LegacyPost;