import React, { useState } from "react";
import {
  MagnifyingGlassIcon,
  CogIcon,
  LinkIcon,
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {
  LockClosedIcon,
  PhoneIcon,
  StarIcon,
  VideoCameraIcon,
  PhotoIcon,
  UserGroupIcon,
  PlusCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import InfoButton from "@/pages/messenger/group/InfoButton";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";
import { useQuery } from "@tanstack/react-query";
import { ChatType } from "@/utils/types/ChatType";
import tw from "twin.macro";
import getName from "@/utils/getName";
import { User } from "@/utils/user";
import GroupComboBox from "@/components/elements/form/GroupComboBox";
import { showReportModal } from "@/state/reportModal/actions";
import { useDispatch } from "react-redux";
import isMobile from "@/utils/isMobile";
import FormTextarea from "@/components/elements/form/FormTextarea";

type Props = {
  groupId: string;
  onClose?: () => void;
};

const GroupInfo = ({ groupId, onClose }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const [tab, setTab] = useState("info");
  const dispatch = useDispatch();
  const [description, setDescription] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
  const [newUsers, setNewUsers] = useState<Array<User>>([]);

  const chat = useQuery<ChatType, Error>(["chat-" + groupId], async () => {
    const { data } = await api.get("/api/v1/chats/" + groupId);
    return data;
  });

  if (!chat.isSuccess) return <></>;
  if (!user) return <></>;

  return (
    <div
      css={[
        tw`flex flex-col overflow-auto h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] lg:h-[calc(100dvh-82px)] relative`,
        isMobile
          ? tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`
          : tw`border border-gray-300 rounded-2xl relative px-3`,
      ]}
      className="no-scrollbar"
    >
      <div className="pt-3 px-0.5">
        {tab === "info" && (
          <div className="grid grid-flow-row gap-3 text-sm">
            <button
              className="absolute left-0 flex justify-center items-center px-2"
              onClick={onClose}
            >
              <ArrowLeftIcon className="w-8 h-8 hover:bg-gray-300 rounded-full" />
            </button>
            <div className="flex flex-row gap-4 items-center text-center text-xs">
              <div className="flex flex-col justify-center items-center w-full gap-2 cursor-pointer">
                <div className="rounded-full border border-gray-300 relative bg-white shrink-0 w-[50px] h-[50px]">
                  {chat.data.image ? (
                    <img
                      src={`${chat.data.image.data_url}/avatar`}
                      className="rounded-full"
                    />
                  ) : (
                    <img
                      src="/images/placeholder/club.png"
                      className="rounded-full"
                    />
                  )}
                </div>
                <div className="my-auto text-gray-600 text-sm font-bold line-clamp-1 break-all">
                  {chat.data.name}
                </div>
              </div>
            </div>
            <div className="grid grid-flow-col gap-2 text-xs">
              <button className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1">
                <PhoneIcon className="w-4 h-4" />
                Audio
              </button>
              <button className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1">
                <VideoCameraIcon className="w-4 h-4" />
                Video
              </button>
              <button
                className="flex flex-col items-center gap-0.5 border border-gray-300 rounded-xl py-1"
                onClick={() => {
                  onClose && onClose();
                }}
              >
                <MagnifyingGlassIcon className="w-4 h-4 stroke-[3px]" />
                Suchen
              </button>
            </div>
            {!showDescription && (
              <button
                className="border border-gray-300 rounded-xl"
                onClick={() => {
                  setShowDescription(true);
                }}
              >
                Gruppenbeschreibung hinzufügen
              </button>
            )}
            {showDescription && (
              <FormTextarea
                onChange={(e) => setDescription(e)}
                value={description}
                name="description"
              />
            )}
            <div className="grid grid-flow-row">
              <InfoButton
                label="Medien, Links und Doks"
                icon={
                  <PhotoIcon className="w-6 h-6 bg-white text-blue-700 rounded-md p-[1px]" />
                }
              />
              <InfoButton
                label="Mit Stern markiert"
                icon={
                  <StarIcon className="w-6 h-6 bg-yellow-500 text-white rounded-md" />
                }
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label="Verschlüsselung"
                subLabel="Nachrichten und Anrufe sind Ende-zu-Ende-verschlüsselt. Tipp, um mehr zu erfahren"
                icon={
                  <LockClosedIcon className="w-6 h-6 bg-blue-700 text-white rounded-md" />
                }
              />
              <InfoButton
                label="Gruppenberechtigungen"
                icon={
                  <CogIcon className="w-6 h-6 bg-blue-700 text-white rounded-md" />
                }
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label="Gruppe neuer Community hinzufügen"
                subLabel="Führe Mitglieder in themenbasierten Gruppen zusammen."
                icon={
                  <UserGroupIcon className="w-6 h-6 bg-blue-700 text-white rounded-md" />
                }
              />
            </div>
            <div className="flex flex-row items-center justify-between px-2">
              <div>{chat.data.members.length} Mitglieder</div>
              <button>
                <MagnifyingGlassIcon className="w-6 h-6 bg-gray-200 text-gray-600 p-[3px] rounded-full stroke-[3px]" />
              </button>
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label="Mitglieder hinzufügen"
                icon={
                  <PlusCircleIcon className="w-6 h-6 bg-transparent text-darkblue rounded-md" />
                }
                showArrow={false}
                onClick={() => {
                  setTab("add_users");
                }}
              />
              <InfoButton
                label="Mit Link zur Gruppe einladen"
                icon={
                  <LinkIcon className="w-6 h-6 bg-transparent text-darkblue rounded-md" />
                }
                showArrow={false}
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label="Gruppe Melden"
                showArrow={false}
                onClick={() => dispatch(showReportModal(groupId, "chat"))}
                icon={
                  <ExclamationTriangleIcon className="w-6 h-6 bg-white text-yellow-500 rounded-md" />
                }
              />
              <InfoButton
                label="Gruppe verlassen"
                showArrow={false}
                icon={
                  <ArrowRightOnRectangleIcon className="w-6 h-6 bg-transparent text-darkblue rounded-md" />
                }
              />
            </div>
            <div className="grid grid-cols-1 gap-2 mt-2">
              <div className="px-2">Mitglieder</div>
              {chat.data.members.map((member) => (
                <button
                  key={member.user.id}
                  className="relative select-none py-1.5 cursor-pointer pl-3 pr-9 text-gray-900 rounded-2xl border border-gray-300 shadow-sm hover:border-darkblue"
                  onClick={() => {
                    if (
                      selectedUsers.find((user) => user.id === member.user.id)
                    ) {
                      setSelectedUsers(
                        selectedUsers.filter(
                          (item) => item.id !== member.user.id,
                        ),
                      );
                    } else {
                      setSelectedUsers([...selectedUsers, member.user]);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        (member.user.avatar !== "" && member.user.avatar) ||
                        `/images/placeholder/${member.user.type}.png`
                      }
                      alt=""
                      className="h-7 w-7 flex-shrink-0 rounded-full"
                    />
                    <span css={[tw`ml-3 line-clamp-1 text-left`]}>
                      {getName(member.user)}
                    </span>
                  </div>
                  <span
                    css={[
                      tw`absolute inset-y-0 right-0 flex items-center pr-4`,
                    ]}
                  >
                    <input
                      type="radio"
                      className="outline-none focus:ring-indigo-600 text-indigo-600"
                      checked={
                        !selectedUsers.find(
                          (user) => user.id === member.user.id,
                        )
                      }
                    />
                  </span>
                </button>
              ))}
            </div>
          </div>
        )}
        {tab === "add_users" && (
          <GroupComboBox
            selectedUsers={newUsers}
            setSelectedUsers={setNewUsers}
            onSave={() => setTab("info")}
            label="Kontakt suchen"
          />
        )}
      </div>
    </div>
  );
};

export default GroupInfo;
