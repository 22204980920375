import React, { PropsWithChildren } from "react";
import tw from "twin.macro";
import { TwStyle } from "twin.macro";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

type Props = {
  onClick?: () => void;
  label: string;
  count?: number;
  color?: TwStyle;
  textColor?: TwStyle;
  hideCount?: boolean;
  small?: boolean;
};

const PostsActionBarItem = (props: PropsWithChildren<Props>) => (
  <div className="group">
    <button
      css={[
        tw`relative h-5 md:h-6 flex flex-row items-center justify-start relative`,
        !props.small && tw`w-fit md:w-[56px]`,
        props.small && tw`w-fit`,
      ]}
      onClick={() => {
        Haptics.impact({ style: ImpactStyle.Light });
        props.onClick && props.onClick();
      }}
    >
      <div
        css={[
          tw`rounded-full p-1 h-6 w-6 relative`,
          props.color,
          props.textColor ?? tw`text-gray-600`,
        ]}
      >
        <div className="h-4 w-4 absolute -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2">
          {props.children}
        </div>
      </div>
      {!props.hideCount && (
        <div
          css={[
            tw`text-center ml-1 text-gray-600 text-xs whitespace-nowrap min-w-[16px]`,
            props.textColor,
          ]}
        >
          {props.count ? props.count : 0}
        </div>
      )}
    </button>
  </div>
);

export default PostsActionBarItem;
