import React, { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import useDebounce from "@/utils/useDebounce";
import api from "@/api/api";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  onSelect: (id: string) => void;
  label: string;
};

const UserComboBox = ({ onSelect, label }: Props) => {
  const [query, setQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState<
    { id: string; name: string } | undefined
  >();
  const debouncedSearchTerm = useDebounce(query, 500);

  const contacts = useQuery<Response>(
    ["contacts", debouncedSearchTerm],
    async () => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=0&q=${debouncedSearchTerm}`,
      );
      return data;
    },
    { enabled: !!debouncedSearchTerm },
  );

  return (
    <Combobox
      as="div"
      value={selectedUser}
      onChange={(user: { id: string; name: string }) => {
        onSelect(user.id);
        setSelectedUser(user);
        setQuery(user.name);
      }}
      className="relative"
    >
      <Combobox.Label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900 z-10">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-2xl border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          value={query}
          tabIndex={-1}
        />
        {!!query && (
          <Combobox.Button
            onClick={() => {
              setQuery("");
            }}
            className="w-5 h-5 absolute right-0 -translate-x-1/2 top-1/2 -translate-y-1/2"
          >
            <XMarkIcon />
          </Combobox.Button>
        )}

        {contacts.isSuccess && contacts.data.data.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {contacts.isSuccess &&
              contacts.data.data.map((contact) => (
                <Combobox.Option
                  key={contact.id}
                  value={{
                    id: contact.id,
                    name: getName(contact),
                    imageUrl:
                      (contact.avatar !== "" && contact.avatar) ||
                      `/images/placeholder/${contact.type}.png`,
                  }}
                  css={[
                    tw`relative cursor-default select-none py-2 pl-3 pr-9`,
                    tw`text-gray-900`,
                  ]}
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={
                            (contact.avatar !== "" && contact.avatar) ||
                            `/images/placeholder/${contact.type}.png`
                          }
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />
                        <span
                          css={[
                            tw`ml-3 truncate`,
                            selected && tw`font-semibold`,
                          ]}
                        >
                          {getName(contact)}
                        </span>
                      </div>

                      {selected && (
                        <span
                          css={[
                            tw`absolute inset-y-0 right-0 flex items-center pr-4`,
                            active ? tw`text-white` : tw`text-indigo-600`,
                          ]}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default UserComboBox;
