import React from "react";
import Panel from "@components/box/Panel";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const HelpContainer = () => {
  const navigate = useNavigate();

  return (
    <Panel mobileBorder={false}>
      <button onClick={() => navigate(-1)} className="lg:hidden">
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <div className="flex flex-col gap-6 text-gray-700">
        <div className="text-2xl font-semibold">
          Schritt-für-Schritt Anleitung - So geht&#39;s
        </div>
        <div className="text-xs">
          Das digitale Zeitalter verbindet Jeden mit Jedem. Dabei wird das
          Netzwerken gerade für die Karriere immer wichtiger. Wer sein Netzwerk
          gezielt aus- und aufbaut, pflegt und aktuell hält, vergrößert seine
          Chancen präsent zu sein und gesehen zu werden. Das Internet verbindet
          die gesamte Welt. Aus dieser einfachen Erkenntnis heraus leitet sich
          unsere Vision ab: Wir wollen der Anbieter mit der smartesten Plattform
          sein und alle Personen und Dienstleister vom Altenpfleger bis hin zum
          Zweiradmechatroniker vernetzen. Wir möchten Dir eine einfache Lösung
          bieten, alles an einem Ort abzuwickeln.
        </div>
        <div className="text-mini font-bold">
          Marktplatz + Jobbörse + Auftragsportal + Newsfeed + Watch + Netzwerk =
          zahlreiche Möglichkeiten sich unter 5 Minuten schnell und effizient zu
          verknüpfen!
        </div>

        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-4">
            <div className="text-2xl">
              Wie erstelle ich ein kostenloses Profil?
            </div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/startseite_1.jpg" />
              <div className="flex flex-col gap-4">
                <div className="text-lg">
                  Kostenloses Profil anlegen bei TRADEFOOX
                </div>
                <div className="text-xs">
                  Registriere Dich bei TRADEFOOX als Privatperson, Verein,
                  Unternehmen, Händler oder Person des öffentlichen Lebens mit
                  nur wenigen Klicks. Der Netzwerkgedanke gewinnt zunehmend an
                  Bedeutung. Sei es im Berufs- oder Privatleben - ein gut
                  geflochtenes Netz aus Kontakten zu Personen und Einrichtungen
                  bildet die Basis für Effektivität und Erfolg.
                </div>
                <ul className="list-disc text-xs">
                  <li>
                    tausch Dich mit Gleichgesinnten aus und bekomm
                    Inspirationen, Ideen und Feedback zu den Themen, die Dich
                    beschäftigen
                  </li>
                  <li>
                    lerne neue Menschen kennen, die Dir helfen können, deine
                    Ideen und Projekte zu unterstützen und baue Deine
                    Geschäftsbeziehungen auf und aus
                  </li>
                  <li>
                    unterstütze andere Menschen und inspiriere diese und bekomme
                    Ideen und Feedback
                  </li>
                  <li>
                    werde sichtbarer – Bekanntheit und Sichtbarkeit ist in der
                    digitalen Zeit eine enorm wichtige Währung
                  </li>
                  <li>
                    profitiere vom positiven Umfeld und dem geballten Know-How
                    des Netzwerks, wenn du ein aktiver Teil bist
                  </li>
                </ul>
                <div className="text-xs">
                  Erstelle bei TRADEFOOX Dein eigenes Profil und gib diesem mit
                  einem Profilbild den passenden Charakter. In Deinem Profil
                  hast Du die Möglichkeit als Privatuser alle relevanten Daten
                  einzupflegen.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-2xl">Registrieren und anmelden</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/registrieren.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div className="font-bold">Registrierung als Privatnutzer:</div>
                <div>
                  Das Nutzerprofil bietet Privatnutzern unzählige Möglichkeiten
                  sich interessant und wirkungsvoll mit seinen Hobbys oder
                  Interessen zu präsentieren, neue Kontakte zu Gleichgesinnten
                  aufzunehmen und sein Netzwerk zu optimieren.
                </div>
                <div>
                  Du möchtest TRADEFOOX als Verein mit allen Vorteilen eines
                  Vereins-Profils genießen? Als Verein kannst Du bei TRADEFOOX
                  kostenlos Deine Vereinsdaten eintragen, Dein Vereinslogo sowie
                  Bilder und Dokumente hochzuladen. Das Nutzerprofil bietet
                  Vereinen auf dem Weg zur Digitalisierung und
                  Öffentlichkeitsarbeit unzählige Möglichkeiten sich interessant
                  und wirkungsvoll mit seinem Betätigungsfeld zu präsentieren.
                </div>
                <div className="font-bold">Registrierung als Unternehmen:</div>
                <div>
                  Als Unternehmen alle Vorteile eines Business-Profils genießen?
                  Du kannst bei TRADEFOOX kostenlos Deine Firmendaten eintragen,
                  Dein Firmenlogo sowie Bilder und Dokumente hochladen. Das
                  Business-Profil bietet Firmen und Gewerbetreibenden unzählige
                  Möglichkeiten sich interessant und wirkungsvoll in seiner
                  Branche oder Interessensgebiet darzustellen oder neue Kunden
                  und Mitarbeiter zu gewinnen.
                </div>
                <div className="font-bold">Registrierung als Händler:</div>
                <div>
                  Du möchtest TRADEFOOX mit allen Vorteilen eines
                  Händler-Profils genießen? Als Händler kannst Du bei TRADEFOOX
                  kostenlos Deine Logo sowie Bilder und Dokumente hochladen. Das
                  Händler-Profil bietet neben der Nutzung des OnlineMarkets und
                  der Auftragsplattform weitere Möglichkeiten Dein Geschäft
                  effektiv zu promoten.
                </div>
                <div className="font-bold">
                  Registrierung als Person des öffentlichen Lebens:
                </div>
                <div>
                  Du möchtest TRADEFOOX mit allen Vorteilen für Dich als Person
                  des öffentlichen Lebens genießen? Bei uns kannst Du für Dich
                  entscheiden was privat und was öffentlich gepostet wird. Du
                  entscheidest welche Anfragen Du annimmst oder ablehnst. Allein
                  Du entscheidest was Du über Dich für Deine Follower preisgeben
                  möchtest. Du kannst außerdem schnell und effektiv Deine
                  Inhalte auf andere Plattformen streuen. Bei uns sparst du Dir
                  jede Menge Zeit und hast alles an einem Ort.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-2xl">Bei TRADEFOOX anmelden</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/anmeldung.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>
                  Die Bestätigungs-E-Mail enthält einen Link. Klicke auf
                  &quot;Account Bestätigen&quot;, um Deine Anmeldung
                  abzuschließen.
                </div>
                <div>
                  Falls Du unsere Bestätigungs-E-Mail nicht in Deinem
                  Posteingang siehst, überprüfe bitte Deinen Spam-Ordner bzw.
                  Papierkorb. Zudem empfehlen wir Dir, unsere E-Mail-Adresse
                  info@tradefoox.com als sichere E-Mail-Adresse in dein
                  Adressbuch und in Deine Anti-Spam-Software einzutragen.
                </div>
                <div>
                  Solltest Du unsere Bestätigungs-Email nach dem Überprüfen
                  Deiner Ordner nicht finden, oder falls Du Schwierigkeiten bei
                  der Bestätigung Deines Kontos hast, kontaktiere bitte unseren
                  Kundenservice.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-2xl">Profil vervollständigen</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/profil_bearbeiten.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>
                  Vervollständige jetzt Deine Informationen in Deinem
                  Nutzerprofil um Dich abzuheben.
                </div>
                <div>
                  Je sorgfältiger Du Dein Profil einrichtest, desto mehr
                  profitierst Du bei TRADEFOOX.
                </div>
                <div className="font-bold">
                  Mit einem vollständigen Profil hast Du folgende Vorteile:
                </div>
                <ul className="list-disc">
                  <li>
                    ein passendes Profilbild sticht dem potentiellen Kunden bei
                    seiner Suche nach einem passenden Dienstleister sofort ins
                    Auge
                  </li>
                  <li>
                    schaffe mit dem Profilbild das Aushängeschild Deines
                    Unternehmens und somit einen hohen Wiedererkennungswert
                  </li>
                  <li>
                    ein vollständig ausgefülltes Profil weckt das Interesse
                    anderer Mitglieder und erleichtert den Schritt zur
                    Kontaktaufnahme - hier kannst Du auch auflisten wo deine
                    Stärken liegen!
                  </li>
                  <li>
                    Zeige, wer Du wirklich bist und was Dich ausmacht - ein gut
                    gefülltes Profil bietet zahlreiche Anknüpfungspunkte und
                    zeigt womöglich schon erste gemeinsame Interessen
                  </li>
                  <li>
                    die Nutzung von Schlagworten (Keywords) und einmaligen
                    Firmenbeschreibungen erhöht die Auffindbarkeit über die
                    Suchmaschinen
                  </li>
                  <li>
                    ein vollständig ausgefülltes Profil ermöglicht es Dir durch
                    unser &quot;Matching&quot; schnell einen neuen Job
                    vorgeschlagen zu bekommen bzw. schneller bei Arbeitgebern
                    aufzutauchen
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-2xl">
              Profilbild, Headerbild und Informationen
            </div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/profilvollstaendigkeit.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>
                  In der oberen Funktionsleiste findest Du von links nach rechts
                  gesehen die folgenden Tradefoox-Funktion:
                </div>
                <div>
                  Messenger - Dein interner Nachrichtenkanal zu Freunden
                </div>
                <div>
                  Als nächstes solltest Du die Allgemeinen und Weiteren
                  Informationen vervollständigen. Sprich direkt mit Deinem
                  Zielpublikum, denn Deine Interessenten möchten wissen, dass
                  sie «am richtigen Ort» sind und dass Du die richtige Person
                  bist, die bei einem spezifischen Problem helfen kann.
                </div>
                <div>
                  Den Fortschritt der Vollständigkeit hast Du auf der linken
                  Menüseite immer im Blick.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-2xl">TRADEFOOX Funktionen</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/funktionen.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>
                  In der oberen Funktionsleiste findest Du von links nach rechts
                  gesehen die folgenden Tradefoox-Funktion:
                </div>
                <ul className="flex flex-col gap-2">
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Messenger:
                    </div>
                    Dein interner Nachrichtenkanal zu Freunden
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Kontakte:
                    </div>
                    Deine Übersicht zu Kontakten, Freunden und Followern
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Firmenverzeichnis:
                    </div>
                    regionale und überregionale Unternehmen im Überblick
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Newsfeed:
                    </div>
                    Dein aktueller Nachrichtenkanal mit Themen aus aller Welt
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Job-Market:
                    </div>
                    Deine Stellen- und Jobbörse mit Bewerbungsportal
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Marketplace:
                    </div>
                    Dein Onlineshop von A-Z
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Auftragsplattform:
                    </div>
                    Suche, finde und vergebe Aufträge
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Watch:
                    </div>
                    Dein Videoportal, Billionen von Menschen können Kurzvideos
                    anschauen und teilen
                  </li>
                  <li>
                    <div className="text-darkcyan font-bold float-left pr-1">
                      Aktuelle Benachrichtigungen:
                    </div>
                    Übersicht zu den letzten Portalaktivitäten
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default HelpContainer;
