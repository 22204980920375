import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  title?: string;
  children: ReactNode;
  padding?: boolean;
  onClose?: () => void;
};

const ButtonPanel = ({ title, children, padding = true, onClose }: Props) => (
  <div
    css={[
      tw`border border-gray-300 bg-white rounded-2xl relative`,
      padding && tw`p-3`,
    ]}
  >
    {title && (
      <div className="flex flex-row items-center gap-2 border-b pb-1 mb-4 w-full text-base font-semibold">
        {title}
      </div>
    )}
    <button onClick={onClose} className="absolute right-2 top-2">
      <XMarkIcon className="text-gray-700 w-5 h-5" />
    </button>
    {children}
  </div>
);

export default ButtonPanel;
