import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import AuthWrapper from "@pages/auth/AuthWrapper";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";
import requestPasswordReset from "@/api/auth/request_reset";
import Panel from "@/components/box/Panel";
import AppLink from "@components/elements/shared/AppLink";

const RequestPasswordResetContainer = () => {
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  const loginMutation = useMutation(["request-reset-password"], {
    mutationFn: () => {
      return requestPasswordReset({
        email,
      });
    },
    onSuccess: () => {
      alert("Password reset link will be delivered to your email");
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel mobileBorder={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="grid grid-flow-row gap-1 text-center justify-center text-black"
      >
        <div className="text-darkblue font-medium text-xl mb-4">
          TRADEFOOX – wir verbinden Euch.
          <br /> Ein Netzwerk voller Möglichkeiten
        </div>
        <Input
          name="email"
          label="E-Mail"
          value={email}
          placeholder="max.mustermann@beispiel.de"
          type="email"
          onChange={(e) => setEmail(e)}
        />
        <Button onClick={() => loginMutation.mutate()}>
          Neues Passwort anfordern
        </Button>
        <div className="text-sm mt-2 text-gray-700">
          Videos, Fotos und Beiträge teilen Jobangebote, Unternehmen und Vereine
          vor Ort finden - alles auf einer Plattform Immer Up to Date: Hier,
          Jetzt, Dort & Immer
        </div>
        <Button
          onClick={() => {
            navigate("/register");
          }}
          variant={Button.Variants.Cyan}
        >
          Neues Konto erstellen
        </Button>
        <AppLink />
      </form>
    </Panel>
  );
};

export default RequestPasswordResetContainer;
