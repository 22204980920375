import React, { useState } from "react";
import isApp from "@/utils/isApp";
import tw from "twin.macro";
import FullScreenImage from "@components/elements/shared/FullScreenImage";

const AppQRCode = () => {
  const [fullscreen, setFullscreen] = useState(false);
  if (isApp) return <></>;

  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <div className="text-xs">
          QR-Code scannen, um die App zu installieren
        </div>
        <button onClick={() => setFullscreen(true)}>
          <img src="/images/app/qr_code.svg" className="w-[100px] h-[100px]" />
        </button>
      </div>
      {fullscreen && (
        <FullScreenImage
          imageUrl="/images/app/qr_code.svg"
          onClose={() => setFullscreen(false)}
          imageCSS={tw`w-[300px] h-[300px]`}
          backgroundCSS={tw`bg-opacity-70`}
        />
      )}
    </>
  );
};

export default AppQRCode;
