import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import api from "@/api/api";
import { useDispatch } from "react-redux";
import { signOut } from "@/state/user/userSlice";

const LogoutContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const logoutMutation = useMutation(["login"], {
    mutationFn: async () => {
      return await api.get("/api/v1/auth/logout");
    },
    onSuccess: () => {
      navigate("/news");
      dispatch(signOut());
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    logoutMutation.mutate();
  }, []);

  return <div>Logout ...</div>;
};

export default LogoutContainer;
