import Select from "@components/elements/input/Select";
import workTimeOptions from "@components/elements/jobs/shared/options/workTimeOptions";
import React from "react";

type Props = {
  value: string;
  onChange: (key: string, value: string) => void;
};

const WorkTimeSelector = ({ value, onChange }: Props) => (
  <Select
    items={workTimeOptions}
    name="type"
    label="Jobart"
    value={value || ""}
    onChange={(e) => onChange("type", e.target.value)}
  />
);

export default WorkTimeSelector;
