import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";
import { NavLink } from "react-router-dom";
import Button from "@components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = {
  user: User;
  type: "sent" | "received";
  key?: string;
};

const ContactRequestsItem = ({ user, type }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const accept = useMutation([`contacts-request-accept-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/connect`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + type],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const reject = useMutation([`contacts-request-reject-${user.id}`], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/contacts/requests/${user.id}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + type],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel>
      <div>
        <div className="text-sm flex flex-col w-full gap-2">
          <UserWidget
            user={user}
            showName={true}
            follow={false}
            verified={true}
          />

          <NavLink
            to={"/profile/" + user.id}
            className="flex flex-col gap-1 w-full"
          >
            <div className="flex flex-row justify-between text-gray-600 text-xs">
              <NavLink to="/contacts">
                <div className="flex flex-col items-center gap-x-1">
                  <div>{t("main.followers.title")}</div>
                  <div>{user.follower_count}</div>
                </div>
              </NavLink>
              <NavLink to="/contacts">
                <div className="flex flex-col items-center gap-x-1">
                  <div>{t("main.following.title")}</div>
                  <div>{user.following_count}</div>
                </div>
              </NavLink>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.likes.title")}</div>
                <div>{user.like_count}</div>
              </div>
            </div>
          </NavLink>
          {type === "sent" ? (
            <Button
              onClick={() => accept.mutate()}
              size={Button.Sizes.Small}
              variant={Button.Variants.Secondary}
            >
              <div className="inline-flex gap-1 items-center">
                <CheckIcon className="w-3 h-3 border-2 border-green-700 text-green-400 stroke-[4px]" />
                Austehend
              </div>
            </Button>
          ) : (
            <div className="flex flex-row justify-evenly">
              <button onClick={() => reject.mutate()}>
                <XCircleIcon className="w-7 h-7 text-red-700" />
              </button>
              <button onClick={() => accept.mutate()}>
                <CheckCircleIcon className="w-7 h-7  text-green-700" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default ContactRequestsItem;
