import React from "react";
import Tab from "@/components/menu/Tab";
import Profiles from "@/pages/discover/tabcontent/Profiles";
import News from "@/pages/discover/tabcontent/News";
import Watch from "@/pages/discover/tabcontent/Watch";
import Ads from "@/pages/discover/tabcontent/Ads";
import Assignments from "@/pages/discover/tabcontent/Assignments";
import Jobs from "@/pages/discover/tabcontent/Jobs";
import Hashtags from "@/pages/discover/tabcontent/Hashtags";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import { useAppSelector } from "@/state/hooks";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const tabs = (t: TFunction) => [
  {
    label: t("main.bookmarks.tabs.hashtags"),
    key: "hashtags",
    url: "hashtags",
  },
  { label: t("main.bookmarks.tabs.watch"), key: "watch", url: "watch" },
  {
    label: t("main.bookmarks.tabs.advertisements"),
    key: "news",
    url: "news",
  },
  {
    label: t("main.bookmarks.tabs.profiles"),
    key: "profiles",
    url: "profile/",
  },
  { label: t("main.bookmarks.tabs.jobs"), key: "jobs", url: "jobs" },
  {
    label: t("main.bookmarks.tabs.advertisements"),
    key: "assignments",
    url: "inserate",
  },
  {
    label: t("main.bookmarks.tabs.marketplace"),
    key: "ads",
    url: "marketplace",
  },
];

type Props = {
  tab?: string;
  subTab?: string;
};

const DiscoverContainer = ({ tab = "hashtags", subTab }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  if (!user) return null;

  return (
    <div className="flex flex-col gap-2">
      <TabMenu>
        {tabs(t).map((tab: any) => {
          return (
            <>
              <TabLink to={"/entdecken/" + tab.url}>{tab.label}</TabLink>
            </>
          );
        })}
      </TabMenu>

      <Tab isActive={tab === "profiles"}>
        <Profiles />
      </Tab>

      <Tab isActive={tab === "news"}>
        <News />
      </Tab>

      <Tab isActive={tab === "watch"}>
        <Watch />
      </Tab>

      <Tab isActive={tab === "jobs"}>
        <Jobs />
      </Tab>

      <Tab isActive={tab === "assignments"}>
        <Assignments />
      </Tab>

      <Tab isActive={tab === "ads"}>
        <Ads />
      </Tab>

      <Tab isActive={tab === "hashtags"}>
        <Hashtags tab={subTab} />
      </Tab>
    </div>
  );
};

export default DiscoverContainer;
