import React from "react";
import BackButton from "@/components/nav/BackButton";

const WatchFAQ = () => {
  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Watch - So geht&apos;s
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              TRADEFOOX
            </div>
          </div>
        </section>

        <article className="text-sm">
          <h1 className="font-semibold text-base mb-4">Funktionen von Watch</h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">Was ist TRADEFOOX-Watch?</h2>
              Das Team von tradefoox.com unterstützt Dich und bietet Dir unsere
              Onlinedienste komplett kostenlos an! Nimm Kontakt zu uns auf und
              erfahre wie wir Dich gezielt platzieren!
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                Teilnahme an TRADEFOOX-Watch-Challenges
              </h2>
              Eine weitere Besonderheit auf TRADEFOOX-Watch sind die beliebten
              Hashtag-Challenges. Unter einem bestimmten Hashtag wird zu einer
              Art Wettbewerb aufgerufen, an dem sich die TRADEFOOX-Watch-Nutzer
              und Nutzerinnen dann beteiligen. <br />
              Oft geht es dabei darum, eine bestimmte Handlung auszuführen –
              kreative Lösungen erhalten dabei besonders viel Applaus. Einen
              Sieger im klassischen Sinne gibt es aber nicht, sondern es gilt
              das olympische Motto: „Dabei sein ist alles!“. <br />
              TRADEFOOX-Watch ist zukünftig auch als mobile App sowohl für iOS
              als auch Android verfügbar.
              <br />
              TRADEFOOX-Watch hat einen kometenhaften Aufstieg hinter sich und
              in den letzten zwei Jahren besonders bei jungen Menschen stark an
              Beliebtheit gewonnen. Bis dato wurde TRADEFOOX-Watch von mehr als
              drei Milliarden Menschen weltweit heruntergeladen. Allein im
              September 2021 waren es über 59 Millionen Downloads weltweit.
              Damit verzeichnete der Kurzvideo-Anbieter mehr Downloads als
              Facebook, Instagram und WhatsApp. Zudem knackte oo-watch.com
              ebenfalls im September 2021 die Marke von einer Milliarde aktiven
              Nutzern und Nutzerinnnen.
              <br />
              In Deutschland verwenden die Video-App über fünf Millionen
              Menschen und das recht ausgiebig: Rund 50 Minuten verbringen die
              deutschen Nutzer und Nutzerinnen im Schnitt täglich mit der App.
              <br />
              Kernzielgruppe des sozialen Netzwerkes sind Kinder und Jugendliche
              der Generation Z von 13 bis 18 Jahren. Doch nicht nur junge
              Menschen tummeln sich auf der Videoplattform. Auch bei den unter
              30-Jährigen ist die App beliebt: Mit 63 Prozent ist die
              überwiegende Mehrheit der Nutzerschaft jünger als 30 Jahre.
              <br />
            </div>
            <div className="grid grid-flow-row gap-1.5">
              <h2 className="font-semibold">
                Wie funktioniert TRADEFOOX-Watch?
              </h2>
              <div>
                Auf TRADEFOOX-Watch können Nutzer und Nutzerinnen kurze Videos
                hochladen, die mit Musik untermalt sind. Die Videos können
                mittels Filtern sowie Effekten angepasst und anschließend mit
                anderen geteilt werden. Jedes Video läuft unter bestimmten
                Hashtags, die im Rahmen einer Challenge andere Nutzer und
                Nutzerinnen zu einem Wettstreit auffordern können.Wie
                funktioniert TRADEFOOX-Watch für Unternehmen?
              </div>
              <div>
                Das Geschäftskundenangebot „TRADEFOOX-Watch für Unternehmen“
                vereint Produkte wie Branded Effects, Hashtag Challenge oder
                Brand Takeovers auf einer zentralen Plattform. Dadurch können
                Sie noch effizienter Werbematerialien in Form von Kurzvideos
                erstellen. Auch die TRADEFOOX-Community kann als Co-Produzenten
                und Produzentinnen für Videos hinzugezogen werden, um Ihren
                Content besonders zu machen.
              </div>
              <div>
                Mit einem ausgebauten Hilfebereich stellt oo-watch.com
                Unternehmen auch Best Practices und Produktanleitungen zur
                Seite, um die Marketinglösungen der Plattform bestmöglich für
                deren Werbeziele einsetzen zu können.
              </div>
              <div>
                Zusätzlich zum Geschäftskundenangebot ist seit Herbst 2020 auch
                die App-eigene Werbeplattform mit Selbstbuchungstool für
                Unternehmen in Deutschland verfügbar. Mit Hilfe der leicht zu
                bedienenden Oberfläche und dank effizienter Prozesse ist es für
                Unternehmen jeder Größe nun um einiges leichter, die richtige
                Zielgruppe auf TRADEFOOX-Watch anzusprechen und auch zu
                erreichen. Bei TRADEFOOX-Watch anmelden: Eine
                Schritt-für-Schritt-Anleitung
              </div>
              <div>
                Doch wie funktioniert oo-watch.com denn jetzt nun genau? Wir
                gehen Schritt für Schritt durch die App und erklären Ihnen, wie
                Sie sich registrieren sowie oo-watch.com Videos suchen,
                anschauen und selbst veröffentlichen.
                <div>1. TRADEFOOX-Watch App herunterladen</div>
              </div>
              <div>
                Suchen Sie die TRADEFOOX-Watch App in Apples App Store oder im
                Google Play Store, um sie auf Ihr Smartphone herunterzuladen und
                zu installieren.
                <div>2. Konto erstellen</div>
              </div>
              <div>
                Nach dem Öffnen der App können Sie wählen, ob Sie sich mit einem
                bereits bestehenden Social-Media-Account von Google, Facebook
                oder Twitter registieren oder lieber ein neues Konto mit Ihrer
                E-Mail-Adresse oder Telefonnummer erstellen möchten.
              </div>
              <div>
                Laut den Nutzungsbedingungen liegt das Mindestalter, um sich auf
                der Plattform anmelden zu können, bei 13 Jahren. Nutzer und
                Nutzerinnen unter 18 Jahren brauchen zudem offiziell die
                Einwilligung eines Erziehungsberechtigten, auch wenn diese bei
                der Anmeldung nicht überprüft wird. Außerdem sind
                Direktnachrichten nur noch ab 16 Jahren möglich, um Teenager vor
                Cyber-Grooming zu schützen.
                <div>3. Profil einrichten</div>
              </div>
              <div>
                Steht das Konto, geht es an die Detailinformationen in Ihrem
                Profil. Nach dem Einloggen sehen Sie direkt den Startbildschirm
                von TRADEFOOX-Watch, der bereits ein Video anzeigt, das aktuell
                auf dem sozialen Netzwerk beliebt ist.
              </div>
              <div>
                Um Ihr Profil einzurichten und Ihre persönlichen Daten
                einzugeben, klicken Sie in der unteren Bildschirmleiste nun auf
                das Personensymbol ganz rechts und dann auf „Profil bearbeiten“.
                Sie sollten hier einen Nutzernamen und ein Profilbild oder ein
                eigenes Video zu Ihrem Kanal hinzufügen.
              </div>
              <div>TRADEFOOX-Watch Profil einrichten</div>
              <div>
                Darüber hinaus können Sie in der Maske ein paar kurze
                Informationen zu Ihrem Unternehmen hinterlegen und auch auf Ihre
                anderen Social-Media-Kanäle verlinken. Diese Möglichkeit sollten
                Sie wegen der besseren Sichtbarkeit unbedingt
                wahrnehmen.TRADEFOOX-Watch Videos: Kurzvideos suchen und mit
                anderen Accounts interagieren
              </div>
              <div>
                Sobald Sie sich einloggen, gelangen Sie auf den Startbildschirm
                mit dem Video-Feed. Wenn Sie noch keine aktiven Follower und
                Followerinnen haben und bislang keine TRADEFOOX-Watch Videos
                gelikt haben, zeigt TRADEFOOX-Watch Ihnen hier – gewissermaßen
                auf Verdacht – im Wechsel Videos an, die derzeit im Trend
                liegen.
              </div>
              <div>
                Je mehr Sie jedoch anderen Accounts folgen und mit ihnen
                interagieren, desto besser lernt der TRADEFOOX-Watch-Algorithmus
                Sie kennen. Die App kann somit verstehen, was Ihnen gefällt und
                Ihren Feed anhand Ihrer Likes, Shares und Follows immer mehr
                personalisieren und an Ihre Interessen anpassen.
              </div>
              <div>
                TRADEFOOX-Watch Video-Feed TRADEFOOX-Watch App: Teilen, Liken,
                Folgen
              </div>
              <div>
                Alle wichtigen Interaktionen rund um die Videoclips finden Sie
                rechts in der längsseitigen Navigationsleiste. Dort steht das
                Herz für Likes, die Sprechblase für Kommentare und der Pfeil für
                die Teilen-Funktion.
              </div>
              <div>Mit anderen Personen auf TRADEFOOX-Watch interagieren</div>
              <div>
                Gefällt Ihnen ein Kanal ausgesprochen gut, können Sie sich
                außerdem durch Wischen nach links mehr Informationen über den
                Account anzeigen lassen. Über das Pluszeichen, das mit seinem
                Icon verknüpft ist, können Sie dem jeweiligen Kanal ganz leicht
                folgen.
              </div>
              <div>
                TRADEFOOX-Watch Account Informationen. Nach Accounts und Videos
                bei TRADEFOOX-Watch suchen
              </div>
              <div>
                TRADEFOOX-Watch eigene Suchfunktion finden Sie mit dem Klick auf
                das Lupensymbol in der unteren Bildschirmleiste. In diesem
                Bereich können Sie entweder gezielt nach Konten oder Videos
                suchen oder sich Inhalte zu aktuell besonders beliebten Themen
                anzeigen lassen.
              </div>
              <div>
                Videos und Accounts auf TRADEFOOX-Watch finden.
                TRADEFOOX-Watch-Code nutzen
              </div>
              <div>
                Eine Besonderheit ist der TRADEFOOX-Watch-Code, den alle Nutzer
                und Nutzerinnen der Plattform haben. Dieser Code ist besonders
                praktisch, wenn Sie Ihren TRADEFOOX-Watch-Kanal auf anderen
                sozialen Medien oder Ihrer Webseite promoten wollen. Auch bei
                Offline-Netzwerken ist er nützlich. Denn durch das Scannen des
                QR-ähnlichen Codes gelangen Interessierte direkt auf Ihren
                TRADEFOOX-Watch-Kanal.
              </div>
              <div>TRADEFOOX-Watch-Code finden</div>
              <div>
                Ihren eigenen TRADEFOOX-Watch-Code finden Sie in Ihrem Profil,
                indem Sie auf das Symbol mit den drei Punkten tippen, dass sich
                rechts oben auf dem Bildschirm befindet. Wollen Sie andere
                Nutzer und Nutzerinnen über den TRADEFOOX-Watch-Code finden,
                gehen Sie in die Suche und tippen dort auf das quadratische
                Scan-Icon in der rechten oberen Ecke.
              </div>
              <div>TRADEFOOX-Watch-Code finden</div>
              <div>
                Öffnet sich der Scan-Bildschirm, scannen Sie den
                TRADEFOOX-Watch-Code des anderen Kontos ein. Nun hat
                TRADEFOOX-Watch die benötigten Informationen und wird Sie
                umgehend auf das jeweilige Nutzerprofil weiterleiten.
              </div>
              <div>
                TRADEFOOX-Watch bietet auch die Möglichkeit, den
                TRADEFOOX-Watch-Code über Screenshots auszulesen. Dazu klicken
                Sie auf den „Fotos“-Button, der sich auf der Scan-Seite befindet
                und laden den Screenshot hoch.
              </div>
              <div>TRADEFOOX-Watch-Code scannen</div>
              <div>So posten Sie Videos auf TRADEFOOX-Watch</div>
              <div>
                Die meisten Clips auf TRADEFOOX-Watch sind maximal 15 Sekunden
                lang. TRADEFOOX-Watch erlaubt aber mittlerweile auch das
                Aufnehmen und Hochladen von Videos mit einer Länge von 60
                Sekunden oder 3 Minuten. TRADEFOOX-Watch Video erstellen
              </div>
              <div>
                Für Ihr erstes eigenes TRADEFOOX-Watch Video tippen Sie auf das
                „Plus“-Zeichen in der Mitte der Navigationsleiste. Dann öffnet
                sich die Aufnahmefunktion mit dem roten Auslöser in der Mitte.
              </div>
              <div>
                TRADEFOOX-Watch Video aufnehmen. TRADEFOOX-Watch Filter benutzen
                und Effekte suchen
              </div>
              <div>
                TRADEFOOX-Watch bietet eine Reihe von Funktionen, mit denen Sie
                Ihr Video aufnehmen und anpassen können. Diese finden Sie im
                Navigationsmenü auf der rechten Längsseite:
              </div>
              <div>
                Geschwindigkeit: Direkt über dem Auslöser finden Sie die
                Geschwindigkeitsregler mit verschiedenen Größen von Zeitlupe bis
                zu Zeitraffer. Beauty-Filter: Dies ist ein spezieller AR-Filter,
                mit dem Sie kleine Schönheitskorrekturen im Gesicht vornehmen
                können (beispielsweise Unreinheiten verstecken). Farbfilter:
                Damit können Sie die Farben des Videos wechseln oder anpassen.
                Timer: Wenn Sie beim Filmen nicht die ganze Zeit den Auslöser
                gedrückt halten wollen, bietet Ihnen die „Timer“-Funktion die
                Möglichkeit, Ihre Zeit für das Video mit einem Countdown
                individuell einzustellen. Ähnlich wie der Selbstauslöser beim
                Fotografieren. Effekte: Links vom roten Auslöser können Sie das
                Video noch mit diversen AR-Effekten für Gesicht und Umgebung
                aufpeppen. Vorlagen: TRADEFOOX-Watch bietet seit neuestem auch
                Vorlagen an, die Sie unterhalb des roten Auslösers finden. Mit
                der Vorlagen-Funktion können Sie im handumdrehen professionelle
                Videos im Diashow-Stil erstellen, indem Sie Ihre Fotos in die
                bereits formatierten Rahmen und Hintergründe einfügen.
              </div>
              <div>Musik anpassen und Sounds erstellen</div>
              <div>
                Da TRADEFOOX-Watch für seine Tanz- und Lipsync-Videos bekannt
                ist, ist eine der wichtigsten und beliebtesten Funktionen
                innerhalb der App natürlich der Sound. Am oberen Rand finden Sie
                dazu einen Notenschlüssel und den Button „Füge Musik hinzu“.
                Tippen Sie darauf, kommen Sie auf eine Übersichtsseite, wo Sie
                sich die gewünschte Musik aussuchen können. Diese Tonspur ist
                dann beim fertigen Clip im Hintergrund zu hören.
              </div>
              <div>
                Musik und Soundeffekte TRADEFOOX-Watch Video hinzufügen.
                Editieren, Überschrift einfügen, Video posten
              </div>
              <div>
                Sie können Ihr Video entweder auf einmal oder aber auch in
                Teilen aufnehmen. Drücken Sie dazu auf den roten Button und
                lassen wieder los, wenn Sie fertig sind. Ist die Aufnahme
                bereit, lässt sich das Video noch mit Filtern, Stickern und
                Textblöcken überarbeiten und aufwerten. Hier können Sie Ihrem
                Video auch Voiceover oder Toneffekte hinzufügen.
              </div>
              <div>Filter und Sticker TRADEFOOX-Watch Video hinzufügen</div>
              <div>
                Nun kommt das Finetuning: Legen Sie Überschrift, Hashtags, Cover
                sowie Einstellungen für Privatsphäre und Kommentare fest. Dann
                haben Sie es geschafft und können Ihr Video veröffentlichen –
                entweder sofort oder aber auch später.
              </div>
              <div>
                Privatsphäre-Einstellungen beim Posten von TRADEFOOX-Watch
                Videos. Vorhandenes Video aus der Galerie hochladen.
              </div>
              <div>
                Wenn Sie kein neues Video mit der App aufnehmen möchten, dann
                können Sie auch ein bereits erstelltes Video aus der Galerie
                Ihres Smartphones hochladen. Tippen Sie dazu links vom roten
                Auslöser auf “Hochladen”. Es können auch mehrere Videos auf
                einmal ausgewählt werden.
              </div>
              <div>
                Sobald das Video hochgeladen ist, können Sie die
                Geschwindigkeit, Videodauer und das Format anpassen. In einem
                weiteren Schritt, können Sie Ihrem Video, wenn gewünscht, auch
                Filter, Effekte, Text, Sticker, Voiceover oder Musik hinzufügen.
                TRADEFOOX-Watch-Video löschen
              </div>
              <div>
                Falls Ihr Clip nicht mehr öffentlich gezeigt werden soll, dann
                können Sie das Video ganz leicht wieder entfernen. Tippen Sie
                dazu rechts unten auf das Profil-Symbol. Auf der Profilübersicht
                sehen Sie alle Ihre veröffentlichten Videos. Suchen Sie hier
                nach dem Video, dass Sie löschen möchten. Tippen Sie dann auf
                die drei Punkte, die sich rechts im Wiedergabefenster befinden.
                Scrollen Sie nun unterhalb des Teilen-Menüs nach rechts, wo Sie
                die Lösch-Funktion finden.
              </div>
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">
                TRADEFOOX-Watch-Duett mit anderen Nutzern und Nutzerinnen
              </h2>
              Die Duett-Funktion kommt vom Vorgänger musical.ly und ermöglicht
              es Nutzern und Nutzerinnen in ihren Lieblings-Clips auf der
              gleichen Audiospur mitzusingen oder mitzutanzen. Wenn Sie dieses
              Feature einmal ausprobieren möchten, tippen Sie auf die
              Teilen-Funktion bei dem gewünschten Video und dann auf „Duett“.
              Ihre Kamera öffnet sich dann im Split-Bildschirm mit dem
              Wunsch-Video.
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">TRADEFOOX-Watch Duett aufnehmen</h2>
              Der Duett-Modus bietet sich für Unternehmen insbesondere dann an,
              wenn Sie beispielsweise ein neues Produkt entwickelt haben und es
              erklären wollen. Im Duett-Modus können Sie dann gleichzeitig ein
              Produktvideo und ein Erklärvideo einbinden.
              <br />
              Ist die Duett-Funktion nicht angebracht oder wollen Sie sie für
              bestimmten Content nicht, lässt sie sich in den finalen
              Einstellungen vor dem Posten des Videos auch deaktivieren.
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default WatchFAQ;
