import React, { Fragment } from "react";
import Input from "@components/elements/input/Input";
import Select from "@components/elements/input/Select";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

type selectType = {
  name: string;
  value: string;
  id?: number;
};

type Props = {
  label?: string;
  values?: Array<selectType>;
  types?: Array<selectType>;
  name: string;
  placeholder?: string;
  onChange: (array: Array<selectType>) => void;
};

const HashMapList = ({
  label,
  onChange,
  name,
  values = [],
  types = [],
  placeholder = "",
}: Props) => {
  /**
   * Remove item
   * @param index
   */
  const removeItem = (index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues.splice(index, 1);
    onChange(newValues);
  };

  /**
   * Adds item
   * @param item
   */
  const addItem = (item: selectType) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues.push(item);
    onChange(newValues);
  };

  /**
   * Changes item key
   * @param k
   * @param index
   */
  const changeItemKey = (k: string, index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues[index].name = k;
    onChange(newValues);
  };

  /**
   * Changes item value
   * @param v
   * @param index
   */
  const changeItemValue = (v: string, index: number) => {
    const newValues: Array<selectType> = JSON.parse(JSON.stringify(values));
    newValues[index].value = v;
    onChange(newValues);
  };

  return (
    <div className="mt-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="grid grid-cols-[200px,auto,20px] items-center gap-x-2 gap-y-2 rounded-2xl border border-gray-300 shadow-sm sm:text-sm py-2 pl-2 pr-4">
        {values.map((item: selectType, index: number) => {
          return (
            <div key={item.id}>
              <Select
                items={types}
                name="type"
                onChange={(e) => changeItemKey(e.target.value, index)}
                value={item.name}
              />
              <Input
                placeholder={placeholder}
                name=""
                onChange={(e) => changeItemValue(e, index)}
              />
              <button
                onClick={() => {
                  removeItem(index);
                }}
              >
                <MinusCircleIcon className="w-6 h-6 text-red-600" />
              </button>
            </div>
          );
        })}
        <Fragment>
          <div className="col-span-2 text-center">Neuen Link hinzufügen</div>
          <button
            onClick={() => {
              addItem({
                name: types[0].name,
                value: types[0].value,
                id: Date.now(),
              });
            }}
          >
            <PlusCircleIcon className="w-6 h-6 text-green-600" />
          </button>
        </Fragment>
      </div>
      <Input name={name} type="hidden" value={JSON.stringify(values)} />
    </div>
  );
};

export default HashMapList;
