import React, { useState } from "react";
import Panel from "@/components/box/Panel";
import Input from "@/components/elements/input/Input";
import Select from "@/components/elements/input/Select";
import Tab from "@/components/menu/Tab";
import TabFilter from "@/components/menu/TabFilter";
import Tags from "@/components/elements/input/Tags";
import Button from "@/components/elements/input/Button";
import ContactsEditor from "@/components/elements/form/ContactsEditor";
import HashMapList from "@/components/elements/form/HashMapList";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

function GroupsCreateContainer({ onClose }: Props) {
  const [filter, setFilter] = useState<string>("basics");
  const { t } = useTranslation();
  const statusOptions = [
    { name: "offen", value: "0" },
    { name: "geschlossen", value: "1" },
    { name: "privat", value: "2" },
  ];

  const startSiteOptions = [
    { name: "Newsfeed", value: "news" },
    { name: "Marketplace", value: "marketplace" },
    { name: "Watch", value: "watch" },
    { name: "Inserate", value: "inserate" },
    { name: "Messenger", value: "messenger" },
    { name: "Kontakte", value: "contacts" },
    { name: "Gruppen", value: "groups" },
    { name: "Jobs", value: "jobs" },
  ];

  const socialMediaSites = [
    { name: "Facebook", value: "facebook" },
    { name: "Instagram", value: "instagram" },
    { name: "Youtube", value: "youtube" },
    { name: "Xing", value: "xing" },
    { name: "LinkedIn", value: "linkedIn" },
    { name: "Twitter", value: "twitter" },
  ];

  const tabs = [
    {
      name: t("main.header.links.groups.tabs.create.tabs.info.title"),
      key: "basics",
    },
    {
      name: t("main.header.links.groups.tabs.create.tabs.description"),
      key: "details",
    },
    {
      name: t("main.header.links.groups.tabs.create.tabs.contact"),
      key: "contact",
    },
    {
      name: t("main.header.links.groups.tabs.create.tabs.socialMedia"),
      key: "social",
    },
  ];

  const { user } = useAppSelector((state) => state.user);
  if (!user) return <span>{t("main.loading")}</span>;

  /**
   * Tab switching
   * @param direction
   */
  const changeTab = (direction: number) => {
    {
      const tabIndex =
        tabs.findIndex((element) => {
          return element.key === filter;
        }) + direction;
      if (typeof tabs[tabIndex] != "undefined") {
        setFilter(tabs[tabIndex].key);
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <button onClick={onClose}>
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <TabFilter
        value={filter}
        options={tabs}
        onChange={(value) => setFilter(value)}
        subTab={true}
      />
      <Panel>
        <Tab isActive={filter === "basics"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="groupname"
                placeholder={t("main.groupsInfoForm.groupName")}
                label={t("main.groupsInfoForm.groupName")}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="country"
                placeholder={t("main.groupsInfoForm.coutry")}
                label={t("main.groupsInfoForm.coutry")}
              />
              <Input
                name="city"
                placeholder={t("main.groupsInfoForm.zipCity")}
                label={t("main.groupsInfoForm.zipCity")}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="phone"
                placeholder={t("main.groupsInfoForm.phone")}
                label={t("main.groupsInfoForm.phone")}
              />
              <Input
                name="fax"
                placeholder={t("main.groupsInfoForm.fax")}
                label={t("main.groupsInfoForm.fax")}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="website"
                placeholder={t("main.groupsInfoForm.website")}
                label={t("main.groupsInfoForm.website")}
              />
              <Input
                name="email"
                placeholder={t("main.groupsInfoForm.email")}
                label={t("main.groupsInfoForm.email")}
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Select
                items={statusOptions}
                name="status"
                label={t("main.groupsInfoForm.groupType")}
              />
              <Select
                items={startSiteOptions}
                name="startsite"
                label={t("main.groupsInfoForm.homePage")}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <Input
                type="file"
                name="profileimage"
                label={t("main.groupsInfoForm.uploadProfilePicture")}
              />
              <Input
                type="file"
                name="headerimage"
                label={t("main.groupsInfoForm.updateHeaderImage")}
              />
            </div>
          </div>
        </Tab>

        <Tab isActive={filter === "details"}>
          <div className="grid grid-flow-row gap-x-4 gap-y-2">
            <div className="text-sm">
              {t("main.groupsInfoForm.description")}
              <textarea
                name="description"
                className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
              />
            </div>
            <Input
              name="specialization"
              placeholder={t("main.groupsInfoForm.subjectArea")}
            />
            <Input
              name="shortdescription"
              placeholder={t("main.groupsInfoForm.shortDescription")}
            />
            <Tags
              label={t("main.groupsInfoForm.industry")}
              name="branch"
              placeholder={t("main.groupsInfoForm.industry")}
            />

            <Tags
              label={t("main.groupsInfoForm.keywords")}
              name="keywords"
              placeholder={t("main.groupsInfoForm.keywords")}
            />
          </div>
        </Tab>

        <Tab isActive={filter === "contact"}>
          <ContactsEditor
            name="contactseditor"
            onChange={() => {
              return;
            }}
            contacts={[]}
          />

          <div className="font-semibold my-2">
            {t("main.groupsInfoForm.supervisorGroupsManager")}
          </div>
          <ContactsEditor
            name="contactseditor"
            onChange={() => {
              return;
            }}
          />
        </Tab>

        <Tab isActive={filter === "social"}>
          <HashMapList
            name="socialmedia"
            onChange={() => {
              return;
            }}
            types={socialMediaSites}
            values={[]}
            placeholder="Link zur Seite"
          />
        </Tab>

        <Tab stayVisible={true}>
          <div className="flex flex-wrap sm:flex-nowrap flex-row sm:gap-4 justify-end">
            <Button
              variant={Button.Variants.Transparent}
              size={Button.Sizes.Small}
              onClick={() => changeTab(-1)}
            >
              {t("main.groupsInfoForm.buttons.back")}
            </Button>
            <Button
              variant={Button.Variants.Transparent}
              size={Button.Sizes.Small}
              onClick={() => changeTab(1)}
            >
              {t("main.groupsInfoForm.buttons.further")}
            </Button>
            <Button
              variant={Button.Variants.Transparent}
              size={Button.Sizes.Small}
              onClick={() => {
                return;
              }}
            >
              {t("main.groupsInfoForm.buttons.preview")}
            </Button>
            <Button
              size={Button.Sizes.Small}
              onClick={() => {
                return;
              }}
            >
              {t("main.groupsInfoForm.buttons.save")}
            </Button>
          </div>
        </Tab>
      </Panel>
    </form>
  );
}

export default GroupsCreateContainer;
