import UserWidget from "@components/elements/UserWidget";
import Panel from "@components/box/Panel";
import React from "react";
import { User } from "@/utils/user";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  users?: Array<User>;
  link: string;
  key?: string;
};

const SideBarUserElement = ({ title, users, link = "" }: Props) => {
  const { t } = useTranslation();
  return (
    <Panel title={title}>
      <div className="grid grid-cols-1 gap-2">
        {users &&
          users.map((user) => (
            <UserWidget
              key={user.id}
              user={user}
              showName={true}
              follow={false}
              sideName={true}
            />
          ))}
        <NavLink
          to={link}
          className="rounded-full bg-gray-200 hover:bg-gray-300 mt-3 w-full py-1 text-xs text-center"
        >
          {t("buttons.showMore")}
        </NavLink>
      </div>
    </Panel>
  );
};

export default SideBarUserElement;
