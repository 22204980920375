import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";

const Unsubscripe = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <BackButton />
      <form>
        Einfach Deine E-Mail-Adresse eintragen und mit abmelden bestätigen.
        <Input
          type="email"
          name="email"
          placeholder="E-Mail*"
          value={email}
          required
          onChange={(e) => {
            setEmail(e);
          }}
        />
        <Checkbox
          name="agb"
          required
          checked={privacyCheck}
          onChange={(e) => setPrivacyCheck(e)}
        >
          Ich habe die{" "}
          <NavLink to="/privacy" target="_blank">
            Datenschutzerklärung
          </NavLink>{" "}
          gelesen und akzeptiert
        </Checkbox>
        <Button submit>Abmelden</Button>
      </form>
    </>
  );
};

export default Unsubscripe;
