import React from "react";
import UserWidget from "@components/elements/UserWidget";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

const NavUserElement = () => {
  const { user } = useAppSelector((state) => state.user);
  const loginModal = useLoginModal();
  const { t } = useTranslation();

  if (user) {
    return (
      <NavLink to={`/profile/${user ? user.id : ""}`}>
        {({ isActive }) => (
          <div
            css={[
              tw`relative flex flex-col cursor-pointer items-center py-0`,
              isActive && tw`border-b-2 border-darkblue`,
            ]}
          >
            <div css={[tw`w-7 h-7 text-darkblue`]}>
              <UserWidget size={28} user={user} />
            </div>
            <span
              css={[
                tw`hidden md:block text-xs text-center font-semibold pb-0.5`,
                isActive
                  ? tw`text-darkblue opacity-100`
                  : tw`text-gray-600 opacity-60`,
              ]}
            >
              {t("main.header.links.profileView")}
            </span>
          </div>
        )}
      </NavLink>
    );
  }

  return (
    <NavLink
      className="group"
      to="/login"
      css={[tw`relative flex flex-col cursor-pointer items-center py-0`]}
    >
      <div
        css={[
          tw`w-7 h-7 text-darkblue opacity-60 group-hover:opacity-100 opacity-60`,
        ]}
      >
        <UserCircleIcon className="heroicon-stroke-w-1.2" />
      </div>
      <span
        css={[
          tw`hidden md:block text-xs text-center font-semibold`,
          tw`text-gray-600 opacity-60`,
        ]}
      >
        Login
      </span>
    </NavLink>
  );
};

export default NavUserElement;
