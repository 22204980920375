import React, { Fragment } from "react";
import {
  CheckIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  title?: string;
  description?: string;
  city?: string;
  price?: number;
  country?: string;
  p18?: boolean;
  created_at?: string;
  start_at?: string;
  end_at?: string;
  files?: string[];
  showButtons?: boolean;
  minimized?: boolean;
  showStats?: boolean;
  key?: string;
};

const Assignment = ({
  id = "0",
  title = "Kein Titel",
  description = "",
  city = "",
  price = 0,
  country = "",
  p18 = false,
  created_at = "",
  start_at = "",
  end_at = "",
  files = [],
  showButtons = false,
  minimized = false,
  showStats = true,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${
        minimized
          ? "w-[calc(18%-8px)] max-w-[75px] shrink-0"
          : "shadow bg-white rounded-2xl p-3"
      }`}
    >
      <div className="text-sm">
        <NavLink to={"/inserate/" + id}>
          <img
            src="/placeholder.png"
            className={`${minimized && "rounded-lg"}`}
          />
          {!minimized && (
            <Fragment>
              <div className="text-sm line-clamp-1 text-center text-ellipsis overflow-x-hidden">
                {title}
              </div>
              <div className="text-center">{price.toFixed(2)} €</div>
              <div className="flex flex-row whitespace-nowrap text-ellipsis justify-between w-full text-gray-600 text-mini">
                <div>{city}</div>
                <div>
                  Ab:{" "}
                  {new Date(start_at).toLocaleString("de-DE", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </div>
              </div>
              {showStats && (
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row justify-between text-gray-600 text-xs">
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.followers.title")}</div>
                      <div>---</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.follows.title")}</div>
                      <div>---</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.likes.title")}</div>
                      <div>---</div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </NavLink>
        {showButtons && !minimized && (
          <div className="flex justify-between">
            <PencilIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <ShareIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <CheckIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <TrashIcon className="bg-red-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Assignment;
