import React from "react";
import Panel from "@components/box/Panel";
import SuggestionsCenterItem from "@components/elements/sidebar/suggestions/SuggestionsCenterItem";
import axios from "axios";
import { JobType } from "@/utils/types/JobType";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import api from "@/api/api";

type Props = { key?: string };

type Response = {
  data: JobType[];
};

const JobsSuggestions = ({ key }: Props) => {
  const jobs = useQuery<Response, Error>(["jobs"], async () => {
    const { data } = await api.get("/api/v1/jobs");
    return data;
  });

  if (!jobs.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title="Beliebte Jobs auf Tradefoox" mobileBorder={false} key={key}>
        <div className="flex gap-4 overflow-x-auto no-scrollbar">
          {jobs.data.data.map((item: JobType) => {
            return (
              <SuggestionsCenterItem
                key={item.id}
                name={item.title}
                link={"/jobs/" + item.id}
                picture={
                  item.user.avatar ||
                  `/images/placeholder/${item.user.type}.png`
                }
                imageStyle={tw`rounded-xl w-[100px] h-[150px] object-cover`}
                nameStyle={tw`w-[100px]`}
              />
            );
          })}
        </div>
      </Panel>
    </div>
  );
};
export default JobsSuggestions;
