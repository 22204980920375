import React, { useState } from "react";
import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  FlagIcon,
  PencilIcon,
  TrashIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import EditPost from "@components/elements/posts/form/EditPost";
import { Post } from "@/utils/post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import { showReportModal } from "@/state/reportModal/actions";
import { useDispatch } from "react-redux";
import ContextMenu from "@components/elements/shared/ContextMenu";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  post: Post;
  onDownloadStart?: () => void;
  onDownloadEnd?: () => void;
  onDownloadProgress?: (progress: number) => void;
};

const PostsDropdown = ({ post, ...props }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const userId = user?.id;
  const loginModal = useLoginModal();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deletePost = useMutation(["delete-post"], {
    mutationFn: async (postId: string) => {
      const res = await api.delete(`/api/v1/posts/${postId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${post.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${post.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const downloadAllFiles = async () => {
    props.onDownloadStart && props.onDownloadStart();
    props.onDownloadProgress && props.onDownloadProgress(0);
    const zip = new JSZip();

    if (post.media.length === 0) {
      props.onDownloadEnd && props.onDownloadEnd();
      return;
    }

    if (post.media.length === 1) {
      const media = post.media[0];
      await api({
        method: "get",
        url: `https://cdn.tradefoox.iaccam.com/${media.key}/${
          media.file_name
        }?_=${Date.now()}`,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          // Calculate the percentage of the current file
          const percentage = Math.floor(
            (progressEvent.loaded * 100) / (progressEvent.total || 0),
          );

          console.log(percentage, progressEvent.loaded, progressEvent.total);

          // Report progress
          props.onDownloadProgress &&
            props.onDownloadProgress(Math.round(percentage));
        },
      }).then((response) => {
        // Error checking, if server doesn't give a successful response
        if (response.status !== 200) {
          throw new Error(
            `Error while fetching ${media.id}: ${response.statusText}`,
          );
        }
        saveAs(response.data, media.file_name);
        props.onDownloadEnd && props.onDownloadEnd();
      });
      return;
    }

    // Fetch all files one by one
    for (const media of post.media) {
      let totalPercentage = 0;
      await axios({
        method: "get",
        url: `https://cdn.tradefoox.iaccam.com/${media.key}/${
          media.file_name
        }?_=${Date.now()}`,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          // Calculate the percentage of the current file
          const percentage = Math.floor(
            (progressEvent.loaded * 100) / (progressEvent.total || 0),
          );

          // Calculate the total progress
          totalPercentage =
            (post.media.indexOf(media) / post.media.length +
              percentage / (100 * post.media.length)) *
            100;

          // Report progress
          props.onDownloadProgress &&
            props.onDownloadProgress(Math.round(totalPercentage));
        },
      }).then((response) => {
        // Error checking, if server doesn't give a successful response
        if (response.status !== 200) {
          throw new Error(
            `Error while fetching ${media.id}: ${response.statusText}`,
          );
        }

        const blob = new Blob([response.data], { type: response.data.type });
        zip.file(media.file_name, blob, { binary: true });
      });
    }

    // Generate ZIP file and trigger download
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${post.id}.zip`);
    });
    props.onDownloadEnd && props.onDownloadEnd();
  };

  const block = useMutation([`block-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblock = useMutation([`unblock-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      {openEdit && (
        <EditPost
          onSuccess={() => setOpenEdit(false)}
          post={post}
          onClose={() => setOpenEdit(false)}
        />
      )}
      <ContextMenu
        items={[
          post.media[0] && {
            label: "Download",
            onClick: () => downloadAllFiles(),
            icon: ArrowDownTrayIcon,
          },
          user &&
            user.id === post.author.id &&
            !post.reposted && {
              label: "Bearbeiten",
              onClick: () => setOpenEdit(true),
              icon: PencilIcon,
            },
          user &&
            (user.id === post.author.id || user.admin) && {
              label: "Löschen",
              onClick: () => deletePost.mutate(post.id),
              icon: TrashIcon,
            },
          {
            label: "Melden",
            onClick: () => dispatch(showReportModal(post.id, "post")),
            icon: FlagIcon,
          },
          {
            label: "Blockieren",
            onClick: () => block.mutate(),
            icon: MinusCircleIcon,
          },
          {
            label: "Speichern ",
            onClick: () => (post.bookmarked ? remove.mutate() : add.mutate()),
            icon: post.bookmarked ? BookmarkIconSolid : BookmarkIcon,
          },
        ]}
      />
    </>
  );
};

export default PostsDropdown;
