import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";
import { NavLink } from "react-router-dom";
import Button from "@components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import ContactContextMenu from "@pages/contacts/context/ContactContextMenu";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = { user: User; mode?: string; key?: string };

const ContactMyFollowersItem = ({ user }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const block = useMutation([`block-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-my_followers"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblock = useMutation([`unblock-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-my_followers"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel>
      <ContactContextMenu userId={user.id} />
      <div>
        <div className="text-sm flex flex-col w-full gap-2">
          <NavLink
            to={"/profile/" + user.id}
            className="flex flex-col gap-1 w-full"
          >
            <UserWidget
              user={user}
              showName={true}
              follow={false}
              verified={true}
            />
          </NavLink>
          <div className="flex flex-row justify-between text-gray-600 text-xs">
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.followers.title")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.following.title")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
            <div className="flex flex-col items-center gap-x-1">
              <div>{t("main.likes.title")}</div>
              <div>{user.like_count}</div>
            </div>
          </div>
          <Button
            onClick={() => {
              if (user.blocked) {
                unblock.mutate();
              } else {
                block.mutate();
              }
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            <MinusCircleIcon className="w-4 h-4" />
            {user.blocked ? "Entblocken" : "Blockieren"}
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default ContactMyFollowersItem;
