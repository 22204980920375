import React from "react";
import Panel from "@components/box/Panel";
import { NavLink } from "react-router-dom";
import Input from "@/components/elements/input/Input";
import Checkbox from "@/components/elements/input/Checkbox";
import Button from "@/components/elements/input/Button";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const MarketingContainer = () => {
  const navigate = useNavigate();

  return (
    <Panel mobileBorder={false}>
      <button onClick={() => navigate(-1)} className="lg:hidden">
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <div className="grid grid-flow-row text-xs gap-4">
        <div className="font-bold text-sm">
          Mit Euch zusammen erreichen wir Eure Zielgruppe und überzeugen sie von
          Eurem Produkt.
        </div>
        <p>
          Nutzt die Bekanntheit unserer Marken und Titel und platziert Eure
          Werbebotschaft einem breiten Publikum. Überzeugt Euch von unseren
          Angeboten und kontaktiert uns, für Eure ganz persönliche
          Media-Kampagne. In den Mediadaten von TRADEFOOX findet Ihr alle
          relevanten Informationen rund um unsere Webseite, Newsletter, Formate
          und Werbung. Für nähere Informationen steht Euch Euer persönlicher
          Ansprechpartner gerne zur Verfügung.
        </p>
        <NavLink
          to="/media/dateien/mediadaten_tradefoox.pdf"
          className="text-cyan-700"
        >
          Mediadaten zum Download
        </NavLink>
        <p className="font-bold">Platzierung der Werbeschaltung</p>
        <img
          src="/images/info/tf-ad-placement.jpg"
          className="w-full object-contain"
        />
        <p className="font-bold">Formate und Preise</p>
        <img
          src="/images/info/tf-newsletter.jpg"
          className="w-full object-contain"
        />
        <p className="font-bold">Werde Partner von TRADEFOOX!</p>
        <p>
          Du bist im Online-Business tätig und hast ein starkes Vertriebsteam?
          Du willst Dein internationales Produktportfolio erweitern? Dann werde
          Vertriebspartner von TRADEFOOX. Präsentiere Dich mit tradefoox.com als
          attraktiven Partner, um Vorteile gegenüber Wettbewerbern zu schaffen.
          Neben einer nachhaltigen Kooperationsentwicklung kannst Du dich durch
          die Nutzung wechselseitiger Stärken auf Dein eigentliches Kerngeschäft
          konzentrieren.
        </p>
        <p>Deine Vorteile als Partner:</p>
        <ul className="list-disc list-inside">
          <li>
            Professionelle Zusammenarbeit mit unserem einzigartigen Netzwerk in
            Deutschland, Österreich und der Schweiz.
          </li>
          <li>Erschließung neuer Umsatzpotenziale</li>
          <li>
            Risikofreier Marktzugang nach Deutschland, Österreich der Schweiz
            und Europa für Deine Kunden
          </li>
          <li>Umfangreiche Unterstützung für Dein Team</li>
          <li>Maximiere die Sichtbarkeit für Deine Kunden</li>
        </ul>
        <p className="font-bold">
          Gemeinsam mit unseren Unternehmenspartnern konnten wir schon viel
          erreichen.
        </p>
        <p>
          Lass Dich von den erfolgreichen Kooperationen inspirieren und
          kontaktiere uns noch heute - wir freuen uns!
        </p>
        <form>
          <Input placeholder="Firmen" name="company" />
          <Input placeholder="Land" name="country" />
          <Input placeholder="Website" name="website" />
          <Input placeholder="E-Mail" name="email" />
          <Input placeholder="Deine Nachricht" name="message" />
          <Checkbox
            name="privacypolicy"
            onChange={() => {
              console.log("checkeda");
            }}
            checked
          >
            Ich habe die Datenschutzerklärung gelesen und akzeptiert* Absenden
          </Checkbox>
          <Input placeholder="" name="form" />
          <Button
            onClick={() => {
              console.log("");
            }}
            variant={Button.Variants.Transparent}
          >
            Absenden
          </Button>
        </form>
        <p className="text-base">Unsere Partner:</p>
        <img
          src="/images/info/tf-partner.jpg"
          className="w-full object-contain"
        />
        <p>
          Tradefoox.com, der weltweit größte C2C, B2C &
          B2B-E-Commerce-Marktplatz, hilft Dir, auch in Zeiten der Pandemie
          Millionen von Käufern zu erreichen.
        </p>
        <p className="font-bold text-center">
          Tradefoox.com hilft Dir, Dein Geschäft weltweit zu betreiben
        </p>
        <p className="-mt-4 text-center">
          Tradefoox.com hilft Verkäufern wie Dir, verschiedene Vorteile zu
          genießen und an Millionen von Käufern auf der ganzen Welt zu
          verkaufen.
        </p>
        <p className="font-bold">
          Verbinde Dich mit Millionen von Käufern auf der ganzen Welt
        </p>
        <p className="-mt-4">
          <ul className="list-disc list-inside">
            <li>
              Erhalte sofortigen Zugang zu 11 Millionen B2B-Käufern auf der
              ganzen Welt
            </li>
            <li>
              Unsere aktiven Käufer senden jeden Tag über 300.000 Anfragen
            </li>
            <li>
              Erschließe Dir den 27 Billionen Dollar schweren weltweiten
              B2B-E-Commerce-Markt
            </li>
            <li>
              Tradefoox.com ist wie eine globale Messe für Verkäufer, die rund
              um die Uhr geöffnet haben
            </li>
          </ul>
        </p>
        <p className="font-bold">
          Steiger Deine Umsätze mit intelligenten Tools
        </p>
        <p className="-mt-4">
          <ul className="list-disc list-inside">
            <li>
              Erstelle ein digitales Schaufenster für Deine Marke – keine
              Programmierkenntnisse erforderlich
            </li>
            <li>
              Präsentiere Bilder, Videos, Zertifizierungen und Deine wichtigsten
              Verkaufsargumente
            </li>
            <li>
              Stellen mithilfe der Massen-Upload-Funktion Deinen gesamten
              Katalog online
            </li>
            <li>
              Finden mithilfe intelligenter Marketing-Tools die richtigen Käufer
              für Deine Produkte
            </li>
          </ul>
        </p>
        <p className="font-bold">Erhalte Anfragen und steigere Deinen Umsatz</p>
        <p className="-mt-4">
          <ul className="list-disc list-inside">
            <li>
              Jeder Verkauf ist ein Gespräch, das mit einer Anfrage beginnt. Du
              kannst mit Deinen Kunden zu Deinen Bedingungen interagieren und
              die Beziehungen selbst gestalten. Mit Online-Messen und
              Live-Streaming-Veranstaltungen kannst Du Deine Produkte
              potenziellen Kunden präsentieren. Du kannst proaktiv Käufer
              finden, die eine Angebotsanfrage stellen und nach Produkten wie
              den Ihren suchen.
            </li>
          </ul>
        </p>
        <p className="font-bold">
          Stelle Deinen Erfolg mit professionellem Kundenservice sicher
        </p>
        <p className="-mt-4">
          <ul className="list-disc list-inside">
            <li>
              Wir haben unsere neuen Erfolgsprogramme für Verkäufer entwickelt,
              um Dich auf den Erfolg vorzubereiten. Dazu gehören
              Schulungsseminare, der Tradefoox Seller Training E-Kurs und
              Verkäuferforen von Servicepartnern und dem offiziellen
              Tradefoox-Team.
            </li>
          </ul>
        </p>
        <p className="font-bold">
          Schöpfe den Wettbewerbsvorteil im grenzüberschreitenden elektronischen
          Handel voll aus.
        </p>
        <p className="text-lg font-bold text-center">
          Bist Du bereit, Dein Geschäft auszubauen?
        </p>
      </div>
    </Panel>
  );
};

export default MarketingContainer;
