import { NavLink, useParams } from "react-router-dom";
import { HeartIcon, PrinterIcon } from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartIconFull,
  BookmarkIcon as BookmarkIconFull,
} from "@heroicons/react/24/solid";
import {
  BookmarkIcon,
  EyeIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const MarketplaceDetails = () => {
  const { adsId } = useParams();
  const { t } = useTranslation();
  return (
    <div>
      {/* Title */}
      <div className="flex flex-row justify-between items-start">
        <div className="text-xl">
          title title title title title title title title title title title
        </div>
        <div className="inline-flex gap-3">
          <button className="text-darkblue bg-white rounded-md border-darkblue border">
            <HeartIconFull className="w-5 h-5 heroicon-stroke-w-2.0 text-red-600" />
          </button>

          <button className="button--primary">Kontaktieren</button>

          <button className="button--primary">
            <BookmarkIconFull className="w-5 h-5 heroicon-stroke-w-2.0" />
          </button>

          <button className="button--primary">
            <PrinterIcon className="w-5 h-5 heroicon-stroke-w-2.0" />
          </button>

          <NavLink to="/publictender/">
            {t("main.groupsInfoForm.buttons.back")}
          </NavLink>
        </div>
      </div>

      {/* Stats */}
      <div className="flex flex-row w-full justify-between border-b pb-2 mb-4">
        <div className="flex flex-col gap-1">
          <div className="whitespace-nowrap inline-flex items-center gap-2">
            ###++
            <div className="text-gray-600 text-xs">710 Sternebewertungen</div>
          </div>
          <div className="inline-flex w-full gap-2 text-xs">
            <div className="inline-flex gap-0.5 items-center">
              <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">4</div>
            </div>
            <div className="inline-flex gap-0.5 items-center">
              <BookmarkIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">1</div>
            </div>
            <div className="inline-flex gap-0.5 items-center">
              <HeartIcon className="w-3 h-3 text-darkred heroicon-stroke-w-2.0" />
              <div className="text-gray-500">2</div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-[auto,250px] gap-6">
        {/* Left side */}
        <div className="w-full">
          {/* Image */}
          <img src="/placeholder.png" />

          {/* Order description */}
          <div className="text-sm mt-6">
            <div className="text-gray-800">
              <b className="block font-semibold">Beschreibung</b>
              <div className="break-all">Lorem Ipsum</div>
            </div>
          </div>
          <div className="cursor-pointer border w-full border-gray-300 inline-flex items-center justify-center gap-1 my-2 text-sm">
            Einklappen
            <ChevronUpIcon className="w-4 h-4 heroicon-stroke-w-2.4" />
          </div>
        </div>

        {/* Right side */}
        <div className="flex flex-col gap-8 text-gray-800">
          {/* Info */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="flex flex-col">
              <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap text-lg">
                1.000 €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Nettopreis</b>{" "}
                1.200 €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Bruttopreis</b>{" "}
                2.000 €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Versand</b>{" "}
                kostenlos
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Anzahl</b>{" "}
                <div className="font-semibold">Derzeit nicht verfügbar.</div>
              </div>

              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Ab 18</b>
              </div>
              <div className="text-sm text-gray-800 inline-flex gap-2 mt-2">
                <button className="button--primary">Einpacken</button>
                <button className="button--secondary">Kaufen</button>
              </div>
            </div>
          </div>

          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="flex flex-col">
              <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap">
                Verkäufer & Versand
              </div>
              <div className="text-sm text-gray-800">
                <NavLink to={`/profile/100`} className="whitespace-nowrap">
                  Linus
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceDetails;
