import React, { useState } from "react";
import JobsListItem from "@pages/jobs/JobsListItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { JobType } from "@/utils/types/JobType";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import JobWrapper from "@pages/jobs/JobWrapper";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = {
  tab?: string;
};

type Response = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

const JobsContainer = ({ tab = "all" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  const jobs = useInfiniteQuery<Response>(
    ["jobs", searchQuery, tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/jobs?page=${pageParam}&q=${searchQuery}&filter=${tab}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  return (
    <JobWrapper
      popular
      searchValue={searchQuery}
      onSearchValueChange={setSearchQuery}
    >
      {tab === "my" && (
        <TabMenu subTab>
          <TabLink subTab to="/jobs/meine_jobs/übersicht">
            {t("main.jobsTabs.myJobs.tabs.overview")}
          </TabLink>
          <TabLink subTab to="/jobs/meine_jobs/nachrichten">
            {t("main.jobsTabs.myJobs.tabs.news")}
          </TabLink>
          <TabLink subTab to="/jobs/meine_jobs/werdegänge">
            {t("main.jobsTabs.myJobs.tabs.careers")}
          </TabLink>
          <TabLink subTab to="/jobs/meine_jobs/statistik">
            {t("main.jobsTabs.myJobs.tabs.statistics")}
          </TabLink>
        </TabMenu>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {jobs.data &&
          jobs.data.pages.map(
            (page) =>
              page.data &&
              page.data.map((job: JobType) => (
                <JobsListItem key={job.id} job={job} showButtons={false} />
              )),
          )}
      </div>
      {jobs.hasNextPage && !jobs.isFetchingNextPage && (
        <ShowMoreButton onClick={() => jobs.fetchNextPage()} />
      )}
    </JobWrapper>
  );
};

export default JobsContainer;
