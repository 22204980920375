import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import Button from "@components/elements/input/Button";

function CreateFormButton() {
  const createForms: { [index: string]: { url: string; label?: string } } = {
    inserate: { url: "/create" },
    jobs: { url: "/jobs/jobs_erstellen" },
    news: { url: "/news/create", label: "Posten" },
    watch: { url: "/watch/create", label: "Posten" },
    kontakte: { url: "/kontakte/news/create", label: "Posten" },
    messenger: { url: "/messenger/news/create", label: "Posten" },
    marketplace: { url: "/marketplace/create" },
    profile: { url: "/news/create", label: "Posten" },
    gruppen: { url: "/gruppen/erstellen" },
  };
  const path = location.pathname.split("/")[1];
  const createPath = createForms[path]?.url ?? "";

  return (
    <>
      {createForms[path] && (
        <NavLink
          to={createPath}
          state={{ tab: "create" }}
          className="w-full h-full flex justify-center -translate-y-1"
        >
          <Button variant={Button.Variants.Transparent}>
            {createForms[path].label ?? "Erstellen"}
          </Button>
        </NavLink>
      )}
    </>
  );
}

export default CreateFormButton;
