import React, { useState } from "react";
import Input from "@components/elements/input/Input";
import Select from "@components/elements/input/Select";
import ProfileEditConfig from "@pages/profile/edit/ProfileEditConfig";
import TabFilter from "@components/menu/TabFilter";
import BottomProfileEditTabs from "@pages/profile/edit/BottomProfileEditTabs";
import Panel from "@components/box/Panel";
import ProfileEditSocialsContainer from "@pages/profile/edit/ProfileEditSocialsContainer";
import ContactsEditor from "@components/elements/form/ContactsEditor";
import { User } from "@/utils/user";
import tw from "twin.macro";
import TextareaAutosize from "react-textarea-autosize";
import Tags from "@components/elements/input/Tags";
import LegalInput from "@components/elements/input/LegalInput";
import { useMutation } from "@tanstack/react-query";
import ImageUpload from "@components/elements/input/ImageUpload";
import ProfileEditCVList from "@pages/profile/edit/ProfileEditCVList";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import api from "@/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import BackButton from "@/components/nav/BackButton";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const tabs = (t: TFunction) => ({
  company: [
    { key: "common", name: t("main.profileTabs.company.info") },
    { key: "description", name: t("main.profileTabs.company.details") },
    { key: "contact_person", name: t("main.profileTabs.company.contact") },
    { key: "socials", name: t("main.profileTabs.company.socialMedia") },
  ],
  person: [
    { key: "common", name: t("main.profileTabs.person.info") },
    {
      key: "more_information",
      name: t("main.profileTabs.person.details"),
    },
    { key: "cv", name: t("main.profileTabs.person.career") },
    { key: "socials", name: t("main.profileTabs.person.socialMedia") },
  ],
  club: [
    { key: "common", name: t("main.profileTabs.club.info") },
    { key: "description", name: t("main.profileTabs.club.info") },
    { key: "contact_person", name: t("main.profileTabs.club.info") },
    { key: "socials", name: t("main.profileTabs.club.info") },
  ],
});

const ProfileEditContainer = () => {
  const [userList, setUserList] = useState<Array<User>>([]);
  const [data, setData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<string>();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState<string>();
  const [header, setHeader] = useState<string>();
  const { user } = useAppSelector((state) => state.user);
  const popupModal = usePopupModal();

  const uploadAvatar = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const res = await api.post("/api/v1/avatar/upload", formData, config);
      return res.data;
    },
    onSuccess: (data) => {
      setData((value: any) => {
        return { ...value, avatar: data.data };
      });
      setAvatar(data.data);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const uploadHeader = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const res = await api.post("/api/v1/header/upload", formData, config);
      return res.data;
    },
    onSuccess: (data) => {
      setData((value: any) => {
        return { ...value, header: data.data };
      });
      setHeader(data.data);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const location = useLocation();
  const navigate = useNavigate();

  const updateProfile = useMutation(["update-profile"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/profile/edit", data);
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.OK}
          message="Daten gespeichert."
          title="Bearbeitung"
        />,
      );
      return res.data;
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.Error}
          message="Deine Daten konnten nicht gespeichert werden."
          title="Bearbeitung"
        />,
      );
    },
  });

  if (!user) return <span>Loading...</span>;

  const type: "company" | "club" | "person" = user ? user.type : "company";
  if (!selectedTab) {
    setSelectedTab(tabs(t)[type][0].key);
  }
  if (!selectedTab) return <span>Loading...</span>;

  const selectForward = () => {
    for (const [i, tab] of tabs(t)[type].entries()) {
      if (i + 1 === tabs(t)[type].length) {
        return;
      }
      if (tab.key === selectedTab) {
        setSelectedTab(tabs(t)[type][i + 1].key);
      }
    }
  };

  const selectBackward = () => {
    for (const [i, tab] of tabs(t)[type].entries()) {
      if (tab.key === selectedTab) {
        if (i === 0) {
          return;
        }
        setSelectedTab(tabs(t)[type][i - 1].key);
      }
    }
  };

  if (!user) return <></>;

  return (
    <>
      <BackButton />
      <TabFilter
        value={selectedTab}
        options={tabs(t)[type]}
        onChange={setSelectedTab}
      />
      <Panel>
        <div className="grid grid-cols-2 gap-2 items-end">
          {selectedTab === "contact_person" && (
            <div className="col-span-2">
              <div className="font-semibold my-2">Ansprechpartner</div>
              <ContactsEditor
                name="contactseditor"
                onChange={(data: Array<User>) => {
                  setUserList(data);
                }}
                contacts={userList}
              />
              <div className="font-semibold my-2">
                Betreuer / Seitenverwalter
              </div>
              <ContactsEditor name="contactseditor" onChange={setUserList} />
            </div>
          )}
          {selectedTab === "socials" && (
            <ProfileEditSocialsContainer
              value={data.socials ?? (user ? user.socials : [])}
              onChange={(socials) =>
                setData((data: any) => {
                  return { ...data, socials };
                })
              }
            />
          )}
          {selectedTab === "cv" && (
            <div className="col-span-2">
              <ProfileEditCVList
                name="cv"
                onChange={(cv) =>
                  setData((data: any) => {
                    return { ...data, cv };
                  })
                }
                values={data.cv ?? (user ? user.cv : "")}
                addText={t(
                  "main.profileViewTabs.editProfile.form.careerInfo.addEntry",
                )}
                label={t(
                  "main.profileViewTabs.editProfile.form.careerInfo.career",
                )}
              />
              <ProfileEditCVList
                name="education"
                onChange={(education) =>
                  setData((data: any) => {
                    return { ...data, education };
                  })
                }
                values={data.education ?? (user ? user.education : "")}
                addText={t(
                  "main.profileViewTabs.editProfile.form.careerInfo.addEntry",
                )}
                label={t(
                  "main.profileViewTabs.editProfile.form.careerInfo.training",
                )}
              />
            </div>
          )}
          {selectedTab !== "socials" &&
            selectedTab !== "contact_person" &&
            ProfileEditConfig(t)[user.type][selectedTab].map(
              (item: {
                type: string;
                name: string;
                disabled: boolean;
                label: string;
                link: string;
                cols: number;
                items: Array<any>;
                placeholder: string;
                maxLength: number;
              }) => (
                <div key={item.name} css={[item.cols === 2 && tw`col-span-2`]}>
                  {item.type === "input" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      defaultValue={getProfileData(item.name, user)}
                      onChange={(e) =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    />
                  )}
                  {item.type === "number" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      type="number"
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      defaultValue={getProfileData(item.name, user)}
                      onChange={(e) => {
                        setData((value: any) => {
                          return {
                            ...value,
                            [item.name]: parseInt(e),
                          };
                        });
                      }}
                    />
                  )}
                  {item.type === "date" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      type="date"
                      defaultValue={getProfileData(item.name, user)}
                      onChange={(e) =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    />
                  )}
                  {item.type === "link" && (
                    <a
                      className="text-sm hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.link}
                    >
                      {item.label}
                    </a>
                  )}
                  {item.type === "textarea" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        {item.label}
                      </label>
                      <TextareaAutosize
                        minRows={3}
                        onChange={(e) =>
                          setData((value: any) => {
                            return { ...value, [item.name]: e.target.value };
                          })
                        }
                        value={
                          data[item.name] ?? getProfileData(item.name, user)
                        }
                        defaultValue={(user as any)[item.name]}
                        className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
                        maxLength={item.maxLength}
                      />
                    </>
                  )}
                  {item.type === "select" && item.items && (
                    <Select
                      name={item.name}
                      items={item.items}
                      disabled={item.disabled}
                      value={data[item.name]}
                      defaultValue={getProfileData(item.name, user)}
                      label={item.label}
                      onChange={(e) =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e.target.value };
                        })
                      }
                    />
                  )}
                  {item.type === "tags" && (
                    <Tags
                      label={item.label}
                      name={item.name}
                      placeholder={item.placeholder}
                    />
                  )}
                  {item.type === "legal" && (
                    <LegalInput
                      name={item.name}
                      label={item.label}
                      maxLength={item.maxLength}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      onChange={(value) =>
                        setData((oldData: any) => {
                          return { ...oldData, [item.name]: value };
                        })
                      }
                    />
                  )}
                </div>
              ),
            )}
        </div>
        {selectedTab === "common" && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <ImageUpload
              name="avatar"
              onChange={(newImage: File) => {
                uploadAvatar.mutate(newImage);
              }}
              label="Profilbild hochladen"
              borderRadius={100}
              value={
                (avatar
                  ? "https://cdn.tradefoox.iaccam.com/avatar/" + avatar
                  : user.avatar) ?? ""
              }
            />
            <ImageUpload
              name="header"
              onChange={(newImage: File) => {
                uploadHeader.mutate(newImage);
              }}
              label="Headerbild hochladen"
              value={
                (header
                  ? "https://cdn.tradefoox.iaccam.com/header/" + header
                  : user.header_image) ?? ""
              }
            />
          </div>
        )}
        <BottomProfileEditTabs
          showForward={selectedTab !== "socials"}
          showBackward={selectedTab !== "common"}
          backward={selectBackward}
          forward={selectForward}
          preview={() => console.log("preview")}
          submit={updateProfile.mutate}
        />
      </Panel>
    </>
  );
};

// TODO: refactor to avoid @ts-ignore
const getProfileData = (key: string, data: User) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "person" && data.person && data.person[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.person[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "company" && data.company && data.company[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.company[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "club" && data.club && data.club[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.club[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data && data[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data[key];
  }
  return "";
};

export default ProfileEditContainer;
