import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/state/store";
import { User } from "@/utils/user";

interface UserState {
  user: User | null;
  token: string;
}

const initialState: UserState = {
  user: null,
  token: "",
};

export const userlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    signOut: (state) => {
      state.user = null;
      state.token = "";
    },
  },
});

export const selectNotifications = (state: RootState) =>
  state.notifications.value;

export const { setUser, setToken, signOut } = userlice.actions;

export default userlice.reducer;
