import { TFunction } from "i18next";

type AssignmentCreateConfigType = {
  [key: string]: any;
};

const categories = (t: TFunction) => ({
  name: "category",
  label: t("main.sell.created.categories"),
  type: "select",
  items: [
    { name: "Dienstleistung", value: "---" },
    { name: "Fahrzeug", value: "---" },
    { name: "Haushalt", value: "---" },
    { name: "Immobilien1", value: "---" },
    { name: "Kleidung", value: "---" },
    { name: "Lebensmittel", value: "---" },
    { name: "Technik", value: "---" },
    { name: "Tiere", value: "---" },
    { name: "Sammeln", value: "---" },
  ],
});

const type = {
  name: "type",
  label: "",
  type: "radio",
  items: [
    { name: "Biete", value: "0" },
    { name: "Suche", value: "1" },
  ],
};

const pricetype = {
  name: "pricetype",
  label: "Preistyp",
  items: [
    { name: "Preistyp", value: "undefined" },
    { name: "pro Stunde", value: "hourly" },
    { name: "Tagespauschale", value: "daily" },
    { name: "Festpreis / Budget", value: "fixed" },
    { name: "Verhandlungsbasis", value: "base" },
    { name: "zu verschenken", value: "gift" },
    { name: "Tausch", value: "swap" },
  ],
};

const vat = (t: TFunction) => ({
  name: "vat",
  label: t("main.sell.created.vat"),
  type: "radio",
  items: [
    { name: "19%", value: 19 },
    { name: "7%", value: 7 },
    { name: "nicht ausweißbar", value: 0 },
  ],
});

const shippingservices = (t: TFunction) => ({
  name: "shippingservices",
  label: t("main.sell.created.shippingServiceProvider"),
  type: "select",
  items: [
    { name: "DHL", value: "DLH" },
    { name: "DHL Express", value: "DLH Express" },
    { name: "DPD", value: "DPD" },
    { name: "UPS", value: "UPS" },
    { name: "GLS", value: "GLS" },
    { name: "FedEx", value: "Fedex" },
    { name: "Deutsche Post", value: "Deutsche Post" },
  ],
});

const title = {
  name: "title",
  label: "Titel",
  type: "input",
};

const description = {
  name: "description",
  label: "Beschreibung",
  type: "textarea",
};

const keywords = (t: TFunction) => ({
  name: "keywords",
  label: t("main.sell.created.tagsKeywords"),
  type: "tags",
});

const quantity = (t: TFunction) => ({
  name: "quantity",
  label: t("main.sell.created.quantityAvailable"),
  type: "input",
});

const nettoprice = (t: TFunction) => ({
  name: "nettoprice",
  label: t("main.sell.created.netPrice"),
  type: "input",
});

const bruttoprice = (t: TFunction) => ({
  name: "bruttoprice",
  label: t("main.sell.created.grossPrice"),
  type: "input",
});

const offerprice = (t: TFunction) => ({
  name: "offerprice",
  label: t("main.sell.created.priceOffer"),
  type: "input",
});

const barcode = (t: TFunction) => ({
  name: "barcode",
  label: t("main.sell.created.barcode"),
  type: "input",
});

const hscode = (t: TFunction) => ({
  name: "hscode",
  label: t("main.sell.created.hsCode"),
  type: "input",
});

const reference = (t: TFunction) => ({
  name: "reference",
  label: t("main.sell.created.referenceNumber"),
  type: "input",
});

const trackingid = (t: TFunction) => ({
  name: "trackingid",
  label: t("main.sell.created.trackingNumber"),
  type: "input",
});

const trackingurl = (t: TFunction) => ({
  name: "trackingurl",
  label: t("main.sell.created.trackingUrl"),
  type: "input",
});

const deliverydate = (t: TFunction) => ({
  name: "deliverydate",
  label: t("main.sell.created.expectedDelivery"),
  type: "input",
});

const agb = (t: TFunction) => ({
  name: "agb",
  label: t("main.sell.created.termsCondition"),
  type: "input",
});

const hsntsn = {
  name: "hsntsn",
  label: "HSN / TSN",
  type: "input",
};

const cartypes = {
  name: "cartypes",
  label: "",
  type: "select",
  items: [
    { name: "Anhänger", value: "Anhänger" },
    { name: "Boote", value: "Boote" },
    { name: "Fahrräder", value: "Fahrräder" },
    { name: "Kraftfahrzeuge", value: "Kraftfahrzeuge" },
    { name: "LKW / Nutzfahrzeuge", value: "LKW" },
    { name: "Moped / Roller", value: "Moped" },
    { name: "Motorrad", value: "Motorrad" },
    { name: "Oldtimer", value: "Oldtimer" },
    { name: "Wohnmobil / Caravan", value: "Wohnmobil" },
  ],
};

const condition = (t: TFunction) => ({
  name: "condition",
  type: "radio",
  items: [
    { name: t("main.new.title"), value: "new" },
    { name: "Gebraucht", value: "used" },
    { name: "Instandgesetzt", value: "refurbished" },
    { name: "Defekt", value: "defect" },
  ],
});

const tüv = {
  name: "tüv",
  label: "TÜV",
  type: "date",
};

const lastinspection = {
  name: "lastinspection",
  label: "Letzte Inspektion",
  type: "date",
};

const mileage = {
  name: "mileage",
  label: "Kilometerstand",
  type: "input",
};

const builddate = {
  name: "builddate",
  label: "Baujahr",
  type: "date",
};

const firstapproval = {
  name: "firstapproval",
  label: "Erstzulassung",
  type: "date",
};

const fueltypes = {
  name: "fueltypes",
  label: "Kraftstoff",
  type: "select",
  items: [
    { name: "Benzin", value: "Benzin" },
    { name: "Diesel", value: "Diesel" },
    { name: "Erdgas (CNG)", value: "CNG" },
    { name: "Autogas (LPG)", value: "LPG" },
    { name: "Elektro", value: "Elektro" },
    { name: "Hybrid", value: "Hybrid" },
    { name: "Mild-Hybrid", value: "Mild-Hybrid" },
    { name: "Plug-In-Hybrid", value: "Plug-In-Hybrid" },
    { name: "Sonstiges", value: "Sonstiges" },
  ],
};

const co2km = {
  name: "co2km",
  label: "CO2-Emission (g/km)",
  placeholder: "CO2-Emission (g/km)",
  type: "input",
};

const pollutantclass = {
  name: "pollutantclass",
  label: "Schadstoffklasse",
  placeholder: "Schadstoffklasse",
  type: "select",
  items: [
    { name: "Euro 00", value: "Euro 00" },
    { name: "Euro 0", value: "Euro 0" },
    { name: "Euro 1", value: "Euro 1" },
    { name: "Euro 2", value: "Euro 2" },
    { name: "Euro 3", value: "Euro 3" },
    { name: "Euro 4", value: "Euro 4" },
    { name: "Euro 5", value: "Euro 5" },
    { name: "Euro 6", value: "Euro 6" },
  ],
};

const co2efficiency = {
  name: "co2efficiency",
  label: "CO2-Effizienz",
  placeholder: "Schadstoffklasse",
  type: "select",
  items: [
    { name: "A+", value: "A+" },
    { name: "A", value: "A" },
    { name: "B", value: "B" },
    { name: "C", value: "C" },
    { name: "D", value: "D" },
    { name: "E", value: "E" },
    { name: "F", value: "F" },
    { name: "G", value: "G" },
  ],
};

const finedustcertificate = {
  name: "finedustcertificate",
  label: "Feinstaubplakette",
  type: "checkboxgroup",
  items: [
    { label: "Grün" },
    { label: "Gelb" },
    { label: "Rot" },
    { label: "Blau" },
    { label: "Keine" },
  ],
};

const drive = {
  name: "drive",
  type: "checkboxgroup",
  items: [
    { label: "Schaltung" },
    { label: "Halbautomatik" },
    { label: "Automatik" },
    { label: "Sonstiges" },
  ],
};

const power = {
  name: "power",
  label: "Leistung (kw)",
  placeholder: "Leistung (kw)",
  type: "input",
};

const gears = {
  name: "gears",
  label: "Gänge",
  placeholder: "Gänge",
  type: "input",
};

const emptyweight = {
  name: "emptyweight",
  label: "Leergewicht",
  placeholder: "Leergewicht",
  type: "input",
};

const cardesign = {
  name: "cardesign",
  label: "Bauart",
  placeholder: "Bauart",
  type: "select",
  items: [
    { name: "Kombi", value: "Kombi" },
    { name: "Cabrio / Roadster", value: "Cabrio-Roadster" },
    { name: "Van", value: "Van" },
    { name: "Kleinbus", value: "Kleinbus" },
    { name: "Kleinwagen", value: "Kleinwagen" },
    { name: "Limousine", value: "Limousine" },
    { name: "Coupe", value: "Coupe" },
    { name: "SUV", value: "SUV" },
    { name: "Geländewagen", value: "Geländewagen" },
  ],
};

const color = {
  name: "color",
  label: "Farbe",
  placeholder: "Farbe",
  type: "input",
};

const carexterior = {
  name: "carexterior",
  label: "Außenausstattung",
  placeholder: "Außenausstattung",
  type: "select",
  items: [
    { name: "Allradantrieb", value: "Allradantrieb" },
    { name: "Beheizbare Heckscheibe", value: "Beheizbare-Heckscheibe" },
    { name: "Beheizbare Außenspiegel", value: "Beheizbare-Außenspiegel" },
    { name: "Elektrische Heckklappe", value: "Elektrische-Heckklappe" },
    { name: "Elektrischer Außenspiegel", value: "Elektrischer-Außenspiegel" },
    { name: "Ganzjahresreifen", value: "Ganzjahresreifen" },
    { name: "Winterreifen", value: "Winterreifen" },
    { name: "Sommerreifen", value: "Sommerreifen" },
    { name: "Alufelgen", value: "Alufelgen" },
    { name: "Stahlfelgen", value: "Stahlfelgen" },
    { name: "Getönte Scheiben", value: "Getönte-Scheiben" },
    {
      name: "Kurvenfahrlicht / Abbiegelicht",
      value: "Kurvenfahrlicht-Abbiegelicht",
    },
    { name: "Tagfahrlicht", value: "Tagfahrlicht" },
    { name: "Umgebungsbeleuchtung", value: "Umgebungsbeleuchtung" },
    { name: "Scheibenbremse", value: "Scheibenbremse" },
    { name: "Metallic-Lackierung", value: "Metallic-Lackierung" },
  ],
};

const carinterior = {
  name: "carinterior",
  label: "Innenausstattung",
  placeholder: "Innenausstattung",
  type: "select",
  items: [
    { name: "12V-Steckdose", value: "12V-Steckdose" },
    { name: "Ambientebeleuchtung", value: "Ambientebeleuchtung" },
    { name: "Android-Auto", value: "Android-Auto" },
    { name: "AppleCarPlay", value: "AppleCarPlay" },
    { name: "Bluetooth", value: "Bluetooth" },
    { name: "Bordcomputer", value: "Bordcomputer" },
    { name: "HiFi-Soundsystem", value: "HiFi-Soundsystem" },
    { name: "CD-Player / Wechsler", value: "CD-Player-Wechsler" },
    { name: "Digitales Cockpit", value: "Digitales-Cockpit" },
    { name: "Farbdisplay", value: "Farbdisplay" },
    { name: "Head-Up-Display", value: "Head-Up-Display" },
    { name: "Touchscreen Display", value: "Touchscreen Display" },
    { name: "Beheizbare Sitze", value: "Beheizbare Sitze" },
    { name: "Elektrische Fensterheber", value: "Elektrische Fensterheber" },
    { name: "Elektrostarter", value: "Elektrostarter" },
    { name: "Freisprecheinrichtung", value: "Freisprecheinrichtung" },
    { name: "Gepäckraumbeleuchtung", value: "Gepäckraumbeleuchtung" },
    { name: "Glaspanoramadach", value: "Glaspanoramadach" },
    { name: "Tempomat", value: "Tempomat" },
    { name: "Servolenkung", value: "Servolenkung" },
    { name: "Navigationssystem", value: "Navigationssystem" },
    { name: "Klimaanlage", value: "Klimaanlage" },
    { name: "Klimaautomatik", value: "Klimaautomatik" },
    { name: "Multifunktionslenkrad", value: "Multifunktionslenkrad" },
  ],
};

const rentoptions = {
  name: "rentoptions",
  type: "checkboxgroup",
  items: [{ label: "zur Miete" }, { label: "zum Kauf" }],
};

const rentstateoptions = {
  name: "rentstateoptions",
  type: "checkboxgroup",
  items: [
    { label: "Vermietet" },
    { label: "Unvermietet" },
    { label: "Keine Angaben" },
  ],
};

const cardoorquantity = {
  name: "cardoorquantity",
  label: "Anzahl Türen",
  placeholder: "Anzahl Türen",
  type: "input",
};

const carseatquantity = {
  name: "carseatquantity",
  label: "Sitzplätze",
  placeholder: "Sitzplätze",
  type: "input",
};

const realestatetypes = {
  name: "realestatetypes",
  type: "select",
  items: [
    { name: "Häuser", value: "Häuser" },
    { name: "Wohnungen", value: "Wohnungen" },
    { name: "Grundstücke", value: "Grundstücke" },
    { name: "Gewerbeimmobilien", value: "Gewerbeimmobilien" },
    { name: "Sonstige Immobilien", value: "Sonstige-Immobilien" },
    { name: "Immobilien im Ausland", value: "Immobilien-im-Ausland" },
    { name: "Nachmieter", value: "Nachmieter" },
    { name: "WG's und Untermiete", value: "WGs-und-Untermiete" },
  ],
};

const avaiabledate = {
  name: "avaiabledate",
  label: "Verfügbar ab",
  placeholder: "Verfügbar ab",
  type: "date",
};

const realestatesize = {
  name: "realestatesize",
  label: "Wohnfläche in qm",
  placeholder: "Wohnfläche in qm",
  type: "input",
};

const roomcount = {
  name: "roomcount",
  label: "Zimmeranzahl",
  placeholder: "Zimmeranzahl",
  type: "input",
};

const realestatebuildphase = {
  name: "realestatebuildphase",
  itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
  placeholder: "Bauphase",
  type: "checkboxgroup",
  items: [
    { label: "Haus in Planung" },
    { label: "Haus im Bau" },
    { label: "Haus fertig gestellt" },
    { label: "Keine Angaben" },
  ],
};

const realestatecondition = {
  name: "realestatecondition",
  itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
  placeholder: "Objektzustand",
  type: "checkboxgroup",
  items: [
    { label: "Altbau" },
    { label: "Neubau" },
    { label: "Modernisiert" },
    { label: "Saniert" },
    { label: "Renovierungsbedürftig" },
  ],
};

const buildyear = {
  name: "buildyear",
  label: "Baujahr",
  placeholder: "Baujahr",
  type: "date",
};

const realestateupgradetype = {
  name: "realestateupgradetype",
  label: "Art der Modernisierung / Sanierung",
  placeholder: "Art der Modernisierung / Sanierung",
  type: "input",
};

const realestateequipmenttype = {
  name: "realestateequipmenttype",
  type: "checkboxgroup",
  items: [
    { label: "Normal" },
    { label: "Gehobener Standard" },
    { label: "Luxusausstattung" },
  ],
};

const realestateequipment = {
  name: "realestateequipment",
  label: "Ausstattungsmerkmale",
  type: "select",
  items: [
    { name: "Bad mit Fenster", value: "Bad mit Fenster" },
    { name: "Bad mit Wanne", value: "Bad mit Wanne" },
    { name: "Bad mit Dusche", value: "Bad mit Dusche" },
    { name: "Balkon / Terasse", value: "Balkon-Terasse" },
    { name: "Barrierefrei", value: "Barrierefrei" },
    { name: "Senioren geeignet", value: "Senioren-geeignet" },
    { name: "Garage / Stellplatz", value: "Garage-Stellplatz" },
    { name: "Gartennutzung /-zugang", value: "Gartennutzung-Gartenzugang" },
    { name: "Keller", value: "Keller" },
    { name: "Möbiliert / Teilmöbiliert", value: "Möbiliert-Teilmöbiliert" },
    { name: "Einbauküche", value: "Einbauküche" },
    { name: "Gäste-WC", value: "Gäste-WC" },
    { name: "Fußbodenheizung", value: "Fußbodenheizung" },
    { name: "Sauna", value: "Sauna" },
    { name: "Pool", value: "Pool" },
    { name: "Kamin", value: "Kamin" },
  ],
};

const realestatehousetype = {
  name: "realestatehousetype",
  label: "Immboilienart",
  type: "select",
  items: [
    { name: "Bauernhaus", value: "Bauernhaus" },
    { name: "Bungalow", value: "Bungalow" },
    { name: "Burg / Schloss", value: "Burg-Schloss" },
    { name: "Doppelhaushälfte", value: "Doppelhaushälfte" },
    { name: "Doppelhaus", value: "Doppelhaus" },
    { name: "Einfamilienhaus", value: "Einfamilienhaus" },
    { name: "Zweifamilienhaus", value: "Zweifamilienhaus" },
    { name: "Mehrfamilienhaus", value: "Mehrfamilienhaus" },
    { name: "Finca", value: "Finca" },
    { name: "Herrenhaus", value: "Herrenhaus" },
    { name: "Reihenendhaus", value: "Reihenendhaus" },
    { name: "Reihenmittelhaus", value: "Reihenmittelhaus" },
    { name: "Stadthaus", value: "Stadthaus" },
    { name: "Villa", value: "Villa" },
  ],
};

const realestatefloortype = {
  name: "realestatefloortype",
  label: "Wohnungstyp",
  type: "select",
  items: [
    { name: "Apartment", value: "Apartment" },
    { name: "Etagenwohnung", value: "Etagenwohnung" },
    { name: "Einliegerwohnung", value: "Einliegerwohnung" },
    { name: "Terassenwohnung", value: "Terassenwohnung" },
    { name: "Loft", value: "Loft" },
    { name: "Maisonette", value: "Maisonette" },
    { name: "Penthouse", value: "Penthouse" },
    { name: "Souterrain", value: "Souterrain" },
    { name: "Dachgeschoss", value: "Dachgeschoss" },
    { name: "Erdgeschoss", value: "Erdgeschoss" },
  ],
};

const rentnetto = {
  name: "rentnetto",
  label: "Nettokaltmiete in Euro",
  placeholder: "Nettokaltmiete in Euro",
  type: "input",
};

const rentwarm = {
  name: "rentwarm",
  label: "Warmmiete in Euro",
  placeholder: "Warmmiete in Euro",
  type: "input",
};

const realestatedeposit = {
  name: "realestatedeposit",
  label: "Kaution in Euro",
  placeholder: "Kaution in Euro",
  type: "input",
};

const realestatepartdeposit = {
  name: "realestatepartdeposit",
  itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
  label: "Zahlweise Kaution",
  type: "checkboxgroup",
  items: [
    { label: "vor Einzug" },
    { label: "in Raten" },
    { label: "Kautionsbürgschaft" },
    { label: "Überweisung" },
    { label: "Nach Absprache" },
  ],
};

const realestateheatingcosts = {
  name: "realestateheatingcosts",
  label: "Heizkosten",
  type: "checkboxgroup",
  items: [
    { label: "In Nbenkosten enthalten" },
    { label: "Separat zu zahlen" },
    { label: "Keine Angaben" },
  ],
};

const realestatefueltype = {
  name: "realestatefueltype",
  label: "Wesentlicher Energieträger",
  type: "select",
  items: [
    { name: "Öl", value: "Öl" },
    { name: "Gas", value: "Gas" },
    { name: "Kohle", value: "Kohle" },
    { name: "Strom", value: "Strom" },
    { name: "Fernwärme", value: "Fernwärme" },
    { name: "Wasserstoff", value: "Wasserstoff" },
    { name: "Erneuerbare Energie", value: "Erneuerbare Energie" },
  ],
};

const realestateheatingtype = {
  name: "realestateheatingtype",
  label: "Heizungsart",
  type: "select",
  items: [
    { name: "Wärmepumpe", value: "Wärmepumpe" },
    { name: "Pelletheizung", value: "Pelletheizung" },
    { name: "Solarthermie", value: "Solarthermie" },
    { name: "Gasheizung", value: "Gasheizung" },
    { name: "BHKW", value: "BHKW" },
    { name: "Brennstoffzelle", value: "Brennstoffzelle" },
    { name: "Fernwärme", value: "Fernwärme" },
    { name: "Ölheizung", value: "Ölheizung" },
  ],
};

const heatingcosts = {
  name: "heatingcosts",
  label: "Heizkostenbetrag",
  placeholder: "Heizkostenbetrag",
  type: "input",
};

const kwhm3usage = {
  name: "kwhm3usage",
  label: "Verbrauchskennwert in kWh / m²a",
  placeholder: "Art der Modernisierung / Sanierung",
  type: "input",
};

const realestateenergyefficiency = {
  name: "realestateenergyefficiency",
  label: "Effizienzklasse",
  placeholder: "Schadstoffklasse",
  type: "select",
  items: [
    { name: "A+", value: "A+" },
    { name: "A", value: "A" },
    { name: "B", value: "B" },
    { name: "C", value: "C" },
    { name: "D", value: "D" },
    { name: "E", value: "E" },
    { name: "F", value: "F" },
    { name: "G", value: "G" },
    { name: "H", value: "H" },
  ],
};

const realestateenergycertificate = {
  name: "realestateenergycertificate",
  itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
  label: "Energieausweis",
  type: "checkboxgroup",
  items: [
    { label: "liegt vor" },
    { label: "beantragt" },
    { label: "gesetzt / befreit" },
    { label: "Verbrauchsausweis" },
    { label: "Bedarfsausweis" },
    { label: "keine Angaben" },
  ],
};

const AssignmentCreateConfig = (t: TFunction): AssignmentCreateConfigType => ({
  standard: [
    { label: "Allgemeine Daten", type: "label" },
    type,
    pricetype,
    barcode,
    { label: t("main.sell.created.productData"), type: "label" },
    title,
    description,
    keywords,
    categories,
    quantity,
    [nettoprice, bruttoprice],
    offerprice,
    vat,
    hscode,
    shippingservices,
    reference,
    trackingid,
    trackingurl,
    deliverydate,
    agb,
  ],
  autos: [
    { label: "Fahrzeugtypen", type: "label" },
    hsntsn,
    cartypes,
    { label: "Zustand", type: "label" },
    condition,
    [tüv, lastinspection],
    [mileage, builddate, firstapproval],
    [fueltypes, co2km],
    [pollutantclass, co2efficiency],
    finedustcertificate,
    { label: "Antrieb", type: "label" },
    drive,
    [power, gears, emptyweight],
    { label: "Ausstattung", type: "label" },
    [cardesign, color],
    [carexterior, carinterior],
    [cardoorquantity, carseatquantity],
  ],
  immobilie: [
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.property"),
      type: "label",
    },
    realestatetypes,
    rentoptions,
    [avaiabledate, realestatesize, roomcount],
    rentstateoptions,
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.constructionPhase"),
      type: "label",
    },
    realestatebuildphase,
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.objectState"),
      type: "label",
    },
    realestatecondition,
    [buildyear, realestateupgradetype],
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.furnishing"),
      type: "label",
    },
    realestateequipmenttype,
    realestateequipment,
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.characteristics"),
      type: "label",
    },
    [realestatehousetype, realestatefloortype],
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.cost"),
      type: "label",
    },
    [rentnetto, rentwarm, realestatedeposit],
    realestatepartdeposit,
    {
      label: t("main.navLinks.createAd.tabs.create.tabs.extraCosts"),
      type: "label",
    },
    realestateheatingcosts,
    [realestatefueltype, realestateheatingtype],
    [heatingcosts, kwhm3usage, realestateenergyefficiency],
    realestateenergycertificate,
  ],
});

export default AssignmentCreateConfig;
