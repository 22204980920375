import React, { useEffect, useRef, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import "@/components/elements/posts/form/shared/editorTheme.css";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { MentionNode } from "@components/elements/posts/form/shared/MentionNode";
import PostText from "@components/elements/posts/shared/PostText";
import { NodeEventPlugin } from "@lexical/react/LexicalNodeEventPlugin";
import { Browser } from "@capacitor/browser";
import { HashtagNode } from "@lexical/hashtag";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  value: string;
  disableTopMargin?: boolean;
};

function onError(error: Error) {
  console.log(error);
}

const theme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  hashtag: "editor-link",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h2",
    h2: "editor-heading-h2",
    h3: "editor-heading-h2",
    h4: "editor-heading-h2",
    h5: "editor-heading-h2",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

const InitialEditorStatePlugin = ({
  initialState,
}: {
  initialState: string;
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const state = editor.parseEditorState(initialState);
    editor.setEditorState(state);
  }, [editor, initialState]);

  return null;
};

const TextView = (props: Props) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const body = useRef<HTMLDivElement>(null);

  useEffect(() => {
    body.current && body.current.clientHeight > 64 && setLargeText(true);
  }, [body]);
  try {
    JSON.parse(props.value);
    const initialConfig = {
      namespace: "PostView",
      editable: false,
      theme,
      onError,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        MentionNode,
        HashtagNode,
      ],
    };

    return (
      <div
        className="flex gap-3 w-full"
        css={[!props.disableTopMargin && tw`mt-3`]}
      >
        <div className="text-sm flex grow">
          <div className="w-full grid grid-cols-1 gap-1">
            <LexicalComposer initialConfig={initialConfig}>
              <div className="">
                <div
                  className="editor-inner"
                  css={[
                    tw`leading-4`,
                    !showMore && largeText && tw`max-h-[50px] overflow-hidden`,
                  ]}
                  ref={body}
                >
                  <RichTextPlugin
                    contentEditable={<ContentEditable />}
                    placeholder={<></>}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <InitialEditorStatePlugin initialState={props.value} />
                  <HistoryPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <HashtagPlugin />
                  <NodeEventPlugin
                    eventListener={(e) => {
                      const event = e as PointerEvent;
                      const element = event.target as HTMLElement;
                      const parent = element.parentElement as HTMLAnchorElement;

                      const hastagRegex = /#([a-zA-ZäöüÄÖÜß]+)/g;
                      if (hastagRegex.test(element.innerText)) {
                        navigate(`/tags/${element.innerText.replace("#", "")}`);
                        event.preventDefault();
                        return;
                      }
                      Browser.open({ url: parent.href });
                      event.preventDefault();
                    }}
                    eventType="click"
                    nodeType={LinkNode}
                  />
                  <NodeEventPlugin
                    eventListener={(e) => {
                      const event = e as PointerEvent;
                      const element = event.target as HTMLElement;
                      navigate(`/tags/${element.innerText.replace("#", "")}`);
                      event.preventDefault();
                    }}
                    eventType="click"
                    nodeType={HashtagNode}
                  />
                  <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
                {!showMore && largeText && (
                  <button
                    className="text-gray-500"
                    onClick={() => setShowMore((value) => !value)}
                  >
                    mehr
                  </button>
                )}
                {showMore && largeText && (
                  <button
                    className="text-gray-500"
                    onClick={() => setShowMore((value) => !value)}
                  >
                    weniger
                  </button>
                )}
              </div>
            </LexicalComposer>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return (
      <PostText text={props.value} disableTopMargin={props.disableTopMargin} />
    );
  }
};

export default TextView;
