import React from "react";
import tw from "twin.macro";
import PostCommentSection from "@components/elements/posts/comments/PostCommentSection";
import ModalWrapper from "@components/elements/shared/ModalWrapper";
import CreatePostComment from "@components/elements/posts/comments/CreatePostComment";
import { useAppSelector } from "@/state/hooks";

type Props = {
  onClose: () => void;
  comment_count: number;
  postId: string;
};

const CommentsPopup = ({ onClose, comment_count, postId }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const self = user;
  return (
    <ModalWrapper
      onCancel={onClose}
      title={comment_count + " Kommentare"}
      open={true}
      hideActions={true}
    >
      <div
        className="h-[85dvh] no-scrollbar overflow-y-auto"
        css={[self && tw`pb-[72px]`]}
      >
        <PostCommentSection postId={postId} hideCreateComment={true} />
      </div>
      {self && (
        <div className="absolute bottom-0 left-0 bg-white w-full p-4 sm:p-6">
          <CreatePostComment postId={postId} />
        </div>
      )}
    </ModalWrapper>
  );
};

export default CommentsPopup;
