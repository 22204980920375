import React from "react";
import NavSideLink from "@components/nav/NavSideLink";
import NavSideLinkList, { ListType } from "@components/nav/NavSideLinkList";
import { useTranslation } from "react-i18next";

type Props = {
  isMobile?: boolean;
  onClose?: () => void;
  linkIndex: string;
  setLinkIndex: (index: string) => void;
};

const NavSideUser = ({
  isMobile = false,
  onClose,
  linkIndex = "",
  setLinkIndex,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="border-gray-300 w-full top-[52px] z-10">
      <div className="flex flex-col gap-2">
        {NavSideLinkList(t).map((link: ListType) => (
          <NavSideLink
            link={link}
            key={link.href}
            isMobile={isMobile}
            onClose={onClose}
            linkIndex={linkIndex}
            setLinkIndex={(newIndex) => {
              setLinkIndex(newIndex);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NavSideUser;
