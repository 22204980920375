import React from "react";
import { Post } from "@/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import api from "@/api/api";
import useDebounce from "@/utils/useDebounce";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import CompactPost from "@pages/news/posts/CompactPost";

type Props = {
  userId: string;
  searchQuery: string;
};

type Response = {
  data: Post[];
  current_page: number;
  total_pages: number;
};
const PostLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);

  const list = useInfiniteQuery<Response>(
    [userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/feed?liked_by=${userId}&page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
        {list.data &&
          list.data.pages.map((page) =>
            page.data.map((item: Post) => (
              <CompactPost
                key={item.id}
                id={item.id}
                type={item.watch ? "video" : "text"}
                viewCount={item.view_count}
                likeCount={item.like_count}
                image={getMediaImage(item)}
              />
            )),
          )}
      </div>
      {list.hasNextPage && !list.isFetchingNextPage && (
        <ShowMoreButton onClick={() => list.fetchNextPage()} />
      )}
    </>
  );
};

const getMediaImage = (post: Post) => {
  if (post.media && post.media[0]) {
    if (post.media[0].data_url === "image")
      return post.media[0].data_url + "/tile";
    if (post.media[0].type === "video")
      return post.media[0].data_url + "/thumbnails/thumbnail.jpg";
  }
  if (post.link_preview && post.link_preview.image) {
    return post.link_preview.image + "/tile";
  }
  return "/images/default_header_profile.png";
};

export default PostLikes;
