import React, { useEffect, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import tw from "twin.macro";
import MyProfileWidget from "@/components/elements/MyProfileWidget";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";

const NavSideMenuElement = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const location = useLocation();
  const placeholder = `/images/placeholder/${user ? user.type : ""}.png`;
  const navigate = useNavigate();

  const closeMenu = () => {
    setSideMenu(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div className="bg-transparent">
      {sideMenu && (
        <div className="fixed w-full text-black bg-white pb-6 px-4 top-0 bottom-0 left-0 z-50 xl:hidden pt-[calc(env(safe-area-inset-top)+0.5rem)]">
          <MyProfileWidget isMobile={true} onClose={closeMenu} />
        </div>
      )}
      <div css={[tw`relative flex flex-col cursor-pointer items-center py-0`]}>
        {!user && (
          <button
            className="group"
            css={[tw`relative flex flex-col cursor-pointer items-center py-0`]}
            onClick={() => navigate("/login")}
          >
            <div css={[tw`w-7 h-7`]}>
              <UserCircleIcon className="heroicon-stroke-w-1.2" />
            </div>
            <span
              css={[
                tw`hidden md:block text-xs text-center font-semibold`,
                tw`text-gray-600 opacity-60`,
              ]}
            >
              Login
            </span>
          </button>
        )}
        {user && (
          <button
            css={[tw`w-full h-full flex justify-center text-darkblue`]}
            onClick={() => {
              setSideMenu((value) => !value);
            }}
          >
            <div
              css={[
                tw`rounded-full border border-gray-300 relative bg-white shrink-0 w-[28px] h-[28px]`,
              ]}
            >
              <div
                css={[
                  tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`,
                ]}
              >
                <img
                  className="w-full"
                  src={user ? user.avatar : placeholder}
                />
              </div>
            </div>
          </button>
        )}
        <span
          css={[
            tw`hidden md:block text-xs text-center font-semibold`,
            tw`text-gray-600 opacity-60`,
          ]}
        ></span>
      </div>
    </div>
  );
};

export default NavSideMenuElement;
