import React, { Fragment } from "react";
import {
  CheckIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { JobType } from "@/utils/types/JobType";
import Panel from "@components/box/Panel";
import { useTranslation } from "react-i18next";

type Props = {
  job: JobType;
  showButtons?: boolean;
  minimized?: boolean;
  showStats?: boolean;
  key?: string;
};

const JobsListItem = ({
  job,
  showButtons = false,
  minimized = false,
  showStats = true,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Panel>
      <div className="text-sm">
        <NavLink to={"/jobs/" + job.id}>
          <div className="aspect-square flex justify-center items-center">
            <img
              src={job.user.avatar}
              className={`${minimized && "rounded-lg"}`}
            />
          </div>
          {!minimized && (
            <Fragment>
              <div className="text-sm line-clamp-1 text-center text-ellipsis overflow-x-hidden">
                {job.title}
              </div>
              {showStats && (
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row justify-between text-gray-600 text-xs">
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.followers.title")}</div>
                      <div>0</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.seen.title")}</div>
                      <div>0</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.likes.title")}</div>
                      <div>0</div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </NavLink>
        {showButtons && (
          <div className="flex justify-between">
            <PencilIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <ShareIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <CheckIcon className="bg-green-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
            <TrashIcon className="bg-red-600 rounded-full p-0.5 w-4 h-4 text-white heroicon-stroke-w-2.4" />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default JobsListItem;
