import React, { useState } from "react";
import BackButton from "@/components/nav/BackButton";
import Input from "@/components/elements/input/Input";
import Button from "@/components/elements/input/Button";
import Checkbox from "@/components/elements/input/Checkbox";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const Support = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form className="grid grid-flow-row gap-2 text-sm">
      <BackButton />
      <img
        src="/images/info/projekt-auftragsvergabe-header-5.jpg"
        className="h-[300px] w-full object-cover"
      />
      <div className="mb-2">
        Du hast Fragen? Anmerkungen? Möchtest einen Verstoß melden? Nutze unser
        Kontaktformular um den Support von tradefoox.com zu erreichen. Wir
        versuchen schnellstmöglich Dein Anliegen zu bearbeiten.
      </div>
      <h1 className="font-semibold">Kontaktiere uns</h1>

      <Input
        label="Firma"
        type="text"
        name="company"
        placeholder="Bitte trage Deinen vollständigen Firmennamen ein."
        value={company}
        onChange={(e) => {
          setCompany(e);
        }}
        required
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <Input
          label="Vorname"
          type="text"
          name="surname"
          placeholder="Max"
          value={surname}
          onChange={(e) => {
            setSurname(e);
          }}
          required
        />
        <Input
          label="Nachname"
          type="text"
          name="name"
          placeholder="Mustermann"
          value={lastname}
          onChange={(e) => {
            setLastname(e);
          }}
          required
        />
        <Input
          label="E-Mail"
          type="text"
          name="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => {
            setEmail(e);
          }}
          required
        />
        <Input
          label="Telefon"
          type="text"
          name="phone"
          placeholder="+49 1234 123456"
          value={phone}
          onChange={(e) => {
            setPhone(e);
          }}
          required
        />
      </div>
      <div>
        Deine Nachricht
        <TextareaAutosize
          minRows={3}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
          required
        />
      </div>

      <Checkbox
        name="agb"
        required
        checked={privacyCheck}
        onChange={(e) => setPrivacyCheck(e)}
      >
        Ich habe die{" "}
        <NavLink to="/privacy" target="_blank">
          Datenschutzerklärung
        </NavLink>{" "}
        gelesen und akzeptiert
      </Checkbox>
      <Checkbox
        name="newsletter"
        checked={newsletterCheck}
        onChange={(e) => setNewsletterCheck(e)}
      >
        Ich möchte den TRADEFOOX-Newsletter kostenlos erhalten.
      </Checkbox>
      <Button submit>Senden</Button>
    </form>
  );
};

export default Support;
