import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import AuthWrapper from "@pages/auth/AuthWrapper";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";
import resetPassword from "@/api/auth/reset_password";
import BackButton from "@/components/nav/BackButton";

const ResetPasswordContainer = () => {
  const [password, setPassword] = useState<string>("");
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const loginMutation = useMutation(["reset-password"], {
    mutationFn: () => {
      return resetPassword({
        request_id: code ?? "",
        password: password,
      });
    },
    onSuccess: () => {
      alert("Password reset successful, you can now log in!");
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (code == null) {
    alert("no code provided.");
    return <></>;
  }

  return (
    <AuthWrapper>
      <BackButton stepback={true} />
      <div className="text-darkblue font-medium text-xl mb-4">
        TRADEFOOX – wir verbinden Euch.
        <br /> Ein Netzwerk voller Möglichkeiten
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          name="new password"
          label="new password"
          value={password}
          placeholder="**************"
          type="password"
          onChange={(e) => setPassword(e)}
        />
        <div className="gap-x-4 mt-2 flex ml-auto mr-auto">
          <Button onClick={() => loginMutation.mutate()}>Reset Password</Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ResetPasswordContainer;
