import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";

type Props = { user: User; minimized?: boolean };
const EmployeeContainer = ({ user, minimized = true }: Props) => {
  return (
    <Panel>
      <div>
        <div className="text-sm flex flex-col w-full gap-2">
          <UserWidget
            user={user}
            showName={true}
            follow={false}
            verified={true}
          />

          {!minimized && (
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-col justify-between text-xs">
                <div className="flex flex-row gap-x-1">
                  <div className="font-semibold shrink-0">PLZ, Ort:</div>
                  <div className="truncate"></div>
                </div>
                <div className="flex flex-row gap-x-1">
                  <div className="font-semibold">Abteilung:</div>
                  <div>{user.person?.department}</div>
                </div>
                <div className="flex flex-row gap-x-1">
                  <div className="font-semibold">Position:</div>
                  <div>{user.person?.job_position}</div>
                </div>
                <div className="flex flex-row gap-x-1">
                  <div className="font-semibold">Website:</div>
                  <div></div>
                </div>
                <div className="flex flex-row gap-x-1">
                  <div className="font-semibold">Telefon:</div>
                  <div>{user.phone_number}</div>
                </div>
                <div className="flex flex-row gap-x-1">
                  <div>{user.email}</div>
                </div>
              </div>
            </div>
          )}
          {minimized && (
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-col justify-between text-xs">
                <div className="flex flex-row justify-center gap-x-1">
                  <div className="font-semibold">Abteilung:</div>
                  <div>{user.person?.department}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default EmployeeContainer;
