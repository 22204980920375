import React, { useState } from "react";
import Tab from "@/components/menu/Tab";
import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Post } from "@/utils/post";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import WatchThumbnailList from "@components/elements/posts/watch/WatchThumbnailList";
import Input from "@/components/elements/input/Input";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import api from "@/api/api";
import SmallPostsContainer from "@pages/news/posts/SmallPostsContainer";
import CompactPost from "@pages/news/posts/CompactPost";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const tabs = (t: TFunction) => [
  { label: t("main.header.links.watch"), key: "watch", url: "watch" },
  { label: t("main.header.links.feed"), key: "news", url: "feed" },
  {
    label: t("main.header.links.advertisements"),
    key: "assignments",
    url: "inserate",
  },
  { label: t("main.header.links.jobs"), key: "jobs", url: "jobs" },
  {
    label: t("main.header.links.marketplace"),
    key: "marketplace",
    url: "marketplace",
  },
];

type Response = {
  data: Array<{
    id: string;
    view_count: number;
    like_count: number;
    picture: string;
    watch: boolean;
  }>;
  current_page: number;
  total_pages: number;
};

type Props = {
  tab?: string;
};

const BookmarksContainer = ({ tab = "watch" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();

  const posts = useInfiniteQuery<Response>(
    ["posts", "bookmarks", "tab"],
    async ({ pageParam = 0 }) => {
      const res = await api.get("/api/v1/bookmarks?page=" + pageParam);
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const { user } = useAppSelector((state) => state.user);
  if (!user) return null;

  return (
    <>
      <TabMenu>
        {tabs(t).map((tab: any) => {
          return (
            <>
              <TabLink to={"/bookmarks/" + tab.url}>{tab.label}</TabLink>
            </>
          );
        })}
      </TabMenu>
      <Input
        name="search-all"
        placeholder="Suchen"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      {tab === "watch" && <WatchThumbnailList bookmarked={true} />}
      {tab === "feed" && (
        <>
          <div className="grid grid-flow-row gap-2">
            <div className="grid grid-cols-3 lg:grid-cols-4 gap-2">
              {posts.data?.pages.map(
                (page) =>
                  page &&
                  page.data.map((post) => {
                    return (
                      <>
                        <CompactPost
                          id={post.id}
                          type={post.watch ? "video" : "text"}
                          viewCount={post.view_count}
                          likeCount={post.like_count}
                          image={post.picture || "/placeholder.png"}
                        />
                      </>
                    );
                  }),
              )}
            </div>
          </div>
          {(posts.hasNextPage || posts.isFetchingNextPage) && (
            <ShowMoreButton onClick={() => posts.fetchNextPage()} />
          )}
        </>
      )}
      <Tab isActive={tab === "assignments"}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4"></div>
      </Tab>
      <Tab isActive={tab === "jobs"}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4"></div>
      </Tab>
      <Tab isActive={tab === "marketplace"}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4"></div>
      </Tab>
    </>
  );
};

export default BookmarksContainer;
