import React, { useContext } from "react";
import NavBar from "@components/nav/NavBar";
import { Outlet } from "react-router-dom";
import NavBarBottom from "@components/nav/NavBarBottom";
import MyProfileWidget from "@components/elements/MyProfileWidget";
import Suggestions from "@components/elements/sidebar/suggestions/Suggestions";
import MyCart from "@/components/elements/sidebar/widgets/MyCart";
import Footer from "@components/elements/sidebar/widgets/Footer";
import SideBarUserElement from "@components/elements/sidebar/SideBarUserElement";
import { useQuery } from "@tanstack/react-query";
import Panel from "@/components/box/Panel";
import usePopupModal from "@/state/modal/usePopupModal";
import { AppContext } from "@/App";
import tw from "twin.macro";
import api from "@/api/api";
import AppLink from "@/components/elements/shared/AppLink";
import CreateFormButton from "@/components/nav/CreateFormButton";
import { useAppSelector } from "@/state/hooks";

const sidebarConfig = {
  left: [
    {
      key: "person_suggestions",
      title: "Nutzervorschläge",
      link: "/kontakte/für_dich/personen",
    },
    {
      key: "company_suggestions",
      title: "Firmenvorschläge",
      link: "/kontakte/für_dich/firmen",
    },
    {
      key: "group_suggestions",
      title: "Gruppenvorschläge",
      link: "/gruppen/für_dich",
    },
    {
      key: "birthdays",
      title: "Geburtstage",
      link: "/kontakte/meine_kontakte/geburtstage",
      login: true,
    },
    { key: "managed_pages", title: "Verwaltete Seiten", link: "", login: true },
  ],
  right: [
    { key: "new_users", title: "Neu", link: "/kontakte/für_dich/alle" },
    {
      key: "visitors",
      title: "Meine Profilbesucher",
      link: "/kontakte/meine_kontakte/statistik/besucher",
      login: true,
    },
    {
      key: "my_followers",
      title: "Meine Follower",
      link: "/kontakte/follower",
      login: true,
    },
    {
      key: "my_friends",
      title: "Meine Freunde",
      link: "/kontakte/meine_kontakte/übersicht",
      login: true,
    },
    { key: "last_visited", title: "Zuletzt angesehen", link: "", login: true },
    { key: "remember", title: "Merkliste", link: "/bookmarks", login: true },
  ],
};

const MobileMainWrapper = () => {
  const appContext = useContext(AppContext);
  const popupModal = usePopupModal();

  return (
    <div
      css={[
        tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen || appContext?.topNavHidden) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <main className="overflow-x-hidden scrollbar-none overflow-y-scroll">
        <Outlet />
      </main>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
    </div>
  );
};

export default MobileMainWrapper;
