import React, { PropsWithChildren, useRef, useState } from "react";
import api from "@/api/api";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@/components/elements/input/Button";
import Input from "@/components/elements/input/Input";
import ImageUpload from "@/components/elements/input/ImageUpload";
import { ChatType } from "@/utils/types/ChatType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { User } from "@/utils/user";
import GroupComboBox from "@/components/elements/form/GroupComboBox";
import { Media } from "@/utils/types/Media";
import AvatarEditElement from "@pages/messenger/AvatarEditElement";
import isMobile from "@/utils/isMobile";
import tw from "twin.macro";

type Props = {
  selectedForm: string;
  setSelectedForm: (e: string) => void;
  selectedChats: Array<string>;
  setSelectedChats: (e: Array<string>) => void;
  setSelectedChat: (e: string | false) => void;
  onClose: () => void;
  chats: Array<ChatType>;
};

const MessengerGroups = ({ setSelectedChats, onClose }: Props) => {
  const [name, setName] = useState<string>("");
  const [members, setMembers] = useState<Array<User>>([]);
  const imageRef = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState<number>(0);
  const [image, setImage] = useState<Media | undefined>(undefined);

  const queryClient = useQueryClient();

  const setSelectedUsers = (data: Array<User>) => {
    setMembers(data);
  };

  const upload = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const res = await api.post("/api/v1/media/upload", formData, config);
      return res.data.data;
    },
    onSuccess: (data) => {
      setImage(data);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createGroup = useMutation(["send-message"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/chats/groups", {
        name,
        members: members.map((item) => item.id),
        avatar: image?.id,
      });
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      queryClient.invalidateQueries({
        queryKey: ["messages"],
      });
      setStep(0);
      setSelectedChats([]);
      onClose();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Wrapper>
      {step === 0 && (
        <div className="flex flex-col gap-2.5 px-3 pb-0.5 mt-[env(safe-area-inset-top)]">
          <div className="flex items-center gap-2 px-3 text-gray-900 text-sm max-md:text-center mt-[env(safe-area-inset-top)]">
            <ArrowLeftIcon
              className="hover:bg-gray-300 rounded-full cursor-pointer w-6 h-6"
              onClick={() => {
                onClose();
              }}
            />
            Neue Gruppe
          </div>
          <GroupComboBox
            selectedUsers={members}
            setSelectedUsers={setSelectedUsers}
            onSave={() => setStep((value) => value + 1)}
            label="Kontakt suchen"
          />
        </div>
      )}
      {step === 1 && (
        <div className="flex flex-col gap-2.5 px-3 pb-0.5 mt-[env(safe-area-inset-top)]">
          <div className="flex items-center gap-2 px-3 text-gray-900 text-sm max-md:text-center mt-[env(safe-area-inset-top)]">
            <ArrowLeftIcon
              className="hover:bg-gray-300 rounded-full cursor-pointer w-6 h-6"
              onClick={() => setStep((value) => value - 1)}
            />
            Neue Gruppe
          </div>
          <input
            type="file"
            className="hidden"
            ref={imageRef}
            multiple={false}
            onChange={(e) => {
              if (e.target.files) {
                upload.mutate(e.target.files[0]);
              }
            }}
          />
          <AvatarEditElement
            image={image}
            loading={upload.isLoading}
            onClick={() => imageRef.current?.click()}
          />
          <Input
            name="group_name"
            label="Gruppenname"
            required
            maxLength={25}
            onChange={(value) => setName(value)}
          />
          <div className="flex gap-2">
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => {
                onClose();
                setSelectedChats([]);
              }}
            >
              Abbrechen
            </Button>
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => createGroup.mutate()}
            >
              Erstellen
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = (props: PropsWithChildren) => {
  if (isMobile) {
    return (
      <div className="fixed top-0 left-0 w-screen h-screen h-[100dvh] z-50 p-2 bg-white">
        {props.children}
      </div>
    );
  } else return props.children;
};

export default MessengerGroups;
