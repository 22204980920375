import React, { useState } from "react";
import { CommentType } from "@/utils/types/CommentType";
import getName from "@/utils/getName";
import { formatDate } from "@/utils/formatDate";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserWidget from "@components/elements/UserWidget";
import CommentsDropdown from "@/pages/news/CommentsDropdown";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import TextView from "@components/elements/textedit/TextView";
import PostsActionBarItem from "@pages/news/posts/PostsActionBarItem";
import { ChatBubbleOvalLeftIcon, HeartIcon } from "@heroicons/react/24/outline";
import tw from "twin.macro";
import CreatePostComment from "@components/elements/posts/comments/CreatePostComment";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import SharePopup from "@components/popup/SharePopup";
import { useAppSelector } from "@/state/hooks";
import { formatDateDifference } from "@components/elements/PostWidget";

type Props = {
  level?: number;
  comment: CommentType;
  comments: CommentType[];
  viewCount?: number;
  postId: string;
  key?: string;
  parentId?: string;
};
const PostComment = ({ comment, ...props }: Props) => {
  const [answer, setAnswer] = useState<boolean>(false);
  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false);
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.user);
  const loginModal = useLoginModal();

  const queryClient = useQueryClient();

  const like = useMutation([`like-${comment.id}-${user ? user.id : ""}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/comments/${comment.id}/like`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unlike = useMutation([`unlike-${comment.id}-${user ? user.id : ""}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/comments/${comment.id}/unlike`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      <div className="flex gap-3 relative group/comment">
        <CommentsDropdown comment={comment} />
        <UserWidget user={comment.author} size={30} />
        <div className="w-[calc(100%-42px)]">
          <div
            className={
              "border-2 border-lightgray px-1.5 py-1 rounded-xl bg-lightgray"
            }
          >
            <div className="flex gap-1 w-full">
              <div
                className="text-sm font-semibold text-lightblue truncate"
                style={{ maxWidth: "calc(100% - 150px)" }}
              >
                {getName(comment.author)}
              </div>
              <div className="text-xs leading-5 text-gray-600 shrink-0">
                -{" "}
                {formatDateDifference(new Date(), new Date(comment.created_at))}
              </div>
            </div>
            <div className="break-all text-xs whitespace-pre-wrap">
              <TextView value={comment.text} disableTopMargin />
            </div>

            <div className="flex mt-1 text-darkblue relative gap-4">
              <PostsActionBarItem
                onClick={() => {
                  user ? setAnswer((value) => !value) : loginModal.open();
                }}
                label="Kommentieren"
                count={comment.comment_count}
              >
                <ChatBubbleOvalLeftIcon />
              </PostsActionBarItem>
              <PostsActionBarItem
                label="Like"
                count={comment.like_count}
                onClick={() =>
                  comment.liked ? unlike.mutate() : like.mutate()
                }
                color={comment.liked ? tw`bg-pink-100` : tw``}
              >
                {comment.liked ? (
                  <HeartIconSolid className="peer cursor-pointer text-pink-700" />
                ) : (
                  <HeartIcon className="peer cursor-pointer" />
                )}
              </PostsActionBarItem>

              {sharePopupOpen && (
                <SharePopup
                  postId={props.postId}
                  userId=""
                  content={{
                    title: "News",
                    body: "https://www.tradefoox.com/news/" + props.postId,
                  }}
                  onClose={() => setSharePopupOpen(false)}
                />
              )}
            </div>
          </div>
          {!showReplies && comment.comment_count !== 0 && (
            <div
              className="text-sm flex cursor-pointer mt-1"
              onClick={() => setShowReplies(true)}
            >
              <ChatBubbleOvalLeftIcon className="h-5 w-5 px-1" />
              <span>{comment.comment_count} Antworten</span>
            </div>
          )}
        </div>
      </div>
      {answer && (
        <CreatePostComment
          parentId={
            props.level && props.level > 1 ? props.parentId : comment.id
          }
          answer={comment}
          postId={props.postId}
          onSuccess={() => setAnswer(false)}
        />
      )}
      {showReplies && (
        <div className="grid grid-cols-1 gap-3 pl-10">
          {props.comments.map(
            (child) =>
              child.parent_id === comment.id && (
                <PostComment
                  viewCount={props.viewCount}
                  comments={props.comments}
                  level={props.level != undefined ? props.level + 1 : undefined}
                  postId={props.postId}
                  key={child.id}
                  parentId={comment.id}
                  comment={child}
                />
              ),
          )}
        </div>
      )}
    </>
  );
};

export default PostComment;
