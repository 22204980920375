import api from "@/api/api";


export declare type NotificationTokenResponse = {
    token: string;
}

export default async function getNotificationToken(): Promise<NotificationTokenResponse> {
    const res = await api.get("/api/v1/auth/notifications/token");

    return res.data;
}