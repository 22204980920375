import React, { useState } from "react";
import tw from "twin.macro";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import useDebounce from "@/utils/useDebounce";
import api from "@/api/api";
import Button from "@components/elements/input/Button";
import Input from "@components/elements/input/Input";
import { useAppSelector } from "@/state/hooks";
import UserWidget from "@components/elements/UserWidget";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  onSave?: () => void;
  label: string;
  setSelectedUsers: (user: Array<User>) => void;
  selectedUsers: Array<User>;
};

const GroupComboBox = ({
  label,
  onSave,
  setSelectedUsers,
  selectedUsers,
}: Props) => {
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 100);

  const addSelectedUsers = (contact: User) => {
    const isUserAdded = !!selectedUsers.find((user) => user.id === contact.id);
    if (!isUserAdded) {
      setQuery("");
      setSelectedUsers([...selectedUsers, contact]);
    }
  };
  const { user } = useAppSelector((state) => state.user);

  const contacts = useQuery<Response>(
    ["contacts", query, debouncedSearchTerm],
    async () => {
      if (query) {
        const { data } = await api.get(
          `/api/v1/contacts/for_you?page=0&q=${debouncedSearchTerm}`,
        );
        return data;
      }
      const { data } = await api.get("/api/v1/contacts/for_you");
      return data;
    },
  );

  return (
    <div className="mt-1 w-full rounded-2xl bg-white text-base focus:outline-none sm:text-sm">
      <div className="flex flex-row gap-2 overflow-x-auto no-scrollbar px-3 pb-0.5">
        {selectedUsers.map((user) => (
          <div key={user.id}>
            <UserWidget user={user} follow={false} online={false} size={45} />
            <span css={[tw`line-clamp-1 text-center w-12 text-xs`]}>
              {getName(user)}
            </span>
          </div>
        ))}
      </div>
      {onSave && (
        <div className="px-2">
          <Button variant={Button.Variants.Transparent} onClick={onSave}>
            Weiter
          </Button>
        </div>
      )}
      <div className="z-2 w-full h-[2px] bg-gray-300 mt-4" />
      <div className="grid grid-cols-1 gap-2">
        <div className="px-2">
          <Input label={label} name="" value={query} onChange={setQuery} />
        </div>
        <div className="overflow-y-auto grid grid-cols-1 gap-2 no-scrollbar">
          {contacts.data &&
            contacts.data.data.slice(0, 10).map((contact) => (
              <div
                onClick={() => {
                  if (selectedUsers.find((user) => user.id === contact.id)) {
                    setSelectedUsers(
                      selectedUsers.filter((item) => item.id !== contact.id),
                    );
                  } else {
                    addSelectedUsers(contact);
                  }
                }}
                key={contact.id}
                className="relative select-none py-1.5 cursor-pointer text-gray-900 pl-1 hover:bg-gray-200"
              >
                <div className="flex items-center">
                  <UserWidget
                    user={contact}
                    follow={false}
                    online={false}
                    size={45}
                  />
                  <span css={[tw`ml-3 line-clamp-1 text-left`]}>
                    {getName(contact)}
                  </span>
                </div>
                <span
                  css={[
                    tw`absolute inset-y-0 right-0 flex items-center pr-1`,
                    tw`text-indigo-600`,
                  ]}
                >
                  <input
                    type="radio"
                    className="outline-none ring-0 accent-indigo-600 "
                    checked={
                      !!selectedUsers.find((user) => user.id === contact.id)
                    }
                  />
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GroupComboBox;
