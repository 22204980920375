import React from "react";
import useUser from "@/api/user/useUser";
import getName from "@/utils/getName";

type Props = {
  userId: string;
};

const ProfileDetailsUser = ({ userId }: Props) => {
  const user = useUser(userId ?? "");

  if (!user.isSuccess) return <></>;

  return (
    <>
      <div className="flex flex-wrap gap-1">
        Benutzername
        <div className="font-semibold">{getName(user.data)}</div>
      </div>
    </>
  );
};

export default ProfileDetailsUser;
