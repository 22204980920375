import React, { useState, useContext, useRef } from "react";
import Panel from "@components/box/Panel";
import LegacyLinkPreview from "@components/elements/LegacyLinkPreview";
import Schedule from "@components/elements/shared/Schedule";
import Button from "@components/elements/input/Button";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import ScheduleButton from "@components/elements/shared/buttons/ScheduleButton";
import PostTextEdit from "@components/elements/posts/form/shared/PostTextEdit";
import {
  AtSymbolIcon,
  CalendarDaysIcon,
  HashtagIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { formatDate } from "@/utils/formatDate";
import PostUserSelect from "@components/elements/posts/form/shared/PostUserSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PostMedia from "@components/elements/posts/form/shared/PostMedia";
import tw from "twin.macro";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import { AppContext } from "@/App";
import api from "@/api/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  onSuccess: () => void;
  onClose?: () => void;
};

export const findAutolink = (node: any): string | false => {
  // Base case: If the current node is of type 'autolink', return its URL
  if (node.type === "autolink" || node.type === "link") {
    return node.url;
  }

  // If the current node has children, iterate through them recursively
  if (node.children) {
    for (const child of node.children) {
      const result = findAutolink(child);
      if (result) {
        return result; // Return the URL if found
      }
    }
  }

  // If the function reaches here, it means the 'autolink' type was not found in this branch
  return false;
};

const CreatePost = () => {
  const appContext = useContext(AppContext);

  if (window.innerWidth < 768) {
    if (appContext?.setFullscreen) appContext.setFullscreen(true);
  }

  const navigate = useNavigate();

  const [openSchedule, setOpenSchedule] = useState(false);
  const [adult, setAdult] = useState(false);
  const [text, setText] = useState("");
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const [disabledLink, setDisabledLink] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [atClick, setAtClick] = useState<() => void>();
  const [hashtagClick, setHashtagClick] = useState<() => void>();
  const [addMediaClick, setAddMediaClick] = useState<() => void>();
  const { t } = useTranslation();

  let parsed = { root: undefined };

  if (!disabledLink) {
    try {
      parsed = JSON.parse(text);
    } catch (e) {
      /* empty */
    }
  }

  const link = !disabledLink && parsed.root && findAutolink(parsed.root);

  const send = useMutation(["post"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/posts", {
        text,
        adult,
        media: media.map((m) => m.id),
        link: disabledLink ? undefined : link || "",
        set_active_at: scheduleDate,
        link_preview_id: linkPreviewId,
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate("/news/für_dich");
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Dein Beitrag konnte nicht erstellt werden."
          title="Feed"
        />,
      );
    },
  });

  return (
    <div className="fixed lg:relative top-0 left-0 w-full h-full bg-white z-50 overflow-y-auto pt-[env(safe-area-inset-top)]">
      <Panel mobileBorder={false}>
        <div css={[tw`grid grid-flow-row gap-2`, openSchedule && tw`hidden`]}>
          <div className="flex flex-row gap-5 items-center font-semibold">
            <button onClick={() => navigate("/news")}>
              <XMarkIcon className="w-8 h-8 hover:bg-gray-200 rounded-full p-1.5" />
            </button>
            <PostUserSelect />
          </div>

          {scheduleDate && (
            <button
              className="flex flex-row gap-2 items-center text-xs hover:underline"
              onClick={() => {
                setOpenSchedule(true);
              }}
            >
              <CalendarDaysIcon className="w-4 h-4" />
              Wird veröffentlicht am{" "}
              {formatDate(new Date(scheduleDate).toISOString())}.
            </button>
          )}

          <PostMedia
            onChange={(data) => setMedia((value) => [...value, data])}
            media={media}
            onDelete={(id) =>
              setMedia((data) => data.filter((item) => item.id !== id))
            }
            addClick={{
              getFunction: addMediaClick,
              setFunction: setAddMediaClick,
            }}
          />
          {link && (
            <LegacyLinkPreview
              link={link}
              onChange={setLinkPreviewId}
              onDelete={() => {
                setLinkPreviewId(undefined);
                setDisabledLink(true);
              }}
            />
          )}
          <PostTextEdit
            value={text}
            onChange={setText}
            textRef={textareaRef}
            atClick={{ getFunction: atClick, setFunction: setAtClick }}
            hashtagClick={{
              getFunction: hashtagClick,
              setFunction: setHashtagClick,
            }}
          />
          <div className="flex flex-row items-baseline justify-between max-lg:fixed max-lg:bottom-4 w-[calc(100%-30px)]">
            <ScheduleButton
              onClick={() => setOpenSchedule((value) => !value)}
            />
            <button onClick={atClick}>
              <AtSymbolIcon className="w-6 h-6 text-darkblue" />
            </button>
            <button onClick={hashtagClick}>
              <HashtagIcon className="w-6 h-6 text-darkblue" />
            </button>
            <button onClick={addMediaClick}>
              <PhotoIcon className="w-6 h-6 text-darkblue" />
            </button>
            <div className="max-sm:hidden"></div>

            <div className="w-fit">
              <Button onClick={send.mutate}>
                <PaperAirplaneIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
        {openSchedule && (
          <Schedule
            onClose={() => setOpenSchedule(false)}
            onChange={(date) => setScheduleDate(date)}
            selectedDate={scheduleDate}
          />
        )}
      </Panel>
    </div>
  );
};

export default CreatePost;
