import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import HashtagItem from "@components/elements/hashtags/HashtagItem";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import api from "@/api/api";

type Hashtag = {
  total_views: number;
  times_used: number;
  first_user_id: string;

  total_like_count: number;
  hashtag: string;

  media_id?: string;
  media_key?: string;
  media_type?: string;
  media_filename?: string;
  media_thumbnail?: string;
  media_data_url?: string;

  author_name: string;
  author_id: string;
  author_avatar: string;
  author_type: string;
};

type Response = {
  data: Array<Hashtag>;
  current_page: number;
  total_pages: number;
};

type Props = {
  filter: string;
};

const HashtagList = ({ filter }: Props) => {
  const watch = filter === "watch";
  const hashtags = useInfiniteQuery<Response>(
    ["hashtags", watch],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/hashtags?page=" + pageParam + "&watch=" + watch
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    }
  );
  if (!hashtags.isSuccess) return <></>;

  const getImageSrc = (hashtag: Hashtag) => {
    if (
      hashtag.media_type !== "video" &&
      hashtag.media_key &&
      hashtag.media_filename
    ) {
      return hashtag.media_data_url + "/tile";
    }
    if (hashtag.media_type === "video" && hashtag.media_thumbnail) {
      return hashtag.media_thumbnail;
    }
    return undefined;
  };

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-4 gap-2">
        {hashtags.data.pages.map(
          (page) =>
            page.data &&
            page.data.map((hashtag: Hashtag) => {
              return (
                <HashtagItem
                  tab={filter}
                  key={hashtag.hashtag}
                  tag={hashtag.hashtag}
                  type={hashtag.media_type}
                  viewCount={hashtag.total_views}
                  likeCount={hashtag.total_like_count}
                  image={getImageSrc(hashtag)}
                />
              );
            })
        )}
      </div>
      {(hashtags.hasNextPage || hashtags.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => hashtags.fetchNextPage()} />
      )}
    </>
  );
};

export default HashtagList;
