import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import Button from "@components/elements/input/Button";

enum MessageVariants {
  Error = "error",
  Info = "info",
  OK = "ok",
  Warning = "warning",
}

enum ButtonVariants {
  None = "none",
  Clean = "clean",
  OK = "ok",
  YesNo = "yesno",
  YesNoCancel = "yesnocancel",
  RetryCancel = "retrycancel",
}

type Props = {
  title?: string;
  message?: string;
  timeout?: number;
  variant?: MessageVariants;
  buttons?: ButtonVariants;
  yesClick?: () => void;
  noClick?: () => void;
  retryClick?: () => void;
  cancelClick?: () => void;
  onClose: () => void;
};

const _Alert = ({
  title = "title",
  message = "message text",
  variant = MessageVariants.Error,
  buttons = ButtonVariants.OK,
  timeout = 5000,
  yesClick,
  noClick,
  retryClick,
  cancelClick,
  onClose,
}: Props) => {
  const [isClosing, setIsClosing] = useState(false);

  const colorStyle = {
    error: {
      backgroundColor: "bg-red-100",
      borderColor: "border-red-500",
      textColor: "text-red-700",
    },
    info: {
      backgroundColor: "bg-orange-100",
      borderColor: "border-orange-500",
      textColor: "text-orange-700",
    },
    ok: {
      backgroundColor: "bg-green-100",
      borderColor: "border-green-500",
      textColor: "text-green-700",
    },
    warning: {
      backgroundColor: "bg-yellow-100",
      borderColor: "border-yellow-500",
      textColor: "text-yellow-700",
    },
  };

  const buttonConfig = {
    clean: ["clean"],
    none: [""],
    ok: ["ok"],
    yesno: ["yes", "no"],
    yesnocancel: ["yes", "no", "cancel"],
    retrycancel: ["retry", "cancel"],
  };

  useEffect(() => {
    if (timeout > 0) {
      const closeTimer = setTimeout(() => {
        onClose();
      }, timeout);
      return () => {
        clearTimeout(closeTimer);
      };
    }
  }, []);

  /**
   * Run onclick function of button then close alert box
   * @param clickFunction onclick function of the clicked button
   */
  function clickHandler(clickFunction: (() => void) | undefined) {
    if (clickFunction) clickFunction();
    setIsClosing(true);
    setTimeout(function () {
      onClose();
    }, 500);
  }

  return (
    <div
      className={`${colorStyle[variant].backgroundColor} border-l-4 ${
        colorStyle[variant].borderColor
      } ${
        colorStyle[variant].textColor
      } pl-2 pr-1 py-1 fixed inset-0 top-0 left-1/2 z-50 w-[90vw] max-w-[300px] min-h-[50px] h-fit mb-auto animate-slidedown ${
        isClosing &&
        "transition-opacity opacity-0 animate-slidedown duration-1000 ease-out delay-[1000]"
      }`}
    >
      <div className="flex flex-row justify-between items-start pb-2">
        <div className="font-bold">{title}</div>
        {!buttonConfig[buttons].includes("clean") && (
          <button
            onClick={() => {
              clickHandler(cancelClick);
            }}
          >
            <XCircleIcon className="w-7 cursor-pointer hover:brightness-125" />
          </button>
        )}
      </div>
      <div>{message}</div>
      <div className="flex flex-row gap-2">
        {buttonConfig[buttons].includes("ok") && (
          <Button
            onClick={() => {
              clickHandler(yesClick);
            }}
            size={Button.Sizes.Small}
          >
            OK
          </Button>
        )}
        {buttonConfig[buttons].includes("yes") && (
          <Button
            onClick={() => {
              clickHandler(yesClick);
            }}
            size={Button.Sizes.Small}
          >
            Ja
          </Button>
        )}
        {buttonConfig[buttons].includes("no") && (
          <Button
            onClick={() => {
              clickHandler(noClick);
            }}
            size={Button.Sizes.Small}
          >
            Nein
          </Button>
        )}
        {buttonConfig[buttons].includes("retry") && (
          <Button
            onClick={() => {
              clickHandler(retryClick);
            }}
            size={Button.Sizes.Small}
          >
            Wiederholen
          </Button>
        )}
        {buttonConfig[buttons].includes("cancel") && (
          <Button
            onClick={() => {
              clickHandler(cancelClick);
            }}
            size={Button.Sizes.Small}
          >
            Abbrechen
          </Button>
        )}
      </div>
    </div>
  );
};

const Alert = Object.assign(_Alert, {
  MessageVariants,
  ButtonVariants,
});

export default Alert;
