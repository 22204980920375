import React from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  type: string;
  h?: TwStyle;
  w?: TwStyle;
  customStyle?: TwStyle | TwStyle[];
};

const VerifyBadge = ({
  type,
  h = tw`h-3`,
  w = tw`w-3`,
  customStyle,
}: Props) => {
  if (type === "person")
    return (
      <img
        src="/images/person_verify.svg"
        css={[h, w, customStyle]}
        alt="PersonBadge"
      />
    );
  if (type === "company")
    return (
      <img
        src="/images/company_verify.svg"
        css={[h, w, customStyle]}
        alt="CompanyBadge"
      />
    );
  if (type === "club")
    return (
      <img
        src="/images/club_verify.svg"
        css={[h, w, customStyle]}
        alt="ClubBadge"
      />
    );

  return <></>;
};

export default VerifyBadge;
