import {
  $applyNodeReplacement,
  DecoratorNode,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export type SerializedMentionNode = Spread<
  {
    id: string;
    name: string;
  },
  SerializedLexicalNode
>;

export class MentionNode extends DecoratorNode<ReactNode> {
  __name: string;
  __id: string;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__name, node.__id, node.__key);
  }

  constructor(name: string, id: string, key?: NodeKey) {
    super(key);
    this.__name = name;
    this.__id = id;
  }

  createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.style.display = "contents";
    return div;
  }

  updateDOM(): false {
    return false;
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    console.log(serializedNode);
    return new MentionNode(serializedNode.name, serializedNode.id);
  }

  exportJSON(): SerializedMentionNode {
    return {
      id: this.__id,
      name: this.__name,
      type: this.getType(),
      version: 1,
    };
  }

  decorate(): ReactNode {
    return (
      <NavLink to={"/profile/" + this.__id}>
        <span className="px-2 text-blue-500 rounded-2xl">@{this.__name}</span>
      </NavLink>
    );
  }
}

export function $createMentionNode(name: string, id: string): MentionNode {
  const mentionNode = new MentionNode(name, id);
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(
  node: LexicalNode | null | undefined,
): node is MentionNode {
  return node instanceof MentionNode;
}
