import React, { useEffect, Dispatch, SetStateAction } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import {
  $getSelection,
  CONTROLLED_TEXT_INSERTION_COMMAND,
  EditorState,
} from "lexical";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import "@/components/elements/posts/form/shared/editorTheme.css";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import {
  AutoLinkPlugin,
  createLinkMatcherWithRegExp,
} from "@lexical/react/LexicalAutoLinkPlugin";
import ToolbarPlugin from "@components/elements/posts/form/shared/ToolbarPlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { MentionNode } from "@components/elements/posts/form/shared/MentionNode";
import MentionsPlugin from "@components/elements/posts/form/shared/MentionsPlugin";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { HashtagNode } from "@lexical/hashtag";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import isApp from "@/utils/isApp";
import tw from "twin.macro";

type Props = {
  value: string;
  onChange: (text: string) => void;
  small?: boolean;
  dark?: boolean;
  textRef?: any;
  atClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
  hashtagClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
};

function onError(error: Error) {
  console.error(error);
}

const theme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  hashtag: "editor-link",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

function onChange(editorState: EditorState, onChange: (text: string) => void) {
  onChange(JSON.stringify(editorState));
}

function Placeholder() {
  return <div className="editor-placeholder">Was gibt&apos;s neues?</div>;
}

const URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text.startsWith("http") ? text : `https://${text}`;
  }),
];

const TextEditButton = ({
  label,
  text,
  buttonClick,
}: {
  text: string;
  label: string;
  buttonClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
}) => {
  const insert = () => {
    editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, text);
  };

  const [editor] = useLexicalComposerContext();

  if (buttonClick?.setFunction) {
    if (!buttonClick?.getFunction) {
      buttonClick.setFunction(() => {
        return insert;
      });
    }
    return null;
  }

  return (
    <button
      className="gap-1 py-0.5 px-1.5 shadow-sm font-medium rounded-full text-darkblue bg-zinc-100  focus:outline-none focus:ring-2 focus:ring-zinc-50 border border-darkblue"
      onClick={insert}
    >
      <span className="text-sm">{label}</span>
    </button>
  );
};

const InitialEditorStatePlugin = ({
  initialState,
}: {
  initialState: string;
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    try {
      JSON.parse(initialState);
      const state = editor.parseEditorState(initialState);
      editor.setEditorState(state);
    } catch (e) {
      console.log(e);
      editor.update(() => {
        console.log("initialState", initialState);
        const selection = $getSelection();
        selection?.insertText(initialState);
      });
    }
  }, [editor]);

  return null;
};

const PostTextEdit = (props: Props) => {
  const initialConfig = {
    namespace: "PostTextEdit",
    theme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      MentionNode,
      HashtagNode,
    ],
  };

  return (
    <div className="flex gap-3 w-full">
      <div className="text-sm flex grow">
        <div className="w-full grid grid-cols-1 gap-1">
          <LexicalComposer initialConfig={initialConfig}>
            <div
              className="editor-container"
              css={[
                props.dark && tw`text-white border-0 bg-white bg-opacity-10`,
              ]}
            >
              {!props.small && <ToolbarPlugin />}
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input" />}
                  placeholder={<Placeholder />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <InitialEditorStatePlugin initialState={props.value} />
                <HistoryPlugin />
                {!isApp && <AutoFocusPlugin />}
                <MentionsPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin matchers={MATCHERS} />
                <HashtagPlugin />
                <OnChangePlugin
                  onChange={(editorState) =>
                    onChange(editorState, props.onChange)
                  }
                />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 text-xs w-fit ml-auto">
              <TextEditButton
                text="@"
                label={props.small ? "@" : "@ Erwähnungen"}
                buttonClick={props.atClick}
              />
              <TextEditButton
                text="#"
                label={props.small ? "#" : "# Hashtags"}
                buttonClick={props.hashtagClick}
              />
            </div>
          </LexicalComposer>
        </div>
      </div>
    </div>
  );
};

export default PostTextEdit;
