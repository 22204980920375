import React, { PropsWithChildren, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import PopularWidget from "@components/elements/PopularWidget";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import Input from "@components/elements/input/Input";
import Tab from "@components/menu/Tab";
import TabFilter from "@components/menu/TabFilter";
import CreateJob from "@components/elements/jobs/form/CreateJob";
import CVCreateContainer from "@pages/jobs/CVCreateContainer";
import { JobType } from "@/utils/types/JobType";
import JobsListItem from "@pages/jobs/JobsListItem";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  searchValue?: string;
  onSearchValueChange?: (value: string) => void;
  popular?: boolean;
};

type Response = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

const JobWrapper = ({
  onSearchValueChange,
  searchValue,
  popular,
  children,
}: PropsWithChildren<Props>) => {
  const { user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <TabMenu>
        <TabLink to="/jobs/meine_jobs">
          {t("main.jobsTabs.myJobs.title")}
        </TabLink>
        <TabLink to="/jobs/für_dich">{t("main.jobsTabs.toYou")}</TabLink>
        {user && user.type === "company" && (
          <TabLink to="/jobs/jobs_erstellen">Erstellen</TabLink>
        )}
        <TabLink to="/jobs/follower">{t("main.jobsTabs.following")}</TabLink>
        <TabLink to="/jobs/trending">{t("main.jobsTabs.trending")}</TabLink>
      </TabMenu>

      {onSearchValueChange && (
        <Input
          name="jobs-keywords"
          placeholder={t("main.jobsTabs.placeholder")}
          value={searchValue}
          onChange={(e) => onSearchValueChange(e)}
          clearable
        />
      )}
      {children}
    </div>
  );
};

export default JobWrapper;
