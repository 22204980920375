import React from "react";
import { User } from "@/utils/user";
import ContactForYouItem from "@pages/contacts/for_you/ContactForYouItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import api from "@/api/api";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import { useTranslation } from "react-i18next";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
  tab?: string;
};

const ContactForYouContainer = ({ searchQuery, tab }: Props) => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("main.contacts.tabs.toYou.tabs.all"),
      key: "",
      url: "alle",
    },
    {
      label: t("main.contacts.tabs.toYou.tabs.persons"),
      key: "person",
      url: "personen",
    },
    {
      label: t("main.contacts.tabs.toYou.tabs.companies"),
      key: "company",
      url: "firmen",
    },
    {
      label: t("main.contacts.tabs.toYou.tabs.societies"),
      key: "club",
      url: "vereine",
    },
  ];

  const contacts = useInfiniteQuery<Response>(
    ["contacts-for_you", searchQuery, tab === "all" ? "" : tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=${pageParam}&q=${searchQuery}&type=${
          tab === "all" ? "" : tab
        }`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  return (
    <>
      <TabMenu subTab>
        {tabs.map((tab: any) => {
          return (
            <>
              <TabLink subTab to={"/kontakte/für_dich/" + tab.url}>
                {tab.label}
              </TabLink>
            </>
          );
        })}
      </TabMenu>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {contacts.data &&
          contacts.data.pages.map((page) =>
            page.data.map((item: User) => (
              <ContactForYouItem key={item.id} user={item} />
            )),
          )}
      </div>
      {contacts.hasNextPage && !contacts.isFetchingNextPage && (
        <ShowMoreButton onClick={() => contacts.fetchNextPage()} />
      )}
    </>
  );
};

export default ContactForYouContainer;
