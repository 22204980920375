import React from "react";
import Panel from "@components/box/Panel";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const GlossaryContainer = () => {
  const navigate = useNavigate();

  return (
    <Panel mobileBorder={false}>
      <button onClick={() => navigate(-1)} className="lg:hidden">
        <XMarkIcon className="text-gray-700 w-5 h-5" />
      </button>
      <div className="flex flex-col gap-6 text-gray-700">
        <div className="text-4xl">Glossar</div>
        <div>
          <div className="text-xs font-bold">@-Erwähnung</div>
          <div className="text-xs">
            Wenn Du eine Person, ein Unternehmen oder ein Verein in einem Feed
            markieren möchtest. Füge @benutzername – z.B.„@Tradefoox“ – hinzu.
            Es wird verwendet, um Gespräche mit anderen Benutzern zu initiieren
            oder Inhalte zuzuordnen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">AI | KI</div>
          <div className="text-xs">
            Künstliche Intelligenz bezieht sich auf die Intelligenz von
            Maschinen, d.h. auf kognitive Funktionen, die wir normalerweise nur
            dem menschlichen Verstand zutrauen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Algorithmus</div>
          <div className="text-xs">
            Bei einem Algorithmus handelt es sich um eine Reihe von Schritten,
            mit denen ein Computer Aufgaben erledigt. Die Algorithmen von Social
            Media-Plattformen legen fest, welcher Content einem bestimmten
            Nutzer zu einer bestimmten Zeit angezeigt wird. Social
            Media-Netzwerke halten die Funktionsweise ihrer jeweiligen
            Algorithmen gern geheim, arbeiten aber im Allgemeinen mit
            Anhaltspunkten, die aus den Social Media-Kontakten und
            -Interaktionen eines Nutzers bezogen werden, um den ansprechendsten
            Inhalt für ihn zu finden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Analytics</div>
          <div className="text-xs">
            Analytics ist ein Oberbegriff, der sowohl Social Media-Analysetools
            als auch die Information beschreibt, die von diesen Tools geliefert
            wird. Die meisten Social Media-Netzwerke bieten ihre eigenen
            Analytics-Tools, mit deren Hilfe Profilinhaber selbst analysieren
            können, wie gut ihre Posts in Bezug auf Kennzahlen wie Reichweite,
            Engagement und Wachstum der Follower-Zahlen abschneiden.
            Spezialisierte Analytics-Programme können detailliertere
            Informationen und Reports liefern, in denen unter anderem auch
            Kennzahlen wie Teamleistung und Social Media-ROI enthalten sind.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Antworten</div>
          <div className="text-xs">
            Das Antworten ist eine Social Media-Funktion, mit der Du öffentlich
            auf den Kommentar eines anderen Nutzers reagieren kannst, wodurch
            ein Kommentar-Thread erzeugt wird.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Arbeitgebermarke</div>
          <div className="text-xs">
            Bei der Arbeitgebermarke, auch Employer Brand genannt, handelt es
            sich um die Präsentation des Unternehmens und der Unternehmenswerte
            nach außen. Ziel einer aussagekräftigen Arbeitgebermarke ist es
            neben dem Ausbau des Unternehmensimages ebenfalls das Interesse bei
            potenziellen Bewerbern zu wecken.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Avatar</div>
          <div className="text-xs">
            Ein Avatar repräsentiert eine Person visuell in digitalen Kontexten.
            Meist handelt es sich um computergenerierte Bilder wie Bitmojis. In
            den sozialen Medien bezeichnet der Begriff „Avatar“ auch das
            Profilbild, das einen Nutzer auf der Plattform repräsentiert. Die
            meisten Nutzer wählen ein Foto als Social Media-Avatar, das
            gelegentlich durch einen digitalen Rahmen oder Filter ergänzt wird.
            Für Marken ist in der Regel das Firmenlogo die erste Avatar-Wahl.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Beitragsklicks</div>
          <div className="text-xs">
            Die Anzahl der Personen, die auf etwas in Deinem Beitrag geklickt
            haben. Dazu kann gehören, dass jemand auf ein Bild mit einem „Mehr
            sehen“- „Call-to-Action“ oder eine von Ihnen eingefügte URL klickt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Benachrichtigung</div>
          <div className="text-xs">
            Eine Benachrichtigung ist eine Nachricht oder ein Alert, der auf
            neue Social Media-Aktivitäten hinweist. Wenn jemand beispielsweise
            einem Deiner Beiträge/Feeds ein Like gibt, kannst Du auf Deinem
            Smartphone eine Benachrichtigung erhalten, die Dich darüber
            informiert.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Bewerber Management System</div>
          <div className="text-xs">
            Kurz BMS genannt - Es handelt es sich um ein Tool, um den
            Recruiting-Prozess online zu verwalten. Mit einem BMS können
            sämtliche Recruiting Entscheidungen einfach koordiniert und
            verwaltet werden. Ein Dashboard gibt Auskunft über alle aktuellen
            ausgeschriebenen Stellen und zeigt alle eingegangenen Bewerbungen
            auf. Somit ist es deutlich einfacher, den Überblick über den
            gesamten Recruiting-Prozess zu behalten. Durch ein BMS wird die
            Kommunikation erleichtert, Vorlagen für Absagen, Einladungen zu
            Bewerbungsgesprächen etc. sparen zusätzlich viel Zeit ein. Vorteile:
          </div>
          <ul className="list-disc text-xs pl-3">
            <li>Übersicht und Struktur über alle ausgeschriebenen Stellen</li>
            <li>Überblick über alle eingegangenen Bewerbungen</li>
            <li>Vereinfachte Kommunikation mit Bewerbern</li>
            <li>Vorlagen für Absagen, Zusagen für Vorstellungsgespräche</li>
            <li>Vereinfachtes, schnelles Bewerbungsverfahren für Bewerber</li>
            <li>
              Einfache Erstellung und Veröffentlichung neuer
              Stellenausschreibungen
            </li>
            <li>Matching zwischen Bewerbern und Stellenausschreibern</li>
            <li>DSGVO konform</li>
          </ul>
        </div>
        <div>
          <div className="text-xs font-bold">Blockieren</div>
          <div className="text-xs">
            Wenn Du jemanden in den sozialen Medien blockierst, kann diese
            Person Deine Posts im entsprechenden Social Media-Netzwerk nicht
            mehr sehen. Blockierte Nutzer können auch nicht folgen, Dir keine
            Nachrichten schicken oder Dich markieren. Denke jedoch daran, dass
            es auch für blockierte Nutzer ziemlich einfach ist, Content zu
            sehen, den Du öffentlich gepostet hast.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Blog</div>
          <div className="text-xs">
            Der Begriff war ursprünglich eine Kurzform von „Weblog“ und
            bezeichnet eine digitale Publikation, in der ein oder mehrere
            Autoren regelmäßig Inhalte – meist zu einem bestimmten Thema –
            veröffentlichen. Viele Marken nutzen einen Blog, um ansprechenden
            Content mit der Zielgruppe zu teilen und Branchenkompetenz zu
            demonstrieren. Das Schreiben für einen Blog wird übrigens als
            „Bloggen“ bezeichnet.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Blogger</div>
          <div className="text-xs">
            Der Begriff „Blogger“ kann auch einen Menschen bezeichnen, der einen
            Blog schreibt und veröffentlicht. Viele bekannte Blogger gelten als
            Influencer, da ihr Content sehr viele Menschen erreicht.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Business to Business (B2B) / Business to Consumer (B2C)
          </div>
          <div className="text-xs">
            Im Online-Marketing tauchen häufig die Akronyme B2B und B2C auf. B2B
            steht für Business to Business und bedeutet, dass ein Unternehmen
            seine Produkte an andere Unternehmen vermarktet. B2C steht dagegen
            für Business to Consumer und beschreibt das Direktmarketing eines
            Unternehmens an ausgewählte Endverbraucher:innen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Call-to-Action</div>
          <div className="text-xs">
            Ein Call-to-Action ist eine direkte Aufforderung an Deinen Besucher,
            eine bestimmte Handlung auszuführen. Dabei kann die gewünschte
            Handlung alles Mögliche sein.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Chat</div>
          <div className="text-xs">
            Ein Chat ist eine Online-Konversation mit einer oder mehreren
            Personen. Ob zu zweit oder in einer Gruppe – Chats sind
            normalerweise privat und textbasiert, obwohl sie auch GIFs, Fotos
            und sogar Audioaufnahmen enthalten können. „Chat“ kann aber auch
            eine moderierte öffentliche Unterhaltung in den sozialen Medien
            bedeuten, die um einen Hashtag herum organisiert wird.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Chatbot</div>
          <div className="text-xs">
            Ein Chatbot ist ein programmiertes Dialogsystem, welches es
            ermöglicht automatisierte Nachrichten an den User zu verschicken.
            Der Chatbot erkennt dabei hinterlegte Schlüsselwörter aus den Fragen
            und versendet dann vorgefertigte Antworten an den User.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Compliance</div>
          <div className="text-xs">
            Compliance befasst sich mit dem Verstehen und Befolgen von Regeln,
            Vorschriften und Gesetzen. In den sozialen Medien ist Compliance
            besonders für Unternehmen aus regulierten Branchen wie dem
            Gesundheitswesen und dem Finanzsektor relevant. Diese Unternehmen
            unterliegen strengen Regeln für die Nutzung sozialer Medien und die
            Archivierung von Social Media-Content.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Content</div>
          <div className="text-xs">
            Als Content (deutsch: Inhalt) wird im Bereich des Online-Marketings
            der gesamte Inhalt einer Webseite bezeichnet. In aller Regel ist das
            Text, aber auch Bilder, Grafiken, Videos, Musikdateien oder Gifs.
            Content ist essentiell für das Ranking in Suchmaschinen, für die
            Aufenthaltsdauer von Webseitenbesuchern und deren Interaktion auf
            der Website.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Content-Marketing</div>
          <div className="text-xs">
            Content-Marketing bedeutet, dass man durch die Erstellung und
            Verbreitung von wertvollen Original-Inhalten wie Videos, Whitepaper,
            Leitfäden und Infografiken Kunden gewinnt und hält. Wer regelmäßig
            nützlichen und einmaligen Content bereitstellt, gibt seinen
            Followern einen Grund, regelmäßig auf seinen Social Media-Kanälen
            vorbeizuschauen. Gleichzeitig lassen sich so Beziehungen aufbauen
            und Branchen-Know-how demonstrieren.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Conversion</div>
          <div className="text-xs">
            Eine Conversion findet statt, wenn ein Social Media-Nutzer oder ein
            Besucher Deiner Website eine bestimmte gewünschte Aktion ausführt.
            Häufig ist ein Kauf die gewünschte Konversion, aber es gibt auch
            andere Spielarten – beispielsweise Aktionen zur Leadgenerierung wie
            die Anmeldung zu einem Newsletter-Abo, die Registrierung für ein
            Webinar oder das Herunterladen eines Whitepapers.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Conversion-Rate</div>
          <div className="text-xs">
            Die Conversion-Rate ist die Anzahl der Conversions, dividiert durch
            die Anzahl der Besucher. Es handelt sich dabei um eine Social
            Media-Kennzahl, mit der man die Leistung der eigenen Social
            Media-Aktivitäten zur Erreichung bestimmter Geschäftsziele messen
            kann.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Corporate Identity</div>
          <div className="text-xs">
            Die Corporate Identity kurz CI genannt beschreibt alle Merkmale,
            welche ein Unternehmen verkörpert. Mitinbegriffen sind nicht nur die
            Gestaltung des Logos, sondern auch alles rund um die
            Unternehmenswerte und Kommunikation. Die CI ist also breitgefächert
            und umfasst die unterschiedlichsten Bereiche.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Crowdsourcing</div>
          <div className="text-xs">
            Beim Crowdsourcing zapft man seine Online-Community an, um neue
            Ideen, Vorschläge, Informationen oder Inhalte zu bekommen. Ein
            Paradebeispiel dafür ist nutzergenerierter Content, man kann aber
            auch durch interaktive Features wie Umfragen an gute Ideen kommen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Dashboard</div>
          <div className="text-xs">
            Unser Dashboard ist ein Tool, das es Profilinhabern ermöglicht, über
            einen einzigen Screen die Be- und Überarbeitung des eigenen Profils
            zu verwalten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Direktnachricht (Direct Message; DM)
          </div>
          <div className="text-xs">
            Eine Direktnachricht ist eine private Nachricht, die über eine
            Social Media-Plattform versandt wird. Standardmäßig werden
            Direktnachrichten von Nicht-Followern blockiert oder via Filter an
            einen sekundären Posteingang weitergeleitet. Marken, die
            Direktnachrichten zur Interaktion mit Kunden nutzen möchten, können
            ihre Einstellungen jedoch so ändern, dass sie diese Art Nachrichten
            von jedem Absender empfangen können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">DSGVO</div>
          <div className="text-xs">
            Die DSGVO ist die sogenannte Datenschutz-Grundverordnung, welche
            durch die europäische Union verabschiedet wurde. Die Vereinbarung
            beschäftigt sich mit der Verwendung und der Verarbeitung von
            personenbezogenen Daten. Mitinbegriffen sind private und öffentliche
            Daten. Mit der Vereinbarung soll der Schutz der Daten garantiert
            werden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Eingebetteter Inhalt (embedded content)
          </div>
          <div className="text-xs">
            Ein eingebetteter Inhalt ist ein Social Media-Post oder anderer
            digitaler Content, der mit Hilfe digitaler Embedding-Tools in einem
            anderen Inhalt platziert wird. Die meisten sozialen Netzwerke bieten
            native Tools an, die den Nutzer mit einem Programmcode Inhalte in
            Blogbeiträge oder Webseiten einbetten lassen. Richtig eingebettete
            Inhalte enthalten den ursprünglichen Inhalt des Posts ebenso wie
            Links zum Originalbeitrag und dem Profil des Erstellers.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Einladung</div>
          <div className="text-xs">
            Lade jemanden ein Deinem Netzwerk beizutreten und sich mit Dir zu
            verbinden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Emoji</div>
          <div className="text-xs">
            Emojis sind eine Reihe winziger Grafiken, die in digitalen Kanälen –
            von Textnachrichten bis zu sozialen Medien – verwendet werden. Sie
            haben sich aus Emoticons (z. B. dem Smiley-Gesicht) entwickelt, die
            aus Zeichen auf einer normalen Tastatur bestanden. Emojis tauchten
            dann in den späten 1990er-Jahren auf. 2010 genehmigte das
            Unicode-Konsortium den Google-Vorschlag, Emoji-Zeichen zu
            standardisieren. Sowohl iOS- als auch Android-Geräte haben
            integrierte Emoji-Tastaturen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Employer Brand</div>
          <div className="text-xs">
            Bei der Employer Brand auch Arbeitgebermarke genannt, handelt es
            sich um die Präsentation des Unternehmens und der Unternehmenswerte
            nach außen. Den Aufbau einer Arbeitgebermarke kann man mit dem
            Aufbau einer normalen Marke vergleichen. Ziel einer aussagekräftigen
            Arbeitgebermarke ist es neben dem Ausbau des Unternehmensimages
            ebenfalls das Interesse bei potenziellen Bewerbern zu wecken.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Engagement</div>
          <div className="text-xs">
            Damit wird jede Art der Interaktion mit Deiner Marke / Profil –
            Likes, Kommentare und geteilte Posts – in den sozialen Medien
            bezeichnet. Die Anzahl der Likes, Reaktionen, Kommentare und Shares,
            die Du erhalten hast.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Engagement-Rate (Interaktionsrate)
          </div>
          <div className="text-xs">
            Die Engagement-Rate misst, wie viele Menschen mit Deinen Social
            Media-Inhalten interagieren. Es gibt verschiedene Methoden, sie zu
            berechnen – dabei geht es immer darum, wie hoch der Prozentsatz
            jener Nutzer ist, die einen Post gesehen und sich für eine
            Interaktion damit entschieden haben.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Entfolgen</div>
          <div className="text-xs">
            Wenn man jemanden entfolgt, beendet man das Abonnement für das
            Social Media-Konto der betreffenden Person. Sollte man die Social
            Media-Verbindung zu dieser Person aufrechterhalten, aber ihre Posts
            nicht mehr sehen wollen, kann man sie auch stumm schalten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">E-Recruiting</div>
          <div className="text-xs">
            Das E-Recruiting beinhaltet alle neuen Recruiting-Wege aus dem
            digitalen Bereich. Mitinbegriffen ist unter anderem das Recruiting
            durch Social Media, geschaltete Werbeanzeigen oder auch die
            Direktansprache der Kandidaten durch berufliche Networking
            Plattformen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Erweiterte Realität (Augmented Reality; AR)
          </div>
          <div className="text-xs">
            Augmented Reality setzt computergenerierte Effekte ein, um die
            Realität, die wir mit unseren Augen und Ohren wahrnehmen, zu
            erweitern. Geläufige Beispiele dafür sind Gesichtsfilter in Social
            Media-Apps wie Instagram Stories. Dabei wird das Gesicht des Nutzers
            mit Grafiken (und manchmal Tönen) ergänzt, die von der jeweiligen
            Social Media-Plattform erzeugt werden. Bei kreativem Einsatz bietet
            Augmented Reality potenziellen Kunden neue Möglichkeiten, mit einer
            Marke zu interagieren. So kann ein Interessent beispielsweise sehen,
            wie ein Produkt in seinem Haushalt wirken würde oder welche Brille
            sich für sein Gesicht am besten eignet.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Events</div>
          <div className="text-xs">
            können von einem Unternehmen, Verein oder einem Profil erstellt
            werden und werden für Geschäftsveranstaltungen, Präsentationen und
            vieles mehr genutzt. Erstelle eine Veranstaltung, eine Party, ein
            Treffen und erreiche Deine Zielgruppe
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Evergreen-Content (Dauerbrenner)
          </div>
          <div className="text-xs">
            Als Evergreen- oder Dauerbrenner-Content bezeichnet man Inhalte, die
            auf eine lange Lebensdauer ausgelegt sind. Sie beziehen sich nicht
            auf ein bestimmtes Ereignis oder eine Werbeaktion, sondern können
            einer Website über Jahre hinweg Traffic verschaffen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Feed</div>
          <div className="text-xs">
            Ein Feed ist eine ständig aktualisierte Liste der neuen Inhalte, die
            von den Accounts gepostet werden, denen ein Nutzer in den sozialen
            Medien folgt. Die meisten Social Media-Feeds sind aber nicht rein
            chronologisch angeordnet, sondern werden von einem Algorithmus
            gesteuert.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Filter</div>
          <div className="text-xs">
            Ein Filter ist ein fotografischer Effekt, der zur Bearbeitung von
            Bildern vor deren Veröffentlichung eingesetzt werden kann – von
            Schwarzweiß über Sepia bis hin zu Blumenkränzen und Dackelohren.
            Filter sind auf Instagram, Snapchat, Facebook Messenger und vielen
            anderen Apps mit Kameraintegration verfügbar.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Folgen</div>
          <div className="text-xs">
            Dies sind die Personen, denen Du folgst, damit Du Aktualisierungen
            in deren Feed sehen kannst. Sie müssen dir nicht folgen, damit du
            ihnen folgen kannst.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Follower</div>
          <div className="text-xs">
            Dies sind die Personen, die Ihrem Handle gefolgt sind und Ihre
            Updates in ihrem Feed sehen können. Sie müssen ihnen nicht folgen,
            damit sie Ihr Follower werden. Ähnlich wie bei einem Fan oder einem
            Twitter-Follower, können Follower Beiträge sehen, ohne dass es einer
            Genehmigung via Freundschaftsanfrage bedarf.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Freund</div>
          <div className="text-xs">
            Verbindung zwischen zwei Personenprofilen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Funktionen</div>
          <div className="text-xs">
            TRADEFOOX bietet den Usern vielfältige Möglichkeiten, um sein
            privates Netzwerk, das berufliche Netzwerk oder das Netzwerk als
            Verein auszubauen. Neben dem Pflegen von bisher bestehenden
            Geschäftskontakten und dem Knüpfen neuer beruflicher Kontakte,
            bietet die Plattform noch viele weitere Funktionen. Die Plattform
            bietet außerdem die Möglichkeit Seiten, berühmten Persönlichkeiten
            etc. zu folgen. Auf diese Weise erhält man als User
            Benachrichtigungen, sobald die Seite, die Person etc. neue Inhalte
            geteilt haben. Des Weiteren können Bereiche in Form von Hashtags
            hinterlegt werden, welche besonders interessant für den User sind.
            Interessiere ich mich zum Beispiel besonders für das
            Online-Marketing kann ich dort gezielt nach Seiten suchen, welche
            sich mit dem Bereich beschäftigen und kann diesen Folgen. Es besteht
            auch die Möglichkeit Gruppen beizutreten. Auf diese Weise bleibt man
            als User immer auf dem neusten Stand und kann Beiträge zu
            interessanten Themen lesen und weiter teilen. Auch selbst können
            Beiträge und Artikel verfasst und veröffentlicht werden.
          </div>
          <ul className="list-disc text-xs pl-3">
            <li>
              Gestaltung des eigenen Profils (Erwähnung absolvierter Abschlüsse,
              Erwerb von Kenntnissen, Zertifikaten etc., hinterlegen von
              Interessen, Bereichen etc.)
            </li>
            <li>
              Als Unternehmen: Verlinkung zur eigenen Website und Hinterlegung
              von Kontaktdaten
            </li>
            <li>
              Als Verein: Verlinkung zur eigenen Website und Hinterlegung von
              Kontaktdaten
            </li>
            <li>
              Pflegen von bisherigen Geschäftskontakten / Knüpfen neuer
              Geschäftskontakte
            </li>
            <li>Folgen von Profilen bekannter Persönlichkeiten</li>
            <li>Erstellen/Verwalten und Beitritt in Gruppen</li>
            <li>Verfassen von Beiträgen / Teilen von Beiträgen</li>
            <li>Newsfeed (Text-Bild-Post) / Watch (Video-Posts)</li>
            <li>
              Stellensuche- und -Gesuche mit Matching bei passenden Parametern
            </li>
            <li>Erstellen von Inseraten</li>
            <li>Erstellen eines eigenen Shops auf unserm Marketplace</li>
            <li>Direktkontakt untereinander via Messenger</li>
          </ul>
        </div>
        <div>
          <div className="text-xs font-bold">Geo-Tag</div>
          <div className="text-xs">
            Ein Geo-Tag ist ein bestimmter Ort, der zu einem Foto, Video oder
            anderen Social Media-Post hinzugefügt wird. Durch Geo-Tags können
            Posts mehr Menschen erreichen, da Inhalte oft nach Standort
            durchsuchbar sind.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">GIF</div>
          <div className="text-xs">
            GIF ist die Abkürzung für Graphics Interchange Format – ein
            Dateiformat, das sowohl statische wie animierte Bilder unterstützt.
            GIFs wurden deshalb so beliebt, weil man mit ihnen in den sozialen
            Medien ohne Worte reagieren kann.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Google Ads (Google AdWords)</div>
          <div className="text-xs">
            Google Ads sind eine Form von Online-Werbung (vormals Google
            AdWords). Google Ads erscheinen oben in den Google-Suchergebnissen
            für ein gesuchtes Keyword. Über das Google Display Network können
            sie auch auf anderen Webseiten auftauchen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Gruppen</div>
          <div className="text-xs">
            Öffentlich oder privat, erstellt von einer Person, einem Unternehmen
            oder einem Verein können hier Themen diskutiert werden. Dient dem
            Erfahrungsaustausch und der Weiterbildung. Ein Ort für Gruppenchats
            für Menschen, die ein gemeinsames Interesse haben - Gruppen können
            privat oder öffentlich sein.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Hashtags</div>
          <div className="text-xs">
            # wird von Instagram und Twitter verwendet. Ein Hashtag ermöglicht
            es Benutzern, sich mit anderen zu verbinden und Bilder zu finden,
            die auf einem gemeinsamen Wort basieren. Die Verwendung eines
            Hashtags bedeutet auch, dass andere Ihren Post finden können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Headerbild</div>
          <div className="text-xs">
            Beim Headerbild handelt es sich um das Bild, das oben im Profil
            eines Social Media-Nutzers erscheint. Es wird auch als Coverbild
            oder Coverfoto bezeichnet und gibt Unternehmen die Möglichkeit, ihre
            Produkte, Teams oder einen anderen Aspekt ihres Geschäfts zu
            präsentieren, der Menschen dazu bewegen soll, sich ein Profil näher
            anzusehen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Headhunter</div>
          <div className="text-xs">
            Ein Headhunter beschreibt wörtlich übersetzt einen „Kopfjäger“. Der
            Begriff stammt aus dem Bereich der Personalsuche. Dabei handelt es
            sich bei einem sogenannten Headhunter, um einen Personalberater, der
            Unternehmen gezielten bei der Kandidatensuche unterstützt und die
            Direktansprache der Kandidaten übernimmt. Unternehmen beauftragen
            Headhunter häufig, um einen Kandidaten für eine spezielle offene
            Vakanz zu finden. Meistens handelt es sich bei den Vakanzen um zu
            besetzende Fach- und Führungspositionen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Impressions (Seitenaufrufe)</div>
          <div className="text-xs">
            Die Kennzahl „Impressions“ gibt an, wie oft eine Ad oder ein
            beworbener Post vom Server abgerufen und in einem Social
            Media-Netzwerk angezeigt wird. Sie sagt aber nichts darüber aus, wie
            viele Leute die Anzeige gesehen haben. Es kann beispielsweise
            vorkommen, dass einem Social Media-Nutzer dieselbe Ad innerhalb
            eines gewissen Zeitraums mehrmals in seinem Newsfeed angezeigt wird.
            Dies wird jedes Mal als ein Seitenaufruf gezählt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Inbound-Marketing</div>
          <div className="text-xs">
            Inbound-Marketing ist eine Strategie, bei der Sie nutzwertigen
            Content und Ressourcen anbieten, um potenzielle Kunden auf Ihr
            Unternehmen aufmerksam zu machen. Es nennt sich „inbound“, weil die
            von Ihnen erstellten Materialien Menschen dazu bringen, Ihr
            Unternehmen selbst zu entdecken und kennenzulernen, statt mit einer
            Vertriebspräsentation konfrontiert zu werden. Ihr Team kann diese
            neuen Kontakte dann pflegen, bis sie bereit sind, Kunden zu werden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Influencer</div>
          <div className="text-xs">
            Wichtige und einflussreiche Personen in Ihrer Branche, die Ihnen
            großartige Inhalte liefern können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Influencer-Marketing</div>
          <div className="text-xs">
            Influencer-Marketing ist eine Strategie, bei der man mit einer
            einflussreichen Person in den sozialen Medien (einem „Influencer“)
            zusammenarbeitet, um ein Produkt, eine Dienstleistung oder eine
            Kampagne zu bewerben.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Insights</div>
          <div className="text-xs">
            Im Zusammenhang mit sozialen Medien bezieht sich der Begriff
            „Insights“ („Erkenntnisse“) üblicherweise auf die Analytics und
            Performance-Statistiken. Je nach Art des Profils zeigt Dir Insights
            die Demografie der Follower, zu welcher Zeit diese am ehesten online
            sind, die Reichweite der einzelnen Posts und mehr.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Key Performance Indicator – KPI
          </div>
          <div className="text-xs">
            Bei einem KPI handelt es sich um einen sogenannten „Key Performance
            Indicator“, was zu Deutsch eine Kennzahl zur Messung einer Leistung
            beschreibt. Die Anzahl der KPIs ist groß und es je nach Bereich gibt
            es unterschiedlich Kennzahlen, die am besten geeignet sind. So gibt
            es zum Beispiel Kennzahlen: Im Printbereich, um das beste
            Printmedium für Inserate zu finden Im Onlinebereich, um die
            Performance von geschalteten Werbeanzeigen messen zu können Für den
            Bewerberprozess, um feststellen zu können, wie sich das Recruiting
            entwickelt
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Kommentare</div>
          <div className="text-xs">
            Ein Kommentar ist eine Form des Engagements, bei der ein Nutzer auf
            einen Social Media-Post reagiert. Kommentare können Lob ausdrücken,
            eine Frage stellen, eine andere Meinung äußern oder auf andere Art
            zur Online-Konversation über Social Media-Inhalte beitragen. Sie
            können Text, Hashtags, @Erwähnungen und Emojis enthalten. Eine große
            Anzahl von Kommentaren zeigt, dass ein Beitrag ansprechend ist und
            damit durch den Algorithmus eines Social Media-Netzwerks seine
            Position im Newsfeed verbessern kann.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Kontakte</div>
          <div className="text-xs">Personen, mit denen Sie vernetzt sind.</div>
        </div>
        <div>
          <div className="text-xs font-bold">Krisenmanagement</div>
          <div className="text-xs">
            Krisenmanagement ist die Kunst, eine Krise in den Griff zu bekommen,
            um den entstandenen Schaden zu minimieren und so schnell wie möglich
            wieder den Normalzustand herzustellen. Jedes Unternehmen sollte über
            ein Social Media-Krisenmanagement verfügen, um Risiken in den
            sozialen Medien zu bewältigen und auf eine schnelle Reaktion im
            Krisenfall vorbereitet zu sein.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Künstliche Intelligenz (KI)</div>
          <div className="text-xs">
            Künstliche Intelligenz bezeichnet die Fähigkeit von Computern,
            Programmen oder Maschinen, auf eine dem menschlichen Denken ähnliche
            Weise zu lernen und sich anzupassen. So nutzen zum Beispiel Chatbots
            künstliche Intelligenz, um zu kommunizieren und Fragen zu
            beantworten, während digitale Assistenten wie Alexa durch KI mit der
            Zeit lernen, besser auf Anfragen zu reagieren. Je mehr man mit einem
            KI-Programm interagiert, desto „intelligenter“ wird es – weil es auf
            mehr Daten zurückgreifen kann.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Landingpage</div>
          <div className="text-xs">
            Eine Landingpage ist – wie der Name verrät – eine Webseite, auf der
            Besucher:innen landen. Dabei handelt es sich in der Regel um eine
            für sich stehende Webseite, die Teil einer Werbekampagne ist und
            Kund:innen durch einen Call-to-Action (CTA) zu einer bestimmten
            Handlung auffordert. Häufig werden Interessierte gebeten, ihre
            Kontaktdaten zu hinterlassen. Im Gegenzug erhalten sie eine
            kostenlose Testversion oder anderen Content. Landingpages sind ein
            essentieller Bestandteil der Lead-Generierung, die den ersten
            Schritt bei der Umwandlung von Besucher:innen in Kund:innen
            darstellt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Like</div>
          <div className="text-xs">
            Ein Like ist eine Interaktionsform in den sozialen Medien. Damit
            geben Sie an, dass Ihnen ein Inhalt gefällt, indem Sie einfach auf
            den „Gefällt mir“-Button klicken. Auf Facebook ist dieser Button ein
            Daumen-hoch-Symbol, auf Instagram und Twitter ein Herzsymbol. Wer
            Content mit „Gefällt mir“ markiert, setzt damit auch ein
            Lesezeichen, weil man später abrufen kann, welchem Content man ein
            Like gegeben hat.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Linkaufbau</div>
          <div className="text-xs">
            Linkaufbau ist eine Marketingstrategie zur Verbesserung des Traffics
            und des Suchmaschinen-Rankings, indem andere Websites dazu bewegt
            werden, auf Ihre zu verlinken. Zu den gängigen Methoden, solche
            Links im Rahmen eines Linkaufbau-Programms zu erhalten, gehören das
            Gast-bloggen und das Anbieten wertiger Inhalte zum Weiterposten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Livestream</div>
          <div className="text-xs">
            Eine Livestream ist ein Echtzeit-Video, das via Internet geteilt
            wird. Die meisten sozialen Netzwerke bieten heute
            Live-Streaming-Optionen an, bei denen man auch mit den Zuschauern
            interagieren kann. Die Nutzer können während der Sendung
            schriftliche Kommentare abgeben und Fragen stellen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Lurker</div>
          <div className="text-xs">
            Ein Lurker ist eine Person, die einen Social Media-Feed beobachtet
            oder einer Gruppe in den sozialen Medien angehört, aber nicht in
            Form von Likes oder Antworten mit dem Content interagiert.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Markenbekanntheit</div>
          <div className="text-xs">
            Markenbekanntheit ist eine Social Media-Kennzahl, die erfasst, wie
            wahrscheinlich es ist, dass Menschen eine bestimmte Marke kennen.
            Man kann sie für einen beliebigen Zeitraum messen, indem man
            verfolgt, wie viel Aufmerksamkeit die Marke in Form von Erwähnungen
            und Engagement erhält. Je mehr Menschen online über eine Marke
            sprechen, desto höher die Markenbekanntheit.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Markenfürsprecher</div>
          <div className="text-xs">
            Ein Markenfürsprecher ist ein Kunde, der Ihre Marke so sehr liebt,
            dass er Ihre Produkte oder Dienstleistungen unaufgefordert bewirbt.
            Markenfürsprecher können aber noch wertvoller werden, wenn Sie sich
            direkt mit ihnen in Verbindung setzen, um sie gezielt einzusetzen
            und ihnen größere Befugnisse zu geben.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Marketing Analytics</div>
          <div className="text-xs">
            Unter Marketing Analytics versteht man das Sammeln und Auswerten von
            Daten zu digitalen Marketingmaßnahmen. Dies umfasst das Messen von
            Key Performance Indicators (KPIs) zu Werbekampagnen, etwa Conversion
            Rate (CVR) und Bounce Rate, sowie die Analyse von Daten wie
            Akquisekosten, um den Return on Investment (ROI) zu bestimmen. Im
            Rahmen der Marketing Analytics werden Berichte erstellt, anhand
            derer Unternehmen den Erfolg ihrer Marketingaktivitäten messen und
            diese optimieren können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Matching</div>
          <div className="text-xs">
            Mit Matching-Methoden wird anhand gemeinsamer Merkmale aus einem
            Datensatz eine oder mehrere ähnliche Beobachtungen aus den anderen
            Datensätzen zugeordnet. Damit wird eine gemeinsame Analyse der Daten
            möglich. Job-Matching schaltet zwischen Job und Kandidat einen
            intelligenten Matching-Algorithmus, der beide Seiten vollautomatisch
            miteinander abgleicht und die Passgenauigkeit auf Herz und Nieren
            prüft. Die Jobs werden gefiltert und sortiert. Bewerber erhalten nur
            noch Angebote, die zum eigenen Profil passen. Auf der anderen Seite
            reduziert sich die Anzahl der potenziell stimmigen Bewerbungen auf
            dem Tisch des Personalers. Die Jobsuche wird methodisch, effizient,
            zielführend.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Meme</div>
          <div className="text-xs">
            Ein Online-Meme ist ein Witz oder Kommentar, der zum Teilen in
            sozialen Netzwerken gemacht wurde. Es handelt sich üblicherweise um
            eine Grafik oder ein GIF mit Text über dem oder im Bild.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Messenger</div>
          <div className="text-xs">
            Sofortnachrichten für den Chat mit Freunden, auf dem Handy und auf
            der Website.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Multiposting</div>
          <div className="text-xs">
            Günstig Stellen inserieren und das auf so vielen Kanälen wie
            möglich, das ist Multiposting. Ob über Stellenbörsen, die eigenen
            sozialen Netzwerke oder über bestimmte Portale, die für das
            Multi-Posting bekannt sind und dieses direkt für Sie übernehmen und
            mehrere Kanäle gleichzeitig bespielen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Nachwuchsförderung</div>
          <div className="text-xs">
            Unter Nachwuchsförderung versteht man die Durchführung gezielter
            Recruiting Maßnahmen, um junge Talente zu finden und zu rekrutieren.
            Dabei werden die Karriereplanung und Ziele der jungen Arbeitnehmer
            gezielt gefördert und kommuniziert.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Netzwerk</div>
          <div className="text-xs">
            Ihre Kontakte, einschließlich der Kontakte Ihrer Kontakte.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Networking Plattformen</div>
          <div className="text-xs">
            Bei Networking Plattformen handelt es sich um berufliche Netzwerke.
            Diese sind mit der Social Media Plattform Facebook zu vergleichen,
            legen jedoch den Fokus auf den beruflichen Austausch.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">News Feed</div>
          <div className="text-xs">
            Persönlicher Content-Fluß (von oben nach unten), der durch Updates
            und News von Freunden, Marken oder Gruppen denen Sie folgen
            beeinflusst wird.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Newsjacking</div>
          <div className="text-xs">
            Dieser auch als „Trendjacking“ bekannte Begriff bezieht sich auf den
            Verweis auf eine Nachricht oder ein Trendthema, um auf diese Art
            eine Verbindung mit dem Publikum herzustellen, das dieser
            Nachricht/dem Thema folgt. Hashtags sind eine gängige Methode, um
            Inhalte mit aktuellen Nachrichten zu verknüpfen. Newsjacking
            funktioniert aber nur dann, wenn ein enger Bezug zu der betreffenden
            Meldung besteht.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Nutzergenerierter Content (User-generated content; UGC)
          </div>
          <div className="text-xs">
            Im Gegensatz zu Marken-Content werden nutzergenerierte Social
            Media-Inhalte von ganz normalen Leuten erstellt. Marken sammeln
            diesen Content über Preisausschreiben, Marken-Hashtags oder einfach,
            indem sie die Ersteller um Erlaubnis bitten. Wenn Marken solche
            Inhalte mit ihren eigenen Followern teilen, handelt es sich um eine
            UGC-Kampagne. Nutzergenerierter Content ist hilfreich, wenn es darum
            geht die Markenbekanntheit und Kundentreue zu erhöhen. Unternehmen
            können sich die Begeisterung und kreative Energie ihrer Kunden
            zunutze zu machen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Nutzername (Handle)</div>
          <div className="text-xs">
            Ihr Nutzername identifiziert Sie in den sozialen Medien und wird für
            gewöhnlich als @nutzername angegeben. Man kann ihn auch in einer
            personalisierten URL für jedes soziale Netzwerk verwenden. Es
            empfiehlt sich, den selben Nutzernamen in allen sozialen Netzwerken
            zu verwenden, damit potenzielle Follower Ihre Accounts leichter
            finden können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Organische Reichweite</div>
          <div className="text-xs">
            Die organische Reichweite ist die Anzahl der einzelnen Nutzer, die
            Ihren Content ohne bezahlte Werbung sehen. Menschen finden Social
            Media-Inhalte organisch über ihre eigenen Feeds – entweder von
            Unternehmen, deren Accounts sie mit „Gefällt mir“ markiert haben,
            oder durch Content, der von Freunden oder Verbindungen geteilt
            wurden. Wenn jemand Ihr Social Media-Profil aufgrund einer Suche
            oder einer anderen nicht bezahlten Empfehlung besucht, zählt auch
            dies zur organischen Reichweite.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Outsourcing</div>
          <div className="text-xs">
            Outsourcing zu Deutsch Auslagerung bedeutet, dass bestimmte Aufgaben
            von externen Dienstleistern übernommen werden oder ganze
            Produktionsbereiche, aus Kostengründen, ins Ausland verlagert
            werden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Pay per Click (PPC)</div>
          <div className="text-xs">
            Pay per Click ist eine Werbeform, bei der ein Unternehmen jedes Mal
            dafür zahlt, dass ein Nutzer auf eine Anzeige klickt. Die Kosten für
            eine PPC-Kampagne variieren abhängig davon, wie stark das
            Ziel-Keyword gefragt ist. Der Betrag, den man für jeden Klick in
            einer Pay-per-Click-Kampagne bezahlt, ist der Preis pro Klick (Cost
            per Click; CPC).
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Plattform</div>
          <div className="text-xs">
            Eine Plattform ist ein soziales Netzwerk oder ein Bestandteil eines
            solchen. Twitter, Facebook und Instagram sind Social
            Media-Plattformen. Manche Marketer betrachten jedoch den
            Facebook-Newsfeed und Facebook Stories als eigene Plattformen, da
            sie unterschiedliche Zielgruppen haben können, die man mit anderen
            Marketingstrategien erreichen muss.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Post</div>
          <div className="text-xs">
            Als Post oder Beitrag bezeichnet man jede Statusaktualisierung,
            jedes Foto oder Video und jeden geteilten Content in einem sozialen
            Netzwerk, Blog oder Forum.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Posteingang (Inbox)</div>
          <div className="text-xs">
            Ein Posteingang ist der Bildschirm, auf dem man Nachrichten liest,
            organisiert und beantwortet. Bekannt ist der E-Mail-Posteingang,
            aber auch Social Media-Messenger-Dienste verwenden Posteingänge. Die
            Hootsuite Inbox ist ein Tool zur Verwaltung öffentlicher und
            privater Konversationen von mehreren Social Media-Plattformen auf
            einem einzigen Bildschirm.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Profil</div>
          <div className="text-xs">
            Social Media Accounts werden im Deutschen als Profile auf sozialen
            Medien bezeichnet. Unternehmen können sich auf sozialen Plattformen
            registrieren und sind so für potentielle Interessenten auffindbar
            und können sich dem Corporate Identity entsprechend nach außen hin
            präsentieren.
          </div>
          <ul className="list-disc text-xs pl-3">
            <li>
              Firmenprofil: Unternehmen können eigene Profile anlegen, auf denen
              Sie das Unternehmen beschreiben und für offenen Stellenanzeigen
              werben.
            </li>
            <li>
              Vereinsprofil: Vereine können eigene Profile anlegen, auf denen
              Sie den Verein beschreiben und sich präsentieren
            </li>
            <li>
              Userprofil: Privatpersonen können eigene Profile anlegen, auf
              denen sie sich selbst präsentieren
            </li>
          </ul>
        </div>
        <div>
          <div className="text-xs font-bold">Profilbild</div>
          <div className="text-xs">
            Das Bild, das Sie oder Ihr Unternehmen repräsentiert. Dies ist das
            kleinere Foto, das neben all Ihren Beiträgen angezeigt wird.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">PPC</div>
          <div className="text-xs">
            Pay-per-Click-Anzeigen sind Anzeigen auf Websites oder in
            Suchmaschinen, die Sie bezahlen, wenn ein Benutzer darauf klickt.
            Bid-based PPC verwenden auktionsgetriebene Preise, bei der
            Werbetreibende mit anderen Werbetreibenden konkurrieren, indem sie
            für jeden Klick ein Maximalgebot festlegen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Privat</div>
          <div className="text-xs">
            Ein privater Social Media-Account oder eine private Gruppe sind für
            die Öffentlichkeit nicht einsehbar. Die Grundelemente des Kontos
            oder der Gruppe – wie Profilbild und Name – sind für alle sichtbar,
            doch die geteilten Inhalte können nur genehmigte Follower sehen. Auf
            Twitter wird ein privater Account als „geschützt“ bezeichnet.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            Programmierschnittstelle (Application Programming Interface / API)
          </div>
          <div className="text-xs">
            Eine Anwendungs-Programmierschnittstelle (API) ist ein
            Kommunikationssystem, das es zwei Applikationen oder Plattformen
            erlaubt, miteinander zu “sprechen”. Die APIs sozialer Netzwerke
            ermöglichen diesen Plattformen die Integration mit anderen
            Softwareanbietern und Apps.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Reaktion</div>
          <div className="text-xs">
            Reaktionen sind eine Form der Interaktion auf Facebook. Zusätzlich
            zu Likes gibt es Reaktionen wie Herz, Haha, Wow, Traurig und Wütend,
            die durch Emojis angezeigt werden. Facebook-Nutzer können auf die
            Reaktion-Optionen zugreifen, indem sie mit dem Mauszeiger über den
            Like-Button fahren oder ihn gedrückt halten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Reichweite</div>
          <div className="text-xs">
            Die Reichweite bezieht sich auf die Gesamtzahl der Personen, die man
            mit einem Social Media-Post oder einer Werbeanzeige erreichen kann.
            Diese Kennzahl sagt aber nicht unbedingt aus, dass all diese
            Personen den Content wirklich gesehen haben – sie könnten zum
            Beispiel auch einfach weiter gescrollt haben. Die Reichweite zeigt
            lediglich an, dass der Inhalt mindestens einmal im Social Media-Feed
            des Nutzers erschienen ist. Social Media-Analytics Tools weisen die
            organische und die bezahlte Reichweite üblicherweise als zwei
            getrennte Kennzahlen aus.
          </div>
          <ul className="list-disc text-xs pl-3">
            <li>
              Beitragsreichweite - wie viele individuelle Nutzer haben Ihren
              Beitrag gesehen
            </li>
            <li>
              Seitenreichweite - wie viele Nutzer haben die von Ihnen geposteten
              Inhalte gesehen
            </li>
            <li>
              Organische Reichweite - wie viele Nutzer haben Ihre Inhalte aus
              eigenem Antrieb gesehen
            </li>
            <li>
              Bezahlte Reichweite - wie viele Nutzer haben Ihren beworbenen
              Beitrag gesehen
            </li>
          </ul>
        </div>
        <div>
          <div className="text-xs font-bold">Reaktionen</div>
          <div className="text-xs">
            Eine Erweiterung des Like-Button um Emotionen auszudrücken wie:
            Liebe, Haha, Wow, Traurig, Wütend, etc.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Recruiting</div>
          <div className="text-xs">
            Recruiting ist die Suche nach qualifizierten Arbeitskräften
            (Personalbeschaffung).
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Recruiting Video</div>
          <div className="text-xs">
            Ein Recruiting Video kann auf die unterschiedlichsten Weisen
            erstellt und verwendet werden. Ob Erklärvideos für bestimmte
            Positionen in einem animierten Stil oder Videos mit realen
            Ausschnitten aus dem Alltag, welche dann zur Rekrutierung verwendet
            werden, alles ist möglich! Recruiting Videos sind immer gefragter
            und kommen gut an. Persönlich und emotional können auf diese Weise
            Kandidaten angesprochen werden und eine direkte emotionale
            Verbindung zwischen Bewerber und Unternehmen wird geschaffen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Registerkarten</div>
          <div className="text-xs">
            Diese befinden sich unter Ihrem Profilfoto und sind Links zu
            Informationen über Ihr Unternehmen, Ihren Standort, Ihre Fotos,
            Videos und Apps von Drittanbietern.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Return on Investment (ROI)</div>
          <div className="text-xs">
            Der Return on Investment (ROI) misst die Rentabilität einer
            Investition, indem er das durch die Investition verdiente Geld dem
            eingesetzten Kapital gegenüberstellt. Der ROI wird bei
            Marketingentscheidungen herangezogen, da er in einer Momentaufnahme
            zeigt, welche Marketingaufwendungen das beste
            Kosten-Nutzen-Verhältnis aufweisen. Letztlich beschreibt er, was Sie
            für Ihr Geld bekommen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Request | Anfrage</div>
          <div className="text-xs">
            Von einem Benutzer an einen anderen gesendet, für die
            Kontaktaufnahme und Vernetzung oder um sogar um ein mögliches
            Projekt gemeinsam zu starten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">RSS</div>
          <div className="text-xs">
            Das „Rich Site Summary“ (RSS, auch „Really Simple Syndication“
            genannt – aber ob es wirklich so einfach geht, ist Ansichtssache;
            beide Erklärungen sind übrigens nicht offiziell) ist ein Format zur
            Syndikation von Web-Content. RSS-Feeds werden in einem
            Standard-XML-Format erstellt, das sie mit einer Vielzahl von Readern
            und Aggregatoren kompatibel macht, die Leser abonnieren können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">RSS-Reader</div>
          <div className="text-xs">
            Ein RSS-Reader ist ein Tool, mit dem man Artikel aus mehreren
            RSS-Feeds zum einfachen Lesen an einem Ort sammeln kann.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Shitstorm</div>
          <div className="text-xs">
            Der Begriff Shitstorm ist ein spezielles Internetphänomen und setzt
            sich aus den englischen Wörtern „shit“ und „storm“ zusammen. Als
            Shitstorm wird in Deutschland eine Empörungswelle bezeichnet, die
            sich meist über Social-Media-Kanäle schnell ausbreitet. Dieses
            unerwartete Auftreten von Kritik, Beleidigungen und negativen
            Kommentaren kann Unternehmen, Privatpersonen oder Prominente
            betreffen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Selfie</div>
          <div className="text-xs">
            Ein Selfie ist ein fotografisches Selbstporträt, meist mit der
            Frontkamera eines Smartphones aufgenommen und in den sozialen Medien
            geteilt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">SEM / Suchmaschinenmarketing</div>
          <div className="text-xs">
            Eine Online-Strategie mit dem Ziel, Kunden zu gewinnen,
            Markenbekanntheit zu erzeugen, Vertrauen und Loyalität aufzubauen.
            SEM erhöht die Sichtbarkeit Ihrer Website vor allem durch
            Pay-per-Click-Anzeigen (PPC).
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">
            SEO / Suchmaschinenoptimierung
          </div>
          <div className="text-xs">
            Suchmaschinenoptimierung ist die Kunst, Visit-Volumen und/oder die
            Qualität des organischen Traffics auf Ihre Website zu verbessern,
            und zwar kostenlos. Normalerweise tut man dies, indem man für die
            Suchbegriffe für die man gefunden werden will, passende Inhalte auf
            seiner Website erstellt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Share of Voice</div>
          <div className="text-xs">
            Die Kennzahl Share of Voice zeigt an, wie häufig eine bestimmte
            Marke im Vergleich zu den Mitbewerbern erwähnt wird. Sie wird
            üblicherweise als Prozentsatz der gesamten Erwähnungen innerhalb
            einer Branche oder einer vordefinierten Gruppe von Mitbewerbern
            angegeben.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Listening</div>
          <div className="text-xs">
            Bei Social Listening geht es um das Ermitteln und Bewerten aller
            Äußerungen in den sozialen Medien über ein Unternehmen, ein Thema,
            eine Marke oder eine Person. Anschließend kann das Social Media-Team
            Maßnahmen ergreifen, die auf den Ergebnissen der Analyse basieren.
            Diese Maßnahmen können ganz einfach sein – etwa eine Antwort an
            einen zufriedenen Kunden schicken – oder auch so umfangreich wie die
            Überarbeitung der Markenstrategie.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Media Management</div>
          <div className="text-xs">
            Unter Social Media Management versteht man die Verwaltung von Social
            Media-Accounts, das Engagement mit Zielgruppen und die Messung der
            geschäftlichen Ergebnisse aus Social Media-Aktivitäten. Effektive
            Social Media Management-Praktiken, die abteilungs- und
            regionenübergreifend implementiert werden, ermöglichen die
            Zusammenarbeit aller Mitarbeiter des Unternehmens und messbare
            Social Media-Ergebnisse.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Media Marketing</div>
          <div className="text-xs">
            Als Social Media Marketing bezeichnet man den Einsatz der sozialen
            Medien, um die Markenbekanntheit zu steigern, wichtige Zielgruppen
            zu identifizieren, Leads zu generieren und sinnvolle
            Kundenbeziehungen aufzubauen. Social Media Marketing sollte immer
            Teil einer umfassenden Social Media-Strategie sein, zu der Social
            Media-Kundenservice, Community-Management und Social
            Selling-Aktivitäten gehören.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Media Recruiting</div>
          <div className="text-xs">
            Spricht man von Social Media Recruiting, dann ist die Rede von
            Recruiting über soziale Netzwerke wie Facebook und Instagram. Dabei
            handelt es sich, um bezahlte Werbekampagnen, welche im Hintergrund
            durch spezielle Targetierungen, der gewünschten Zielgruppe im
            Facebook Feed oder den Instagram Stories ausgespielt werden.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Networking</div>
          <div className="text-xs">
            Ganz einfache Definition - Kontakte in einer Online-Community
            knüpfen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Social Selling</div>
          <div className="text-xs">
            Social Selling ist der Einsatz von Social Media-Tools, um Leads zu
            entdecken, neue potenzielle Kunden anzusprechen und
            Geschäftsbeziehungen zu pflegen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Spam</div>
          <div className="text-xs">
            Als Spam bezeichnet man unnötige, unerwünschte oder sich dauernd
            wiederholende Inhalte, die Posteingänge und Social Media-Feeds
            verstopfen. Der Ausdruck „Spam“ wird seit den Anfangszeiten des
            Internets für Junk-Nachrichten benutzt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Statistik</div>
          <div className="text-xs">
            Dies ist Ihr Analyse-Hub. Hier finden Sie alle Post- und
            Seitenanalysen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Stellenanzeige</div>
          <div className="text-xs">
            Bei einer Stellenausschreibung handelt es sich um eine Ausschreibung
            eines Unternehmens, welche auf der Suche nach neuem Personal ist.
            Stellenausschreibungen können intern im Unternehmen oder extern über
            diverse Kanäle veröffentlicht werden. Die Stellenausschreibung
            enthält jegliche Informationen rund um die Position, welche besetzt
            werden soll. Die Ausschreibung soll dem potenziellen Bewerber einen
            Einblick in sein zukünftiges Aufgabengebiet geben, zeigt die
            geforderten Qualifikationen auf und bringt dem potenziellen Bewerber
            außerdem das Unternehmen näher.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Storytelling</div>
          <div className="text-xs">
            Das Storytelling ist eine bestimmte Erzählform. Typisch für diese
            Art des Erzählens sind vor allem eine bildhafte Sprache, welche von
            zahlreichen Metaphern und Vergleichen begleitet wird. Dem Leser
            sollen auf diese Weise Inhalte auf eine interessante und
            unterhaltsame Weise vermittelt werden. Der Leser soll sich in der
            Geschichte wiederfinden und sich zum Beispiel mit der Thematik
            identifizieren können.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Tabs</div>
          <div className="text-xs">
            Links zu anderen Bereichen wie Startseite, Messenger, Kontakte,
            Feed, Watch, Inserate, Jobs, Marketplace etc. findest Du im oberen
            Center-Bereich.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Tag</div>
          <div className="text-xs">
            Tagging ist eine Social-Media-Funktionalität, die am häufigsten auf
            Facebook und Instagram verwendet wird. Damit können Benutzer auf das
            Profil der Person zurückgreifen, die auf dem Foto zu sehen ist.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Talent Pool</div>
          <div className="text-xs">
            Im Talent Management System spielt auch der Talent Pool eine
            wichtige Rolle. Der Talent Pool ist eine Datenbank aus Bewerbern,
            welche sich zum einen bereits für eine Position beworben haben und
            leider eine Absage erhalten haben, aber zugestimmt haben, dass ihre
            Daten weiterhin gespeichert werden. Und zum anderen handelt es sich
            um Bewerbungen, welche initiativ abgegeben wurden und somit in das
            Datenbanksystem mitaufgenommen wurden. Es werden somit potenzielle,
            sehr gute Kandidaten vorgemerkt, die zeitlich verschoben eine neue
            Chance bekommen sollen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Targeting</div>
          <div className="text-xs">
            Beim Targeting handelt es sich um eine definierte Zielgruppe Im
            Onlinemarketing. Dabei wird für jede Add bzw. Werbekampagne im
            ersten Schritt die Zielgruppe definiert, an welche sich die Kampagne
            richten soll.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Teilen</div>
          <div className="text-xs">
            Mit der Teilen-Funktion können Sie die Inhalte, die Sie genießen,
            mit persönlichen Facebook-Freunden teilen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Terminierung</div>
          <div className="text-xs">
            Der Begriff „Terminierung“ bezeichnet das Vorausplanen von Social
            Media-Updates und -Inhalten mit einer Social Media
            Management-Plattform oder eines anderen Publishing-Tools. Durch das
            Terminieren ihrer Social Media-Posts können Nutzer Zeit sparen,
            indem sie mehrere Nachrichten in einem Schub verfassen – oft auch
            als Teil eines Veröffentlichungs-Freigabeprozesses oder einer
            umfangreichen Marketingkampagne. Zudem lassen sich so Zielgruppen in
            verschiedenen Zeitzonen erreichen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Thread</div>
          <div className="text-xs">
            Beginnend mit einem Beitrag, handelt es sich hier um alle Kommentare
            und Reaktionen auf diesen Beitrag. Ein Thread ist ein
            Nachrichtenstrang, der eine Konversation abbildet. Threads beginnen
            mit einer ursprünglichen Nachricht, gefolgt von einer Reihe von
            Antworten oder Kommentaren dazu. Threads sind ein wichtiger
            Bestandteil jeder Art von Online-Kommunikation, inklusive Social
            Media und E-Mail, um den Überblick über Konversationen zu behalten.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Throwback Thursday (#TBT)</div>
          <div className="text-xs">
            ist ein Hashtag, der zum Teilen alter Fotos in den sozialen Medien
            verwendet wird.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Titelbild</div>
          <div className="text-xs">
            Das 820 x 312 Pixel große Bild oben auf Ihrer Seite oder Ihrem
            Profil.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Traffic</div>
          <div className="text-xs">
            Besuchervolumen auf Ihrer Website oder Plattform
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Trending</div>
          <div className="text-xs">
            Die derzeit am häufigsten verwendeten Hashtags gelten als Trends. Im
            Treending erhälts Du die Übersicht zu den jeweils aktuellsten / am
            meisten gesehenen Inhalten. Als Trending bezeichnet man ein Thema
            oder einen Hashtag, das/der zu einem bestimmten Zeitpunkt in den
            sozialen Medien angesagt ist. Social Media-Netzwerke stellen solche
            Trends besonders heraus, um Diskussionen anzuregen und ihre Nutzer
            zu mehr Engagement zu bewegen. Das „Trending“-Konzept wurde von
            Twitter bekannt gemacht und danach auch von anderen Social
            Media-Netzwerken übernommen. Die Trends werden den für Dich
            personalisiert – je nach Standort, den Leuten, denen Du folgst, oder
            deren Seiten, du mit „Gefällt mir“ markiert hast.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Trendthema</div>
          <div className="text-xs">
            Ein überdurchschnittlich stark diskutiertes Thema in den Sozialen
            Medien.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Trendjacking</div>
          <div className="text-xs">
            Wenn Sie in ein großes Trending Topic aktiv einsteigen und die
            Begeisterung um ads Thema nutzen, um die Nutzer dazu zu bringen,
            sich mit Ihrer Marke zu beschäftigen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Troll</div>
          <div className="text-xs">
            Im Netzjargon nennt man einen Social Media-Nutzer, der vorsätzlich
            beleidigende oder lästige Beiträge postet, nur um andere Nutzer
            damit zu provozieren, einen Troll.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Unternehmenskultur</div>
          <div className="text-xs">
            Werte, Normen und Einstellungen das sind die Grundlagen, welche zur
            Unternehmenskultur zählen und welche diese prägen. Sind diese einmal
            definiert werden diese so von den Mitarbeitern und von der
            Geschäftsführung gelebt und umgesetzt.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">URL</div>
          <div className="text-xs">
            URL ist die Abkürzung für Uniform Resource Locator („einheitlicher
            Ressourcenanzeiger“) und bedeutet die Adresse einer Website-Seite
            oder anderen Internet-Ressource. URLs können UTM-Codes enthalten,
            die bei Nachverfolgung und Analytics hilfreich sind.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Usability</div>
          <div className="text-xs">
            Ein Produkt zu verwenden, ohne dass es irgendwelche Auffälligkeiten
            aufweist?! Dann wurde mit der Usability, Benutzerfreundlichkeit
            alles richtig gemacht, denn genau das ist das Ziel. Ein Produkt so
            zu entwickeln, dass die Handhabung, Verwendung durch den Verwender
            besonders einfach wahrgenommen wird. Die Usability,
            Benutzerfreundlichkeit lässt sich nicht nur auf Produkte, sondern
            auch auf die Nutzung von z.B. Websites übertragen. Lässt sich eine
            Website einfach bedienen, ist die Benutzerfreundlichkeit gelungen
            und alles wurde richtig gemacht. Fallen dem Nutzer jedoch negative
            Aspekte auf, ist die Benutzerfreundlichkeit nicht bestmöglich
            umgesetzt worden. Vanity-Metrik Die Vanity-Metrik ist ein
            Analytics-Element, das zwar gemessen werden kann, aber nichts über
            den tatsächlichen Return on Investment (ROI) aussagt. Beispiele
            dafür sind die Anzahl der Follower, Likes oder Kommentare. Diese
            Kennzahlen sollte man durch konkrete Zahlen wie die Klickrate oder
            die Conversion von Besuchern in Leads in einen Kontext stellen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Vanity-URL</div>
          <div className="text-xs">
            Eine Vanity-URL ist eine Web-Adresse, die zu Marketingzwecken mit
            einem Markennamen gekennzeichnet ist. Vanity („Eitelkeit“)-URLs
            werden speziell dazu erstellt, die normalerweise von URL-Verkürzern
            vergebene Links durch solche zu ersetzen, in denen der Markenname
            vorkommt. Beispiele dafür sind ti.me, die Vanity-URL von Time Inc.,
            oder nyti.ms (New York Times).
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Video Recruiting</div>
          <div className="text-xs">
            Videos veranschaulichen Sachverhalte und Dinge auf eine
            unterhaltsame Art und Weise. Sie machen Spaß und vermitteln dabei
            noch Informationen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Viral</div>
          <div className="text-xs">
            Der Begriff „viral“ bezieht sich in den sozialen Medien auf das
            Phänomen, dass ein bestimmter Beitrag zu einer ungewöhnlich großen
            Zahl von Interaktionen führt. Wird der Post besonders häufig
            geteilt, so gilt dies als deutlichstes Zeichen dafür, dass er viral
            geht – weil er sich wie ein Virus im Internet ausbreitet.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Virtuelle Realität (VR)</div>
          <div className="text-xs">
            Virtuelle Realität lässt den Nutzer in ein Erlebnis eintauchen, in
            dem sein Handeln real aussieht oder sich so anfühlt. In den meisten
            Fällen interagiert man über ein VR-Headset mit einer virtuellen
            Realität.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Vloggen</div>
          <div className="text-xs">
            „Vloggen“ ist eine Kombination aus den Worten „Video“ und „Bloggen“.
            Es bedeutet, dass man Video-Blog-Content erstellt und postet.
            Jemand, der vloggt, wird als Vlogger bezeichnet.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">War for Talents</div>
          <div className="text-xs">
            Der Fachkräftemangel wird auch als „War for talents“ beschrieben und
            beschreibt die Problematik der fehlenden Fachkräfte auf dem
            Arbeitsmarkt. Durch den demografischen Wandel, den späten
            Berufseinstieg und das sinkende Interesse an technischen,
            handwerklichen und IT-technischen Berufen fehlen immer mehr
            Fachkräfte auf dem freien Arbeitsmarkt. Aus diesem Grund „kämpfen“
            Unternehmen händeringend um neues Personal. Das Abwerben von
            Personal gehört deshalb immer mehr zum Alltag von Recruitern und
            Personalverantwortlichen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Web Analytics</div>
          <div className="text-xs">
            Messung, Analyse und Berichterstellung von Internetdaten - wichtig
            für das Verständnis des Traffic auf Ihrer Website.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Webinar</div>
          <div className="text-xs">
            Online-Seminar oder -Präsentation, veranstaltet von einer Person
            oder einem Unternehmen. Im Marketing werden Webinare eingesetzt, um
            die Zielgruppe über ein bestimmtes Thema aufzuklären. Mit dem
            Hashtag des Webinars wird die Diskussion über Social Media
            gefördert.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Work-Life-Balance</div>
          <div className="text-xs">
            Arbeiten, arbeiten, arbeiten… Freizeit Fehlanzeige! Genau dieser
            Problematik wirkt die sogenannte Work-Life-Balance entgegen. Denn
            diese soll ein angenehmes, ausgeglichenes Verhältnis zwischen Arbeit
            und Privatleben schaffen. Die Work-Life-Balance wird auch als
            Vereinbarkeit von Familie und Beruf bezeichnet. Meist kann diese
            positiv durch den Arbeitgeber beeinflusst werden in dem die
            Arbeitszeitmodelle entsprechend angepasst werden und dem
            Arbeitnehmer die Möglichkeit für Gleitzeit oder Home Office geboten
            wird. Auch Sport- und Freizeitangebote beeinflussen die
            Work-Life-Balance positiv.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">YOLO</div>
          <div className="text-xs">
            Du lebst nur einmal (You Only Live Once)
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Ziele</div>
          <div className="text-xs">
            Ziele sind die Zielsetzungen einer Werbekampagne in den sozialen
            Medien. Jedes Social Media-Netzwerk bietet sein eigenes Set von
            Zielen, auf die sich Anzeigen abstimmen lassen. Die Ziele für
            Facebook-Werbung sind zum Beispiel in die drei breiteren Kategorien
            „Bekanntheit“, „Erwägung“ oder „Conversions“ unterteilt. Das
            ausgewählte Ziel bestimmt, welche Anzeigenformate oder
            Zahlungsstrukturen für Ihre Kampagne zur Verfügung stehen.
          </div>
        </div>
        <div>
          <div className="text-xs font-bold">Zielgruppe</div>
          <div className="text-xs">
            Die Zielgruppe beschreibt, welchen Personenkreis du mit deiner
            Werbung ansprechen willst. Da du bei der entsprechenden
            Zielgruppen-Definition der Frage nachgehst, WEN du umwirbst,
            bezeichnest du die Personen deiner Zielgruppe auch als
            Werbesubjekte.
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default GlossaryContainer;
