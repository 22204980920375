import React, { useState } from "react";
import PostsActionBarItem from "@pages/news/posts/PostsActionBarItem";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import tw from "twin.macro";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";

type Props = {
  postId: string;
  bookmarked: boolean;
  bookmarkCount: number;
};

const PostBookmarkButton = ({ postId, bookmarked, bookmarkCount }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const { user } = useAppSelector((state) => state.user);

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  return (
    <PostsActionBarItem
      onClick={() => {
        if (user) {
          if (bookmarked) {
            remove.mutate();
          } else {
            add.mutate();
          }
        } else {
          loginModal.open();
        }
      }}
      label="Beitrag speichern"
      textColor={tw`text-gray-600 transition-all ease-out duration-200 group-hover:text-slate-700`}
      color={bookmarked ? tw`bg-slate-300` : tw`group-hover:bg-slate-300`}
      count={bookmarkCount}
    >
      {bookmarked ? (
        <BookmarkIconSolid
          className={`${actionEffect && "animate-ping-once"}`}
        />
      ) : (
        <BookmarkIcon
          onClick={() => {
            setActionEffect(true);
          }}
          onAnimationEnd={() => {
            setActionEffect(false);
          }}
        />
      )}
    </PostsActionBarItem>
  );
};

export default PostBookmarkButton;
