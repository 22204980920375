import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";
import React, { useRef } from "react";
import ReactPlayer from "react-player";
import Button from "@components/elements/input/Button";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  onChange: (text: string) => void;
  videoUrl: string;
};

const WatchTextEdit = ({ value, onChange, videoUrl = "" }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-[170px,1fr] h-[300px] gap-4">
      <div className="relative w-[170px]">
        <img
          src="/smartphone-frame-simple.png"
          className="absolute w-full pointer-events-none z-20 h-[300px]"
        />
        <div className="w-full h-full rounded-lg">
          <ReactPlayer
            width="100px"
            height="auto"
            controls={false}
            url={videoUrl}
            playing
            muted
            loop
            className="react_player rounded"
          />
        </div>
      </div>
      <div className="text-sm flex grow">
        <div className="flex flex-col w-full h-[300px] justify-start">
          <div className="h-full">
            <TextareaAutosize
              value={value}
              minRows={6}
              onChange={(e) => onChange(e.target.value)}
              css={[
                tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0 min-h-[260px] max-h-[260px]`,
              ]}
              placeholder={t("main.watchTabs.create.watchCreate.placeholder")}
            />
          </div>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => {
                if (textareaRef.current) {
                  const oldSelectionStart = textareaRef.current.selectionStart;
                  const newText =
                    textareaRef.current.value.slice(
                      0,
                      textareaRef.current.selectionStart,
                    ) +
                    "@" +
                    textareaRef.current.value.slice(
                      textareaRef.current.selectionStart,
                    );
                  textareaRef.current.value = newText;
                  onChange(newText);
                  textareaRef.current.focus();
                  textareaRef.current.setSelectionRange(
                    oldSelectionStart + 1,
                    oldSelectionStart + 1,
                  );
                }
              }}
            >
              <div className="flex justify-center gap-1 text-xs whitespace-nowrap">
                @ <div className="max-sm:hidden">Erwähnungen</div>
              </div>
            </Button>
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => {
                if (textareaRef.current) {
                  const oldSelectionStart = textareaRef.current.selectionStart;
                  const newText =
                    textareaRef.current.value.slice(
                      0,
                      textareaRef.current.selectionStart,
                    ) +
                    "#" +
                    textareaRef.current.value.slice(
                      textareaRef.current.selectionStart,
                    );
                  textareaRef.current.value = newText;
                  onChange(newText);
                  textareaRef.current.focus();
                  textareaRef.current.setSelectionRange(
                    oldSelectionStart + 1,
                    oldSelectionStart + 1,
                  );
                }
              }}
            >
              <div className="flex justify-center gap-1 text-xs whitespace-nowrap">
                # <div className="max-sm:hidden">Hashtags</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchTextEdit;
