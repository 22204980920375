import React from "react";
import Input from "@components/elements/input/Input";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

type Props = {
  label?: string;
  values?: Array<string>;
  name: string;
  placeholder?: string;
  onChange?: (array: Array<string>) => void;
  addText?: string;
};

const Tags = ({
  label,
  onChange,
  name,
  placeholder = "neuer Text",
  values = [],
  addText = "hinzufügen",
}: Props) => {
  /**
   * Remove tag
   * @param index
   */
  const removeTag = (index: number) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags.splice(index, 1);
  };

  /**
   * Add tag
   * @param tag
   */
  const addTag = (tag: string) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags.push(tag);
  };

  /**
   * Change tag
   * @param text
   * @param index
   */
  const changeTag = (text: string, index: number) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags[index] = text;
  };

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <div className="flex flex-wrap gap-x-2 gap-y-2 rounded-2xl border border-gray-300 shadow-sm sm:text-sm py-2 px-3">
        {values.map((tag: string, index: number) => {
          return (
            <div
              contentEditable={true}
              key={index}
              onInput={(e) =>
                changeTag(e.currentTarget.textContent || "", index)
              }
              className="focus:border-darkblue focus:ring-darkblue ring-gray-400 ring-1 outline-darkblue rounded-2xl py-0.5 px-2 w-fit inline-flex gap-1 items-center group"
              suppressContentEditableWarning={true}
            >
              {tag ? tag : " "}
              <button
                onClick={() => {
                  removeTag(index);
                }}
                className="hidden group-hover:block outline-none"
              >
                <XCircleIcon className="w-4 h-4 text-red-600" />
              </button>
            </div>
          );
        })}
        <button
          className="focus:border-darkblue focus:ring-darkblue ring-gray-400 ring-1 outline-darkblue rounded-2xl py-0.5 px-2 w-fit inline-flex gap-1 items-center"
          onClick={() => {
            addTag(placeholder);
          }}
        >
          {addText}
          <PlusCircleIcon className="w-4 h-4 text-green-600" />
        </button>
      </div>
      <Input name={name} type="hidden" value={JSON.stringify(values)} />
    </div>
  );
};

export default Tags;
