import React from "react";
import Panel from "@components/box/Panel";
import { ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type cartContentType = {
  count: number;
  price: number;
  id: string;
};

const MyCart = () => {
  const { user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const cartContent: cartContentType[] = [];

  return (
    <Panel title={t("main.shoppingVenture.title")}>
      <div className="flex flex-row justify-evenly">
        <NavLink to={"/verwaltung/marketplace/warenkorb"}>
          <div className="w-[60px] h-[60px] p-2.5 rounded-full border-2 border-darkblue bg-transparent relative">
            <ShoppingCartIcon className="text-darkblue heroicon-stroke-w-1.2" />
            <div className="absolute -top-[12px] -right-[12px]">
              <div className="w-[30px] h-[30px] bg-white rounded-full border-darkblue border-2 flex items-center justify-center">
                {cartContent.length > 0
                  ? cartContent
                      .map((item) => item.count)
                      .reduce((sum, amount) => sum + amount)
                  : 0}
              </div>
            </div>
          </div>
          <div className="whitespace-nowrap text-center text-darkblue">
            {t("main.shoppingVenture.shoppingCart")}
          </div>
        </NavLink>
        {user ? (
          <NavLink to={"/profile/" + user.id}>
            <div className="w-[60px] h-[60px] p-2.5 rounded-full border-2 border-darkblue bg-transparent">
              <UserIcon className="text-darkblue heroicon-stroke-w-1.2" />
            </div>
            <div className="whitespace-nowrap text-center text-darkblue">
              {t("main.shoppingVenture.profile")}
            </div>
          </NavLink>
        ) : (
          <div>
            <div className="w-[60px] h-[60px] p-2.5 rounded-full border-2 border-darkblue bg-transparent">
              <UserIcon className="text-darkblue heroicon-stroke-w-1.2" />
            </div>
            <div className="whitespace-nowrap text-center text-darkblue">
              {t("main.shoppingVenture.profile")}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2 justify-around my-2 items-center">
        <NavLink
          to={"/verwaltung/marketplace/bestellungen"}
          className="bg-gray-200 text-sm text-center px-2 py-0.5 h-fit inline-flex items-center justify-center rounded-full"
        >
          {t("main.shoppingVenture.orders")}
        </NavLink>
        <div className="flex flex-row gap-8">
          <NavLink
            to={"/verwaltung/marketplace/kaufübersicht"}
            className="bg-gray-200 text-sm text-center px-2 py-0.5 h-fit inline-flex items-center justify-center rounded-full"
          >
            {t("main.shoppingVenture.purchases")}
          </NavLink>
          <NavLink
            to={"/verwaltung/mein_shop/verkaufen"}
            className="bg-gray-200 text-sm text-center px-2 py-0.5 h-fit inline-flex items-center justify-center rounded-full"
          >
            {t("main.shoppingVenture.sell")}
          </NavLink>
        </div>
        <NavLink
          to={"/verwaltung/marketplace/wunschliste"}
          className="bg-gray-200 text-sm text-center px-2 py-0.5 h-fit inline-flex items-center justify-center rounded-full"
        >
          {t("main.shoppingVenture.wishlist")}
        </NavLink>
      </div>
      {/* Bottom side */}
      <div className="flex flex-col gap-1 items-center rounded-3xl bg-gray-200 text-darkblue pt-2 pb-2 px-2 mt-4">
        <div className="sidebar--title">
          {t("main.shoppingVenture.basket.title")}
        </div>
        <div className="text-xs">
          {t("main.shoppingVenture.basket.total")} (
          {cartContent.length > 0
            ? cartContent
                .map((item) => item.count)
                .reduce((sum, amount) => sum + amount)
            : 0}{" "}
          {t("main.shoppingVenture.basket.article")})
        </div>
        <div className="text-xs">
          {cartContent.length > 0
            ? cartContent
                .map((item) => item.count * item.price)
                .reduce((sum, amount) => sum + amount)
                .toFixed(2)
            : 0}
          € inkl. Mwst.
        </div>
        <div className="text-xs">
          {t("main.shoppingVenture.basket.shippingOptions")}
        </div>
        <NavLink
          to={"/verwaltung/mein_shop/verkaufen"}
          className="bg-cyan-600 text-sm text-center text-gray-50 px-2 py-0.5 h-fit inline-flex items-center justify-center rounded-full mt-2"
        >
          {t("main.shoppingVenture.buttons.buyNow")}
        </NavLink>
      </div>
    </Panel>
  );
};

export default MyCart;
