import React, { useState } from "react";
import Input from "@components/elements/input/Input";
import ButtonPanel from "@components/box/ButtonPanel";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import tw from "twin.macro";
import UserLikes from "@pages/profile/UserLikes";
import PostLikes from "@pages/profile/PostLikes";
import WatchLikes from "@pages/profile/WatchLikes";
import { useTranslation } from "react-i18next";

type Props = {
  type: string;
  userId: string;
  onClose: () => void;
  setTab?: (
    e:
      | "likes"
      | "feed"
      | "watch"
      | "following"
      | "follows"
      | "feed_likes"
      | "watch_likes"
      | undefined,
  ) => void;
};

const UserLikeList = (props: Props) => {
  const { userId } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const headlines: { [index: string]: string } = {
    likes: "Likes",
    feed_likes: "Likes",
    watch_likes: "Likes",
  };

  return (
    <ButtonPanel onClose={props.onClose} title={headlines[props.type]}>
      <TabMenu subTab>
        <button
          css={[props.type === "likes" && tw`border-b-2 border-darkblue`]}
          onClick={() => {
            props.setTab && props.setTab("likes");
          }}
        >
          Meine Likes
        </button>
        <button
          css={[props.type === "feed_likes" && tw`border-b-2 border-darkblue`]}
          onClick={() => {
            props.setTab && props.setTab("feed_likes");
          }}
        >
          Feed
        </button>
        <button
          css={[props.type === "watch_likes" && tw`border-b-2 border-darkblue`]}
          onClick={() => {
            props.setTab && props.setTab("watch_likes");
          }}
        >
          Watch
        </button>
      </TabMenu>

      <Input
        name="search-contacts"
        placeholder={t("main.feedTabs.placeholder")}
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <div className="mt-2">
        {props.type === "likes" && (
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
            <UserLikes
              type={props.type}
              userId={userId}
              searchQuery={searchQuery}
            />
          </div>
        )}
        {props.type === "feed_likes" && (
          <PostLikes userId={userId} searchQuery={searchQuery} />
        )}
        {props.type === "watch_likes" && (
          <WatchLikes userId={userId} searchQuery={searchQuery} />
        )}
      </div>
    </ButtonPanel>
  );
};

export default UserLikeList;
