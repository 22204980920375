import { User } from "@/utils/user";
import axios, { AxiosResponse } from "axios";
import { loginRequest } from "@/api/auth/login";
import api from "@/api/api";

export type ResetPasswordRequest = {
  email: string;
};

async function requestPasswordReset(
  request: ResetPasswordRequest
): Promise<AxiosResponse> {
  return await api.post("/api/v1/auth/reset", request);
}

export default requestPasswordReset;
