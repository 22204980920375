import React from "react";
import {
  DocumentTextIcon,
  EyeIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { Link, NavLink } from "react-router-dom";
import { HeartIcon } from "@heroicons/react/24/solid";

type Props = {
  thumbnail: string;
  viewCount: number;
  postId: string;
  mediaId: string;
  key?: string;
};

const WatchThumbnailItem = ({
  thumbnail,
  viewCount,
  mediaId,
  postId,
}: Props) => {
  return (
    <NavLink
      to={`/watch?media_id=${mediaId}&post_id=${postId}`}
      className="h-[200px] relative group"
    >
      <img src={thumbnail} className="object-cover h-full w-full mx-auto" />
      <PlayIcon className="w-5 h-5 absolute top-1 right-1" />
      <div className="absolute top-0 lg:invisible group-hover:visible w-full h-full group-hover:bg-black/30 max-lg:bg-black/30">
        <div className="absolute top-1/2 -translate-y-1/2 w-full px-4">
          <div className="flex flex-row items-center justify-center gap-1 text-white">
            <EyeIcon className="w-5 h-5 text-white flip -scale-x-100" />
            <div>{viewCount}</div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default WatchThumbnailItem;
