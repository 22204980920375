import React, { Fragment } from "react";
import Input from "@components/elements/input/Input";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

type dateRange = {
  start: string;
  end: string;
  description: string;
};

type Props = {
  label?: string;
  values?: Array<dateRange>;
  name: string;
  onChange: (array: Array<dateRange>) => void;
  addText?: string;
};

const ProfileEditCVList = ({
  label,
  onChange,
  name,
  values = [],
  addText = "Neuen Link hinzufügen",
}: Props) => {
  /**
   * Remove item
   * @param index
   */
  const removeItem = (index: number) => {
    const newValues: Array<dateRange> = values;
    newValues.splice(index, 1);
    onChange(newValues);
  };

  /**
   * Adds item
   * @param item
   */
  const addItem = (item: dateRange) => {
    const newValues: Array<dateRange> = values;
    newValues.push(item);
    onChange(newValues);
  };

  /**
   * Changes the start date
   * @param start
   * @param index
   */
  const changeStart = (start: string, index: number) => {
    const date = new Date(start);
    const sqlTimestamp = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T00:00:00Z`;
    const newValues: Array<dateRange> = values;
    newValues[index].start = sqlTimestamp;
    onChange(newValues);
  };

  /**
   * Changes the end date
   * @param end
   * @param index
   */
  const changeEnd = (end: string, index: number) => {
    const date = new Date(end);
    const sqlTimestamp = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T00:00:00Z`;
    const newValues: Array<dateRange> = values;
    newValues[index].end = sqlTimestamp;
    onChange(newValues);
  };

  /**
   * Changes the description
   * @param description
   * @param index
   */
  const changeDescription = (description: string, index: number) => {
    const newValues: Array<dateRange> = values;
    newValues[index].description = description;
    onChange(newValues);
  };

  return (
    <div className="mt-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="flex flex-col rounded-2xl border border-gray-300 shadow-sm sm:text-sm py-2 pl-2 pr-4">
        {values.map((item: dateRange, index: number) => {
          return (
            <div
              key={index}
              className="grid grid-cols-[140px,140px,auto,20px] items-center gap-x-2 gap-y-2"
            >
              <Input
                name=""
                onChange={(e) => changeStart(e, index)}
                type="date"
              />
              <Input
                name=""
                onChange={(e) => changeEnd(e, index)}
                type="date"
              />
              <Input name="" onChange={(e) => changeDescription(e, index)} />
              <button
                onClick={() => {
                  removeItem(index);
                }}
              >
                <MinusCircleIcon className="w-6 h-6 text-red-600" />
              </button>
            </div>
          );
        })}
        <div className="flex flex-row justify-center items-center relative mt-3">
          <div className="col-span-3 text-center">{addText}</div>
          <button
            onClick={() => {
              const date = new Date();
              const sqlTimestamp = `${date.getFullYear()}-${String(
                date.getMonth() + 1
              ).padStart(2, "0")}-${String(date.getDate()).padStart(
                2,
                "0"
              )}T00:00:00Z`;
              addItem({
                start: sqlTimestamp,
                end: sqlTimestamp,
                description: "Titel",
              });
            }}
          >
            <PlusCircleIcon className="w-6 h-6 text-green-600 absolute -right-1 top-0" />
          </button>
        </div>
      </div>
      <Input name={name} type="hidden" value={JSON.stringify(values)} />
    </div>
  );
};

export default ProfileEditCVList;
