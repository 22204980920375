import React, { useEffect, useState } from "react";
import Panel from "@components/box/Panel";
import UserWidget from "@components/elements/UserWidget";
import { formatDate } from "@/utils/formatDate";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import LinkPreview from "@components/elements/LinkPreview";
import { Post } from "@/utils/post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import PostsDropdown from "@pages/news/posts/PostsDropdown";
import PostsActionBar from "@pages/news/posts/PostsActionBar";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import { useVisibility } from "@/hooks/useVisibility";
import PostCommentSection from "@components/elements/posts/comments/PostCommentSection";
import api from "@/api/api";
import Lightbox from "@components/elements/shared/Lightbox";
import TextView from "@components/elements/textedit/TextView";
import PostGallery from "@components/elements/shared/PostGallery";
import { useAppSelector } from "@/state/hooks";

type Props = {
  postId: string;
  post: Post;
  author: User;
  text: string;
  created_at: string;
  key?: string;
  repost?: Post;
};

const checkHideLinkText = (lexicalObjectRaw: string) => {
  try {
    const lexicalObject = JSON.parse(lexicalObjectRaw);
    if (lexicalObject["root"]["children"].length !== 1) return false;
    if (lexicalObject["root"]["children"][0]["children"].length !== 1)
      return false;
    return (
      lexicalObject["root"]["children"][0]["children"][0]["type"] === "link" ||
      lexicalObject["root"]["children"][0]["children"][0]["type"] === "autolink"
    );
  } catch (e) {
    return false;
  }
};

// remove first link from lexicalObject
const removeLinkFromLexicalObject = (
  lexicalObjectRaw: string,
  link: string,
) => {
  try {
    if (!link) return lexicalObjectRaw;

    const lexicalObject = JSON.parse(lexicalObjectRaw);
    if (lexicalObject["root"]["children"].length === 0) return lexicalObjectRaw;
    if (lexicalObject["root"]["children"][0]["children"].length === 0)
      return lexicalObjectRaw;

    for (let i = 0; i < lexicalObject["root"]["children"].length; i++) {
      if (lexicalObject["root"]["children"][i]["children"].length === 0)
        continue;
      if (
        lexicalObject["root"]["children"][i]["children"][0]["type"] ===
          "link" ||
        lexicalObject["root"]["children"][i]["children"][0]["type"] ===
          "autolink"
      ) {
        if (
          lexicalObject["root"]["children"][i]["children"][0]["url"] === link
        ) {
          lexicalObject["root"]["children"][i]["children"].shift();
          break;
        }
      }
    }
    return JSON.stringify(lexicalObject);
  } catch (e) {
    return lexicalObjectRaw;
  }
};

export function formatDateDifference(
  currentDate: Date,
  postDate: Date,
): string {
  const diffInSeconds = Math.floor(
    (currentDate.getTime() - postDate.getTime()) / 1000,
  );
  const mins = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(mins / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (mins < 1) return "Gerade eben";
  if (mins === 1) return "Vor einer 1 Min";
  if (mins < 60) return `vor ${mins} Min`;
  if (hours === 1) return "Vor 1 h";
  if (hours < 24) return `vor ${hours} h`;
  if (days === 1) return "Vor 1 T";
  if (days < 7) return `vor ${days} T`;
  if (weeks === 1) return "Vor 1 W";
  return `vor ${weeks} W`;
}

const PostWidget = ({ author, created_at, ...props }: Props) => {
  const [openComments, setOpenComments] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const linkPreview = props.repost
    ? props.repost.link_preview
    : props.post.link_preview;
  const text = removeLinkFromLexicalObject(
    props.repost ? props.repost.text : props.text,
    props.post.link,
  );
  const media = props.repost ? props.repost.media : props.post.media;
  const watch = props.repost ? props.repost.watch : props.post.watch;
  const [downloadRunning, setDownloadRunning] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const urlRegex = /(https?:\/\/[^\s]+)|#([a-zA-ZäöüÄÖÜß]+)/g;
  const queryClient = useQueryClient();
  const { user } = useAppSelector((state) => state.user);
  const [isVisible, ref] = useVisibility();
  const [viewed, setViewed] = useState(false);

  const hideLinkText =
    linkPreview && (linkPreview.url === text || checkHideLinkText(text));

  let match;
  const links: any[] = [];
  while ((match = urlRegex.exec(text)) !== null) {
    links.push(match[0]);
  }

  const like = useMutation([`like-${props.post.id}-${user?.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${props.post.id}/like`);
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unlike = useMutation([`unlike-${props.post.id}-${user?.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${props.post.id}/unlike`);
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (isVisible && !viewed) {
      setViewed(true);
      axios.get(`/api/v1/posts/${props.post.id}/view`);
    }
  }, [isVisible, props.post.id, viewed]);

  return (
    <Panel mobileBorder={false}>
      <PostsDropdown
        post={props.post}
        onDownloadStart={() => setDownloadRunning(true)}
        onDownloadEnd={() => setDownloadRunning(false)}
        onDownloadProgress={(progress) => setDownloadProgress(progress)}
      />
      <div ref={ref}>
        <div className="flex gap-3 w-full">
          <div className="w-[35px]">
            <UserWidget user={author} size={35} />
          </div>
          <div css={[tw`text-sm grow overflow-hidden`]}>
            <div className="my-auto w-full mb-2">
              <NavLink
                to={`/profile/${author.id}`}
                className="inline-flex items-center gap-2 text-base font-semibold"
              >
                {getName(author)}{" "}
                {author.type === "person" && (
                  <img src="/images/person_verify.svg" className="w-4 h-4" />
                )}
                {author.type === "company" && (
                  <img src="/images/company_verify.svg" className="w-4 h-4" />
                )}
                {author.type === "club" && (
                  <img src="/images/club_verify.svg" className="w-4 h-4" />
                )}
                {props.repost && (
                  <NavLink
                    to={`/profile/${props.repost.author.id}`}
                    className="flex gap-1"
                  >
                    <span className="text-xs font-medium">repost von</span>
                    <UserWidget
                      user={props.repost.author}
                      size={16}
                      follow={false}
                      online={false}
                    />
                    <span className="text-xs font-medium">
                      {getName(props.repost.author)}
                    </span>
                  </NavLink>
                )}
              </NavLink>
              <div className="text-[0.7rem] leading-4 text-gray-600">
                {formatDateDifference(new Date(), new Date(created_at))}
              </div>
            </div>
            <div className="w-full">
              {openLightbox && (
                <Lightbox
                  onClose={() => setOpenLightbox(false)}
                  media={media.map((m) => {
                    return {
                      type: m.type,
                      url: m.type === "video" ? m.key : m.data_url,
                      key: m.key,
                    };
                  })}
                />
              )}
              {downloadRunning && (
                <Panel>
                  Medien von diesem Post werden heruntergeladen!
                  <div className="w-full rounded-full h-2.5">
                    <div
                      className="bg-darkblue h-2.5 rounded-full"
                      style={{ width: `${downloadProgress}%` }}
                    ></div>
                  </div>
                </Panel>
              )}
              {linkPreview && <LinkPreview link={linkPreview} />}
              {watch && media && media[0] && (
                <div className="aspect-square rounded-2xl overflow-hidden border border-gray-400 mt-3">
                  <ReactPlayer
                    width="100%"
                    controls={true}
                    height="100%"
                    url={`https://cdn.tradefoox.iaccam.com/${media[0].key}/${media[0].file_name}`}
                  />
                </div>
              )}
              {media.length > 0 && (
                <PostGallery
                  onClick={() => setOpenLightbox(true)}
                  media={media}
                />
              )}
              {!hideLinkText && text && <TextView value={text} />}
            </div>
            <PostsActionBar
              toggleComments={() => setOpenComments((value) => !value)}
              toggleLike={() => {
                if (user) {
                  if (props.post.liked) {
                    unlike.mutate();
                  } else {
                    like.mutate();
                  }
                }
              }}
              isLiked={props.post.liked}
              isReposted={props.post.is_reposted}
              likeCount={props.post.like_count}
              viewCount={props.post.view_count}
              bookmarkCount={props.post.bookmark_count}
              repostCount={props.post.repost_count}
              bookmarked={props.post.bookmarked}
              postId={props.post.id}
              commentCount={props.post.comment_count}
            />
          </div>
        </div>
      </div>
      {openComments && (
        <PostCommentSection
          postId={props.postId}
          viewCount={props.post.view_count}
        />
      )}
    </Panel>
  );
};

export default PostWidget;
