import React, { useState, useRef } from "react";
import LegacyLinkPreview from "@components/elements/LegacyLinkPreview";
import Checkbox from "@components/elements/input/Checkbox";
import Button from "@components/elements/input/Button";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import EmojiPicker from "emoji-picker-react";
import PostTextEdit from "@components/elements/posts/form/shared/PostTextEdit";
import regex from "@/utils/regex";
import PostUserSelect from "@components/elements/posts/form/shared/PostUserSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PostMedia from "@components/elements/posts/form/shared/PostMedia";
import { Post } from "@/utils/post";
import PopupWrapper from "@components/elements/PopupWrapper";
import api from "@/api/api";
import { findAutolink } from "@components/elements/posts/form/CreatePost";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  post: Post;
};

const EditPost = ({ onSuccess, post, onClose }: Props) => {
  const [adult, setAdult] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [text, setText] = useState(post.text);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >(
    post.media.map((m) => ({
      id: m.id,
      key: m.key,
      file_name: m.file_name,
      type: m.type,
      thumbnail: m.thumbnail,
    })),
  );
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [disabledLink, setDisabledLink] = useState(false);

  let parsed = { root: undefined };

  if (!disabledLink) {
    try {
      parsed = JSON.parse(text);
    } catch (e) {
      /* empty */
    }
  }

  const link = !disabledLink && parsed.root && findAutolink(parsed.root);

  const send = useMutation(["post"], {
    mutationFn: async () => {
      const res = await api.patch("/api/v1/posts/" + post.id, {
        text,
        adult,
        media: media.map((m) => m.id),
        link_preview_id: linkPreviewId,
        link: disabledLink ? undefined : link || "",
      });
      return res.data;
    },
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PopupWrapper onClose={onClose}>
      <div className="grid grid-flow-row gap-2">
        <div className="font-semibold">Beitrag bearbeiten</div>
        <PostUserSelect />
        <PostMedia
          onChange={(data) => setMedia((value) => [...value, data])}
          media={media}
          onDelete={(id) =>
            setMedia((data) => data.filter((item) => item.id !== id))
          }
        />
        {link && (
          <LegacyLinkPreview
            link={link}
            onChange={setLinkPreviewId}
            onDelete={() => {
              setLinkPreviewId(undefined);
              setDisabledLink(true);
            }}
            small={true}
          />
        )}
        <PostTextEdit value={text} onChange={setText} textRef={textareaRef} />
        <div className="flex flex-row items-baseline justify-between">
          <Checkbox
            name="18+"
            checked={adult}
            onChange={(checked) => setAdult(checked)}
          >
            Ab 18
          </Checkbox>
          <div className="max-sm:hidden"></div>

          <div className="w-fit">
            <Button onClick={send.mutate}>
              <PaperAirplaneIcon className="h-[20px]" />
            </Button>
          </div>
        </div>
        {emojiOpen && (
          <EmojiPicker
            onEmojiClick={(emoji) =>
              setText(
                (data) =>
                  data + String.fromCodePoint(parseInt(emoji.unified, 16)),
              )
            }
          />
        )}
      </div>
    </PopupWrapper>
  );
};

export default EditPost;
