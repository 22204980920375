import BackButton from "@/components/nav/BackButton";
import React from "react";
import { NavLink } from "react-router-dom";

type PlaceType = {
  title: string;
  link: string;
  thumbnail: string;
};

const LeisureMaps = () => {
  const places: Array<PlaceType> = [
    {
      title: "Chemnitzer Vereine",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
    {
      title: "Chemnitzer Sehenswürdigkeiten und Museen",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
    {
      title: "Galerien, Kunst und Ausstellungen",
      link: "https://www.google.com/maps/d/viewer?hl=de&mid=1OFHcnel2Ezl2Kjn5vVLKVOJRcjkYxxIb&ll=50.83351219182122%2C12.92018098075773&z=11",
      thumbnail: "/images/info/karte_vereine.jpg",
    },
  ];

  return (
    <>
      <BackButton />
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-3.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute top-1/2 left-6 -translate-y-1/2 font-semibold italic flex flex-col">
            <div className="bg-white bg-opacity-80 text-darkcyan w-fit px-1 text-2xl">
              Kulturkarte Chemnitz
            </div>
            <div className="bg-darkblue bg-opacity-80 text-white w-fit px-1 ml-4">
              Kunst und Kultur
            </div>
          </div>
        </section>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          {places.map((place: PlaceType, index) => {
            return (
              <NavLink to={place.link} key={index} target="_blank">
                <div className="relative group h-[225px] shadow-lg overflow-hidden">
                  <img
                    src={place.thumbnail}
                    className="w-full h-[225px] object-cover group-hover:brightness-50 group-hover:scale-150"
                  />
                  <h2 className="text-xs font-semibold absolute top-2 text-center w-full break-words group-hover:text-gray-100 group-hover:font-semibold group-hover:text-base px-2">
                    {place.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LeisureMaps;
