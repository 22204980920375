import React from "react";
import Panel from "@components/box/Panel";
import SuggestionsCenterItem from "@components/elements/sidebar/suggestions/SuggestionsCenterItem";
import axios from "axios";
import tw from "twin.macro";
import { NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import api from "@/api/api";
import { useTranslation } from "react-i18next";

type Props = { key?: string };

type Response = {
  data: any[];
};

const AssignmentsSuggestions = ({ key }: Props) => {
  const { t } = useTranslation();
  const assignments = useQuery<Response, Error>(["assignments"], async () => {
    const { data } = await api.get("/api/v1/assignments");
    return data;
  });

  if (!assignments.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel
        title="Beliebte Inserate auf Tradefoox"
        mobileBorder={false}
        key={key}
      >
        <div className="flex gap-4 overflow-x-auto no-scrollbar">
          {assignments.data.data.map((item) => (
            <SuggestionsCenterItem
              key={item.id}
              name={item.title}
              link={"/inserate/" + item.id}
              picture={
                item.user.avatar || `/images/placeholder/${item.user.type}.png`
              }
              imageStyle={tw`rounded-xl w-[100px] h-[150px] object-cover`}
              nameStyle={tw`w-[100px]`}
            />
          ))}
          <NavLink to="/inserate/">
            <div className="w-[100px] h-[150px] flex flex-col justify-center text-center">
              {t("buttons.showMore")}
            </div>
          </NavLink>
        </div>
      </Panel>
    </div>
  );
};
export default AssignmentsSuggestions;
