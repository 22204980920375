import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "@components/elements/input/Input";
import Button from "@components/elements/input/Button";
import login from "@/api/auth/login";
import { trackFormSubmission } from "@/utils/hubspot";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import Panel from "@/components/box/Panel";
import isApp from "@/utils/isApp";
import AppLink from "@components/elements/shared/AppLink";
import { setUser, setToken } from "@/state/user/userSlice";
import { useDispatch } from "react-redux";

const LoginContainer = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();

  const loginMutation = useMutation(["login"], {
    mutationFn: () => {
      trackFormSubmission("loginForm");
      return login({
        email,
        password,
      });
    },
    onSuccess: (loginData) => {
      dispatch(setUser(loginData.user));
      dispatch(setToken(loginData.token));
      setTimeout(
        () =>
          navigate(
            loginData.user.start_page
              ? "/" + loginData.user.start_page
              : "/news",
          ),
        200,
      );
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="E-Mail oder Passwort falsch"
          title="Login"
        />,
      );
    },
  });

  return (
    <Panel mobileBorder={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!loginMutation.isLoading) loginMutation.mutate();
        }}
        id="loginForm"
        className="grid grid-flow-row gap-1 text-center justify-center text-black"
      >
        <div className="text-darkblue font-medium text-xl">
          TRADEFOOX – wir verbinden Euch.
          <br /> Ein Netzwerk voller Möglichkeiten
        </div>
        <Input
          name="email"
          label="E-Mail"
          value={email}
          placeholder="max.mustermann@beispiel.de"
          type="email"
          onChange={(e) => setEmail(e)}
        />
        <Input
          name="password"
          label="Passwort"
          value={password}
          placeholder="Passwort"
          type="password"
          onChange={(e) => setPassword(e)}
        />
        <Button
          submit
          onClick={() => {
            if (!loginMutation.isLoading) loginMutation.mutate();
          }}
        >
          Login
        </Button>
        <div className="flex flex-row items-center justify-center gap-1 text-darkblue">
          <div className="text-3xl">!</div>
          <div className="whitespace-nowrap text-mini">
            Passwort vergessen? <br />
            Fordere{" "}
            <NavLink to="/verwaltung/passwort" className="text-darkcyan">
              hier
            </NavLink>{" "}
            ein neues Passwort an
          </div>
        </div>
        <div className="text-sm text-gray-700">
          Videos, Fotos und Beiträge teilen Jobangebote, Unternehmen und Vereine
          vor Ort finden - alles auf einer Plattform Immer Up to Date: Hier,
          Jetzt, Dort & Immer
        </div>
        <Button
          onClick={() => {
            navigate("/register");
          }}
          variant={Button.Variants.Cyan}
        >
          Neues Konto erstellen
        </Button>
        <AppLink />
        <div className="flex gap-2 justify-center">
          <NavLink to="/impressum" className="text-sm">
            Impressum
          </NavLink>
          <span className="text-sm">|</span>
          <NavLink to="/datenschutz" className="text-sm">
            Datenschutz
          </NavLink>
        </div>
      </form>
    </Panel>
  );
};

export default LoginContainer;
