import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/state/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/api/api";
import wait from "@/utils/wait";
import ModalWrapper from "@components/elements/shared/ModalWrapper";
import { hideBlockUserModal } from "@/state/blockUserModal/actions";

const BlockUserModal = () => {
  const dispatch = useDispatch();
  const blockUserModalState = useSelector(
    (state: RootState) => state.blockUserModal
  );
  const queryClient = useQueryClient();

  const block = useMutation(["block"], {
    mutationFn: async () => {
      const res = await api.get(
        `/api/v1/users/${blockUserModalState.userId}/block`
      );
      await wait(2000);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
  });
  const handleClose = () => {
    dispatch(hideBlockUserModal());
    setTimeout(block.reset, 500);
  };

  return (
    <ModalWrapper
      open={blockUserModalState.isVisible}
      onCancel={handleClose}
      onConfirm={block.mutate}
      title="Nutzer Blockieren"
      loading={block.isLoading}
      success={block.isSuccess}
      failed={block.isError}
    >
      Möchtest du diesen Benutzer wirklich blockieren?
    </ModalWrapper>
  );
};

export default BlockUserModal;
