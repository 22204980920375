import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React, { RefObject } from "react";

const ScrollIcons = ({
  galleryRef,
}: {
  galleryRef: RefObject<HTMLDivElement>;
}) => {
  if (!galleryRef) return <></>;

  return (
    <>
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
        onClick={() => {
          galleryRef.current &&
            galleryRef.current.scrollTo(galleryRef.current.scrollLeft - 150, 0);
        }}
      >
        <div className="group absolute opacity-0 hover:opacity-100 left-0 top-0 h-full w-full z-0 flex items-center justify-center">
          <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
            <ChevronLeftIcon className="h-[25px] w-[25px] mx-auto z-50" />
          </div>
        </div>
      </div>

      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 h-full w-20 cursor-pointer flex items-center pointer-events-none sm:pointer-events-auto"
        onClick={() => {
          galleryRef.current &&
            galleryRef.current.scrollTo(galleryRef.current.scrollLeft + 150, 0);
        }}
      >
        <div className="group absolute opacity-0 hover:opacity-100 right-0 top-0 h-full w-full z-0 flex items-center justify-center">
          <div className="bg-white rounded-full p-2 group-hover:bg-opacity-50">
            <ChevronRightIcon className="h-[25px] w-[25px] mx-auto z-50" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollIcons;
