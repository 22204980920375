import React, { Fragment, useState } from "react";
import TabFilter from "@components/menu/TabFilter";
import Tab from "@/components/menu/Tab";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import { Post } from "@/utils/post";
import PostWidget from "@components/elements/PostWidget";
import api from "@/api/api";
import Input from "@/components/elements/input/Input";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import { NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  tab?: string;
};

const HashtagContainer = ({ tab = "feed" }: Props) => {
  const { hashtag } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  const tabs = [
    {
      label: (
        <>
          <NewspaperIcon className="w-4 h-4 float-left mr-1.5" /> Feed
        </>
      ),
      key: "feed",
      url: "/tags/" + hashtag + "/feed",
    },
    {
      label: (
        <>
          <VideoCameraIcon className="w-4 h-4 float-left mr-1.5" /> Watch
        </>
      ),
      key: "watch",
      url: "/tags/" + hashtag + "/watch",
    },
  ];

  const posts = useInfiniteQuery<Response>(
    ["posts", hashtag],
    async ({ pageParam = 0 }) => {
      console.log(hashtag);
      const res = await api.get(
        `/api/v1/feed?page=${pageParam}&q=%23${hashtag}`,
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const { user } = useAppSelector((state) => state.user);
  if (!user) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="font-bold truncate">#{hashtag}</div>
      <Input
        placeholder={t("main.feedTabs.placeholder")}
        name="search_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <Fragment>
        <TabMenu subTab>
          {tabs.map((tabItem) => {
            return (
              <>
                <TabLink subTab to={tabItem.url}>
                  {tabItem.label}
                </TabLink>
              </>
            );
          })}
        </TabMenu>

        {tab === "watch" && (
          <div className="grid grid-cols-2 md:grid-cols-3 gap-1">
            {/* <Media image="/images/default_header_profile.png" type="image" /> */}
          </div>
        )}
        {tab === "feed" && (
          <>
            {posts.data?.pages.map(
              (page) =>
                page &&
                page.data.map((post: Post) => (
                  <PostWidget
                    postId={post.id}
                    post={post}
                    key={post.id}
                    text={post.text}
                    author={post.author}
                    repost={post.reposted}
                    created_at={post.created_at}
                  />
                )),
            )}
            {(posts.hasNextPage || posts.isFetchingNextPage) && (
              <ShowMoreButton onClick={() => posts.fetchNextPage()} />
            )}
          </>
        )}
      </Fragment>
    </div>
  );
};

export default HashtagContainer;
