import React from "react";
import Panel from "@components/box/Panel";
import { User } from "@/utils/user";
import UserWidget from "@components/elements/UserWidget";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import ContactContextMenu from "@pages/contacts/context/ContactContextMenu";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

type Props = { user: User; mode?: string; key?: string };

const ContactMyContactsItem = ({ user }: Props) => {
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const { user: userData } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/chats/create/${user.id}`);
      return res.data;
    },
    onSuccess: (data) => {
      navigate("/messenger", { state: { chatId: data.data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel>
      <ContactContextMenu userId={user.id} />
      <div>
        <div className="text-sm flex flex-col w-full gap-2">
          <NavLink
            to={"/profile/" + user.id}
            className="flex flex-col gap-1 w-full"
          >
            <UserWidget
              user={user}
              showName={true}
              follow={false}
              verified={true}
            />
          </NavLink>
          <div className="flex flex-row justify-between text-gray-600 text-xs">
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.followers.title")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to="/contacts">
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.following.title")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
            <div className="flex flex-col items-center gap-x-1">
              <div>{t("main.likes.title")}</div>
              <div>{user.like_count}</div>
            </div>
          </div>
          <Button
            onClick={() => (userData ? createChat.mutate() : loginModal.open())}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            <div className="inline-flex items-center gap-1">
              <EnvelopeIcon className="w-4 h-4" />
              Nachricht senden
            </div>
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default ContactMyContactsItem;
