import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  key?: string;
};

const CVItem = ({ key = "cvitem" }: Props) => (
  <NavLink key={key} to="" className="h-[200px] relative group"></NavLink>
);

export default CVItem;
