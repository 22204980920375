import React, { useMemo } from "react";
import MarketplaceSuggestions from "@/components/elements/sidebar/suggestions/MarketplaceSuggestions";
import JobsSuggestions from "@/components/elements/sidebar/suggestions/JobsSuggestions";
import AssignmentsSuggestions from "@/components/elements/sidebar/suggestions/AssignmentsSuggestions";
import WatchSuggestions from "@/components/elements/sidebar/suggestions/WatchSuggestions";
import NewsfeedSuggestions from "@/components/elements/sidebar/suggestions/NewsfeedSuggestions";

const SuggestionsContainer = () => {
  const seed = useMemo(() => {
    return Math.floor(Math.random() * 100);
  }, []);

  if (seed < 20) {
    return <NewsfeedSuggestions />;
  } else if (seed < 40) {
    return <JobsSuggestions />;
  } else if (seed < 60) {
    return <MarketplaceSuggestions />;
  } else if (seed < 80) {
    return <AssignmentsSuggestions />;
  } else {
    return <WatchSuggestions />;
  }
};

export default SuggestionsContainer;
