import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Panel from "@/components/box/Panel";
import UserWidget from "@/components/elements/UserWidget";
import Button from "@/components/elements/input/Button";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  HeartIcon,
  BookmarkIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/utils/user";
import axios from "axios";
import getName from "@/utils/getName";
import tw from "twin.macro";
import api from "@/api/api";

const GroupDetailsContainer = () => {
  const { userId } = useParams();

  const user = useQuery<User, Error>([`user-${userId}`], async () => {
    const res = await api.get(`/api/v1/users/${userId}`);
    return res.data.data;
  });

  const [textMinimized, setTextMinimized] = useState(true);
  const sampleText =
    "WCPN (104.9 FM) is a non-commercial educational radio station licensed to Lorain, Ohio, featuring a public radio format as a repeater of Kent–licensed WKSU. Owned by Ideastream Public Media, the station serves the western portion of Greater Cleveland and parts of surrounding Northeast Ohio. By virtue of WKSU, studios are located at Playhouse Square in Downtown Cleveland,[2] while WCPN's transmitter resides in the Cleveland suburb of Avon. In addition to a standard analog transmission, WCPN broadcasts over four HD Radio channels and streams online. Signing on in 1975 under the WZLE call sign, the station originally focused on Lorain County with a full-service format of local news and easy listening music dubbed  mellow gold . A 1983 sale of WZLE to a local non-profit Christian group saw the station switch to Christian radio programming, eventually focusing on contemporary Christian music by the early 1990s. Jacor's purchase of WZLE in 1998 resulted in a format change to contemporary hit radio as the first home of WAKS the following year. A complex seven-station asset swap involving Jacor's successor company Clear Channel, Salem Communications and WCLV (95.5 FM) owner Radio Seaway on July 3, 2001, resulted in 104.9 FM becoming the second home of WCLV and its classical music format. Converted to non-commercial status and donated to Ideastream in 2013, WCLV's programming and call letters moved to 90.3 FM on March 28, 2022; in turn, this station became a full-power satellite of co-managed WKSU, bearing the WCPN calls. ";

  if (!user.isSuccess) return <span>Loading...</span>;

  const tabs = [
    { name: "Über uns", key: "about" },
    { name: "Feed", key: "feed" },
    { name: "Watch", key: "watch" },
    { name: "Mitglieder", key: "members" },
  ];

  return (
    <div className="grid grid-flow-row gap-2">
      <img
        src="/images/default_header_profile.png"
        className="w-full h-[300px] object-cover"
      />
      <div className="flex gap-4 overflow-x-auto no-scrollbar mt-2 mb-4">
        {tabs.map((tab) => {
          return (
            <div
              key={tab.key}
              className="whitespace-nowrap pb-1 text-sm text-gray-400 hover:text-black cursor-pointer border-b-2 hover:border-black"
            >
              {tab.name} (99+)
            </div>
          );
        })}
      </div>

      {/* Über uns Tab */}
      <div className="grid grid-flow-row gap-4">
        {/* Main description */}
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <UserWidget user={user.data} follow={false} online={true} />
            <div className="flex flex-col">
              <div className="inline-flex gap-2 items-center">
                <div className="text-lg0 font-semibold">
                  {getName(user.data)}
                </div>
                <img src="/images/group_verify.svg" className="w-4 h-4" />
              </div>
              <div className="text-xs">4 / 5 Sterne</div>
            </div>
          </div>
          <div className="flex flex-row gap-4 text-xs">
            <div>Mitglieder: 0</div>
            <div>Likes: 0</div>
            <div>Beiträge: 0</div>
            <div>Offene Gruppe</div>
          </div>
          <div className="flex flex-row items-center just w-full gap-4 text-xs">
            <div className="flex flex-row gap-0.5 items-center bg-slate-200 rounded-lg px-1 py-0.5 h-fit">
              <ArrowPathRoundedSquareIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex flex-row gap-0.5 items-center bg-slate-200 rounded-lg px-1 py-0.5 h-fit">
              <HeartIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex flex-row gap-0.5 items-center bg-slate-200 rounded-lg px-1 py-0.5 h-fit">
              <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex flex-row gap-0.5 items-center bg-slate-200 rounded-lg px-1 py-0.5 h-fit">
              <BookmarkIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">xxx</div>
            </div>
            <button
              className="flex flex-row gap-0.5 items-center bg-slate-200 rounded-lg px-4 py-0.5 h-fit"
              onClick={() => {
                return;
              }}
            >
              <div className="text-gray-500">Beitreten</div>
            </button>
          </div>

          <div>
            <div className="font-semibold text-sm">Allgemeines zur Gruppe:</div>
            <div css={[tw`text-sm`, textMinimized && tw`line-clamp-5`]}>
              {sampleText}
            </div>
            <button
              className="flex flex-row justify-center items-center gap-2 text-sm border border-gray-500 w-full mt-1"
              onClick={() => setTextMinimized(!textMinimized)}
            >
              {textMinimized ? "weiterlesen" : "weniger"}
              {textMinimized ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronUpIcon className="h-4 w-4" />
              )}
            </button>
          </div>
        </div>
        <div className="relative flex flex-col gap-2">
          <Panel title="Weitere Infos">
            <div className="grid grid-flow-col text-xs">
              <div>
                <img
                  src="/images/rating.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Bewertung</div>
              </div>

              <div>
                <img
                  src="/images/media_library.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Medien</div>
              </div>

              <div>
                <img
                  src="/images/groups.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Gruppen</div>
              </div>

              <div>
                <img
                  src="/images/contact_person.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Kontakt</div>
              </div>

              <div>
                <img
                  src="/images/hashtag.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Hashtags</div>
              </div>

              <div>
                <img
                  src="/images/events.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Events</div>
              </div>

              <div>
                <img
                  src="/images/statistics.svg"
                  className="border border-gray-400 rounded-lg hover:border-gray-800 cursor-pointer h-[55px] mx-auto"
                />
                <div className="text-center">Statistik</div>
              </div>
            </div>
          </Panel>
          <Panel title="Themen und Regeln">
            <div className="flex flex-col gap-1">
              <div>Themen: A B C D</div>
              <div>Regeln: 1 2 3 4</div>
            </div>
          </Panel>
          <Panel title="Social Media">
            <div className="flex flex-row gap-1">
              facebook instagram twitter youtube whatsapp
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsContainer;
