import React from "react";
import { User } from "@/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import api from "@/api/api";
import ContactForYouItem from "@pages/contacts/for_you/ContactForYouItem";
import useDebounce from "@/utils/useDebounce";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";

type Props = {
  type: string;
  userId: string;
  searchQuery: string;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};
const UserLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);

  const list = useInfiniteQuery<Response>(
    [props.type, userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/users/${userId}/${props.type}?page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  return (
    <>
      {list.data &&
        list.data.pages.map((page) =>
          page.data.map((item: User) => (
            <ContactForYouItem key={item.id} user={item} />
          )),
        )}
      {list.hasNextPage && !list.isFetchingNextPage && (
        <ShowMoreButton onClick={() => list.fetchNextPage()} />
      )}
    </>
  );
};

export default UserLikes;
