import React, { useRef, useState } from "react";
import ModalWrapper from "@components/elements/shared/ModalWrapper";
import Input from "@/components/elements/input/Input";
import { User } from "@/utils/user";
import getName from "@/utils/getName";
import tw from "twin.macro";
import GroupComboBox from "@components/elements/form/GroupComboBox";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/api/api";
import Button from "@components/elements/input/Button";
import { Media } from "@/utils/types/Media";
import AvatarEditElement from "@pages/messenger/AvatarEditElement";

type Props = {
  chatId: string;
  name: string;
  visible: boolean;
  members: Array<User>;
  closeModal: () => void;
  avatar?: Media;
};

const EditModal = ({
  visible,
  closeModal,
  members,
  name,
  chatId,
  avatar,
}: Props) => {
  const [value, setValue] = useState(name ?? "");
  const [memberEdit, setMemberEdit] = useState(false);
  const [groupMembers, setGroupMembers] = useState(members);
  const imageRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<Media | undefined>(avatar);
  const queryClient = useQueryClient();

  const upload = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const res = await api.post("/api/v1/media/upload", formData, config);
      return res.data.data;
    },
    onSuccess: (data) => {
      setImage(data);
    },
    onError: () => {
      alert("Failed");
    },
  });

  const editGroup = useMutation(["edit-group"], {
    mutationFn: async () => {
      const res = await api.patch(`/api/v1/chats/${chatId}/groups`, {
        name: value,
        members: groupMembers.map((item) => item.id),
        avatar: image?.id,
      });
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      closeModal();
      setTimeout(() => {
        editGroup.reset();
      }, 500);
    },
    onError: () => {
      setTimeout(() => {
        editGroup.reset();
      }, 500);
    },
  });

  return (
    <ModalWrapper
      open={visible}
      onCancel={closeModal}
      onConfirm={() => editGroup.mutate()}
      loading={editGroup.isLoading}
      failed={editGroup.isError}
      hideActions={memberEdit}
      title="Gruppe bearbeiten"
    >
      <div className="grid grid-cols-1 gap-4 max-h-[75vh] overflow-y-scroll no-scrollbar">
        {!memberEdit ? (
          <div>
            <input
              type="file"
              className="hidden"
              ref={imageRef}
              multiple={false}
              onChange={(e) => {
                if (e.target.files) {
                  upload.mutate(e.target.files[0]);
                }
              }}
            />
            <AvatarEditElement
              image={image}
              loading={upload.isLoading}
              onClick={() => imageRef.current?.click()}
            />
            <Input
              value={value}
              onChange={(val) => setValue(val)}
              name="Gruppe erstellen"
            />
            <div className="grid grid-cols-1 gap-2 mt-2">
              <div>Teilnehmer</div>
              {groupMembers.map((member) => (
                <div
                  key={member.id}
                  className="relative cursor-default select-none py-1.5 cursor-pointer pl-3 pr-9 text-gray-900 rounded-2xl border border-gray-300 shadow-sm hover:border-darkblue"
                >
                  <div className="flex items-center">
                    <img
                      src={
                        (member.avatar !== "" && member.avatar) ||
                        `/images/placeholder/${member.type}.png`
                      }
                      alt=""
                      className="h-6 w-6 flex-shrink-0 rounded-full"
                    />
                    <span css={[tw`ml-3 line-clamp-1 text-left`]}>
                      {getName(member)}
                    </span>
                  </div>
                </div>
              ))}
              <Button
                variant={Button.Variants.Transparent}
                onClick={() => setMemberEdit(true)}
              >
                Bearbeiten
              </Button>
            </div>
          </div>
        ) : (
          <>
            <GroupComboBox
              selectedUsers={groupMembers}
              setSelectedUsers={setGroupMembers}
              label="Kontakt suchen"
            />
          </>
        )}
      </div>
      {memberEdit && (
        <Button
          variant={Button.Variants.Transparent}
          onClick={() => setMemberEdit(false)}
        >
          Fertig
        </Button>
      )}
    </ModalWrapper>
  );
};

export default EditModal;
