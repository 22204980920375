import React, { useState } from "react";
import Panel from "@/components/box/Panel";
import Checkbox from "@/components/elements/input/Checkbox";
import Input from "@/components/elements/input/Input";
import Select from "@/components/elements/input/Select";
import Tab from "@/components/menu/Tab";
import TabFilter from "@/components/menu/TabFilter";
import { useMutation } from "@tanstack/react-query";
import driverLicenseOptions from "@components/elements/jobs/shared/options/driverLicenseOptions";
import JobBasicsForm from "@components/elements/jobs/form/shared/JobBasicsForm";
import SalutationSelect from "@components/elements/shared/inputs/SalutationSelect";
import BottomProfileEditTabs from "@pages/profile/edit/BottomProfileEditTabs";
import api from "@/api/api";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";

const CreateJob = () => {
  const [createFilter, setCreateFilter] = useState<string>("basics");
  const [form, setForm] = useState<any>({});
  const { t } = useTranslation();

  const createTabs = [
    { name: t("main.navLinks.jobSpublished.jobInfo"), key: "basics" },
    { name: t("main.navLinks.jobSpublished.requirement"), key: "requirements" },
    { name: t("main.navLinks.jobSpublished.contactDetails"), key: "contact" },
  ];

  const create = useMutation(["create-job"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/jobs/", form);
      return res.data;
    },
    onError: () => {
      alert("Failed");
    },
  });

  const onChange = (name: string, value: unknown) => {
    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const { user } = useAppSelector((state) => state.user);

  if (!user) return <span>Loading...</span>;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        console.log(e);
      }}
    >
      <TabFilter
        value={createFilter}
        options={createTabs}
        onChange={(value) => setCreateFilter(value)}
        subTab={true}
      />

      <Panel>
        {createFilter === "basics" && (
          <JobBasicsForm form={form} onChange={onChange} />
        )}
        <Tab isActive={createFilter === "requirements"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <div className="grid grid-flow-row gap-1">
              <Input
                name="education"
                placeholder="Berufsausbildung / Studium"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    education: e,
                  }))
                }
              />
            </div>
            <div className="-mb-2">Sonstige Anmerkungen zur Ausbildung</div>
            <div className="grid grid-flow-row gap-1">
              <textarea
                name="education_note"
                className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    education_note: e,
                  }))
                }
              />
            </div>
            <div className="-mb-2">Fähigkeiten</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="skills"
                placeholder="Kenntnisse / Fähigkeiten"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    skills: e,
                  }))
                }
              />
              <Input
                name="language"
                placeholder="Sprachkenntnisse"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    language: e,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="strenghts"
                placeholder="Persönliche Stärken"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    personal_strengths: e,
                  }))
                }
              />
              <Input
                name="experience"
                placeholder="Berufserfahrung"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    work_experience: e,
                  }))
                }
              />
            </div>
            <div className="-mb-2">Führungskompentenzen</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="leadskills"
                placeholder="Leitungsarten"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    leadership: e,
                  }))
                }
              />
              <Input name="authorization" placeholder="Vollmachten" />
            </div>
            <div className="grid grid-cols-2 gap-2 items-center">
              <Checkbox
                name="teamlead"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    leadership_experience: e,
                  }))
                }
                checked={false}
              >
                Führungserfahrung
              </Checkbox>
              <Checkbox
                name="budget"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    budget_responsibility: e,
                  }))
                }
                checked={false}
              >
                Budgetverwantwortung
              </Checkbox>
            </div>
            <div className="grid grid-cols-2 gap-2 items-center">
              <Checkbox
                name="humanresources"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    staff_responsibility: e,
                  }))
                }
                checked={false}
              >
                Personalverantwortung
              </Checkbox>
            </div>
            <div className="-mb-2">Mobilität</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Checkbox
                name="travel"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    montage: e,
                  }))
                }
                checked={false}
              >
                Reise- und Montagebereitschaft
              </Checkbox>
              <Select
                items={driverLicenseOptions}
                name="driverslicense"
                label="Führerscheine"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    drivers_license: e.target.value,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input
                name="location"
                placeholder="Arbeitsort"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    working_place: e,
                  }))
                }
              />
              <Input
                name="range"
                placeholder="Umkreis"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    radius: e,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row gap-2 items-center">
              <Input
                name="country"
                placeholder="Land"
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    country: e,
                  }))
                }
              />
            </div>
          </div>
        </Tab>

        <Tab isActive={createFilter === "contact"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <div className="-mb-2">Ansprechpartner</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Select
                name="employee"
                label="Mitarbeiter auswählen"
                items={[]}
                onChange={(e) =>
                  setForm((prev: any) => ({
                    ...prev,
                    contact_person_id: e.target.value,
                  }))
                }
              />
            </div>
            <div className="-mb-2">Alternative Ansprechpartner</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <SalutationSelect
                value={form.contact_person && form.contact_person.salutation}
                onChange={onChange}
              />
              <Input name="contact-title" placeholder="Titel" />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="contact-surname" placeholder="Vorname" />
              <Input name="contact-lastname" placeholder="Nachname" />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="contact-position" placeholder="Position" />
              <Input name="contact-department" placeholder="Abteilung" />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="contact-phone" placeholder="Telefon" />
              <Input name="contact-email" placeholder="E-Mail" />
            </div>
            <div className="-mb-2">Vorstellungsort</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="meeting-postalcode" placeholder="PLZ" />
              <Input name="meeting-city" placeholder="Ort" />
            </div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="meeting-street" placeholder="Straße" />
              <Input name="meeting-streetnumber" placeholder="Hausnummer" />
            </div>
            <div className="-mb-2">Angaben zur Bewerbung</div>
            <div className="grid grid-flow-row gap-2 items-center">
              <Input
                name="meeting-type"
                placeholder="Gewünschte Art der Bewerbung"
              />
            </div>
            <div className="-mb-2">Bewerbungszeitraum</div>
            <div className="grid grid-flow-row lg:grid-cols-2 gap-2 items-center">
              <Input name="meeting-startdate" placeholder="Von" />
              <Input name="meeting-enddate" placeholder="Bis" />
            </div>
            <div className="-mb-2">Hinweise</div>
            <div className="grid grid-flow-row gap-2 items-center">
              <textarea
                name="notes"
                className="block w-full rounded-xl py-3 px-4 bg-gray-200 border-0"
              />
            </div>
          </div>
        </Tab>
        <BottomProfileEditTabs
          showForward={createFilter !== "contact"}
          showBackward={createFilter !== "basics"}
          backward={() => {
            if (createFilter === "contact") setCreateFilter("requirements");
            if (createFilter === "requirements") setCreateFilter("basics");
          }}
          forward={() => {
            if (createFilter === "basics") setCreateFilter("requirements");
            if (createFilter === "requirements") setCreateFilter("contact");
          }}
          preview={() => console.log("preview")}
          submit={create.mutate}
        />
      </Panel>
    </form>
  );
};

export default CreateJob;
