import React, { useState } from "react";
import Input from "@/components/elements/input/Input";
import HashtagList from "@components/elements/hashtags/HashtagList";
import TabLink from "@components/elements/shared/tab/TabLink";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import { useAppSelector } from "@/state/hooks";

type Props = {
  tab?: string;
};

const Hashtags = ({ tab = "news" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const tabs = [
    { label: "Feed", key: "news", url: "feed" },
    { label: "Watch", key: "watch", url: "watch" },
  ];

  const { user } = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <div className="flex flex-col gap-3">
      <Input
        placeholder="Hashtags durchsuchen"
        name="hashtags_keywords"
        onChange={(e) => setSearchQuery(e)}
        clearable
      />
      <TabMenu subTab>
        {tabs.map((tab: any) => {
          return (
            <>
              <TabLink subTab to={"/entdecken/hashtags/" + tab.url}>
                {tab.label}
              </TabLink>
            </>
          );
        })}
      </TabMenu>
      <HashtagList filter={tab} />
    </div>
  );
};

export default Hashtags;
