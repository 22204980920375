import React, { useState } from "react";
import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import PostsActionBarItem from "@pages/news/posts/PostsActionBarItem";
import tw from "twin.macro";
import PostBookmarkButton from "@pages/news/posts/actions/PostBookmarkButton";
import SharePopup from "@/components/popup/SharePopup";
import useLoginModal from "@/state/modal/useLoginModal";
import { useAppSelector } from "@/state/hooks";

type Props = {
  toggleComments: () => void;
  toggleLike: () => void;
  commentCount: number;
  likeCount: number;
  viewCount: number;
  bookmarkCount: number;
  repostCount: number;
  bookmarked: boolean;
  isReposted: boolean;
  postId: string;
  isLiked: boolean;
};

const PostsActionBar = (props: Props) => {
  const [sharePopupOpen, setSharePopupOpen] = React.useState(false);
  const loginModal = useLoginModal();
  const { user } = useAppSelector((state) => state.user);
  const [likeEffect, setLikeEffect] = useState(false);

  return (
    <div className="flex flex-row justify-between mt-2 text-darkblue relative h-6">
      <PostsActionBarItem
        onClick={props.toggleComments}
        label="Kommentieren"
        count={props.commentCount}
        color={tw`group-hover:bg-blue-100`}
        textColor={tw`group-hover:text-blue-700 text-gray-600`}
      >
        <ChatBubbleOvalLeftIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label="Beitrag teilen"
        count={props.repostCount}
        onClick={() => (user ? setSharePopupOpen(true) : loginModal.open())}
        color={
          props.isReposted
            ? tw`bg-green-100`
            : tw`bg-white group-hover:bg-green-100`
        }
        textColor={
          props.isReposted
            ? tw`text-green-700`
            : tw`group-hover:text-green-700 text-gray-600`
        }
      >
        <ArrowPathRoundedSquareIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label="Like"
        count={props.likeCount}
        onClick={() => {
          user ? props.toggleLike() : loginModal.open();
        }}
        textColor={
          props.isLiked
            ? tw`text-pink-700`
            : tw`text-gray-600 transition-all ease-out duration-200 group-hover:text-pink-700`
        }
        color={props.isLiked ? tw`bg-pink-100` : tw`group-hover:bg-pink-100 `}
      >
        {props.isLiked ? (
          <HeartIconSolid className={`${likeEffect && "animate-ping-once"}`} />
        ) : (
          <HeartIcon
            onClick={() => {
              setLikeEffect(true);
            }}
            onAnimationEnd={() => {
              setLikeEffect(false);
            }}
          />
        )}
      </PostsActionBarItem>
      <PostsActionBarItem
        label="Views"
        count={props.viewCount}
        color={tw`group-hover:bg-blue-100`}
        textColor={tw`group-hover:text-blue-700 text-gray-600`}
      >
        <EyeIcon />
      </PostsActionBarItem>
      <PostBookmarkButton
        postId={props.postId}
        bookmarked={props.bookmarked}
        bookmarkCount={props.bookmarkCount}
      />
      {sharePopupOpen && (
        <SharePopup
          postId={props.postId}
          userId=""
          content={{
            title: "News",
            body: "https://www.tradefoox.com/s/p/" + props.postId,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
    </div>
  );
};

export default PostsActionBar;
